<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜資源
        </div>
        <div class="mt-3 ms-auto col-md-4 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-advanced btn-sm" value="上級｜5.　緊急時におけるBCP発動｜資源（つづき）へ進む" v-show="is_completed_middle" :disabled="isProcessing" @click="showAdvancedMaterial">
        </div>
        <div class="mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="card-group">
        <div class="card bg-light-blue border-green">
            <div class="card-body">
                <div class="row mx-3">
                    <div class="col-md-7 ">
                        ◯　中核{{ callReplaceWord('事業') }}に係るボトルネック資源［設備・機械・車両など］
                    </div>
                </div>

                <template v-for="(bottleneckResource, index) in bottleneckResourceInfo" :key="index">
                    <div class="row mx-3 mt-3">
                        <div class="col-md-12 border border-dark border-bottom-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                            <span v-if="index == 0">&emsp;</span>
                            <input type="button" class="btn btn-delete mt-3" value="削除" v-if="index > 0" @click="deleteBottleneckResource(index)">
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            &emsp;
                        </div>
                        <div class="mx-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            資源の品名・モデル名
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-4 col-md-1 border border-dark bg-light-gray text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                            {{ index + 1 }}
                        </div>
                        <div class="mx-3 col-md-8 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.productName">
                        </div>
                        <div class="ms-1 col-md-1 d-flex align-items-start justify-content-start">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>中核事業に係るボトルネック資源［設備・機械・車両など］では、従業員や電力など膨大な資源数となるため、代替{{ callReplaceWord('生産') }}（B&B災害応援協定）に関連する大きな資機材、車両の把握から始めましょう。<br />コンピュータ設備は、上級コースで整理します。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-5 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            TOTONO-L 会員ID
                        </div>
                        <div class="mx-3 mt-5 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                        </div>
                        <div class="mx-3 mt-5 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            TOTONO-L 会員ID
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control bg-info d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.supplyCompanyCorpId">
                        </div>
                        <button type="button" class="mx-3 col-md-1 btn btn-black my-2" @click="getMemberInfo(index, bottleneckResource.supplyCompanyCorpId, isMain)">
                            検　索
                        </button>
                        <div class="mx-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control bg-info d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.supplyCompanyExtraCorpId">
                        </div>
                        <button type="button" class="mx-3 col-md-1 btn btn-black my-2" @click="getMemberInfo(index, bottleneckResource.supplyCompanyExtraCorpId, isSub)">
                            検　索
                        </button>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-4 bg-light-gray text-dark d-flex align-items-top justify-content-start" style="font-size:10.5pt">
                            ※入力すると購入事業者の情報が自動表示されます。
                        </div>
                        <div class="mx-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                        </div>
                        <div class="mx-3 col-md-5 bg-light-gray text-dark d-flex align-items-top justify-content-start" style="font-size:10.5pt">
                            ※入力すると購入事業者｜予備の情報が自動表示されます。
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            供給事業者
                        </div>
                        <div class="mx-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                        </div>
                        <div class="mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            供給事業者｜予備
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.supplyCompany">
                        </div>
                        <div class="ms-4 col-md-5 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.supplyCompanyExtra">
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            メールアドレス
                        </div>
                        <div class="mx-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                        </div>
                        <div class="mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            メールアドレス
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.supplyCompanyEmail">
                        </div>
                        <div class="ms-4 col-md-5 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.supplyCompanyExtraEmail">
                        </div>
                        <div class="ms-1 col-md-1 d-flex align-items-start justify-content-start">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>メールアドレスは、BCP発動時の連絡手段のほか、運用サイクルにおける合同訓練に使用できます。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            当該資源を利用する{{ callReplaceWord('社内') }}重要業務
                        </div>
                        <div class="mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            社内責任者
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                            <select name="selectImportantBusiness" class="form-select form-control bg-resilience-orange d-flex align-items-center justify-content-center py-2"
                                v-model="bottleneckResource.importantBusinessId"
                                @change="getImportantBusinessManager(index)"
                            >
                                <option type="text" ></option>
                                <option type="text" v-for="importantBusinessList in important_businesses_info" :key="importantBusinessList" :value="importantBusinessList.id">
                                    {{ importantBusinessList.important_business_content }}
                                </option>
                            </select>
                        </div>
                        <div class="ms-4 col-md-4 d-flex align-items-center justify-content-start py-2">
                            <input type="text" readonly class="form-control d-flex align-items-center justify-content-center py-2 border border-dark bg-light-gray text-dark" placeholder="" v-model="bottleneckResource.importantBusinessManager">
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            当該資源の現状
                        </div>
                        <div class="mt-3 col-md d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                            <template v-for="current in currentStatusList" :key="current.statusId">
                                <input type="checkbox" :id="'customRadioInline1' + index + current.statusId" :name="'customRadioInline1' + index"
                                    class="custom-control-input" :class="{'ms-3': current.statusId >= 2}"
                                    :value="current.statusId" v-model="bottleneckResource.currentResource"
                                >
                                <label class="custom-control-label" :for="'customRadioInline1' + index + current.statusId">&nbsp;{{ current.status }}</label>
                            </template>
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            {{ callReplaceWord('事業') }}継続の際の想定設置場所
                        </div>
                        <div class="mx-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                        </div>
                        <div class="mx-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            交換・設置に要する時間
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.installationLocation">
                        </div>
                        <div class="ms-1 col-md-1 d-flex align-items-start justify-content-start">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>風水害など、事前の警戒情報が期待できる災害においては、安全な場所に移動が可能な設備や機械かどうかについても把握しておきます。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                        <div class="ms-5 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-center">
                            約
                        </div>
                        <div class="col-md-2 d-flex align-items-start justify-content-start py-2">
                            <input type="Number" min="0" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.installationTime">
                        </div>
                        <div class="col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            時間
                        </div>
                    </div>

                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            備考
                        </div>
                    </div>
                    <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="mx-3 col-md-11 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="bottleneckResource.note">
                        </div>
                    </div>

                    <div class="row mx-3">
                        <div class="col-md-12 border border-dark border-top-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                            &emsp;
                        </div>
                    </div>
                </template>

                <div class="row mt-1">
                    <div class="ms-3 col-md-10 d-flex align-items-start justify-content-start">
                    </div>
                    <div class="ms-3 col-md-1 d-flex align-items-start justify-content-start">
                        <input type="button" class="btn btn-black" value="ワンセット増やす" @click="addBottleneckResourceInfoSet">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="card border-0 bg-transparent">
                <div class="card-body">
                    <div class="d-flex flex-row">
                        <VDropdown
                            theme="browsing_restrictions"
                        >
                            <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                            <template #popper>
                                <div class="d-flex justify-content-start">
                                    <label>閲覧制限</label>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                        <label class="form-check-label" for="checkbox_1">
                                            あり
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="ms-5 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜資源（つづき）へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
           // 取得データをセット
           if (props.bottleneck_resources_info.length > 0) {
                // 閲覧制限
                viewLimit.value = props.bottleneck_resources_info[0].is_view_limit == 1 ? true : false;

                bottleneckResourceInfo.splice(0);
                props.bottleneck_resources_info.forEach(fetchData => {
                    // ボトルネック資源情報
                    bottleneckResourceInfo.push({
                        resourceType: fetchData.resource_type,
                        isViewLimit: fetchData.is_view_limit,
                        productName: fetchData.product_name,
                        equipmentQuantity: fetchData.equipment_quantity,
                        types: fetchData.types !== null ? fetchData.types.split(',') : fetchData.types,
                        supplyCompanyCorpId: fetchData.supply_company_corp_id,
                        supplyCompany: fetchData.supply_company,
                        supplyCompanyEmail: fetchData.supply_company_email,
                        supplyCompanyExtraCorpId: fetchData.supply_company_extra_corp_id,
                        supplyCompanyExtra: fetchData.supply_company_extra,
                        supplyCompanyExtraEmail: fetchData.supply_company_extra_email,
                        importantBusinessId: fetchData.important_business_id,
                        importantBusinessManager: fetchData.important_business_manager,
                        currentResource: fetchData.current_resource !== null ? fetchData.current_resource.split(',') : fetchData.current_resource,
                        installationLocation: fetchData.installation_location,
                        installationTime: fetchData.installation_time,
                        note: fetchData.note,
                    })
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const viewLimit = ref(0);
        const isMain = 1;
        const isSub = 2;
        const typeEquipmentMachineVehicle = '1';

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const currentStatusList = [
            {statusId: 1, status: '現在、使用中'},
            {statusId: 2, status: 'リース予定／購入予定'},
        ]

        const bottleneckResourceInfo = reactive([
            {
                resourceType: typeEquipmentMachineVehicle,
                isViewLimit: viewLimit.value,
                productName: '',
                equipmentQuantity: '',
                types: [],
                supplyCompanyCorpId: '',
                supplyCompany: '',
                supplyCompanyEmail: '',
                supplyCompanyExtraCorpId: '',
                supplyCompanyExtra: '',
                supplyCompanyExtraEmail: '',
                importantBusinessId: '',
                importantBusinessManager: '',
                currentResource: [],
                installationLocation: '',
                installationTime: '',
                note: '',
            }
        ]);

        // 検索ボタン
        const getMemberInfo = (index, searchId, target) => {
            const url="/api/bcp/getMemberInfo";

            // 企業情報取得
            return axios.post(url, {
                corp_id: searchId,
            }).then(res=>{
                    if (target === isMain) {
                        bottleneckResourceInfo[index].supplyCompanyCorpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                        bottleneckResourceInfo[index].supplyCompany = res.data.corporate_name !== undefined ? res.data.corporate_name : null;
                        bottleneckResourceInfo[index].supplyCompanyEmail = res.data.mail !== undefined ? res.data.mail : null;
                    } else {
                        bottleneckResourceInfo[index].supplyCompanyExtraCorpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                        bottleneckResourceInfo[index].supplyCompanyExtra = res.data.corporate_name !== undefined ? res.data.corporate_name : null;
                        bottleneckResourceInfo[index].supplyCompanyExtraEmail = res.data.mail !== undefined ? res.data.mail : null;
                    }
            });
        }

       // 重要業務の責任者取得
       const getImportantBusinessManager = (index) => {
            const url="/api/bcp/getImportantBusinessInfo";

            return axios.post(url, {
                bcp_id: props.bcp_basic_frame_info.id,
                version: props.bcp_basic_frame_info.version,
                important_business_id: bottleneckResourceInfo[index].importantBusinessId,
            }).then(res =>{
                bottleneckResourceInfo[index].importantBusinessManager = res.data.important_business_manager !== undefined
                    ? res.data.important_business_manager
                    : null;
            });
        }

        // ワンセット増やすボタン
        const addBottleneckResourceInfoSet = () => {
            bottleneckResourceInfo.push({
                resourceType: typeEquipmentMachineVehicle,
                isViewLimit: viewLimit.value,
                productName: '',
                equipmentQuantity: '',
                types: [],
                supplyCompanyCorpId: '',
                supplyCompany: '',
                supplyCompanyEmail: '',
                supplyCompanyExtraCorpId: '',
                supplyCompanyExtra: '',
                supplyCompanyExtraEmail: '',
                importantBusinessId: '',
                importantBusinessManager: '',
                currentResource: [],
                installationLocation: '',
                installationTime: '',
                note: '',
            });
        }

        const deleteBottleneckResource = (index) => {
            if(!confirm((index + 1) + "番目（" + bottleneckResourceInfo[index].productName + "）を削除します。よろしいですか？")){
                return false;
            }

            bottleneckResourceInfo.splice(index, 1);
        }

        const showBack = async () => {
            // ボトルネック資源を登録
            const result = await registerSecond8();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // ボトルネック資源を登録
            const result = await registerSecond8();

            if (result == '200') {
                const url = '/bcp/bcp_second/11';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // ボトルネック資源を登録
            const result = await registerSecond8();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // ボトルネック資源を登録
            const result = await registerSecond8();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showAdvancedMaterial = async () => {
            // ボトルネック資源を登録
            const result = await registerSecond8();

            if (result == '200') {
                const url = '/bcp/bcp_second/9';
                location.href = url;
            }
        }

        // ボトルネック資源登録
        const registerSecond8 = async () => {
            // リクエスト内容の調整
            bottleneckResourceInfo.forEach(data => {
                // 閲覧制限
                data.isViewLimit = viewLimit.value;
            })

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond8';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'resource_type': typeEquipmentMachineVehicle,
                'bottleneck_resources_info': bottleneckResourceInfo,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            viewLimit,
            isMain,
            isSub,
            callReplaceWord,
            currentStatusList,
            bottleneckResourceInfo,
            getMemberInfo,
            getImportantBusinessManager,
            addBottleneckResourceInfoSet,
            deleteBottleneckResource,
            showBack,
            showNext,
            showPrevious,
            showContents,
            showAdvancedMaterial,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        important_businesses_info: {
            type: Object,
        },
        bottleneck_resources_info: {
            type: Object,
        },
        is_completed_middle: {
            type: Boolean,
        },
    }

}
</script>
