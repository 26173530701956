<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap text-nowrap">様式16-3</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">中核{{ callReplaceWord('事業') }}に係るボトルネック資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 当社の中核事業を代替施設で復旧・継続さえる際に必要となる各種の機材や道具類には以下のものがある。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">3&emsp;その他の器具類</label>
                </div>
            </div>
            <div class="ms-md-3 fs-half">
                <div class="row mt-3">
                    <div class="px-1 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center justify-content-md-start">
                        <label class="form-label text-nowrap">資源の項目</label>
                    </div>
                    <div class="px-1 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center justify-content-md-start">
                        <label class="form-label text-nowrap">数量</label>
                    </div>
                    <div class="px-1 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center justify-content-md-start">
                        <label class="form-label text-nowrap">供給事業者</label>
                    </div>
                    <div class="px-1 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center justify-content-md-start">
                        <label class="form-label">供給事業者｜予備</label>
                    </div>
                    <div class="px-1 col-md-3 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">{{ callReplaceWord('事業') }}継続の際の想定設置場所</label>
                    </div>
                </div>
                <div class="row" v-for="(item, index) in bottleneckResourceOtherInfo">
                    <div class="px-1 px-md-3 col-md-2 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== bottleneckResourceOtherInfo.length - 1 }">
                        <label class="form-label">{{ item.resourceItem || '&emsp;' }}</label>
                    </div>
                    <div class="px-1 px-md-3 col-md-1 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== bottleneckResourceOtherInfo.length - 1 }">
                        <label class="form-label">{{ item.quantity == null ? '&emsp;' : item.quantity }}</label>
                    </div>
                    <div class="px-1 px-md-3 col-md-3 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== bottleneckResourceOtherInfo.length - 1 }">
                        <label class="form-label">{{ item.supplyCompany || '&emsp;' }}</label>
                    </div>
                    <div class="px-1 px-md-3 col-md-3 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== bottleneckResourceOtherInfo.length - 1 }">
                        <label class="form-label">{{ item.supplyCompanyExtra || '&emsp;' }}</label>
                    </div>
                    <div class="px-1 px-md-3 col-md-3 border bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== bottleneckResourceOtherInfo.length - 1 }">
                        <label class="form-label">{{ item.installationLocation || '&emsp;' }}</label>
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        let bottleneckResourceOtherInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/J5';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/J7';
            location.href = url;
        }

        if (props.bottleneck_resource_others_info.length > 0) {
            bottleneckResourceOtherInfo = props.bottleneck_resource_others_info.map((fetchData) => ({
                resourceItem: fetchData.resource_item,
                quantity: fetchData.quantity,
                supplyCompany: fetchData.supply_company,
                supplyCompanyExtra: fetchData.supply_company_extra,
                installationLocation: fetchData.installation_location,
            }));
        }

        return {
            setLevel,
            isMargin1,
            bottleneckResourceOtherInfo,
            callReplaceWord,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bottleneck_resource_others_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .fs-half {
        font-size: 0.5rem;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
