<template>
    <div class="container">
        <div class="row mt-3">
            <div class="heading mt-3">内部情報管理（過去の従量課金）</div>
        </div>
        <div class="row mt-3" v-for="payPerUse in pay_per_use_list" :key="payPerUse.id">
            <div class="row">
                <div class="col-1 fw-bold">制定日</div>
                <div class="col-3 fw-bold">{{ this.$root.dayjs(payPerUse.制定日).format('YYYY/MM/DD') }}</div>
            </div>
            <div class="row">
                <div class="col-3">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">登録する役員・従業員人数</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>から（人）</td>
                                <td>まで（人）</td>
                                <td>1人（円）</td>
                            </tr>
                        </tbody>
                        <tr v-for="employee in pay_per_use_employee_list.filter((i) => i.pay_per_use_id === payPerUse.id)" :key="[employee.pay_per_use_id, employee.従量課金区分, employee.seq]">
                            <td class="text-end">{{ this.formatNumber(employee.開始数) }}</td>
                            <td class="text-end">{{ this.formatNumber(employee.終了数) }}</td>
                            <td class="text-end">{{ this.formatNumber(employee.課金額) }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">登録する拠点・支店の数</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>から（ヶ所）</td>
                                <td>まで（ヶ所）</td>
                                <td>1カ所（円）</td>
                            </tr>
                        </tbody>
                        <tr v-for="branch in pay_per_use_branch_list.filter((i) => i.pay_per_use_id === payPerUse.id)" :key="[branch.pay_per_use_id, branch.従量課金区分, branch.seq]">
                            <td class="text-end">{{ this.formatNumber(branch.開始数) }}</td>
                            <td class="text-end">{{ this.formatNumber(branch.終了数) }}</td>
                            <td class="text-end">{{ this.formatNumber(branch.課金額) }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-3">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">登録する家族の人数</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>から（人）</td>
                                <td>まで（人）</td>
                                <td>1人（円）</td>
                            </tr>
                        </tbody>
                        <tr v-for="family in pay_per_use_family_list.filter((i) => i.pay_per_use_id === payPerUse.id)" :key="[family.pay_per_use_id, family.従量課金区分, family.seq]">
                            <td class="text-end">{{ this.formatNumber(family.開始数) }}</td>
                            <td class="text-end">{{ this.formatNumber(family.終了数) }}</td>
                            <td class="text-end">{{ this.formatNumber(family.課金額) }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-2" v-if="getLINEPrice(payPerUse.id)">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="2" class="text-center">LINE連携オプション</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>利用料金</td>
                                <td>{{ formatNumber(getLINEPrice(payPerUse.id).課金額) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';

export default {
    setup(props) {
        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        const getLINEPrice = (id) => {
            return props.pay_per_use_line_list.find(item => item.pay_per_use_id === id);
        }

        return {
            formatNumber,
            getLINEPrice,
        }
    },
    props: {
        pay_per_use_list: {
            type: Array,
        },
        pay_per_use_employee_list: {
            type: Array,
        },
        pay_per_use_branch_list: {
            type: Array,
        },
        pay_per_use_family_list: {
            type: Array,
        },
        pay_per_use_line_list: {
            type: Array,
        },
    }
}
</script>