<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜発動フロー
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="col-md-12">
                <label for="" class="form-label d-flex align-items-center">緊急事態が発生した場合のBCPの発動手順は次のとおりです。</label>
                <label for="" class="form-label d-flex align-items-center">①   緊急事態が発覚したら、初動対応（災害の種類ごとに違いあり）を行います。</label>
                <label for="" class="form-label d-flex align-items-center">②   なるべく速やかに、{{ callReplaceWord('顧客') }}等へ被災状況を連絡するとともに、中核{{ callReplaceWord('事業') }}の継続方針を立案し、その実施体制を確立します。</label>
                <label for="" class="form-label d-flex align-items-center">③   中核{{ callReplaceWord('事業') }}継続方針に基づき、{{ callReplaceWord('顧客') }}・協力会社向け対策、{{ callReplaceWord('従業員') }}・{{ callReplaceWord('事業') }}資源対策、財務対策を併行して進めます。<br>&emsp;&nbsp;&nbsp;&nbsp;また、地域貢献活動も実施します。</label>
                <label for="" class="form-label d-flex align-items-center">④   緊急事態の進展・収束にあわせて、応急対策、復旧対策、復興対策を進めます。</label>
            </div>
        </div>
    </div>

  <!-- 図 -->
  <div class="d-flex flow-diagram mt-4">
        <div class="flow-diagram-row-1">
            <p>大規模災害時の目安</p>
            <div class="timetable">
                <div>当日〜</div>
                <div>数日～</div>
                <div>1ヶ月～</div>
                <div>数か月～</div>
            </div>
        </div>
        <div class="flow-diagram-row-2">
            <div class="recovery-time">復旧目標時間</div>
            <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown theme="hint-theme">
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap mt-2" value="ヒント">
                        <template #popper>
                            <div class="row">
                                {{ hint_word_11 }}
                            </div>
                        </template>
                    </VDropdown>
                </div>
        </div>
        <div class="flow-diagram-row-3">
            <div class="flow-diagram-section d-flex">
                <div class="flow-diagram-content content-1 me-3">緊急事態の発覚</div>
                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown theme="hint-theme">
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                            <label>{{ hint_word_1 }}</label>
                        </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="flow-diagram-border">
                <div class="flow-diagram-border-1 d-flex">
                    <div></div>
                    <div></div>
                </div>
                <div class="flow-diagram-border-2 d-flex">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="flow-diagram-section d-flex">
                <div class="flow-diagram-content content-2 me-3">
                    <div class="flow-diagram-title">初動対応［災害ごと］</div>
                    <div class="flow-diagram-menu">
                        <button type="button" class="btn d-block" @click="showPrevious('first10-1')">二次災害の防止措置</button>
                        <button type="button" class="btn d-block" @click="showPrevious('first10-2')">{{ callReplaceWord('従業員') }}の参集</button>
                        <button type="button" class="btn d-block" @click="showPrevious('first10-3')">安否・被災状況の把握</button>
                    </div>
                </div>
                <div class="flow-diagram-section-hint">
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_2 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_3 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap mb-0" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_4 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
            <div class="flow-diagram-border">
                <div class="flow-diagram-border-1 d-flex">
                    <div></div>
                    <div class="border-b"></div>
                    <div class="border-b"></div>
                </div>
                <div class=" flow-diagram-border-2 d-flex">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="flow-diagram-section d-flex">
                <div class="flow-diagram-content content-3 me-3">
                    <div class="flow-diagram-title">事業継続のための緊急対策</div>
                    <div class="flow-diagram-menu">
                        <button type="button" class="btn d-block" @click="showPrevious('first10-4')">{{ callReplaceWord('顧客') }}・協力会社への連絡</button>
                        <button type="button" class="btn d-block" @click="showPrevious('first10-5')">中核{{ callReplaceWord('事業') }}継続方針立案・体制確立</button>
                    </div>
                </div>
                <div class="flow-diagram-section-hint">
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_6 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap mb-0" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_7 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
            <div class="flow-diagram-border">
                <div class="flow-diagram-border-1 d-flex">
                    <div></div>
                    <div></div>
                </div>
                <div class="flow-diagram-border-2 d-flex">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="flow-diagram-section d-flex">
                <div class="flow-diagram-content content-4 me-3">
                    <div class="flow-diagram-title">事業継続のための応急・復旧対策</div>
                    <div class="flow-diagram-menu">
                        <button type="button" class="btn d-block" @click="showPrevious('first10-6')">{{ callReplaceWord('顧客') }}・協力会社向け対策</button>
                        <button type="button" class="btn d-block" @click="showPrevious('first10-7')">{{ callReplaceWord('社員') }}・{{ callReplaceWord('事業') }}資源対策</button>
                        <button type="button" class="btn d-block" @click="showPrevious('first10-8')">財務対策</button>
                    </div>
                </div>
                <div class="flow-diagram-section-hint">
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_8 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_9 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap mb-0" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_10 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
            <div class="flow-diagram-border">
                <div class="flow-diagram-border-1 d-flex">
                    <div></div>
                    <div class="border-b"></div>
                    <div class="border-b"></div>
                </div>
                <div class="flow-diagram-border-2 d-flex">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="flow-diagram-section d-flex">
                <button type="button" class="flow-diagram-content content-5 me-3" @click="showXxxx">災害復興・公的支援制度</button>
                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown theme="hint-theme">
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                                <label>{{ hint_word_12 }}</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>

        </div>
        <div class="flow-diagram-row-4 d-flex">
            <div>
                <div class="flow-diagram-border"></div>
                <a href="/bcp/bcp_first/10#first10-9" class="katudou">
                    地域貢献活動
                </a>
                <div class="flow-diagram-border"></div>
            </div>
            <div class="p2 col-md-1 d-flex align-items-start justify-content-start mt-3 ms-2">
                <VDropdown theme="hint-theme">
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            {{ hint_word_5 }}
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-12 align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜5. 緊急時におけるBCP発動｜発動フローに沿った実施項目 へ進む" style="font-size:10.5pt" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            // window.location.reload();
        }

        const setLevel = ref('011100');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const hint_word_1 = ref(`地震や風水害等の自然災害やテロや火災、事故等の人為的災害といった${callReplaceWord('従業員')}や中核${callReplaceWord('事業')}等に対して重大な被害や影響を及ぼす可能性のある事態のこといいます。`);
        const hint_word_2 = ref(`ある災害が発生した場合に、それが原因となってその後に発生する災害のこと。例えば、地震の後に発生する火災や爆発等が挙げられます。`);
        const hint_word_3 = ref(`就業時間外等に緊急事態が発生した場合、${callReplaceWord('経営者')}自身及び${callReplaceWord('従業員')}は、${callReplaceWord('組織')}等に参集します。例えば大地震では、${callReplaceWord('従業員')}の被災や交通機関の混乱（特に都市部）により、${callReplaceWord('従業員')}の${callReplaceWord('出社')}が困難となることを踏まえ、BCPに記載しておく必要があります。`);
        const hint_word_4 = ref(`まずは、${callReplaceWord('従業員')}とその家族の安否を確認します（参集して業務に携わるためには、家族の安全が確保されていることが大きな要素になります）。次に、中核${callReplaceWord('事業')}の継続や復旧を検討するため、事業所内外の被害状況を確認します。`);
        const hint_word_5 = ref(`事業継続対策と併行して余力があれば、会社の業種の特性を活かした地域貢献活動を行います。市役所や町村役場、社会福祉協議会、地元自治会、NPOと連携しつつ、協同組合や商店街等で各社の役割分担を決めて行うと効果的です。`);
        const hint_word_6 = ref(`初動対応が済んだら、経営者がリーダーシップをとり${callReplaceWord('従業員')}に事業継続のための緊急対策を指示します。できる限り速やかに${callReplaceWord('顧客')}・協力会社との連絡手段を確保し、安否・被害状況について相互に報告し状況の把握をします。`);
        const hint_word_7 = ref(`中核${callReplaceWord('事業')}が受けたダメージを判断した上、中核${callReplaceWord('事業')}の目標復旧時間等の継続方針を立案するとともに、それを実施するための体制を確立します。`);
        const hint_word_8 = ref(`${callReplaceWord('顧客')}及び協力会社と代替${callReplaceWord('生産')}及び${callReplaceWord('事業')}資源復旧後の取引復元について調整の上、この調整結果に従って代替${callReplaceWord('生産')}及び取引復元を実施します。`);
        const hint_word_9 = ref(`${callReplaceWord('社員')}と${callReplaceWord('事業')}継続について情報共有を行うとともに、被災した${callReplaceWord('社員')}に対して可能な限り生活支援を行います。同時に${callReplaceWord('事業')}継続に必要な資源の代替調達や早期復旧を行います。`);
        const hint_word_10 = ref(`当面の運転資金を確保した上、さらには${callReplaceWord('事業')}復旧のための資金を確保します。大規模な地震や風水害などで災害救助法が適用されると、商工会議所や商工会などに特別相談窓口が設置されたり、地方自治体や政府系金融機関による緊急貸付制度が発足します。`);
        const hint_word_11 = ref(`目標復旧時間（RTO＝Recovery Time Objective ）とは、事業中断による被害を極力小さく抑えるためには、中核事業を復旧させるまでの期限の目安となる指標です。あなたの${callReplaceWord('組織')}が被災した場合に、それらの${callReplaceWord('取引先')}から許容される${callReplaceWord('事業')}停止時間の限度を把握しなければなりません。一方、特定した中核${callReplaceWord('事業')}の停止による損失に対して、あなたの${callReplaceWord('組織')}の資金が耐えられる限界の期間を見積もっておく必要があります。具体的には、中核事業が停止した場合の${callReplaceWord('売上')}の途絶に加えて、納期遅延等による違約金、その間の${callReplaceWord('従業員')}の賃金、災害対応のための臨時人員の賃金、事業所や設備機器が被災した場合の修繕や新規調達費用等が発生しますので、それらの費用負担に対して、どれだけの期間耐えられる資金があなたの${callReplaceWord('組織')}にあるかを見極めなければなりません。`);
        const hint_word_12 = ref(`大規模災害では都道府県や市町村等の復興計画が立案されます。こうした復興計画とも連携し、${callReplaceWord('組織')}の事業の再編や拡大を考えることも良いでしょう。その際、協同組合等を受け皿に災害復旧高度化資金を利用する案もあります`);

        const showBack = async () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showContents = async () => {
            // 目次ページへ遷移
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        const showNext = () => {
            const url = '/bcp/bcp_first/10';
            location.href = url;
        }

        const showPrevious = (targetTag) => {
            const url = '/bcp/bcp_first/10#' + targetTag;
            location.href = url;
        }

        // TODO: 確定次第追加
        const showXxxx = () => {
            const url = '';
            location.href = url;
        }

        return {
            setLevel,
            hint_word_1,
            hint_word_2,
            hint_word_3,
            hint_word_4,
            hint_word_5,
            hint_word_6,
            hint_word_7,
            hint_word_8,
            hint_word_9,
            hint_word_10,
            hint_word_11,
            hint_word_12,
            callReplaceWord,
            showBack,
            showContents,
            showNext,
            showPrevious,
            showXxxx,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
    .bg-blue {
    background-color: #4472c4;
    }

    .bg-disaster {
    background-color: #c00000;
    color: white;
    }

    .bg-gray {
    background-color: #a6a6a6;
    }

    .border-orange {
    border-style: solid;
    border-width: 1px;
    border-color: #ff6600;
    }

    .bg-header {
        background-color: #f2f2f2;
    }

    .flow-diagram-row-1 {
        width: 160px;
        text-align: center;
    }

    .flow-diagram-row-1 .timetable {
        width: 80px;
        margin: auto;
    }

    .flow-diagram-row-1 .timetable div {
        padding: 10px 0;
    }


    .flow-diagram-row-1 .timetable div:nth-child(1) {
        background-color: #404040;
        color: #fff;
        height: 524px;
    }

    .flow-diagram-row-1 .timetable div:nth-child(2) {
        background-color: #808080;
        color: #fff;
        height: 111px;
    }

    .flow-diagram-row-1 .timetable div:nth-child(3) {
        background-color: #A6A6A6;
        color: #fff;
        height: 111px;
    }

    .flow-diagram-row-1 .timetable div:nth-child(4) {
        background-color: #BFBFBF;
        height: 74px;
    }

    .flow-diagram-row-2 {
        width: 100px;
    }

    .flow-diagram-row-2 .recovery-time {
        height: 180px;
        background-color: #FF6600;
        color: #fff;
        border: 1px #404040 solid;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        letter-spacing: 0.4em;
        padding: 20px 10px;
        margin-top: 586px;
    }

    .flow-diagram-row-2 {
        height: 100%;
    }

    .flow-diagram-row-3 {
        text-align: center;
        margin-top: 40px;
    }

    .flow-diagram-row-3 .flow-diagram-content {
        width: 400px;
    }

    .flow-diagram-row-3 .content-1 {
        height: 44px;
        background-color: #c00000;
        color: #fff;
        border: 1px #404040 solid;
        padding: 10px;
    }

    .flow-diagram-row-3 .content-5 {
        height: 44px;
        background-color: #A9D08E;
        border: 1px #404040 solid;
        padding: 10px;
    }

    .flow-diagram-border-1 div:nth-child(1),
    .flow-diagram-border-2 div:nth-child(1) {
        border-right: 1px #909aaf solid;
        display: flex !important;
    }

    .flow-diagram-border-1 .border-b {
        border-bottom: 1px #909aaf solid;
    }

    .flow-diagram-border div div {
        width: 200px;
        height: 15px;
    }

    .flow-diagram-border div div:nth-child(3) {
        width: 100px;
        height: 15px;
    }

    .flow-diagram-row-3 .flow-diagram-title {
        background-color: #BFBFBF;
        border-bottom: 1px #404040 solid;
        padding: 10px;
    }

    .flow-diagram-row-3 .flow-diagram-menu .btn {
        border: 1px #404040 solid;
    }

    .flow-diagram-row-3 .content-2 {
        height: 222px;
        background-color: #FDCFEA;
        border: 1px #404040 solid;
    }

    .flow-diagram-row-3 .content-2 .btn {
        background-color: #FB9FD6;
        // border-radius: 0%;
    }

    .flow-diagram-row-3 .content-3 {
        height: 168px;
        background-color: #FFF2CC;
        border: 1px #404040 solid;
    }

    .flow-diagram-row-3 .content-3 .btn {
        background-color: #F6C700;
        // border-radius: 0%;
    }

    .flow-diagram-row-3 .content-4 {
        height: 222px;
        background-color: #D0D4F8;
        border: 1px #404040 solid;
    }

    .flow-diagram-row-3 .content-4 .btn {
        background-color: #8A94EE;
        // border-radius: 0%;
    }

    .flow-diagram-row-3 .flow-diagram-menu {
        display: flex;
        flex-flow: column;
        gap: 16px;
        padding: 16px;
    }

    .flow-diagram-menu button {
        width: 100%;
    }

    .flow-diagram-row-3 .flow-diagram-section-hint {
        margin-top: 60px;
    }

    .flow-diagram-row-3 .flow-diagram-section-hint .btn {
        margin-bottom: 24px;
        // border-radius: 0%;
    }

    .flow-diagram-row-4 {
        padding-top: 350px;
    }

    .flow-diagram-row-4 .flow-diagram-border {
        height: 20px;
        width: 22px;
        border-right: 1px #909aaf solid;
    }

    .flow-diagram-row-4 .flow-diagram-border:nth-child(1) {
        border-top: 1px #909aaf solid;
    }

    .flow-diagram-row-4 .flow-diagram-border:nth-child(3) {
        margin-top: -6px;
        border-bottom: 1px #909aaf solid;
    }

    .flow-diagram-row-4 .katudou {
        text-align: center;
        height: 411px;
        background-color: #5B9BD5;
        border: 1px #404040 solid;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        letter-spacing: 0.4em;
        display: inline-block;
        padding: 20px 10px;
        font-size: 1rem;
        font-weight: 400;
        color: #212529;
        text-decoration: none;
    }

</style>
