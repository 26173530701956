<template>
    <div>
        <div class="d-flex flex-row ms-4" :class="[margin_thin ? 'my-1' : 'my-3']">
            <div class="mx-1" :class="[ status_intro ? 'font-color-intro' : 'font-color-default' ]">●</div>
            <div class="mx-1" :class="[ status_basic ? 'font-color-basic' : 'font-color-default' ]">●</div>
            <div class="mx-1" :class="[ status_middle ? 'font-color-middle' : 'font-color-default' ]">●</div>
            <div class="mx-1" :class="[ status_high ? 'font-color-advanced' : 'font-color-default' ]">●</div>
            <div class="mx-1" :class="[ status_care_disaster ? 'font-color-care-disaster' : 'font-color-default' ]">●</div>
            <div class="mx-1" :class="[ status_care_disease ? 'font-color-care-disease' : 'font-color-default' ]" >●</div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
    setup(props) {
        const status_intro = ref(false);
        const status_basic = ref(false);
        const status_middle = ref(false);
        const status_high = ref(false);
        const status_care_disaster = ref(false);
        const status_care_disease = ref(false);
        const margin_thin = ref(false);
        
        onMounted(() => {
            status_intro.value = props.set_level.substring(0, 1) === '1' ? true : false;
            status_basic.value = props.set_level.substring(1, 2) === '1' ? true : false;
            status_middle.value = props.set_level.substring(2, 3) === '1' ? true : false;
            status_high.value = props.set_level.substring(3, 4) === '1' ? true : false;
            status_care_disaster.value = props.set_level.substring(4, 5) === '1' ? true : false;
            status_care_disease.value = props.set_level.substring(5, 6) === '1' ? true : false;

            if(props.margin_1){
                margin_thin.value = true;
            }
        })

        return {
            status_intro,
            status_basic,
            status_middle,
            status_high,
            status_care_disaster,
            status_care_disease,
            margin_thin,
        }
    },
    
    props: {
        set_level: {
            type: String,
        },
        margin_1: {
            type: Boolean,
        }
    }
}
</script>

<style lang="scss" scoped>
.font-color-default {
    color: #d9d9d9;
}

.font-color-intro {
    color: #8ea9db;
}

.font-color-basic {
    color: #4472c4;
}

.font-color-middle {
    color: #2353a4;
}

.font-color-advanced {
    color: #002060;
}

.font-color-care-disaster {
    color: #7cbf33;
}

.font-color-care-disease {
    color: #009900;
}

</style>