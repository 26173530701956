<template>
    <div>
        <div class="heading-orange mt-3">
            TOPページ
        </div>
        <levelIcon
            :set_level="setLevel"
        />
        
        <div class="row mt-5">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" @click="showSafety">
                            <div class="d-flex fill">
                                <i id="anpi"></i>
                                <span class="ps-3 flex-fill text-start">安否確認</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>災害発生時、{{ callReplaceWord('従業員') }}とその家族の安否情報を共有し、敏速な{{ callReplaceWord('組織') }}参集をお手伝いします。</span>
                                </div>
                            </template>
                        </VDropdown>

                    </div>
                </div>
            </div>
            <div class="mt-3 mt-md-0 col-md-5">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" @click="showStockpiles">
                            <div class="d-flex fill">
                                <i id="stockpiles"></i>
                                <span class="ps-3 flex-fill text-start">防災備蓄品リスト</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>管理者は購入した「防災備蓄品」を、ここから登録し、在庫管理を行います。<br>消費期限等のアラーム機能をご活用ください。</span>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mt-md-5">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" :disabled="!is('administrator')" @click="showBCP">
                            <div class="d-flex fill">
                                <i id="sakutei"></i>
                                <span class="ps-3 flex-fill text-start">BCP策定</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>［管理者専用］事業継続計画（BCP）の現状認識に始まり、入門、初級、中級、上級が策定できるほか、BCPの運用サイクルまでを管理できます。</span>
                                </div>
                            </template>
                        </VDropdown>

                    </div>
                </div>
            </div>
            <div class="col-md-5 mt-3 mt-md-0">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" :disabled="!is('administrator')" @click="showBnB">
                            <div class="d-flex fill">
                                <i id="matching"></i>
                                <span class="ps-3 flex-fill text-start">B&B災害応援ビジネスマッチング</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>［管理者専用］代替戦略の提携先を検索し、マッチング交渉後にB&B災害応援協定の締結を支援します。協定書の保管サービスもございます。</span>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mt-md-5">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" @click="showBCPBrowse">
                            <div class="d-flex fill">
                                <i id="etsuran"></i>
                                <span class="ps-3 flex-fill text-start">BCP閲覧</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>あなたの{{ callReplaceWord('組織') }}が策定したBCPを閲覧することができます。策定したBCPの印刷はここから行います。</span>
                                </div>
                            </template>
                        </VDropdown>

                    </div>
                </div>
            </div>
            <div class="col-md-5 mt-3 mt-md-0">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" @click="showFixation">
                            <div class="d-flex fill">
                                <i id="unyo"></i>
                                <span class="ps-3 flex-fill text-start">運用サイクル</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>訓練や教育を通じてBCPの文化を定着し、策定したBCPの診断を通じて改善点を確認することができます。</span>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mt-md-5">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-8">
                        <button type="button" class="btn btn-white w-100" @click="showBCPActivate">
                            <div class="d-flex fill">
                                <i id="hatsudo"></i>
                                <span class="ps-3 flex-fill text-start">BCP発動</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <span>［管理者専用］災害発生時、策定した@@自社@@のBCPをもとに事業継続対策をスタートします。{{ callReplaceWord('従業員') }}全員で発動メモを残します。</span>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-3">
            <div class="col-md-4 cursor-pointer" @click="showBCPBrowse">
                <i id="top1k_etsuran" v-if="is('administrator')" />
                <i id="top1r_etsuran" v-else />
            </div>
            <div class="col-md-4 cursor-pointer" @click="showFixation">
                <i id="top2_cycle" />
            </div>
            <div class="col-md-4 cursor-pointer" @click="showSafety">
                <i id="top3_anpi" />
            </div>
        </div>
        <div class="row mt-5 pb-5">
            <div class="col-md-4 cursor-pointer" @click="showMyPage">
                <i id="top4k_mypage" v-if="is('administrator')" />
                <i id="top4r_mypage" v-else />
            </div>
            <div class="col-md-4 cursor-pointer">
                <i id="top6_sakutei" v-if="is('administrator')" @click="showBCP" />
                <i id="top7r_hatsudo" @click="showBCPActivate" v-else />
            </div>
            <div class="col-md-4 cursor-pointer">
                <i id="top5_bbmatching" v-if="is('administrator')" @click="showBnB" />
            </div>
        </div>
        <div class="row mt-5 pb-5" v-if="is('administrator')" >
            <div class="col-md-4 cursor-pointer">
                <i id="top7k_hatsudo" @click="showBCPActivate"/>
            </div>
        </div> -->
        <div class="row mt-5">
            <div class="col-md-2 border-bottom-black">
                <label>お知らせ</label>
            </div>
        </div>
        <div class="row mt-3 pb-5" id="notice_area">
            <div v-for="notice in noticeList" :key="notice.id">
                <div class="row mt-3">
                    <div class="col-md-2 color-resilience-orange">{{ this.$root.dayjs(notice.created_at).format('YYYY年MM月DD日') }}</div>
                    <div class="col-md-10">{{ notice.title }}</div>
                </div>
                <div class="row mt-1">
                    <div>{{ notice.detail }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="d-flex">
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF('useManual')">
                        TOTONO-L　利用マニュアルダウンロード
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <a href="https://lin.ee/gw92o2E" target="_blank"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"></a>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const noticeList = ref([]);

        const setLevel = ref('111111');

        const getNotice = () => {
            const url = '/api/getAlNotice?cnt=' + 5;

            axios.get(url)
            .then(res => {
                noticeList.value = res.data;
            })
        }

        onMounted(() => {
            getNotice();
        });

        const showMyPage = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const showBCP = () => {
            const url = '/bcp/home';

            location.href = url;
        }

        const showBnB = () => {
            const url = '/bnb/home';
            
            location.href = url;
        }

        const showFixation = () => {
            const url = '/fixation/home';

            location.href = url;
        }

        const showSafety = () => {
            const url = '/safety/home';

            location.href = url;
        }

        const showBCPBrowse = () => {
            const url = '/browse/home';

            location.href = url;
        }

        const showBCPActivate = () => {
            const url = '/bcp/activate/1';

            location.href = url;
        }

        const showStockpiles = () => {
            const url = '/fixation/Bi?back=Home';

            location.href = url;
        }

        const downloadPDF = (kbn) => {
            const url_print = '/api/downloadDoc';

            let DocName;

            switch(kbn){
                case 'useManual':
                    DocName = 'TOTONO-L利用マニュアル';
                    break;
                default:
                    return;
            }

            axios.post(url_print, {
                'kbn': kbn,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = DocName + '.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            });

        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list);
        }

        return {
            setLevel,
            getNotice,
            noticeList,
            showMyPage,
            showBCP,
            showBnB,
            showSafety,
            showBCPBrowse,
            showFixation,
            showBCPActivate,
            showStockpiles,

            downloadPDF,

            callReplaceWord,
        }
    },
    props: {
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-white {
    border: 1px solid;
    color: #404040;
    background-color: white;
}

.btn-white:hover {
    border: 1px solid;
    color: white;
    border-color: #404040;
    background-color: #404040;
}

#sakutei {
    background-image: url('../../../svg/home_button/top_sakutei.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#unyo {
    background-image: url('../../../svg/home_button/top_unyo.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#anpi {
    background-image: url('../../../svg/home_button/top_anpi.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#etsuran {
    background-image: url('../../../svg/home_button/top_etsuran.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#hatsudo {
    background-image: url('../../../svg/home_button/top_hatsudo.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#matching {
    background-image: url('../../../svg/home_button/top_matching.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#stockpiles {
    background-image: url('../../../svg/home_button/top_stockpiles.svg');
    display: inline-flex;
    width: 23px;
    height: 25px;
    background-size: 23px auto;
    background-repeat: no-repeat;
}

#top1k_etsuran {
    background-image: url('../../../svg/al_top_button/top1k_etsuran.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top1r_etsuran {
    background-image: url('../../../svg/al_top_button/top1r_etsuran.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top2_cycle {
    background-image: url('../../../svg/al_top_button/top2_cycle.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top3_anpi {
    background-image: url('../../../svg/al_top_button/top3_anpi.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top4k_mypage {
    background-image: url('../../../svg/al_top_button/top4k_mypage.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top4r_mypage {
    background-image: url('../../../svg/al_top_button/top4r_mypage.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top5_bbmatching {
    background-image: url('../../../svg/al_top_button/top5_bbmatching.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top6_sakutei {
    background-image: url('../../../svg/al_top_button/top6_sakutei.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top7k_hatsudo {
    background-image: url('../../../svg/al_top_button/top7k_hatsudo.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#top7r_hatsudo {
    background-image: url('../../../svg/al_top_button/top7r_hatsudo.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#notice_area {
    overflow: scroll;
    height: 21em;
}

.btn-pdf-download {
    width:100%;
    padding-left: 20px;
    padding-top: 30px;
    font-size: 1.5rem;
}

</style>