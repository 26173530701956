<template>
    <div v-show="mode==='回答'">
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
                現状認識
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="text">
            <label class="ms-4 mt-3">初めてのBCP策定に入る前に、あなたの{{ callReplaceWord('組織') }}の現在の「事業継続能力」を診断してみましょう。下記のチェックリストの設問ごとに、「はい」／「いいえ」でありのままをご回答ください。</label>
        </div>

        <div class="text">
            <label class="ms-4 mt-3 text-orange" style="font-size:14pt">BCP取組状況チェックリスト ――――――――――――――――――――――――――――――――――――――――――</label>
        </div>

        <div class="row ms-4 mt-5">
            <div class="p2 col-md-1 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 d-flex align-items-center py-2">
                設問
            </div>
            <div class="p2 col-md-1 bg-white border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                はい
            </div>
            <div class="p2 col-md-1 bg-white border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                いいえ
            </div>
            <div class="p2 col-md-1 bg-white border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                不明
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-11 bg-human-resource border border-dark border-top-0 d-flex align-items-center py-2">
                ⦿&emsp;人的資源
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                1
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                緊急事態発生時に、支援が到着するまでの{{ callReplaceWord('従業員') }}の安全や健康を確保するための災害対応計画を作成していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_human_resource.q1" :class="{'is-invalid': q1_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_human_resource.q1" :class="{'is-invalid': q1_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_human_resource.q1" :class="{'is-invalid': q1_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>災害対応計画とは、災害発生直後の{{ callReplaceWord('従業員') }}の安全確保、応急救護、安否確認など、あなたの{{ callReplaceWord('組織') }}が事業を継続させる上で必要な対応をいいます。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                2
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                災害が勤務時間中に起こった場合、勤務時間外に起こった場合、あなたの{{ callReplaceWord('組織') }}は{{ callReplaceWord('従業員') }}と連絡を取り合うことができますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_human_resource.q2" :class="{'is-invalid': q2_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_human_resource.q2" :class="{'is-invalid': q2_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_human_resource.q2" :class="{'is-invalid': q2_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>災害によってLINE Worksなどの通信も使えなくなる可能性があるので、災害用伝言ダイヤル171や携帯電話会社の災害用伝言板などはおさえておいた方が良いでしょう。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                3
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                緊急時に必要な{{ callReplaceWord('従業員') }}が出勤できない場合に、代行できる{{ callReplaceWord('従業員') }}を育成していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_human_resource.q3" :class="{'is-invalid': q3_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_human_resource.q3" :class="{'is-invalid': q3_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_human_resource.q3" :class="{'is-invalid': q3_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>どんな状況であれ{{ callReplaceWord('従業員') }}が緊急時に必要な{{ callReplaceWord('従業員') }}の代わりをできるかという点で回答をしてください。<br />代わりができなくても事業継続に関して問題なければ大丈夫です。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                4
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                定期的に避難訓練や初期救急、心肺蘇生法の訓練を実施していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_human_resource.q4" :class="{'is-invalid': q4_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_human_resource.q4" :class="{'is-invalid': q4_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_human_resource.q4" :class="{'is-invalid': q4_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>火災や災害などを想定した避難訓練、消防本部によって行われる救命講習、日本赤十字社の救急法基礎講習などがあります。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-11 bg-material-resource border border-dark border-top-0 d-flex align-items-center py-2">
                ⦿&emsp;物的資源（モノ）
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                5
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}の建物は、地震や風水害に耐えることができますか? そして、建物内にある設備は地震や風水害から保護されますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_material_resource.q5" :class="{'is-invalid': q5_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_material_resource.q5" :class="{'is-invalid': q5_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_material_resource.q5" :class="{'is-invalid': q5_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>地震については、1981年に耐震基準が大幅に改正されているため、それ以前の建物では耐震性が低いと思われます。<br />水害・土砂災害については、ハザードマップで浸水深と浸水時間、土砂災害特別警戒区域、土砂災害警戒区域に指定されているかどうかを確認してみてください。<br />その上で、現状の対策はその被害に対応できているかを回答してください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                6
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}周辺の地震や風水害の被害に関する危険性を把握していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_material_resource.q6" :class="{'is-invalid': q6_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_material_resource.q6" :class="{'is-invalid': q6_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_material_resource.q6" :class="{'is-invalid': q6_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>ハザードマップ等で地震の活断層は何度や、洪水や内水氾濫時の浸水深を把握しているかどうかで回答してください。<br />ハザードマップは、水害・土砂災害・津波などについては国土交通省の重ねるハザードマップ（但し，中小河川は含まれないことがあります）、都道府県によるweb gisシステムを使ったハザードマップ（○○県，水害・土砂災害・地震，gisなどで検索するとヒットします。<br />こちらは中小河川が含まれる場合もあります。利用上の注意や用いているデータなどを確認してください）があります。都道府県のweb gisで見つからない災害は、市町村のホームページにpdfで掲載されていることが多いです。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                7
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}の設備の流動を管理し、目録を更新していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="はい" v-model="answer_material_resource.q7" :class="{'is-invalid': q7_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="いいえ" v-model="answer_material_resource.q7" :class="{'is-invalid': q7_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio" class="form-check-input" value="不明" v-model="answer_material_resource.q7" :class="{'is-invalid': q7_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>機械設備、パソコン等の設備の購入時期、契約内容等を一覧にし、その情報更新のことです。<br />被害が起きたときに、どうすべきかを判断できる状況を把握しているかということです。社内の業務データは含まれません。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4" v-if="isManufacture">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                8
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}の製造拠点が操業できなくなる、{{ callReplaceWord('仕入先') }}からの{{ callReplaceWord('原材料') }}の{{ callReplaceWord('納入') }}がストップする等の場合に備えて、代替で{{ callReplaceWord('生産') }}や調達する手段を準備していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_material_resource.q81" :class="{'is-invalid': q81_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_material_resource.q81" :class="{'is-invalid': q81_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_material_resource.q81" :class="{'is-invalid': q81_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>事業を継続するための収入を確保する手段をさします。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4" v-else>
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                8
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}の仕入先からの納入や{{ callReplaceWord('取引先') }}からの供給がストップする等の場合に備えて、代替で調達する手段を準備していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_material_resource.q82" :class="{'is-invalid': q82_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_material_resource.q82" :class="{'is-invalid': q82_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_material_resource.q82" :class="{'is-invalid': q82_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>事業を継続するための収入を確保する手段をさします。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-11 bg-financial-resource border border-dark border-top-0 d-flex align-items-center py-2">
                ⦿&emsp;物的資源（カネ）
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                9
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                1週間又は 1ヶ月程度、{{ callReplaceWord('事業') }}を中断した際の損失を把握していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_financial_resource.q9" :class="{'is-invalid': q9_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_financial_resource.q9" :class="{'is-invalid': q9_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_financial_resource.q9" :class="{'is-invalid': q9_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>「事業継続ができるか」を判断するための情報として、事業を中断した際の損失を把握しているかという点で回答をしてください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                10
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたは、災害後に{{ callReplaceWord('事業') }}を再開させる上で現在の保険の損害補償範囲が適切であるかどうかを決定するために保険の専門家と相談しましたか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_financial_resource.q10" :class="{'is-invalid': q10_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_financial_resource.q10" :class="{'is-invalid': q10_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_financial_resource.q10" :class="{'is-invalid': q10_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>「事業継続ができるか」を判断するための情報を把握しているかということです。<br />例えば、保険の補償額でどれくらいの期間事業継続可能かどうかを把握し、復旧できる期間に必要な金額が補償される内容の保険に加入しているかどうか等です。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                11
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                事前の災害対策や被災時復旧を目的とした融資制度を把握していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_financial_resource.q11" :class="{'is-invalid': q11_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_financial_resource.q11" :class="{'is-invalid': q11_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_financial_resource.q11" :class="{'is-invalid': q11_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>「事業継続ができるか」を判断するための情報を把握しているかということです。<br />事前の災害対策に対する支援制度として防災対策支援貸付制度、中小企業組合等活路開拓事業、社会環境対応施設整備資金等や、緊急事態発生後の支援制度として小規模企業共済災害時貸付、特別相談窓口、既往債務の返済条件緩和等があります。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                12
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                1ヶ月分程度の{{ callReplaceWord('事業') }}運転資金に相当する額のキャッシュフローを確保していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_financial_resource.q12" :class="{'is-invalid': q12_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_financial_resource.q12" :class="{'is-invalid': q12_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_financial_resource.q12" :class="{'is-invalid': q12_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>「事業継続ができるか」を判断するための情報として、キャッシュフローを確保しているかという点で回答をしてください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-11 bg-information-resource border border-dark border-top-0 d-flex align-items-center py-2">
                ⦿&emsp;物的資源（情報）
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                13
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                情報のコピー又はバックアップをとっていますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_information_resource.q13" :class="{'is-invalid': q13_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_information_resource.q13" :class="{'is-invalid': q13_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_information_resource.q13" :class="{'is-invalid': q13_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>情報をコピーして保管することによりデータ損失、情報漏えいのリスクを低減させ、災害発生時のデータ復旧を可能とします。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                14
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}のオフィス以外の場所に情報のコピーまたはバックアップを保管していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_information_resource.q14" :class="{'is-invalid': q14_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_information_resource.q14" :class="{'is-invalid': q14_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_information_resource.q14" :class="{'is-invalid': q14_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>バックアップを同じ場所に置いていたら、災害時に消失する可能性が高いので、被災してもデータが消失しない対策（クラウド等の物理的に別の場所に保管等）をとっているかを回答をしてください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                15
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                主要{{ callReplaceWord('顧客') }}や各種公共機関の連絡先リストを作成する等、緊急時に情報を発信・収集する手段を準備していますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_information_resource.q15" :class="{'is-invalid': q15_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_information_resource.q15" :class="{'is-invalid': q15_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_information_resource.q15" :class="{'is-invalid': q15_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>災害時に、必要な相手方に必要な連絡をおこなえるようにしているかということです。（ただし，どんな状況でも連絡が取れるかどうかまでは問うていません。<br />どういう状況であれば連絡が取れ、どういう状況では連絡が取れないかを把握し、それで事業継続上クリティカルな状況に陥らなければ問題ありません）</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                16
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                操業に不可欠なIT機器システムが故障等で使用できない場合の代替方法がありますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_information_resource.q16" :class="{'is-invalid': q16_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_information_resource.q16" :class="{'is-invalid': q16_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_information_resource.q16" :class="{'is-invalid': q16_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>例えば、クラウド等別の場所に情報が保管してあり、代替機器にてアクセス可能なことをいいます。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-11 bg-system border border-dark border-top-0 d-flex align-items-center py-2">
                ⦿&emsp;体制等
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                17
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                あなたの{{ callReplaceWord('組織') }}が自然災害や人的災害に遭遇した場合、{{ callReplaceWord('組織') }}の{{ callReplaceWord('事業') }}活動がどうなりそうかを考えたことがありますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_system.q17" :class="{'is-invalid': q17_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_system.q17" :class="{'is-invalid': q17_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_system.q17" :class="{'is-invalid': q17_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>対策を考える際には、災害イメージを持つことがとても大事です。どういう状況になるかをイメージすることで、実効的な対策を考えやすくなります。考えたことが無い場合は一度考えてみて下さい。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                18
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                緊急事態に遭遇した場合、あなたの{{ callReplaceWord('組織') }}のどの{{ callReplaceWord('事業') }}を優先的に継続・復旧すべきであり、そのためには何をすべきか考え、実際に何らかの対策を打っていますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_system.q18" :class="{'is-invalid': q18_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_system.q18" :class="{'is-invalid': q18_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_system.q18" :class="{'is-invalid': q18_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>あなたの{{ callReplaceWord('組織') }}の重要業務を把握し、事業継続・復旧の対策として施設の耐震化、防災用具の購入、防災に関する従業員教育、避難計画の作成等を行ったり、代替生産先を決めているなどをさします。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                19
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                {{ callReplaceWord('経営者') }}であるあなたが出張中だったり、負傷したりした場合、代わりの者が指揮をとる体制が整っていますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_system.q19" :class="{'is-invalid': q19_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_system.q19" :class="{'is-invalid': q19_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_system.q19" :class="{'is-invalid': q19_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>災害時において指揮命令の組織体制が出来ているかを問うています。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                20
            </div>
            <div class="p2 col-md-7 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center py-2">
                {{ callReplaceWord('取引先') }}及び同じ{{ callReplaceWord('組織') }}等と災害発生時の相互支援について取り決めていますか？
            </div>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="はい" v-model="answer_system.q20" :class="{'is-invalid': q20_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="いいえ" v-model="answer_system.q20" :class="{'is-invalid': q20_has_error}">
            </label>
            <label class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                <input type="radio"  class="form-check-input" value="不明" v-model="answer_system.q20" :class="{'is-invalid': q20_has_error}">
            </label>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>災害発生時に、お互いの情報を共有し、物資・場所・設備等の提供をするなど復旧支援活動を実施する相互支援の取り決めのことをいいます。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="text">
            <label class="ms-4 mt-5 text-orange " style="font-size:14pt">結果集計</label>
        </div>

        <div class="row ms-4 mt-3">
            <div class="p2 col-md-2 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 col-md-1 bg-human-resource border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                人的資源
            </div>
            <div class="p2 col-md-2 bg-material-resource border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                物的資源（モノ）
            </div>
            <div class="p2 col-md-2 bg-financial-resource border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                物的資源（カネ）
            </div>
            <div class="p2 col-md-2 bg-information-resource border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                物的資源（情報）
            </div>
            <div class="p2 col-md-1 bg-system border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                体制等
            </div>
            <div class="p2 col-md-1 bg-white border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                合計
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-2 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                「はい」の回答数
            </div>
            <div class="p2 col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_human_resource_yes_count }} / 4
            </div>
            <div class="col-md-2 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_material_resource_yes_count }} / 4
            </div>
            <div class="col-md-2 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_financial_resource_yes_count }} / 4
            </div>
            <div class="col-md-2 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_information_resource_yes_count }} / 4
            </div>
            <div class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_system_yes_count }} / 4
            </div>
            <div class="col-md-1 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_summary_count }} / 20
            </div>
        </div>

        <div class="p2 ms-4 mt-5 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black px-3" value="判定結果を確認します" @click="showResult">
        </div>
    </div>
    <div v-show="mode==='結果'">
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
                現状認識
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="text">
            <label class="ms-4 mt-5 text-orange" style="font-size:14pt">「はい」の回答数</label>
        </div>

            
        <div class="row ms-4 mt-3">
            <div class="p2 col-md-2 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 col-md-2 bg-white border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                合計
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-2 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                「はい」の回答数
            </div>
            <div class="col-md-2 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                {{ answer_summary_count }} / 20
            </div>
        </div>

        <div class="text">
            <label class="ms-4 mt-5 text-orange" style="font-size:14pt">診断結果</label>
        </div>

        <div class="row ms-4 mt-3" v-if="answer_summary_count < 4">
            <div class="p2 col-md-2 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
                3個以下の方
            </div>
            <div class="col-md-8 bg-human-resource border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                <label class="form-label">今、緊急事態に遭遇したら、あなたの{{ callReplaceWord('組織') }}の事業は長期間停止し、廃業に追い込まれるおそれが大です。 <span class="fw-bold">中小企業BCP策定運用指針</span>に沿って制作した本サイトに従い、一からBCPの策定・運用に取り組んで下さい。早急にできることから始めて下さい。</label>
            </div>
        </div>

        <div class="row ms-4 mt-5" v-else-if="answer_summary_count < 16">
            <div class="p2 col-md-2 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
                15個以下の方
            </div>
            <div class="col-md-8 bg-financial-resource border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                <label class="form-label">緊急時に備える意識は高いようですが、まだまだ改善すべき点が多いといえます。 <span class="fw-bold">中小企業BCP策定運用指針</span>に沿って制作した本サイトに従い、実践的なBCPを策定し、平常時から運用を進めることが必要です。</label>
            </div>
        </div>

        <div class="row ms-4 mt-5" v-else>
            <div class="p2 col-md-2 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
                16個以上の方
            </div>
            <div class="col-md-8 bg-system border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                <label class="form-label">あなたの{{ callReplaceWord('組織') }}では、BCPの考え方に則った取組みが進んでいるようです。 <span class="fw-bold">中小企業BCP策定運用指針</span>に沿って制作した本サイトに従い、{{ callReplaceWord('組織') }}のBCPをチェックし、より強力なものとすることが望まれます。</label>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-8 d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 ms-4 mt-3 col-md-2 d-flex align-items-center">
                <input type="button" class="btn btn-secondary btn-sm" value="その他の診断結果" @click="showCommentary">
            </div>
        </div>

        <div class="p2 ms-4 mt-3 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-secondary btn-sm" value="BCP策定ページに進みます" @click="showBCPIntroduction">
        </div>

    </div>
    <div id="commentary-modal" v-show="modalCommentaryVisible" @click.self="closeCommentaryModal">
        <div id="commentary-content">
            <div class="row ms-4 mt-5">
                <div class="p2 col-md-2 bg-white border border-dark d-flex align-items-center justify-content-center py-2">
                    3個以下の方
                </div>
                <div class="col-md-8 bg-white border border-dark border-start-0 d-flex align-items-center justify-content-center py-2">
                    <label class="form-label">今、緊急事態に遭遇したら、あなたの{{ callReplaceWord('組織') }}の事業は長期間停止し、廃業に追い込まれるおそれが大です。<span class="fw-bold">中小企業BCP策定運用指針</span>に沿って制作した本サイトに従い、一からBCPの策定・運用に取り組んで下さい。早急にできることから始めて下さい。</label>
                </div>
            </div>

            <div class="row ms-4">
                <div class="p2 col-md-2 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                    15個以下の方
                </div>
                <div class="col-md-8 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                    <label class="form-label">緊急時に備える意識は高いようですが、まだまだ改善すべき点が多いといえます。 <span class="fw-bold">中小企業BCP策定運用指針</span>に沿って制作した本サイトに従い、実践的なBCPを策定し、平常時から運用を進めることが必要です。</label>
                </div>
            </div>

            <div class="row ms-4">
                <div class="p2 col-md-2 bg-white border border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                    16個以上の方
                </div>
                <div class="col-md-8 bg-white border border-dark border-start-0 border-top-0 d-flex align-items-center justify-content-center py-2">
                    <label class="form-label">あなたの{{ callReplaceWord('組織') }}では、BCPの考え方に則った取組みが進んでいるようです。 <span class="fw-bold">中小企業BCP策定運用指針</span>に沿って制作した本サイトに従い、{{ callReplaceWord('組織') }}のBCPをチェックし、より強力なものとすることが望まれます。</label>
                </div>
            </div>

            <div class="p2 ms-4 mt-5 col-md-2 d-flex align-items-center">
                <input type="button" class="btn btn-black btn-sm" value="戻る" @click="closeCommentaryModal">
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.answer_info){
                props.answer_info.forEach(obj => {
                    const columnName = obj.column_name;
                    const value = obj.value;

                    switch(columnName){
                        case 'q1':
                        case 'q2':
                        case 'q3':
                        case 'q4':
                            answer_human_resource.value[columnName] = value;
                            break;
                        case 'q5':
                        case 'q6':
                        case 'q7':
                        case 'q81':
                        case 'q82':
                            answer_material_resource.value[columnName] = value;
                            break;
                        case 'q9':
                        case 'q10':
                        case 'q11':
                        case 'q12':
                            answer_financial_resource.value[columnName] = value;
                            break;
                        case 'q13':
                        case 'q14':
                        case 'q15':
                        case 'q16':
                            answer_information_resource.value[columnName] = value;
                            break;
                        case 'q17':
                        case 'q18':
                        case 'q19':
                        case 'q20':
                            answer_system.value[columnName] = value;
                            break;
                    }
                });
            }
        });

        const setLevel = ref('100011');

        const mode = ref('回答');
        
        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const answer_human_resource = ref({
            'q1': '',
            'q2': '',
            'q3': '',
            'q4': '',
        });

        const answer_human_resource_yes_count = computed(() => {
            return Object.values(answer_human_resource.value).filter(val => {
                return val === 'はい'
            }).length;
        });

        const answer_material_resource = ref({
            'q5': '',
            'q6': '',
            'q7': '',
            'q81': '',
            'q82': '',
        });

        const answer_material_resource_yes_count = computed(() => {
            return Object.values(answer_material_resource.value).filter(val => {
                return val === 'はい'
            }).length;
        });

        const answer_financial_resource = ref({
            'q9': '',
            'q10': '',
            'q11': '',
            'q12': '',
        });

        const answer_financial_resource_yes_count = computed(() => {
            return Object.values(answer_financial_resource.value).filter(val => {
                return val === 'はい'
            }).length;
        });

        const answer_information_resource = ref({
            'q13': '',
            'q14': '',
            'q15': '',
            'q16': '',
        });

        const answer_information_resource_yes_count = computed(() => {
            return Object.values(answer_information_resource.value).filter(val => {
                return val === 'はい'
            }).length;
        });

        const answer_system = ref({
            'q17': '',
            'q18': '',
            'q19': '',
            'q20': '',
        });

        const answer_system_yes_count = computed(() => {
            return Object.values(answer_system.value).filter(val => {
                return val === 'はい'
            }).length;
        });

        const answer_summary_count = computed(() => {
            return answer_human_resource_yes_count.value + answer_material_resource_yes_count.value + answer_financial_resource_yes_count.value + answer_information_resource_yes_count.value + answer_system_yes_count.value;
        });

        const manufacturing_code = ref([
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
        ]);

        const isManufacture = computed(() => {
            return manufacturing_code.value.includes(props.corp_info.業種.slice(0,2));
        });

        const showResult = () => {
            q1_has_error.value = false;
            if(answer_human_resource.value.q1 === ""){
                q1_has_error.value = true;
                alert('設問1を選択してください。');
                return;
            }

            q2_has_error.value = false;
            if(answer_human_resource.value.q2 === ""){
                q2_has_error.value = true;
                alert('設問2を選択してください。');
                return;
            }

            q3_has_error.value = false;
            if(answer_human_resource.value.q3 === ""){
                q3_has_error.value = true;
                alert('設問3を選択してください。');
                return;
            }

            q4_has_error.value = false;
            if(answer_human_resource.value.q4 === ""){
                q4_has_error.value = true;
                alert('設問4を選択してください。');
                return;
            }

            q5_has_error.value = false;
            if(answer_material_resource.value.q5 === ""){
                q5_has_error.value = true;
                alert('設問5を選択してください。');
                return;
            }

            q6_has_error.value = false;
            if(answer_material_resource.value.q6 === ""){
                q6_has_error.value = true;
                alert('設問6を選択してください。');
                return;
            }

            q7_has_error.value = false;
            if(answer_material_resource.value.q7 === ""){
                q7_has_error.value = true;
                alert('設問7を選択してください。');
                return;
            }

            q81_has_error.value = false;
            q82_has_error.value = false;
            if(isManufacture.value){
                if(answer_material_resource.value.q81 === ""){
                    q81_has_error.value = true;
                    alert('設問8を選択してください。');
                    return;
                }
            }else{
                if(answer_material_resource.value.q82 === ""){
                    q82_has_error.value = true;
                    alert('設問8を選択してください。');
                    return;
                }
            }

            q9_has_error.value = false;
            if(answer_financial_resource.value.q9 === ""){
                q9_has_error.value = true;
                alert('設問9を選択してください。');
                return;
            }

            q10_has_error.value = false;
            if(answer_financial_resource.value.q10 === ""){
                q10_has_error.value = true;
                alert('設問10を選択してください。');
                return;
            }

            q11_has_error.value = false;
            if(answer_financial_resource.value.q11 === ""){
                q11_has_error.value = true;
                alert('設問11を選択してください。');
                return;
            }

            q12_has_error.value = false;
            if(answer_financial_resource.value.q12 === ""){
                q12_has_error.value = true;
                alert('設問12を選択してください。');
                return;
            }

            q13_has_error.value = false;
            if(answer_information_resource.value.q13 === ""){
                q13_has_error.value = true;
                alert('設問13を選択してください。');
                return;
            }

            q14_has_error.value = false;
            if(answer_information_resource.value.q14 === ""){
                q14_has_error.value = true;
                alert('設問14を選択してください。');
                return;
            }

            q15_has_error.value = false;
            if(answer_information_resource.value.q15 === ""){
                q15_has_error.value = true;
                alert('設問15を選択してください。');
                return;
            }

            q16_has_error.value = false;
            if(answer_information_resource.value.q16 === ""){
                q16_has_error.value = true;
                alert('設問16を選択してください。');
                return;
            }

            q17_has_error.value = false;
            if(answer_system.value.q17 === ""){
                q17_has_error.value = true;
                alert('設問17を選択してください。');
                return;
            }

            q18_has_error.value = false;
            if(answer_system.value.q18 === ""){
                q18_has_error.value = true;
                alert('設問18を選択してください。');
                return;
            }

            q19_has_error.value = false;
            if(answer_system.value.q19 === ""){
                q19_has_error.value = true;
                alert('設問19を選択してください。');
                return;
            }

            q20_has_error.value = false;
            if(answer_system.value.q20 === ""){
                q20_has_error.value = true;
                alert('設問20を選択してください。');
                return;
            }

            const url = '/api/bcp/postBCPCheck';
            axios.post(url, {
                id: props.corp_info.id,
                answer_list: { ...answer_financial_resource.value, ...answer_human_resource.value, ...answer_material_resource.value, ...answer_information_resource.value, ...answer_system.value },
            });

            mode.value = '結果';

        }

        const q1_has_error = ref(false);
        const q2_has_error = ref(false);
        const q3_has_error = ref(false);
        const q4_has_error = ref(false);
        const q5_has_error = ref(false);
        const q6_has_error = ref(false);
        const q7_has_error = ref(false);
        const q81_has_error = ref(false);
        const q82_has_error = ref(false);
        const q9_has_error = ref(false);
        const q10_has_error = ref(false);
        const q11_has_error = ref(false);
        const q12_has_error = ref(false);
        const q13_has_error = ref(false);
        const q14_has_error = ref(false);
        const q15_has_error = ref(false);
        const q16_has_error = ref(false);
        const q17_has_error = ref(false);
        const q18_has_error = ref(false);
        const q19_has_error = ref(false);
        const q20_has_error = ref(false);

        const modalCommentaryVisible = ref(false);

        const closeCommentaryModal = () => {
            modalCommentaryVisible.value = false;
        }

        const showCommentary = () => {
            modalCommentaryVisible.value = true;
        }

        const showBCPIntroduction = () => {
            const url = '/bcp/bcp_introduction/1';

            location.href = url;
        }

        return {
            setLevel,
            mode,
            callReplaceWord,

            manufacturing_code,

            answer_human_resource,
            answer_human_resource_yes_count,

            answer_material_resource,
            answer_material_resource_yes_count,

            answer_financial_resource,
            answer_financial_resource_yes_count,

            answer_information_resource,
            answer_information_resource_yes_count,

            answer_system,
            answer_system_yes_count,

            answer_summary_count,

            isManufacture,

            showResult,

            q1_has_error,
            q2_has_error,
            q3_has_error,
            q4_has_error,
            q5_has_error,
            q6_has_error,
            q7_has_error,
            q81_has_error,
            q82_has_error,
            q9_has_error,
            q10_has_error,
            q11_has_error,
            q12_has_error,
            q13_has_error,
            q14_has_error,
            q15_has_error,
            q16_has_error,
            q17_has_error,
            q18_has_error,
            q19_has_error,
            q20_has_error,

            modalCommentaryVisible,
            closeCommentaryModal,
            showCommentary,

            showBCPIntroduction,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        corp_info: {
            type: Object,
        },
        answer_info: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
.text-orange {
    color: #ff6600;
}

.bg-human-resource {
    background-color: #ffcccc;
}

.bg-material-resource {
    background-color: #f8cbad;
}

.bg-financial-resource {
    background-color: #ffffcc;
}

.bg-information-resource {
    background-color: #ccff99;
}

.bg-system {
    background-color: #ccecff;
}

#commentary-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#commentary-content {
    z-index: 2;
    width: 80%;
    padding: 5em;
    background: #f2f2f2;
}

</style>