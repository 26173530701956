<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">更新履歴一覧画面</div>
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>

            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">更新履歴</label></div>
        </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>日付</th>
                        <th>内容</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="history in history_list" :key="history.id">
                        <td class="col-form-label">{{ history.id }}</td>
                        <td class="col-form-label">{{ this.$root.dayjs(history.notice_date).format('YYYY-MM-DD') }}</td>
                        <td class="col-form-label">{{ history.detail }}</td>
                        <td>
                            <input type="button" class="btn btn-red px-3 " value="編集" @click="editHistory(history.id)" />
                            <input type="button" class="btn btn-black px-3 ms-5" value="削除" @click="deleteHistory(history.id)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        const back = () => {
            const url = '/s-admin/noticeHistoryLists';

            location.href = url;
        }

        const editHistory = (id) => {
            const url = '/s-admin/editHistory/' + id;

            location.href = url;
        }

        const deleteHistory = (id) => {
            if(!confirm('No.' + id + 'を削除してよろしいですか')){
                return;
            }

            const url = '/api/s-admin/deleteHistory';

            axios.delete(url, {data: {id: id}})
            .then(res => {
                const url = '/s-admin/showHistoryList';

                location.href = url;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        
        return {
            back,
            editHistory,
            deleteHistory,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,

            visibleFlashMessage,
        }
    },
    props: {
        history_list: {
            type: Array,
        }
    }
}
</script>

