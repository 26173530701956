<template>
    <div class="row justify-content-center" v-if="mode===1">
        <div class="row">
            <div class="col">
                <div class="d-flex flex-row my-3">
                    <btn class="btn btn-light px-5 py-2" @click="changeFamily">家族会員の連携はこちら</btn>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card border-color-resilience-black">
                <div class="card-body bg-resilience-black text-white">
                    <form @submit.prevent="post_form">
                        <div class="row mb-3">
                            <label for="member_id" class="col-md-4 col-form-label text-md-end">会員ID（会員番号）</label>

                            <div class="col-md-6">
                                <input id="member_id" type="text" class="form-control" :class="{'is-invalid': user_password_message.length > 0}" name="member_id" required autofocus placeholder="00000000000000001" v-model="member_id">
                                <span class="invalid-feedback" role="alert" v-show="member_id_message.length > 0">
                                    <strong>{{ member_id_message }}</strong>
                                </span>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="user_id" class="col-md-4 col-form-label text-md-end">利用者ID</label>

                            <div class="col-md-6">
                                <input id="user_id" type="text" class="form-control" :class="{'is-invalid': user_password_message.length > 0}" name="user_id" required placeholder="000001" v-model="user_id">
                                <span class="invalid-feedback" role="alert" v-show="user_id_message.length > 0">
                                    <strong>{{ user_id_message }}</strong>
                                </span>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="password" class="col-md-4 col-form-label text-md-end">利用者パスワード</label>

                            <div class="col-md-6">
                                <input id="password" type="password" class="form-control" :class="{'is-invalid': user_password_message.length > 0}" name="password" required v-model="user_password">
                                <span class="invalid-feedback" role="alert" v-show="user_password_message.length > 0">
                                    <strong>{{ user_password_message }}</strong>
                                </span>
                            </div>
                        </div>

                        <div class="row mb-5 mt-5">
                            <div class="col-md-8 offset-md-4">
                                <button type="submit" class="btn btn-gray me-5" :disabled="isProcessing"><i class="bi bi-lock-fill"></i><span class="ms-3">ログイン</span></button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="mode===2">
        <div class="card py-5">
            <div class="card-body">
                <div class="d-flex align-items-center flex-column">
                    <h3 class="card-title">LINEとTOTONO-Lとの連携処理が正常に行われました。</h3>
                    <div class="mt-3">
                        <input type="button" class="btn btn-resilience-red" value="TOTONO-LのHOME画面へ遷移" @click="showHome" />
                    </div>
                </div>
            </div>            
        </div>

    </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    setup(props) {
        const mode = ref(1);
        onMounted(() => {
            mode.value = 1;
        });

        const member_id = ref("");
        const member_id_message = ref("");
        const user_id = ref("");
        const user_id_message = ref("");
        const user_password = ref("");
        const user_password_message = ref("");

        const isProcessing = ref(false);

        const post_form = () => {
            user_password_message.value = "";

            const url = "/api/line/link";

            isProcessing.value = true;

            axios.post(url, {
                member_id: member_id.value,
                user_id: user_id.value,
                user_password: user_password.value,
                line_user_id: props.line_user_id,
            }).then(res => {
                mode.value = 2;
            }).catch(err => {
                user_password_message.value = "ログインに失敗しました。入力内容を再度確認してください。"
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showHome = () => {
            const url = "/home";

            location.href = url;
        }

        const changeFamily = () => {
            const url = "/line_family_link?line_id=" + props.line_user_id;

            location.href = url;
        }

        return {
            mode,
            member_id,
            member_id_message,
            user_id,
            user_id_message,
            user_password,
            user_password_message,

            isProcessing,

            post_form,

            showHome,
            
            changeFamily,
        }
    },
    props: {
        line_user_id: {
            type: String,
        },
    },
}
</script>
