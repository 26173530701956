<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜避難
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-4 border bg-light-gray py-2">
            ◯　避難計画シート｜屋外避難用
        </div>
    </div>

    <template v-for="(evacuationLocationData, index) in evacuationLocationsInfo" :key="index">
        <div class="row ms-3 mt-3">
            <div class="p2 col-md-12 border border-dark border-bottom-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray" >
            <div class="p2 ms-4 col-md-1 border border-dark bg-light-gray text-dark d-flex align-items-center justify-content-center py-2">
                {{ index + 1 }}
            </div>
            <div class="p2 ms-4 col-md-1 border border-danger bg-light-gray text-danger d-flex align-items-center justify-content-start py-2" style="font-size:14pt">
                災害想定
            </div>
            <div class="p2 col-md-7 border border-danger bg-danger text-white d-flex align-items-center justify-content-start py-2" style="font-size:14pt">
                {{ evacuationLocationData.disasterAssumptionContent }}
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-end justify-content-center">
                避難場所
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.evacuationLocation">
            </div>
            <div class="p2 col-md-2 d-flex align-items-center justify-content-start py-2">への避難計画</div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                ※　必要に応じて、上記場所までの地図を用意します。
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <label type="button" class="ms-4 col-md-6">
                <input type="file" style="display:none" @change="dropFile($event, index)"/>
                <div class="px-5 py-3 btn-new-window-button text-center"
                        @dragleave="dragLeave"
                        @dragover.prevent="dragEnter"
                        @drop.prevent="dropFile($event, index)"
                        :class="{enter: isEnter}"
                    >
                    <div class="h2">
                        避難場所の地図をアップロード
                    </div>
                    <div class="h4 mt-2">
                        拠点となる場所の地図データを{{ callReplaceWord('従業員') }}と共有する
                    </div>
                    <input id="submit_pdf" type="file" class="no-display" accept=".jpg, .jpeg" @change="dropFile($event, index)" />
                    <span v-if="!!evacuationLocationData.fileName">アップロードファイル：{{ evacuationLocationData.fileName }}</span>
                </div>
            </label>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                {{ callReplaceWord('組織') }}から避難が必要となった場合にするべき事項
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-10 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.refugeShouldBeMatter">
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                集合場所
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.setLocation">
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                集合場所責任者
            </div>
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                代理責任者
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <select name="selectAdminCandidate" class="form-select" v-model="evacuationLocationData.setLocationManagerName">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <select name="selectAdminCandidate" class="form-select" v-model="evacuationLocationData.setLocationSubManagerName">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                上記責任者の責務
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="row ms-3 col-md-10">
                <textarea type="text" rows="2" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.setLocationManagerDuty"></textarea>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                業務停止責任者
            </div>
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                代理責任者
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <select name="selectAdminCandidate" class="form-select" v-model="evacuationLocationData.businessStopManagerName">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <select name="selectAdminCandidate" class="form-select" v-model="evacuationLocationData.businessStopSubManagerName">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                上記責任者の責務
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="row ms-3 col-md-10">
                <textarea type="text" rows="2" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.businessStopManagerDuty"></textarea>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                避難解除責任者
            </div>
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                代理責任者
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <select name="selectAdminCandidate" class="form-select" v-model="evacuationLocationData.refugeCancellationManagerName">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <select name="selectAdminCandidate" class="form-select" v-model="evacuationLocationData.refugeCancellationSubManagerName">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                非常口の場所
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="row ms-3 col-md-6">
                <textarea type="text" rows="2" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.emergencyExitLocation"></textarea>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                避難訓練の実施回数
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-1 d-flex align-items-center justify-content-start py-2">
                <input type="Number" min="0" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.trainingImplementationTimes">
            </div>
            <div class="p2 mt-1 col-md-2 bg-light-gray text-dark d-flex align-items-start justify-content-start">
                回／年
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                備考
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="row ms-3 col-md-10">
                <textarea type="text" rows="5" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="evacuationLocationData.note"></textarea>
            </div>
        </div>
        <div class="row ms-3">
            <div class="p2 col-md-12 border border-dark border-top-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
        </div>
    </template>

<!--
    <div class="row mt-1">
        <div class="p2 ms-3 col-md-10 d-flex align-items-start justify-content-start">
        </div>
        <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-black text-white" value="ワンセット増やす">
        </div>
    </div> -->

    <div class="row mt-5">
        <div class="p2 ms-3 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜情報連絡 へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 ms-5 col-md-5 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 ms-3 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(async () => {
            // 避難場所
            evacuationLocationsInfo.splice(0);
            if (props.evacuation_locations_info.length > 0) {
                props.evacuation_locations_info.forEach(fetchData => {
                    evacuationLocationsInfo.push({
                        disasterAssumptionId: fetchData.disaster_assumption_id,
                        disasterAssumptionContent: fetchData.disaster_assumption_content,
                        evacuationLocation: fetchData.evacuation_location,
                        refugeShouldBeMatter: fetchData.refuge_should_be_matter,
                        setLocation: fetchData.set_location,
                        setLocationManagerName: fetchData.set_location_manager_name,
                        setLocationSubManagerName: fetchData.set_location_sub_manager_name,
                        setLocationManagerDuty: fetchData.set_location_manager_duty,
                        businessStopManagerName: fetchData.business_stop_manager_name,
                        businessStopSubManagerName: fetchData.business_stop_sub_manager_name,
                        businessStopManagerDuty: fetchData.business_stop_manager_duty,
                        refugeCancellationManagerName: fetchData.refuge_cancellation_manager_name,
                        refugeCancellationSubManagerName: fetchData.refuge_cancellation_sub_manager_name,
                        emergencyExitLocation: fetchData.emergency_exit_location,
                        trainingImplementationTimes: fetchData.training_implementation_times,
                        note: fetchData.note,
                        mapId: fetchData.map_id,
                        fileName: fetchData.name,
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const typeRefugeCancellation = 3;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const evacuationLocationsInfo = reactive([
            {
                disasterAssumptionId: '',
                disasterAssumptionContent: '',
                evacuationLocation: '',
                refugeShouldBeMatter: '',
                setLocation: '',
                setLocationManagerName: '',
                setLocationSubManagerName: '',
                setLocationManagerDuty: '',
                businessStopManagerName: '',
                businessStopSubManagerName: '',
                businessStopManagerDuty: '',
                refugeCancellationManagerName: '',
                refugeCancellationSubManagerName: '',
                emergencyExitLocation: '',
                trainingImplementationTimes: '',
                note: '',
                mapId: '',
                fileName: '',
            }
        ]);

        const isEnter = ref(false);
        const dragEnter = () => {
            isEnter.value = true;
        }
        const dragLeave = () => {
            isEnter.value = false;
        }

        // 地図のアップロード
        const files = ref([]);
        const dropFile = (e, index) => {
            isEnter.value = false;
            const fileList = e.target.files ? e.target.files : e.dataTransfer.files;

            if(fileList.length === 0){
                return false;
            }

            let files = [];
            for(let i = 0; i < fileList.length; i++){
                files.push(fileList[i]);
            }

            let file = files.length > 0 ? files[0] : [];
            if(file.type !== 'image/jpeg'){
                return false;
            }
            if(file.size > 10000000){
                return false;
            }

            evacuationLocationsInfo[index].fileName = file.name;
            const mapType = typeRefugeCancellation;

            let fd = new FormData();
            fd.append('file', file);
            fd.append('bcp_id', props.bcp_basic_frame_info.id);
            fd.append('version', props.bcp_basic_frame_info.version);
            fd.append('map_type', mapType);
            fd.append('map_id', evacuationLocationsInfo[index].mapId ?? '');

            const url = '/api/bcp/postBcpMapImage';
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(url, fd, config).then(res => {
                evacuationLocationsInfo[index].mapId = res.data.image_id
            }).finally(fin => {
                e.target.value = null;
            });
        }

        const showBack = async () => {
            // 避難場所を登録
            const result = await registerSecond1();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 避難場所を登録
            const result = await registerSecond1();

            if (result == '200') {
                const url = '/bcp/bcp_second/2';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 避難場所を登録
            const result = await registerSecond1();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 避難場所を登録
            const result = await registerSecond1();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 避難場所登録
        const registerSecond1 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond1';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'evacuation_locations_info': evacuationLocationsInfo,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            evacuationLocationsInfo,
            files,
            isEnter,
            dragEnter,
            dragLeave,
            dropFile,
            callReplaceWord,
            showBack,
            showNext,
            showPrevious,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        admin_candidate_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        evacuation_locations_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
.no-display {
    display: none;
}


</style>
