<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row title">
                <div class="p2 col-md">
                    <label class="form-label">目次構成</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">表紙</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">目次構成</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">1. 基本方針</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;BCPの基本方針</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">2. BCPの運用体制</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;BCPの策定・運用体制</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;{{ callReplaceWord('従業員') }}携帯カード</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;複数企業連携によるBCPの策定・運用体制</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式05</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="基本情報整理用" @click="showBrowse('J1')">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">3. 中核{{ callReplaceWord('事業') }}と復旧目標 </label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;中核{{ callReplaceWord('事業') }}に係る情報</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;中核{{ callReplaceWord('業務') }}の継続に必要な重要業務</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;中核事業影響度評価フォーム</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式07</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="基本情報整理用" @click="showBrowse('J2')">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;{{ callReplaceWord('事業') }}継続に係る各種資源の代替の情報</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　情報連絡の拠点となる場所</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　{{ callReplaceWord('事業') }}復旧を実施するための場所</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　応援要員</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　資金調達</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　通信手段・各種インフラ</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　中核事業に必要となる情報に関する情報</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">4. 財務診断と事前対策計画</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">財務診断</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <!-- <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="復旧費用の算定" @click="showBrowse('J3')">ページへ移動</button> -->
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;保険情報リスト</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;事前対策のための投資計画</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">5. 緊急時におけるBCP発動</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　発動フロー</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;災害ごとの発動フロー</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　避難</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;避難計画シート｜屋外避難用</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　情報連絡</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;主要組織の連絡先</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;従業員連絡リスト</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;・　基本情報整理用</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;・　従業員一覧</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;・　従業員個別用</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;&emsp;&emsp;&emsp;情報通信手段</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式13</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="基本情報整理用" @click="showBrowse('J4')">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;電話／FAX番号シート【{{ callReplaceWord('自社') }}用】</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;主要{{ callReplaceWord('顧客') }}情報</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;・　資源</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;中核事業に係るボトルネック資源</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;1　設備・機械・車両など</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;&emsp;&emsp;&emsp;2　コンピュータ機器とソフトウェア</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式16-2</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="基本情報整理用" @click="showBrowse('J5')">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;&emsp;&emsp;&emsp;3　その他の器具類</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式16-3</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="基本情報整理用" @click="showBrowse('J6')">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;中核事業に必要な供給品目情報</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;主要供給者・業者情報</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-secondary">&emsp;&emsp;&emsp;&emsp;災害対応用具チェックリスト</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;・　地域貢献</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;&emsp;&emsp;&emsp;地域貢献活動</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式20</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="基本情報整理用" @click="showBrowse('J7')">ページへ移動</button>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">6. 自己診断結果</label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;自己診断チェックリスト（中級・上級）</label>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <span class="bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">上級コース</span>
                </div>
                <div class="mt-2 mt-md-0 col-md-2 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" id="自己診断チェックリスト（中級・上級）" @click="showBrowse('C5')">ページへ移動</button>
                </div>
            </div>

            <br><br><br><br><br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/home';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/J1';
            location.href = url;
        }

        const showBrowse = (path) => {
            const url = `/browse/${path}`;
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            showBack,
            showNext,
            showBrowse,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.title {
    background-color: #f2f2f2;
}

.btn-next-page {
    border: 1px solid;
    color: white;
    border-color: #00c0bc;
    background-color: #00c0bc;
}

.btn-next-page:hover {
    border: 1px solid;
    color: #00c0bc;
    border-color: #00c0bc;
    background-color: white;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }
}
</style>
