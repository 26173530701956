<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式11</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 情報連絡</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 当社における中核事業の継続・復旧において重要な組織の連絡先には以下のものがある</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">主要組織の連絡先</label>
                </div>
            </div>
            <div class="row ms-md-3">
                <div v-for="(item, index) in mainCompanyInfo" class="card px-0 px-md-3" :class="{ 'mt-3' : index !== 0 }">
                    <div class="card-body">
                        <div class="d-md-none d-print-none">
                            <div class="row">
                                <div class="p2 col col-md-2 d-flex align-items-center justify-content-start">
                                    <label class="form-label">業種分類：</label>
                                    <div class="d-md-none form-label ms-3" v-if="is('administrator') || !item.isViewLimit">{{ displayIndustry(item.businessClassification, item.businessClassificationOther) }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-md-block d-print-block">
                            <div class="row">
                                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                                    <label class="form-label">業種分類：</label>
                                </div>
                                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio1" value="1" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio1" value="1" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">消防署</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio2" value="2" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio2" value="2" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">警察署</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio3" value="3" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio3" value="3" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">病院</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio4" value="4" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio4" value="4" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">電話会社</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio5" value="5" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio5" value="5" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">電力会社</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio6" value="6" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio6" value="6" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">ガス会社</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio7" value="7" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio7" value="7" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">水道事業者</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio8" value="8" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio8" value="8" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">金融機関</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio9" value="9" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio9" value="9" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">保険会社</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio10" value="10" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio10" value="10" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">監査法人</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio11" value="11" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio11" value="11" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">会計士</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio12" value="12" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio12" value="12" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">債権者</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio13" value="13" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio13" value="13" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">建物管理者</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio14" value="14" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio14" value="14" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">建物所有者</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio15" value="15" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio15" value="15" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label"> 建物警備会社</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                                    <div class="form-check form-check-inline flex-shrink-0">
                                        <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio16" value="16" onclick="return false;">
                                        <input v-else class="form-check-input" type="radio" :name="`inlineRadioOptions${index}`" id="inlineRadio16" value="16" onclick="return false;" v-model="item.businessClassification">
                                        <label class="form-check-label">その他</label>
                                    </div>
                                    <div class="form-control bg-info note">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.businessClassificationOther || '&emsp;' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="p2 col-md-4 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">法人名／サービス名</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.corporateName || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="p2 col-md-4 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">提供されるサービス内容</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.provisionService || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="p2 col-md-4 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">口座番号（必要な場合）</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.bankAccountNumber || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="p2 col-md-4 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">住所</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : `${item.prefecture || ''}${item.city || ''}${item.otherAddress || ''}` || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="p2 col-md-4 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">ホームページアドレス</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.url || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="p2 col-md-4 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">電話番号（代表）</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.representPhoneNumber || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="d-none d-md-block">
                            <div class="row">
                                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">部署</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactDivision || '&emsp;' }}</div>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">担当者</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPerson || '&emsp;' }}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">第1連絡先</label>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">電話番号</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPhoneNumber || '&emsp;' }}</div>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">携帯電話等</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactMobilePhoneNumber || '&emsp;' }}</div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">FAX番号</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactFax || '&emsp;' }}</div>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">E-mail</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactEmail || '&emsp;' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-md-none d-print-none">
                            <div class="row">
                                <div class="col border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">第1連絡先</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1 border border-top-0 border-end-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                </div>
                                <div class="col px-0">
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">部署</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactDivision || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">担当者</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPerson || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">電話番号</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPhoneNumber || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">携帯電話等</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactMobilePhoneNumber || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">FAX番号</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactFax || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">E-mail</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactEmail || '&emsp;' }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="d-none d-md-block d-print-block">
                            <div class="row ">
                                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">部署</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactDivision || '&emsp;' }}</div>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">担当者</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPerson || '&emsp;' }}</div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">第2連絡先</label>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">電話番号</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPhoneNumber || '&emsp;' }}</div>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">携帯電話等</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactMobilePhoneNumber || '&emsp;' }}</div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">FAX番号</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactFax || '&emsp;' }}</div>
                                </div>
                                <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                                    <label class="form-label">E-mail</label>
                                </div>
                                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                    <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactEmail || '&emsp;' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-md-none d-print-none">
                            <div class="row">
                                <div class="col border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">第2連絡先</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1 border border-top-0 border-end-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                </div>
                                <div class="col px-0">
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">部署</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactDivision || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">担当者</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPerson || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">電話番号</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPhoneNumber || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">携帯電話等</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactMobilePhoneNumber || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">FAX番号</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactFax || '&emsp;' }}</div>
                                    </div>
                                    <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <label class="form-label ms-2">E-mail</label>
                                    </div>
                                    <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactEmail || '&emsp;' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="p2 col-md-4 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">備考</label>
                            </div>
                            <div class="p2 col-md-8 bg-info border d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.note || '&emsp;' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let mainCompanyInfo = reactive([]);

        const showBack = () => {
            const url = '/browse/K10';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K12';
            location.href = url;
        }

        const displayIndustry = (businessClassification, businessClassificationOther) => {
            switch(businessClassification){
                case "1": return "消防署";
                case "2": return "警察署";
                case "3": return "病院";
                case "4": return "電話会社";
                case "5": return "電力会社";
                case "6": return "ガス会社";
                case "7": return "水道事業者";
                case "8": return "金融機関";
                case "9": return "保険会社";
                case "10": return "監査法人";
                case "11": return "会計士";
                case "12": return "債権者";
                case "13": return "建物管理者";
                case "14": return "建物所有者";
                case "15": return "建物警備会社";
                case "16": return businessClassificationOther;
            }
        }

        if (props.main_company_info.length > 0) {
            mainCompanyInfo = props.main_company_info.map(
                (fetchData) => ({
                    mainCompanyInfoId: fetchData.id,
                    contactType: fetchData.contact_type,
                    isViewLimit: fetchData.is_view_limit === 1,
                    businessClassification: fetchData.business_classification,
                    businessClassificationOther: fetchData.business_classification_other,
                    provisionService: fetchData.provision_service,
                    corpId: fetchData.corp_id,
                    corporateName: fetchData.corporate_name,
                    bankAccountNumber: fetchData.bank_account_number,
                    prefecture: fetchData.prefecture,
                    city: fetchData.city,
                    otherAddress: fetchData.other_address,
                    url: fetchData.url,
                    representPhoneNumber: fetchData.represent_phone_number,
                    firstContactDivision: fetchData.first_contact_division,
                    firstContactPerson: fetchData.first_contact_person,
                    firstContactPhoneNumber: fetchData.first_contact_phone_number,
                    firstContactMobilePhoneNumber: fetchData.first_contact_mobile_phone_number,
                    firstContactFax: fetchData.first_contact_fax,
                    firstContactEmail: fetchData.first_contact_email,
                    secondContactDivision: fetchData.second_contact_division,
                    secondContactPerson: fetchData.second_contact_person,
                    secondContactPhoneNumber: fetchData.second_contact_phone_number,
                    secondContactMobilePhoneNumber: fetchData.second_contact_mobile_phone_number,
                    secondContactFax: fetchData.second_contact_fax,
                    secondContactEmail: fetchData.second_contact_email,
                    note: fetchData.note,
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            mainCompanyInfo,
            showBack,
            showNext,
            displayIndustry,
        }
    },
    props:{
        main_company_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}
.form-control {
    color: common_colors.colors(resilience-black);
}

.form-control:not(.note) {
    border: none;
}

.form-control.note {
    border-color: common_colors.colors(resilience-gray);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .form-control.note {
        border-color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }
}
</style>
