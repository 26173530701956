<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="row">
                <div class="h4 d-flex justify-content-center color-resilience-red">主な機能（TOTONO-Lが解決してくれること）</div>
            </div>
            <div class="row d-flex justify-content-center mb-2">
                <div class="h4 col-1 bg-color-white border-resilience-red d-flex justify-content-center color-resilience-red fw-bold">
                    6
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center my-3">
            <div class="h1 d-flex justify-content-center color-resilience-red fw-bold">
                法人も個人事業主も取組みやすい利用料設定
            </div>
        </div>
        <div class="row">
            <label class="color-resilience-red">より多くの会員がTOTONO-Lを利用していただくことで、ネットワーク効果を活かした「防災ビックデータ」の生成を実現します。</label>
        </div>
        <div class="relative d-flex justify-content-center py-3">
            <i id="explanation_image_1" class="explanation_image" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .explanation_image {
        display: inline-flex;
        width: 1100.341px;
        height: 583.67px;
        background-size: 1100.341px auto;
        background-repeat: no-repeat;

    }

    #explanation_image_1 {
        background-image: url('../../../svg/01-L6.svg');
    }

    @media (max-width: 1199px) {
        .explanation_image {
            display: inline-flex;
            width: 860px;
            height: 447.273px;
            background-size: 860px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 991px) {
        .explanation_image {
            display: inline-flex;
            width: 680px;
            height: 447.273px;
            background-size: 680px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 767px) {
        .explanation_image {
            display: inline-flex;
            width: 500px;
            height: 447.273px;
            background-size: 500px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 575px) {
        .explanation_image {
            display: inline-flex;
            width: 400px;
            height: 447.273px;
            background-size: 400px auto;
            background-repeat: no-repeat;
        }
    }
</style>
