<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card card-bg-gray">
        <div class="card-body">

            <div class="row title headline">
                <label class="form-label ps-md-3 col-3">目次構成</label>
            </div>

            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">表紙</label>
                </div>
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline">目次構成</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式01</label>
                        </div>
                    </div>
                </div>
                <!-- <label class="form-label headline ps-3 col-5">目次構成</label>
                <div class="col-4">
                    <span class="bg-bcp-basic text-light px-4 py-1 col-form-label">様式01</span>
                </div> -->
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline">1. 基本方針</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">BCPの基本方針</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式02</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK1">ページへ移動</button>
                </div>
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline">2. BCPの運用体制</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">BCPの策定・運用体制</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式03</label>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">{{ callReplaceWord('従業員') }}携帯カード</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式04</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK2">ページへ移動</button>
                </div>
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline">3. 中核{{ callReplaceWord('事業') }}と復旧目標</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">中核{{ callReplaceWord('事業') }}に係る情報</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式06-1</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK3">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">中核{{ callReplaceWord('業務') }}の継続に必要な重要業務</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式06-2</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">{{ callReplaceWord('事業') }}継続に係る各種資源の代替の情報</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式08</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column ">
                            <label class="form-label ms-3">・　情報連絡の拠点となる場所</label>
                            <label class="form-label ms-3">・　{{ callReplaceWord('事業') }}復旧を実施するための場所</label>
                            <label class="form-label ms-3">・　応援要員</label>
                            <label class="form-label ms-3">・　資金調達</label>
                            <label class="form-label ms-3">・　通信手段・各種インフラ</label>
                            <label class="form-label ms-3">・　中核事業に必要となる情報に関する情報</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK4a">ページへ移動</button>
                </div>
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline">4. 財務診断と事前対策計画</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">復旧費用の算定</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">財務診断</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column ">
                            <label class="form-label ms-3">緊急時に使える資金</label>
                            <label class="form-label ms-3">財務診断とキャッシュフロー対策</label>
                            <label class="form-label ms-3">損害保険の整理</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK5">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">事前対策のための投資計画</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式09</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK7">ページへ移動</button>
                </div>
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline">5. 緊急時におけるBCP発動</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">・　発動フロー</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式09-2</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column ms-3">
                            <label class="form-label ms-5">発動フローに沿った実施項目</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK8">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">・　避難</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式10</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column ms-3">
                            <label class="form-label ms-5">避難計画シート｜屋外避難用</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK10">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">・　情報連絡</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                </div>
            </div>
            <div class="row mt-1">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">主要組織の連絡先</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式11</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK11">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">従業員連絡リスト</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式12-3</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column ms-3">
                            <label class="form-label ms-5">・　基本情報整理用</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK12">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">電話／FAX番号シート【{{ callReplaceWord('自社') }}用】</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式14</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK13">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">主要{{ callReplaceWord('顧客') }}情報</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式15</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK14">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3">・　資源</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">中核事業に係るボトルネック資源</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">1　設備・機械・車両など</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式16-1</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK15">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">中核事業に必要な供給品目情報</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式17-1</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK16">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">主要供給者・業者情報【供給品目別】</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式17-2</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK17">ページへ移動</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ps-3 ms-5">災害対応用具チェックリスト</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式19</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK18">ページへ移動</button>
                </div>
            </div>

            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label headline font-color-light-gray">6. 自己診断結果</label>
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="ps-md-3 col-12 col-md-7">
                    <div class="row">
                        <div class="col-8 d-md-flex flex-column">
                            <label class="form-label ms-3 font-color-light-gray">自己診断チェックリスト（基本）</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">基本コース</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-next-page btn-sm px-4 no-print" @click="showK19">ページへ移動</button>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showK1" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/home';
            location.href = url;
        }

        const showK1 = async () => {
            const url = '/browse/K1';
            location.href = url;
        }

        const showK2 = async () => {
            const url = '/browse/K2';
            location.href = url;
        }

        const showK3 = async () => {
            const url = '/browse/K3';
            location.href = url;
        }

        const showK4a = async () => {
            const url = '/browse/K4a';
            location.href = url;
        }

        const showK5 = async () => {
            const url = '/browse/K5';
            location.href = url;
        }

        const showK7 = async () => {
            const url = '/browse/K7';
            location.href = url;
        }

        const showK8 = async () => {
            const url = '/browse/K8';
            location.href = url;
        }

        const showK10 = async () => {
            const url = '/browse/K10';
            location.href = url;
        }

        const showK11 = async () => {
            const url = '/browse/K11';
            location.href = url;
        }

        const showK12 = async () => {
            const url = '/browse/K12';
            location.href = url;
        }

        const showK13 = async () => {
            const url = '/browse/K13';
            location.href = url;
        }

        const showK14 = async () => {
            const url = '/browse/K14';
            location.href = url;
        }

        const showK15 = async () => {
            const url = '/browse/K15';
            location.href = url;
        }

        const showK16 = async () => {
            const url = '/browse/K16';
            location.href = url;
        }

        const showK17 = async () => {
            const url = '/browse/K17';
            location.href = url;
        }

        const showK18 = async () => {
            const url = '/browse/K18';
            location.href = url;
        }

        const showK19 = async () => {
            const url = '/browse/K19';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            showBack,
            showK1,
            showK2,
            showK3,
            showK4a,
            showK5,
            showK7,
            showK8,
            showK10,
            showK11,
            showK12,
            showK13,
            showK14,
            showK15,
            showK16,
            showK17,
            showK18,
            showK19,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.title {
    background-color: #f2f2f2;
}

.btn-next-page {
    border: 1px solid;
    color: white;
    border-color: #00c0bc;
    background-color: #00c0bc;
}

.btn-next-page:hover {
    border: 1px solid;
    color: #00c0bc;
    border-color: #00c0bc;
    background-color: white;
}

.card-bg-gray {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.font-color-light-gray {
    color: #a6a6a6
}

@media (max-width: 767px) {
    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }
}
</style>
