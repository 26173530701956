<template>
    <div class="row">
        <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
            協定書保管規約
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="historyBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row pb-3 border-bottom-red">
        <div class="h2 mt-5 d-flex justify-content-center color-resilience-red">
            B&B災害応援協定書保管規程
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
            本規程は、TOTONO-Lサイトの利用規約を補完するB&B災害応援協定書保管サービス（以下、「協定書保管サービス」といいます。）専用の規程です。ユーザーは、利用規約と共に本規程を十分に理解しこれを承諾したうえで、本サービスを利用するものとします。
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            １&nbsp;&emsp;&emsp;保管できる協定書の種類
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>協定書保管サービスを利用することができる協定書は次の通りとし、そのデータ形式は、PDFのみとします。</li>
            <ol>
                <li>TOTONO-L指定のモデル災害応援協定書（加筆訂正したものを含む。）</li>
                <li>ユーザー同士が独自に締結した災害応援協定書</li>
            </ol>     
                <li>上記データは、アップロード、ダウンロード、削除の機能が利用できるものとします。なお、削除されたデータはいかなる場合であっても復活することができません。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ２&nbsp;&emsp;&emsp;保管期間
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>協定書保管サービスを利用できる期間は、当会の事業年度と同様の、毎年10月1日から翌年9月30日までとし、1年ごとの更新手続により延長するものとします。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ３&nbsp;&emsp;&emsp;バックアップの努力義務
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>利用規約「第26条&nbsp;免責事項」に該当する不測の事態に備えるため、ユーザーは協定書保管サービス以外の媒体にバックアップを取る等、必要かつ適切な安全管理措置を講じてください。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ４&nbsp;&emsp;&emsp;付則
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
            <ul>
                <li>本規程の改廃は、当会の総会または理事会において行います。</li>
            <ol>
                <li>制定：2022年12月8日（総会）</li>
            </ol>
            </ul>
        </div>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-5 text-center btn btn-bnb-blue" value="B&B応援災害協定ビジネスマッチングHOMEへ戻る" @click="backHome">
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default{
    components: {
        levelIcon,
    },
    setup() {
        const setLevel = ref('011111');

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const historyBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const backHome = () => {
            const url = '/bnb/home';

            location.href = url;
        }

        return {
            setLevel,
            historyBack,
            backHome,
        }
    },
    props: {
    }
}
</script>

<style lang="scss" scoped>
#notice_area {
    overflow: scroll;
    height: 21em;
}

.border-notice {
    border-bottom: 3px solid #7f7f7f;
}

.btn-download {
    border: 1px solid;
    color: white;
    border-color: #a5a5a5;
    background-color: #a5a5a5;
}

.btn-download:hover {
    border: 1px solid;
    color: #a5a5a5;
    background-color: white;
}

</style>