<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">3. 中核{{ callReplaceWord('事業') }}と復旧目標</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式08</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 緊急事態発生後に中核事業を復旧させるための代替方針に関する情報を整理する。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">事業継続に係る各種資源の代替の情報</label>
                </div>
            </div>
            <div class="row mt-5 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">① 情報連絡の拠点となる場所</label>
                </div>
            </div>
            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">拠点となる場所（住所）</label>
                </div>
                <div class="p2 col-md-9 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.address || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">建物所有者/管理者</label>
                </div>
                <div class="p2 col-md-9 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.owner || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">第一電話番号</label>
                </div>
                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.firstPhoneNumber || '&emsp;' }}</div>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">予備電話番号</label>
                </div>
                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.extraPhoneNumber || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">E-メール</label>
                </div>
                <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.email || '&emsp;' }}</div>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">その他連絡先（携帯電話、PHS等）</label>
                </div>
                <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.mobilePhoneNumber || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">連絡すべき内容</label>
                </div>
                <div class="p2 col-md-9 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.detail || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">当該場所に行くべき{{ callReplaceWord('従業員') }}</label>
                </div>
                <div class="p2 col-md-9 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.employeeName || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row ms-md-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-9 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info">{{ !is('administrator') && recoveryLocationInfo.isViewLimit ? '&emsp;' : recoveryLocationInfo.note || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row ms-md-1" id="image_1">
                <div class="p2 col-md-12 border bg-info d-flex align-items-center justify-content-start">
                    <div class="form-control bg-info pb-3">
                        <p>○当該場所までの地図（必要と思われる場合）</p>
                        <img v-if="is('administrator') || !recoveryLocationInfo.isViewLimit" :src="`data:image/png;base64,${recoveryLocationInfo.image}`" alt="">
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const contactBase = '1';

        let recoveryLocationInfo = reactive({});

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K3';
            location.href = url;
        }

        const showNextForAdmin = async () => {
            const url = '/browse/K4b';
            location.href = url;
        }

        const showNextForUser = async () => {
            const url = '/browse/K8';
            location.href = url;
        }

        // 取得データをセット
        // 復旧場所
        if (props.recovery_locations_info.length > 0) {
            const data = props.recovery_locations_info.find(fetchData => fetchData.location_type === contactBase);
            if (data) {
                recoveryLocationInfo = {
                    locationType: data.location_type,
                    isViewLimit : data.is_view_limit === 1,
                    corpId : data.corp_id,
                    owner: data.owner,
                    address: data.address,
                    firstPhoneNumber: data.first_phone_number,
                    extraPhoneNumber: data.extra_phone_number,
                    mobilePhoneNumber: data.mobile_phone_number,
                    email: data.email,
                    detail: data.detail,
                    employeeName: data.employee_name,
                    note: data.note,
                    mapId: data.map_id,
                    image: data.image,
                }
            }
        }

        return {
            setLevel,
            isMargin1,
            recoveryLocationInfo,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        recovery_locations_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-9 {
        width: 75%;
    }

    #image_1 {
        break-before: page;
    }
}
</style>
