<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式13</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 情報通信</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 緊急事態発生時における従業員、取引先、供給業者、及び他の主要な連絡先との連絡は、災害発生後の営業活動再開に重大に影響を与える。その連絡のために利用する通信機器を以下のとおり整理する。</label>
                </div>
            </div>
            <div class="ms-md-3" v-for="item in infoComMethods">
                <div class="row mt-3">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">利用を想定する情報通信機器の種別</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="電話（外線）" value="1" onclick="return false;" :checked="item.useEquipments.includes('1')">
                            <label class="form-check-label">電話（外線）</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="電話（内線）" value="2" onclick="return false;" :checked="item.useEquipments.includes('2')">
                            <label class="form-check-label">電話（内線）</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="ファックス" value="3" onclick="return false;" :checked="item.useEquipments.includes('3')">
                            <label class="form-check-label">ファックス</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="インターネット" value="4" onclick="return false;" :checked="item.useEquipments.includes('4')">
                            <label class="form-check-label">インターネット</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-md-7 border border-top-0 border-bottom-0 bg-white d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="携帯電話" value="5" onclick="return false;" :checked="item.useEquipments.includes('5')">
                            <label class="form-check-label">携帯電話&emsp;&emsp;</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="その他" value="6" onclick="return false;" :checked="item.useEquipments.includes('6')">
                            <label class="form-check-label">その他</label>
                        </div>
                        <div>
                            <label class="form-label"><span class="me-3">[</span>{{ item.useEquipmentOther || '&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;' }}<span class="ms-3">]</span></label>
                        </div>
                    </div>
                    <div class="p2 col-md-7 border border-top-0 border-bottom-0 bg-white d-md-none d-print-none">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="携帯電話" value="5" onclick="return false;" :checked="item.useEquipments.includes('5')">
                            <label class="form-check-label">携帯電話&emsp;&emsp;</label>
                        </div>
                    </div>
                    <div class="p2 col-md-7 border border-top-0 border-bottom-0 bg-white d-md-none d-print-none">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="その他" value="6" onclick="return false;" :checked="item.useEquipments.includes('6')">
                            <label class="form-check-label">その他</label>
                        </div>
                        <div>
                            <label class="form-label"><span class="me-3">[</span>{{ item.useEquipmentOther || '&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;' }}<span class="ms-3">]</span></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">当該資源の現状</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="現在、使用中" value="1" onclick="return false;" :checked="item.currentResource.includes('1')">
                            <label class="form-check-label">現在、使用中</label>
                        </div>
                        <div class="ms-md-5 form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="リース予定／購入予定" value="2" onclick="return false;" :checked="item.currentResource.includes('2')">
                            <label class="form-check-label">リース予定／購入予定</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">平時利用している機種名、台数</label>
                    </div>
                    <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-white d-none d-md-block d-print-block">
                        <label class="form-label">{{ item.model || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-1 border border-start-0 border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">|</label>
                    </div>
                    <div class="p2 col-md-1 border border-start-0 border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center d-none d-md-block d-print-block">
                        <label class="form-label">{{ item.count }}</label>
                    </div>
                    <div class="p2 col-md-1 border border-start-0 border-bottom-0 bg-white d-flex align-items-center justify-content-center d-none d-md-block d-print-block">
                        <label class="form-label">台</label>
                    </div>
                    <div class="d-md-none d-print-none border border-bottom-0 bg-white ">
                        <label class="form-label">{{ item.model + " | " + item.count + " 台" || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">緊急時に必要と予測される台数（概数）</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">約</label>
                    </div>
                    <div class="p2 col-md-1 border border-start-0 border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">{{ item.predictionCount || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-5 border border-start-0 border-bottom-0 bg-white d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">台</label>
                    </div>
                    <div class="d-md-none d-print-none border border-bottom-0 bg-white ">
                        <label class="form-label">{{ "約 " + item.predictionCount + " 台" || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">緊急時の障害等により機能する台数が上記に満たない場合の対応</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="リース" value="option9" onclick="return false;" :checked="item.isLease">
                            <label class="form-check-label">リース</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="事業復旧場所向けに購入" value="option10" onclick="return false;" :checked="item.isPurchase">
                            <label class="form-check-label">事業復旧場所向けに購入</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="その他" value="option11" onclick="return false;" :checked="item.isOther">
                            <label class="form-check-label">その他</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-md-7 border border-top-0 border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">リース/購入予定業者名&emsp;&emsp;&emsp;&emsp;<span class="me-3">[</span>{{ item.purchasePlanCompanyName || '&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;' }}<span class="ms-3">]</span></label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-md-7 border border-top-0 border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">リース/購入予定業者名（予備）<span class="me-3">[</span>{{ item.purchasePlanCompanyNameExtra || '&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;' }}<span class="ms-3">]</span></label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-md-7 border border-top-0 border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">「その他」の場合</label>
                        <label class="ms-3 form-label border flex-grow-1">{{ item.other || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">事業継続の際の想定設置場所</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.installationLocation || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考</label>
                    </div>
                    <div class="p2 col-md-7 border bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.note || '&emsp;' }}</label>
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        let infoComMethods = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/J3';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/J5';
            location.href = url;
        }

        if (props.info_com_methods.length > 0) {
            infoComMethods = props.info_com_methods.map(
                (fetchData) => ({
                    useEquipments: fetchData.use_equipments?.split(',') || [],
                    useEquipmentOther: fetchData.use_equipment_other,
                    currentResource: fetchData.current_resource?.split(',') || [],
                    model: fetchData.model,
                    count: fetchData.count,
                    predictionCount: fetchData.prediction_count,
                    isLease: fetchData.is_lease === 1,
                    purchasePlanCompanyCorpId: fetchData.purchase_plan_company_corp_id,
                    purchasePlanCompanyName: fetchData.purchase_plan_company_name,
                    purchasePlanCompanyExtraCorpId: fetchData.purchase_plan_company_extra_corp_id,
                    purchasePlanCompanyNameExtra: fetchData.purchase_plan_company_name_extra,
                    isPurchase: fetchData.is_purchase === 1,
                    isOther: fetchData.is_other === 1,
                    other: fetchData.other,
                    installationLocation: fetchData.installation_location,
                    note: fetchData.note,
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            infoComMethods,
            callReplaceWord,
            showBack,
            showNext,
        }
    },
    props:{
        info_com_methods: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="checkbox"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }
}
</style>
