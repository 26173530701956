<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 対象とする被害の選択</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 border d-flex align-items-center justify-content-around">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" onclick="return false;">
                        <label class="form-check-label" for="inlineRadio1">地震による全壊</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onclick="return false;">
                        <label class="form-check-label" for="inlineRadio2">地震による半壊</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" onclick="return false;">
                        <label class="form-check-label" for="inlineRadio3">床上浸水</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" onclick="return false;">
                        <label class="form-check-label" for="inlineRadio4">火災による全焼</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="option5" onclick="return false;">
                        <label class="form-check-label" for="inlineRadio5">水害による床上浸水</label>
                    </div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 復旧費用の算定</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-center bg-gray-1">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-center bg-gray-1">
                    <label class="form-label">復旧期間（日）</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-center bg-gray-1">
                    <label class="form-label">復旧費用</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">建物・構造物</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">機械・装置等</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">棚卸資産</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">器具・工具等</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">電気</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">都市ガス</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">水道</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-end-0 d-flex align-items-center justify-content-start bg-gray-1">
                    <label class="form-label">計</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 d-flex align-items-center justify-content-start bg-gray-1">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border d-flex align-items-center justify-content-start bg-gray-1">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);

        const showBack = () => {
            const url = '/browse/C';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C1b';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            showBack,
            showNext,
        }
    },
    props:{},
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-1 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #BFBFBF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
