<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　中核{{ callReplaceWord('事業') }}と復旧目標 ｜中核{{ callReplaceWord('事業') }}に係る情報（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-advanced btn-sm" :value="'上級｜中核' + callReplaceWord('事業') + '影響度評価フォーム へ進む'" v-show="is_completed_middle" :disabled="isProcessing" @click="showAdvanced">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る"  :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <!-----------------------------------様式06-2------------------------------------------------->
    <template v-for="(importantBusinessInfo, index) in importantBusinessesInfo" :key="index">
        <div class="row mt-5" >
            <div class="card bg-member_card">
                <div class="card-body">
                    <div class="row ms-3 ">
                        <div class="p2 ms-4 col-md-8 text-dark d-flex align-items-end justify-content-start">
                            中核{{ callReplaceWord('事業') }}の継続に必要な重要業務
                        </div>
                    </div>

                    <div class="row ms-3 ">
                        <div class="p2 ms-4 col-md-8 d-flex align-items-center justify-content-start">
                            <input type="text" class="form-control bg-resilience-orange d-flex align-items-center justify-content-center py-2" placeholder="" v-model="importantBusinessInfo.importantBusinessContent">
                        </div>
                        <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>特定した中核事業に対して、その事業を継続するために必要な資源（人、物、金、情報等）を把握します。例えば、中核事業の一つが、「{{ callReplaceWord('顧客') }}"甲"に対して、{{ callReplaceWord('商品') }}"A"を{{ callReplaceWord('製造') }}すること」であるとします。この場合、{{ callReplaceWord('商品') }}"A"をひたすら{{ callReplaceWord('製造') }}し続ければよいわけではなく、その他にも{{ callReplaceWord('受注') }}、{{ callReplaceWord('出荷') }}、支払い、決済等、中核事業に付随する業務も不可欠です。そのため、まずは当該中核事業の遂行に必要な「重要業務」をすべて把握し、それに必要となる資源を具体的に考えていきます。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </div>

                    <div class="row ms-3 ">
                        <div class="p2 ms-4 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                            ○　重要業務に必要な資源
                        </div>
                        <div class="p2 mt-3 col-md-1 d-flex align-items-center justify-content-center">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>効率的に考えるための方法のひとつに、例えば、次のような資源が「利用できなくなった」、または「無くなった」場合に、重要業務が継続できるかどうかを想像する方法があります。【{{ callReplaceWord('従業員') }}、{{ callReplaceWord('工場') }}等の施設、設備（{{ callReplaceWord('製造') }}用機材等）、{{ callReplaceWord('原材料') }}等の供給、パソコン（インターネットや電子メールを含む）、情報管理システム、電話、電力、ガス、水道、各種書類・帳票類、その他】</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </div>

                    <div class="row ms-3">
                        <div class="p2 ms-5 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start" >
                            人手による一部代替が不可能な資源
                        </div>
                    </div>
                    <div class="row ms-3">
                        <template v-for="(impossibleInfo, impossibleIndex) in importantBusinessInfo.impossibleRequiredResources" :key="impossibleIndex">
                            <div class="p2 col-md-3 d-flex align-items-center justify-content-start" :class="{'ms-5': impossibleIndex % 3 === 0,  'mt-3': impossibleIndex >= 3}">
                                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2"  v-model="impossibleInfo.resource">
                            </div>
                            <div class="p2 mt-3 col-md-1 d-flex align-items-center justify-content-start" v-if="impossibleIndex === importantBusinessInfo.impossibleRequiredResources.length - 1">
                                <input type="button" class="btn btn-black" value="マスを増やす" @click="addResourceSpace(index, substituteImpossible)">
                            </div>
                        </template>
                    </div>

                    <div class="row ms-3">
                        <div class="p2 ms-5 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start" >
                            人手による一部代替が可能な資源
                        </div>
                    </div>
                    <div class="row ms-3">
                        <template v-for="(possibleInfo, possibleIndex) in importantBusinessInfo.possibleRequiredResources" :key="possibleIndex">
                            <div class="p2 col-md-3 d-flex align-items-center justify-content-start" :class="{'ms-5': possibleIndex % 3 === 0,  'mt-3': possibleIndex >= 3}">
                                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" v-model="possibleInfo.resource">
                            </div>
                            <div class="p2 mt-3 col-md-1 d-flex align-items-center justify-content-start" v-if="possibleIndex === importantBusinessInfo.possibleRequiredResources.length - 1">
                                <input type="button" class="btn btn-black" value="マスを増やす" @click="addResourceSpace(index, substitutePossible)">
                            </div>
                        </template>
                    </div>

                    <div class="row ms-3 ">
                        <div class="p2 ms-4 mt-5 col-md-3 text-dark d-flex align-items-center justify-content-start">
                            重要業務の責任者
                        </div>
                        <div class="p2 mt-5 col-md-3 text-dark d-flex align-items-center justify-content-start">
                            責任者の連絡先
                        </div>
                    </div>

                    <div class="row ms-3 ">
                        <div class="p2 ms-4 col-md-3 d-flex align-items-center justify-content-start">
                            <select name="selectAdminCandidate" class="form-select d-flex align-items-center justify-content-center py-2" v-model="importantBusinessInfo.importantBusinessManager" @change="getContactTel(index)">
                                <option type="text" v-for="(adminCandidate, key) in admin_candidate_list" :key="key" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                            </select>

                        </div>
                        <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="importantBusinessInfo.responsibleContactPhoneNumber">
                        </div>
                    </div>

                    <div class="row ms-3 ">
                        <div class="p2 ms-4 mt-5 col-md-3 text-dark d-flex align-items-center justify-content-start">
                            備考
                        </div>
                    </div>

                    <div class="row ms-3 ">
                        <div class="p2 ms-4 col-md-10 d-flex align-items-center justify-content-start">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="importantBusinessInfo.note">
                        </div>
                        <div class="col d-flex justify-content-end">
                            <input type="button" class="btn btn-delete" value="削除" :class="{'no-display': importantBusinessesInfo.length <= 1}" @click="deleteInfo(index)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-2" v-if="index === importantBusinessesInfo.length - 1">
            <input type="button" class="btn btn-black" value="ワンセット増やす" @click="addImportantBusinessSet(index)">
        </div>
    </template>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-blue" :value="'基本｜3.中核' + callReplaceWord('事業') + '継続に係る各種資源の代替の情報 へ進む'" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.important_businesses_info.length > 0) {
                importantBusinessesInfo.splice(0);
                // 重要業務
                props.important_businesses_info.forEach((fetchData, index) => {
                    importantBusinessesInfo.push({
                        importantBusinessId: fetchData.id,
                        importantBusinessContent: fetchData.important_business_content,
                        importantBusinessManager: fetchData.important_business_manager,
                        responsibleContactPhoneNumber: fetchData.responsible_contact_phone_number,
                        note: fetchData.note,
                        impossibleRequiredResources: [],
                        possibleRequiredResources:[],
                    });
                    // 必要資源
                    if (fetchData.impossible_required_resources.length > 0) {
                        // 代替不可能資源
                        fetchData.impossible_required_resources.forEach(impossibleData => {
                            importantBusinessesInfo[index].impossibleRequiredResources.push({
                                requiredResourceId: impossibleData.id,
                                resourceType: impossibleData.resource_type,
                                resource: impossibleData.resource,
                            });
                        });
                    }
                    if (fetchData.possible_required_resources.length > 0) {
                        // 代替可能資源
                        fetchData.possible_required_resources.forEach(possibleData => {
                            importantBusinessesInfo[index].possibleRequiredResources.push({
                                requiredResourceId: possibleData.id,
                                resourceType: possibleData.resource_type,
                                resource: possibleData.resource,
                            });
                        });
                    }

                    // 必要な入力枠の作成
                    const impossibleDataCount = fetchData.impossible_required_resources.length > 0 ? fetchData.impossible_required_resources.length: 0;
                    const possibleDataCount = fetchData.possible_required_resources.length > 0 ? 6 + fetchData.possible_required_resources.length: 6;
                    for (let i = impossibleDataCount; i < 6; i++) {
                        importantBusinessesInfo[index].impossibleRequiredResources.push({
                            requiredResourceId: '',
                            resourceType: substituteImpossible,
                            resource: requiredResourcePlaceholders[i] !== undefined && impossibleDataCount === 0 ? requiredResourcePlaceholders[i] : '',
                        });
                    }
                    for (let i = possibleDataCount; i < 15; i++) {
                        importantBusinessesInfo[index].possibleRequiredResources.push({
                            requiredResourceId: '',
                            resourceType: substitutePossible,
                            resource: requiredResourcePlaceholders[i] !== undefined && possibleDataCount === 0 ? requiredResourcePlaceholders[i] : '',
                        });
                    }
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const substituteImpossible = 1;
        const substitutePossible = 2;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const requiredResourcePlaceholders = [
            callReplaceWord('従業員'),
            '情報管理システム',
            '各種書類・帳票類',
            '',
            '',
            '',
            callReplaceWord('工場') + 'などの施設',
            '設備（' + callReplaceWord('製造') + '用機材など）',
            callReplaceWord('原材料') + '等の供給',
            'パソコン（インターネットや電子メールを含む）',
            '電話',
            '電気',
            'ガス',
            '納品のための輸送手段',
            '',
        ];

        const importantBusinessesInfo = reactive([
            {
                importantBusinessId: '',
                importantBusinessContent: '',
                importantBusinessManager: '',
                responsibleContactPhoneNumber: '',
                note: '',
                impossibleRequiredResources:[],
                possibleRequiredResources:[],
            }
        ]);

        for (let i = 0; i < 6; i++) {
            importantBusinessesInfo[0].impossibleRequiredResources.push({
                requiredResourceId: '',
                resourceType: substituteImpossible,
                resource: requiredResourcePlaceholders[i] !== undefined ? requiredResourcePlaceholders[i] : '',
            });
        }
        for (let i = 6; i < 15; i++) {
            importantBusinessesInfo[0].possibleRequiredResources.push({
                requiredResourceId: '',
                resourceType: substitutePossible,
                resource: requiredResourcePlaceholders[i] !== undefined ? requiredResourcePlaceholders[i] : '',
            });
        }

        const addResourceSpace = (index, type) => {
            if (type === substituteImpossible) {
                for (let i = 0; i < 3; i++) {
                    importantBusinessesInfo[index].impossibleRequiredResources.push({
                        requiredResourceId: '',
                        resourceType: substituteImpossible,
                        resource: '',
                    });
                }
            } else {
                for (let i = 0; i < 3; i++) {
                    importantBusinessesInfo[index].possibleRequiredResources.push({
                        requiredResourceId: '',
                        resourceType: substitutePossible,
                        resource: '',
                    });
                }
            }
        }

        const addImportantBusinessSet = index => {
            importantBusinessesInfo.push({
                importantBusinessId: '',
                importantBusinessContent: '',
                importantBusinessManager: '',
                responsibleContactPhoneNumber: '',
                note: '',
                impossibleRequiredResources:[],
                possibleRequiredResources:[],
            });
            for (let i = 0; i < 6; i++) {
                importantBusinessesInfo[index + 1].impossibleRequiredResources.push({
                    requiredResourceId: '',
                    resourceType: substituteImpossible,
                    resource: requiredResourcePlaceholders[i] !== undefined ? requiredResourcePlaceholders[i] : '',
                });
            }
            for (let i = 6; i < 15; i++) {
                importantBusinessesInfo[index + 1].possibleRequiredResources.push({
                    requiredResourceId: '',
                    resourceType: substitutePossible,
                    resource: requiredResourcePlaceholders[i] !== undefined ? requiredResourcePlaceholders[i] : '',
                });
            }
        }

        const showBack = async () => {
            // 重要業務と必要資源を登録
            const result = await registerFirst3a();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 重要業務と必要資源を登録
            const result = await registerFirst3a();


            if (result == '200') {
                const url = '/bcp/bcp_first/4';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 重要業務と必要資源を登録
            const result = await registerFirst3a();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showAdvanced = async () => {
            // 重要業務と必要資源を登録
            const result = await registerFirst3a();

            if (result == '200') {
                const url = '/bcp/bcp_first/form';
                location.href = url;
            }
        }

        const getContactTel = index => {
            props.admin_candidate_list.forEach(data => {
                if (importantBusinessesInfo[index].importantBusinessManager === data.氏名) {
                    importantBusinessesInfo[index].responsibleContactPhoneNumber = data.電話番号;
                }
            });
        }

        // 重要業務と必要資源登録
        const registerFirst3a = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst3a';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'important_businesses_info': importantBusinessesInfo,
                'delete_id_list': deleteIDList.value,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const deleteInfo = (index) => {
            if(!confirm((index+1).toString() + "番目の重要業務、必要資源を削除します。よろしいですか？")){
                return false;
            }
            
            if(importantBusinessesInfo[index].importantBusinessId){
                deleteIDList.value.push([importantBusinessesInfo[index].importantBusinessId]);
            }
            importantBusinessesInfo.splice(index, 1);
        }

        const deleteIDList = ref([]);

        return {
            setLevel,
            isProcessing,
            substituteImpossible,
            substitutePossible,
            importantBusinessesInfo,
            addResourceSpace,
            addImportantBusinessSet,
            callReplaceWord,
            showBack,
            showNext,
            showContents,
            showAdvanced,
            getContactTel,
            deleteInfo,
            deleteIDList,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        admin_candidate_list: {
            type: Object,
        },
        important_businesses_info: {
            type: Object,
        },
        is_completed_middle: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>
.no-display {
    display: none;
}
</style>
