<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="row">
                <div class="h4 d-flex justify-content-center color-resilience-red">主な機能（TOTONO-Lが解決してくれること）</div>
            </div>
            <div class="row d-flex justify-content-center mb-2">
                <div class="h4 col-1 bg-color-white border-resilience-red d-flex justify-content-center color-resilience-red fw-bold">
                    1
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center my-3">
            <div class="h1 d-flex justify-content-center color-resilience-red fw-bold">
                平時活用・有事機能を実現した「安否確認」ツール
            </div>
        </div>
        <div class="row">
            <label class="color-resilience-red">役職員とその家族が選択肢の中から回答した結果を、グラフでわかりやすく表示します。</label>
        </div>
        <div class="row mt-5">
            <label>安否確認ツールを、平時に活用することで、有事の際にもスピード感のある初動対応が実現します。</label>
        </div>
        <div class="row mb-5">
            <label>TOTONO-Lは、家族の個人情報を守るため、一方通行による情報発信（利用登録・安否メッセージ送信）を採用しています。</label>
        </div>
        <div class="relative d-flex justify-content-center py-3">
            <i id="explanation_image_1" class="explanation_image" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .bg-color-resilience-green {
        background-color: #a9d08e;
    }

    .bg-color-resilience-dull-orange {
        background-color: #f4b084;
    }

    .explanation_image {
        display: inline-flex;
        width: 1100.341px;
        height: 523.567px;
        background-size: 1100.341px auto;
        background-repeat: no-repeat;
    }

    #explanation_image_1 {
        background-image: url('../../../svg/01-L1.svg');
    }

    @media (max-width: 1199px) {
        .explanation_image {
            display: inline-flex;
            width: 900px;
            height: 447.273px;
            background-size: 900px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 991px) {
        .explanation_image {
            display: inline-flex;
            width: 700px;
            height: 447.273px;
            background-size: 700px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 767px) {
        .explanation_image {
            display: inline-flex;
            width: 500px;
            height: 447.273px;
            background-size: 500px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 575px) {
        .explanation_image {
            display: inline-flex;
            width: 400px;
            height: 447.273px;
            background-size: 400px auto;
            background-repeat: no-repeat;
        }
    }

</style>
