<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            訓練実施記録
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row">
        <div class="row ms-3">
            <div class="col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                実施日
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                訓練シナリオの名称・テーマ
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                訓練の方法
            </div>
            <div class="col-md-2 border border-dark border-bottom-0 bg-light-gray text-dark d-flex align-items-center py-2">
                責任者
            </div>
        </div>
        <div class="row ms-3">
            <div class="col-md-2 border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center py-2">
                <input type="date" class="form-control d-flex align-items-center justify-content-center py-2" placeholder=""
                    v-model="trainingImplementationInfo.implementationDate"
                    @change="selectedImplementationDate"
                >
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center py-2">
                <select name="selectTraining" class="form-select form-control"
                    v-model="selectedTrainingPlan"
                    @change="selectTraining"
                >
                    <option type="text"></option>
                    <option type="text" v-for="item in trainingNameList" :key="item.id" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center py-2">
                <template v-if="trainingMethodList.find(data => data.id == trainingImplementationInfo.trainingMethod)">
                    <template v-if="trainingMethodList.find(data => data.id == trainingImplementationInfo.trainingMethod).id == trainingMethodList[7].id">
                        {{ trainingMethodList.find(data => data.id == trainingImplementationInfo.trainingMethod).methodName }} ：
                        {{ trainingImplementationInfo.trainingMethodOther }}
                    </template>
                    <template v-else>
                        {{ trainingMethodList.find(data => data.id == trainingImplementationInfo.trainingMethod).methodName }}
                    </template>
                </template>
            </div>
            <div class="col-md-2 border border-dark border-bottom-0 bg-white text-dark d-flex align-items-center py-2">
                {{ trainingImplementationInfo.manager }}
            </div>
        </div>
        <div class="row ms-3">
            <div class="col-md border border-dark bg-light-gray text-dark d-flex align-items-center py-2">
                訓練参加者
            </div>
        </div>
        <div class="row ms-3">
            <template v-for="participant, index in trainingParticipants" :key="index">
                <div class="col-md-2 border border-dark border-top-0 bg-white text-dark d-flex align-items-center py-2" style="height: 63px;"
                    :class="{'border-end-0': (index + 1) % 6 && index + 1  !== trainingParticipants.length}"
                >
                    {{ participant.participant }}
                </div>
            </template>
        </div>

        <div class="row ms-3 mt-5 ">
            <table class="table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray" colspan="3">災害想定</td>
                        <td class="bg-light-gray">発生曜日</td>
                        <td class="bg-light-gray">発生時間</td>
                        <td class="bg-light-gray">経過想定日数</td>
                        <td class="bg-light-gray">被害場所</td>
                    </tr>
                    <tr>
                        <td class="bg-disaster" colspan="3">
                            {{ trainingImplementationInfo.disasterAssumption }}
                        </td>
                        <td class="bg-white">
                            <select name="selectWeekday" class="form-select form-control"
                                v-model="trainingImplementationInfo.occurrenceWeekday"
                                :disabled="isUnselectedPlan"
                            >
                                <option type="text"></option>
                                <option type="text" v-for="item in weekdayList" :key="item" :value="item.id">
                                    {{ item.weekday }}
                                </option>
                            </select>
                        </td>
                        <td class="bg-white">
                            <input type="time" min="00:00:00" class="form-control d-flex align-items-center justify-content-center" placeholder="" v-model="trainingImplementationInfo.occurrenceTime">
                        </td>
                        <td class="bg-white">
                            <select name="selectAssumptionDays" class="form-select form-control"
                                v-model="trainingImplementationInfo.progressAssumptionDays"
                            >
                                <option type="text"></option>
                                <option type="text" v-for="item in assumptionDaysList" :key="item" :value="item.id">
                                    {{ item.assumptionDays }}
                                </option>
                            </select>
                        </td>
                        <td class="bg-white">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center" placeholder="" v-model="trainingImplementationInfo.damage">
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="3">影響を受ける中核事業</td>
                        <td class="bg-light-gray" colspan="4">訓練終了後に気づいた課題・BCP見直しのポイントなど（自由記述）</td>
                    </tr>
                    <tr>
                        <td class="bg-blue" colspan="3">
                            {{ trainingImplementationInfo.coreBusiness }}
                        </td>
                        <td class="bg-white" colspan="4" rowspan="12">
                            <textarea type="text" class="form-control" style="height: 30em;" placeholder="" v-model="trainingImplementationInfo.freeDescription"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="3">影響を受ける重要業務</td>
                    </tr>
                    <tr>
                        <td class="bg-orange" colspan="3">
                            {{ trainingImplementationInfo.importantBusiness }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="3">経過想定日数時の災害イメージ</td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">電気</td>
                        <td class="bg-white" style="width: 120px;">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.electricity)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.electricity).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">通信（固定電話、携帯電話、インターネット、社内イントラネット等）</td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.communication)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.communication).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">ガス</td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.gas)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.gas).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">上下水道</td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.waterworks)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.waterworks).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">交通機関（高速道路、国道、鉄道等）</td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.trafficMethod)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.trafficMethod).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">小売業（金融機関、スーパー等）</td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.retailBusiness)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.retailBusiness).availableStatus }}
                            </template>
                       </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">地域住民等</td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.regionalResident)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.regionalResident).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header">その他</td>
                        <td class="bg-white" style="width: 400px;">
                            {{ trainingImplementationInfo.otherInputValue }}
                        </td>
                        <td class="bg-white">
                            <template v-if="availableStatusList.find(data => data.id == trainingImplementationInfo.other)">
                                {{ availableStatusList.find(data => data.id == trainingImplementationInfo.other).availableStatus }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="訓練計画の閲覧 へ登録する" style="font-size:10.5pt" :disabled="isProcessing || isUnselectedPlan" @click="register">
        </div>
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>
    <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.training_plan_info).length > 0) {
                Object.values(props.training_plan_info).map(fetchData => {
                    trainingNameList.value.push({
                        id: fetchData[0].training_plan_id,
                        name: fetchData[0].training_name,
                    });
                })
            }
        });

        const setLevel = ref('001111');
        const isProcessing = ref(false);
        const isUnselectedPlan = ref(true);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const trainingNameList = ref([]);
        const selectedTrainingPlan = ref('');

        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        // 訓練の方法
        const trainingMethodList = [
            {id: 1, methodName: '机上訓練（模擬災害対策訓練）'},
            {id: 2, methodName: 'バックアップデータを取り出す訓練'},
            {id: 3, methodName: '机上訓練（ワークショップ訓練）'},
            {id: 4, methodName: 'BCP手順確認訓練'},
            {id: 5, methodName: '机上訓練（ロールプレイング訓練）'},
            {id: 6, methodName: 'BCP全体を通して行う総合訓練'},
            {id: 7, methodName: '安否確認システム操作訓練・緊急時通報診断'},
            {id: 8, methodName: 'その他'},
            {id: 9, methodName: '代替施設への移動訓練'},
        ]

        // 曜日
        const weekdayList = [
            {id: 1, weekday: '月曜日'},
            {id: 2, weekday: '火曜日'},
            {id: 3, weekday: '水曜日'},
            {id: 4, weekday: '木曜日'},
            {id: 5, weekday: '金曜日'},
            {id: 6, weekday: '土曜日'},
            {id: 0, weekday: '日曜日'},
        ]

        // 経過想定日数
        const assumptionDaysList = [
            {id: 1, assumptionDays: '発災初日'},
            {id: 2, assumptionDays: '発災後4～5日目'},
        ]

        // 利用可能状況
        const availableStatusList = [
            {id: 1, availableStatus: '使える＝◯'},
            {id: 0, availableStatus: '使えない＝✕'},
        ]

        // 訓練計画実施情報
        const trainingImplementationInfo = ref({});

        // 訓練計画実施参加者
        const trainingParticipants = ref([]);
        for (let i = 0; i < 12; i++) {
            trainingParticipants.value.push({
                participantType: '',
                participant: '',
                externalOrganizationCorpId: '',
            });
        }

        // 実施日選択
        const selectedImplementationDate = () => {
            if (selectedTrainingPlan.value !== '') {
                isUnselectedPlan.value = false;
            } else {
                isUnselectedPlan.value = true;
            }
        }

        // 訓練計画選択プルダウン
        const selectTraining = () => {
            const trainingPlan = props.training_plan_info[selectedTrainingPlan.value];
            if (typeof trainingPlan !== 'undefined') {
                // 訓練計画実施情報
                trainingImplementationInfo.value = {
                    id: '',
                    trainingPlanId: trainingPlan[0].training_plan_id,
                    implementationDate: trainingImplementationInfo.value.implementationDate,
                    trainingName: trainingPlan[0].training_name,
                    trainingTarget: trainingPlan[0].training_target,
                    trainingMethod: trainingPlan[0].training_method,
                    trainingMethodOther: trainingPlan[0].training_method_other,
                    manager: props.manager_name,
                    disasterAssumption: trainingPlan[0].disaster_assumption,
                    coreBusiness: trainingPlan[0].core_business,
                    importantBusiness: trainingPlan[0].important_business,
                    occurrenceWeekday: trainingPlan[0].occurrence_weekday,
                    occurrenceTime: trainingPlan[0].occurrence_time,
                    progressAssumptionDays: trainingPlan[0].progress_assumption_days,
                    damage: trainingPlan[0].damage,
                    electricity: trainingPlan[0].electricity,
                    communication: trainingPlan[0].communication,
                    gas: trainingPlan[0].gas,
                    waterworks:  trainingPlan[0].waterworks,
                    trafficMethod: trainingPlan[0].traffic_method,
                    retailBusiness: trainingPlan[0].retail_business,
                    regionalResident: trainingPlan[0].regional_resident,
                    other: trainingPlan[0].other,
                    otherInputValue: trainingPlan[0].other_input_value,
                    freeDescription: '',
                }
                // 参加者
                trainingParticipants.value.splice(0);
                trainingPlan.forEach(data => {
                    if (data.participant) {
                        trainingParticipants.value.push({
                            participantType: data.participant_type,
                            participant: data.participant,
                            externalOrganizationCorpId: data.external_organization_corp_id,
                        });
                    }
                });
            } else {
                // データがない場合は表示内容を初期化
                trainingImplementationInfo.value = {};
                trainingParticipants.value.splice(0);
                for (let i = 0; i < 12; i++) {
                    trainingParticipants.value.push({
                        participantType: '',
                        participant: '',
                        externalOrganizationCorpId: '',
                    });
                }
            }

            console.log(trainingImplementationInfo.value.implementationDate);
            if (trainingImplementationInfo.value.implementationDate !== '' && trainingImplementationInfo.value.implementationDate !== undefined) {
                isUnselectedPlan.value = false;
            }else{
                isUnselectedPlan.value = true;
            }
        }

        const showBack = async () => {
            let result = null;
            if (!isUnselectedPlan.value) {
                // 訓練実施記録を登録
                result = await registerB3();
            }
            if (result == '200' || isUnselectedPlan.value) {
                // 前のページへ遷移
                history.back();
            }
        }

        const register = () => {
            // 訓練実施記録を登録
            registerB3();
        }

        const showTop = async () => {
            let result = null;
            if (!isUnselectedPlan.value) {
                // 訓練実施記録を登録
                result = await registerB3();
            }

            if (result == '200' || isUnselectedPlan.value) {
                const url = '/fixation/fixation';
                location.href = url;
            }
        }

        // 訓練実施記録登録
        const registerB3 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/registerB3';

            // 参加者の情報を付与
            trainingImplementationInfo.value.participants = trainingParticipants.value;

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'training_implementation_info': trainingImplementationInfo.value,
            }).then(res => {
                if(typeof res.data.entry_record_id !== 'undefined'){
                    // 新規登録の場合は、登録した訓練実施記録idをセット
                    trainingImplementationInfo.value.id = res.data.entry_record_id;
                }
                visibleFlashMessage('正常に登録されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            isUnselectedPlan,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            trainingMethodList,
            trainingNameList,
            weekdayList,
            assumptionDaysList,
            availableStatusList,
            trainingImplementationInfo,
            trainingParticipants,
            selectedImplementationDate,
            selectedTrainingPlan,
            selectTraining,
            showBack,
            register,
            showTop,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        training_plan_info: {
            type: Object,
        },
        manager_name: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-disaster {
    background-color: #c00000;
    color: white;
    height: 39.5px;
}
.bg-blue {
    background-color: #4472c4;
    color: white;
    height: 39.5px;
}

.bg-orange {
  background-color: #FF6600;
  height: 39.5px;
}

.bg-header {
    background-color: #F2F2F2;
    height: 39.5px;
}

.bg-light-gray {
    height: 39.5px;
}

</style>
