<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col">
                    <label class="form-label" style="font-size:12pt">BCP策定・運用状況の診断</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">基本コース</span>
                </div>
            </div>
            <template v-if="is('administrator') || !viewLimit">
                <div class="row mt-3 ms-1">
                    <div class="p2 col d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">策定したBCPおよびBCP運用状況の診断チェックを行ってください。以下の設問に、はい／いいえ／不明でお答えください。</label>
                    </div>
                </div>
                <div class="ms-md-3 d-none d-md-block d-print-block">
                    <div class="row mt-3">
                        <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">優先度</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">診断項目</label>
                        </div>
                        <div class="p2 col-3 border border-bottom-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">経営者のチェック</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">必須</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">推奨</label>
                        </div>
                        <div class="p2 col-7 border border-top-0 border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-3 border border-top-0 border-bottom-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　{{ callReplaceWord('事業') }}継続基本方針の立案</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index < 3">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index < 2">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index === 2">○</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCPサイクルの運用体制確立</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 3 && index < 7">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <label class="form-label" v-if="index === 3 || index === 4">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <label class="form-label" v-if="index === 5 || index === 6">○</label>
                                </div>
                                <div class="p2 col-7 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 6}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row mt-3">
                        <div class="px-1 col-2 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">優先度</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">診断項目</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">経営者のチェック</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">必須</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">推奨</label>
                        </div>
                        <div class="px-1 col-8 border border-top-0 border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-2 border border-top-0 border-bottom-0 bg-gray-1 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　{{ callReplaceWord('事業') }}継続基本方針の立案</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index < 3">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index < 2">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index === 2">○</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCPサイクルの運用体制確立</label>
                        </div>
                        <div class="p2 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 3 && index < 7">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <label class="form-label" v-if="index === 3 || index === 4">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    <label class="form-label" v-if="index === 5 || index === 6">○</label>
                                </div>
                                <div class="p2 col-8 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 6}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 6}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="row mt-3 ms-md-1">
                    <div class="p2 col d-flex align-items-center justify-content-start">
                        <label class="form-label">①「事業を理解する」</label>
                    </div>
                </div>
                <div class="ms-md-3 d-none d-md-block d-print-block">
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　{{ callReplaceWord('事業') }}への影響度を評価する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 7 && index < 11">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index !== 9">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index === 9">○</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　中核{{ callReplaceWord('事業') }}が受ける被害を評価する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 11 && index < 14">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">3.　財務状況を診断する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 14 && index < 20">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <label class="form-label" v-if="index !== 15 && index !== 17">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <label class="form-label" v-if="index === 15 || index === 17">○</label>
                                </div>
                                <div class="p2 col-7 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 19}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　{{ callReplaceWord('事業') }}への影響度を評価する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 7 && index < 11">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index !== 9">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index === 9">○</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　中核{{ callReplaceWord('事業') }}が受ける被害を評価する</label>
                        </div>
                        <div class="p2 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 11 && index < 14">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">3.　財務状況を診断する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 14 && index < 20">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <label class="form-label" v-if="index !== 15 && index !== 17">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    <label class="form-label" v-if="index === 15 || index === 17">○</label>
                                </div>
                                <div class="p2 col-8 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 19}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 19}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="row mt-3 ms-md-1">
                    <div class="p2 col d-flex align-items-center justify-content-start">
                        <label class="form-label">②「BCPの準備、事前対策を検討する」</label>
                    </div>
                </div>
                <div class="ms-md-3 d-none d-md-block d-print-block">
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　{{ callReplaceWord('事業') }}継続のための代替策の特定と選択をする</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 20 && index < 26">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　事前対策を検討・実施する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 26 && index < 29">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <label class="form-label" v-if="index === 26">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <label class="form-label" v-if="index !== 26">○</label>
                                </div>
                                <div class="p2 col-7 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 28}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　{{ callReplaceWord('事業') }}継続のための代替策の特定と選択をする</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 20 && index < 26">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 26}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　事前対策を検討・実施する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 26 && index < 29">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <label class="form-label" v-if="index === 26">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    <label class="form-label" v-if="index !== 26">○</label>
                                </div>
                                <div class="p2 col-8 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 28}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 28}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </div>



                <div class="row mt-3 ms-md-1">
                    <div class="p2 col d-flex align-items-center justify-content-start">
                        <label class="form-label">③「BCPを作成する」</label>
                    </div>
                </div>
                <div class="ms-md-3 d-none d-md-block d-print-block">
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　BCP発動基準を明確にする</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 29 && index < 33">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index !== 32">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index === 32">○</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 border-bottom-0 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCP発動時の体制を明確にする</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 33 && index < 40">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="(index >= 33 && index < 36) || index === 39">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index >= 36 && index < 39">○</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">3.　事業継続に関連する情報の整理と文書化をする</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 40 && index < 45">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <label class="form-label" v-if="index < 43">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <label class="form-label" v-if="index >= 43">○</label>
                                </div>
                                <div class="p2 col-7 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 44}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　BCP発動基準を明確にする</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 29 && index < 33">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index !== 32">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index === 32">○</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCP発動時の体制を明確にする</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 33 && index < 40">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="(index >= 33 && index < 36) || index === 39">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label" v-if="index >= 36 && index < 39">○</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">3.　事業継続に関連する情報の整理と文書化をする</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 40 && index < 45">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <label class="form-label" v-if="index < 43">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    <label class="form-label" v-if="index >= 43">○</label>
                                </div>
                                <div class="p2 col-8 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 44}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 44}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="row mt-3 ms-md-1">
                    <div class="p2 col d-flex align-items-center justify-content-start">
                        <label class="form-label">④「BCP文化を定着させる」</label>
                    </div>
                </div>
                <div class="ms-md-3 d-none d-md-block d-print-block">
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　従業員へのBCP教育を実施する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 45 && index < 47">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCP訓練を実施する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 47 && index < 51">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">3.　BCP文化を醸成する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 51 && index < 58">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <label class="form-label" v-if="index === 51 || index === 54">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <label class="form-label" v-if="index !== 51 && index !== 54">○</label>
                                </div>
                                <div class="p2 col-7 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 57}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　従業員へのBCP教育を実施する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 45 && index < 47">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCP訓練を実施する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 47 && index < 51">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">3.　BCP文化を醸成する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 51 && index < 58">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <label class="form-label" v-if="index === 51 || index === 54">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    <label class="form-label" v-if="index !== 51 && index !== 54">○</label>
                                </div>
                                <div class="p2 col-8 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 57}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 57}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="row mt-3 ms-md-1">
                    <div class="p2 col d-flex align-items-center justify-content-start">
                        <label class="form-label">⑤「BCPの診断、維持・更新を行う」</label>
                    </div>
                </div>
                <div class="ms-md-3 d-none d-md-block d-print-block">
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　BCPの診断・チェックを行う</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index === 58">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-7 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCP文化を醸成する</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">はい</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">いいえ</label>
                        </div>
                        <div class="p2 col-1 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">不明</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 59 && index < 66">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-7 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 65}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault1' + index" value="1" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault2' + index" value="2" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                                <div class="p2 col-1 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" :name="'flexRadioDefault1' + index" :id="'flexRadioDefault3' + index" value="9" v-model="item.checkType" onclick="return false;">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">1.　BCPの診断・チェックを行う</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index === 58">
                            <div class="row ">
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-8 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="row ">
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-1 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="px-1 col-8 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                            <label class="form-label">2.　BCP文化を醸成する</label>
                        </div>
                        <div class="px-1 col-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                            <label class="form-label">&emsp;</label>
                        </div>
                    </div>
                    <template v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 59 && index < 66">
                            <div class="row ">
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <label class="form-label">○</label>
                                </div>
                                <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    <label class="form-label">&emsp;</label>
                                </div>
                                <div class="p2 col-8 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start" :class="{'border-bottom-0': index < 65}">
                                    <label class="form-label">{{ diagnosisItems[index] }}</label>
                                </div>
                                <div class="p2 col-2 border bg-gray-3 d-flex align-items-center justify-content-center" :class="{'border-bottom-0': index < 65}">
                                    {{ displayCheck(item.checkType) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </template>
        </div>

        <br>

    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="BCP閲覧 HOMEへ戻る" @click="showHome" />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.self_diagnoses_info.length > 0) {
                props.self_diagnoses_info.forEach(fetchData => {
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        viewLimit.value = fetchData.check_type == 1 ? true : false;
                    }
                    checkItems.value.forEach(initData => {
                        if (initData.columnName === fetchData.column_name) {
                            initData.checkType = fetchData.check_type;
                        }
                    });
                });
            }
            console.log(viewLimit.value);
        });

        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const viewLimit = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const diagnosisItems = [
            callReplaceWord('経営者') + 'が関与して規定された事業継続の基本方針がありますか？',
            '上記の' + callReplaceWord('事業') + '継続の基本方針について、すべての' + callReplaceWord('従業員') + 'に内容が周知されるための仕組みがありますか？',
            '上記の事業継続の基本方針について、' + callReplaceWord('取引先') + 'や協力' + callReplaceWord('組織') + 'などに内容が公開されていますか？',
            '平時においてBCPサイクルの運用を推進する' + callReplaceWord('社内') + '体制が確立されていますか？',
            '上記の' + callReplaceWord('社内') + '体制は、' + callReplaceWord('経営者') + '自らが率先してBCPの策定・運用にあたるものになっていますか？',
            'BCPサイクル運用体制について、当該メンバー以外の' + callReplaceWord('従業員') + 'も、その存在を明確に認識していますか？',
            'BCPサイクルの運用体制の状況を確認し評価する機能はありますか？',
            '事業影響度評価を通して、あなたの' + callReplaceWord('組織') + 'における中核' + callReplaceWord('事業') + 'を明確に認識しましたか？',
            '上記の中核' + callReplaceWord('事業') + 'に不可欠な、あなたの会社の重要業務及び資源（人、物、金、情報 等）を明確に認識しましたか？',
            '上記の中核' + callReplaceWord('事業') + 'の継続に不可欠な資源について、重要度の順位付けはされていますか？',
            '上記の中核' + callReplaceWord('事業') + 'について、' + callReplaceWord('取引先') + 'やサプライチェーンの要請を考慮して目標復旧時間を設定しましたか？',
            '自然災害、人的災害を含めて、中核' + callReplaceWord('事業') + 'が影響を受ける災害を明確に認識しましたか？',
            '上記の各災害が中核事業の各ボトルネックに与える影響について、可能な限り漏れなく評価しましたか？',
            '中核' + callReplaceWord('事業') + 'が影響を受ける災害について、影響度の順位付けはされていますか？',
            '「財務診断モデル」を利用して、あなたの' + callReplaceWord('組織') + 'の被災後のキャッシュフローを把握しましたか？',
            '財務診断の結果にもとづき、政府系中小企業金融機関などの災害復旧貸付制度の利用を検討しましたか？',
            '財務診断の結果にもとづき、1ヶ月程度の操業停止に耐え得るだけの資金確保の必要性を検討しましたか？',
            '財務診断の結果にもとづき、損害保険の追加加入の必要性を検討しましたか？',
            '財務診断の結果にもとづき、目標復旧時間を財務状況面から再検討しましたか？',
            '最新の事業影響度評価、被害評価及び財務診断は、それぞれ過去12ヶ月以内に実施されたものですか？',
            '緊急時における情報連絡の拠点場所の確保について検討しましたか？',
            '被災したあなたの' + callReplaceWord('組織') + 'の重要施設・設備の代替確保について検討しましたか？',
            '臨時' + callReplaceWord('従業員') + 'の確保について検討しましたか？',
            '資金調達の方針について検討しましたか？',
            '中核' + callReplaceWord('事業') + 'の復旧・継続に必要となる重要情報のバックアップ方針について検討しましたか？',
            '通信手段や電力などのインフラに関する二重化対策やバックアップの必要性を検討しましたか？',
            '中核' + callReplaceWord('事業') + 'への影響が高いと思われる災害と、それにより影響を受けるボトルネック資源の両面から事前対策を検討し、可能なソフトウェア対策を実施しましたか？',
            '事前対策に投入できる年間予算が明確に割り当てられていますか？',
            'ハードウェア面での事前対策のための融資制度の活用を検討しましたか？',
            'BCP発動基準が明確に設定されていますか？',
            'BCP発動を判断する人物が明確に決められていますか？',
            'BCP発動を判断すべき人物が不在などで判断できない場合、代理の判断者が決められていますか？',
            'BCP発動を判断する人物（もしくはその代理者）を、平時よりすべての' + callReplaceWord('従業員') + 'に周知させていますか？',
            'BCP発動時の' + callReplaceWord('社内') + '体制を明確に規定していますか？',
            'BCP発動時の' + callReplaceWord('社内') + '体制は' + callReplaceWord('経営者') + 'が指揮命令を下せるようになっていますか？',
            'BCP発動時の体制に規定されている全体のリーダー（' + callReplaceWord('経営者') + '）が不在の場合に、代理のリーダーが決められていますか？',
            '上記の代理リーダーが適切な指揮命令ができるように、平時より' + callReplaceWord('経営者') + 'のノウハウの共有が図られていますか？',
            '代理リーダーを、平時よりすべての' + callReplaceWord('従業員') + 'が周知していますか？',
            'BCP発動時の体制における各自の役割を、各' + callReplaceWord('従業員') + 'は明確に把握していますか？',
            '取引先や協力会社からの問い合わせへの対応体制および責任者が明確にされていますか？',
            callReplaceWord('従業員') + '連絡先リスト、もしくは電話連絡網が作成され、最新の情報に更新されていますか？',
            '主要な' + callReplaceWord('取引先') + 'の連絡先リストが作成され、最新の情報に更新されていますか？',
            '緊急避難計画は規定されていますか？',
            '救急や公共サービス、行政組織等への連絡先リストが作成され、最新の情報に更新されていますか？',
            'あなたの' + callReplaceWord('組織') + 'が保有する電話／FAX番号リストを整理し、中核' + callReplaceWord('事業') + 'の継続・復旧において各番号が不可欠かどうかを評価しましたか？',
            'BCPや防災に関して' + callReplaceWord('従業員') + 'と平時より議論したり、勉強会などを開催したりしていますか？',
            'BCPや防災に関する知識や技能を' + callReplaceWord('従業員') + 'に身につけさせるための支援を行っていますか？',
            '机上訓練や電話連絡診断など様々なレベルの訓練を、必要に応じて定期的に実施していますか？',
            '自治体などで開催されている防災訓練に関する情報を入手する体制がありますか？',
            '自治体などで開催されている防災訓練に参加していますか？',
            '各' + callReplaceWord('従業員') + 'の役割や義務、責任や権限が本人に理解されていますか？',
            callReplaceWord('経営者') + 'がBCPを積極的に支持していますか？',
            callReplaceWord('経営者') + 'のBCPに対する積極的な姿勢が、' + callReplaceWord('従業員') + 'に理解されていますか？',
            '全社的なBCPの運用に対して' + callReplaceWord('従業員') + 'が積極的に取り組んでいますか？',
            callReplaceWord('経営者') + '層と' + callReplaceWord('従業員') + 'との平時からのコミュニケーションが適切に取れていますか？',
            '社宅の耐震化や家庭用防災用具の配布など、' + callReplaceWord('従業員') + 'の安全を守るための対策を' + callReplaceWord('組織') + 'として実施していますか？',
            callReplaceWord('社内') + '報やイントラネット、口頭での連絡も含め、BCPや防災に関する情報が' + callReplaceWord('社内') + 'に周知されていますか？',
            callReplaceWord('従業員') + 'のBCPへの参加や貢献を評価するための制度がありますか？',
            '本チェックリストを用いてBCPをチェックしましたか？',
            'BCPの維持・定期的な更新が明確に規定されていますか？',
            'BCPの運用体制の見直しの必要性を判断し、必要に応じて見直しを行っていますか？',
            '事前対策や教育訓練の費用等、BCPの運用に必要な経費を見積もり、その資金を確保するための手順が明確にされていますか？',
            '訓練や診断により明らかとなった問題点がBCPの更新に反映される仕組みがありますか？',
            'あなたの' + callReplaceWord('組織') + 'の組織体制に大きな変更があった場合、' + callReplaceWord('取引先') + 'に大きな変更があった場合、新' + callReplaceWord('商品') + 'を開発した場合などの際に、BCPの更新に反映される仕組みがありますか？',
            '現状のBCPの不適切な点等について、' + callReplaceWord('従業員') + 'が' + callReplaceWord('経営者') + 'へ伝える仕組みがありますか？',
            'BCPの更新が' + callReplaceWord('従業員') + 'に周知される仕組みがありますか？',
        ]

        const checkItems = ref([]);
        for (let i = 1; i <= 66; i++) {
            checkItems.value.push({
                columnName: ('00000' + i).slice(-5),
                diagnosisItem: '',
                checkType: '',
            });
        }

        const showBack = () => {
            const url = '/browse/K18';
            location.href = url;
        }

        const showHome = async () => {
            const url = '/browse/home';
            location.href = url;
        }

        const displayCheck = (val) => {
            switch(val){
                case "1":
                    return 'はい';
                case "2":
                    return 'いいえ';
                case "9":
                    return "不明";
                default:
                    return '';
            }
        }

        return {
            setLevel,
            isMargin1,
            viewLimit,
            diagnosisItems,
            checkItems,
            callReplaceWord,
            showBack,
            showHome,
            displayCheck,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        self_diagnoses_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-1 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #BFBFBF;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

}
</style>
