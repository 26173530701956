<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">団体会員と団体所属会員との紐づけ登録</div>
            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">団体会員</label></div>
        </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>会員番号</th>
                        <th>団体会員の名称</th>
                        <th>団体会員コード</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ corp_info.企業ID }}</td>
                        <td>{{ corp_info.組織名 }}</td>
                        <td>{{ corp_info.団体会員コード }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-5">
            <div class="col-md-3">
                <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">団体会員一覧</label></div>
            </div>
            <div class="col-md-2">
                <input type="button" class="btn px-5 btn-black" value="新規登録" @click="createMember" />
            </div>
            <div class="row ms-5 flash col-5 alert mt-3" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
       </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light" id="detail_tbl">
                <thead>
                    <tr>
                        <th rowspan="2"></th>
                        <th rowspan="2" class="v-middle">団体所属会員の名称</th>
                        <th rowspan="2" class="v-middle">郵便番号・所在地</th>
                        <th colspan="3" class="t-center">管理者</th>
                        <th rowspan="2"></th>
                    </tr>
                    <tr>
                        <th class="t-center">氏名</th>
                        <th class="t-center">部署・役職</th>
                        <th class="t-center">メールアドレス</th>
                    </tr>
                </thead>
                <tbody v-for="(member, index) in group_affiliation_list" :key="member.id">
                    <tr>
                        <td rowspan="2" class="v-middle"><input type="button" class="btn btn-red" :value="index + 1" @click="editMember(member.id)" /></td>
                        <td rowspan="2" class="v-middle">{{ member.団体所属組織名 }}</td>
                        <td>{{ label郵便番号(member.郵便番号) }}</td>
                        <td rowspan="2" class="v-middle">{{ member.管理者名_姓 + '　' + member.管理者名_名 }}</td>
                        <td>{{ member.管理者_所属部署 }}</td>
                        <td rowspan="2" class="v-middle t-center">{{ member.管理者_メールアドレス }}</td>
                        <td rowspan="2" class="v-middle t-center">
                            <input type="button" class="btn btn-black btn-sm" value="団体会員コード通知文をダウンロード" @click="downloadDoc(member.id)" :disabled="isProcessing" v-show="member.郵便番号" />
                            <input type="button" class="btn btn-black ms-3 btn-sm" value="団体会員コードをメール送信する" :disabled="isProcessing" @click="sendMail(member.id)" v-show="member.管理者_メールアドレス" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ (member.都道府県 ?? '') + (member.市区町村 ?? '') + (member.番地 ?? '') }}</td>
                        <td>{{ member.管理者_役職 }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        const back = () => {
            const url = '/s-admin/showGroupMemberList';

            location.href = url;
        }

        const createMember = () => {
            const url = '/s-admin/createGroupMember/' + props.corp_info.id;

            location.href = url;
        }

        const label郵便番号 = (zip) => {
            if(zip){
                const 郵便番号 = zip.toString();

                return 郵便番号.substring(0, 3) + '-' + 郵便番号.substring(3);
            }else{
                return '';
            }
        }

        const editMember = (id) => {
            const url = '/s-admin/editGroupMember/' + id;

            location.href = url;
        }

        const isProcessing = ref(false);

        const downloadDoc = (id) => {
            const url = '/api/s-admin/downloadGroupMemberDoc';

            isProcessing.value = true;

            axios.post(url, {
                'id': id,
            }, {
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '02002団体所属会員への団体会員コード通知文.docx';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }

            ).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const sendMail = (id) => {
            const url = '/api/s-admin/sendGroupMemberMail';

            isProcessing.value = true;

            axios.post(url, {
                'id': id,
            }).then(res=>{
                visibleFlashMessage('メールを送信しました。', 'success');
            }).finally(fin=>{
                isProcessing.value = false;
            })

        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        return {
            back,
            createMember,
            label郵便番号,
            editMember,
            isProcessing,
            downloadDoc,
            sendMail,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        group_affiliation_list: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-middle{
        vertical-align: middle;
    }

    .t-center {
        text-align: center;
    }
</style>