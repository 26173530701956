<template>
    <div class="heading mt-3">
        お知らせ
    </div>
    <div class="row mt-3 pb-5">
        <div v-for="notice in noticeList" :key="notice.id">
            <div class="row mt-3">
                <div class="col-md-2 color-resilience-orange">{{ this.$root.dayjs(notice.created_at).format('YYYY年MM月DD日') }}</div>
                <div class="col-md-10">{{ notice.title }}</div>
            </div>
            <div class="row mt-1">
                <div>{{ notice.detail }}</div>
            </div>
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import axios from 'axios';

export default {
    setup() {
        const noticeList = ref([]);

        const getNotice = () => {
            const url = '/api/getBlNotice?cnt=' + 5;

            axios.get(url)
            .then(res => {
                noticeList.value = res.data;
            })
        }

        onMounted(() => {
            getNotice();
        });

        return {
            noticeList,
            getNotice,
        }
        
    },
}
</script>
