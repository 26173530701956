<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">お知らせ一覧画面</div>
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>

            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">お知らせ</label></div>
        </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>種別</th>
                        <th>開始日</th>
                        <th>終了日</th>
                        <th>件名</th>
                        <th>内容</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="notice in notice_list" :key="notice.id">
                        <td class="col-form-label">{{ notice.id }}</td>
                        <td class="col-form-label">{{ showKind(notice.notice_kind)  }}</td>
                        <td class="col-form-label">{{ this.$root.dayjs(notice.notice_from).format('YYYY-MM-DD') }}</td>
                        <td class="col-form-label">{{ this.$root.dayjs(notice.notice_to).format('YYYY-MM-DD') }}</td>
                        <td class="col-form-label">{{ notice.title }}</td>
                        <td class="col-form-label">{{ notice.detail }}</td>
                        <td>
                            <input type="button" class="btn btn-red px-3 " value="編集" @click="editNotice(notice.id)" />
                            <input type="button" class="btn btn-black px-3 ms-5" value="削除" @click="deleteNotice(notice.id)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        const back = () => {
            const url = '/s-admin/noticeHistoryLists';

            location.href = url;
        }

        const showKind = (code) => {
            switch(code){
                case 90000001:
                    return 'BL・AL両方';
                case 90000002:
                    return 'BLのみ';
                case 90000003:
                    return 'ALのみ';
                default:
                    return '';
            }
        }

        const editNotice = (id) => {
            const url = '/s-admin/editNotice/' + id;

            location.href = url;
        }

        const deleteNotice = (id) => {
            if(!confirm('No.' + id + 'を削除してよろしいですか')){
                return;
            }

            const url = '/api/s-admin/deleteNotice';

            axios.delete(url, {data: {id: id}})
            .then(res => {
                const url = '/s-admin/showNoticeList';

                location.href = url;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        
        return {
            back,
            showKind,
            editNotice,
            deleteNotice,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,

            visibleFlashMessage,
        }
    },
    props: {
        notice_list: {
            type: Array,
        }
    }
}
</script>

