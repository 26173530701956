<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜地域貢献活動
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　日常的な活動
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 text-dark bg-light-gray d-flex align-items-center justify-content-start py-2">
            <label class="custom-control-label" for="customRadioInline1">チェック</label>
        </div>
        <div class="p2 col-md-5 border border-dark border-end-0 border-bottom-0 text-dark bg-light-gray d-flex align-items-center justify-content-start py-2">
            <label class="custom-control-label" for="customRadioInline1">活動例</label>
        </div>
        <div class="p2 col-md-5 border border-dark border-bottom-0 text-dark bg-light-gray d-flex align-items-center justify-content-start py-2">
            <label class="custom-control-label" for="customRadioInline1">補足</label>
        </div>
    </div>

    <div class="row ms-3" v-for="(form1, form1Index) in valueFormsField1" :key="form1Index">
        <div class="p2 col-md-1 border border-dark border-end-0 text-dark bg-light-gray d-flex align-items-center justify-content-center py-2" :class="{'border-bottom-0': form1Index !== valueFormsField1.length - 1}">
            <input type="checkbox" :id="'customRadioInline1' + form1Index" :name="'customRadioInline1' + form1Index" class="custom-control-input" v-model="form1.values[0].value">
        </div>
        <div class="p2 col-md-5 border border-dark border-end-0 text-dark bg-white d-flex align-items-center justify-content-start py-2" :class="{'border-bottom-0': form1Index !== valueFormsField1.length - 1}">
            <input type="text" class="form-control" v-model="form1.values[1].value">
        </div>
        <div class="p2 col-md-5 border border-dark text-dark bg-white d-flex align-items-center justify-content-start py-2" :class="{'border-bottom-0': form1Index !== valueFormsField1.length - 1}">
            <input type="text" class="form-control" v-model="form1.values[2].value">
        </div>
        <div class="p2 col-md-1 d-flex align-items-end justify-content-start" v-if="form1Index === valueFormsField1.length - 1">
            <input type="button" class="btn btn-black btn-sm" value="1行増やす" @click="addLine(1)">
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　緊急時における活動
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 text-dark bg-light-gray d-flex align-items-center justify-content-start py-2">
            <label class="custom-control-label" for="customRadioInline1">チェック</label>
        </div>
        <div class="p2 col-md-5 border border-dark border-end-0 border-bottom-0 text-dark bg-light-gray d-flex align-items-center justify-content-start py-2">
            <label class="custom-control-label" for="customRadioInline1">活動例</label>
        </div>
        <div class="p2 col-md-5 border border-dark border-bottom-0 text-dark bg-light-gray d-flex align-items-center justify-content-start py-2">
            <label class="custom-control-label" for="customRadioInline1">補足</label>
        </div>
    </div>

    <div class="row ms-3" v-for="(form2, form2Index) in valueFormsField2" :key="form2Index">
        <div class="p2 col-md-1 border border-dark border-end-0 text-dark bg-light-gray d-flex align-items-center justify-content-center py-2" :class="{'border-bottom-0': form2Index !== valueFormsField2.length - 1}">
            <input type="checkbox" :id="'customRadioInline2' + form2Index" :name="'customRadioInline2' + form2Index" class="custom-control-input" v-model="form2.values[0].value">
        </div>
        <div class="p2 col-md-5 border border-dark border-end-0 text-dark bg-white d-flex align-items-center justify-content-start py-2" :class="{'border-bottom-0': form2Index !== valueFormsField2.length - 1}">
            <input type="text" class="form-control" v-model="form2.values[1].value">
        </div>
        <div class="p2 col-md-5 border border-dark text-dark bg-white d-flex align-items-center justify-content-start py-2" :class="{'border-bottom-0': form2Index !== valueFormsField2.length - 1}">
            <input type="text" class="form-control" v-model="form2.values[2].value">
        </div>
        <div class="p2 col-md-1 d-flex align-items-end justify-content-start" v-if="form2Index === valueFormsField2.length - 1">
            <input type="button" class="btn btn-black btn-sm" value="1行増やす" @click="addLine(2)">
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-advanced" value="上級｜5. 緊急時におけるBCP発動 終了ページへ" style="font-size:10.5pt" :disabled="isProcessing" @click="showAdvancedComplete">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.bcp_values_info).length > 0) {
                valueFormsField1.splice(0);
                valueFormsField2.splice(0);
                Object.values(props.bcp_values_info).forEach(fetchData =>{
                    // フィールド１
                    if (fetchData[0].field_number === 1) {
                        valueFormsField1.push({
                            fieldNumber: fetchData[0].field_number,
                            columnName: fetchData[0].column_name,
                            values: [
                                {value: ''},
                                {value: ''},
                                {value: ''},
                            ],
                        });
                        fetchData.forEach((val, index) => {
                            if (index === 0) {
                                // チェックボックスの場合はbooleanに変換
                                valueFormsField1[valueFormsField1.length - 1].values.splice(val.seq - 1, 1, {value: val.value == 1 ? true : false});
                            } else {
                                valueFormsField1[valueFormsField1.length - 1].values.splice(val.seq - 1, 1, {value: val.value});
                            }
                        });
                    }
                    // フィールド２
                    if (fetchData[0].field_number === 2) {
                        valueFormsField2.push({
                            fieldNumber: fetchData[0].field_number,
                            columnName: fetchData[0].column_name,
                            values: [
                                {value: ''},
                                {value: ''},
                                {value: ''},
                            ],
                        });
                        fetchData.forEach((val, index) => {
                            if (index === 0) {
                                // チェックボックスの場合はbooleanに変換
                                valueFormsField2[valueFormsField2.length - 1].values.splice(val.seq - 1, 1, {value: val.value == 1 ? true : false});
                            } else {
                                valueFormsField2[valueFormsField2.length - 1].values.splice(val.seq - 1, 1, {value: val.value});
                            }
                        });
                    }
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('000100');
        const isProcessing = ref(false);
        const pageName = 'S13';
        const setDefaultFieldNumber = 1;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // フィールド１
        const valueFormsField1 = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'J00043',
                values: [
                    {value: true},
                    {value: '地域の自主防災組織の活動に対して、ノウハウや人手、資金等の提供協力をする。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'J00044',
                values: [
                    {value: true},
                    {value: '地域住民と共同で防災訓練を立案・実施する。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'J00045',
                values: [
                    {value: false},
                    {value: ''},
                    {value: ''},
                ],
            },
        ]);

        // フィールド２
        const valueFormsField2 = reactive([
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00046',
                values: [
                    {value: true},
                    {value: '独居高齢者世帯等に対して声掛けを行う（避難勧告発令時、避難生活時等）'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00047',
                values: [
                    {value: true},
                    {value: '周辺住家の被災状況を把握し、救出・応急救護・初期消火に協力する。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00048',
                values: [
                    {value: true},
                    {value: '被災した住家の後片付け等を手伝う。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00049',
                values: [
                    {value: true},
                    {value: '従業員に対し、ボランティアとして登録・活動することを推奨する。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00050',
                values: [
                    {value: true},
                    {value: '施設を避難所として提供する（学校等の公的避難所が使用できない場合等）'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00051',
                values: [
                    {value: true},
                    {value: '在庫商品を提供する。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00052',
                values: [
                    {value: true},
                    {value: '貯水タンクの水や備蓄用品・資機材を供出する。'},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'J00053',
                values: [
                    {value: false},
                    {value: ''},
                    {value: ''},
                ],
            },
        ]);

        // 1行増やすボタン
        const addLine = (targetField) => {
            // カラム名の最大を取得
            const valueFormsFieldAll = valueFormsField1.concat(valueFormsField2);
            valueFormsFieldAll.sort((a, b) => {
                return a.columnName.substring(1) - b.columnName.substring(1);
            });
            const columnNameMax = parseInt(valueFormsFieldAll[valueFormsFieldAll.length - 1].columnName.substring(1));

            if (targetField === setDefaultFieldNumber) {
                valueFormsField1.push({
                    fieldNumber: targetField,
                    columnName: 'J' + ('00000' + (columnNameMax + 1)).slice(-5),
                    values: [
                        {value: false},
                        {value: ''},
                        {value: ''},
                    ],
                });
            } else {
                valueFormsField2.push({
                    fieldNumber: targetField,
                    columnName: 'J' + ('00000' + (columnNameMax + 1)).slice(-5),
                    values: [
                        {value: false},
                        {value: ''},
                        {value: ''},
                    ],
                });
            }
        }

        const showBack = async () => {
            // 活動を登録
            const result = await registerSecond13();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showContents = async () => {
            // 活動を登録
            const result = await registerSecond13();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showAdvancedComplete = async () => {
            // 活動を登録
            const result = await registerSecond13();

            if (result == '200') {
                const url = '/bcp/bcp_second/advanced_complete';
                location.href = url;
            }
        }

        // 活動登録
        const registerSecond13 = async () => {
            // リクエスト内容の生成
            const reqValueForms = [];
            // フィールド1
            valueFormsField1.forEach(data => {
                if (data.values[1].value.length !== 0 || data.values[2].value.length !== 0) {
                    // 活動例か補足のどちらかに入力がある場合に登録値として設定
                    data.values.forEach((val, index) => {
                        if(val.value.length !== 0){
                            reqValueForms.push({
                                fieldNumber: data.fieldNumber,
                                columnName: data.columnName,
                                seq: index + 1,
                                value: val.value,
                            });
                        }
                    })
                }
            });
            // フィールド2
            valueFormsField2.forEach(data => {
                if (data.values[1].value.length !== 0 || data.values[2].value.length !== 0) {
                    // 活動例か補足のどちらかに入力がある場合に登録値として設定
                    data.values.forEach((val, index) => {
                        if(val.value.length !== 0){
                            reqValueForms.push({
                                fieldNumber: data.fieldNumber,
                                columnName: data.columnName,
                                seq: index + 1,
                                value: val.value,
                            });
                        }
                    })
                }
            })

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond13';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            setDefaultFieldNumber,
            callReplaceWord,
            valueFormsField1,
            valueFormsField2,
            addLine,
            showBack,
            showContents,
            showAdvancedComplete,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    }

}
</script>

