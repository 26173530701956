<template>
        <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">団体所属会員の新規登録</div>
            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">団体会員</label></div>
        </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>会員番号</th>
                        <th>団体会員の名称</th>
                        <th>団体会員コード</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ corp_info.企業ID }}</td>
                        <td>{{ corp_info.組織名 }}</td>
                        <td>{{ corp_info.団体会員コード }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-5">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">団体所属会員</label></div>
        </div>
        <form @submit.prevent="post_form">
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">団体所属会員の名称</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" maxlength="100" required v-model="input組織名"/>
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">郵便番号</label>
                </div>
                <div class="col-md-2">
                    <input type="text" class="form-control" maxlength="7" v-model="input郵便番号" @blur="getAddress" :class="{'is-invalid': 郵便番号_has_error}" />
                    <div class='invalid-feedback'>{{ 郵便番号_message }}</div>
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">都道府県</label>
                </div>
                <div class="col-md-2">
                    <input type="text" class="form-control" maxlength="10" v-model="input都道府県" :class="{'is-invalid': 郵便番号_has_error}" />
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">市区町村</label>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input市区町村" maxlength="50" :class="{'is-invalid': 郵便番号_has_error}" />
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">町名番地、ビル名等</label>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input番地" maxlength="255" :class="{'is-invalid': 郵便番号_has_error}" />
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">
                        管理者名
                    </label>
                    <span class="badge rounded-pill bg-danger ms-2">必須</span>
                </div>
                <label class="col-md-1 col-form-label">
                    姓
                </label>
                <div class="col-md-2">
                    <input type="text" class="form-control" v-model="input管理者名_姓" required maxlength="255"/>
                </div>
                <label class="col-md-1 col-form-label">
                    名
                </label>
                <div class="col-md-2">
                    <input type="text" class="form-control" v-model="input管理者名_名" required maxlength="255"/>
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">
                        所属部署
                    </label>
                    <span class="badge rounded-pill bg-danger ms-2">必須</span>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input管理者_所属部署" required maxlength="255"/>
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">
                        役職
                    </label>
                    <span class="badge rounded-pill bg-danger ms-2">必須</span>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input管理者_役職" required maxlength="255"/>
                </div>
            </div>
            <div class="row mt-3 ms-5">
                <div class="col-md-2">
                    <label class="col-form-label">
                        メールアドレス
                    </label>
                </div>
                <div class="col-md-6">
                    <input type="mail" class="form-control" v-model="input管理者_メールアドレス" maxlength="255" :class="{'is-invalid': メールアドレス_has_error}" />
                    <div class='invalid-feedback'>{{ メールアドレス_message }}</div>
                </div>
            </div>
            <div class="row mt-5 mb-5">
                <div class="col-md-2 offset-md-2">
                    <input type="submit" class="btn btn-black" value="更新" :disabled="isProcessing" />
                </div>
                <div class="col-md-2">
                    <input type="button" class="btn btn-red" value="削除" :disabled="isProcessing" @click="deleteMember" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        onMounted(() => {
            input組織名.value = props.corp_member_info.団体所属組織名;
            input郵便番号.value = props.corp_member_info.郵便番号;
            input都道府県.value = props.corp_member_info.都道府県;
            input市区町村.value = props.corp_member_info.市区町村;
            input番地.value = props.corp_member_info.番地;
            input管理者名_姓.value = props.corp_member_info.管理者名_姓;
            input管理者名_名.value = props.corp_member_info.管理者名_名;
            input管理者_所属部署.value = props.corp_member_info.管理者_所属部署;
            input管理者_役職.value = props.corp_member_info.管理者_役職;
            input管理者_メールアドレス.value = props.corp_member_info.管理者_メールアドレス;
        })

        const isProcessing = ref(false);

        const back = () => {
            const url = '/s-admin/showGroupMember/' + props.corp_info.id;

            location.href = url;
        }

        const input組織名 = ref('');
        const input郵便番号 = ref('');
        const input都道府県 = ref('');
        const input市区町村 = ref('');
        const input番地 = ref('');
        const input管理者名_姓 = ref('');
        const input管理者名_名 = ref('');
        const input管理者_所属部署 = ref('');
        const input管理者_役職 = ref('');
        const input管理者_メールアドレス = ref('');
        

        const getAddress = () => {
            if(input郵便番号.value){
                const url = '/api/getAddressSeparate?zip=' + input郵便番号.value;

                axios.get(url).then(res =>{
                    input都道府県.value = res.data.prefecture;
                    input市区町村.value = res.data.municipalitie + res.data.town;
                });
            }else{
                input都道府県.value = '';
                input市区町村.value = '';
            }
        }

        const 郵便番号_has_error = ref(false);
        const 郵便番号_message = ref('');
        const メールアドレス_has_error = ref(false);
        const メールアドレス_message = ref('');

        const post_form = () => {
            郵便番号_has_error.value = false;
            郵便番号_message.value = "";
            メールアドレス_has_error.value = false;
            メールアドレス_message.value = "";

            const url = '/api/s-admin/updateGroupMember';

            isProcessing.value = true;

            axios.post(url, {
                'id': props.corp_member_info.id,
                '団体会員ID': props.corp_info.企業ID,
                '団体所属組織名' : input組織名.value,
                '団体会員コード': props.corp_info.団体会員コード,
                '郵便番号' : input郵便番号.value,
                '都道府県' : input都道府県.value,
                '市区町村' : input市区町村.value,
                '番地' : input番地.value,
                '管理者名_姓' : input管理者名_姓.value,
                '管理者名_名' : input管理者名_名.value,
                '管理者_所属部署' : input管理者_所属部署.value,
                '管理者_役職' : input管理者_役職.value,
                '管理者_メールアドレス' : input管理者_メールアドレス.value,
            }).then(res => {
                const url = '/s-admin/showGroupMember/' + props.corp_info.id;

                location.href = url;
            }).catch(err=>{
                if(err.response.status === 422){
                    if(err.response.data.errors.郵便番号){
                        郵便番号_has_error.value = true;
                        郵便番号_message.value = '住所もしくはメールアドレスのいずれかを入力してください。';
                    }
                    if(err.response.data.errors.管理者_メールアドレス){
                        メールアドレス_has_error.value = true;
                        メールアドレス_message.value = '住所もしくはメールアドレスのいずれかを入力してください。';
                    }
                }
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const deleteMember = () => {
            const url = '/api/s-admin/deleteGroupMember';

            isProcessing.value = true;

            axios.delete(url, {data: {id: props.corp_member_info.id}})
            .then(res => {
                const url = '/s-admin/showGroupMember/' + props.corp_info.id;

                location.href = url;
            })
            .finally(fin => {
                isProcessing.value = false;
            });

        }

        return {
            isProcessing,

            back,
            input組織名,
            input郵便番号,
            input都道府県,
            input市区町村,
            input番地,
            input管理者名_姓,
            input管理者名_名,
            input管理者_所属部署,
            input管理者_役職,
            input管理者_メールアドレス,

            getAddress,

            post_form,

            deleteMember,

            郵便番号_has_error,
            郵便番号_message,
            メールアドレス_has_error,
            メールアドレス_message,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        corp_member_info: {
            type: Object,
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-middle{
        vertical-align: middle;
    }

    .t-center {
        text-align: center;
    }
</style>