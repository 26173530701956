<template>
    <div id="confirm-modal" v-show="participantModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeParticipantModal"></div>
            <div class="row mt-3 ms-auto">
                <template v-if="participantInfo.length > 0">
                    <div class="col-md align-items-center justify-content-start">
                        <template v-for="participant, index in participantInfo" :key="index">
                            <div class="align-items-center justify-content-start d-flex">
                                <div class="col-md border border-dark bg-white text-dark d-flex align-items-center justify-content-center py-2"
                                    :class="{'border-bottom-0': index !== participantInfo.length - 1}"
                                >
                                    {{ participant.participant }}
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md ms-auto d-flex align-items-center justify-content-center py-2">
                        参加者がいません
                    </div>
                </template>
            </div>
            <div class="row mt-3 ms-auto align-items-center justify-content-center py-2">
                <button class="col-md-5 btn btn-gray " @click="closeParticipantModal">閉じる</button>
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="deleteModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeDeleteModal"></div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-10 align-items-start">
                    登録一覧から、<br>
                 <div class="color-resilience-light-blue">{{ selectedEducationInfo.educationName }}</div>
                    を削除します。よろしいですか？（復元不可）
                </div>
            </div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-3 d-flex align-items-start">
                    <button class="btn btn-bcp-blue " :disabled="isProcessing" @click="deleteEducationPlan">はい</button>
                </div>
                <div class="col-md-3 d-flex align-items-start">
                    <button class="btn btn-gray " :disabled="isProcessing" @click="closeDeleteModal">キャンセル</button>
                </div>
            </div>
            <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="setMailModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeSetMailModal"></div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-10 align-items-start">
                    登録一覧から、
                    <div class="color-resilience-light-blue">
                        {{ selectedEducationInfo.educationName }}
                    </div>
                    を実施します。実施日時をご指定ください。
                </div>
            </div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-4 py-2">
                    <input type="date" class="form-control" v-model="implementationDate" @change="getWeekDay">
                </div>
                <div class="col-md-2 py-2">
                    {{ dispWeekDay }}
                </div>
                <div class="col-md-3 py-2">
                    <input type="time" min="00:00:00" class="form-control" v-model="implementationTime" @change="inputDateTime">
                </div>
                <div class="col-md-1 py-2">
                    ～
                </div>
            </div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-5 d-flex align-items-start">
                    <button class="btn btn-green" :disabled="isProcessing || isNotInput" @click="deliveryMail">参加者へ今すぐ配信する</button>
                </div>
                <div class="col-md-3 d-flex align-items-start">
                    <button class="btn btn-gray " :disabled="isProcessing" @click="closeSetMailModal">キャンセル</button>
                </div>
            </div>
            <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
        </div>
    </div>

    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            教育計画一覧から実施
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-3">
        <div class="row ms-3 mt-3">
            <template v-for="planInfo, index in educationPlanInfo" :key="index">
                <div class="d-flex col-md-10">
                    <table class="table table-bordered border-dark col-md-11 mt-3">
                        <thead v-if="index === 0">
                            <tr>
                                <td class="bg-light-gray text-dark" style="width: 3%;">No</td>
                                <td class="bg-light-gray text-dark" style="width: 45%;">教育計画のテーマ・目的</td>
                                <td class="bg-light-gray text-dark">教育の方法</td>
                                <td class="bg-light-gray text-dark" style="width: 10%;">参加者｜{{ callReplaceWord('自社') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-items-center" style="width: 5%;">
                                    {{ index + 1 }}
                                </td>
                                <td class="" style="width: 45%;">
                                    {{ planInfo.theme }}
                                </td>
                                <td class="">
                                    <template v-if="educationMethodList.find(data => data.id == planInfo.educationMethod)">
                                        <template v-if="educationMethodList.find(data => data.id == planInfo.educationMethod).id == educationMethodList[4].id">
                                            {{ educationMethodList.find(data => data.id == planInfo.educationMethod).methodName }} ：
                                            {{ planInfo.educationMethodOther }}
                                        </template>
                                        <template v-else>
                                            {{ educationMethodList.find(data => data.id == planInfo.educationMethod).methodName }}
                                        </template>
                                    </template>
                                </td>
                                <td class="" style="width: 10%;">
                                    <input type="button" class="btn btn-green" value="参加者一覧" style="font-size:10.5pt" @click="showParticipantModal(planInfo)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-md-1 ms-5 mt-3">
                        <div class="row col d-flex btn-sm justify-content-center align-items-center py-2" v-if="index === 0">
                            &emsp;
                        </div>
                        <div class="row">
                            <button class="col d-flex btn-gray btn-sm border border-white justify-content-center align-items-center py-2"
                                style="font-size:10.5pt" @click="showTargetEducationPlan(planInfo.educationPlanId)"
                            >
                                編集
                            </button>
                        </div>
                        <div class="row">
                            <button class="col d-flex btn-gray btn-sm border border-white justify-content-center align-items-center py-2"
                                style="font-size:10.5pt" @click="showDeleteModal(planInfo)"
                            >
                                削除
                            </button>
                        </div>
                        <div class="row">
                            <button class="col d-flex btn-green btn-sm border border-white justify-content-center align-items-center py-2"
                                style="font-size:10.5pt" @click="showSetMailModal(planInfo)"
                            >
                                日時を決めて<br>今すぐ配信
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-2 d-flex align-items-start">
            <input type="button" class="btn btn-bcp-blue" value="新規作成" style="font-size:10.5pt" :disabled="isProcessing" @click="showRegister">
        </div>
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>

</template>

<script>
import { onMounted,  ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.education_plan_list).length > 0) {
                // 教育計画情報
                Object.values(props.education_plan_list).forEach((fetchData, index) => {
                    educationPlanInfo.value.push({
                        educationPlanId: fetchData.education_plan_id,
                        theme: fetchData.theme,
                        educationMethod: fetchData.education_method,
                        educationMethodOther: fetchData.education_method_other,
                        participants: [],
                    });
                    // 参加者
                    if (typeof fetchData.participants !== 'undefined') {
                        educationPlanInfo.value[index].participants = Object.values(fetchData.participants).map(
                            fetchParticipant => ({
                                participant: fetchParticipant.participant,
                            })
                        );
                    }
                })
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011111');
        const isProcessing = ref(false);
        const isNotInput = ref(true);
        const selectedEducationInfo = ref({});
        const dispWeekDay = ref('');
        const implementationDate = ref('');
        const implementationTime = ref('');

        const participantModalVisible = ref(false);
        const deleteModalVisible = ref(false);
        const setMailModalVisible = ref(false);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');
        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false;
                window.location.reload();
                isProcessing.value = false;
            }, 3000)
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 教育の方法
        const educationMethodList = [
            {id: 1, methodName: '社内ディスカッション（BCPの周知・防災）'},
            {id: 2, methodName: '防災に関する勉強会への参加'},
            {id: 3, methodName: '応急救護の受講（心肺蘇生法等）'},
            {id: 4, methodName: 'BCPや防災対策関連のセミナーへの参加'},
            {id: 5, methodName: 'その他'},
        ]

        // 曜日
        const weekdayList = [
            {id: 0, weekday: '日曜日'},
            {id: 1, weekday: '月曜日'},
            {id: 2, weekday: '火曜日'},
            {id: 3, weekday: '水曜日'},
            {id: 4, weekday: '木曜日'},
            {id: 5, weekday: '金曜日'},
            {id: 6, weekday: '土曜日'},
        ]

        // 教育計画情報
        const educationPlanInfo = ref([]);
        // 参加者情報
        const participantInfo = ref([]);

        // 参加者モーダル
        const showParticipantModal = planInfo => {
            participantInfo.value.splice(0);
            participantInfo.value = planInfo.participants.map(
                fetchData => ({
                    participant: fetchData.participant
                })
            );
            participantModalVisible.value = true;
        }

        // 削除モーダル
        const showDeleteModal = planInfo => {
            selectedEducationInfo.value = {
                educationPlanId: planInfo.educationPlanId,
                educationName: planInfo.theme,
            }
            deleteModalVisible.value = true;
        }

        // メール送信モーダル
        const showSetMailModal = planInfo => {
            // 表示初期化
            implementationDate.value = '';
            implementationTime.value = '';
            dispWeekDay.value = '';

            selectedEducationInfo.value = {
                educationPlanId: planInfo.educationPlanId,
                educationName: planInfo.theme,
            }
            setMailModalVisible.value = true;
        }

        const closeParticipantModal = () => {
            participantModalVisible.value = false;
        }

        const closeDeleteModal = () => {
            deleteModalVisible.value = false;
        }

        const closeSetMailModal = () => {
            setMailModalVisible.value = false;
            isNotInput.value = true;
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showRegister = () => {
            const url = '/fixation/k1';
            location.href = url;
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        const showTargetEducationPlan = (targetTag) => {
            const url = `/fixation/k1#list-${targetTag}`;
            // 選択された訓練計画にスクロール
            location.href = url;
        }

        const getWeekDay = e => {
            const dObj = new Date(e.target.value);
            const wDay = dObj.getDay();
            dispWeekDay.value = weekdayList[wDay].weekday
            inputDateTime();
        }

        // 実施日時入力判定
        const inputDateTime = () => {
            if (implementationDate.value && implementationTime.value) {
                isNotInput.value = false;
            } else {
                isNotInput.value = true;
            }
        }

        // 教育計画削除
        const deleteEducationPlan = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/deleteEducationPlan';

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'selected_education_plan_id': selectedEducationInfo.value.educationPlanId,
            }).then(res => {
                visibleFlashMessage('正常に削除されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                // isProcessing.value = false;
            });
        }

        // メール配信
        const deliveryMail = async() => {
            isProcessing.value = true;
            const url = '/api/fixation/sendEducationMail';

            return await axios.post(url, {
                'corp_id': props.corp_info.id,
                'education_plan_id': selectedEducationInfo.value.educationPlanId,
                'implementation_date': implementationDate.value,
                'implementation_time': implementationTime.value,
            }).then(res => {
                visibleFlashMessage('送信しました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('送信に失敗しました', 'danger');
                return err.response?.status;
            })
            .finally(fin => {
                // isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            isNotInput,
            selectedEducationInfo,
            dispWeekDay,
            implementationDate,
            implementationTime,
            participantModalVisible,
            deleteModalVisible,
            setMailModalVisible,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            callReplaceWord,
            educationMethodList,
            weekdayList,
            educationPlanInfo,
            participantInfo,
            showParticipantModal,
            showDeleteModal,
            showSetMailModal,
            closeParticipantModal,
            closeDeleteModal,
            closeSetMailModal,
            showBack,
            showRegister,
            showTop,
            showTargetEducationPlan,
            getWeekDay,
            inputDateTime,
            deleteEducationPlan,
            deliveryMail,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        education_plan_list: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-light-gray {
    height: 39.5px;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 40%;
    padding: 1em 3em;
    background: #F2F2F2;
}

.btn-close {
    position: relative;
    top: 0px;
    left: 680px;
}

.btn-close:hover{
    cursor: pointer;
}

</style>
