<template>
    <div class="row">
        <div>
            <div class="row mt-3 pb-5">
                <i id="safety-logo"></i>
            </div>
            <div class="row my-5">
                <div class="col-md-4 px-5">
                    <div class="row">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showMessage">安否メッセージ</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showEveryoneSafety">みんなの安否</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showMessage = () => {
            const url ='/family/家1';

            location.href = url;
        }

        const showEveryoneSafety = () => {
            const url ='/family/家2';

            location.href = url;
        }

        return {
            showMessage,
            showEveryoneSafety,
        }
    },
}
</script>

<style lang="scss" scoped>
#safety-logo {
    display: inline-block;
    width: 703px;
    height: 283px;
    background-image: url('../../../svg/13-05_anpi.svg');
    background-size: 703px auto;
    background-repeat: no-repeat;
}

.menu-button {
    background-color: #808080;
    border: 1px solid white;
    color: white;
}

@media (max-width: 767px) {
    #safety-logo {
        display: inline-block;
        width: 300px;
        height: 120.8px;
        background-image: url('../../../svg/13-05_anpi.svg');
        background-size: 300px auto;
        background-repeat: no-repeat;
    }
}


</style>
