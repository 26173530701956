<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式10</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 避難</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">避難計画シート｜屋外避難用</label>
                </div>
            </div>
            <template v-for="item in evacuationLocationsInfo">
                <div class="row mt-3 mx-1">
                    <div class="p2 col-6 col-md-3 border d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.evacuationLocation || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-6 col-md-3 d-flex align-items-center justify-content-start">
                        <label class="form-label"> への避難計画</label>
                    </div>
                </div>
                <div class="row mt-3 mx-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label text-md-nowrap">{{ callReplaceWord('組織') }}から避難が必要となった場合にするべき事項</label>
                    </div>
                    <div class="p2 col-md-9 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.refugeShouldBeMatter || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">集合場所</label>
                    </div>
                    <div class="p2 col-md-9 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.setLocation || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">集合場所責任者</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.setLocationManagerName || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">代理責任者</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.setLocationSubManagerName || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-3 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">上記責任者の責務</label>
                    </div>
                    <div class="p2 col-md-9 bg-white border d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.setLocationManagerDuty || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1 mt-3">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">業務停止責任者</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.businessStopManagerName || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">代理責任者</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.businessStopSubManagerName || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-3 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">上記責任者の責務</label>
                    </div>
                    <div class="p2 col-md-9 bg-white border d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.businessStopManagerDuty || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1 mt-3">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">避難解除責任者</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.refugeCancellationManagerName || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">代理責任者</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.refugeCancellationSubManagerName || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">非常口の場所</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.emergencyExitLocation || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">避難訓練の実施回数</label>
                    </div>
                    <div class="p2 col-md-3 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white">{{ item.trainingImplementationTimes == null ? '&emsp;' : item.trainingImplementationTimes }}</div>
                        <label class="col-form-label text-nowrap">回／年</label>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考</label>
                    </div>
                    <div class="p2 col-md-9 bg-white border border-bottom-0 d-flex align-items-center justify-content-start">
                        <div class="form-control border-white bg-white show-note">{{ item.note || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-md-12 border bg-white d-flex align-items-center justify-content-start">
                        <div class="py-3">
                            <p>避難場所の地図</p>
                            <img :src="`data:image/png;base64,${item.image}`" alt="">
                        </div>
                    </div>
                </div>
                <br>
            </template>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let evacuationLocationsInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K9';
            location.href = url;
        }

        const showNextForAdmin = async () => {
            const url = '/browse/K11';
            location.href = url;
        }

        const showNextForUser = async () => {
            const url = '/browse/K12';
            location.href = url;
        }

        if (props.evacuation_locations_info.length > 0) {
            evacuationLocationsInfo = props.evacuation_locations_info.map(
                (fetchData) => ({
                    evacuationLocation: fetchData?.evacuation_location,
                    refugeShouldBeMatter: fetchData?.refuge_should_be_matter,
                    setLocation: fetchData?.set_location,
                    setLocationManagerName: fetchData?.set_location_manager_name,
                    setLocationSubManagerName: fetchData?.set_location_sub_manager_name,
                    setLocationManagerDuty: fetchData?.set_location_manager_duty,
                    businessStopManagerName: fetchData?.business_stop_manager_name,
                    businessStopSubManagerName: fetchData?.business_stop_sub_manager_name,
                    businessStopManagerDuty: fetchData?.business_stop_manager_duty,
                    refugeCancellationManagerName: fetchData?.refuge_cancellation_manager_name,
                    refugeCancellationSubManagerName: fetchData?.refuge_cancellation_sub_manager_name,
                    emergencyExitLocation: fetchData?.emergency_exit_location,
                    trainingImplementationTimes: fetchData?.training_implementation_times,
                    note: fetchData?.note,
                    mapId: fetchData?.map_id,
                    fileName: fetchData?.name,
                    image: fetchData?.image,
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            evacuationLocationsInfo,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        evacuation_locations_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

img {
    width: 100%;
}

.show-note {
    white-space: pre-wrap;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-9 {
        width: 75%;
    }
}
</style>
