<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            2.　BCPの運用体制｜BCPの策定・運用体制
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-advanced btn-sm" value="上級｜2.BCPの運用体制 複数企業連携によるBCPの策定・運用体制 へ進む" v-show="is_completed_middle" :disabled="isProcessing" @click="showAdvanced">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 bg-sub-title py-2">
            ◯　BCPの策定体制
        </div>
    </div>

    <div v-for="(form1, form1Index) in valueFormsField1" :key="form1Index">
        <div class="row ms-3 mt-3">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start">
                <span v-if="form1Index === 0">責任者</span>
                <span v-else-if="form1Index === 1">サブリーダー</span>
                <span v-else-if="form1Index === 2">事業資源担当（復旧対応）</span>
                <span v-else-if="form1Index === 3">財務担当（財務管理）</span>
                <span v-else-if="form1Index === 4">従業員支援担当（後方支援）</span>
                <span v-else-if="form1Index === 5">責任者</span>
            </div>
        </div>
        <div class="row ms-3 mt-3" v-if="form1Index === 1">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start">
                {{ callReplaceWord('顧客') }}・協力会社担当（外部対応）
            </div>
        </div>

        <div class="row ms-3">
            <div class="row col-md-4">
                <div class="d-flex align-items-center justify-content-center py-2">
                    <select name="selectAdminCandidate" class="form-select" v-model="form1.value">
                        <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                    </select>
                </div>
            </div>
            <div class="p2 col-md-1 d-flex align-items-start justify-content-start" v-if="form1Index === 0 || form1Index === 1">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label v-if="form1Index === 0">BCPの策定・運用は最重要の経営課題であり、経営者のリーダーシップが不可欠です。責任者は、経営者自ら担います。</label>
                            <label v-else>サブリーダーは、企業の規模や業務の役割分担に応じて複数名選任します。<br />家族経営のような企業では経営者1人でも構いませんが、総務、財務、労務、技術、営業など役割分担が決まっている場合は、各部署からサブリーダーを参画させてください。また、女性特有の問題を共有するために、女性を参画させてください。障がいを持っている人、育児や介護を行っている人など、それぞれの事情について配慮できるようにするためには、計画策定への当事者の参画が不可欠です。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 bg-sub-title py-2">
            ◯　平常時におけるBCPの運用推進体制
        </div>
    </div>
    <div v-for="(form2, form2Index) in valueFormsField2" :key="form2Index">
        <div class="row ms-3 mt-3">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                <span v-if="form2Index === 0">①責任者</span>
                <span v-else-if="form2Index === 1">②サブリーダー</span>
                <span v-else-if="form2Index === 2">事業資源担当（復旧対応）</span>
                <span v-else-if="form2Index === 3">財務担当（財務管理）</span>
                <span v-else-if="form2Index === 4">従業員支援担当（後方支援）</span>
                <span v-else-if="form2Index === 5">③　連携する取引先{{ callReplaceWord('組織') }}や協力{{ callReplaceWord('組織') }}</span>
                <span v-else-if="form2Index === 6">④　一緒に取組む組合等の組織</span>
                <span v-else-if="form2Index === 7">⑤　BCP運用の対象者</span>
                <span v-else>⑤　BCP運用の対象者</span>
            </div>
            <div class="row mt-3" v-if="form2Index === 1">
                <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start">
                    {{ callReplaceWord('顧客') }}・協力会社担当（外部対応）
                </div>
            </div>
        </div>

        <div class="row ms-3" v-for="(form2Values, valIndex) in form2.values" :key="valIndex">
            <div class="p2 col-md-4 d-flex align-items-center justify-content-start" :class="{'mt-sm-3':valIndex > 0}" v-if="form2Index <= 4">
                <select name="selectAdminCandidate" class="form-select" v-model="form2Values.value">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
            <div class="p2 mt-3 col-md-6 d-flex align-items-center justify-content-start" :class="{'mt-sm-3':valIndex > 0}" v-if="form2Index > 4">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" :placeholder="form2Values.placeholder" v-model="form2Values.value">
            </div>
            <div class="p2 col-1 d-flex align-items-start justify-content-start">
                <VDropdown
                    theme="hint-theme"
                    v-if="valIndex === 0 && (form2Index === 0 || form2Index === 1 || form2Index >= 5)"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label v-if="form2Index === 0">BCPの策定・運用は最重要の経営課題であり、経営者のリーダーシップが不可欠です。責任者は、経営者自ら担います。</label>
                            <label v-if="form2Index === 1">サブリーダーは、企業の規模や業務の役割分担に応じて複数名選任します。<br />家族経営のような企業では経営者1人でも構いませんが、総務、財務、労務、技術、営業など役割分担が決まっている場合は、各部署からサブリーダーを参画させてください。</label>
                            <label v-if="form2Index === 5">サプライチェーンにおいて取引のある企業のうち、製品やサービスを提供する顧客のこと。<br />財や情報の流れで下流に位置します。緊急時の事業継続には取引先企業や協力企業との連携が重要になります。<br />BCPに関する意見交換や摺り合わせをしばしば行うことをお勧めします。</label>
                            <label v-if="form2Index === 6">協同組合や商店街の加盟企業が連携してBCP策定・運用に取り組んだり、商工会や商工会議所でBCPに関する勉強会を開いたりすることも有効です。</label>
                            <label v-if="form2Index === 7">BCPの策定・運用推進に取り組んでいることを全ての従業員に周知し、参加意識を高める必要があります。<br />実際の緊急時には従業員の行動が計画の成否を左右します。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
            <div class="p2 ms-5 col-1 d-flex align-items-start justify-content-start" :class="{'mt-sm-3': valIndex !== 0}"
                v-if="valIndex === form2.values.length - 1 && form2.values.length < 30 && (form2Index === 0 || form2Index === 6 || form2Index === 5)"
            >
                <input type="button" class="btn btn-black" value="行を増やす" @click="addLineField2(form2.columnName)">
            </div>
            <div class="p2 ms-5 col-1 d-flex align-items-start justify-content-start" :class="{'mt-sm-3': valIndex !== 0}"
                v-if="valIndex !== form2.values.length - 1 && form2.values.length < 30 && (form2Index === 0 || form2Index === 6 || form2Index === 5)"
            >
                <input type="button" class="btn btn-delete" value="削除" @click="deleteLineField2(form2Index, valIndex)">
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 bg-sub-title py-2">
            ◯　緊急時におけるBCPの発動体制
        </div>
    </div>

    <div v-for="(form3, form3Index) in valueFormsField3" :key="form3Index">
        <div class="row ms-3 mt-3" v-if="form3Index === 0">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                ①責任者
            </div>
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                代行者
            </div>
        </div>

        <div class="row ms-3 mt-3" v-if="form3Index === 1">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                ②サブリーダー
            </div>
        </div>
        <div class="row ms-3 mt-3" v-if="form3Index === 1">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                {{ callReplaceWord('顧客') }}・協力会社担当（外部対応機能）
            </div>
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                代行者
            </div>
        </div>

        <div class="row ms-3 mt-3" v-if="form3Index === 2">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                事業資源担当（復旧対応機能）
            </div>
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                代行者
            </div>
        </div>

        <div class="row ms-3 mt-3" v-if="form3Index === 3">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                財務担当（財務管理機能）
            </div>
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                代行者
            </div>
        </div>
        <div class="row ms-3 mt-3" v-if="form3Index === 4">
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                従業員支援担当（後方支援）
            </div>
            <div class="p2 mt-3 col-md-4 text-dark d-flex align-items-center justify-content-start">
                代行者
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-4 d-flex align-items-center justify-content-start">
                <select name="selectAdminCandidate" class="form-select" v-model="form3.values[0].value">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
            <div class="p2 col-md-4 d-flex align-items-center justify-content-start">
                <select name="selectAdminCandidate" class="form-select" v-model="form3.values[1].value">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2 card-title h5">
        {{ callReplaceWord('従業員') }}携帯カード
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 mt-3 col-md-8 text-dark d-flex align-items-end justify-content-start">
            緊急事態に迅速な初動対応が出来るよう、すべての{{ callReplaceWord('従業員') }}に携帯させるとよいでしょう。
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <button type="button" class="p2 col-md-6 border btn btn-white py-2 bg-light">
            <div class="d-flex flex-row justify-content-center py-4">
                <!-- <img src="..." class="rounded float-left" alt="...">                -->
                <i id="card-logo"></i>
                <div class="display-6 ms-5">
                    携帯カード印刷
                </div>
            </div>
        </button>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>{{ callReplaceWord('従業員') }}携帯カードの入力内容には、{{ callReplaceWord('従業員') }}の機微情報や家族の個人情報が含まれているため、利用者ごとにマイページから印刷していただくよう周知してください。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 mx-auto col-md-4 d-flex align-items-start justify-content-start py-3">
            <input type="button" class="btn btn-bcp-blue px-4" :value="btnMessage" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mx-auto col-md-2 d-flex align-items-start justify-content-start py-3">
            <input type="button" class="btn btn-bcp-blue" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_values_info.length > 0) {
                props.bcp_values_info.forEach(fetchData =>{
                    // フィールド１
                    valueFormsField1.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.value = fetchData.value;
                        }
                    });
                    // フィールド２
                    valueFormsField2.forEach(initData =>{
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                    // フィールド３
                    valueFormsField3.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const pageName = 'F2';
        const setDefaultFieldNumber = 1;
        const setDefaultSeq = 1;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const btnMessage = '基本｜3.　中核' + callReplaceWord('事業') + 'と復旧目標 ｜中核' + callReplaceWord('事業') + 'に係る情報へ進む';

        const valueFormsField1 = reactive([]);
        for (let i = 12; i <= 16; i++) {
            valueFormsField1.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K' + ('00000' + i).slice(-5),
                value: '',
            });
        }

        const valueFormsField2 = reactive([]);
        for (let i = 17; i <= 24; i++) {
            const setValue = i === 24 ? {value: '従業員全員で運用する。'} : {value: ''};
            valueFormsField2.push({
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K' + ('00000' + i).slice(-5),
                values: [
                    setValue,
                ],
            });
        }

        const valueFormsField3 = reactive([]);
        for (let i = 25; i <= 29; i++) {
            valueFormsField3.push({
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K' + ('00000' + i).slice(-5),
                values: [
                    {value: ''},
                    {value: ''},
                ],
            });
        }

        const addLineField2 = (targetColumnName) => {
            valueFormsField2.forEach(data => {
                if(data.columnName == targetColumnName){
                    data.values.push({
                        value: ''
                    });
                }
            });
        }

        const deleteLineField2 = (field_no, index) => {
            let title = "";
            switch(field_no){
                case 0:
                    title="平常時におけるBCPの運用推進体制　①責任者の";
                    break;
                case 5:
                    title="平常時におけるBCPの運用推進体制　③　連携する取引先組織や協力組織";
                    break;
                case 6:
                    title="平常時におけるBCPの運用推進体制　④　一緒に取組む組合等の組織";
                    break;
                default:

            }

            if(!confirm(title + (index + 1) + "番目（" + valueFormsField2[field_no].values[index].value + "）を削除します。よろしいですか？")){
                return false;
            }

            valueFormsField2[field_no].values.splice(index, 1);
        }

        const showBack = async () => {
            // 運用体制を登録
            const result = await registerFirst2();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 運用体制を登録
            const result = await registerFirst2();

            if (result == '200') {
                const url = '/bcp/bcp_first/3';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 運用体制を登録
            const result = await registerFirst2();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showAdvanced = async () => {
            // 運用体制を登録
            const result = await registerFirst2();

            if (result == '200') {
                const url = '/bcp/bcp_multiple/1';
                location.href = url;
            }
        }

        // 運用体制登録
        const registerFirst2 = async () => {
            const reqValueForms = [];

            // フィールド1
            valueFormsField1.forEach(data => {
                if (data.value != '') {
                    // 入力済みの場合
                    reqValueForms.push({
                        fieldNumber: data.fieldNumber,
                        columnName: data.columnName,
                        seq: setDefaultSeq,
                        value: data.value,
                    });
                }
            });

            // フィールド２
            valueFormsField2.forEach(data => {
                let index = 1;
                data.values.forEach(val => {
                    if (val.value != '') {
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index ++,
                            value: val.value,
                        });
                    }
                });
            });

            // フィールド３
            valueFormsField3.forEach(data => {
                let index = 1;
                data.values.forEach(val => {
                    let selValue = val.value;
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index ++,
                            value: selValue,
                        });
                    }
                });
            });

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst2';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            btnMessage,
            valueFormsField1,
            valueFormsField2,
            valueFormsField3,
            addLineField2,
            deleteLineField2,
            showBack,
            showNext,
            showContents,
            showAdvanced,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
        admin_candidate_list: {
            type: Array,
        },
        introduction_manager_info: {
            type: Object,
        },
        is_completed_middle: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

.ms-add-line {
    margin-left: 10rem !important;
}

.bg-sub-title {
    background-color: #D9D9D9;
}

.card-title {
    color: common_colors.colors(resilience-orange);

    display: flex;
    align-items: center; /* 垂直中心 */
    justify-content: center; /* 水平中心 */
}

.card-title::after {
    border-top: 1px solid;
    content: "";
    width: 75%; /* 線の長さ */
}

#card-logo {
    display: inline-block;
    width: 75px;
    height: 60px;
    background-image: url('../../../../svg/08-card.svg');
    background-size: 75px auto;
    background-repeat: no-repeat;
}

</style>
