<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　中核{{ callReplaceWord('事業') }}と復旧目標｜中核{{ callReplaceWord('事業') }}影響度評価フォーム
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-al-inquiry-button btn-sm" value="中級｜訓練計画登録 へ進む">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-3" v-for="(disasterAssumption, index) in disasterAssumptionsInfo" :key="index">
        <div class="card bg-gray">
            <div class="card-body">
                <div class="row mt-3">
                    <div class="ms-3 col-md-1 border border-dark bg-light-gray text-center">
                        <label for="" class="col-form-label">{{ index + 1 }}</label>
                    </div>
                    <div class="ms-3 col-md-2 border-red bg-light">
                        <label for="" class="col-form-label font-red">災害想定</label>
                    </div>
                    <div class="col-md-6 border-red bg-red">
                        <label for="" class="col-form-label text-white">{{ index + 1 + ' ' + disasterAssumption.disasterAssumptionContent }}</label>
                    </div>
                    <div class="col-md-1">
                        <label for="" class="col-form-label">の場合</label>
                    </div>
                    <div class="ms-3 col-md-1 d-flex flex-row">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>中核事業が影響を受ける可能性が高いと思われる災害を想定します。<br />影響を受ける災害には様々なものがありますが、いくつかの代表的な災害を想定し、中核事業の被害を評価します。<br />ただしその際、災害として想定する規模（地震であれば震度）も同時に想定しておくようにして下さい。<br />規模の設定について、妥当な基準は一般的にはなく、経営者による意思確認は重要となります。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <template v-if="disasterAssumption.importantBusinesses.length > 0">
                    <template v-for="(importantBusiness, importantBusinessIndex) in disasterAssumption.importantBusinesses" :key="importantBusinessIndex">
                        <div class="row ms-3 mt-3">
                            <div class="p2 ms-4 col-md-8 d-flex align-items-center justify-content-start">
                                <select name="selectImportantBusiness" class="form-select form-control bg-resilience-orange d-flex align-items-center justify-content-center py-2"
                                    v-model="setImportantBusinessIds[index].importantBusinessIndexes[importantBusinessIndex]"
                                    @change="getRequiredResources(index, importantBusinessIndex, disasterAssumption.disasterAssumptionId)"
                                >
                                    <option type="text" ></option>
                                    <option type="text" v-for="importantBusinessList in important_businesses_info" :key="importantBusinessList" :value="importantBusinessList.id">
                                        {{ importantBusinessList.important_business_content }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3" v-for="(requiredResource, requiredResourceIndex) in importantBusiness.requiredResources" :key="requiredResourceIndex">
                            <div class="card bg-light-gray">
                                <div class="card-body">
                                    <div class="row">
                                        <label class="form-label">①中核{{ callReplaceWord('事業') }}の継続に必要な資源（ボトルネック資源）</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 ms-3">
                                            <input type="text" class="form-control" disabled :value="requiredResource.resource" />
                                        </div>
                                        <div class="col-md-2 ms-3" v-if="requiredResourceIndex === 0">
                                            <VDropdown
                                                theme="hint-theme"
                                            >
                                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                <template #popper>
                                                    <div class="row">
                                                        <label>事業の継続や業務復旧の際に、その部分に問題が発生すると全体の円滑な進行の妨げとなるような要素です。<br />つまり、「重要業務に不可欠な資源」は、同時に中核事業を継続するための障害となり、ボトルネック資源といいます。<br />この項目を書き換える場合は、「中核{{ callReplaceWord('事業') }}の継続に必要な重要業務」の「重要業務に必要な資源」に戻ります。</label>
                                                    </div>
                                                </template>
                                            </VDropdown>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <label class="form-label">②中核{{ callReplaceWord('事業') }}に対する重要度の大きさ</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 ms-3">
                                            <select class="form-select" v-model="requiredResource.importanceDimension"
                                                @change="culcImpact(index, importantBusinessIndex, requiredResourceIndex)"
                                            >
                                                <option v-for="evaluation in evaluation_resource_list" :key="evaluation.code" :value="parseInt(String(evaluation.code).slice(-1))">
                                                    {{ evaluation.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <label class="form-label">③想定している災害がボトルネックに与える影響</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-10 ms-3">
                                            <select class="form-select" v-model="requiredResource.impact"
                                                @change="culcImpact(index, importantBusinessIndex, requiredResourceIndex)"
                                            >
                                                <option v-for="bottleneck in impact_bottleneck_list" :key="bottleneck.code" :value="parseInt(String(bottleneck.code).slice(-1))">
                                                    {{ bottleneck.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <label class="form-label">④影響の大きさ（②×③）</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-1 ms-3">
                                            <input type="text" class="form-control" readonly="readonly" :value="requiredResource.impactDimension"/>
                                        </div>
                                        <div class="col-md-2 ms-3" v-if="requiredResourceIndex === 0">
                                            <VDropdown
                                                theme="hint-theme"
                                            >
                                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                <template #popper>
                                                    <div class="row">
                                                        <label>中核事業に対する重要度が大きいものを３とし、1～3段階でご記入ください。<br />災害がボトルネックに与える影響については、<br />1 ほとんど被害を受けない<br />2 ある程度の量や時間は影響を受けるが目標復旧時間内の復旧には間に合う<br />3 目標復旧時間内の復旧に間に合わない程度の時間や影響を受け続けるを検討しご記入ください。<br />②×③で求められた数字が大きい場合、想定した災害が中核事業に与える影響が大きいボトルネック資源ということになります。有効な対策を検討する必要があります。</label>
                                                    </div>
                                                </template>
                                            </VDropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row mt-3" v-if="importantBusinessIndex === disasterAssumption.importantBusinesses.length - 1">
                            <input type="button" class="ms-3 btn btn-black btn-sm" value="重要業務を増やす" @click="addImportantBusinessSet(index)">
                        </div>
                    </template>
                </template>

                <template v-else>
                    <div class="row ms-3 mt-3">
                        <div class="p2 ms-4 col-md-8 d-flex align-items-center justify-content-start">
                            <select name="selectImportantBusiness" class="form-select form-control bg-resilience-orange d-flex align-items-center justify-content-center py-2"
                                v-model="setImportantBusinessIds[index].importantBusinessIndexes[0]"
                                @change="getRequiredResources(index)"
                            >
                                <option type="text" ></option>
                                <option type="text" v-for="importantBusinessList in important_businesses_info" :key="importantBusinessList" :value="importantBusinessList.id">
                                    {{ importantBusinessList.important_business_content }}
                                </option>
                            </select>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <!-- <div class="mt-3">
        <input type="button" class="btn btn-red px-3" value="上記の災害想定を登録する">
    </div> -->

    <div class="row mt-5 pb-5">
        <div class="p2 col-md-12 d-flex flex-row align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-advanced" value="上級｜4. 財務診断と事前対策計画 へ進む" @click="showFinance">
            <input type="button" class="ms-3 btn btn-bcp-basic" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 災害想定
            disasterAssumptionsInfo.splice(0);
            setImportantBusinessIds.splice(0);
            if (props.disaster_assumptions_info.length > 0) {
                props.disaster_assumptions_info.forEach((fetchData, disasterAssumptionIndex) => {
                    disasterAssumptionsInfo.push({
                        disasterAssumptionId: fetchData.id,
                        disasterAssumptionContent: fetchData.disaster_assumption_content,
                        importantBusinesses: [],
                    });
                    setImportantBusinessIds.push({
                        disasterAssumptionIndex: disasterAssumptionIndex,
                        importantBusinessIndexes:[],
                    })

                    // 重要業務
                    if (fetchData.important_businesses.length > 0) {
                        fetchData.important_businesses.forEach((importantBusinessData, importantBusinessIndex) => {
                            disasterAssumptionsInfo[disasterAssumptionIndex].importantBusinesses.push({
                                importantBusinessId: importantBusinessData.id,
                                importantBusinessContent: importantBusinessData.important_business_content,
                                requiredResources: [],
                            });

                            // 必要資源
                            if (importantBusinessData.required_resources.length > 0) {
                                importantBusinessData.required_resources.forEach(data => {
                                    disasterAssumptionsInfo[disasterAssumptionIndex].importantBusinesses[importantBusinessIndex].requiredResources.push({
                                        requiredResourceId: data.required_resource_id,
                                        requiredResourceDetailId: data.required_resource_detail_id,
                                        resource: data.resource,
                                        importanceDimension: data.importance_dimension,
                                        impact: data.impact,
                                        impactDimension: data.impact_dimension,
                                    })
                                });
                            }
                            setImportantBusinessIds[disasterAssumptionIndex].importantBusinessIndexes.push(importantBusinessData.id);
                        });
                    } else {
                        setImportantBusinessIds[disasterAssumptionIndex].importantBusinessIndexes.push('');
                    }
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('000100');
        const isProcessing = ref(false);
        const setImportantBusinessIds = reactive([
            {
                disasterAssumptionIndex: '',
                importantBusinessIndexes: [],
            }
        ]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const disasterAssumptionsInfo = reactive([
            {
                disasterAssumptionId: '',
                disasterAssumptionContent: '',
                importantBusinesses: [
                    {
                        importantBusinessId: '',
                        importantBusinessContent: '',
                        requiredResources: [
                            {
                                requiredResourceId: '',
                                requiredResourceDetailId: '',
                                resource: '',
                                importanceDimension: '',
                                impact: '',
                                impactDimension: '',
                            }
                        ]
                    }
                ],
            }
        ]);

        // 必要資源情報の取得
        const getRequiredResources = (index, importantBusinessIndex, disasterAssumptionId) => {
            console.log(importantBusinessIndex);
            console.log(disasterAssumptionId);

            importantBusinessIndex = importantBusinessIndex === undefined ? 0 : importantBusinessIndex;

            const url="/api/bcp/getRequiredResourceInfo";

            return axios.post(url, {
                bcp_id: props.bcp_basic_frame_info.id,
                version: props.bcp_basic_frame_info.version,
                disaster_assumption_id : disasterAssumptionId !== undefined ? disasterAssumptionId : '',
                important_business_id: setImportantBusinessIds[index].importantBusinessIndexes[importantBusinessIndex],
            }).then(res =>{
                if (res.data.length > 0) {
                    const resImportantBusinesses = {
                        importantBusinessId: res.data[0].important_business_id,
                        importantBusinessContent: res.data[0].important_business_content,
                        requiredResources: [],
                    }
                    res.data.forEach(resData => {
                        resImportantBusinesses.requiredResources.push({
                            requiredResourceId: resData.required_resource_id !== undefined ? resData.required_resource_id : '',
                            requiredResourceDetailId: resData.required_resource_detail_id !== undefined ? resData.required_resource_detail_id : '',
                            resource: resData.resource !== undefined ? resData.resource : '',
                            importanceDimension: resData.importance_dimension !== undefined ? resData.importance_dimension : '',
                            impact: resData.impact !== undefined ? resData.impact : '',
                            impactDimension: resData.impact_dimension !== undefined ? resData.impact_dimension : '',
                        });
                    });
                    // 取得内容を差し替える
                    disasterAssumptionsInfo[index].importantBusinesses.splice(importantBusinessIndex, 1, resImportantBusinesses);

                } else {
                    // データ取得できない場合は表示を消す
                    disasterAssumptionsInfo[index].importantBusinesses.splice(importantBusinessIndex, 1);
                    setImportantBusinessIds[index].importantBusinessIndexes.splice(importantBusinessIndex, 1);
                }
            });
        }

        // 影響の大きさの算出
        const culcImpact = (index, importantBusinessIndex, requiredResourceIndex) => {
            const inImportanceDimension = disasterAssumptionsInfo[index].importantBusinesses[importantBusinessIndex].requiredResources[requiredResourceIndex].importanceDimension;
            const inImpact = disasterAssumptionsInfo[index].importantBusinesses[importantBusinessIndex].requiredResources[requiredResourceIndex].impact;
            const setImpactDimension = parseInt(String(inImportanceDimension).slice(-1)) * parseInt(String(inImpact).slice(-1));
            disasterAssumptionsInfo[index].importantBusinesses[importantBusinessIndex].requiredResources[requiredResourceIndex].impactDimension = isNaN(setImpactDimension)
                ? 0
                : setImpactDimension;
        }

        // 重要業務を増やすボタン
        const addImportantBusinessSet = index => {
            disasterAssumptionsInfo[index].importantBusinesses.push({
                importantBusinessId: '',
                importantBusinessContent: '',
                requiredResources: [
                    {
                        disasterAssumptionId: '',
                        requiredResourceDetailId: '',
                        resource: '',
                        importanceDimension: '',
                        impact: '',
                        impactDimension: '',
                    }
                ]
            });
        }

        const showBack = async () => {
            // 必要資源詳細を登録
            const result = await registerFirstForm();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showContents = async () => {
            // 必要資源詳細を登録
            const result = await registerFirstForm();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showFinance = async () => {
            // 必要資源詳細を登録
            const result = await registerFirstForm();

            if (result == '200') {
                const url = '/bcp/bcp_financial/home';
                location.href = url;
            }
        }

        // 必要資源詳細登録
        const registerFirstForm = async () => {
            // リクエスト送信
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirstForm';
            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'form_data': disasterAssumptionsInfo
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            setImportantBusinessIds,
            disasterAssumptionsInfo,
            callReplaceWord,
            showBack,
            showContents,
            getRequiredResources,
            culcImpact,
            addImportantBusinessSet,
            showFinance,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        disaster_assumptions_info: {
            type: Object,
        },
        important_businesses_info: {
            type: Object,
        },
        evaluation_resource_list: {
            type: Array,
        },
        impact_bottleneck_list: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.border-red{
    border-style: solid;
    border-width: 1px;
    border-color: #a50021;
}

.bg-red {
    background-color: #a50021;
}

.font-red {
    color: #a50021;
}

.bg-gray {
    background-color: #bfbfbf;
}

</style>
