<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3">
            4. 財務診断と事前対策計画｜保険情報リスト
        </div>

        
        <div class="col-md-4 d-flex justify-content-end">
            <div class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る" :disabled="isProcessing" @click="showBack"></div>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5 ">
        <div class="col-md-11">
            <div class="card bg-light-blue border-green">
                <div class="card-body">
                    <div class="row mt-3" v-for="(insurance, index) in insurance_infos" :key="index">
                        <div class="col-md-10">
                            <div class="">
                                <div class="card bg-light-gray">
                                    <div class="card-body">
                                        <div class="row ms-3">
                                            <div class="p2 ms-3 col-md-5 text-dark d-flex align-items-center justify-content-start py-2">
                                                ○ 保険代理店
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-3 mt-3 col-md-2  text-dark d-flex align-items-end justify-content-start">
                                                TOTONO-L 会員ID
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class="col-md-3 ms-3">
                                                <input type="text" class="form-control col-md-3" placeholder="" v-model="insurance.会員ID">
                                            </div>
                                            <div class="col-md-2">
                                                <input type="button" class="ms-3 btn btn-black" value="検索" @click="searchMember(index)" :disabled="isProcessing">
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-3 mt-3 col-md-2  text-dark d-flex align-items-end justify-content-start">
                                                法人名／屋号
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class="p2 ms-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                                                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="insurance.組織名">
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                <label class="col-form-label">都道府県</label>
                                                <input type="text" class="form-control" placeholder="" v-model="insurance.都道府県">
                                            </div>
                                            <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                <label class="col-form-label">市区町村</label>
                                                <input type="text" class="form-control" placeholder="" v-model="insurance.市区町村">
                                            </div>
                                            <div class="p2 ms-3 mt-3 col-md-4  text-dark">
                                                <label class="col-form-label">町名番地、ビル名等</label>
                                                <input type="text" class="form-control" placeholder="" v-model="insurance.番地">
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-3 mt-3 col-md-5  text-dark">
                                                <label class="col-form-label">ホームページアドレス</label>
                                                <input type="text" class="form-control" placeholder="" v-model="insurance.url">
                                            </div>
                                            <div class="p2 ms-3 mt-3 col-md-3  text-dark">
                                                <label class="col-form-label">電話番号（代表）</label>
                                                <input type="text" class="form-control" placeholder="" v-model="insurance.電話番号">
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="ms-4 mt-3 col-md-1 border border-dark text-dark text-center  d-flex align-items-center justify-content-center">
                                                担<br>
                                                当<br>
                                                者
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                        <label class="col-form-label">部署</label>
                                                        <input type="text" class="form-control" id="部署" placeholder="" v-model="insurance.担当者_部署">
                                                    </div>
                                                    <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                        <label class="col-form-label">担当者</label>
                                                        <input type="text" class="form-control" id="担当者" placeholder="" v-model="insurance.担当者_担当者名">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                        <label class="col-form-label">電話番号</label>
                                                        <input type="text" class="form-control" id="電話番号" placeholder="" v-model="insurance.担当者_電話番号">
                                                    </div>
                                                    <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                        <label class="col-form-label">携帯電話等</label>
                                                        <input type="text" class="form-control" id="携帯電話等" placeholder="" v-model="insurance.担当者_携帯電話番号">
                                                    </div>
                                                    <div class="p2 ms-3 mt-3 col-md-2  text-dark">
                                                        <label class="col-form-label">FAX番号</label>
                                                        <input type="text" class="form-control" id="FAX番号" placeholder="" v-model="insurance.担当者_FAX番号">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="p2 ms-3 mt-3 col-md-4  text-dark">
                                                        <label class="col-form-label">E-mail</label>
                                                        <input type="email" class="form-control" id="E-mail" placeholder="" v-model="insurance.担当者_メールアドレス">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-3 mt-3 col-md-5 text-dark d-flex align-items-center justify-content-start py-2">
                                                ○ 保険代理店
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="row ms-3 mt-3 d-flex flex-row">
                                                <div class="p2 col-md-2 bg-light border border-dark d-flex align-items-center justify-content-start">
                                                    保険のタイプ
                                                </div>
                                                <div class="p2 col-md-2 bg-light border border-dark d-flex align-items-center justify-content-start">
                                                    保険証番号
                                                </div>
                                                <div class="p2 col-md-2 bg-light border border-dark d-flex align-items-center justify-content-start">
                                                    免責金額
                                                </div>
                                                <div class="p2 col-md-2 bg-light border border-dark d-flex align-items-center justify-content-start">
                                                    補償限度額
                                                </div>
                                                <div class="p2 col-md-2 bg-light border border-dark d-flex align-items-center justify-content-start">
                                                    補償範囲
                                                </div>
                                            </div>
                                            <div v-for="(policy, idx) in insurance.policy_list" :key="idx" class="row ms-3 d-flex flex-row">
                                                <div class="p2 col-md-2 bg-white border border-dark">
                                                    <input type="text" class="form-control border-white" id="保険のタイプ" v-model="policy.type" maxlength="255">
                                                </div>
                                                <div class="p2 col-md-2 bg-white border border-dark">
                                                    <input type="text" class="form-control border-white" id="保険証番号" v-model="policy.insurance_no" maxlength="255">
                                                </div>
                                                <div class="p2 col-md-2 bg-white border border-dark">
                                                    <input type="text" class="form-control border-white" id="免責金額" v-model="policy.deductible_amount" maxlength="255">
                                                </div>
                                                <div class="p2 col-md-2 bg-white border border-dark">
                                                    <input type="text" class="form-control border-white" id="補償限度額" v-model="policy.compensation_limit" maxlength="255">
                                                </div>
                                                <div class="p2 col-md-2 bg-white border border-dark">
                                                    <input type="text" class="form-control border-white" id="補償範囲" v-model="policy.coverage" maxlength="255">
                                                </div>
                                            </div>
                                            <div class="row ms-3 d-flex flex-row">
                                                <div class="p2 ms-3 col-md-9 d-flex align-items-start justify-content-start">
                                                    &emsp;
                                                </div>
                                                <div class="p2 col-md-1 d-flex align-items-center justify-content-end mt-2">
                                                    <input type="button" class="btn btn-black" value="1行増やす" @click="addPolicy(index)" :disabled="isProcessing">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-3 mt-3 col-md-5 text-dark d-flex align-items-center justify-content-start py-2">
                                                <label for="" class="form-label d-flex align-items-center">○ 損害補償の範囲検討</label>
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="col-md-12">
                                                <div class="form-check form-check-inline ms-4 col-md-5 text-dark">
                                                    <label for="" class="form-label d-flex align-items-center">洪水保険を必要としますか？</label>
                                                </div>
                                                <div class="form-check form-check-inline col-md-2">
                                                    <input class="form-check-input" type="radio" :id="'洪水保険有_' + index" value=1 v-model="insurance.洪水保険有無">
                                                    <label class="form-check-label" :for="'洪水保険有_' + index">はい</label>
                                                </div>
                                                <div class="form-check form-check-inline  col-md-2">
                                                    <input class="form-check-input" type="radio" :id="'洪水保険無_' + index" value=0 v-model="insurance.洪水保険有無">
                                                    <label class="form-check-label" :for="'洪水保険無_' + index">いいえ</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class="col-md-12">
                                                <div class="form-check form-check-inline ms-4 col-md-5 text-dark">
                                                    <label for="" class="form-label d-flex align-items-center">地震保険を必要としますか？</label>
                                                </div>
                                                <div class="form-check form-check-inline col-md-2">
                                                    <input class="form-check-input" type="radio" :id="'地震保険有_' + index" value=1 v-model="insurance.地震保険有無">
                                                    <label class="form-check-label" :for="'地震保険有_' + index">はい</label>
                                                </div>
                                                <div class="form-check form-check-inline  col-md-2">
                                                    <input class="form-check-input" type="radio" :id="'地震保険無_' + index" value=0 v-model="insurance.地震保険有無">
                                                    <label class="form-check-label" :for="'地震保険無_' + index">いいえ</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class="col-md-12">
                                                <div class="form-check form-check-inline ms-4 col-md-5 text-dark">
                                                    <label for="" class="form-label d-flex align-items-center">事業収益及びを特別損失に関する保険を必要としますか？</label>
                                                </div>
                                                <div class="form-check form-check-inline col-md-2">
                                                    <input class="form-check-input" type="radio" :id="'特別損失保険有_' + index" value=1 v-model="insurance.特別損失保険有無">
                                                    <label class="form-check-label" :for="'特別損失保険有_' + index">はい</label>
                                                </div>
                                                <div class="form-check form-check-inline  col-md-2">
                                                    <input class="form-check-input" type="radio" :id="'特別損失保険無_' + index" value=0 v-model="insurance.特別損失保険有無">
                                                    <label class="form-check-label" :for="'特別損失保険無_' + index">いいえ</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class="p2 ms-5 col-md-10 text-dark d-flex align-items-center justify-content-start">
                                                <label for="" class="form-label d-flex align-items-center">その他の災害関連の保険に関する質問 等</label>
                                            </div>
                                            <div class="p2 ms-5 col-md-10 text-dark d-flex align-items-center justify-content-start">
                                                <input type="text" class="form-control border-white text-end" id="その他の災害関連の保険に関する質問 等" placeholder="" v-model="insurance.その他">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex align-items-start flex-column">
                            <div v-if="index !== 0" class="">
                                <input type="button" class="btn btn-delete" value="削除" @click="delete_insurance_info(index)" :disabled="isProcessing">
                            </div>
                            <div class="mt-auto" v-if="insurance_infos.length === index + 1">
                                <input type="button" class="btn btn-black" value="1セット増やす" @click="add_insurance_infos" :disabled="isProcessing">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <VDropdown
                theme="browsing_restrictions"
            >
                <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                <template #popper>
                    <div class="d-flex justify-content-start">
                        <label>閲覧制限</label>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                            <label class="form-check-label" for="checkbox_1">
                                あり
                            </label>
                        </div>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001100');

        const isProcessing = ref(false);

        const insurance_infos = reactive([{
            会員ID: '',
            組織名: '',
            都道府県: '',
            市区町村: '',
            番地: '',
            url: '',
            電話番号: '',
            担当者_部署: '',
            担当者_担当者名: '',
            担当者_電話番号: '',
            担当者_携帯電話番号: '',
            担当者_FAX番号: '',
            担当者_メールアドレス: '',
            洪水保険有無: 0,
            地震保険有無: 0,
            特別損失保険有無: 0,
            その他: '',
            policy_list: [{
                type: '',
                insurance_no: '',
                deductible_amount: '',
                compensation_limit: '',
                coverage: '',
            }],

        }]);

        onMounted(() => {
            if(props.insurance_list.length > 0){
                insurance_infos.splice(0);
                for(let i = 0; i < props.insurance_list.length; i++){
                    const ins_policy_list = [];
                    for(let j = 0; j < props.insurance_list[i].policy.length; j++){
                        ins_policy_list.push({
                            type: props.insurance_list[i].policy[j].type,
                            insurance_no: props.insurance_list[i].policy[j].insurance_no,
                            deductible_amount: props.insurance_list[i].policy[j].deductible_amount,
                            compensation_limit: props.insurance_list[i].policy[j].compensation_limit,
                            coverage: props.insurance_list[i].policy[j].coverage,
                        })
                    }
                    if(ins_policy_list.length === 0){
                        ins_policy_list.push({
                            type: '',
                            insurance_no: '',
                            deductible_amount: '',
                            compensation_limit: '',
                            coverage: '',
                        })
                    }
                    

                    insurance_infos.push({
                        会員ID: props.insurance_list[i]['会員ID'],
                        組織名: props.insurance_list[i].組織名,
                        都道府県: props.insurance_list[i].都道府県,
                        市区町村: props.insurance_list[i].市区町村,
                        番地: props.insurance_list[i].番地,
                        url: props.insurance_list[i].url,
                        電話番号: props.insurance_list[i].電話番号,
                        担当者_部署: props.insurance_list[i].担当者_部署,
                        担当者_担当者名: props.insurance_list[i].担当者_担当者名,
                        担当者_電話番号: props.insurance_list[i].担当者_電話番号,
                        担当者_携帯電話番号: props.insurance_list[i].担当者_携帯電話番号,
                        担当者_FAX番号: props.insurance_list[i].担当者_FAX番号,
                        担当者_メールアドレス: props.insurance_list[i].担当者_メールアドレス,
                        洪水保険有無: props.insurance_list[i].洪水保険有無,
                        地震保険有無: props.insurance_list[i].地震保険有無,
                        特別損失保険有無: props.insurance_list[i].特別損失保険有無,
                        その他: props.insurance_list[i].その他,
                        policy_list: ins_policy_list,
                    });

                }
            }

            if (props.bcp_values_info.length > 0) {
                props.bcp_values_info.forEach(fetchData =>{
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        viewLimit.value = fetchData.value == 1 ? true : false;
                    }
                });
            }
        });

        const add_insurance_infos = () => {
            insurance_infos.push({
                会員ID: '',
                組織名: '',
                都道府県: '',
                市区町村: '',
                番地: '',
                url: '',
                電話番号: '',
                担当者_部署: '',
                担当者_担当者名: '',
                担当者_電話番号: '',
                担当者_携帯電話番号: '',
                担当者_FAX番号: '',
                担当者_メールアドレス: '',
                洪水保険有無: 0,
                地震保険有無: 0,
                特別損失保険有無: 0,
                その他: '',
                policy_list: [{
                    type: '',
                    insurance_no: '',
                    deductible_amount: '',
                    compensation_limit: '',
                    coverage: '',
                }],
            });
        }

        const delete_insurance_info = (index) => {
            if(!confirm((index + 1) + "番目（" + insurance_infos[index].組織名 + "）を削除します。よろしいですか？")){
                return false;
            }

            insurance_infos.splice(index, 1);
        }

        const addPolicy = (index) => {
            insurance_infos[index].policy_list.push({
                type: '',
                insurance_no: '',
                deductible_amount: '',
                compensation_limit: '',
                coverage: '',
            });
        }

        const searchMember = (index) => {
            if(insurance_infos[index].会員ID === ''){
                return;
            }

            isProcessing.value = true;
            const url="/api/bcp/getMemberInfo"
            axios.post(url, {
                corp_id: insurance_infos[index].会員ID,
            }).then(res => {
                insurance_infos[index].組織名 = res.data.corporate_name;
                insurance_infos[index].都道府県 = res.data.prefecture;
                insurance_infos[index].市区町村 = res.data.city;
                insurance_infos[index].番地 = res.data.other_address;
                insurance_infos[index].url = res.data.url;
                insurance_infos[index].電話番号 = res.data.phone_number;
                insurance_infos[index].担当者_部署 = res.data.administrator_department;
                insurance_infos[index].担当者_担当者名 = res.data.administrator_name;
                insurance_infos[index].担当者_電話番号 = res.data.administrator_phone_number;
                insurance_infos[index].担当者_メールアドレス = res.data.administrator_mail;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showBack = async () => {
            // 保険情報を登録
            const result = await registerInsurance();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showContents = async () => {
            // 保険情報を登録
            const result = await registerInsurance();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const registerInsurance = async () => {
            const url = '/api/bcp/registerInsurance';

            // 閲覧制限
            const setDefaultSeq = 1;
            const setDefaultFieldNumber = 1;
            const pageName = 'Fin18';
            const reqValueForms = [
                {
                    fieldNumber: setDefaultFieldNumber,
                    columnName: 'view_limit',
                    seq: setDefaultSeq,
                    value: viewLimit.value,
                }
            ];

            isProcessing.value = true;

            return await axios.post(url, {
                bcp_id: props.bcp_basic_frame_info.id,
                version: props.bcp_basic_frame_info.version,
                page_name: pageName,
                insurance_info: insurance_infos,
                value_form_list: reqValueForms,
            }).then(res=>{
                return res.status;
            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const viewLimit = ref(0);

        return {
            setLevel,
            isProcessing,
            insurance_infos,
            add_insurance_infos,
            delete_insurance_info,
            addPolicy,
            searchMember,

            showBack,
            showContents,
            
            registerInsurance,

            viewLimit,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        insurance_list: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-yellow {
    background-color: #ffd966;
    border: 1px solid #404040;
    color: #404040;
}

.bg-funds {
    background-color: #009900;
    color: white;
}

.bg-title {
    background: #fce4d6;
}

.font-orange {
    color: #ff6600;
}
</style>