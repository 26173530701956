<template>
    <div id="confirm-modal" v-show="modalConfirmVisible" @click.self="closeConfirmModal">
        <div id="modal-confirm-content">
            <div class="row align-self-center">
                <label class="text-center">従量課金額が変更になりました。</label>
                <label class="text-center">表示合計額が翌期（10/1～9/30）の更新時に年会費と共に請求されます。</label>
                <label class="text-center">金額をご確認ください。</label>
                <label class="text-center">～TOTONO-L事務局～</label>
            </div>
            <div class="d-flex justify-content-evenly mt-3">
                <input type="button" class="btn btn-gray" value="戻る" @click="closeConfirmModal" />
                <input type="button" class="btn btn-gray" value="登録" @click="registerPayPerUse" />
            </div>
        </div>
    </div>

    <div id="payment-confirm-modal" v-show="modalPaymentConfirmVisible" @click.self="closePaymentConfirmModal">
        <div id="modal-confirm-content">
            <div class="row align-self-center">
                <label class="text-center">従業課金は年度単位（10/1～9/30）のため月割計算は</label>
                <label class="text-center">ございません。表示合計額は今期の金額になり、更新時には</label>
                <label class="text-center">年会費及び従量課金額が請求されます。</label>
                <label class="text-center">～TOTONO-L事務局～</label>
            </div>
            <div class="d-flex justify-content-evenly mt-3">
                <input type="button" class="btn btn-gray" value="戻る" @click="closePaymentConfirmModal" />
                <input type="button" class="btn btn-gray" value="確認" @click="payPayPerUse" />
            </div>
        </div>
    </div>

    <div id="recommend-switch-modal" v-show="modalRecommendSwitchVisible" @click.self="closeRecommendSwitchModal">
        <div id="modal-recommend-switch-content">
            <div class="row">
                <label class="col-form-label">指定された従量課金を利用される場合は、<br />有償会員に切り替える必要がございます。</label>
            </div>
            <div class="row mt-3">
                <a href="#" @click.prevent.stop="changePaid" class="link-orange">➡ 有償会員切替ページへ</a>
            </div>
        </div>
    </div>

    <div v-show="mode === 1">
        <div class="row">
            <div class="col-md-9">
                <div class="h5 heading-orange mt-3">
                    従量課金の登録・変更							
                </div>
            </div>
            <div class="col-md-3 mt-3">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black" value="前ページへ戻る" @click="goBack" :disabled="isProcessing" />
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="row ms-5 mx-5 mt-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center py-2">
            </div>
            <div class="p2 col-md-6 border d-flex align-items-center border-start-0 py-2">
                現在の情報
            </div>
            <div class="p2 col-md-5 border d-flex align-items-center border-start-0 py-2">
                変更したい情報
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-bottom-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                登録している拠点・支店の数
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(pay_per_use_info.拠点数) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                ヶ所
            </div>
            <div class="p2 col-md-5 border d-flex align-items-center border-start-0 border-top-0 border-bottom-0 py-2">
                変更したい拠点・支店の数
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
                1
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2 font-gray">
                ▶実際に登録している拠点・支店の数
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(branch_cnt_actual) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                ヶ所
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
                <span class="badge bg-secondary">必須</span>
            </div>
            <div class="p2 col-md-1 py-2">
                <input type="number" class="form-control" v-model="input拠点数" :min="branch_cnt_actual" :class="{'is-invalid': 拠点数_has_error}" @change="calcNewAmount('拠点数')" :disabled="isProcessing" />
                <div class='invalid-feedback'>{{ 拠点数_message }}</div>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                ヶ所
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
                <input type="button" class="btn btn-black btn-sm" value="変更しない" :disabled="isProcessing" @click="returnVal('拠点数')">
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-end-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-end-0 border-top-0 py-2 font-gray">
                ▶従量課金状況（税込）
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(pay_per_use_amount.従量_支店総額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                円
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                新たな従量課金
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-end-0 py-2">
                <!-- <input type="button" class="btn btn-black btn-sm" value="計算する" @click="calcNewAmount('拠点数')" :disabled="isProcessing"> -->
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(label_new_拠点金額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-start border-start-0 border-top-0 py-2">
                円（税込）
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-bottom-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                登録している利用者（役員・従業員）の人数
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(pay_per_use_info.利用者数) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                人
            </div>
            <div class="p2 col-md-5 border d-flex align-items-center border-start-0 border-top-0 border-bottom-0 py-2">
                変更したい利用者（役員・従業員）の人数
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
                2
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2 font-gray">
                ▶実際に使用している人数
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(user_cnt_actual) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                人
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
                <span class="badge bg-secondary">必須</span>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
                <input type="number" class="form-control" v-model="input利用者数" :min="user_id_max" @change="calcNewAmount('従業員数')" :disabled="isProcessing">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                人
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
                <input type="button" class="btn btn-black btn-sm" value="変更しない" :disabled="isProcessing" @click="returnVal('従業員数')">
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-end-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-end-0 border-top-0 py-2 font-gray">
                ▶従量課金状況（税込）
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(pay_per_use_amount.従量_従業員総額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                円
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                新たな従量課金
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-end-0 py-2">
                <!-- <input type="button" class="btn btn-black btn-sm" value="計算する" @click="calcNewAmount('従業員数')" :disabled="isProcessing"> -->
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(label_new_従業員金額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-start border-start-0 border-top-0 py-2">
                円（税込）
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-bottom-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                登録している利用者家族の人数
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(pay_per_use_info.利用家族数) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                人
            </div>
            <div class="p2 col-md-5 border d-flex align-items-center border-start-0 border-top-0 border-bottom-0 py-2">
                変更したい利用者家族の人数
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
                3
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2 font-gray">
                ▶実際に登録している利用者家族の人数
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(family_cnt_actual) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                人
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
                <span class="badge bg-secondary">必須</span>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
                <input type="number" class="form-control" v-model="input利用家族数" :min="family_cnt_actual" @change="calcNewAmount('家族数')" :disabled="isProcessing">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                人
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
                <input type="button" class="btn btn-black btn-sm" value="変更しない" :disabled="isProcessing" @click="returnVal('家族数')" />
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-end-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-end-0 border-top-0 py-2 font-gray">
                ▶従量課金状況（税込）
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(pay_per_use_amount.従量_家族総額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                円
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                新たな従量課金
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-end-0 py-2">
                <!-- <input type="button" class="btn btn-black btn-sm" value="計算する" @click="calcNewAmount('家族数')" :disabled="isProcessing"> -->
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(label_new_家族金額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-start border-start-0 border-top-0 py-2">
                円（税込）
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light" v-if="showLINE === 'true'">
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
                4
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                LINE連携オプション
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                {{ corp_info_for_main.LINE連携 == 1 ? "あり" : "なし" }}
            </div>
            <div class="col-md-5">
                <div class="row mt-3">
                    <div class="col-md-5">
                        LINE連携オプションの利用
                    </div>
                    <div class="col-md-3">
                        <select class="form-select" v-model="selectLINE連携" @change="calcNewAmount('LINE')" :disabled="isProcessing">
                            <option value="1">あり</option>
                            <option value="0">なし</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <input type="button" class="btn btn-black btn-sm ms-2" value="変更しない" :disabled="isProcessing" @click="returnVal('LINE')" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row ms-5 mx-5 bg-light" v-if="showLINE === 'true'">
            <div class="p2 col-md-1 border d-flex align-items-center border-top-0 border-end-0 py-2">
            </div>
            <div class="p2 col-md-4 border d-flex align-items-center border-end-0 border-top-0 py-2 font-gray">
                ▶従量課金状況（税込）
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 font-gray">
                {{ number_format(pay_per_use_amount.従量_家族総額) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2 font-gray">
                円
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                新たな従量課金
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-end-0 py-2">
                <!-- <input type="button" class="btn btn-black btn-sm" value="計算する" @click="calcNewAmount('家族数')" :disabled="isProcessing"> -->
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
                {{ number_format(label_new_LINE連携) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-start border-start-0 border-top-0 py-2">
                円（税込）
            </div>
        </div>

        <div class="row ms-5 mx-5">
            <div class="p2 col-md-1 d-flex align-items-center py-2">
            </div>
            <div class="p2 col-md-6 d-flex align-items-center border-start-0 py-2">
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center border-top-0 border-end-0 py-2 bg-light">
                合計
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-center border-start-0 border-top-0 border-end-0 py-2 bg-light">
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2 bg-light">
                {{ number_format(label_new_summary) }}
            </div>
            <div class="p2 col-md-1 border d-flex align-items-center justify-content-start border-start-0 border-top-0 py-2 bg-light">
                円（税込）
            </div>
        </div>

        <div class="row ms-5 mx-5 mt-3">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
            </div>
            <div class="p2 col-md-4 d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end border-start-0 border-top-0 border-end-0 py-2">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center py-2">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 col-md-2 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
                <input type="button" class="btn btn-black btn-sm" value="従量課金表" @click="openPrices" :disabled="isProcessing">
            </div>
        </div>
        
        <div class="row ms-5 mx-5 mt-3">
            <div class="p2 col-md-2 d-flex align-items-center">
                <input type="button" class="btn btn-gray px-3" value="管理者ページへ戻る" @click="backAdministratorHome" :disabled="isProcessing">
                <input type="button" class="btn btn-black px-5 ms-3" value="登録" @click="confirmPayPerUse" :disabled="isProcessing">
            </div>
        </div>
    </div>

    <div v-show="mode===2">
        <div class="row">
            <div class="col-md-9">
                <div class="h5 heading-gray mt-3">
                    従量課金支払い				
                </div>
            </div>
            <!-- <div class="col-md-3 mt-3">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black" value="前ページへ戻る" @click="goBack" :disabled="isProcessing" />
            </div> -->
        </div>

        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">従量課金額</div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light" id="amountTable">
                    <tbody>
                        <tr>
                            <th>{{ 年度 }}年度</th>
                            <td colspan="3">従量課金の内容</td>
                            <td class="text-center">ご請求金額（税込）</td>
                        </tr>
                        <tr>
                            <td :rowspan="showLINE === 'true' ? 4 : 3">従量課金</td>
                            <td>登録する利用者（役員・従業員）の人数</td>
                            <td class="text-end">{{ number_format(input利用者数) }}</td>
                            <td class="text-end">人</td>
                            <td class="text-end">{{ number_format(label_new_従業員金額) }}円</td>
                        </tr>
                        <tr>
                            <td>登録する拠点・支店の数</td>
                            <td class="text-end">{{ number_format(input拠点数) }}</td>
                            <td class="text-end">ヶ所</td>
                            <td class="text-end">{{ number_format(label_new_拠点金額) }}円</td>
                        </tr>
                        <tr>
                            <td>登録する利用者家族の人数</td>
                            <td class="text-end">{{ number_format(input利用家族数) }}</td>
                            <td class="text-end">人</td>
                            <td class="text-end">{{ number_format(label_new_家族金額) }}円</td>
                        </tr>
                        <tr>
                            <td v-if="showLINE === 'true'">LINE連携オプション</td>
                            <td class="text-center" colspan="2">{{ selectLINE連携 == "1" ? "あり" : "なし" }}</td>
                            <td class="text-end">{{ number_format(label_new_LINE連携) }}円</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-3">
                <input type="button" class="btn btn-black" value="料金プラン" @click="showPrice" />
            </div>
        </div>
        <div class="row pb-5">
            <div class="col-md-3 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td class="text-center">合計（税込）</td>
                            <td class="text-end">{{ number_format(label_new_summary) }}円</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="col-md-3">
                <input type="button" class="btn btn-orange-button" value="請求書PDFをダウンロード" @click="makeInvoice" />
            </div> -->
        </div>
        <div class="row my-3">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払方法の選択</div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr v-show="corp_info_for_main.請求方法区分 === 10050003">
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050003" id="口座振替" />
                            </td>
                            <td>
                                <label class="form-check-label" for="口座振替">口座振替</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="口座振替"></label>
                            </td>
                        </tr>
                        <tr v-show="corp_info_for_main.請求方法区分 === 10050001">
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050001" id="クレジット決済" />
                            </td>
                            <td>
                                <label class="form-check-label" for="クレジット決済">クレジット決済</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="クレジット決済">※ クレジット決済専用ページに移動します。</label>
                            </td>
                        </tr>
                        <tr v-if="showConvenientPayFlg">
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050002" id="コンビニ払い" />
                            </td>
                            <td>
                                <label class="form-check-label" for="コンビニ払い">コンビニ払い</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="コンビニ払い">※ コンビニ決済専用ページに移動します。</label>
                            </td>
                        </tr>
                        <tr v-show="支払方法=='10050002'">
                            <td colspan="3">
                                <p>・以下のコンビニエンスストアでお支払いできます。</p>
                                <p>・コンビニ決済専用ページに移動後、氏名、メールアドレス等必要な情報を入力してください。<br />※お客様情報の氏名は必ずご登録者ご本人のお名前をご記入ください。</p>
                                <p>・指定コンビニでの支払い番号が完了画面、もしくは確認メールに表示されます。</p>
                                <p>・ご入金の確認後、TOTONO-L事務局よりメールにて連絡いたします。</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <label class="form-label font-s-075">※コンビニ払い、銀行振込は支払確認後に利用可能となります。</label>
                <label class="form-label font-s-075">※金融機関営業時間外にお振込された場合、入金確認まで時間がかかりますので、ご注意ください。</label> -->
            </div>
            <div class="col-md-3 d-flex align-items-end">
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-8 offset-md-1">
                <div class="d-flex justify-content-center" v-if="支払方法=='10050001'">
                    <form id="form_credit" :action="densan_url" method="post" @submit.prevent="getOrderID('payCredit')" ref="submitCredit" accept-charset='shift_jis'>
                        <input type="hidden" name="pay_method" :value="sb_pay_method" />
                        <input type="hidden" name="merchant_id" :value="sb_merchant_id" />
                        <input type="hidden" name="service_id" :value="sb_service_id">
                        <input type="hidden" name="cust_code" :value="sb_cust_code">
                        <input type="hidden" name="order_id" :value="sb_order_id">
                        <input type="hidden" name="item_id" :value="sb_item_id">
                        <input type="hidden" name="item_name" :value="sb_item_name">
                        <input type="hidden" name="amount" :value="sb_amount">
                        <input type="hidden" name="pay_type" :value="sb_pay_type">
                        <input type="hidden" name="service_type" :value="sb_service_type">
                        <input type="hidden" name="terminal_type" :value="sb_terminal_type">
                        <!--商品明細行繰り返し start -->
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_1">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_1">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_1">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_1">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_1">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_2">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_2">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_2">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_2">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_2">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_3">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_3">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_3">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_3">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_3">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_4">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_4">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_4">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_4">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_4">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_5">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_5">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_5">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_5">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_5">
                        <!--商品明細行繰り返し end -->

                        <input type="hidden" name="success_url" :value="sb_success_url">
                        <input type="hidden" name="cancel_url" :value="sb_cancel_url">
                        <input type="hidden" name="error_url" :value="sb_error_url">
                        <input type="hidden" name="pagecon_url" :value="sb_pagecon_url">
                        <!-- <input type="hidden" name="free_csv" value="TEFTVF9OQU1FPemItOacqCxGSVJTVF9OQU1FPeWkqumDjixMQVNUX05BTUVfS0FOQT3jgrnjgrrjgq0sRklSU1RfTkFNRV9LQU5BPeOCv+ODreOCpixGSVJTVF9aSVA9MjEwLFNFQ09ORF9aSVA9MDAwMSxBREQxPeWykOmYnOecjCxBREQyPeOBguOBguOBguW4guOBguOBguOBgueUuixBREQzPSxURUw9MTIzNDU2Nzk4MDEsTUFJTD1hYWFhQGJiLmpwLElURU1fTkFNRT1URVNUIElURU0="> -->
                        <input type="hidden" name="request_date" :value="sb_request_date">
                        <input type="hidden" name="hashkey" :value="sb_hashkey">
                        <input type="hidden" name="sps_hashcode" :value="sb_sps_hashcode">
                        <!-- <input type="hidden" name="dtl_rowno" value="1"> -->
                        <!-- <input type="hidden" name="dtl_item_id" value="dtlItem_1"> -->
                        <!-- <input type="hidden" name="dtl_item_name" value="商品詳細１"> -->
                        <!-- <input type="hidden" name="dtl_item_count" value="1"> -->
                        <!-- <input type="hidden" name="dtl_tax" value="1"> -->
                        <!-- <input type="hidden" name="dtl_amount" value="1"> -->
                        <input type="submit" class="btn btn-black px-5" value="クレジット払い" :disabled="isProcessing" >
                    </form>
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
                <div class="d-flex justify-content-center" v-else-if="支払方法=='10050002'">
                    <form id="form_convenience" :action="densan_url" method="post" @submit.prevent="getOrderID('payConvenience')" ref="submitConvenience" accept-charset='shift_jis'>
                        <input type="hidden" name="pay_method" :value="sb_pay_method" />
                        <input type="hidden" name="merchant_id" :value="sb_merchant_id" />
                        <input type="hidden" name="service_id" :value="sb_service_id">
                        <input type="hidden" name="cust_code" :value="sb_cust_code">
                        <input type="hidden" name="order_id" :value="sb_order_id">
                        <input type="hidden" name="item_id" :value="sb_item_id">
                        <input type="hidden" name="item_name" :value="sb_item_name">
                        <input type="hidden" name="amount" :value="sb_amount">
                        <input type="hidden" name="pay_type" :value="sb_pay_type">
                        <input type="hidden" name="service_type" :value="sb_service_type">
                        <input type="hidden" name="terminal_type" :value="sb_terminal_type">
                        <!--商品明細行繰り返し start -->
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_1">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_1">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_1">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_1">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_1">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_2">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_2">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_2">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_2">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_2">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_3">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_3">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_3">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_3">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_3">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_4">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_4">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_4">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_4">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_4">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_5">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_5">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_5">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_5">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_5">
                        <!--商品明細行繰り返し end -->

                        <input type="hidden" name="success_url" :value="sb_success_url">
                        <input type="hidden" name="cancel_url" :value="sb_cancel_url">
                        <input type="hidden" name="error_url" :value="sb_error_url">
                        <input type="hidden" name="pagecon_url" :value="sb_pagecon_url">
                        <!-- <input type="hidden" name="free_csv" value="TEFTVF9OQU1FPemItOacqCxGSVJTVF9OQU1FPeWkqumDjixMQVNUX05BTUVfS0FOQT3jgrnjgrrjgq0sRklSU1RfTkFNRV9LQU5BPeOCv+ODreOCpixGSVJTVF9aSVA9MjEwLFNFQ09ORF9aSVA9MDAwMSxBREQxPeWykOmYnOecjCxBREQyPeOBguOBguOBguW4guOBguOBguOBgueUuixBREQzPSxURUw9MTIzNDU2Nzk4MDEsTUFJTD1hYWFhQGJiLmpwLElURU1fTkFNRT1URVNUIElURU0="> -->
                        <input type="hidden" name="request_date" :value="sb_request_date">
                        <input type="hidden" name="hashkey" :value="sb_hashkey">
                        <input type="hidden" name="sps_hashcode" :value="sb_sps_hashcode">
                        <!-- <input type="hidden" name="dtl_rowno" value="1"> -->
                        <!-- <input type="hidden" name="dtl_item_id" value="dtlItem_1"> -->
                        <!-- <input type="hidden" name="dtl_item_name" value="商品詳細１"> -->
                        <!-- <input type="hidden" name="dtl_item_count" value="1"> -->
                        <!-- <input type="hidden" name="dtl_tax" value="1"> -->
                        <!-- <input type="hidden" name="dtl_amount" value="1"> -->
                        <input type="submit" class="btn btn-black px-5" value="コンビニ払い" :disabled="isProcessing" >
                    </form>
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
                <div class="d-flex justify-content-center" v-else>
                    <input type="button" class="btn btn-black px-5" value="次へ" @click="pay" :disabled="isProcessing" >
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
            </div>
        </div>
        <div class="row pb-5" v-show="corp_info_for_main.請求方法区分 === 10050001">
            <div class="col-md-1 offset-md-1">
                <i id="ssl_icon" />
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="">
                    <label class="form-label font-s-075">当サイトはSSL暗号化通信に対応しております。お申込内容やクレジットカード番号など、お客様の大切な情報は暗号化して送信され第三者から解読できないようになっております。</label>
                </div>
            </div>
        </div>
    </div>
    <div v-show="mode===3">
        <div class="row">
            <div class="col-md-9">
                <div class="h5 heading-gray mt-3">
                    従量課金支払い				
                </div>
            </div>
            <!-- <div class="col-md-3 mt-3">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black" value="前ページへ戻る" @click="goBack" :disabled="isProcessing" />
            </div> -->
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払／請求書発行</div>
        </div>

        <div class="row">
            <div class="col-md-11 offset-md-1">従業課金の支払いを受付いたしました。</div>
        </div>
        <div class="row mt-1">
            <div class="col-md-11 offset-md-1">翌月もしくは翌々月の27日にご登録いただきました口座から引落しとなります。（27日が休日の場合は翌営業日）</div>
        </div>
        <div class="row mt-3">
            <div class="col-md-11 offset-md-1">こちらから請求書をダウンロードください。</div>
        </div>
        <div class="row mt-1">
            <div class="col-md-11 offset-md-1">
                <input type="button" class="btn btn-orange-80" value="請求書PDFをダウンロード" :disabled="isProcessing" @click="makeInvoice" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-11 offset-md-1">※領収書は引落し完了後に管理者専用ページからダウンロードできます。</div>
        </div>
        <div class="row mt-5" v-if="selectLINE連携 == '1'">
            <div class="col-md-11 offset-md-1">
                ※LINE連携オプション申込会員<br>
                ・LINE連携オプションを申込の会員は、LINEアプリからTOTONO-L公式アカウントの友だち追加をしてください。また、利用者にもご周知ください。<br>
                ・スマートファンでご利用の利用者は、下記もしくはTOP画面の「LINE友だち追加」ボタンから公式アカウントの追加もできます。
            </div>
        </div>
        <div class="row mt-2" v-if="selectLINE連携 == '1'">
            <div>
                <div class="col-md-11 offset-md-1">
                    <div class="d-flex align-items-end">
                        <img :src="imageSrc" class="text-right" id="img-LINE">
                        <input type="button" class="btn btn-send-barcode ms-3" value="左記２次元コードをメールで送信" @click="send_code" :disabled="isProcessing" >
                    </div>
                </div>
                <div class="row ms-5 flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>
            </div>
        </div>
        <div class="row mt-2" v-if="selectLINE連携 == '1'">
            <div class="col-md-11 offset-md-1">
                <a href="https://lin.ee/gw92o2E" target="_blank"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"></a>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-md-11 offset-md-1">
                <input type="button" class="btn btn-gray px-5" value="管理者専用TOPへ" @click="backAdministratorTop" :disabled="isProcessing" />
            </div>
        </div>

    </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import { CommonNumberFormat } from '../../../function/CommonNumberFormat.js';
import axios from 'axios';
import CommonCrypt from '../../../function/CommonCrypto';
import { useEnv } from '../../../function/useEnv';

const { SbHash } = CommonCrypt();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject('dayjs');

        const { VITE_SB_DENSAN_URL, VITE_SB_MERCHANT_ID, VITE_SB_SERVICE_ID, VITE_SB_HASH_KEY, VITE_SB_GET_TOKEN_URL, VITE_SHOW_CONVENIENT_PAY_FLG, VITE_ENABLE_LINE } = useEnv();
        const showLINE = ref(VITE_ENABLE_LINE);

        const mode = ref(1);

        onMounted(() => {
            input拠点数.value = props.pay_per_use_info.拠点数;
            new拠点金額.value = props.pay_per_use_amount.従量_支店総額;
            input利用者数.value = props.pay_per_use_info.利用者数;
            new従業員金額.value = props.pay_per_use_amount.従量_従業員総額;
            input利用家族数.value = props.pay_per_use_info.利用家族数;
            new家族金額.value = props.pay_per_use_amount.従量_家族総額;
            // selectLINE連携.value = props.corp_info_for_main.LINE連携;
            selectLINE連携.value = props.corp_info_for_main.次年度LINE連携;
            newLINE連携.value = props.pay_per_use_amount.従量_LINE連携;
        });

        const setLevel = ref('111111');

        const backManageMemberInfo = () => {
            const url = '/al/administrator/manage_member_info';

            location.href = url;
        }

        const goBack = () => {
            let url = '';

            switch(props.back){
                case 'RegisterBranch':
                    url = '/al/administrator/register_branch';
                    break;
                case 'RegisterUser':
                    url = '/al/administrator/register_user';
                    break;
                default:
                    url = '/al/administrator/manage_member_info';
            }

            location.href = url;
        }

        const input拠点数 = ref(0);
        const input利用者数 = ref(0);
        const input利用家族数 = ref(0);
        const selectLINE連携 = ref("");

        const new拠点金額 = ref(0);
        const new従業員金額 = ref(0);
        const new家族金額 = ref(0);
        const newLINE連携 = ref(0);

        const label_new_拠点金額 = computed(() => {
            return new拠点金額.value - props.max_pay_per_use_amount.従量_支店総額 < 0 ? 0 : new拠点金額.value - props.max_pay_per_use_amount.従量_支店総額;
        });
        const label_new_従業員金額 = computed(() => {
            return new従業員金額.value - props.max_pay_per_use_amount.従量_従業員総額 < 0 ? 0 : new従業員金額.value - props.max_pay_per_use_amount.従量_従業員総額;
        });
        const label_new_家族金額 = computed(() => {
            return new家族金額.value - props.max_pay_per_use_amount.従量_家族総額 < 0 ? 0 : new家族金額.value - props.max_pay_per_use_amount.従量_家族総額;
        });
        const label_new_LINE連携 = computed(() => {
            return newLINE連携.value - props.max_pay_per_use_amount.従量_LINE連携 < 0 ? 0 : newLINE連携.value - props.max_pay_per_use_amount.従量_LINE連携;
        });

        const label_new_summary = computed(() => {
            return label_new_拠点金額.value + label_new_従業員金額.value + label_new_家族金額.value + label_new_LINE連携.value;
        });

        const calcNewAmount = (kind) => {

            if(!checkInput()){
                return false;
            }

            let sum;

            const url = '/api/administrator/calcNewPayPerUseAmount';

            switch(kind){
                case '拠点数':
                    sum = input拠点数.value;
                    break;
                case '従業員数':
                    sum = input利用者数.value;
                    break;
                case '家族数':
                    sum = input利用家族数.value;
                    break;
                case "LINE":
                    sum = selectLINE連携.value;
                    break;
            }

            axios.post(url, {
                kind: kind,
                sum: sum,
            }).then(res => {
                switch(kind){
                    case '拠点数':
                        new拠点金額.value = res.data;
                        break;
                    case '従業員数':
                        new従業員金額.value = res.data;
                        break;
                    case '家族数':
                        new家族金額.value = res.data;
                        break;
                    case "LINE":
                        newLINE連携.value = res.data;
                        break;
                }
            });
        }

        const openPrices = () => {
            const url = '/price';

            let newWindow = window.open(url, '_blank');

        }

        const backAdministratorHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const modalConfirmVisible = ref(false);

        const closeConfirmModal = () => {
            modalConfirmVisible.value = false;
        }

        const confirmPayPerUse = () => {
            if(Number(label_new_summary.value) > 0){
                if(!checkInput()){
                    return false;
                }

                if(props.corp_info_for_main.会員種別 === 10000001 || props.corp_info_for_main.会員種別 === 10000002){
                    modalRecommendSwitchVisible.value = true;
                    return;
                }

                if(showLINE.value){
                    modalPaymentConfirmVisible.value = true;
                    return;
                }else{
                    modalConfirmVisible.value = true;
                    return;
                }
            }
            //料金の変更が発生しない場合
            registerPayPerUse("member_info");
        }

        const modalPaymentConfirmVisible = ref(false);

        const closePaymentConfirmModal = () => {
            modalPaymentConfirmVisible.value = false;
        }

        const modalRecommendSwitchVisible = ref(false);

        const closeRecommendSwitchModal = () => {
            modalRecommendSwitchVisible.value = false;
        }

        const payPayPerUse = () => {
            mode.value = 2;
            modalPaymentConfirmVisible.value = false;
        }

        const isProcessing = ref(false);

        const 拠点数_message = ref('');
        const 拠点数_has_error = ref(false);

        const checkInput = () => {
            拠点数_message.value = "";
            拠点数_has_error.value = false;

            if(input拠点数.value < props.branch_cnt_actual){
                拠点数_message.value = "既に変更数以上の登録がございます。";
                拠点数_has_error.value = true;
                return false;
            }

            return true;

        }

        const bill_id = ref(0);

        const registerPayPerUse = (moveto) => {

            if(!checkInput()){
                return false;
            }

            const url = '/api/administrator/updatePayPerUse';

            isProcessing.value = true;

            axios.post(url, {
                corp_id: props.pay_per_use_info.corp_id,
                拠点数: input拠点数.value,
                利用者数: input利用者数.value,
                利用家族数: input利用家族数.value,
                LINE: selectLINE連携.value,
            }).then(res => {
                if(moveto === "member_info"){
                    //金額変更なしの場合
                    const url_manage_member_info = '/al/administrator/manage_member_info';

                    location.href = url_manage_member_info;
                }else{
                    //金額変更あり、かつ口座振替の場合
                    const url_make_bill = "/api/administrator/makeBillPayPerUse";

                    axios.post(url_make_bill, {
                        corp_id: props.pay_per_use_info.corp_id,
                        amount: label_new_summary.value,
                        拠点数: input拠点数.value,
                        拠点金額: label_new_拠点金額.value,
                        利用者数: input利用者数.value,
                        利用者金額: label_new_従業員金額.value,
                        利用家族数: input利用家族数.value,
                        家族金額: label_new_家族金額.value,
                        LINE: selectLINE連携.value,
                        LINE金額: label_new_LINE連携.value,
                    }).then(res=>{
                        console.log(res.data);
                        bill_id.value = res.data.bill_info.id;
                        imageSrc.value = res.data.imageSrc;
                        mode.value = 3;
                    }).finally(fin => {
                        isProcessing.value = false;
                    });
                }
            }).finally(fin => {
                if(moveto === "member_info"){
                    isProcessing.value = false;
                }
            });

        }

        const returnVal = (kind) => {
            switch(kind){
                case '拠点数':
                    input拠点数.value = props.pay_per_use_info.拠点数;
                    calcNewAmount("拠点数");
                    break;
                case '従業員数':
                    input利用者数.value = props.pay_per_use_info.利用者数;
                    calcNewAmount("従業員数");
                    break;
                case '家族数':
                    input利用家族数.value = props.pay_per_use_info.利用家族数;
                    calcNewAmount("家族数");
                    break;
                case "LINE":
                    // selectLINE連携.value = props.corp_info_for_main.LINE連携;
                    selectLINE連携.value = props.corp_info_for_main.次年度LINE連携;
                    calcNewAmount("LINE");
                    break;
            }
        }

        const number_format = (amount) => {
            return CommonNumberFormat(amount);
        }

        const 支払方法 = ref("");

        const densan_url = ref(VITE_SB_DENSAN_URL);

        const sb_pay_method = ref('');
        const sb_merchant_id = ref(VITE_SB_MERCHANT_ID);
        const sb_service_id = ref(VITE_SB_SERVICE_ID);
        const sb_cust_code = ref('');
        const sb_order_id = ref('');
        const sb_item_id = ref('');
        const sb_item_name = ref('');
        const sb_amount = ref('');
        const sb_pay_type = ref('');
        const sb_service_type = ref('');
        const sb_terminal_type = ref('');

        const sb_dtl_rowno_1 = ref('');
        const sb_dtl_item_id_1 = ref('');
        const sb_dtl_item_name_1 = ref('');
        const sb_dtl_item_count_1 = ref('');
        const sb_dtl_amount_1 = ref('');

        const sb_dtl_rowno_2 = ref('');
        const sb_dtl_item_id_2 = ref('');
        const sb_dtl_item_name_2 = ref('');
        const sb_dtl_item_count_2 = ref('');
        const sb_dtl_amount_2 = ref('');

        const sb_dtl_rowno_3 = ref('');
        const sb_dtl_item_id_3 = ref('');
        const sb_dtl_item_name_3 = ref('');
        const sb_dtl_item_count_3 = ref('');
        const sb_dtl_amount_3 = ref('');

        const sb_dtl_rowno_4 = ref('');
        const sb_dtl_item_id_4 = ref('');
        const sb_dtl_item_name_4 = ref('');
        const sb_dtl_item_count_4 = ref('');
        const sb_dtl_amount_4 = ref('');

        const sb_dtl_rowno_5 = ref('');
        const sb_dtl_item_id_5 = ref('');
        const sb_dtl_item_name_5 = ref('');
        const sb_dtl_item_count_5 = ref('');
        const sb_dtl_amount_5 = ref('');

        const sb_success_url = ref('');
        const sb_cancel_url = ref('');
        const sb_error_url = ref('');
        const sb_pagecon_url = ref('');
        const sb_request_date = ref('');
        const sb_hashkey = ref(VITE_SB_HASH_KEY);
        const sb_sps_hashcode = ref('');

        const getOrderID = (next_method) => {
            const url = '/api/getOrderSequence';

            axios.get(url).then(res => {
                switch(next_method){
                    case 'payCredit':
                        payCredit(res.data);
                        break;
                    case 'payConvenience':
                        payConvenience(res.data);
                        break;
                }
            });
        }

        const submitCredit = ref();
        const submitConvenience = ref();

        const payCredit = async (orderID) => {
            const myId = crypto.randomUUID();

            console.log(myId);

            //再接続用
            const url_tmp_token = "/api/postTmpToken";
            axios.post(url_tmp_token, {uuid: myId});

            //クレジット払い
            const time_string = dayjs().format('YYYYMMDDHHmmss')

            const baseUrl = window.location.origin;

            const creditParams = {
                'pay_method': 'credit3d2',
                'merchant_id': sb_merchant_id.value,
                'service_id': sb_service_id.value,
                'cust_code': props.corp_info_for_main.uuid,
                'order_id': orderID,
                'item_id': 98000002,
                'item_name': 'TOTONO-L従量課金費',
                'amount': label_new_summary.value,
                'pay_type': 0,
                'service_type': 0,
                'terminal_type': 0,

                'success_url': baseUrl + '/register_pay_per_use_success?uuid=' + myId + '&corp_id=' + props.corp_info_for_main.id,
                'cancel_url': baseUrl + '/register_pay_per_use_cancel?uuid=' + myId + '&corp_id=' + props.corp_info_for_main.id,
                'error_url': baseUrl + '/register_pay_per_use_error?uuid=' + myId + '&corp_id=' + props.corp_info_for_main.id,
                // 'pagecon_url': baseUrl + '/api/hon_pagecon',
                'pagecon_url': 'https://totono-l.org/api/hon_pagecon',

                'dtl_rowno_1': 1,
                'dtl_item_id_1': 10030001,
                'dtl_item_name_1': '従業員',
                'dtl_item_count_1': input利用者数.value,
                'dtl_amount_1': label_new_従業員金額.value,

                'dtl_rowno_2': 2,
                'dtl_item_id_2': 10030002,
                'dtl_item_name_2': '支店',
                'dtl_item_count_2': input拠点数.value,
                'dtl_amount_2': label_new_拠点金額.value,

                'dtl_rowno_3': 3,
                'dtl_item_id_3': 10030003,
                'dtl_item_name_3': '家族数',
                'dtl_item_count_3': input利用家族数.value,
                'dtl_amount_3': label_new_家族金額.value,

                'dtl_rowno_4': 4,
                'dtl_item_id_4': 10030004,
                'dtl_item_name_4': 'LINEオプション',
                'dtl_item_count_4': Number(selectLINE連携.value),
                'dtl_amount_4': label_new_LINE連携.value,

                'request_date': time_string,

            }

            const url = '/api/postPurchaseResult';

            await axios.post(url, {
                params: creditParams,
                '電話番号': props.corp_info_for_main.電話番号,
                '代表者名_姓': props.corp_info_for_main.代表者名_姓,
                '代表者名_名': props.corp_info_for_main.代表者名_名,
                '代表者名_カナ_姓': props.corp_info_for_main.代表者名_カナ_姓,
                '代表者名_カナ_名': props.corp_info_for_main.代表者名_カナ_名,
                '代表者_役職': props.corp_info_for_main.代表者_役職,
                '管理者名_カナ_姓': props.corp_info_for_main.管理者名_カナ_姓,
                '管理者名_カナ_名': props.corp_info_for_main.管理者名_カナ_名,
                '管理者_電話番号': props.corp_info_for_main.管理者_電話番号,
                'メルマガ登録': props.corp_info_for_main.メルマガ登録,
                '業種': props.corp_info_for_main.業種,
                '会員種別': props.corp_info_for_main.会員種別,
            });

            sb_pay_method.value = creditParams.pay_method;
            sb_merchant_id.value = creditParams.merchant_id;
            sb_service_id.value = creditParams.service_id;
            sb_cust_code.value = creditParams.cust_code;
            sb_order_id.value = creditParams.order_id;
            sb_item_id.value = creditParams.item_id;
            sb_item_name.value = creditParams.item_name;
            sb_amount.value = creditParams.amount;
            sb_pay_type.value = creditParams.pay_type;
            sb_service_type.value = creditParams.service_type;
            sb_terminal_type.value = creditParams.terminal_type;

            sb_dtl_rowno_1.value = creditParams.dtl_rowno_1;
            sb_dtl_item_id_1.value = creditParams.dtl_item_id_1;
            sb_dtl_item_name_1.value = creditParams.dtl_item_name_1;
            sb_dtl_item_count_1.value = creditParams.dtl_item_count_1;
            sb_dtl_amount_1.value = creditParams.dtl_amount_1;

            sb_dtl_rowno_2.value = creditParams.dtl_rowno_2;
            sb_dtl_item_id_2.value = creditParams.dtl_item_id_2;
            sb_dtl_item_name_2.value = creditParams.dtl_item_name_2;
            sb_dtl_item_count_2.value = creditParams.dtl_item_count_2;
            sb_dtl_amount_2.value = creditParams.dtl_amount_2;

            sb_dtl_rowno_3.value = creditParams.dtl_rowno_3;
            sb_dtl_item_id_3.value = creditParams.dtl_item_id_3;
            sb_dtl_item_name_3.value = creditParams.dtl_item_name_3;
            sb_dtl_item_count_3.value = creditParams.dtl_item_count_3;
            sb_dtl_amount_3.value = creditParams.dtl_amount_3;

            sb_dtl_rowno_4.value = creditParams.dtl_rowno_4;
            sb_dtl_item_id_4.value = creditParams.dtl_item_id_4;
            sb_dtl_item_name_4.value = creditParams.dtl_item_name_4;
            sb_dtl_item_count_4.value = creditParams.dtl_item_count_4;
            sb_dtl_amount_4.value = creditParams.dtl_amount_4;

            sb_success_url.value = creditParams.success_url;
            sb_cancel_url.value = creditParams.cancel_url;
            sb_error_url.value = creditParams.error_url;
            sb_pagecon_url.value = creditParams.pagecon_url;
            sb_request_date.value = creditParams.request_date;

            // sb_hashkey.value = ref(VITE_SB_HASH_KEY);

            const vals = Object.values(creditParams);

            const str = vals.reduce((temp, item) => {
                return temp + item;
            });

            const hashData = SbHash(str + sb_hashkey.value);

            sb_sps_hashcode.value = hashData;

            await nextTick();
            submitCredit.value.submit();

        }

        const showConvenientPayFlg = ref(VITE_SHOW_CONVENIENT_PAY_FLG);

        const pay = () => {
            registerPayPerUse("payBank");
        }

        const makeInvoice = () => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'invoice_from_payment_status',
                'bill_id': bill_id.value,
                'corp_id': props.corp_info_for_main.id,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '請求書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const backAdministratorTop = () => {
            const url = "/al/administrator/home";
            location.href = url;
        }

        const imageSrc = ref("");

        const send_code = () => {
            const url = "/api/line/send_code";

            isProcessing.value = true;

            axios.post(url).then(res=>{
                visibleFlashMessage('メールを送信しました。', 'success');
            }).catch(err => {
                visibleFlashMessage('メール送信に失敗しました。時間をあけて再度操作してください。', 'error');
            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const changePaid = () => {
            const url = '/al/administrator/switchPaidMember?branch=' + input拠点数.value + '&user=' + input利用者数.value + '&family=' + input利用家族数.value + '&line=' + selectLINE連携.value;

            location.href = url;
        }

        return {
            mode,
            setLevel,
            backManageMemberInfo,
            goBack,
            input拠点数,
            input利用者数,
            input利用家族数,
            selectLINE連携,
            new拠点金額,
            new従業員金額,
            new家族金額,
            newLINE連携,
            label_new_拠点金額,
            label_new_従業員金額,
            label_new_家族金額,
            label_new_LINE連携,
            label_new_summary,
            calcNewAmount,
            openPrices,
            backAdministratorHome,

            closeConfirmModal,
            modalConfirmVisible,

            isProcessing,

            checkInput,

            confirmPayPerUse,
            registerPayPerUse,

            modalPaymentConfirmVisible,
            closePaymentConfirmModal,
            payPayPerUse,

            拠点数_message,
            拠点数_has_error,

            returnVal,

            showLINE,
            
            number_format,
            支払方法,

            densan_url,

            sb_pay_method,
            sb_merchant_id,
            sb_service_id,
            sb_cust_code,
            sb_order_id,
            sb_item_id,
            sb_item_name,
            sb_amount,
            sb_pay_type,
            sb_service_type,
            sb_terminal_type,

            sb_dtl_rowno_1,
            sb_dtl_item_id_1,
            sb_dtl_item_name_1,
            sb_dtl_item_count_1,
            sb_dtl_amount_1,
            
            sb_dtl_rowno_2,
            sb_dtl_item_id_2,
            sb_dtl_item_name_2,
            sb_dtl_item_count_2,
            sb_dtl_amount_2,

            sb_dtl_rowno_3,
            sb_dtl_item_id_3,
            sb_dtl_item_name_3,
            sb_dtl_item_count_3,
            sb_dtl_amount_3,
            
            sb_dtl_rowno_4,
            sb_dtl_item_id_4,
            sb_dtl_item_name_4,
            sb_dtl_item_count_4,
            sb_dtl_amount_4,

            sb_dtl_rowno_5,
            sb_dtl_item_id_5,
            sb_dtl_item_name_5,
            sb_dtl_item_count_5,
            sb_dtl_amount_5,

            sb_success_url,
            sb_cancel_url,
            sb_error_url,
            sb_pagecon_url,
            sb_request_date,
            sb_hashkey,
            sb_sps_hashcode,

            getOrderID,
            payCredit,

            showConvenientPayFlg,

            submitCredit,
            submitConvenience,

            bill_id,

            pay,

            makeInvoice,
            backAdministratorTop,
            imageSrc,
            send_code,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            modalRecommendSwitchVisible,
            closeRecommendSwitchModal,

            changePaid,
        }

    },
    props: {
        pay_per_use_info: {
            type: Object,
        },
        pay_per_use_amount: {
            type: Object,
        },
        back: {
            type: String,
        },
        branch_cnt_actual: {
            type: Number,
        },
        user_cnt_actual: {
            type: Number,
        },
        family_cnt_actual: {
            type: Number,
        },
        user_id_max: {
            type: Number,
        },
        corp_info_for_main: {
            type: Object,
        },
        年度: {
            type: Number,
        },
        max_pay_per_use_info: {
            type: Object,
        },
        max_pay_per_use_amount: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
    .font-gray {
        color: #bfbfbf;
    }

    #confirm-modal,
    #payment-confirm-modal,
    #recommend-switch-modal {
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #modal-confirm-content,
    #modal-payment-confirm-content {
        z-index: 2;
        width: 50%;
        padding: 5em;
        background: #fff;
    }

    #modal-recommend-switch-content {
        z-index: 2;
        width: 30%;
        padding: 5em;
        background: #fff;
    }

    #ssl_icon {
        background-image: url('../../../../svg/ssl2.svg');
        display: inline-flex;
        width: 86px;
        height: 33px;
        background-size: 86px auto;
        background-repeat: no-repeat;
    }

    .btn-send-barcode {
        border: 1px solid #a6a6a6;
        color: white;
        background-color: #a6a6a6;
    }

    .btn-send-barcode:hover,
    .btn-send-barcode:disabled {
        border: 1px solid #a6a6a6;
        color: #a6a6a6;
        background-color: white;
    }

    #img-LINE {
        height: 72px;
    }

    .link-orange,
    .link-orange:link,
    .link-orange:visited,
    .link-orange:hover,
    .link-orange:active{
        color: #Ff6600;
    }
</style>