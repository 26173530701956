<template>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                防災備蓄品リスト
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-end ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="backHome" v-if="is('administrator')">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="backHomeNoSave" v-else>
        </div>
    </div>

    <div class="row d-flex mt-3">
        <div class="col-md-4">
            <div class="row">
                <div class="col-8">
                    <select class="form-select" v-model="selectSortType">
                        <option value=""></option>
                        <option v-for="sort in sortList" :key="sort.column" :value="sort.column">{{ sort.name }}</option>
                    </select>
                </div>
                <div class="ps-0 col-4">
                    <input type="button" class="ms-0 btn btn-gray" value="並べ替え" @click="sortStockInfoList(selectSortType)">
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-3 mt-md-0">
            <div class="row">
                <div class="col-8 col-md-6">
                    <input type="text" class="form-control" placeholder="" v-model="searchKeyword">
                </div>
                <div class="ps-0 col-2">
                    <input type="button" class="btn btn-gray" value="検索" @click="searchStockInfoList(searchKeyword)">
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3 pb-5">
        <div>
            <template v-for="(stock, index) in stockInfoList" :key="stock.temp_idx">
                <div class="row mt-3 mt-md-0" v-show="stock.is_displayed">
                    <div class="col-md-1 px-1 px-md-0">
                        <div class="d-flex flex-column">
                            <div class="col py-2 border-black bg-color-header text-center" :class="{'md-none': !isEarliestItem(stock.temp_idx)}">
                                <label class="">区分</label>
                            </div>
                            <div class="col py-2 px-1 border-black ">
                                <select class="form-select" v-model="stock.division" :disabled="!is('administrator')">
                                    <option value=""></option>
                                    <option v-for="division in divisionTypeList" :key="division.code" :value="division.code">{{ division.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 px-1 px-md-0">
                        <div class="d-flex flex-column">
                            <div class="col py-2 border-black bg-color-header text-center" :class="{'md-none': !isEarliestItem(stock.temp_idx)}">
                                <label class="">品名</label>
                            </div>
                            <div class="col py-2 px-1 border-black ">
                                <input type="text" class="form-control" v-model="stock.product_name" :disabled="!is('administrator')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 px-1 px-md-0">
                        <div class="d-flex flex-column">
                            <div class="col py-2 border-black bg-color-header text-center" :class="{'md-none': !isEarliestItem(stock.temp_idx)}">
                                <label class="">個数</label>
                            </div>
                            <div class="col py-2 px-1 border-black ">
                                <input type="number" min="0" class="form-control text-end" v-model="stock.quantity" :disabled="!is('administrator')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 px-1 px-md-0">
                        <div class="d-flex flex-column">
                            <div class="col py-2 border-black bg-color-header text-center" :class="{'md-none': !isEarliestItem(stock.temp_idx)}">
                                <label class="">保管場所•保管方法</label>
                            </div>
                            <div class="col py-2 px-1 border-black ">
                                <input type="text" class="form-control" v-model="stock.storing_location_method" :disabled="!is('administrator')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 px-1 px-md-0">
                        <div class="d-flex flex-column">
                            <div class="col py-2 border-black bg-color-header text-center" :class="{'md-none': !isEarliestItem(stock.temp_idx)}">
                                <label class="font-s-075">消費期限（賞味期限・使用期限）</label>
                            </div>
                            <div class="col py-2 px-1 border-black ">
                                <input type="date" class="form-control" v-model="stock.use_by_date" :disabled="!is('administrator')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 px-1 px-md-0">
                        <div class="d-flex flex-column h-100">
                            <div class="col py-2 border-black bg-color-header text-center" :class="{'md-none': !isEarliestItem(stock.temp_idx)}">
                                <label class="font-s-075">消費・補充アラーム（1ヶ月前）</label>
                            </div>
                            <div class="col py-2 px-1 border-black">
                                <div class="form-control-plaintext form-control mx-2">
                                    <input class="form-check-input me-1" type="checkbox" :id="'checkbox' + index" v-model="stock.is_alarm_setting" :disabled="!is('administrator')">
                                    <label class="form-check-label" :for="'checkbox' + index">
                                        アラームを受信する
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div v-show="isEarliestItem(stock.temp_idx)">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>登録した備蓄品の消費期限の1ヶ月前に、TOTONO-Lから消費または補充をお知らせするメールが送信されます。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                        <div class="d-flex flex-row">
                            <input type="button" class="btn btn-del btn-sm mt-4" value="削除" @click="delRow(index)" :disabled="!is('administrator')" >
                            <input type="button" class="btn btn-black btn-sm mt-4 ms-3" value="1行増やす" @click="addRow" v-show="index === stockInfoList.length - 1" :disabled="!is('administrator')">
                            
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="row mt-5 pb-5 d-flex flex-row">
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-gray" value="運用サイクルHOME へ戻る" @click="backHome" v-if="is('administrator')">
                <input type="button" class="text-center btn btn-gray" value="運用サイクルHOME へ戻る" @click="backHomeNoSave" v-else>
            </div>
        </div>
    </div>

</template>

<script>
import { computed, inject, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    watch: {
        selectSortType: {
            // ソート対象項目が変わった場合は、昇順判定をリセットする
            handler: function() {
                this.isAscSort = true;
            }
        }
    },
    setup(props) {
        const dayjs = inject("dayjs");
        onMounted(() => {
            if (props.stock_info_list.length > 0) {
                let i = 0;
                props.stock_info_list.forEach(v => {
                    v.is_alarm_setting = v.is_alarm_setting === 1 ? true : false;
                    v.is_displayed = true;
                    v.temp_idx = i;
                    stockInfoList.value.push(v);
                    i++;
                });
            } else {
                // データが存在しない場合は空行(6行)を表示する
                for (let i = 0; i < 6; i++) {
                    addRow();
                }
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111111');
        const isProcessing = ref(false);
        const stockInfoList = ref([]);
        const selectSortType = ref('');
        const searchKeyword = ref('');
        const isAscSort = ref(true);
        const divisionTypeList = ref([
            {
                code: 1,
                name: '食品',
            },
            {
                code: 2,
                name: '衛生品',
            },
            {
                code: 9,
                name: 'その他',
            }
        ]);

        const sortList = ref([
            {
                column: 'division',
                name: '区分',
            },
            {
                column: 'product_name',
                name: '品名',
            },
            {
                column: 'quantity',
                name: '個数',
            },
            {
                column: 'storing_location_method',
                name: '保管場所・保管方法',
            },
            {
                column: 'use_by_date',
                name: '消費期限（賞味期限・使用期限）',
            },
            {
                column: 'is_alarm_setting',
                name: '消費・補充アラーム（1ヶ月前）',
            }
        ]);


        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 1行追加
        const addRow = () => {
            stockInfoList.value.push({
                corp_id: props.corp_info.id,
                division: '',
                product_name: '',
                quantity: '',
                storing_location_method: '',
                use_by_date: '',
                is_alarm_setting: false,
                is_displayed: true,
                temp_idx: stockInfoList.value.length,
            });
        }

        // 並び替え
        const sortStockInfoList = async (sortType) => {
            if (sortType !== '') {
                if (isAscSort.value) {
                    // 昇順に並べ替える
                    stockInfoList.value.sort((a, b) => {
                        if(a[sortType] === null || a[sortType] === ''){
                            return -1
                        }
                        if(b[sortType] === null || b[sortType] === ''){
                            return 1
                        }
                        if (a[sortType] >= b[sortType]) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    isAscSort.value = false;
                } else {
                    // 降順に並べ替える
                    stockInfoList.value.reverse((a, b) => {
                        if(a[sortType] === null || a[sortType] === ''){
                            return 1
                        }
                        if(b[sortType] === null || b[sortType] === ''){
                            return -1
                        }
                        if (a[sortType] <= b[sortType]) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    isAscSort.value = true;
                }
                let i = 0;
                stockInfoList.value.forEach((item, index) => {
                    item.temp_idx = i;
                    i++;
                });
            }
        }

        // リスト検索
        const searchStockInfoList = async (keyword) => {
            const keywordArray = keyword.split(/\s+/);
            const regex = new RegExp(`^.*${keywordArray.join('|')}.*$`);
            stockInfoList.value.forEach(v => {
                if (regex.test(v.product_name)) {
                // if (regex.test(v.product_name.concat(v.storing_location_method))) {  // 他の項目も含む場合は連結
                    v.is_displayed = true;
                } else {
                    v.is_displayed = false;
                }
            });
        }

        const registerFixationBi = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/registerBi';
            return await axios.post(postUrl, {
                corp_id: props.corp_info.id,
                stock_info_list: stockInfoList.value,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const backHome = async () => {
            const result = await registerFixationBi();
            if (result == '200') {
                // 目次ページへ遷移
                let url = "/fixation/home";
                switch(props.back){
                    case "Home":
                        url = "/home";
                        break;
                    case "BCP12":
                        url = "/bcp/bcp_second/12";
                        break;
                    default:
                        url = '/fixation/home';
                }
                location.href = url;
            }
        }

        const backHomeNoSave = () => {
            // 目次ページへ遷移
            let url = "/fixation/home";
            switch(props.back){
                case "Home":
                    url = "/home";
                    break;
                case "BCP12":
                    url = "/bcp/bcp_second/12";
                    break;
                default:
                    url = '/fixation/home';
            }
            location.href = url;
        }

        const delRow = (index) => {
            const division = divisionTypeList.value.find(item => {
                return item.code == stockInfoList.value[index].division;
            });
            let division_name = "";
            if(division){
                division_name = division.name;
            }

            const confirmMessage = "区分: " + division_name + " 品名: " + stockInfoList.value[index].product_name + " 消費期限: " + (stockInfoList.value[index].use_by_date ? dayjs(stockInfoList.value[index].use_by_date).format("YYYY/MM/DD") : "") + "を削除します。よろしいですか？";
            if(!confirm(confirmMessage)){
                return false;
            }

            stockInfoList.value.splice(index, 1);

            if(stockInfoList.value.length === 0){
                //なくなったら1行足す
                addRow();
            }
        }

        const isDate = (v) => !isNaN(new Date(v).getTime());

        const displayedItems = computed(() => {
            return stockInfoList.value.filter(item => item.is_displayed);
        });

        const lowestTempIdxItem = computed(() => {
            return displayedItems.value.reduce((lowest, item) => {
                return lowest === null || item.temp_idx < lowest.temp_idx ? item : lowest;
            }, null);
        });

        const isEarliestItem = (item) => {
            if(lowestTempIdxItem.value){
                return item === lowestTempIdxItem.value.temp_idx;
            }else{
                return false;
            }
        };
        
        return {
            setLevel,
            isProcessing,
            stockInfoList,
            divisionTypeList,
            sortList,
            isAscSort,
            selectSortType,
            searchKeyword,
            callReplaceWord,
            backHome,
            backHomeNoSave,
            addRow,
            sortStockInfoList,
            searchStockInfoList,
            delRow,
            displayedItems,
            lowestTempIdxItem,
            isEarliestItem,
            isDate,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        stock_info_list: {
            type: Array,
        },
        back: {
            type: String,
        },
    }

}
</script>

<style lang="scss" scoped>
.border-black {
    border-color: #808080;
}

.bg-color-header {
    background-color: #d9d9d9;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}

.btn-del {
    background-color: #7f6000;
    color: white;
    border: 1px solid #7f6000;
}

.btn-del:hover {
    background-color: white;
    color: #7f6000;
    border: 1px solid #7f6000;
}

@media (min-width: 769px) {
    .md-none{
        display: none;
    }
} 
</style>
