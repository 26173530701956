<template>
    <div>
        <div class="row mt-3 pb-1 border-bottom-red">
            <div class="h4 mt-5 color-resilience-red">
                有償会員規約
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
                本規約は、TOTONO-Lサイトの利用規約を補完する有償会員専用の規約です。有償会員は、利用規約と共に本規約を十分に理解しこれを承諾したうえで、本サービスを利用するものとします。
            </div>
        </div>
            
        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                1&nbsp;&emsp;&emsp;用語の定義
            </div>
        </div>
        
        <div class="row">
            <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
                &emsp;&emsp;&emsp;用語の定義は、利用規約に準じます。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                2&nbsp;&emsp;&emsp;加入承認
            </div>
        </div>
        
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                本サービスの利用を目的に、TOTONO-Lサイト上で有償会員の登録手続を行ったユーザーは、当会の会員として加入申込みがあったものとみなし、加入を承認します。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                3&nbsp;&emsp;&emsp;会員種別
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                有償会員は、次の中からいずれか1つの会員種別を選択し、当会の会員として登録するものとします。
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-5 col-md-1 d-flex align-items-center justify-content-center py-2">
                &emsp;&emsp;1   
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                企業会員
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-5 col-md-1 d-flex align-items-center justify-content-center py-2">
                &emsp;&emsp;2   
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                団体会員
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-5 col-md-1 d-flex align-items-center justify-content-center py-2">
                &emsp;&emsp;3   
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                団体所属会員
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-5 col-md-1 d-flex align-items-center justify-content-center py-2">
                &emsp;&emsp;4
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                公共会員
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                4&nbsp;&emsp;&emsp;年会費および従量課金
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                有償会員は、1年ごとに当会が定める年会費又は従量課金を納めるものとします。
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                年会費又は従量課金は年払いのみとし、加入月のみ月割計算し、翌年からは1年分（毎年10月1日から翌年9月30日まで）を12月末日までに所定の決済サービスにより納めるものとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                5&nbsp;&emsp;&emsp;有償会員の地位
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                有償会員は、「一般社団法人及び一般財団法人に関する法律」（以下「一般法人法」といいます。）よる社員の地位に該当しないものとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                6&nbsp;&emsp;&emsp;利用契約書
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                有償会員は、1ヶ月以内に、TOTONO-Lが指定する方法により、当会に「利用契約書」を提出するものとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                7&nbsp;&emsp;&emsp;利用の終了および退会予告手続
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                　
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                有償会員が本サービスの利用終了を希望する場合は、毎年12月末までにTOTONO-Lサイト上で退会予告手続を行う必要があります。
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                　
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                1月以降に退会予告手続を行った場合は、1年分の年会費が発生することに同意するものとします。
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                　
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                いかなる場合であっても、年度の途中で退会予告された場合、会員から受領した年会費、従量課金は一切返戻いたしません。ただし、毎年9月末日まではTOTONO-Lサイトが利用できるものとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                &nbsp;&nbsp;&emsp;&emsp;付則
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                本規約の改廃は、当会の理事会において行なう。
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                制定：2022年12月1日
            </div>
        </div>

        <div class="mt-5">
            <input type="button" class="btn btn-black px-3" value="確認したので、このページを綴じる" @click="closeTab" />
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const closeTab = () => {
            window.close();
        }

        return {
            closeTab,
        }
        
    },
}
</script>

