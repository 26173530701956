<template>
    <div class="row ms-lg-5">
        <div class="col-md-10">
            <div class="h5 heading-green mt-3 print-font-sm">
                BCP表紙
            </div>
        </div>
        <div class="col-md-2 mt-3 no-print">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="returnHome" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="mt-5 py-5 d-flex justify-content-center print-font-16pt">
                {{ corp_info.組織名 }}
            </div>
            <div class="row my-5">
                <div class="print-font-20pt title py-2 text-center">事業継続計画（BCP）</div>
            </div>
            <div class="row pt-5 height-250">
                <div class="col-md-6 offset-md-6">
                    <div class="row justify-content-md-end" v-for="(version, index) in bcp_version" :key="version.version">
                        <div class="col-md-6 mt-5 mt-md-2">
                            <label class="form-label version border-version py-2 px-4 text-center text-nowrap headline align-items-baseline">
                                {{ version.version==1 ? '作成日' : '改定日' }}
                            </label>
                            <label class="form-label version border-version py-2 px-5 text-center text-nowrap align-items-baseline">
                                {{ this.$root.dayjs(version.作成日).format('YYYY年MM月DD日') }}
                            </label>
                        </div>
                        <div class="col-md-2 ms-md-3 mt-2">
                            <label class="form-label version border-version py-2 px-4 width-100 text-center text-nowrap headline align-items-baseline">
                                {{ version.version==1 ? '初版' : '第' + version.version + '版' }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('111100');

        const showNext = () => {
            const url = "/browse/N";

            location.href = url;
        }

        return {
            setLevel,
            showNext,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        bcp_version: {
            type: Array,
        }
    }
}

</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.title, .version {
    background-color: #f2f2f2;
    border-top: 1px solid common_colors.colors(resilience-gray);
    border-bottom: 1px solid common_colors.colors(resilience-gray);
}

.border-version {
    border: 1px solid common_colors.colors(resilience-gray);
}

.width-100 {
    width: 100px;
}

.height-250 {
    min-height: 250px;
}

@media print {
.title, .version {
    background-color: #f2f2f2;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.border-version {
    border: 1px solid black;
}

}
</style>