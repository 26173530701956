<template>
    <div>
        <div class="heading-orange mt-3">
            わたしの家族登録
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <form @submit.prevent="post_form">
            <div class="d-flex row mt-5">
                <div class="ms-md-5 p-2 border border-dark col-8 col-md-3 bg-light"> あなたが登録できる家族の人数は</div>
                <div class="p-2 border border-dark col-1 col-md-1 text-center bg-light">{{ user_info.家族人数 }}</div>
                <div class="p-2 border border-dark col-3 col-md-1 bg-light">人です。</div>
            </div>
            <label class="ms-md-4">※家族登録を追加したい場合は、管理者にご相談ください。</label>

            <div v-for="(family, index) in family_list" :key="family.family_id">
                <div class="row mt-5">
                    <h5>家族{{ index +1 }}</h5>
                </div>
                <div class="row mt-3">
                    <label class="col-md-3 ms-md-5">
                        家族ID　　<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-md-2 d-flex">
                        <label class="col-form-label">{{ user_info.利用者ID }}-</label>
                        <input type="text" readonly class="form-control" maxlength="10" required :value="padStart_2(family.family_id)"/>
                    </div>
                    <label class="col-md-2 ms-md-5">
                        パスワード<span class="badge bg-secondary ms-5" v-show="!family.isRegister">必須</span>
                    </label>
                    <div class="col-md-4 d-flex flex-row">
                        <input type="text" class="form-control" maxlength="50" v-model="family.password" :class="{'is-invalid': family.password_has_error}"/>
                        <input type="button" class="button btn-red ms-3" value="自動生成" @click="makePassword(family.family_id)" />
                        <div class='invalid-feedback'>{{ family.password_error_msg }}</div>
                    </div>
                </div>

                <div class="row mt-3">
                    <label class="col-md-3 ms-md-5">
                        氏名　　　<span class="badge bg-secondary ms-md-5">必須</span>
                    </label>
                    <label class="col-2 col-md-1 text-center col-form-labelmake">
                        姓
                    </label>
                    <div class="col-4 col-md-2">
                        <input type="text" class="form-control" maxlength="50" v-model="family.家族氏名_姓" :class="{'is-invalid': family.家族氏名_姓_has_error}" />
                        <div class='invalid-feedback'>{{ family.家族氏名_姓_error_msg }}</div>
                    </div>
                    <label class="col-2 col-md-1 text-center col-form-label">
                        名
                    </label>
                    <label class="col-4 col-md-2">
                        <input type="text" class="form-control" maxlength="50" v-model="family.家族氏名_名" :class="{'is-invalid': family.家族氏名_名_has_error}" />
                        <div class='invalid-feedback'>{{ family.家族氏名_名_error_msg }}</div>
                    </label>
                </div>

                <div class="row mt-md-3">
                    <label class="col-md-3 ms-md-5">
                        <span class="ms-5">　　</span>
                    </label>
                    <label class="col-2 col-md-1 text-center col-form-label">
                        セイ
                    </label>
                    <div class="col-4 col-md-2">
                        <input type="text" class="form-control" maxlength="50" v-model="family.家族氏名_カナ_姓" :class="{'is-invalid': family.家族氏名_カナ_姓_has_error}" />
                        <div class='invalid-feedback'>{{ family.家族氏名_カナ_姓_error_msg }}</div>
                    </div>
                    <label class="col-2 col-md-1 text-center col-form-label">
                        メイ
                    </label>
                    <label class="col-4 col-md-2">
                        <input type="text" class="form-control" maxlength="50" v-model="family.家族氏名_カナ_名" :class="{'is-invalid': family.家族氏名_カナ_名_has_error}" />
                        <div class='invalid-feedback'>{{ family.家族氏名_カナ_名_error_msg }}</div>
                    </label>
                </div>

                <div class="row mt-3">
                    <label class="col-md-3 ms-md-5">
                        続柄　　　<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-md-2">
                        <input type="text" class="form-control" maxlength="50" v-model="family.続柄" :class="{'is-invalid': family.続柄_has_error}" />
                        <div class='invalid-feedback'>{{ family.続柄_error_msg }}</div>
                    </div>
                </div>

                <div class="row mt-3">
                    <label class="col-md-3 ms-md-5">
                        通勤通学先<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-md-5">
                        <input type="text" class="form-control" maxlength="100" v-model="family.通勤通学先" :class="{'is-invalid': family.通勤通学先_has_error}" />
                        <div class='invalid-feedback'>{{ family.通勤通学先_error_msg }}</div>
                    </div>
                </div>

                <div class="row mt-3">
                    <label class="col-md-3 ms-md-5">
                        携帯電話　<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-3 col-md-1">
                        <input type="text" class="form-control" maxlength="10" v-model="family.電話番号1" :class="{'is-invalid': family.電話番号_has_error}" />
                        <div class='invalid-feedback'>{{ family.電話番号_error_msg }}</div>
                    </div>
                    <label class="col-1 text-center">
                        －
                    </label>
                    <div class="col-3 col-md-1">
                        <input type="text" class="form-control" maxlength="10" v-model="family.電話番号2" :class="{'is-invalid': family.電話番号_has_error}"  />
                    </div>
                    <label class="col-1 text-center">
                        －
                    </label>
                    <div class="col-3 col-md-1">
                        <input type="text" class="form-control" maxlength="10" v-model="family.電話番号3" :class="{'is-invalid': family.電話番号_has_error}"  />
                    </div>
                </div>

                <div class="row mt-3 pb-3 border-bottom border-dark">
                    <label class="col-md-3 ms-md-5">
                        携帯mail　<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-md-5">
                        <input type="mail" class="form-control" maxlength="255" v-model="family.メールアドレス" :class="{'is-invalid': family.メールアドレス_has_error}" />
                        <div class='invalid-feedback'>{{ family.メールアドレス_error_msg }}</div>
                    </div>
                    <!-- <div class="p-2 border border-white col-md-2 ms-3 d-inline bg-dark text-white text-center"> ワンセット増やす</div> -->
                </div>

            </div>

            <div class="ms-md-5 mt-5">
                <input type="submit" class="btn btn-black px-3" value="上記内容で家族を登録する" :disabled="isProcessing" />
            </div>
            <div class="row ms-md-5 flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>

            <!-- <div class="d-flex row mt-5">
                <div class="ms-5 p-2 col-md-2">⦿　家族一覧</div>
                <div class="p-2 border border-dark col-md-2 text-center">別画面で一覧を開く</div>
            </div>

            <div class="input-group row mt-3 col-md-2">
            <input type="text" class="ms-5 p-2 form-control bg-white" placeholder="家族ID" disabled readonly>
            <input type="text" class="p-2 form-control bg-white" placeholder="家族の氏名" disabled readonly>
            <input type="text" class="p-2 col-md-2 form-control bg-white" placeholder="フリガナ" disabled readonly>
            <input type="text" class="p-2 col-md-2 form-control bg-white" placeholder="続柄" disabled readonly>
            <input type="text" class="p-2 col-md-2 form-control bg-white" placeholder="携帯電話番号" disabled readonly>
            <input type="text" class="p-2 col-md-2 form-control bg-white" placeholder="メールアドレス" disabled readonly>
            <div class="p-2 col-md-1 ms-3 d-inline text-white text-center"></div>
            </div>

            <div class="input-group row col-md-2">
            <input type="text" class="ms-5 p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <div class="p-2 border border-white col-md-1 ms-3 d-inline bg-dark text-white text-center"> 変更</div>
            </div>

            <div class="input-group row col-md-2">
            <input type="text" class="ms-5 p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <div class="p-2 border border-white col-md-1 ms-3 d-inline bg-dark text-white text-center"> 変更</div>
            </div>

            <div class="input-group row col-md-2">
            <input type="text" class="ms-5 p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <div class="p-2 border border-white col-md-1 ms-3 d-inline bg-dark text-white text-center"> 変更</div>
            </div>

            <div class="input-group row col-md-2">
            <input type="text" class="ms-5 p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <input type="text" class="p-2 form-control bg-white">
            <div class="p-2 border border-white col-md-1 ms-3 d-inline bg-dark text-white text-center"> 変更</div>
            </div> -->

            <div class="ms-md-5 mt-5">
                <input type="button" class="btn btn-resilience-green px-3" value="マイページへ戻る" @click="backMyPage" :disabled="isProcessing" />
            </div>
        </form>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import CommonCheck from '../../../function/CommonCheck';
const { isTelNumber } = CommonCheck();
import { CommonMakePassword } from '../../../function/CommonMakePassword.js';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            family_list.value = [...props.family];
        });

        const setLevel = ref('111111');

        const isProcessing = ref(false);

        const post_form = () => {
            clearError();
            if(!inputCheck()){
                return;
            };

            const url = '/api/myPage/register_my_family';

            isProcessing.value = true;

            axios.post(url, {
                'family_list' : family_list.value.filter((value) => { return value.家族氏名_姓 !== '' }),
            }).then(res => {
                visibleFlashMessage('正常に登録されました', 'success');
                location.reload();
            }).catch(err => {
            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const inputCheck = () => {
            for(let i = 0; i < family_list.value.length; i++){
                if(family_list.value[i].家族氏名_姓 === '' &&
                   family_list.value[i].家族氏名_名 === '' &&
                   family_list.value[i].家族氏名_カナ_姓 === '' &&
                   family_list.value[i].家族氏名_カナ_名 === '' &&
                   family_list.value[i].続柄 === '' &&
                   family_list.value[i].通勤通学先 === '' &&
                   family_list.value[i].電話番号1 === '' &&
                   family_list.value[i].電話番号2 === '' &&
                   family_list.value[i].電話番号3 === '' &&
                   family_list.value[i].メールアドレス === '' &&
                   family_list.value[i].password === ''
                ){
                    continue;
                }

                if(family_list.value[i].家族氏名_姓 === ''){
                    family_list.value[i].家族氏名_姓_error_msg = '氏名（姓）は入力必須です。';
                    family_list.value[i].家族氏名_姓_has_error = true;
                    return false;
                }

                if(family_list.value[i].家族氏名_名 === ''){
                    family_list.value[i].家族氏名_名_error_msg = '氏名（名）は入力必須です。';
                    family_list.value[i].家族氏名_名_has_error = true;
                    return false;
                }

                if(family_list.value[i].家族氏名_カナ_姓 === ''){
                    family_list.value[i].家族氏名_カナ_姓_error_msg = '氏名（姓）は入力必須です。';
                    family_list.value[i].家族氏名_カナ_姓_has_error = true;
                    return false;
                }

                if(family_list.value[i].家族氏名_カナ_名 === ''){
                    family_list.value[i].家族氏名_カナ_名_error_msg = 'カナ（名）は入力必須です。';
                    family_list.value[i].家族氏名_カナ_名_has_error = true;
                    return false;
                }
                if(family_list.value[i].続柄 === ''){
                    family_list.value[i].続柄_error_msg = '続柄は入力必須です。';
                    family_list.value[i].続柄_has_error = true;
                    return false;
                }
                if(family_list.value[i].通勤通学先 === ''){
                    family_list.value[i].通勤通学先_error_msg = '通勤通学先は入力必須です。';
                    family_list.value[i].通勤通学先_has_error = true;
                    return false;
                }
                if(family_list.value[i].電話番号1 === '' || family_list.value[i].電話番号2 === '' || family_list.value[i].電話番号3 === ''){
                    family_list.value[i].電話番号_error_msg = '電話番号は入力必須です。';
                    family_list.value[i].電話番号_has_error = true;
                    return false;
                }
                if(isTelNumber(family_list.value[i].電話番号1 + family_list.value[i].電話番号2 + family_list.value[i].電話番号3)){
                    family_list.value[i].電話番号_error_msg = '電話番号を確認してください。';
                    family_list.value[i].電話番号_has_error = true;
                    return false;
                }
                if(family_list.value[i].メールアドレス === ''){
                    family_list.value[i].メールアドレス_error_msg = 'メールアドレスは入力必須です。';
                    family_list.value[i].メールアドレス_has_error = true;
                    return false;
                }
                if(family_list.value[i].password === '' && !family_list.value[i].isRegister){
                    family_list.value[i].password_error_msg = 'パスワードは入力必須です。';
                    family_list.value[i].password_has_error = true;
                    return false;
                }

            }

            return true;
        }

        const padStart_2 = (str) => {
            return str.toString().padStart(2, '0');
        }

        const clearError = () => {
            family_list.value.forEach((item) => {
                item.家族氏名_姓_error_msg = '';
                item.家族氏名_姓_has_error = false;
                item.家族氏名_名_error_msg = '';
                item.家族氏名_名_has_error = false;
                item.家族氏名_カナ_姓_error_msg = '';
                item.家族氏名_カナ_姓_has_error = false;
                item.家族氏名_カナ_名_error_msg = '';
                item.家族氏名_カナ_名_has_error = false;
                item.続柄_error_msg = '';
                item.続柄_has_error = false;
                item.通勤通学先_error_msg = '';
                item.通勤通学先_has_error = false;
                item.電話番号_error_msg = '';
                item.電話番号_has_error = false;
                item.メールアドレス_error_msg = '';
                item.メールアドレス_has_error = false;
                item.password_error_msg = '';
                item.password_has_error = false;
            });

        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const backMyPage = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const family_list = ref([]);

        const makePassword = (id) => {
            const make_password_family = family_list.value.find((data) => data.family_id === id);
            make_password_family.password = CommonMakePassword(12);

        }

        return {
            setLevel,
            isProcessing,

            post_form,
            clearError,
            inputCheck,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            backMyPage,

            family_list,

            makePassword,

            padStart_2,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        user_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        family: {
            type: Array,
        },

    }
}
</script>