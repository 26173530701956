<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                マッチングワード登録
            </div>
            <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-dark btn-sm" value="前ページへ戻る" @click="returnPage" :disabled="isProcessing">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="row ms-4 mx-5 mt-5">
            <div class="p2 col-md-4 border bg-white d-flex align-items-center py-2">
                業種（日本標準産業分類）
            </div>
            <div class="p2 col-md-6 border bg-white d-flex align-items-center border-start-0 py-2">
                {{ corp_info.業種 + '　　' + corp_info.業種名 }}
            </div>
        </div>

        <label class="ms-4 mt-3">災害応援協定先の{{ callReplaceWord('組織') }}が、あなたの{{ callReplaceWord('組織') }}を探しやすいように、キーワードを登録します。</label><br>

        <form @submit.prevent="post_form">
            <div class="row ms-4 mt-5">
                <div class="p2 col-md-1 border bg-member_card d-flex align-items-center py-2">
                </div>
                <div class="p2 col-md-7 border border-start-0 bg-member_card d-flex align-items-center py-2">
                    カテゴリー　※1つの枠の中でチェックできるのは1つのみ
                </div>
                <div class="p2 col-md-3 border border-start-0 bg-member_card d-flex align-items-center border-start-0 py-2">
                    マッチングワード（3つまで登録可）
                </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center ps-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>規模が大きい{{ callReplaceWord('組織') }}が、小さい{{ callReplaceWord('組織') }}を助けられるよう、TOTONO-Lでは「要請に応える」のみの協定締結を推進しています。<br />カテゴリーはいくつでも登録できるため、一方的な支援協定と対等な相互協定をご検討ください。<br />なお、相互協定を希望する場合、依頼と要請で2つのキーワード登録を行ってください。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
            </div>

            <div>
                <div class="row ms-4" v-for="(matching, index) in matching_word" :key="index">
                    <div class="col-md-1 border d-flex flex-column align-items-center justify-content-center bg-white">
                        <label>{{ index + 1 }}</label>
                        <input type="button" class="btn btn-red btn-sm mt-3" value="削除" @click="deleteMatchingWord(index)">
                    </div>
                    <div class="col-md-7 border bg-white py-3">
                        <div class="row mx-3">
                            <div class="col-md-2">
                                <h5><span class="badge bg-header px-4">ヒト</span></h5>
                            </div>
                            <div class="col-md-3">
                                <h5><span class="badge bg-title px-4">応援要員</span></h5>
                            </div>
                            <div class="col-md-7">
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" :id="'hito1_1' + (index + 1)" :value="'1_1-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'hito1_1' + (index + 1)">協力を依頼したい</label>
                                </div>
                                <div class="form-check form-check-inline mx-3">
                                    <input type="radio" class="form-check-input" :id="'hito1_2' + (index + 1)" :value="'1_2-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'hito1_2' + (index + 1)">要請に応えたい</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-3">
                            <div class="col-md-2">
                                <h5><span class="badge bg-header px-4">モノ</span></h5>
                            </div>
                            <div class="col-md-3">
                                <h5><span class="badge bg-title px-4">資機材・車両等</span></h5>
                            </div>
                            <div class="col-md-7">
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" :id="'mono2_1' + (index + 1)" :value="'2_1-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'mono2_1' + (index + 1)">協力を依頼したい</label>
                                </div>
                                <div class="form-check form-check-inline mx-3">
                                    <input type="radio" class="form-check-input" :id="'mono2_2' + (index + 1)" :value="'2_2-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'mono2_2' + (index + 1)">要請に応えたい</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-3">
                            <div class="col-md-2">
                                <h5><span class="badge bg-header px-4">コト</span></h5>
                            </div>
                            <div class="col-md-3">
                                <h5><span class="badge bg-title px-4">代替拠点</span></h5>
                            </div>
                            <div class="col-md-7">
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" :id="'koto3_1' + (index + 1)" :value="'3_1-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'koto3_1' + (index + 1)">協力を依頼したい</label>
                                </div>
                                <div class="form-check form-check-inline mx-3">
                                    <input type="radio" class="form-check-input" :id="'koto3_2' + (index + 1)" :value="'3_2-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'koto3_2' + (index + 1)">要請に応えたい</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-3">
                            <div class="col-md-3 offset-md-2">
                                <h5><span class="badge bg-title px-4">代替業務〔生産〕</span></h5>
                            </div>
                            <div class="col-md-7">
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" :id="'koto4_1' + (index + 1)" :value="'4_1-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'koto4_1' + (index + 1)">協力を依頼したい</label>
                                </div>
                                <div class="form-check form-check-inline mx-3">
                                    <input type="radio" class="form-check-input" :id="'koto4_2' + (index + 1)" :value="'4_2-' + (index + 1)" v-model="matching.category">
                                    <label class="form-check-label" :for="'koto4_2' + (index + 1)">要請に応えたい</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 border d-flex flex-column px-3 bg-white">
                        <div class="my-2">
                            <input type="text" class="form-control" v-model="matching.matching_word_1" maxlength="255" />
                        </div>
                        <div class="my-2">
                            <input type="text" class="form-control" v-model="matching.matching_word_2" maxlength="255" />
                        </div>
                        <div class="my-2">
                            <input type="text" class="form-control" v-model="matching.matching_word_3" maxlength="255" />
                        </div>
                    </div>
                    <div class="col-md-1 d-flex flex-column ps-4 " v-if="index === 0">
                        <div class="my-2">
                            <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>マッチングワード：ヒトには、応援要員の依頼または提供可能な人員見込数などを記載し、検索率をヒットさせましょう。</label>
                                </div>
                            </template>
                        </VDropdown>
                        </div>
                        <div class="my-2">
                            <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>マッチングワード：モノには、資機材・車両の名称・品番・型式などを記載し、検索率をヒットさせましょう。</label>
                                </div>
                            </template>
                        </VDropdown>
                        </div>
                        <div class="my-2">
                            <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>マッチングワード：コトには、業務や{{ callReplaceWord('商品') }}の具体的な内容などを記載し、検索率をヒットさせましょう。</label>
                                </div>
                            </template>
                        </VDropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="p2 col-md-2 ms-auto d-flex align-items-center py-2">
                    <input type="button" class="btn btn-dark btn-sm" value="ワンセット増やす" @click="addNewMatchingList">
                </div>
            </div>

            <div class="row mt-5 ms-5">
                <div class="col-md-6" :disabled="isProcessing">
                    <input type="file" style="display:none" @change="dropFile"/>
                    <div class="px-5 py-3 btn-blue text-center" 
                            @dragleave="dragLeave"
                            @dragover.prevent="dragEnter"
                            @drop.prevent="dropFile"
                            :class="{enter: isEnter}"
                        >
                        <label class="h2">
                            マッチング用PR画像を<br>アップロードする
                        </label>
                        <div class="h4 mt-2">
                            {{ callReplaceWord('自社') }}の写真やロゴ等Jpegデータを提供する
                        </div>
                        <label class="col-form-label btn btn-darkblue" for="submit_pdf">
                            ファイルを選択
                            <input id="submit_pdf" type="file" class="no-display" accept=".jpg, .jpeg" @change="dropFile" />
                        </label>
                    </div>
                </div>
                <div class="col-md-6" v-show="thumbnail != ''">
                    <img :src="thumbnail" alt="image" class="thumbnail" />
                </div>
            </div>

            <div class="text">
                <label class="ms-4 mt-5">〇&emsp;画像を変更する場合は、再度アップロードしてください。以前の画像に上書きされます。</label><br>
                <label class="ms-4 mt-3">×&emsp;著作権侵害など違法なファイルはアップロードしないでください。</label><br>
                <label class="ms-4 mt-3">×&emsp;フォルダのアップロードはできません。</label><br>
            </div>

            <div class="p2 ms-4 mt-5">
                <input type="submit" class="btn btn-dark px-5 py-2" value="上記の内容で、カテゴリーとマッチングワードを登録" :disabled="isProcessing">
                <div class="row ms-5 flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>
            </div>
            
            <div class="p2 ms-4 mt-3">
                <input type="button" class="btn btn-gray px-5 py-2" value="管理者ページへ戻る" @click="backHome" :disabled="isProcessing">
            </div>
        </form>

    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.rtn_matching_word_list.length > 0){
                matching_word.splice(0);
                for(let i = 0; i < props.rtn_matching_word_list.length; i++){
                    matching_word.push(props.rtn_matching_word_list[i]);
                }
            }
            if(props.rtn_image){
                thumbnail.value = props.rtn_image;
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111111');

        const isEnter = ref(false);

        const dragEnter = () => {
            isEnter.value = true;
        }

        const dragLeave = () => {
            isEnter.value = false;
        }

        const files = ref([]);

        const fd_2 = ref(new FormData());
        const imageUrl = ref('');
        const thumbnail = ref('');

        const dropFile = (e, key = '', image = {}) => {
            isEnter.value = false;

            const fileList = e.target.files ? e.target.files : e.dataTransfer.files;

            if(fileList.length === 0){
                return false;
            }

            let files = [];

            for(let i = 0; i < fileList.length; i++){
                files.push(fileList[i]);
            }

            // 今回は1ファイルのみ送ることにする。
            let file = files.length > 0 ? files[0] : [];
            if(file.type !== 'image/jpeg'){
                return false;
            }
            if(file.size > 10000000){
                return false;
            }
            // fd_2.value = new FormData(); //★②
            fd_2.value.append('file', file);

            const picture = getBase64(file).then((result) => {
                thumbnail.value = result;
            });

            // const url = '/api/postUseContract';
            // const url = '/api/postCorpImage';

            // const config = {
            //     headers: {
            //         'content-type': 'multipart/form-data'
            //     }
            // };

            // axios.post(url, fd, config).then(res => {
            //     const url_getImage = '/api/getCorpImage?corp_id=' + props.corp_info.id;

            //     // axios.get(url_getImage).then(res => {
            //     //     previewBase64.value = res.data;
            //     // });
            // }).finally(fin => {
            //     e.target.value = null;
            // });
        }

        // const previewBase64 = ref('');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list);
        }

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const matching_word = reactive([{
            id: 0,
            category: '',
            matching_word_1: '',
            matching_word_2: '',
            matching_word_3: '',
        }]);

        const addNewMatchingList = () => {
            matching_word.push({
                id: 0,
                category: '',
                matching_word_1: '',
                matching_word_2: '',
                matching_word_3: '',
            });
        }

        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        const isProcessing = ref(false);

        const post_form = () => {
            const url = '/api/administrator/registerMatchingWord';

            fd_2.value.append('matching_word_list', JSON.stringify(matching_word));
            fd_2.value.append('id', props.corp_info.id);
            fd_2.value.append('corp_name', props.corp_info.組織名);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            isProcessing.value = true;
            axios.post(url, fd_2.value, config).then(res => {
                visibleFlashMessage('正常に更新されました', 'success');
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000);
        }

        const returnPage = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const deleteMatchingWord = (index) => {
            if(!confirm((index + 1).toString() + "番目のマッチングワードを削除します。よろしいですか？")){
                return false;
            }
            matching_word.splice(index, 1);
        }

        return {
            isProcessing,

            setLevel,
            isEnter,
            dragEnter,
            dragLeave,
            files,
            dropFile,

            // previewBase64,

            callReplaceWord,

            backHome,

            matching_word,
            addNewMatchingList,

            fd_2,
            imageUrl,
            getBase64,
            thumbnail,

            post_form,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            returnPage,

            deleteMatchingWord,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        rtn_matching_word_list: {
            type: Array,
        },
        rtn_image: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
    .btn-blue {
        border: 1px solid;
        color: white;
        border-color: #2f75b5;
        background-color: #2f75b5;
    }

    .enter {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

    .btn-darkblue {
        border: 1px solid;
        color: white;
        border-color: white;
        background-color: #2f75b5;
    }

    .btn-darkblue:hover {
        border: 1px solid;
        color: #2f75b5;
        border-color: white;
        background-color: white;
    }


    .no-display {
        display: none;
    }

    .bg-header {
        background-color: #bdd6ee;
        color: inherit;
        border: 1px solid #d8d8d8;
    }

    .bg-title {
        background-color: #f2f2f2;
        color: inherit;
        border: 1px solid #bfbfbf;
        display: inline-block; /* 要素をブロックレベル要素に変更 */
        width: 100%
    }

    .thumbnail{
        max-width: 100%;
        max-height: 200px;
    }
</style>