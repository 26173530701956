<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">団体会員と団体所属会員との紐づけ登録</div>
            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">団体所属会員</label></div>
        </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>会員番号</th>
                        <th>団体会員の名称</th>
                        <th>団体会員コード</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="corp in corp_list" :key="corp.id" :value="corp.id">
                        <td>{{ corp.企業ID }}</td>
                        <td>{{ corp.組織名 }}</td>
                        <td>{{ corp.企業ID }}</td>
                        <td><input type="button" class="btn btn-black px-3" value="団体所属会員一覧・新規登録" @click="editMember(corp.id)" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const editMember = (id) => {
            const url = '/s-admin/showGroupMember/' + id;

            location.href = url;
        }

        const back = () => {
            const url = '/s-admin/manage_group_supporting';

            location.href = url;
        }

        return{
            editMember,
            back,
        }
    },
    props: {
        corp_list: {
            type: Array,
        }
    }

}
</script>

