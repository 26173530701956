<template>
    <div class="container">
        <div class="row mt-3">
            <div class="heading mt-3">団体会員・賛助会員の管理</div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">団体会員と団体所属会員との紐づけ登録</label></div>
        </div>
        <div class="row mt-3">
            <div class="col-md-2 ms-4">
                <input type="button" class="btn btn-red py-2 px-4" value="団体会員一覧から追加" @click="showGroupMemberList" />
            </div>
        </div>
        <div class="row mt-5">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">理事会承認ずみ賛助会員の登録</label></div>
        </div>
        <div class="row mt-3 px-5">
            <label class="ms-3 col-md-2 col-form-label">組織・企業の名称</label>
            <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
            <div class="col-md-6 me-5"><input type="text" class="form-control" v-model="input組織名" required></div>
        </div>
        <div class="row mt-3 px-5">
            <div class="col-md-6 offset-md-3 me-5">
                <input type="button" class="btn btn-submit-button ms-3" value="上記内容で新規登録" @click="createSupportingMember" />
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>
            </div>
        </div>
        <div class="row my-5 px-5">
            <div class="col-md-6 offset-md-3 me-5">
                <input type="button" class="btn btn-red ms-3" value="一覧から訂正" @click="showSupportingMemberList" />
            </div>
        </div>

    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';

export default {
    setup() {
        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const input組織名 = ref('');

        const showGroupMemberList = () => {
            const url = '/s-admin/showGroupMemberList';

            location.href = url;
        }

        const createSupportingMember = () => {
            const url = '/api/s-admin/createSupportingMember';

            axios.post(url, {
                name: input組織名.value,
            }).then(res => {
                visibleFlashMessage('正常に登録されました', 'success');

                input組織名.value = '';
            });
        }

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const showSupportingMemberList = () => {
            const url = '/s-admin/showSupportingMemberList';

            location.href = url;
        }

        return {
            flashMessage,
            showFlashMessage,
            flashMessageStyle,

            input組織名,
            showGroupMemberList,
            createSupportingMember,

            visibleFlashMessage,

            showSupportingMemberList,
        }
    },
}
</script>

<style lang="scss" scoped>
    .btn-submit-button {
        border: 1px solid;
        color: white;
        border-color: #404040;
        background-color: #404040;
    }

    .btn-submit-button:hover {
        border: 1px solid;
        color: #404040;
        border-color: #404040;
        background-color: white;
    }

</style>