<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3" style="font-size:14pt">
            1.　基本方針｜目的・基本方針
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic btn-sm" style="font-size:12pt" value="基本｜1.基本方針 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
            :set_level="setLevel"
        />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2" style="font-size:12pt">
            目的　―――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
    </div>

    <form @submit.prevent="showNext">
        <div class="row ms-3 mt-3">
            <div class="row col-md-10">
                <textarea type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="textForms[0].value"></textarea>
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>「何のためにBCPを策定するのか？」、「BCPを策定・運用することにどのような意味合いがあるのか？」を検討し、基本方針を決めることからBCPの策定が始まります。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-3 mt-5">
            <div class="p2 col-md  d-flex align-items-center justify-content-start py-2" style="font-size:12pt">
                基本方針　―――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md  d-flex align-items-center justify-content-start py-2">
                該当する方針を選択してください。
            </div>
        </div>
        <div v-for="(form, index) in valueForms" :key="index">
            <div class="row ms-3 mt-3">
                <div class="p2 mt-3 col-md-4 d-flex align-items-center justify-content-start">
                    <input type="button" class="btn border btn-light text-dark btn-sm" :class="{ buttoncolor: form.isSelected }" v-model="form.value" :select="form.isSelected" @click="selectedPolicy(index)">
                </div>
                <div class="p2 ms-4 col-md-1 d-flex align-items-center justify-content-start" v-if="index == 0">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                                <label>基本方針とは、あなたの{{ callReplaceWord('組織') }}の{{ callReplaceWord('経営') }}方針の延長に位置するもので、BCPを策定するための目的となります。{{ callReplaceWord('従業員') }}の人命を守るため、供給責任を果たし{{ callReplaceWord('顧客') }}からの信用を守るため等、{{ callReplaceWord('経営者') }}の頭の中にはあることを書き出してみます。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 mt-3 col-md-4 d-flex align-items-center justify-content-start">
                <input type="button" class="btn border btn-light text-dark btn-sm" :class="{ buttoncolor: textForms[1].isSelected }"
                    value="その他&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;"
                    :select="textForms[1].isSelected" @click="selectedOther"
                >
            </div>
            <div class="p2 mt-3 col-md-4 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="textForms[1].value">
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-center justify-content-start">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>上記以外の基本方針が考えられる場合は、記入してください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-3 mt-5">
            <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
                <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜2.BCPの運用体制 へ進む" :disabled="isProcessing" @click="showNext">
            </div>
            <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
                <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
            </div>
        </div>
    </form>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(props.bcp_introduction_texts_info.length > 0){
                textForms.forEach(initData =>{
                    props.bcp_introduction_texts_info.forEach(fetchData => {
                        if(initData.columnName == fetchData.column_name){
                            initData.value = fetchData.text_value;
                            initData.isSelected = true;
                        }
                    });
                })
             }

             if(props.bcp_introduction_values_info.length > 0){
                valueForms.forEach(initData =>{
                    props.bcp_introduction_values_info.forEach(fetchData => {
                        if(initData.columnName == fetchData.column_name){
                            initData.isSelected = true;
                        }
                    });
                })
             }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const isSelected = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive([
            {
                columnName: 'N00004',
                value: '',
            },
            {
                columnName: 'N00010',
                value: '',
                isSelected: false,
            },
        ]);

        const valueForms = reactive([
            {
                columnName: 'N00005',
                value: '人命（' + callReplaceWord('従業員') + '・' + callReplaceWord('顧客') + '）の安全を守る　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00006',
                value: callReplaceWord('当社') + 'の' + callReplaceWord('経営') + 'を維持する　　　　　　　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00007',
                value: '供給責任を果たし、' + callReplaceWord('顧客') + 'からの信用を守る　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00008',
                value: callReplaceWord('従業員') + 'の' + callReplaceWord('雇用') + 'を守る　　　　　　　　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00009',
                value: '地域経済の活力を守る　　　　　　　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
        ]);

        const selectedPolicy = (index) => {
            valueForms[index].isSelected = !valueForms[index].isSelected ? true : false;
        }

        const selectedOther = () => {
            textForms[1].isSelected = !textForms[1].isSelected ? true : false;
        }

        const showBasic = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/1';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/3';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 目的・基本方針登録
        const registerIntroduction2 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerIntroduction2';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'text_form_list': textForms,
                'value_form_list': valueForms.filter(data => data.isSelected === true),
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            textForms,
            valueForms,
            isProcessing,
            isSelected,
            callReplaceWord,
            selectedPolicy,
            selectedOther,
            showBasic,
            showBack,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
.buttoncolor {
    background-color: #c6c7c8;
}
</style>
