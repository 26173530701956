<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            配信履歴
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5 ms-1">
        <table class="table table-bordered table-responsive">
            <thead>
                <tr>
                    <th>配信日</th>
                    <th>時間</th>
                    <th>拠点・支店</th>
                    <th>登録名称</th>
                    <th colspan="2">{{ callReplaceWord('従業員') }}</th>
                    <th colspan="2">家族</th>
                    <th>位置情報</th>
                    <th>{{ callReplaceWord('出社') }}可能時間</th>
                    <th>管理者</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="safety_confirmation in safety_confirmation_list" :key="safety_confirmation.id">
                    <td>{{ this.$root.dayjs(safety_confirmation.配信日時).format('YYYY.MM.DD') }}</td>
                    <td>{{ this.$root.dayjs(safety_confirmation.配信日時).format('HH:mm') }}</td>
                    <td>{{ safety_confirmation.拠点名 }}</td>
                    <td>{{ safety_confirmation.登録名称 }}</td>
                    <td>○</td>
                    <td><input type="button" class="text-center btn btn-sm btn-send" value="配信ﾘｽﾄ" @click="showSendHistoryModal(safety_confirmation.id)"></td>
                    <td>{{ safety_confirmation.家族への安否確認 == '1' ? '○' : '×' }}</td>
                    <td><input type="button" class="text-center btn btn-sm btn-send-family" value="配信ﾘｽﾄ" @click="showSendFamilyHistoryModal(safety_confirmation.id)" :disabled="safety_confirmation.家族への安否確認 != '1'"></td>
                    <td>{{ safety_confirmation.位置情報確認要否 == '1' ? '○' : '×' }}</td>
                    <td>{{ safety_confirmation.出社可能時間確認要否 == '1' ? '○' : '×' }}</td>
                    <td>{{ safety_confirmation.管理者名 }}</td>
                </tr>
            </tbody>
        </table>

    </div>

    <div class="row mt-3 pb-5 d-flex flex-row">
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-darkblue" value="安否確認HOMEへ戻る" @click="backHome">
            </div>
        </div>
    </div>

    <div id="send-history-modal" v-show="modalSendHistoryVisible" @click.self="closeSendHistoryModal">
        <div id="send-history-content">
            <div class="div-scrollable-table">
                <table id="send-history-table" class="table table-bordered">
                    <thead class="sticky-top">
                        <tr>
                            <th @click="sortSendHistoryByID" class="cursor-pointer">利用者ID {{ sendHistorySortFlgForID === "" ? "" : (sendHistorySortFlgForID === "1" ? "↑" : "↓") }}</th>
                            <th @click="sortSendHistoryByDepartment" class="cursor-pointer">部署 {{ sendHistorySortFlgForDepartment === "" ? "" : (sendHistorySortFlgForDepartment === "1" ? "↑" : "↓") }}</th>
                            <th @click="sortSendHistoryByName" class="cursor-pointer">{{ callReplaceWord('従業員') }}氏名 {{ sendHistorySortFlgForName === "" ? "" : (sendHistorySortFlgForName === "1" ? "↑" : "↓") }}</th>
                            <th @click="sortSendHistoryByDelivery" class="cursor-pointer">配信 {{ sendHistorySortFlgForDelivery === "" ? "" : (sendHistorySortFlgForDelivery === "1" ? "↑" : "↓") }}</th>
                            <th @click="sortSendHistoryByAnswer" class="cursor-pointer">回答日時 {{ sendHistorySortFlgForAnswer === "" ? "" : (sendHistorySortFlgForAnswer === "1" ? "↑" : "↓") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="send_history in send_history_list" :key="send_history.利用者ID">
                            <td class="background-member-id">{{ send_history.利用者ID }}</td>
                            <td>{{ send_history.部署 }}</td>
                            <td>{{ send_history.従業員氏名 }}</td>
                            <td><i class="bi bi-check" v-show="send_history.配信"></i></td>
                            <td>{{ send_history.回答日時 === '' ? '' : this.$root.dayjs(send_history.回答日時).format('YYYY/MM/DD HH:mm:ss') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div id="send-family-history-modal" v-show="modalSendFamilyHistoryVisible" @click.self="closeSendFamilyHistoryModal">
        <div id="send-family-history-content">
            <div class="div-scrollable-table">
                <table id="send-family-history-table" class="table table-bordered">
                    <thead class="sticky-top">
                        <tr>
                            <th @click="sortSendFamilyHistoryByID" class="cursor-pointer">利用者ID {{ sendFamilyHistorySortFlgForID === "" ? "" : (sendFamilyHistorySortFlgForID === "1" ? "↑" : "↓") }}</th>
                            <th @click="sortSendFamilyHistoryByDelivery" class="cursor-pointer">配信 {{ sendFamilyHistorySortFlgForDelivery === "" ? "" : (sendFamilyHistorySortFlgForDelivery === "1" ? "↑" : "↓") }}</th>
                            <th @click="sortSendFamilyHistoryByAnswer" class="cursor-pointer">回答日時 {{ sendFamilyHistorySortFlgForAnswer === "" ? "" : (sendFamilyHistorySortFlgForAnswer === "1" ? "↑" : "↓") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="send_family_history in send_family_history_list" :key="send_family_history.利用者ID">
                            <td class="background-member-id">{{ send_family_history.利用者ID }}</td>
                            <td><i class="bi bi-check" v-show="send_family_history.配信"></i></td>
                            <td>{{ send_family_history.回答日時 === '' ? '' : this.$root.dayjs(send_family_history.回答日時).format('YYYY/MM/DD HH:mm:ss') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
import { inject, onMounted, reactive, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject('dayjs');

        const setLevel = ref('111111');

        onMounted(() => {
        })

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backHome = () => {
            const url = '/safety/home';

            location.href = url;
        }

        const createNewSafety = () => {
            const url = '/safety/k1?back=k2';

            location.href = url;
        }

        const modalSendHistoryVisible = ref(false);

        const closeSendHistoryModal = () => {
            modalSendHistoryVisible.value = false;
        }

        const showSendHistoryModal = (id) => {
            const url = '/api/safety/getHistory?id=' + id;

            axios.get(url).then(res => {
                send_history_list.splice(0);

                res.data.forEach(ele => {
                    send_history_list.push(
                        {
                            利用者ID: ele.利用者ID,
                            部署: ele.部署,
                            従業員氏名: ele.ユーザ名,
                            回答日時: ele.回答日時 ? dayjs(ele.回答日時).format('YYYY/MM/DD HH:mm:ss'): "",
                            配信: ele.email.length === 0 ? false : true,
                        }
                    );
                })
                modalSendHistoryVisible.value = true;
            }).finally(fin => {
                sendHistorySortFlgForID.value = "1";
                sendHistorySortFlgForDepartment.value = "";
                sendHistorySortFlgForName.value = "";
                sendHistorySortFlgForDelivery.value = "";
                sendHistorySortFlgForAnswer.value = "";
            });
        }

        const modalSendFamilyHistoryVisible = ref(false);

        const closeSendFamilyHistoryModal = () => {
            modalSendFamilyHistoryVisible.value = false;
        }

        const showSendFamilyHistoryModal = (id) => {
            const url = '/api/safety/getFamilyHistory?id=' + id;

            axios.get(url).then(res => {
                send_family_history_list.splice(0);

                res.data.forEach(ele => {
                    send_family_history_list.push(
                        {
                            利用者ID: ele.家族ID,
                            配信: ele.email.length === 0 ? false : true,
                            回答日時: ele.回答日時 ? dayjs(ele.回答日時).format('YYYY/MM/DD HH:mm:ss'): "",
                        }
                    );
                })

                modalSendFamilyHistoryVisible.value = true;
            }).finally(fin=>{
                sendFamilyHistorySortFlgForID.value = "1";
                sendFamilyHistorySortFlgForDelivery.value = "";
                sendFamilyHistorySortFlgForAnswer.value = "";
            });
        }

        const send_history_list = reactive([]);

        const send_family_history_list = reactive([]);

        const sendHistorySortFlgForID = ref("");
        const conditionSendHistoryByID = (a,b) => {
            if (a.利用者ID < b.利用者ID) {
                return 1;
            } else if (a.利用者ID > b.利用者ID) {
                return -1;
            } else {
                return 0;
            }
        }
        const conditionSendHistoryByIDDesc = (a,b) => {
            if (a.利用者ID < b.利用者ID) {
                return -1;
            } else if (a.利用者ID > b.利用者ID) {
                return 1;
            } else {
                return 0;
            }
        }
        const sortSendHistoryByID = () => {
            sendHistorySortFlgForDepartment.value = "";
            sendHistorySortFlgForName.value = "";
            sendHistorySortFlgForDelivery.value = "";
            sendHistorySortFlgForAnswer.value = "";

            if(sendHistorySortFlgForID.value === "1"){
                send_history_list.sort(conditionSendHistoryByID);
                sendHistorySortFlgForID.value = "2";
            }else{
                send_history_list.sort(conditionSendHistoryByIDDesc);
                sendHistorySortFlgForID.value = "1";
            }
        }

        const sendHistorySortFlgForDepartment = ref("");
        const conditionSendHistoryByDepartment = (a, b) => {
            if (a.部署 < b.部署) {
                return 1;
            } else if (a.部署 > b.部署) {
                return -1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        };
        const conditionSendHistoryByDepartmentDesc = (a, b) => {
            if (a.部署 < b.部署) {
                return -1;
            } else if (a.部署 > b.部署) {
                return 1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        };
        const sortSendHistoryByDepartment = () => {
            sendHistorySortFlgForID.value = "";
            sendHistorySortFlgForName.value = "";
            sendHistorySortFlgForDelivery.value = "";
            sendHistorySortFlgForAnswer.value = "";
            
            if(sendHistorySortFlgForDepartment.value === "1"){
                send_history_list.sort(conditionSendHistoryByDepartment);
                sendHistorySortFlgForDepartment.value = "2";
            }else{
                send_history_list.sort(conditionSendHistoryByDepartmentDesc);
                sendHistorySortFlgForDepartment.value = "1";
            }
        }

        const sendHistorySortFlgForName = ref("");
        const conditionSendHistoryByName = (a,b) => {
            if (a.従業員氏名 < b.従業員氏名) {
                return 1;
            } else if (a.従業員氏名 > b.従業員氏名) {
                return -1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        }
        const conditionSendHistoryByNameDesc = (a,b) => {
            if (a.従業員氏名 < b.従業員氏名) {
                return -1;
            } else if (a.従業員氏名 > b.従業員氏名) {
                return 1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        }
        const sortSendHistoryByName = () => {
            sendHistorySortFlgForID.value = "";
            sendHistorySortFlgForDepartment.value = "";
            sendHistorySortFlgForDelivery.value = "";
            sendHistorySortFlgForAnswer.value = "";

            if(sendHistorySortFlgForName.value === "1"){
                send_history_list.sort(conditionSendHistoryByName);
                sendHistorySortFlgForName.value = "2";
            }else{
                send_history_list.sort(conditionSendHistoryByNameDesc);
                sendHistorySortFlgForName.value = "1";
            }
        }

        const sendHistorySortFlgForDelivery = ref("");
        const conditionSendHistoryByDelivery = (a, b) => {
            if (a.配信 < b.配信) {
                return 1;
            } else if (a.配信 > b.配信) {
                return -1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        };
        const conditionSendHistoryByDeliveryDesc = (a, b) => {
            if (a.配信 < b.配信) {
                return -1;
            } else if (a.配信 > b.配信) {
                return 1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        };
        const sortSendHistoryByDelivery = () => {
            sendHistorySortFlgForID.value = "";
            sendHistorySortFlgForDepartment.value = "";
            sendHistorySortFlgForName.value = "";
            
            if(sendHistorySortFlgForDelivery.value === "1"){
                send_history_list.sort(conditionSendHistoryByDelivery);
                sendHistorySortFlgForDelivery.value = "2";
            }else{
                send_history_list.sort(conditionSendHistoryByDeliveryDesc);
                sendHistorySortFlgForDelivery.value = "1";
            }
        }

        const sendHistorySortFlgForAnswer = ref("");
        const conditionSendHistoryByAnswer = (a, b) => {
            if (a.回答日時 < b.回答日時) {
                return 1;
            } else if (a.回答日時 > b.回答日時) {
                return -1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        };
        const conditionSendHistoryByAnswerDesc = (a, b) => {
            if (a.回答日時 < b.回答日時) {
                return -1;
            } else if (a.回答日時 > b.回答日時) {
                return 1;
            } else {
                return a.利用者ID - b.利用者ID;
            }
        };
        const sortSendHistoryByAnswer = () => {
            sendHistorySortFlgForID.value = "";
            sendHistorySortFlgForDepartment.value = "";
            sendHistorySortFlgForName.value = "";
            sendHistorySortFlgForDelivery.value = "";
            
            if(sendHistorySortFlgForAnswer.value === "1"){
                send_history_list.sort(conditionSendHistoryByAnswer);
                sendHistorySortFlgForAnswer.value = "2";
            }else{
                send_history_list.sort(conditionSendHistoryByAnswerDesc);
                sendHistorySortFlgForAnswer.value = "1";
            }
        }

        const sendFamilyHistorySortFlgForID = ref("");
        const sortSendFamilyHistoryByID = () => {
            sendFamilyHistorySortFlgForDelivery.value = "";
            sendFamilyHistorySortFlgForAnswer.value = "";
            
            if(sendFamilyHistorySortFlgForID.value === "1"){
                send_family_history_list.sort(conditionSendHistoryByID);
                sendFamilyHistorySortFlgForID.value = "2";
            }else{
                send_family_history_list.sort(conditionSendHistoryByIDDesc);
                sendFamilyHistorySortFlgForID.value = "1";
            }
        }

        const sendFamilyHistorySortFlgForDelivery = ref("");
        const sortSendFamilyHistoryByDelivery = () => {
            sendFamilyHistorySortFlgForID.value = "";
            sendFamilyHistorySortFlgForAnswer.value = "";
            
            if(sendFamilyHistorySortFlgForDelivery.value === "1"){
                send_family_history_list.sort(conditionSendHistoryByDelivery);
                sendFamilyHistorySortFlgForDelivery.value = "2";
            }else{
                send_family_history_list.sort(conditionSendHistoryByDeliveryDesc);
                sendFamilyHistorySortFlgForDelivery.value = "1";
            }
        }

        const sendFamilyHistorySortFlgForAnswer = ref("");
        const sortSendFamilyHistoryByAnswer = () => {
            sendFamilyHistorySortFlgForID.value = "";
            sendFamilyHistorySortFlgForDelivery.value = "";
            
            if(sendFamilyHistorySortFlgForAnswer.value === "1"){
                send_family_history_list.sort(conditionSendHistoryByAnswer);
                sendFamilyHistorySortFlgForAnswer.value = "2";
            }else{
                send_family_history_list.sort(conditionSendHistoryByAnswerDesc);
                sendFamilyHistorySortFlgForAnswer.value = "1";
            }
        }

        return {
            setLevel,
            callReplaceWord,
            backHome,
            createNewSafety,

            modalSendHistoryVisible,
            closeSendHistoryModal,
            showSendHistoryModal,

            modalSendFamilyHistoryVisible,
            closeSendFamilyHistoryModal,
            showSendFamilyHistoryModal,

            send_history_list,
            send_family_history_list,

            sendHistorySortFlgForID,
            sortSendHistoryByID,
            sendHistorySortFlgForDepartment,
            sortSendHistoryByDepartment,
            sendHistorySortFlgForName,
            sortSendHistoryByName,
            sendHistorySortFlgForDelivery,
            sortSendHistoryByDelivery,
            sendHistorySortFlgForAnswer,
            sortSendHistoryByAnswer,

            sendFamilyHistorySortFlgForID,
            sortSendFamilyHistoryByID,
            sendFamilyHistorySortFlgForDelivery,
            sortSendFamilyHistoryByDelivery,
            sendFamilyHistorySortFlgForAnswer,
            sortSendFamilyHistoryByAnswer,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        safety_confirmation_list: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
    .btn-darkblue {
        border: 1px solid;
        color: white;
        border-color: #2f75b5;
        background-color: #2f75b5;
    }

    .btn-darkblue:hover {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

    .btn-send {
        border: 1px solid;
        color: white;
        border-color: #33cccc;
        background-color: #33cccc;
    }

    .btn-send:hover {
        border: 1px solid;
        color: #33cccc;
        border-color: #33cccc;
        background-color: white;
    }

    .btn-send-family {
        border: 1px solid;
        color: white;
        border-color: #ff9933;
        background-color: #ff9933;
    }

    .btn-send-family:hover {
        border: 1px solid;
        color: #ff9933;
        border-color: #ff9933;
        background-color: white;
    }

    #send-history-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #send-history-content {
        z-index: 2;
        width: 50%;
        height: 80%;
        padding: 5em;
        background: #f2f2f2;
    }

    #send-family-history-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #send-family-history-content {
        z-index: 2;
        width: 50%;
        padding: 5em;
        background: #f2f2f2;
    }

    .div-scrollable-table {
        overflow: auto;
        height: calc(100vh/1.5);
    }

    #send-history-table,
    #send-family-history-table {
        overflow-y: scroll;
        border:1px solid;
        border-collapse: collapse;
    }

    #send-history-table thead th,
    #send-family-history-table thead th {
        position: sticky;
        top: 0;
        z-index: 10;
        background: #f2f2f2;
        border-top:black;
    }

    .background-member-id {
        background-color: #bdd7ee;
    }


</style>