<template>
    <div id="top">
    </div>
    <div v-show="mode==='問い合わせ'">
        <div class="heading-orange mt-3">
            お問い合わせ
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <form @submit.prevent="post_form">
            <div class="d-flex flex-column mt-5">
                <label class="form-label">TOTONO-Lへのお問い合わせは、下記メールフォームにより対応いたします。</label>
                <label class="form-label">必要事項をご記入の上、「メールを送信する」ボタンを押してください。</label>
            </div>
            
            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">お問い合わせ区分</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-for="contact_kind in contact_kind_list" :key="contact_kind.code">
                        <div class="row mt-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="inputお問合せ区分" :value="contact_kind.code" :id="contact_kind.code">
                                <label class="form-check-label" :for="contact_kind.code">
                                    {{ contact_kind.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">お問い合わせ内容</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <textarea class="form-control" v-model="inputお問合せ内容" rows="10" required />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">送信内容の確認</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="input送信内容の確認" id="inputCheck">
                                <label class="form-check-label" for="inputCheck">
                                    上記、送信内容を確認しました。
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <label>あなたの<span class="font-green fw-bold">{{ callReplaceWord('組織') }}の名称</span>、あなたの<span class="font-green fw-bold">部署・役職・氏名・メールアドレス</span>は、ログイン情報から自動取得し、上記メッセージとともにTOTONO-L事務局へ送信します。</label>
            </div>

            <div class="row mt-5 pb-5">
                <div>
                    <input type="submit" class="btn btn-bcp-restrictions" value="メールを送信する" :disabled="input送信内容の確認 === false || isProcessing">
                    <input type="button" class="btn btn-bcp-restrictions ms-5" value="リセット" @click="resetInput">
                </div>
            </div>

        </form>
    </div>
    <div v-show="mode==='送信完了'">
        <div class="heading-orange mt-3">
            送信完了
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="d-flex flex-column mt-5">
            <label class="form-label">TOTONO-Lへのメッセージをお預かりしました。</label>
            <label class="form-label">メッセージの控えは、あなたのメールアドレスに送信しましたのでご確認ください。</label>
        </div>

        <div class="d-flex flex-column mt-3">
            <label class="form-label">回答を準備いたしておりますので、いましばらくお待ちくださいますようお願いいたします。</label>
        </div>

        <div class="d-flex flex-column mt-3">
            <label class="form-label">TOTONO-L事務局</label>
        </div>

        <div class="row mt-5 pb-5">
            <div>
                <input type="button" class="btn btn-bcp-restrictions" value="マイページ へ" @click="returnMyPage">
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            mode.value = '問い合わせ';
        });
        
        const mode = ref('');

        const setLevel = ref('111111');

        const isProcessing = ref(false);

        const inputお問合せ区分 = ref(90010001);
        const inputお問合せ内容 = ref('');
        const input送信内容の確認 = ref(false);

        const post_form = () => {

            const url = '/api/createContact';

            isProcessing.value = true;

            axios.post(url, {
                'お問合せ区分': inputお問合せ区分.value,
                'お問合せ内容': inputお問合せ内容.value
            }).then(res => {
                mode.value = '送信完了';
                location.hash = "#top";
            }).catch(err => {
            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const resetInput = () => {
            inputお問合せ区分.value = 90010001;
            inputお問合せ内容.value = '';
            input送信内容の確認.value = false;
        }

        const returnMyPage = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        return {
            mode,
            setLevel,
            isProcessing,

            inputお問合せ区分,
            inputお問合せ内容,
            input送信内容の確認,

            post_form,
            resetInput,

            callReplaceWord,

            returnMyPage,

        }
    },
    props: {
        replacement_word_list: {
            type: Array,
        },
        contact_kind_list: {
            type: Array,
        },

    }
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';
    .font-green {
        color: common_colors.colors(resilience-green);
    }

</style>