<template>
    <div id="top">
    </div>
    <div v-show="mode==='問い合わせ'">
        <div class="heading-gray mt-3">
            お問い合わせ（ログイン前）
        </div>

        <form @submit.prevent="post_form">
        <!-- <form> -->
            <div class="d-flex flex-column mt-5">
                <!-- <label class="form-label">TOTONO-Lサービスをご利用中、または無償会員にてご利用中の管理者専用のお問い合わせフォームです。</label> -->
                <label class="form-label">TOTONO-Lサービスをご利用中、またはご利用をご検討中の方のお問い合わせは下記メールフォームにて承ります。</label>
                <label class="form-label">必要事項をご記入の上、「メールを送信する」ボタンを押してください。</label>
            </div>
            
            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">会員名（法人名）</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input法人名" required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">会員ID（会員番号）</label>
                        <span>※おわかりの場合</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input会員ID">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">部署</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input部署">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">役職</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input役職">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">氏名</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="input氏名" required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">メールアドレス</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="email" class="form-control" v-model="inputメールアドレス" required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">お問い合わせ内容</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <textarea class="form-control" v-model="inputお問合せ内容" rows="10" required />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2">
                    <div class="d-flex flex-column">
                        <label class="col-form-label">送信内容の確認</label>
                        <div>
                            <span class="badge bg-secondary">必須</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="input送信内容の確認" id="inputCheck">
                                <label class="form-check-label" for="inputCheck">
                                    上記、送信内容を確認しました。
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <!-- <label>あなたの<span class="color-resilience-red fw-bold">組織の名称</span>、あなたの<span class="color-resilience-red fw-bold">部署・役職・氏名・メールアドレス</span>は、ログイン情報から自動取得し、上記メッセージとともにTOTONO-L事務局へ送信します。</label> -->
            </div>

            <div class="row mt-5 pb-5">
                <div>
                    <input type="submit" 
                        class="btn btn-resilience-red" 
                        value="メールを送信する" 
                        :disabled="input送信内容の確認 === false || isProcessing"
                    >
                    <input type="button" class="btn btn-resilience-red ms-5" value="リセット" @click="resetInput">
                </div>
            </div>
            <div class="row flash col-6 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>

        </form>
    </div>
    <div v-show="mode==='送信完了'">
        <div class="heading-gray mt-3">
            送信完了
        </div>

        <div class="d-flex flex-column mt-5">
            <label class="form-label">TOTONO-Lへのメッセージをお預かりしました。</label>
            <label class="form-label">メッセージの控えは、あなたのメールアドレスに送信しましたのでご確認ください。</label>
        </div>

        <div class="d-flex flex-column mt-3">
            <label class="form-label">回答を準備いたしておりますので、いましばらくお待ちくださいますようお願いいたします。</label>
        </div>

        <div class="d-flex flex-column mt-3">
            <label class="form-label">TOTONO-L事務局</label>
        </div>

        <div class="row mt-5 pb-5">
            <div>
                <input type="button" class="btn btn-resilience-red px-5" value="戻る" @click="returnWelcome">
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import axios from 'axios';
import { useEnv } from '../../../function/useEnv';

export default {
    setup(props) {
        const { VITE_RE_CAPTCHA_SITE_KEY } = useEnv();
        const site_key = ref(VITE_RE_CAPTCHA_SITE_KEY);

        onMounted(() => {
            mode.value = '問い合わせ';

            setRecaptchaToken();
        });

        const setRecaptchaToken = () => {
            grecaptcha.ready(() => {
                grecaptcha.execute(site_key.value, {action: 'bl_contact'})
                    .then((response_token) => {
                        token.value = response_token
                    });
            });
        }
        
        const mode = ref('');

        const isProcessing = ref(false);

        const input法人名 = ref('');
        const input会員ID = ref('');
        const input部署 = ref('');
        const input役職 = ref('');
        const input氏名 = ref('');
        const inputメールアドレス = ref('');
        const inputお問合せ内容 = ref('');
        const input送信内容の確認 = ref(false);

        const token = ref('');

        const post_form = () => {
            const url = '/api/createBlContact';

            isProcessing.value = true;

            axios.post(url, {
                組織名: input法人名.value,
                会員ID: input会員ID.value,
                部署: input部署.value,
                役職: input役職.value,
                氏名: input氏名.value,
                メールアドレス: inputメールアドレス.value,
                お問合せ内容: inputお問合せ内容.value,
                'g-recaptcha-response': token.value,
            }).then(res => {
                mode.value = '送信完了';
                location.hash = "#top";
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors["g-recaptcha-response"]){
                        visibleFlashMessage(err.response.data.errors["g-recaptcha-response"][0], 'error');
                    }
                }
            }).finally(fin => {
                setRecaptchaToken();
                isProcessing.value = false;
            });

        }

        const resetInput = () => {
            input法人名.value = '';
            input会員ID.value = '';
            input部署.value = '';
            input役職.value = '';
            input氏名.value = '';
            inputメールアドレス.value = '';
            inputお問合せ内容.value = '';
            input送信内容の確認.value = false;
        }

        const returnWelcome = () => {
            const url = '/';

            location.href = url;
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        return {
            mode,
            isProcessing,

            site_key,

            setRecaptchaToken,

            input法人名,
            input会員ID,
            input部署,
            input役職,
            input氏名,
            inputメールアドレス,
            inputお問合せ内容,
            input送信内容の確認,

            post_form,
            resetInput,

            returnWelcome,

            token,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

        }
    },
}
</script>

