<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3" style="font-size:14pt">
            ハザードマップによる風水害・震度被害の想定
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-introduction px-3" value="被害想定へ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2" style="font-size:10.5pt">
        ハザードマップで確認できる災害には、洪水・内水・震度被害・津波・火山・土砂災害・高潮・地盤被害などがあります。<br>
        災害発生時に、{{callReplaceWord('工場')}}等{{callReplaceWord('組織')}}の関連施設がどのような被害を受ける可能性があるのか確認します。ハザードマップは、浸水時の深さ別や震度別といった具合に、想定される被害の大きさに応じ、地図が塗り分けられています。<br>
        {{callReplaceWord('工場')}}が位置する場所や周辺がどの程度の被害を受けるのかを確認し、{{callReplaceWord('工場')}}から避難場所までの経路を確認しましょう。
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-01" class="top_image mb-2" />
            <span class="text-center mb-2">洪水</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>大雨や融雪などを原因として、川の水量が異常に増加することによって堤防の浸食や決壊、橋の流出等が起こる災害を洪水災害といいます。（引用：気象庁HP　<a href="https://www.jma.go.jp/jma/kishou/know/ame_chuui/ame_chuui_p4.html" target="_blank">https://www.jma.go.jp/jma/kishou/know/ame_chuui/ame_chuui_p4.html</a>）ここでいう洪水は、大雨等により排水が追いつかず水があふれる内水氾濫（浸水害）も含みます。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-02" class="top_image mb-2" />
            <span class="text-center mb-2">土砂災害</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>土砂災害は、すさまじい破壊力をもつ土砂が、一瞬にして多くの人命や住宅などの財産を奪ってしまう恐ろしい災害です。山腹や川底の石や土砂が集中豪雨などによって一気に下流へと押し流される現象を土石流といいます。また、山の斜面や自然の急傾斜の崖、人工的な造成による斜面が突然崩れ落ちることを崖崩れといいます。（引用：気象庁HP　<a href="https://www.jma.go.jp/jma/kishou/know/ame_chuui/ame_chuui_p2.html" target="_blank">https://www.jma.go.jp/jma/kishou/know/ame_chuui/ame_chuui_p2.html</a>）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-03" class="top_image mb-2" />
            <span class="text-center mb-2">高潮</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>高潮は、台風や発達した低気圧などに伴い、気圧が下がり海面が吸い上げられる効果と強風により海水が海岸に吹き寄せられる効果のために、海面が異常に上昇する現象です。台風や発達した低気圧の接近、上陸に伴って短時間のうちに急激に潮位が上昇し、海水が海岸堤防等を超えると一気に浸水します。また高波が加わるとさらに浸水の危険が増します。（引用：気象庁HP　<a href="https://www.jma.go.jp/jma/kishou/know/ame_chuui/ame_chuui_p6.html" target="_blank">https://www.jma.go.jp/jma/kishou/know/ame_chuui/ame_chuui_p6.html</a>）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-04" class="top_image mb-2" />
            <span class="text-center mb-2">津波</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>津波は、海底で発生する地震に伴う海底地盤の隆起・沈降や海底における地滑りなどにより、その周辺の海水が上下に変動することによって引き起こされるものです。発生した海水面の動き(上下動)が特に大規模なものであれば、沿岸に達すると破壊力の大きな大津波となります。（引用：国土交通省HP <a href="https://www.mlit.go.jp/river/kaigan/main/kaigandukuri/tsunamibousai/01/index01.htm" target="_blank">https://www.mlit.go.jp/river/kaigan/main/kaigandukuri/tsunamibousai/01/index01.htm</a>）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-05" class="top_image mb-2" />
            <span class="text-center mb-2">道路防災</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>道路防災情報は、「アンダーパスなど、大雨の際に冠水し、車両が水没するなどの重大な事故が起きる可能性がある箇所」や「大雨などで土砂崩れや落石の恐れのある箇所について、規制の基準を定めて、災害が発生する前に通行止めなどの規制を実施する区間」を表示します。（引用：国土交通省「重ねるハザードマップ」解説凡例より）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-06" class="top_image mb-2" />
            <span class="text-center mb-2">地形分類</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>地形分類は、山地、台地・段丘、低地、水部、人工地形等について示したものです。地形分類によって自然災害リスクが異なります。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-07" class="top_image mb-2" />
            <span class="text-center mb-2">地震</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>地震は、地下の岩盤が周囲から押され又は引っ張られることによって、ある面を境として岩盤が急激にずれて地面が揺れる現象です。 揺れの強さを表す震度階級は、震度0～4、5弱、5強、6弱、6強、7の10階級（気象庁）となっています。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-08" class="top_image mb-2" />
            <span class="text-center mb-2">火山</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>「火山活動」とは、マグマや熱水や火山ガスなどの移動に伴って生じる噴火活動、地震活動、地殻変動、噴煙活動等の活動全般のこと。（引用：気象庁HP　<a href="https://www.data.jma.go.jp/svd/vois/data/tokyo/STOCK/kaisetsu/kazanyougo/katsudo.html" target="_blank">https://www.data.jma.go.jp/svd/vois/data/tokyo/STOCK/kaisetsu/kazanyougo/katsudo.html</a>）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-1 d-flex flex-column align-items-center">
            <i id="hazard-image-09" class="top_image mb-2" />
            <span class="text-center mb-2">液状化</span>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>液状化とは、地震が発生して地盤が強い衝撃を受けると、今まで互いに接して支えあっていた土の粒子がバラバラになり、<br />地盤全体がドロドロの液体のような状態になる現象のことをいいます。<br />液状化が発生すると、地盤から水が噴き出したり、また、それまで安定していた地盤が急に柔らかくなるため、<br />その上に立っていた建物が沈んだり（傾いたり）、地中に埋まっていたマンホールや埋設管が浮かんできたり、<br />地面全体が低い方へ流れ出すといった現象が発生します。<br />（引用：国土交通省HP）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <button class="p2 col-md-5 border bg-hazard d-flex align-items-center justify-content-start py-2 link-button-font-style" @click="showDisaportal">ハザードマップポータルサイト／国土地理院<br />（重ねるハザードマップ・わがまちハザードマップ）</button>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-01" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-02" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-03" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-04" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-05" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-06" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>ハザードマップポータルサイトは、洪水・土砂災害・高潮・津波のリスク情報、道路防災情報、土地の特徴・成り立ちなどを地図や写真に自由に重ねて表示できる「重ねるハザードマップ」、各市町村が作成した地域ごとのハザードマップを閲覧できる「わがまちハザードマップ」で構成されています。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <input type="button" class="p2 col-md-5 border bg-hazard d-flex align-items-center justify-content-start py-2 link-button-font-style"
            value="NHK 全国ハザードマップ" @click="showNhk"
        >
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-01" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-02" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>NHKの取材チームが、国・都道府県・市区町村から収集した災害リスクデータを、全国どこでも確認できる地図を期間限定で掲載。リスクを”知って”、もしもの時を”創造”し、的確に”逃げる”。あなたとあなたの大切な人の命を守る準備をするためのページです。2022年に公開されました。（NHKのwebサイトから引用）</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <input type="button" class="p2 col-md-5 border bg-hazard d-flex align-items-center justify-content-start py-2 link-button-font-style"
            value="J-SHIS 地震ハザードステーション／防災科研" @click="showJshis"
        >
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-07" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>J-SHISは、日本全国の「地震ハザードの共通情報基盤として活用できるサービスです。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <input type="button" class="p2 col-md-5 border bg-hazard d-flex align-items-center justify-content-start py-2 link-button-font-style"
            value="火山ハザードマップデータベース／防災科研" @click="showkazan"
        >
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <i id="hazard-image-08" class="top_image" />
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>火山ハザードマップデータベースは、1983年から現在に至るまでに日本で公表された活火山のハザードマップや防災マップを網羅的に収録したデータベースです。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <hr>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-5 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-secondary text-white btn-sm link-button-font-style" value="都道府県ハザードマップ おまとめページへ" @click="showNext">
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('011100');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/bcp/bcp_introduction/4';
            location.href = url;
        }

        const showNext = () => {
            const url = '/bcp/bcp_introduction/h0';
            location.href = url;
        }

        const showDisaportal = () => {
            const url = 'https://disaportal.gsi.go.jp/index.html';
            window.open(url);
        }

        const showNhk = () => {
            const url = 'https://www.nhk.or.jp/campaign/w-hazardmap/';
            window.open(url);
        }

        const showJshis = () => {
            const url = 'https://www.j-shis.bosai.go.jp/map/';
            window.open(url);
        }

        const showkazan = () => {
            const url = 'https://vivaweb2.bosai.go.jp/v-hazard/';
            window.open(url);
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,
            showNext,
            showDisaportal,
            showNhk,
            showJshis,
            showkazan,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
    .top_image {
        display: inline-flex;
        width: 83px;
        height: 83px;
        background-size: 83px auto;
        background-repeat: no-repeat;
    }

    #hazard-image-01{
        background-image: url('../../../../svg/07-62.svg');
    }

    #hazard-image-02{
        background-image: url('../../../../svg/07-63.svg');
    }

    #hazard-image-03{
        background-image: url('../../../../svg/07-64.svg');
    }

    #hazard-image-04{
        background-image: url('../../../../svg/07-65.svg');
    }

    #hazard-image-05{
        background-image: url('../../../../svg/07-66.svg');
    }

    #hazard-image-06{
        background-image: url('../../../../svg/07-67.svg');
    }

    #hazard-image-07{
        background-image: url('../../../../svg/07-61.svg');
    }

    #hazard-image-08{
        background-image: url('../../../../svg/07-68.svg');
    }

    #hazard-image-09{
        background-image: url('../../../../svg/07-69.svg');
    }

    .bg-hazard {
        background-color: #f6bcac;
    }

    .link-button-font-style {
        font-size:12pt; 
        text-align:left;
    }

</style>
