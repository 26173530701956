<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            4.　財務診断と事前対策計画｜損害保険の整理
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-middle btn-sm" value="中級｜4. 財務診断と事前対策計画｜保険情報リスト へ進む" v-show="is_completed_basic" :disabled="isProcessing" @click="showMiddle">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-5">
                                <label for="" class="form-label d-flex align-items-center">◯　資産の損害に対する損害保険の加入状況</label>
                            </div>
                            <div class="col-md-5">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>火災保険は、{{ callReplaceWord('自社') }}の建物や{{ callReplaceWord('組織') }}の{{ callReplaceWord('経営') }}に関するさまざまなリスクから守るための保険です。{{ callReplaceWord('事業') }}内容や所有する財産によって備えるリスクが異なります。保険金額には、「新価」と「時価」という2つの考え方があります。「新価」は、同等のものを新たに建築あるいは購入するのに必要な金額をいい、「時価」は、経過年数による価値の減少と使用による消耗分新価から差し引いた金額をいいます。あなたの{{ callReplaceWord('組織') }}の契約形態を確認し、契約している補償内容を◯△✕で整理します。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
<!--------------------------------------資産の損害に対する損害保険の加入状況テーブル---------------------------------------------------------------->
                        <table class="ms-1 mt-3 table table-bordered border-dark">
                            <tbody>
                                <tr>
                                    <td class="bg-light-gray" colspan="3" rowspan="2">&emsp;</td>
                                    <td class="bg-light-gray text-center" colspan="8">火災保険（資産の損害対策）</td>
                                </tr>
                                <tr class="bg-header">
                                    <td colspan="2">普通火災一般物件</td>
                                    <td colspan="2">普通火災工場物件</td>
                                    <td colspan="2">店舗総合保険</td>
                                    <td colspan="2">オールリスク型</td>
                                </tr>
                                <tr>
                                    <td class="bg-light-gray" colspan="3">加入の有無</td>
                                    <td class="bg-white" colspan="2" v-for="(form1, form1Index) of valueFormsInputACount(valueFormsField1, 0, 4)" :key="form1Index">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" :id="'customRadioInline1' + form1Index" :name="'customRadioInline1' + form1Index" class="custom-control-input" value="あり" v-model="form1.values[0].value">
                                            <label class="custom-control-label" :for="'customRadioInline1' + form1Index">&nbsp;あり</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" :id="'customRadioInline2' + form1Index" :name="'customRadioInline2' + form1Index" class="custom-control-input" value="なし" v-model="form1.values[0].value" checked>
                                            <label class="custom-control-label" :for="'customRadioInline2' + form1Index">&nbsp;なし</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" rowspan="4" class="bg-header">&emsp;</td>
                                    <td class="bg-light-gray" colspan="4">付保対象</td>
                                    <td class="bg-light-gray" colspan="4">保険金額（円）</td>
                                </tr>
                                <tr v-for="(form1, form1Index) of valueFormsInputACount(valueFormsField1, 4 ,7)" :key="form1Index">
                                    <td colspan="4" class="bg-header" v-if="form1Index === 0">建物</td>
                                    <td colspan="4" class="bg-header" v-else-if="form1Index === 1">什器備品・機械設備等</td>
                                    <td colspan="4" class="bg-header" v-else>商品・製品等</td>
                                    <td class="bg-white" colspan="4">
                                        <input type="Number" min="0" class="form-control text-end" placeholder="" v-model="form1.values[0].value">
                                    </td>
                                </tr>

                                <tr v-for="(form1, form1Index) of valueFormsInputBCount(valueFormsField1, 7)" :key="form1Index">
                                    <td class="text-center align-items-center bg-header" rowspan="4" v-if="form1Index === 0">自<br>然<br>災<br>害</td>
                                    <td class="text-center align-items-center bg-header" rowspan="7" v-if="form1Index === 4">人<br>災<br>等</td>
                                    <td class="text-center align-items-center bg-header" v-if="form1Index === 11">そ<br>の<br>他<br>1</td>
                                    <td class="text-center align-items-center bg-header" v-if="form1Index === 12">そ<br>の<br>他<br>2</td>
                                    <td class="text-center align-items-center bg-header" rowspan="5" v-if="form1Index === 13">災<br>害<br>の<br>際<br>の<br>出<br>費</td>

                                    <td colspan="2" class="bg-header">{{ rowTitle1[form1Index] }}</td>

                                    <template v-for="(val, valIndex) of form1.values" :key="valIndex">
                                        <td class="bg-white" v-if="valIndex % 2 === 0">
                                            <div class="form-group d-flex flex-row">
                                                <select id="" class="form-control width-auto" v-model="val.value">
                                                    <option value="◯">◯</option>
                                                    <option value="△">△</option>
                                                    <option value="✕">✕</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td class="bg-white" v-else>
                                            <input type="text" class="form-control" placeholder="(備考)" v-model="val.value">
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>

                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="col-md-5">
                                <label for="" class="form-label d-flex align-items-center">◯　事業中断の損害に対する保険の加入状況</label>
                            </div>
                            <div class="col-md-5">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>「利益保険」（利益リスク）は、復旧するまでの{{ callReplaceWord('生産') }}減少や、休業の営業利益減、休業中に支払う給与・地代・租税公課等の『固定費』支出をカバーする保険です。「営業継続費用保険」（営業継続リスク）は、休業・{{ callReplaceWord('生産') }}の中断を」回避するための仮{{ callReplaceWord('工場') }}の賃借費用、早期復旧のための突貫工事等『追加費用』をカバーする保険です。「オールリスク型」は、利益保険と営業継続費用保険を組み合わせ、担保範囲もオールリスクに拡大した保険です。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
<!--------------------------------------事業中断の損害に対する保険の加入状況テーブル---------------------------------------------------------------->
                        <table class="ms-1 mt-3 table table-bordered border-dark">
                            <tbody>
                                <tr>
                                    <td class="bg-light-gray" colspan="3" rowspan="2">&emsp;</td>
                                    <td class="bg-light-gray text-center" colspan="6">利益保険（事業中断の損害対策）</td>
                                </tr>
                                <tr class="bg-header">
                                    <td colspan="2">利益保険</td>
                                    <td colspan="2">営業継続費用保険</td>
                                    <td colspan="2">オールリスク型</td>
                                </tr>
                                <tr>
                                    <td class="bg-light-gray" colspan="3">加入の有無</td>
                                    <td class="bg-white" colspan="2" v-for="(form2, form2Index) of valueFormsInputACount(valueFormsField2, 0, 3)" :key="form2Index">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" :id="'customRadioInline3' + form2Index" :name="'customRadioInline3' + form2Index" class="custom-control-input" value="あり" v-model="form2.values[0].value">
                                            <label class="custom-control-label" :for="'customRadioInline3' + form2Index">&nbsp;あり</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" :id="'customRadioInline4' + form2Index" :name="'customRadioInline4' + form2Index" class="custom-control-input" value="なし" v-model="form2.values[0].value" checked>
                                            <label class="custom-control-label" :for="'customRadioInline4' + form2Index">&nbsp;なし</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="(form2, form2Index) of valueFormsInputACount(valueFormsField2, 3, 7)" :key="form2Index">
                                    <td colspan="3" class="bg-light-gray" v-if="form2Index === 0">約定てん補期間方式<br>保険金額（円）</td>
                                    <td colspan="3" class="bg-light-gray" v-if="form2Index === 1">約定付保割合方式<br>保険金額（円）</td>
                                    <td rowspan="2" class="bg-light-gray" v-if="form2Index === 2">営業継続<br>費用条項</td>
                                    <td colspan="2" class="bg-light-gray" v-if="form2Index === 2">復旧するまでの予想時間（ヶ月）</td>
                                    <td colspan="2" class="bg-light-gray" v-if="form2Index === 3">保険金額（円）</td>
                                    <template v-for="(val, valIndex) of form2.values" :key="valIndex">
                                        <td colspan="2" class="bg-white" v-if="form2Index >= 2 && valIndex === 0">
                                            <input type="text" class="form-control" placeholder="" disabled>
                                        </td>
                                        <td colspan="2" class="bg-white">
                                            <input type="Number" min="0" class="form-control text-end" placeholder="" v-model="val.value">
                                        </td>
                                        <td colspan="2" class="bg-white" v-if="form2Index <= 1 && valIndex === 0">
                                            <input type="text" class="form-control" placeholder="" disabled>
                                        </td>
                                    </template>
                                </tr>

                                <tr v-for="(form2, form2Index) of valueFormsInputBCount(valueFormsField2, 7)" :key="form2Index">
                                    <td class="text-center align-items-center bg-header" rowspan="4" v-if="form2Index === 0">自<br>然<br>災<br>害</td>
                                    <td class="text-center align-items-center bg-header" rowspan="7" v-if="form2Index === 4">人<br>災<br>等</td>
                                    <td class="text-center align-items-center bg-header" v-if="form2Index === 11">そ<br>の<br>他<br>1</td>
                                    <td class="text-center align-items-center bg-header" rowspan="3" v-if="form2Index === 12">そ<br>の<br>他<br>２</td>

                                    <td colspan="2" class="bg-header" >{{ rowTitle2[form2Index] }}</td>

                                    <template v-for="(val, valIndex) of form2.values" :key="valIndex">
                                        <td class="bg-white" v-if="valIndex % 2 === 0">
                                            <div class="form-group d-flex flex-row">
                                                <select id="" class="form-control width-auto" v-model="val.value">
                                                    <option value="◯">◯</option>
                                                    <option value="△">△</option>
                                                    <option value="✕">✕</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td class="bg-white" v-else>
                                            <input type="text" class="form-control" placeholder="(備考)" v-model="val.value">
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-12 align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜4.　財務診断と事前対策計画｜事前対策のための投資計画  へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_values_info.length > 0) {
                props.bcp_values_info.forEach(fetchData =>{
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        viewLimit.value = fetchData.value == 1 ? true : false;
                    }
                    // フィールド１
                    valueFormsField1.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                    // フィールド２
                    valueFormsField2.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('010000');
        const isProcessing = ref(false);
        const pageName = 'F7';
        const setDefaultFieldNumber = 1;
        const viewLimit = ref(0);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueFormsField1 = reactive([]);
        for (let i = 104; i <= 110; i++) {
            valueFormsField1.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K' + ('00000' + i).slice(-5),
                values:[
                    {value: ''},
                ]
            });
        }
        for (let i = 111; i <= 128; i++) {
            valueFormsField1.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K' + ('00000' + i).slice(-5),
                values:[
                    {value: '✕'},
                    {value: ''},
                    {value: '✕'},
                    {value: ''},
                    {value: '✕'},
                    {value: ''},
                    {value: '✕'},
                    {value: ''},
                ]
            });
        }

        const valueFormsField2 = reactive([]);
        for (let i = 129; i <= 131; i++) {
            valueFormsField2.push({
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K' + ('00000' + i).slice(-5),
                values:[
                    {value: ''},
                ]
            });
        }
        for (let i = 132; i <= 135; i++) {
            valueFormsField2.push({
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K' + ('00000' + i).slice(-5),
                values:[
                    {value: ''},
                    {value: ''},
                ]
            });
        }
        for (let i = 136; i <= 150; i++) {
            valueFormsField2.push({
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K' + ('00000' + i).slice(-5),
                values:[
                    {value: '✕'},
                    {value: ''},
                    {value: '✕'},
                    {value: ''},
                    {value: '✕'},
                    {value: ''},
                ]
            });
        }

        const rowTitle1 = [
            '①落雷',
            '②風・雹・雪災',
            '③水害',
            '④地震',
            '⑤火災',
            '⑥破裂・爆裂',
            '⑦飛来・落下・衝突',
            '⑧水濡れ',
            '⑨破壊',
            '⑩盗難',
            '⑪破損',
            'テロ',
            '電気的機械的事故 ',
            '臨時費用',
            '残存物片付費用',
            '失火見舞費用',
            '地震火災費用',
            '修理付帯費用',
        ]

        const rowTitle2 = [
            '①落雷',
            '②風・雹・雪災',
            '③水害',
            '④地震',
            '⑤火災',
            '⑥破裂・爆裂',
            '⑦飛来・落下・衝突',
            '⑧水濡れ',
            '⑨破壊',
            '⑩盗難',
            '⑪破損',
            '集団感染等による操業停止',
            '構外ユーティリティ設備の供給の停止',
            '隣接物に生じた①～③、⑤～⑪の事故',
            '電気的・機械的事故',
        ]

        const valueFormsInputACount = (valueFormsField, start, end) => {
            return valueFormsField.slice(start, end);
        }

        const valueFormsInputBCount = (valueFormsField, start) => {
            return valueFormsField.slice(start);
        }

        const showBack = async () => {
            // 保険の加入状況を登録
            const result = await registerFirst7();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 保険の加入状況を登録
            const result = await registerFirst7();

            if (result == '200') {
                const url = '/bcp/bcp_first/8';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 保険の加入状況を登録
            const result = await registerFirst7();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showMiddle = async () => {
            // 保険の加入状況を登録
            const result = await registerFirst7();

            if (result == '200') {
                const url = '/bcp/bcp_financial/18';
                location.href = url;
            }
        }

        // 保険の加入状況登録
        const registerFirst7 = async () => {
            const reqValueForms = [];

            // 閲覧制限
            reqValueForms.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'view_limit',
                seq: 1,
                value: viewLimit.value,
            });

            // フィールド１
            valueFormsField1.forEach(data => {
                data.values.forEach((val, index) => {
                    let selValue = val.value;
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index + 1,
                            value: selValue,
                        });
                    }
                });
            });

            // フィールド２
            valueFormsField2.forEach(data => {
                data.values.forEach((val, index) => {
                    let selValue = val.value;
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index + 1,
                            value: selValue,
                        });
                    }
                });
            });

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst7';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            viewLimit,
            valueFormsField1,
            valueFormsField2,
            rowTitle1,
            rowTitle2,
            valueFormsInputACount,
            valueFormsInputBCount,
            showBack,
            showNext,
            showContents,
            showMiddle,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
        is_completed_basic: {
            type: Boolean,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
}

.bg-gray {
    background-color: #a6a6a6;
}

.border-orange{
    border-style: solid;
    border-width: 1px;
    border-color: #FF6600;
}

.bg-header {
    background-color: #F2F2F2;

}

.width-auto {
    width: auto;
}
</style>
