<template>
    <div id="top">
    </div>
    <div class="container">
        <div class="heading mt-3">クレジット払い切替</div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">年会費・従量課金</div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light" id="amountTable">
                    <tbody>
                        <tr>
                            <th>{{ 年度 }}年度</th>
                            <td colspan="3">会員種別｜従量課金の内容</td>
                            <td class="text-center">ご請求金額（税込）</td>
                        </tr>
                        <tr>
                            <td>年会費</td>
                            <td colspan="3">
                                <div class="row">
                                    <div class="col-2">
                                        {{ input会員種別 }}
                                    </div>
                                    <div class="col-10">
                                        <label>※期の途中でご加入された場合は、月割りの年会費が適用されます。</label>
                                    </div>
                                </div>
                            </td>
                            <td class="text-end">{{ number_format(年会費) }}円</td>
                        </tr>
                        <tr>
                            <td rowspan="3">従量課金</td>
                            <td>登録する利用者（役員・従業員）の人数</td>
                            <td class="text-end">{{ number_format(input従業員数) }}</td>
                            <td class="text-end">人</td>
                            <td class="text-end">{{ number_format(従量_従業員数) }}円</td>
                        </tr>
                        <tr>
                            <td>登録する拠点・支店の数</td>
                            <td class="text-end">{{ number_format(input支店数) }}</td>
                            <td class="text-end">ヶ所</td>
                            <td class="text-end">{{ number_format(従量_支店数) }}円</td>
                        </tr>
                        <tr>
                            <td>登録する利用者家族の人数</td>
                            <td class="text-end">{{ number_format(input家族数) }}</td>
                            <td class="text-end">人</td>
                            <td class="text-end">{{ number_format(従量_家族数) }}円</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-3">
                <input type="button" class="btn btn-black" value="料金プラン" @click="showPrice" />
            </div>
        </div>
        <div class="row pb-5">
            <div class="col-md-3 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td class="text-center">合計</td>
                            <td class="text-end">{{ number_format(合計額) }}円（税込）</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row my-3" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払方法の選択</div>
        </div>
        <div class="row" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td>
                                <input type="radio" class="form-check-input" v-model="支払方法" value="10050001" id="クレジット決済" />
                            </td>
                            <td>
                                <label class="form-check-label" for="クレジット決済">クレジット決済</label>
                            </td>
                            <td>
                                <label class="form-check-label" for="クレジット決済">※ クレジット決済専用ページに移動します。</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-3 d-flex align-items-end">
            </div>
        </div>
        <div class="row my-3" v-show="input会員種別 != '団体所属会員'">
            <div class="col-md-8 offset-md-1">
                <div class="d-flex justify-content-center" v-if="支払方法=='10050001'">
                    <form id="form_credit" :action="densan_url" method="post" @submit.prevent="getOrderID()" ref="submitCredit" accept-charset='shift_jis'>
                        <input type="hidden" name="pay_method" :value="sb_pay_method" />
                        <input type="hidden" name="merchant_id" :value="sb_merchant_id" />
                        <input type="hidden" name="service_id" :value="sb_service_id">
                        <input type="hidden" name="cust_code" :value="sb_cust_code">
                        <input type="hidden" name="order_id" :value="sb_order_id">
                        <input type="hidden" name="item_id" :value="sb_item_id">
                        <input type="hidden" name="item_name" :value="sb_item_name">
                        <input type="hidden" name="amount" :value="sb_amount">
                        <input type="hidden" name="pay_type" :value="sb_pay_type">
                        <input type="hidden" name="service_type" :value="sb_service_type">
                        <input type="hidden" name="terminal_type" :value="sb_terminal_type">
                        <!--商品明細行繰り返し start -->
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_1">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_1">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_1">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_1">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_1">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_2">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_2">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_2">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_2">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_2">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_3">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_3">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_3">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_3">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_3">
                        <input type="hidden" name="dtl_rowno" :value="sb_dtl_rowno_4">
                        <input type="hidden" name="dtl_item_id" :value="sb_dtl_item_id_4">
                        <input type="hidden" name="dtl_item_name" :value="sb_dtl_item_name_4">
                        <input type="hidden" name="dtl_item_count" :value="sb_dtl_item_count_4">
                        <input type="hidden" name="dtl_amount" :value="sb_dtl_amount_4">
                        <!--商品明細行繰り返し end -->

                        <input type="hidden" name="success_url" :value="sb_success_url">
                        <input type="hidden" name="cancel_url" :value="sb_cancel_url">
                        <input type="hidden" name="error_url" :value="sb_error_url">
                        <input type="hidden" name="pagecon_url" :value="sb_pagecon_url">
                        <!-- <input type="hidden" name="free_csv" value="TEFTVF9OQU1FPemItOacqCxGSVJTVF9OQU1FPeWkqumDjixMQVNUX05BTUVfS0FOQT3jgrnjgrrjgq0sRklSU1RfTkFNRV9LQU5BPeOCv+ODreOCpixGSVJTVF9aSVA9MjEwLFNFQ09ORF9aSVA9MDAwMSxBREQxPeWykOmYnOecjCxBREQyPeOBguOBguOBguW4guOBguOBguOBgueUuixBREQzPSxURUw9MTIzNDU2Nzk4MDEsTUFJTD1hYWFhQGJiLmpwLElURU1fTkFNRT1URVNUIElURU0="> -->
                        <input type="hidden" name="request_date" :value="sb_request_date">
                        <input type="hidden" name="hashkey" :value="sb_hashkey">
                        <input type="hidden" name="sps_hashcode" :value="sb_sps_hashcode">
                        <!-- <input type="hidden" name="dtl_rowno" value="1"> -->
                        <!-- <input type="hidden" name="dtl_item_id" value="dtlItem_1"> -->
                        <!-- <input type="hidden" name="dtl_item_name" value="商品詳細１"> -->
                        <!-- <input type="hidden" name="dtl_item_count" value="1"> -->
                        <!-- <input type="hidden" name="dtl_tax" value="1"> -->
                        <!-- <input type="hidden" name="dtl_amount" value="1"> -->
                        <input type="submit" class="btn btn-black px-5" value="クレジット払い" :disabled="isProcessing" >
                    </form>
                    <!-- <button type="button" class="btn btn-black py-3 px-5"><i class="bi bi-piggy-bank-fill"></i>上記金額を支払う</button> -->
                </div>
            </div>
        </div>
        <div class="row pb-5">
            <div class="col-md-1 offset-md-1">
                <i id="ssl_icon" />
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="">
                    <label class="form-label font-s-075">当サイトはSSL暗号化通信に対応しております。お申込内容やクレジットカード番号など、お客様の大切な情報は暗号化して送信され第三者から解読できないようになっております。</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, ref } from 'vue';
import CommonCheck from '../../function/CommonCheck';
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';
import CommonCrypt from '../../function/CommonCrypto';
import { useEnv } from '../../function/useEnv';
import axios from 'axios';

const { isZenKatakana, isTelNumber } = CommonCheck();
const { SbHash } = CommonCrypt();

export default {
    setup(props) {
        const { VITE_SB_DENSAN_URL, VITE_SB_MERCHANT_ID, VITE_SB_SERVICE_ID, VITE_SB_HASH_KEY, VITE_SB_GET_TOKEN_URL, VITE_SHOW_CONVENIENT_PAY_FLG } = useEnv();

        const dayjs = inject('dayjs');

        onMounted(() => {
            年度.value = props.first_bill_info.年度;
            input会員種別.value = props.corp_info.会員種別名;
            年会費.value = props.first_bill_info.請求年会費;
            input従業員数.value = props.first_bill_info.請求従業員数;
            従量_従業員数.value = props.first_bill_info.請求従業員従量課金;
            input支店数.value = props.first_bill_info.請求支店数;
            従量_支店数.value = props.first_bill_info.請求支店従量課金;
            input家族数.value = props.first_bill_info.請求家族数;
            従量_家族数.value = props.first_bill_info.請求家族従量課金;
            input代表者名_姓.value = props.corp_info.代表者名_姓;
            input代表者名_名.value = props.corp_info.代表者名_名;
            input代表者名_姓_カナ.value = props.corp_info.代表者名_カナ_姓;
            input代表者名_名_カナ.value = props.corp_info.代表者名_カナ_名;
            input代表者_役職.value = props.corp_info.代表者_役職;
            input管理者名_姓_カナ.value = props.corp_info.管理者名_カナ_姓;
            input管理者名_名_カナ.value = props.corp_info.管理者名_カナ_名;
            if(props.corp_info.電話番号){
                const array電話番号 = props.corp_info.電話番号.split("-");
                input電話番号1.value = array電話番号[0];
                input電話番号2.value = array電話番号[1];
                input電話番号3.value = array電話番号[2];
            }
            if(props.corp_info.管理者_電話番号){
                const array管理者電話番号 = props.corp_info.管理者_電話番号.split("-");
                input管理者_電話番号1.value = array管理者電話番号[0];
                input管理者_電話番号2.value = array管理者電話番号[1];
                input管理者_電話番号3.value = array管理者電話番号[2];
            }
            業種_細分類.value = props.corp_info.業種;
        });

        const showConvenientPayFlg = ref(VITE_SHOW_CONVENIENT_PAY_FLG);

        const showMode = ref(0);
        const isProcessing = ref(false);

        const input電話番号1 = ref('');
        const input電話番号2 = ref('');
        const input電話番号3 = ref('');

        const input管理者_電話番号1 = ref('');
        const input管理者_電話番号2 = ref('');
        const input管理者_電話番号3 = ref('');

        const input代表者名_姓 = ref('');
        const input代表者名_名 = ref('');
        const input代表者名_姓_カナ = ref('');
        const input代表者名_名_カナ = ref('');
        const input代表者_役職 = ref('');

        const input管理者名_姓_カナ = ref('');
        const input管理者名_名_カナ = ref('');

        const input支店数 = ref(1);
        const input従業員数 = ref(1);
        const input家族数 = ref(0);

        const inputメルマガ登録 = ref('あり');

        const input所属する団体会員コード = ref('');
        const input賛助会員コード = ref('');
        const input会員種別 = ref('');

        const showPrice = () => {
            const url = '/price'

            let newWindow = window.open(url, '_blank');
        }

        const 年度 = ref(0);
        const 年会費 = ref(0);
        const 従量_支店数 = ref(0);
        const 従量_従業員数 = ref(0);
        const 従量_家族数 = ref(0);
        const number_format = (amount) => {
            return CommonNumberFormat(amount);
        }
        const 合計額 = computed(() => {
            return 年会費.value + 従量_支店数.value + 従量_従業員数.value + 従量_家族数.value;
        });

        // const registerPaidCorp = () => {
        //     const url = '/api/register/corpPaid';

        //     isProcessing.value = true;

        //     if(input会員種別.value === '団体所属会員'){
        //         支払方法.value = 10051001;
        //     }

        //     if(input会員種別.value === '賛助会員'){
        //         支払方法.value = 10051002;
        //     }

        //     axios.post(url, {
        //         会員種別: get会員種別コード(input会員種別.value),
        //         事業形態: props.corp_info.事業形態,
        //         法人番号: props.corp_info.法人番号,
        //         組織名: props.corp_info.組織名,
        //         郵便番号: props.corp_info.郵便番号,
        //         都道府県: props.corp_info.都道府県,
        //         市区町村: props.corp_info.市区町村,
        //         町名番地: props.corp_info.町名番地,
        //         電話番号: input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
        //         代表者名_姓: input代表者名_姓.value,
        //         代表者名_名: input代表者名_名.value,
        //         代表者名_カナ_姓: input代表者名_姓_カナ.value,
        //         代表者名_カナ_名: input代表者名_名_カナ.value,
        //         代表者_役職: input代表者_役職.value,
        //         管理者名_姓: props.corp_info.管理者名_姓,
        //         管理者名_名: props.corp_info.管理者名_名,
        //         管理者名_カナ_姓: input管理者名_姓_カナ.value,
        //         管理者名_カナ_名: input管理者名_名_カナ.value,
        //         管理者_所属部署: props.corp_info.管理者_所属部署,
        //         管理者_役職: props.corp_info.管理者_役職,
        //         管理者_電話番号: input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
        //         email: props.corp_info.管理者_メールアドレス,
        //         メルマガ登録: inputメルマガ登録.value === 'あり' ? 1 : 0,
        //         業種: 業種_細分類.value,
        //         会員資格更新区分: 10060001,
        //         請求方法区分: 支払方法.value,

        //         利用者ID: 利用者ID.value,

        //         仮登録ID: props.corp_info.id,

        //         支店数: input支店数.value,
        //         従量_支店: 従量_支店数.value,
        //         従業員数: input従業員数.value,
        //         従量_従業員: 従量_従業員数.value,
        //         家族数: input家族数.value,
        //         従量_家族: 従量_家族数.value,

        //         年会費: 年会費.value,

        //         所属する団体会員コード: input所属する団体会員コード.value,
        //         賛助会員コード: input賛助会員コード.value,

        //         contractAgree: isAgree.value,

        //         合計額: 合計額.value,

        //         uuid: props.corp_info.uuid,
        //     }).then(res => {
        //         企業ID.value = res.data.corp.企業ID;
        //         corp_info_create.value = res.data.corp.created_at;
        //         service_end_day.value = res.data.corp.有効期限日;
        //         local_code.value = res.data.local_code;
        //         bill_id.value = res.data.bill_info.id;
        //         corp_id.value = res.data.corp.id;
        //         attach_file.value = res.data.attach_file;

        //         showMode.value = 6;
        //         location.hash = "#top";
        //     }).finally(fin => {
        //         isProcessing.value = false;
        //     });
        // }

        const get会員種別コード = (会員種別名) => {
            switch(会員種別名){
                case '企業会員':
                    return 10010002;

                case '団体会員':
                    return 10010003;

                case '公共会員':
                    return 10010004;

                case '団体所属会員':
                    return 10010005;
                
                case '賛助会員':
                    return 10010006;
                
                default:
                    return '';
            }
        }

        const 支払方法 = ref('');

        const submitCredit = ref();
        const submitConvenience = ref();

        const sb_pay_method = ref('');
        const sb_merchant_id = ref(VITE_SB_MERCHANT_ID);
        const sb_service_id = ref(VITE_SB_SERVICE_ID);
        const sb_cust_code = ref('');
        const sb_order_id = ref('');
        const sb_item_id = ref('');
        const sb_item_name = ref('');
        const sb_amount = ref('');
        const sb_pay_type = ref('');
        const sb_service_type = ref('');
        const sb_terminal_type = ref('');

        const sb_dtl_rowno_1 = ref('');
        const sb_dtl_item_id_1 = ref('');
        const sb_dtl_item_name_1 = ref('');
        const sb_dtl_item_count_1 = ref('');
        const sb_dtl_amount_1 = ref('');

        const sb_dtl_rowno_2 = ref('');
        const sb_dtl_item_id_2 = ref('');
        const sb_dtl_item_name_2 = ref('');
        const sb_dtl_item_count_2 = ref('');
        const sb_dtl_amount_2 = ref('');

        const sb_dtl_rowno_3 = ref('');
        const sb_dtl_item_id_3 = ref('');
        const sb_dtl_item_name_3 = ref('');
        const sb_dtl_item_count_3 = ref('');
        const sb_dtl_amount_3 = ref('');

        const sb_dtl_rowno_4 = ref('');
        const sb_dtl_item_id_4 = ref('');
        const sb_dtl_item_name_4 = ref('');
        const sb_dtl_item_count_4 = ref('');
        const sb_dtl_amount_4 = ref('');

        const sb_success_url = ref('');
        const sb_cancel_url = ref('');
        const sb_error_url = ref('');
        const sb_pagecon_url = ref('');
        const sb_request_date = ref('');
        const sb_hashkey = ref(VITE_SB_HASH_KEY);
        const sb_sps_hashcode = ref('');

        const getOrderID = () => {
            const url = '/api/getOrderSequence';

            axios.get(url).then(res => {
                payCredit(res.data);
            });
        }

        const payCredit = async (orderID) => {
            //クレジット払い
            const time_string = dayjs().format('YYYYMMDDHHmmss')

            const baseUrl = window.location.origin;

            const creditParams = {
                'pay_method': 'credit3d2',
                'merchant_id': sb_merchant_id.value,
                'service_id': sb_service_id.value,
                'cust_code': props.corp_info.uuid,
                'order_id': orderID,
                'item_id': 98000001,
                'item_name': 'TOTONO-L年会費',
                'amount': 合計額.value,
                'pay_type': 0,
                'service_type': 0,
                'terminal_type': 0,

                'success_url': baseUrl + '/switch_pay_method_before_deposit_success',
                'cancel_url': baseUrl + '/switch_pay_method_before_deposit_cancel',
                'error_url': baseUrl + '/switch_pay_method_before_deposit_error',
                // 'pagecon_url': baseUrl + '/api/hon_pagecon',
                'pagecon_url': 'https://totono-l.org/api/hon_pagecon',

                'dtl_rowno_1': 1,
                'dtl_item_id_1': 年度.value,
                'dtl_item_name_1': '年会費',
                'dtl_item_count_1': 1,
                'dtl_amount_1': 年会費.value,

                'dtl_rowno_2': 2,
                'dtl_item_id_2': 10030001,
                'dtl_item_name_2': '従業員',
                'dtl_item_count_2': input従業員数.value,
                'dtl_amount_2': 従量_従業員数.value,

                'dtl_rowno_3': 3,
                'dtl_item_id_3': 10030002,
                'dtl_item_name_3': '支店',
                'dtl_item_count_3': input支店数.value,
                'dtl_amount_3': 従量_支店数.value,

                'dtl_rowno_4': 4,
                'dtl_item_id_4': 10030003,
                'dtl_item_name_4': '家族数',
                'dtl_item_count_4': input家族数.value,
                'dtl_amount_4': 従量_家族数.value,

                'request_date': time_string,

            }

            const url = '/api/postPurchaseResult';

            await axios.post(url, {
                params: creditParams,
                '電話番号': input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                '代表者名_姓': input代表者名_姓.value,
                '代表者名_名': input代表者名_名.value,
                '代表者名_カナ_姓': input代表者名_姓_カナ.value,
                '代表者名_カナ_名': input代表者名_名_カナ.value,
                '代表者_役職': input代表者_役職.value,
                '管理者名_カナ_姓': input管理者名_姓_カナ.value,
                '管理者名_カナ_名': input管理者名_名_カナ.value,
                '管理者_電話番号': input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                'メルマガ登録': inputメルマガ登録.value === 'あり' ? 1 : 0,
                '業種': 業種_細分類.value,
                '会員種別': get会員種別コード(input会員種別.value),
            });

            sb_pay_method.value = creditParams.pay_method;
            sb_merchant_id.value = creditParams.merchant_id;
            sb_service_id.value = creditParams.service_id;
            sb_cust_code.value = creditParams.cust_code;
            sb_order_id.value = creditParams.order_id;
            sb_item_id.value = creditParams.item_id;
            sb_item_name.value = creditParams.item_name;
            sb_amount.value = creditParams.amount;
            sb_pay_type.value = creditParams.pay_type;
            sb_service_type.value = creditParams.service_type;
            sb_terminal_type.value = creditParams.terminal_type;

            sb_dtl_rowno_1.value = creditParams.dtl_rowno_1;
            sb_dtl_item_id_1.value = creditParams.dtl_item_id_1;
            sb_dtl_item_name_1.value = creditParams.dtl_item_name_1;
            sb_dtl_item_count_1.value = creditParams.dtl_item_count_1;
            sb_dtl_amount_1.value = creditParams.dtl_amount_1;

            sb_dtl_rowno_2.value = creditParams.dtl_rowno_2;
            sb_dtl_item_id_2.value = creditParams.dtl_item_id_2;
            sb_dtl_item_name_2.value = creditParams.dtl_item_name_2;
            sb_dtl_item_count_2.value = creditParams.dtl_item_count_2;
            sb_dtl_amount_2.value = creditParams.dtl_amount_2;

            sb_dtl_rowno_3.value = creditParams.dtl_rowno_3;
            sb_dtl_item_id_3.value = creditParams.dtl_item_id_3;
            sb_dtl_item_name_3.value = creditParams.dtl_item_name_3;
            sb_dtl_item_count_3.value = creditParams.dtl_item_count_3;
            sb_dtl_amount_3.value = creditParams.dtl_amount_3;

            sb_dtl_rowno_4.value = creditParams.dtl_rowno_4;
            sb_dtl_item_id_4.value = creditParams.dtl_item_id_4;
            sb_dtl_item_name_4.value = creditParams.dtl_item_name_4;
            sb_dtl_item_count_4.value = creditParams.dtl_item_count_4;
            sb_dtl_amount_4.value = creditParams.dtl_amount_4;

            sb_success_url.value = creditParams.success_url;
            sb_cancel_url.value = creditParams.cancel_url;
            sb_error_url.value = creditParams.error_url;
            sb_pagecon_url.value = creditParams.pagecon_url;
            sb_request_date.value = creditParams.request_date;

            // sb_hashkey.value = ref(VITE_SB_HASH_KEY);

            const vals = Object.values(creditParams);

            const str = vals.reduce((temp, item) => {
                return temp + item;
            });

            const hashData = SbHash(str + sb_hashkey.value);

            sb_sps_hashcode.value = hashData;

            await nextTick();
            submitCredit.value.submit();

        }

        // const payConvenience = async (orderID) => {
        //     //クレジット払い
        //     const time_string = dayjs().format('YYYYMMDDHHmmss')

        //     const baseUrl = window.location.origin;

        //     const creditParams = {
        //         'pay_method': 'webcvs',
        //         'merchant_id': sb_merchant_id.value,
        //         'service_id': sb_service_id.value,
        //         'cust_code': props.corp_info.uuid,
        //         'order_id': orderID,
        //         'item_id': 98000001,
        //         'item_name': 'TOTONO-L年会費',
        //         'amount': 合計額.value,
        //         'pay_type': 0,
        //         'service_type': 0,
        //         'terminal_type': 0,

        //         'success_url': baseUrl + '/hon_success',
        //         'cancel_url': baseUrl + '/hon_cancel',
        //         'error_url': baseUrl + '/hon_error',
        //         // 'pagecon_url': baseUrl + '/api/hon_pagecon',
        //         'pagecon_url': 'https://totono-l.org/api/hon_web_cvs_pagecon',

        //         'dtl_rowno_1': 1,
        //         'dtl_item_id_1': 年度.value,
        //         'dtl_item_name_1': '年会費',
        //         'dtl_item_count_1': 1,
        //         'dtl_amount_1': 年会費.value,

        //         'dtl_rowno_2': 2,
        //         'dtl_item_id_2': 10030001,
        //         'dtl_item_name_2': '従業員',
        //         'dtl_item_count_2': input従業員数.value,
        //         'dtl_amount_2': 従量_従業員数.value,

        //         'dtl_rowno_3': 3,
        //         'dtl_item_id_3': 10030002,
        //         'dtl_item_name_3': '支店',
        //         'dtl_item_count_3': input支店数.value,
        //         'dtl_amount_3': 従量_支店数.value,

        //         'dtl_rowno_4': 4,
        //         'dtl_item_id_4': 10030003,
        //         'dtl_item_name_4': '家族数',
        //         'dtl_item_count_4': input家族数.value,
        //         'dtl_amount_4': 従量_家族数.value,

        //         'request_date': time_string,
        //     }

        //     const url = '/api/postPurchaseResult';

        //     axios.post(url, {
        //         params: creditParams,

        //         '電話番号': input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
        //         '代表者名_姓': input代表者名_姓.value,
        //         '代表者名_名': input代表者名_名.value,
        //         '代表者名_カナ_姓': input代表者名_姓_カナ.value,
        //         '代表者名_カナ_名': input代表者名_名_カナ.value,
        //         '代表者_役職': input代表者_役職.value,
        //         '管理者名_カナ_姓': input管理者名_姓_カナ.value,
        //         '管理者名_カナ_名': input管理者名_名_カナ.value,
        //         '管理者_電話番号': input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
        //         'メルマガ登録': inputメルマガ登録.value === 'あり' ? 1 : 0,
        //         '業種': 業種_細分類.value,
        //         '会員種別': get会員種別コード(input会員種別.value),
        //     });

        //     sb_pay_method.value = creditParams.pay_method;
        //     sb_merchant_id.value = creditParams.merchant_id;
        //     sb_service_id.value = creditParams.service_id;
        //     sb_cust_code.value = creditParams.cust_code;
        //     sb_order_id.value = creditParams.order_id;
        //     sb_item_id.value = creditParams.item_id;
        //     sb_item_name.value = creditParams.item_name;
        //     sb_amount.value = creditParams.amount;
        //     sb_pay_type.value = creditParams.pay_type;
        //     sb_service_type.value = creditParams.service_type;
        //     sb_terminal_type.value = creditParams.terminal_type;

        //     sb_dtl_rowno_1.value = creditParams.dtl_rowno_1;
        //     sb_dtl_item_id_1.value = creditParams.dtl_item_id_1;
        //     sb_dtl_item_name_1.value = creditParams.dtl_item_name_1;
        //     sb_dtl_item_count_1.value = creditParams.dtl_item_count_1;
        //     sb_dtl_amount_1.value = creditParams.dtl_amount_1;

        //     sb_dtl_rowno_2.value = creditParams.dtl_rowno_2;
        //     sb_dtl_item_id_2.value = creditParams.dtl_item_id_2;
        //     sb_dtl_item_name_2.value = creditParams.dtl_item_name_2;
        //     sb_dtl_item_count_2.value = creditParams.dtl_item_count_2;
        //     sb_dtl_amount_2.value = creditParams.dtl_amount_2;

        //     sb_dtl_rowno_3.value = creditParams.dtl_rowno_3;
        //     sb_dtl_item_id_3.value = creditParams.dtl_item_id_3;
        //     sb_dtl_item_name_3.value = creditParams.dtl_item_name_3;
        //     sb_dtl_item_count_3.value = creditParams.dtl_item_count_3;
        //     sb_dtl_amount_3.value = creditParams.dtl_amount_3;

        //     sb_dtl_rowno_4.value = creditParams.dtl_rowno_4;
        //     sb_dtl_item_id_4.value = creditParams.dtl_item_id_4;
        //     sb_dtl_item_name_4.value = creditParams.dtl_item_name_4;
        //     sb_dtl_item_count_4.value = creditParams.dtl_item_count_4;
        //     sb_dtl_amount_4.value = creditParams.dtl_amount_4;

        //     sb_success_url.value = creditParams.success_url;
        //     sb_cancel_url.value = creditParams.cancel_url;
        //     sb_error_url.value = creditParams.error_url;
        //     sb_pagecon_url.value = creditParams.pagecon_url;
        //     sb_request_date.value = creditParams.request_date;

        //     sb_hashkey.value = ref(VITE_SB_HASH_KEY);

        //     const vals = Object.values(creditParams);

        //     const str = vals.reduce((temp, item) => {
        //         return temp + item;
        //     });

        //     const hashData = SbHash(str + sb_hashkey.value);

        //     sb_sps_hashcode.value = hashData;

        //     await nextTick();
        //     submitConvenience.value.submit();

        // }

        const 支払方法名 = computed(() => {
            switch(支払方法.value){
                case 10050001:
                    return 'クレジット決済';
                case 10050002:
                    return 'コンビニ払い';
                default:
                    return '口座振替';
            }
        });

        const densan_url = ref(VITE_SB_DENSAN_URL);

        const 業種_細分類 = ref('');

        return {
            showMode,
            isProcessing,

            input代表者名_姓,
            input代表者名_名,
            input代表者名_姓_カナ,
            input代表者名_名_カナ,
            input代表者_役職,

            input管理者名_姓_カナ,
            input管理者名_名_カナ,

            input電話番号1,
            input電話番号2,
            input電話番号3,

            input管理者_電話番号1,
            input管理者_電話番号2,
            input管理者_電話番号3,

            input支店数,
            input従業員数,
            input家族数,

            inputメルマガ登録,

            input所属する団体会員コード,
            input賛助会員コード,
            input会員種別,

            showPrice,

            年度,
            年会費,
            従量_支店数,
            従量_従業員数,
            従量_家族数,
            number_format,
            合計額,

            // registerPaidCorp,

            get会員種別コード,

            getOrderID,

            支払方法,
            payCredit,
            // payConvenience,

            支払方法名,

            densan_url,

            submitCredit,
            submitConvenience,

            sb_pay_method,
            sb_merchant_id,
            sb_service_id,
            sb_cust_code,
            sb_order_id,
            sb_item_id,
            sb_item_name,
            sb_amount,
            sb_pay_type,
            sb_service_type,
            sb_terminal_type,

            sb_dtl_rowno_1,
            sb_dtl_item_id_1,
            sb_dtl_item_name_1,
            sb_dtl_item_count_1,
            sb_dtl_amount_1,
            
            sb_dtl_rowno_2,
            sb_dtl_item_id_2,
            sb_dtl_item_name_2,
            sb_dtl_item_count_2,
            sb_dtl_amount_2,

            sb_dtl_rowno_3,
            sb_dtl_item_id_3,
            sb_dtl_item_name_3,
            sb_dtl_item_count_3,
            sb_dtl_amount_3,
            
            sb_dtl_rowno_4,
            sb_dtl_item_id_4,
            sb_dtl_item_name_4,
            sb_dtl_item_count_4,
            sb_dtl_amount_4,

            sb_success_url,
            sb_cancel_url,
            sb_error_url,
            sb_pagecon_url,
            sb_request_date,
            sb_hashkey,
            sb_sps_hashcode,

            showConvenientPayFlg,

            業種_細分類,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        first_bill_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
    #result_table tr td:nth-child(1){
        width: 25%;
    }

    #ssl_icon {
        background-image: url('../../../svg/ssl2.svg');
        display: inline-flex;
        width: 86px;
        height: 33px;
        background-size: 86px auto;
        background-repeat: no-repeat;
    }
</style>