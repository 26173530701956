<template>
    <div class="row">
        <div id="delete-modal" v-show="deleteModalVisible" @click.self="closeDeleteModal">
            <div id="delete-content">
                <div class="row">
                    <label>登録一覧から</label>
                </div>
                <div class="row">
                    <label class="font-delete-blue">{{ deleteSettingIdx + ' ' + deleteSettingName }}</label>
                </div>
                <div class="row">
                    <label>を削除します。よろしいですか？（復元不可）</label>
                </div>
                <div class="row mt-3">
                    <div class="d-flex justify-content-evenly">
                        <input type="button" class="btn btn-darkblue" value="はい" @click="deleteSetting" :disabled="isProcessing" />
                        <input type="button" class="btn btn-modal-cancel" value="キャンセル" @click="closeDeleteModal" :disabled="isProcessing" />
                    </div>
                </div>
            </div>
        </div>
        <div id="delivery-modal" v-show="deliveryModalVisible" @click.self="closeDeliveryModal">
            <div id="delivery-content">
                <div class="row">
                    <label>登録一覧から</label>
                </div>
                <div class="row">
                    <label class="font-delete-blue">{{ deliverySettingIdx + ' ' + deliverySettingName }}</label>
                </div>
                <div class="row">
                    <label>を配信します。よろしいですか？</label>
                </div>
                <div class="row mt-3">
                    <div class="d-flex justify-content-evenly">
                        <input type="button" class="btn btn-send" value="今すぐ配信" @click="deliveryMail" :disabled="isProcessing" />
                        <input type="button" class="btn btn-modal-cancel" value="キャンセル" @click="closeDeliveryModal" :disabled="isProcessing" />
                    </div>
                </div>
            </div>
        </div>

        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            登録一覧から配信・編集
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome" :disabled="isProcessing">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-5 flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

    <div class="row mt-5 ms-1">
        <table class="table table-bordered border-dark table-responsive">
            <thead>
                <tr>
                    <td rowspan="2">No.</td>
                    <td rowspan="2">登録名称</td>
                    <td colspan="2">安否確認配信先</td>
                    <td colspan="2">確認機能</td>
                    <td colspan="2">未回答者への自動再配信</td>
                    <td colspan="4">訓練用配信予約</td>
                    <td rowspan="2"></td>
                </tr>
                <tr>
                    <td>{{ callReplaceWord('従業員') }}</td>
                    <td>家族</td>
                    <td>位置情報</td>
                    <td>{{ callReplaceWord('出社') }}可能時間</td>
                    <td>連絡間隔</td>
                    <td>再連絡回数</td>
                    <td colspan="3">間隔（日付曜日指定）</td>
                    <td>時間</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(setting, index) in safetyConfirmationLists" :key="setting.condition">
                    <td>{{ index + 1 }}</td>
                    <td>{{ setting.登録名称 }}</td>
                    <td>〇</td>
                    <td>{{ setting.家族への安否確認 == 1 ? '〇' : '－' }}</td>
                    <td>{{ setting.位置情報確認要否 == 1 ? '〇' : '－' }}</td>
                    <td>{{ setting.出社可能時間確認要否 == 1 ? '〇' : '－' }}</td>
                    <td>{{ setting.再配信間隔 }}</td>
                    <td>{{ setting.再配信回数 }}</td>
                    <td>{{ setting.訓練月 }}</td>
                    <td>{{ setting.指定した月 }}</td>
                    <td>{{ (setting.訓練週 ?? '') + (setting.訓練指定日 ?? '') }}</td>
                    <td>{{ setting.訓練時間 }}</td>
                    <td>
                        <div class="col-md-1 d-flex align-items-end">
                            <div class="btn-group-vertical ">
                                <input type="button" class="btn btn-gray" value="編集" @click="editSetting(setting.condition)" :disabled="isProcessing" />
                                <input type="button" class="btn btn-gray" value="削除" @click="showDeleteModal(setting.condition, index)" :disabled="isProcessing" />
                                <input type="button" class="btn btn-send" value="今すぐ配信" @click="showDeliveryModal(setting.condition, index)" :disabled="isProcessing" />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


    <div class="row mt-3 pb-5 d-flex flex-row">
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-darkblue" value="新規作成" @click="createNewSafety">
            </div>
        </div>
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-gray" value="安否確認HOMEへ戻る" @click="backHome">
            </div>
        </div>
    </div>


</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const safetyConfirmationLists = ref([]);

        onMounted(() => {
            safetyConfirmationLists.value = props.safety_confirmation_list;
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backHome = () => {
            const url = '/safety/home';

            location.href = url;
        }

        const createNewSafety = () => {
            const url = '/safety/k1?back=k2';

            location.href = url;
        }

        const modalTrainingVisible = ref(false);

        const closeTrainingModal = () => {
            trainingTiming.value = 0;
            trainingTimingMonth.value = 0;
            trainingDayKind.value = 0;
            trainingDay.value = 0;
            trainingDayOfWeek.value = 0;
            trainingTime.value = 0;

            modalTrainingVisible.value = false;
        }

        const setTraining = () => {
            modalTrainingVisible.value = true;
        }

        const editSetting = (condition) => {
            const url = '/safety/editSafetySetting?back=k2&corp_id=' + props.corp_info.id + '&condition=' + condition;

            location.href = url;
        }

        const deliveryModalVisible = ref(false);
        const deliverySettingName = ref('');
        const deliverySettingIdx = ref(0);
        const deliverySettingCondition = ref(0);

        const showDeliveryModal = (condition, index) => {
            const filterSetting = props.safety_confirmation_list.filter(item => {
                return item.condition === condition;
            });

            deliverySettingName.value = filterSetting[0].登録名称;
            deliverySettingCondition.value = condition;
            deliverySettingIdx.value = index + 1;
            deliveryModalVisible.value = true;

        }

        const closeDeliveryModal = () => {
            deliveryModalVisible.value = false;
        }

        const isProcessing = ref(false);

        const deliveryMail = () => {
            isProcessing.value = true;
            const url = '/api/sendSafetyTrainingMail';

            axios.post(url, {
                corp_id: props.corp_info.id,
                condition: deliverySettingCondition.value,
            }).then(res => {
                visibleFlashMessage('送信しました', 'success');
            }).catch(err => {
                visibleFlashMessage('送信に失敗しました', 'danger');
            })
            .finally(fin => {
                isProcessing.value = false;
                closeDeliveryModal();
            });
        }

        const deleteModalVisible = ref(false);
        const deleteSettingName = ref('');
        const deleteSettingCondition = ref(0);
        const deleteSettingIdx = ref(0);

        const showDeleteModal = (condition, idx) => {
            const filterSetting = props.safety_confirmation_list.filter(item => {
                return item.condition == condition;
            });

            deleteSettingName.value = filterSetting[0].登録名称;
            deleteSettingCondition.value = condition;
            deleteSettingIdx.value = idx + 1;
            deleteModalVisible.value = true;

        }

        const closeDeleteModal = () => {
            deleteModalVisible.value = false;
        }

        const deleteSetting = () => {
            const url = '/api/deleteSafetyConfirmationSettings';

            axios.delete(url, {data: {corp_id: props.corp_info.id, condition: deleteSettingCondition.value}})
            .then(res => {
                visibleFlashMessage('正常に削除されました', 'success');
                getList();
            }).catch(err => {
                visibleFlashMessage('削除に失敗しました', 'danger');
            })
            .finally(fin => {
                closeDeleteModal();
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const getList = () => {
            const url = '/api/getSafetyConfirmationSetting?corp_id=' + props.corp_info.id;

            axios.get(url).then(res => {
                safetyConfirmationLists.value = res.data;
            });
        }

        return {
            setLevel,
            callReplaceWord,
            backHome,
            createNewSafety,

            safetyConfirmationLists,

            modalTrainingVisible,
            closeTrainingModal,
            setTraining,

            editSetting,

            showDeliveryModal,
            deliverySettingName,
            deliverySettingIdx,
            deliverySettingCondition,

            deliveryModalVisible,
            closeDeliveryModal,

            deliveryMail,

            showDeleteModal,
            deleteSettingName,
            deleteSettingIdx,
            deleteSettingCondition,

            deleteModalVisible,
            closeDeleteModal,

            deleteSetting,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            getList,

            isProcessing,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        safety_confirmation_list: {
            type: Array,
        }
    }

}
</script>

<style lang="scss" scoped>
    table thead {
        background-color: #d9d9d9;
    }

    .btn-darkblue {
        border: 1px solid;
        color: white;
        border-color: #2f75b5;
        background-color: #2f75b5;
    }

    .btn-darkblue:hover {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

    .btn-modal-cancel {
        border: 1px solid;
        color: white;
        border-color: #bfbfbf;
        background-color: #bfbfbf;
    }

    .btn-modal-cancel:hover {
        border: 1px solid;
        color: #bfbfbf;
        border-color: #bfbfbf;
        background-color: white;
    }

    .btn-send {
        border: 1px solid #33cccc;
        color: white;
        border-color: #33cccc;
        background-color: #33cccc;
    }

    .btn-send:hover {
        border: 1px solid #33cccc;
        color: #33cccc;
        border-color: #33cccc;
        background-color: white;
    }

    #delete-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #delete-content {
        z-index: 2;
        width: 33%;
        padding: 5em;
        background: #f2f2f2;
    }

    #delivery-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #delivery-content {
        z-index: 2;
        width: 33%;
        padding: 5em;
        background: #f2f2f2;
    }

    .font-delete-blue {
        color: #0070c0;
    }

</style>