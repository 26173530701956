<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <label class="col-form-label">{{ 組織名 }}</label>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <i class="bi bi-geo-alt-fill"></i><label class="col-form-label ms-3">拠点</label>
                            </div>
                            <div class="col-md-9">
                                <label class="col-form-label ms-3">{{ label郵便番号 + ' ' + 住所 }}</label>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-3">
                                <i class="bi bi-book"></i><label class="col-form-label ms-3">産業分類</label>
                            </div>
                            <div class="col-md-9">
                                <label class="col-form-label ms-3 font-blue">{{ 業種 }}</label><label class="col-form-label ms-2">{{ 業種名 }}</label>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-3">
                                <i class="bi bi-people-fill"></i><label class="col-form-label ms-3">{{ label従業員 }}数</label>
                            </div>
                            <div class="col-md-3">
                                <label class="col-form-label ms-3">{{ 従業員数 }}人</label>
                            </div>
                            <div class="col-md-1">
                                <label class="col-form-label fw-bold">URL</label>
                            </div>
                            <div class="col-md-5 mt-2" v-show="url">
                                <a :href="url" target="_blank">{{ url }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 img-container">
                        <img :src='img_src' class="img fit-image" />
                    </div>
                </div>

                <div class="row mt-3 mx-3">
                    <div class="col-md-4 heading-blue font-blue">
                        この組織があなたに求めるもの
                    </div>
                    <div class="col-md-8 heading-blue font-blue">
                        登録しているマッチングワード
                    </div>
                </div>

                <div  v-for="matching in matching_list" :key="matching.id">
                    <div class="row mt-2 mx-3">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-3 d-flex">
                                    <label class="col-form-label label-things-title flex-fill ps-3">{{ matching.thing_title }}</label>
                                </div>
                                <div class="col-md-9 d-flex">
                                    <label class="col-form-label label-things-value flex-fill ps-3">{{ matching.thing_value }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-3 d-flex">
                                    <label class="col-form-label label-word-title flex-fill ps-3">{{ matching.matching_title }}</label>
                                </div>
                                <div class="col-md-9 d-flex">
                                    <label class="col-form-label flex-fill ps-3"  :class="matching.matching_value1.length === 0 && matching.matching_value2.length === 0 && matching.matching_value3.length === 0 ? 'label-word-nothing':'label-word-value'">{{ labelMatchingValue(matching.matching_value1, matching.matching_value2, matching.matching_value3) }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mt-2 mx-3" v-show="matching.matching_value2">
                        <div>
                            <div class="col-md-4">
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-3 d-flex">
                                    </div>
                                    <div class="col-md-9 d-flex">
                                        <label class="col-form-label label-word-value flex-fill ps-3">{{ matching.matching_value2 }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mx-3" v-show="matching.matching_value3">
                        <div>
                            <div class="col-md-4">
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-3 d-flex">
                                    </div>
                                    <div class="col-md-9 d-flex">
                                        <label class="col-form-label label-word-value flex-fill ps-3">{{ matching.matching_value2 }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="row mt-5 mx-3">
                    <div class="col-md-2 heading-blue font-blue">
                        協定実績
                    </div>
                    <div class="col-md-2 label-body">
                        {{ 協定実績 }}
                    </div>
                    <div class="col-md-2 heading-blue font-blue">
                        BCP策定レベル
                    </div>
                    <div class="col-md-2 label-body">
                        {{ BCP策定レベル }}
                    </div>
                    <div class="col-md-2 heading-blue font-blue">
                        BCP発動回数
                    </div>
                    <div class="col-md-2 label-body">
                        {{ BCP発動回数 }}
                    </div>
                </div>
                <div class="row mt-3 mx-3 pb-5">
                    <div class="col-md-2 heading-blue font-blue">
                        協定書保管数
                    </div>
                    <div class="col-md-2 label-body">
                        {{ 協定書保管数 }}
                    </div>
                    <div class="col-md-2 heading-blue font-blue">
                        TOTONO-L歴
                    </div>
                    <div class="col-md-2 label-body">
                        {{ TOTONOL歴 }}年目
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

export default {
    setup(props) {
        const label郵便番号 = computed(() => {
            const zip_code = props.郵便番号.toString();

            return zip_code.substring(0, 3) + '-' + zip_code.substring(3);
        });

        const labelMatchingValue = (matching_val_1, matching_val_2, matching_val_3) => {
            let rtnVal = "";
            if(matching_val_1.length > 0){
                rtnVal = matching_val_1
            }
            if(matching_val_2.length > 0){
                if(rtnVal.length > 0){
                    rtnVal += "　" + matching_val_2;
                }else{
                    rtnVal = matching_val_2;
                }
            }
            if(matching_val_3.length > 0){
                if(rtnVal.length > 0){
                    rtnVal += "　" + matching_val_3;
                }else{
                    rtnVal = matching_val_3;
                }
            }
            return rtnVal;
        }

        return {
            labelMatchingValue,
            label郵便番号,
        }
        
    },
    props: {
        組織名: {
            type: String,
        },
        郵便番号: {
            type: Number,
        },
        住所: {
            type: String,
        },
        業種: {
            type: String,
        },
        業種名: {
            type: String,
        },
        label従業員: {
            type: String,
        },
        url: {
            type: String,
        },
        img_src: {
            type: String,
        },
        matching_list: {
            type: Array,
        },
        協定実績: {
            type: Number,
        },
        BCP策定レベル: {
            type: String,
        },
        BCP発動回数: {
            type: Number,
        },
        協定書保管数: {
            type: Number,
        },
        TOTONOL歴: {
            type: Number,
        },
        従業員数: {
            type: Number,
        },
    }
}
</script>

<style lang="scss" scoped>
.font-blue{
    color: #2f75b5;
}

.label-things-title {
    background-color: #bdd7ee;
    border: 1px solid #bfbfbf;
}

.label-things-value {
    background-color: #f2f2f2;
    border: 1px solid #bfbfbf;
}

.label-word-title {
    background-color: #9bc2e6;
    border: 1px solid #bfbfbf;
}

.label-word-value {
    background-color: #d9d9d9;
    border: 1px solid #bfbfbf;
}

.label-word-nothing {
    background-color: white;
    border: 1px solid #bfbfbf;
}

.label-body {
    padding: 0.1rem 2rem;

}

.img-container {
    width: 300px; /* 親要素の幅を設定 */
    height: 200px; /* 親要素の高さを設定 */
    overflow: hidden; /* オーバーフローした部分を隠す */
}

.fit-image {
    width: 100%; /* 親要素に合わせて幅を100%に */
    height: 100%; /* 親要素に合わせて高さを100%に */
    object-fit: contain; /* アスペクト比を保ちつつ、親要素にフィット */
}
</style>