<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">クーポンコード発行</div>
            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>

        <form @submit.prevent="post_form">
            <div class="row mt-3 px-5">
                <label class="col-md-2 col-form-label">メールアドレス</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red">必須</span></div>
                <div class="col-md-6 me-5">半角英数字<input type="mail" class="form-control" v-model="inputメールアドレス_1" required maxlength="255" :disabled="showMode===2"></div>
            </div>
            <div class="row mt-3 px-5" v-show="showMode===1">
                <div class="col-md-6 offset-md-3">確認のためもう一度入力してください。<input type="mail" class="form-control" v-model="inputメールアドレス_2" required maxlength="255"></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="col-md-2 col-form-label">組織・企業の名称</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red">必須</span></div>
                <div class="col-md-6 me-5"><input type="text" class="form-control" v-model="input組織名" required maxlength="100" :disabled="showMode===2"></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="col-md-1 col-form-label">管理者</label>
                <label class="col-md-1 col-form-label">氏名</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red">必須</span></div>
                <label class="col-md-1 col-form-label">（姓）</label>
                <div class="col-md-2"><input type="text" class="form-control" v-model="input管理者名_姓" required maxlength="255" :disabled="showMode===2"></div>
                <label class="col-md-1 col-form-label">（名）</label>
                <div class="col-md-2"><input type="text" class="form-control" v-model="input管理者名_名" required maxlength="255" :disabled="showMode===2"></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="col-md-1 offset-md-1 col-form-label">部署・役職</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red">必須</span></div>
                <label class="col-md-1 col-form-label">部署</label>
                <div class="col-md-2"><input type="text" class="form-control" v-model="input管理者_所属部署" required maxlength="255" :disabled="showMode===2"></div>
                <label class="col-md-1 col-form-label">役職</label>
                <div class="col-md-2"><input type="text" class="form-control" v-model="input管理者_役職" required maxlength="255" :disabled="showMode===2"></div>
            </div>

            <div class="row mt-3 px-5" v-show="showMode===2">
                <label class="col-md-2 col-form-label border-black">クーポンコード</label>
                <label class="col-md-6 col-form-label border-black">{{ CouponCode }}</label>
                <div class="col-md-2">
                    <div>
                        <input type="button" class="btn btn-light-gray" value="コピー" @click="copyCouponCode"/>
                    </div>
                </div>
            </div>

            <div class="row mt-5 px-5">
                <div>
                    <input type="submit" class="btn btn-resilience-red px-5 py-2" value="上記のユーザーにクーポンを発行する" @click="confirm" v-show="showMode===1" :disabled="isProcessing" />
                    <input type="button" class="btn btn-black" value="クーポンコード発行メールを送信する" @click="sendMail" v-show="showMode===2" :disabled="isProcessing" />
                    <input type="button" class="btn btn-gray ms-3" value="新たなクーポンコードを発行する" @click="newCouponCode" v-show="showMode===2" :disabled="isProcessing" />
                </div>
            </div>
            

        </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';

export default{
    setup() {
        const showMode = ref(1);

        const back = () => {
            const url = '/home';

            location.href = url;
        }

        const inputメールアドレス_1 = ref('');
        const inputメールアドレス_2 = ref('');
        const input組織名 = ref('');
        const input管理者名_姓 = ref('');
        const input管理者名_名 = ref('');
        const input管理者_所属部署 = ref('');
        const input管理者_役職 = ref('');

        const CouponCode = ref('');

        const メールアドレス_has_error = ref(false);
        const メールアドレス_error_msg = ref('');

        const isProcessing = ref(false);

        const confirm = () => {
            メールアドレス_has_error.value = false;
            メールアドレス_error_msg.value = '';

            if(inputメールアドレス_1.value !== inputメールアドレス_2.value){
                alert('入力されたメールアドレスが異なります。');

                メールアドレス_has_error.value = true;
                メールアドレス_error_msg.value = '入力されたメールアドレスが異なります。';

                return false;
            }

            const url = '/api/s-admin/createCouponCode';

            isProcessing.value = true;

            axios.post(url, {
                mail: inputメールアドレス_1.value,
                組織名: input組織名.value,
                管理者名_姓: input管理者名_姓.value,
                管理者名_名: input管理者名_名.value,
                管理者_所属部署: input管理者_所属部署.value,
                管理者_役職: input管理者_役職.value,
            }).then(res=>{
                CouponCode.value = res.data.CouponCode;
                showMode.value = 2;
            }).finally(fin=>{
                isProcessing.value = false;
            });

        }

        const copyCouponCode = () => {
            navigator.clipboard.writeText(CouponCode.value);
        }

        const sendMail = () => {
            const url = '/api/s-admin/sendCouponCode';

            isProcessing.value = true;

            axios.post(url, {
                mail: inputメールアドレス_1.value,
                組織名: input組織名.value,
                管理者名_姓: input管理者名_姓.value,
                管理者名_名: input管理者名_名.value,
                管理者_所属部署: input管理者_所属部署.value,
                管理者_役職: input管理者_役職.value,
                クーポンコード: CouponCode.value,
            }).then(res=>{
            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        const newCouponCode = () => {
            inputメールアドレス_1.value = '';
            inputメールアドレス_2.value = '';
            input組織名.value = '';
            input管理者名_姓.value = '';
            input管理者名_名.value = '';
            input管理者_所属部署.value = '';
            input管理者_役職.value = '';
            CouponCode.value = '';

            showMode.value = 1;

        }

        return {
            showMode,

            back,
            inputメールアドレス_1,
            inputメールアドレス_2,
            input組織名,
            input管理者名_姓,
            input管理者名_名,
            input管理者_所属部署,
            input管理者_役職,
            confirm,

            メールアドレス_has_error,
            メールアドレス_error_msg,
            isProcessing,

            CouponCode,

            copyCouponCode,
            sendMail,

            newCouponCode,
        }
    }
}
</script>