<template>
    <div>
        <a class="nav-link" aria-current="page" @click="showBCPHome" >{{ callReplaceWord('事業') }}継続計画BCPへ</a>
    </div>
</template>

<script>
import { ref } from 'vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    setup(props) {
        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBCPHome = ()=> {
            const url = '/bcp/home';
            location.href = url;
        }

        return {
            callReplaceWord,
            showBCPHome,
        }

    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    },
}



</script>