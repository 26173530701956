<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            ビジネスマッチング規約	
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backPage">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row pb-3 border-bottom-red">
        <div class="h2 mt-5 d-flex justify-content-center color-resilience-red">
            B&B災害応援協定ビジネスマッチング規程
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
            本規程は、TOTONO-Lサイトの利用規約を補完するB&B災害応援協定ビジネスマッチング（以下、「災害応援マッチング」といいます。）専用の規程です。ユーザーは、利用規約と共に本規程を十分に理解しこれを承諾したうえで、本サービスを利用するものとします。
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            １&nbsp;&emsp;&emsp;検索キーワードの登録
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>災害応援マッチングは、提携先の検索システムです。ユーザーは当該サービスを利用するにあたり、希望する代替情報をあらかじめ登録するものとします。</li>
                <li>ユーザーが登録したキーワード情報は、真実かつ正確で最新であることを、ユーザー自身が保証するものとします。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ２&nbsp;&emsp;&emsp;マッチング先への対応義務
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>ユーザーは、マッチング先（協定先候補）のユーザーから協定の打診および取引の依頼があった場合、１週間以内に誠実な回答を行なう義務を負います。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ３&nbsp;&emsp;&emsp;モデル協定書の利用
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>ユーザーは、災害応援マッチングが成功した際、TOTONO-L指定のモデル協定書を利用するものとします。なお、当該協定書はマッチング先の同意によるものであれば、加筆訂正を行うことを認めています。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ４&nbsp;&emsp;&emsp;報告義務および事後措置
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>次の事項が発生した場合は、お問い合わせ機能を利用し、その判断に至った理由等を添えて当会へ報告するものとします。</li>
                <ol>
                <li>前号のモデル協定書への加筆訂正にあたり、マッチング先のユーザーから本来の目的を著しく逸脱する内容の条項追加または削除を求められた場合</li>
                <li>マッチング先のユーザーから利用規約「第20条 禁止事項」に違反する協定締結を持ちかけられた場合</li>
                <li>BCP発動後、協定先（マッチング先との協定を終えたユーザーをいいます。以下同じ。）が、悪質性の高い災害応援協定違反を犯したと判断した場合</li>
            </ol>
                <li>当会は、上記１～２の報告を受けた場合、報告内容の多いものに限定して、TOTONO-L「B&B災害応援協定ビジネスマッチング」のお知らせページにより、全体への注意喚起を行うものとします。ただし、同一ユーザーに対する複数の報告があった場合は、そのユーザーに対し直接注意喚起を行うことがあります。ただし、報告者に対しこれらを約束できるものではありません。</li>
                <li>当会は、上記３の協定違反報告（以下「通報」といいます。）を受けた場合、原則として、その事実をTOTONO-L「B&B災害応援協定ビジネスマッチング」のお知らせページにより公表するものとします。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ５&nbsp;&emsp;&emsp;協定違反の類型
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>協定違反の類型は、次の通りとします。</li>
            <ol>
                <li>協定で定めたBCP発動時の代替行為を引き受ける際、顧客又は取引先に対し、協定とは別の有利な条件を提示してその行為を行なう等、既存の関係を妨害した場合。ただし、協定先の被害状況により、当該協定先の同意を得ていた場合を除く。</li>
                <li>協定で定めたBCPの訓練を数回にわたって怠り、協定先の信頼を失墜した場合。ただし、協定先に対し、都度前日までに合理的な理由を伝え延期の申出を行った場合を除く。</li>
                <li>その他、重大な協定違反があった場合。</li>
            </ol>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ６&nbsp;&emsp;&emsp;通報による公表判断基準
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>通報および公表規定は、本来の目的を逸脱して協定違反を行う悪質なユーザーを排除し、他のユーザーが安心して本サービスを利用していただくために行なう罰則であることをご理解ください。</li>
                <li>ユーザーから、前号の通報があった場合、当会はその事実を調査します。万一、当該違反の悪質性を認めた場合は、協定違反を認めた組織に関し、以下の情報を公表することに同意します。ただし、通報した組織の名称は公表しません。</li>
            <ol>
                <li>協定違反を行ったと認められた組織の名称</li>
                <li>上記組織の代表者氏名</li>
                <li>協定違反の内容</li>
            </ol>
                <li>当会は、当該違反の事実を認めたうえで、災害応援マッチングの目的を達成するためにやむを得ないと判断した場合、公表に代えて厳重注意処分とする場合があります。ただし、厳重注意の喚起を二度以上行ったユーザーについては、前項の公表規定を受け入れていただきます。</li>
                <li>本号は、加筆訂正したTOTONO-L指定のモデル災害応援協定書、またはユーザー同士が独自に締結した災害応援協定書を利用した場合において、公表規定条項がない場合であっても適用されます。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ７&nbsp;&emsp;&emsp;災害応援マッチングに関する免責
        </div>
    </div>
    <div class="row">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                <ul>
                <li>B&B災害応援協定書は、ユーザー同士で締結するものであり、当会は協定の立会人などの責を担うことはありません。</li>
                <li>ユーザー同士で提供される情報の正確性、真実性、適法性等または、B&B災害応援協定書に記された情報について、当会は一切の責任を負わないものとします。</li>
                <li>協定先が、緊急事態発生時にB&B災害応援協定書で定めた情報を履行しなかった場合、当会では一切の責任を負わないものとします。</li>
                <li>B&B災害応援協定により、協定先が行った代替取引について、その内容がユーザーの希望を満たし有用であるか否かにかかわらず、当会は一切の責任を負わないものとします。</li>
                <li>ユーザーからの通報による調査の結果、当該事実を公表または公表しなかったことによる当会の判断について、当会は通報者に対し説明する義務を負わないものとします。</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
            ８&nbsp;&emsp;&emsp;付則
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
            <ul>
                <li>本規程の改廃は、当会の総会または理事会において行います。</li>
            <ol>
                <li>制定：2022年12月8日（総会）</li>
            </ol>
            </ul>
        </div>
    </div>
    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-5 text-center btn btn-back-home" value="B&B応援災害協定ビジネスマッチングHOMEへ戻る" @click="backHome">
            </div>
        </div>
    </div>

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('011111');
        
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        onMounted(() => {
        })

        const backHome = () => {
            const url = '/bnb/home';

            location.href = url;
        }

        const backPage = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        return {
            setLevel,
            backPage,
            backHome,
        }
    },
    props:{
    }

}
</script>

<style lang="scss" scoped>
.btn-back-home {
    border: 1px solid;
    color: #1f4e78;
    border-color: #bdd7ee;
    background-color: #bdd7ee;
}

.btn-back-home:hover {
    border: 1px solid;
    color: #bdd7ee;
    border-color: #1f4e78;
    background-color: #1f4e78;
}


</style>