<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                利用者一覧
            </div>
        </div>

        <form @submit.prevent="change(current_page)">
            <div class="row my-3">
                <div class="col-md-3">
                    <select class="form-select" v-model="sortType">
                        <option value="" disabled>-並べ替え-</option>
                        <option value="1">利用者ID（昇順）</option>
                        <option value="2">利用者ID（降順）</option>
                        <option value="3">勤務地（昇順）</option>
                        <option value="4">勤務地（降順）</option>
                        <option value="5">氏名（昇順）</option>
                        <option value="6">氏名（降順）</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <input type="button" class="btn btn-gray" value="並べ替え" @click="change(current_page)" />
                </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" v-model="searchWord" />
                    </div>
                    <div class="col-md-2">
                        <input type="submit" class="btn btn-gray" value="検索" />
                    </div>
            </div>
        </form>

        <div class="p2 ms-4 mt-3 d-flex align-items-center py-2">
        </div>

        <div class="row mt-3">
            <table class="table table-bordered table-responsive table-hover bg-light">
                <thead>
                    <tr>
                        <th>利用者ID</th>
                        <th>氏名</th>
                        <th>フリガナ</th>
                        <th>勤務地</th>
                        <th>部署・役職</th>
                        <th>携帯電話番号</th>
                        <th>メールアドレス</th>
                        <th>家族の人数</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in userList" :key="user.id">
                        <td>{{ user.利用者ID }}</td>
                        <td>{{ user.姓 + ' ' + user.名 }}</td>
                        <td>{{ user.姓_カナ + ' ' + user.名_カナ }}</td>
                        <td>{{ user.拠点名 ?? '' }}</td>
                        <td>{{ (user.部署 === 'なし' ? '' : user.部署) + ' ' + (user.役職 === 'なし' ? '' : user.役職) }}</td>
                        <td>{{ user.電話番号 }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.家族人数 }}</td>
                        <td>
                            <input type="button" class="btn btn-sm btn-black mx-3" value="編集" @click="editUser(user.利用者ID)" />
                            <input type="button" class="btn btn-sm btn-orange mx-3" value="削除" @click="deleteUser(user.id, user.利用者ID)" v-show="user.利用者ID !== '000001'" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-between">
            <div class="col-md-9">
                <input type="button" class="btn btn-black" value="新規利用者登録" @click="registerUser" />
            </div>
            <div class="col-md-3">
                <div class="col-sm-6 text-right">全 {{total}} 件中 {{from}} 〜 {{to}} 件表示</div>
                <ul class="pagination pagination-sm">
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link" @click="change(1)" aria-label="Top">&laquo;</a></li>
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link" @click="change(current_page - 1)" aria-label="Previous">&lt;</a></li>
                    <li v-for="page in pages" :key="page" :class="[{active: page === current_page}, 'page-item']">
                        <a href="#" class="page-link" @click="change(page)">{{page}}</a>
                    </li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link" @click="change(current_page + 1)" aria-label="Next">&gt;</a></li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link" @click="change(last_page)" aria-label="Last">&raquo;</a></li>
                </ul>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-9">
                <input type="button" class="btn btn-black" value="次へ進む" @click="backHome" />
            </div>
        </div>

    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';

export default {
    setup(props) {
        onMounted(() => {
            searchUserList(1);
        });

        const searchWord = ref('');
        const sortType = ref('');

        const backHome = () => {
            const url = '/home';

            location.href = url;
        }

        const userList = ref([]);

        const searchUserList = (page) => {
            const url="/api/administrator/searchUserList?sortType=" + sortType.value + "&searchWord=" + searchWord.value + "&page=" + page;
            
            axios.get(url).then(res=>{
                current_page.value = res.data.current_page;
                last_page.value = res.data.last_page;
                total.value = res.data.total;
                from.value = res.data.from;
                to.value = res.data.to;
                userList.value = res.data.data;
            });
        }

        const change = (page) => {
            searchUserList(page);
        }

        const showPayPerUse = () => {
            const url ="/al/administrator/register_pay_per_use?back=RegisterUser";

            location.href = url;
        }

        const current_page = ref(1);
        const last_page = ref(1);
        const total = ref(0);
        const from = ref(0);
        const to = ref(0);

        const registerUser = () => {
            const url = '/al/before/register_user';

            location.href = url;
        }

        const editUser = (user_id) => {
            const url = '/al/before/edit_user/' + user_id;

            location.href = url;
        }

        const deleteUser = (user_id, 利用者ID) => {
            if(confirm('利用者ID' + 利用者ID + 'を削除しますか。') === false){
                return false;
            }

            const url = '/api/deleteUser';

            axios.delete(url, {data: {id: user_id}})
            .then(res => {
                change(current_page);
            });
        }

        return {
            searchWord,
            sortType,

            current_page,
            last_page,
            total,
            from,
            to,

            userList,
            searchUserList,
            change,

            registerUser,
            editUser,
            deleteUser,

            backHome,
            showPayPerUse,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>
