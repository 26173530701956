<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            4.　財務診断と事前対策計画｜事前対策のための投資計画
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る"  :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="mt-3 row">
                            <div class="col-md-11">
                                <div class="card-group" v-for="(form, formIndex) in valueForms" :key="formIndex" :class="{'mt-3': formIndex > 0}">
                                    <div class="card bg-light-gray">
                                        <div class="card-body">
                                            <div class="row mt-3">
                                                <div class="col-md-5">
                                                    <label for="" class="form-label">対策項目</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="対策項目" placeholder="" v-model="form.measuresItems">
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <label for="" class="form-label">現状の対策レベル</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="現状の対策レベル" placeholder="" v-model="form.currentMeasuresLevel">
                                                    </div>
                                                </div>
                                                <div class="col-md-1" v-if="formIndex === 0">
                                                    <label for="" class="form-label">&emsp;</label>
                                                    <div class="d-flex align-items-center">
                                                        <VDropdown
                                                            theme="hint-theme"
                                                        >
                                                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                            <template #popper>
                                                                <div class="row">
                                                                    <label>'事業継続の能力を高めるための施設や設備の整備に関する投資計画として立案します。<br />「対策項目」は記入例ですので、{{ callReplaceWord('自社') }}の対策項目を加筆修正してください。<br />なお、事前対策のための投資費用は、一般に多額の資金が必要であるため、公的融資制度の積極的な活用が推奨されます。</label>
                                                                </div>
                                                            </template>
                                                        </VDropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-md-5">
                                                    <label for="" class="form-label">対策後のレベル</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="対策後のレベル" placeholder="" v-model="form.afterMeasuresLevel">
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="" class="form-label">必要資金（円）</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="number" min="0" class="form-control text-end" id="必要資金（円）" placeholder="" v-model="form.requiredFund">
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="" class="form-label">資金調達方法</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="資金調達方法" placeholder="" v-model="form.financingMethod">
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="" class="form-label">実施年（予定）</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="number" class="form-control" id="実施年（予定）" placeholder="" v-model="form.planImplementationYear">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1 d-flex align-items-end">
                                        <div class="d-flex flex-column">
                                            <div class="ms-3 mt-3" v-if="valueForms.length > 1">
                                                <input type="button" class="btn btn-sm btn-delete" value="削除" @click="deleteLine(formIndex)">
                                            </div>
                                            <div class="ms-3 mt-3" v-if="formIndex === valueForms.length - 1">
                                                <input type="button" class="btn btn-sm btn-black" value="ワンセット増やす" @click="addLine">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 d-flex flex-row align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜5. 緊急時におけるBCP発動｜発動フロー へ進む" style="font-size:10.5pt"  :disabled="isProcessing" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_investment_plans_info.length > 0) {

                // 閲覧制限
                viewLimit.value = props.bcp_investment_plans_info[0].is_view_limit == 1 ? true : false;

                valueForms.splice(0);
                props.bcp_investment_plans_info.forEach(fetchData =>{
                    valueForms.push({
                        isViewLimit: fetchData.is_view_limit,
                        measuresItems: fetchData.measures_items,
                        currentMeasuresLevel: fetchData.current_measures_level,
                        afterMeasuresLevel: fetchData.after_measures_level,
                        requiredFund: fetchData.required_fund,
                        financingMethod: fetchData.financing_method,
                        planImplementationYear: fetchData.plan_implementation_year,
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const viewLimit = ref(0);

        const placeholders = [
            '事業所の耐震強化（立替／補強）',
            '事業所の不燃化',
            '水害時の浸水防止工事',
            '津波用等の避難施設の整備',
            '土砂災害防止工事',
            '機械等の転倒・落下防止',
            '災害対策用発電機の購入',
            '応急給水設備の整備',
            '災害対策用通信施設の整備',
            '防災倉庫の建築',
        ]

        const valueForms = reactive([]);
        for (let i = 0; i <= 9; i++) {
            valueForms.push({
                isViewLimit: viewLimit.value,
                measuresItems: placeholders[i],
                currentMeasuresLevel: '',
                afterMeasuresLevel: '',
                requiredFund: '',
                financingMethod: '',
                planImplementationYear: '',
            });
        }

        const addLine = () => {
            valueForms.push({
                isViewLimit: viewLimit.value,
                measuresItems: '',
                currentMeasuresLevel: '',
                afterMeasuresLevel: '',
                requiredFund: '',
                financingMethod: '',
                planImplementationYear: '',
            });
        }

        const deleteLine = (index) => {
            if(!confirm((index + 1) + "番目（" + valueForms[index].measuresItems + "）を削除します。よろしいですか？")){
                return false;
            }

            valueForms.splice(index, 1);
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // 投資計画を登録
            const result = await registerFirst8();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 投資計画を登録
            const result = await registerFirst8();

            if (result == '200') {
                const url = '/bcp/bcp_first/9';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 投資計画を登録
            const result = await registerFirst8();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 投資計画登録
        const registerFirst8 = async () => {
            // リクエスト内容生成
            for (var i = valueForms.length - 1; i >= 0; i--) {
                // 閲覧制限
                valueForms[i].isViewLimit = viewLimit.value;
                if (props.bcp_investment_plans_info.length == 0
                    && valueForms[i].measuresItems === '' && placeholders[i] != undefined && placeholders[i] != '') {
                    // データ未登録で、かつ未入力でプレースホルダーの値がある場合は、プレースホルダーの値をセット
                    valueForms[i].measuresItems = placeholders[i];
                }
            };

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst8';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'form_list': valueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        // 数値桁数制限
        watch(() => valueForms,
            (next, prev) => {
                next.forEach(data => {
                    if (data.planImplementationYear) {
                        data.planImplementationYear = data.planImplementationYear.toString().length > 4
                            ? data.planImplementationYear.toString().slice(0, -1)
                            : data.planImplementationYear;
                    }
                });
            },
            {
                deep: true
            }
        );

        return {
            setLevel,
            callReplaceWord,
            isProcessing,
            viewLimit,
            valueForms,
            placeholders,
            addLine,
            deleteLine,
            showBack,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_investment_plans_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
}

.bg-gray {
    background-color: #a6a6a6;
}

.border-orange{
    border-style: solid;
    border-width: 1px;
    border-color: #FF6600;
}

.bg-header {
    background-color: #F2F2F2;

}

</style>
