<template>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                訓練実施記録の閲覧
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end me-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>


    <div class="row mx-md-3 mt-3 fs-half">
        <div class="col-12">
            <table class="table" id="training_implementation_table">
            <thead>
                <tr>
                    <td>実施日</td>
                    <td>訓練シナリオの名称・テーマ</td>
                    <td>訓練の方法</td>
                    <td>責任者</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ this.$root.dayjs(training_implementation.implementation_date).format('YYYY/MM/DD') }}</td>
                    <td>{{ training_implementation.training_name }}</td>
                    <td>{{ returnTrainingMethod(training_implementation.training_method, training_implementation.training_method_other) }}</td>
                    <td>{{ training_implementation.manager }}</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="col-12">
            <div class="col-md border-title ps-2 bg-title py-2">
                訓練参加者
            </div>
        </div>
        <div class="col-12">
            <div class="row mx-auto">
                <template v-for="(participant, index) in training_participants" :key="participant.id">
                    <div class="col-2 border-title py-2 ps-2">
                        {{ participant.participant }}
                    </div>
                </template>
            </div>
        </div>
        <div class="col-12 mt-5 ">
            <table class="table table-bordered border-dark" id="table_2">
                <colgroup>
                    <tr>
                        <td style="width: 50%;"></td>
                    </tr>
                </colgroup>
                <tbody>
                    <tr>
                        <td class="bg-light-gray" colspan="3" style="width: 50%;">災害想定</td>
                        <td class="bg-light-gray">発生曜日</td>
                        <td class="bg-light-gray">発生時間</td>
                        <td class="bg-light-gray">経過想定日数</td>
                        <td class="bg-light-gray">被害場所</td>
                    </tr>
                    <tr>
                        <td class="bg-disaster" colspan="3">
                            {{ training_implementation.disaster_assumption }}
                        </td>
                        <td>
                            {{ calcWeekDay(training_implementation.occurrence_weekday) }}
                        </td>
                        <td>
                            {{ training_implementation.occurrence_time ? training_implementation.occurrence_time.slice(0, -3) : '' }}
                        </td>
                        <td>
                            {{ calcAssumptionDays(training_implementation.progress_assumption_days) }}
                        </td>
                        <td>
                            {{ training_implementation.damage }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="3">影響を受ける中核事業</td>
                        <td class="bg-light-gray" colspan="4">訓練終了後に気づいた課題・BCP見直しのポイントなど（自由記述）</td>
                    </tr>
                    <tr>
                        <td class="bg-blue" colspan="3">
                            {{ training_implementation.core_business }}
                        </td>
                        <td class="bg-white" colspan="4" rowspan="12">
                            <textarea type="text" class="form-control fs-half" style="height: 30em;" placeholder="" v-model="input_free_description" :readonly="!is('administrator')"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" id="test_id" colspan="3">影響を受ける重要業務</td>
                    </tr>
                    <tr>
                        <td class="bg-orange" colspan="3">
                            {{ training_implementation.important_business }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="3">経過想定日数時の災害イメージ</td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">電気</td>
                        <td class="disaster-image">
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.electricity)">
                                {{ availableStatusList.find(data => data.id == training_implementation.electricity).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">通信（固定電話、携帯電話、インターネット、社内イントラネット等）</td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.communication)">
                                {{ availableStatusList.find(data => data.id == training_implementation.communication).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">ガス</td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.gas)">
                                {{ availableStatusList.find(data => data.id == training_implementation.gas).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">上下水道</td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.waterworks)">
                                {{ availableStatusList.find(data => data.id == training_implementation.waterworks).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">交通機関（高速道路、国道、鉄道等）</td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.traffic_method)">
                                {{ availableStatusList.find(data => data.id == training_implementation.traffic_method).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">小売業（金融機関、スーパー等）</td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.retail_business)">
                                {{ availableStatusList.find(data => data.id == training_implementation.retail_business).availableStatus }}
                            </template>
                       </td>
                    </tr>
                    <tr>
                        <td class="bg-header" colspan="2">地域住民等</td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.regional_resident)">
                                {{ availableStatusList.find(data => data.id == training_implementation.regional_resident).availableStatus }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-header">その他</td>
                        <td class="other-image">
                            {{ training_implementation.other_input_value }}
                        </td>
                        <td>
                            <template v-if="availableStatusList.find(data => data.id == training_implementation.other)">
                                {{ availableStatusList.find(data => data.id == training_implementation.other).availableStatus }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    


    <div class="row mx-3 mt-5">
        <div class="d-flex">
            <input type="button" class="btn btn-gray me-auto" value="文化定着［訓練＆教育］TOP へ戻る" @click="showTop" :disabled="isProcessing">
            <input type="button" class="btn btn-black me-3 px-4" value="修正" @click="updateDetail" :disabled="isProcessing || !is('administrator')">
            <input type="button" class="btn btn-delete px-4" value="削除" @click="deleteDetail" :disabled="isProcessing || !is('administrator')">
        </div>
        <div class="d-flex justify-content-end">
            <div class=" col-md-3 mx-3 flash alert mt-2" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
        </div>
    </div>

</template>

<script>
import { inject, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
import axios from 'axios';
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject("dayjs");

        onMounted(() => {
            input_free_description.value = props.training_implementation.free_description;
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            // 前のページへ遷移
            history.back();
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        const returnTrainingMethod = (code, other) => {
            switch(code){
                case '1':
                    return '机上訓練（模擬災害対策訓練）';
                case '2':
                    return 'バックアップデータを取り出す訓練';
                case '3':
                    return '机上訓練（ワークショップ訓練）';
                case '4':
                    return 'BCP手順確認訓練';
                case '5':
                    return '机上訓練（ロールプレイング訓練）';
                case '6':
                    return 'BCP全体を通して行う総合訓練';
                case '7':
                    return '安否確認システム操作訓練・緊急時通報診断';
                case '9':
                    return 'その他:' + other;
                default:
                    return '代替施設への移動訓練';
            }
        }

        const calcWeekDay = (weekday) => {
            const findWeekDay = weekdayList.find(item => {
                return item.id == weekday;
            });

            if(findWeekDay){
                return findWeekDay.weekday;
            }else{
                return "";
            }
        }

        const calcAssumptionDays = (code) => {
            const assumptionDay = assumptionDaysList.find(item => {
                return item.id == code;
            });

            if(assumptionDay){
                return assumptionDay.assumptionDays;
            }else{
                return "";
            }
        }

        // 曜日
        const weekdayList = [
            {id: 0, weekday: '日曜日'},
            {id: 1, weekday: '月曜日'},
            {id: 2, weekday: '火曜日'},
            {id: 3, weekday: '水曜日'},
            {id: 4, weekday: '木曜日'},
            {id: 5, weekday: '金曜日'},
            {id: 6, weekday: '土曜日'},
        ]

        // 経過想定日数
        const assumptionDaysList = [
            {id: 1, assumptionDays: '発災初日'},
            {id: 2, assumptionDays: '発災後4～5日目'},
        ]

        // 利用可能状況
        const availableStatusList = [
            {id: 1, availableStatus: '使える＝◯'},
            {id: 0, availableStatus: '使えない＝✕'},
        ]

        const showDetail = (id) => {
            const url = '/fixation/showTrainingRecordDetail/' + id;

            location.href = url;
        }

        const input_free_description = ref("");
        const isProcessing = ref(false);

        const updateDetail = () => {
            const url = "/api/fixation/updateTrainingFreeDescription";

            isProcessing.value = true;

            axios.post(url, {
                id: props.training_implementation.implementation_record_id,
                free_description: input_free_description.value,
            }).then(res=>{
                visibleFlashMessage('正常に更新されました', 'success');

            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const deleteDetail = () => {
            const url = "/api/fixation/deleteTrainingDetail";

            const message = "実施日" + dayjs(props.training_implementation.implementation_date).format('YYYY/MM/DD') + "の訓練実施記録を削除しますか？";
            if(!confirm(message)){
                return;
            }

            isProcessing.value = true;

            axios.delete(url, {data: {id: props.training_implementation.implementation_record_id}})
            .then(res=>{
                const back_url = "/fixation/showTrainingRecord";
                location.href = back_url;
            }).finally(fin => {
                isProcessing.value = false;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,
            showTop,
            returnTrainingMethod,
            showDetail,
            assumptionDaysList,
            availableStatusList,

            calcWeekDay,
            weekdayList,

            calcAssumptionDays,

            input_free_description,
            isProcessing,
            updateDetail,
            deleteDetail,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        training_implementation: {
            type: Object,
        },
        training_participants: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

#training_implementation_table {
    margin-bottom: 0px !important;
}

#training_implementation_table thead tr{
    background-color: #d8d8d8;
}

#training_implementation_table thead tr td,
#training_implementation_table tbody tr td{
    border: 1px solid common_colors.colors(resilience-gray);
}

.btn-resilience-light-blue {
    background-color: common_colors.colors(resilience-light-blue);
    color: white;
    border: 1px solid common_colors.colors(resilience-light-blue);
}

.btn-resilience-light-blue:hover {
    background-color: white;
    color: common_colors.colors(resilience-light-blue);
    border: 1px solid common_colors.colors(resilience-light-blue);
}

.border-title {
    border: thin solid #7f7f7f;
}

.bg-title {
    background-color: #d8d8d8;;
}

.disaster-image {
    width: 120px;
}

.other-image {
    // width: 50%;
    width: 400px;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
    height: 39.5px;
}
.bg-blue {
    background-color: #4472c4;
    color: white;
    height: 39.5px;
}

.bg-orange {
  background-color: #FF6600;
  height: 39.5px;
}

.bg-header {
    background-color: #F2F2F2;
    height: 39.5px;
}

@media screen and (max-width: 756px){
    .fs-half {
        font-size: 0.5rem;
    }

    .btn-responsive {
        font-size: 0.5em;
    }

    .other-image {
    // width: 50%;
    width: 100px;
}

}

</style>
