<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">企業・団体情報登録</h4>
                <form @submit.prevent="post_form">
                    <div class="row mt-3">
                        <div class="col col-4">
                            <label for="name">会社名</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                            <input type="text" class="form-control" aria-label="名称" v-model="name" required maxlength="100" />
                        </div>
                        <div class="col col-4">
                            <label for="Representatives_name">代表者名</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                            <input type="text" class="form-control" aria-label="代表者名" v-model="representatives_name" required maxlength="100" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col col-2">
                            <label for="zip">郵便番号</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                            <input type="text" class="form-control" aria-label="郵便番号" v-model="zip" required maxlength="7" @blur="onZipChange($event)" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col col-2">
                            <label for="prefecture">都道府県</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                            <input type="text" class="form-control" aria-label="都道府県" v-model="prefecture" maxlength="10" />
                        </div>
                        <div class="col col-2">
                            <label for="city">市区町村</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                            <input type="text" class="form-control" aria-label="市区町村" v-model="city" maxlength="50" />
                        </div>
                        <div class="col col-4">
                            <label for="town">町名番地</label><span class="badge rounded-pill bg-danger ms-2">必須</span>
                            <input type="text" class="form-control" aria-label="町名番地" v-model="town" maxlength="255" />
                        </div>
                        <div class="col col-4">
                            <label for="building">建物名</label>
                            <input type="text" class="form-control" aria-label="建物名" v-model="building" maxlength="255" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col col-4">
                            <label for="tel">TEL</label>
                            <input type="text" class="form-control" aria-label="TEL" v-model="tel" maxlength="20" />
                        </div>
                        <div class="col col-4">
                            <label for="fax">FAX</label>
                            <input type="text" class="form-control" aria-label="FAX" v-model="fax" maxlength="20" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-1">
                            <button type="submit" class="btn btn-primary">更新</button>
                        </div>
                        <div class="col-10">
                        </div>
                        <div class="col-1">
                            <input type="button" class="btn btn-secondary" value="閉じる" onclick="window.close()" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <notifications position="top left" width="500px" />
</template>

<script>
import axios from 'axios';

export default({
    setup() {

    },
    data() {
        return {
            id: 0,
            name: "",
            representatives_name:"",
            zip: "",
            prefecture: "",
            city: "",
            town: "",
            building: "",
            tel: "",
            fax: "",
        }
    },
    props: [
        'corp_info',
    ],
    mounted() {
        this.id = this.corp_info.id;
        this.name = this.corp_info.name;
        this.representatives_name = this.corp_info.representative;
        this.zip = this.corp_info.zip;
        this.prefecture = this.corp_info.pref;
        this.city = this.corp_info.city;
        this.town = this.corp_info.town;
        this.building = this.corp_info.building;
        this.tel = this.corp_info.tel;
        this.fax = this.corp_info.fax;
    },
    methods: {
        onZipChange(e) {
            let self = this;
            const url = "/api/getAddressSeparate?zip=" + e.target.value;
            axios.get(url).then(function(response){
                if(response.status === 200) {
                    self.prefecture = response.data.prefecture;
                    self.city = response.data.municipalitie;
                    self.town = response.data.town;
                }
            });
            self.zip = e.target.value;
        },
        post_form(){
            const url = "/api/updateCorpInfo/" + this.id;
            axios.post(url,{
                "name": this.name,
                "zip": this.zip,
                "pref": this.prefecture,
                "city": this.city,
                "town": this.town,
                "building": this.building,
                "representative": this.representatives_name,
                "tel": this.tel,
                "fax": this.fax,
            }).then(res => {
                this.$notify({ type: "success", text: this.name + "を更新しました。" });
            });
        },
    },
})
</script>

