<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:12pt">
            4. 財務診断と事前対策計画｜キャッシュフロー対策の考え方・事前対策
        </div>

        
        <div class="col-md-4 d-flex justify-content-evenly">
            <h5 class="align-self-end"><span class="badge bg-resilience-green text-light">閲覧・出力なし</span></h5>
            <h4 class="align-self-end"><span class="badge bg-badge-yellow">製造業</span></h4>
            <h5 class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る"></h5>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

<!--------------------------------------キャッシュフロー対策の考え方---------------------------------------------------------------->

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md-4 bg-title">
            <label for="" class="form-label">キャッシュフロー対策の考え方</label>
        </div>
    </div>

    <div class="row mt-3 ms-3 ">
        <div class="p2 col-md-1 bg-light-gray d-flex align-items-center justify-content-center">
            <label for="" class="form-label">1</label>
        </div>
        <div class="p2 col-md-2 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label font-orange">現金・預金の保有</label>
        </div>
        <div class="p2 col-md-8 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label">災害発生後、不測の出費に備え月の{{ callReplaceWord('売上') }}の1ヶ月分くらいの現金・預金を保有していることをお薦めします。現金・預金が不足の場合は、小企業は小規模企業共済制度の災害時貸付制度・日本政策金融公庫の利用をお薦めします。</label>
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div>
                        <label>小規模企業共済 - 中小機構</label>
                    </div>
                    <div>
                        <a href="https://www.smrj.go.jp/kyosai/skyosai/" target="_blank">https://www.smrj.go.jp/kyosai/skyosai/</a>
                    </div>
                    <div>
                        <label>日本金融政策公庫</label>
                    </div>
                    <div>
                        <a href="https://www.jfc.go.jp/" target="_blank">https://www.jfc.go.jp/</a>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-3 ms-3 ">
        <div class="p2 col-md-1 bg-light-gray d-flex align-items-center justify-content-center">
            <label for="" class="form-label">2</label>
        </div>
        <div class="p2 col-md-2 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label font-orange">特別相談窓口</label>
        </div>
        <div class="p2 col-md-8 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label">借入をしてでも事業継続を図る意欲がある場合は財務面の検討結果に多少の問題があっても、検討結果を持って「特別相談窓口」に相談に行くことをお薦めします。日本政策金融公庫・商工組合中央金庫・信用保証協会（含むセーフティネット保証制度）の各所に窓口があります。</label>
        </div>
    </div>

    <div class="row mt-3 ms-3 ">
        <div class="p2 col-md-1 bg-light-gray d-flex align-items-center justify-content-center">
            <label for="" class="form-label">3</label>
        </div>
        <div class="p2 col-md-2 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label font-orange">災害復旧貸付制度</label>
        </div>
        <div class="p2 col-md-8 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label">日本政策金融公庫の災害復旧貸付制度など中期的な視点で考える制度もあります。地震、台風、豪雪や大規模な火災などの災害を受けた中小企業者の事業の復旧を促進し、被災地域の復興を支援します。</label>
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <div>
                            <label>日本政策金融公庫 災害復旧貸付</label>
                        </div>
                        <div>
                            <a href="https://www.jfc.go.jp/n/finance/search/saigai.html">https://www.jfc.go.jp/n/finance/search/saigai.html</a>
                        </div>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-3 ms-3 ">
        <div class="p2 col-md-1 bg-light-gray d-flex align-items-center justify-content-center">
            <label for="" class="form-label">4</label>
        </div>
        <div class="p2 col-md-2 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label font-orange">
                返済原資の増加<br>
                （収益体質の改善）
            </label>
        </div>
        <div class="p2 col-md-8 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label">災害発生後1～2年間のキャッシュフローを考えておく必要があります。災害復旧資金を借入れた場合、2年据置き後3年目から返済が始まるため、返済原資の増加にあたり、あなたの{{ callReplaceWord('組織') }}の収益体質の改善を図ることが必要です。また、何ヶ月目に資金が不足するのかを予想しましょう。新規借入・資産売却・経営者から支援など資金不足の対策をどのタイミングで行うか、考えておきます。</label>
        </div>
    </div>

    <div class="row mt-3 ms-3 ">
        <div class="p2 col-md-1 bg-light-gray d-flex align-items-center justify-content-center">
            <label for="" class="form-label">5</label>
        </div>
        <div class="p2 col-md-2 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label font-orange">事前対策の必要性</label>
        </div>
        <div class="p2 col-md-8 bg-light-gray d-flex align-items-center justify-content-start">
            <label for="" class="form-label">事前に対策を講じておけば、災害時の「設備の復旧費用（A）＋事業中断によるキヤッシュフローの悪化額（B）」即ち「復旧費用総額（C）」は間違いなく減少します。</label>
        </div>
    </div>

<!--------------------------------------○ キャッシュフロー対策（6ヶ月後まで）---------------------------------------------------------------->

    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">○ キャッシュフロー対策（6ヶ月後まで）</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-10 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            科目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            1ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            2ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            3ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            4ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            5ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            6ヶ月目
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>算出された資金収支が赤字の場合、どのようにキャッシュフロー対策をとるべきか、「キャッシュフロー対策」の水色の欄に考えられる対策の金額を記入し、総合資金収支を確認して下さい。なお、各対策の金額の12ヶ月間の合計が、「復旧費用の調達」（前ページ）で求めた調達可能金額を上回らないよう注意してください。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            現金・預金取り崩し
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            新規借入
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            損害保険金
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            会社資産売却
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            経営者から支援
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            総合　月次資金収支
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            総合　累計資金収支
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>

<!--------------------------------------○ キャッシュフロー対策（7ヶ月後以降）---------------------------------------------------------------->

    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">○ キャッシュフロー対策（7ヶ月後以降）</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-10 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            科目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            7ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            8ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            9ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            10ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            11ヶ月目
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            12ヶ月目
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>算出された資金収支が赤字の場合、どのようにキャッシュフロー対策をとるべきか、「キャッシュフロー対策」の水色の欄に考えられる対策の金額を記入し、総合資金収支を確認して下さい。なお、各対策の金額の12ヶ月間の合計が、「復旧費用の調達」（前ページ）で求めた調達可能金額を上回らないよう注意してください。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            現金・預金取り崩し
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            新規借入
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            損害保険金
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            会社資産売却
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            経営者から支援
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            総合　月次資金収支
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            総合　累計資金収支
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            &emsp;
        </div>
    </div>


    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-10 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            調達可能金額の使用状況
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            12ヶ月間使用額
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            調達可能金額合計
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            使用可能残額
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            現金・預金取り崩し
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            新規借入
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            損害保険金
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>

    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            会社資産売却
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            経営者から支援
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md text-center border border-dark bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

<!--------------------------------------事前対策の考え方---------------------------------------------------------------->

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md-4 d-flex bg-title align-items-center justify-content-start ">
            <label for="" class="form-label">事前対策の考え方</label>
        </div>
    </div>

    <div class="row ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-10 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            必要資金の金額
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-start-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            調達可能金額
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-start-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
            備考
        </div>
        <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>事前対策をする場合は、資金調達の形が下表のように変わります。対策費用を自己資金か借入かで、別途の検討が必要になります。事前対策を自己資金で毎期着実に実行していくことが望ましいのですが、予期せぬ緊急事態発生時には、「災害防止対策資金」の借入を検討することが必要になります。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            災害防止対策費用（F）
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            手許現金・預金
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            0
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-top-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>災害防止対策費用（F）を入力することにより、資産の復旧費用や事業中断によるキャッシュフローの悪化額は、「復旧費用総額（C）の算出」（前ページ）で算定した額よりも大幅に減る可能性がありますので、当該入力欄の値も参考にしながら入力してください。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-top-0 border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="" placeholder="0">
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            損害保険金
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            1
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            復旧資金
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            会社資産売却
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            2
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            資産の復旧費用（A）
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            （会社調達分）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            2
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            事業中断による
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            経営者から支援
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            0
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-top-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            キャッシュフローの悪化額（B）
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-top-0 border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="" placeholder="0">
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            災害防止対策資金借入金額（G）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="" placeholder="1">
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            （A＋B＝C）
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            災害時新規借入金額（E）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="" placeholder="2">
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-3 text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            計（F＋C＝H）
        </div>
        <div class="p2 col-md-2 text-center border border-dark border-end-0 bg-white d-flex align-items-center justify-content-end">
            &emsp;
        </div>
        <div class="p2 col-md-3 text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            計
        </div>
        <div class="p2 col-md-2 border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-end">
            5
        </div>
        <div class="p2 col-md-1 text-center border border-dark bg-white d-flex align-items-center justify-content-start">
            <input type="text" class="form-control border-white text-end" id="" placeholder="">
        </div>
    </div>

<!--------------------------------------付録---------------------------------------------------------------->

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md-1 border d-flex align-items-center justify-content-center ">
            <label for="" class="form-label">付録</label>
        </div>
        <div class="p2 col-md-2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">用語の記号　</label>
        </div>
    </div>

    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">資産の復旧費用</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（A）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">事業中断によるキャッシュフローの悪化額</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（B）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">復旧費用総額　</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（C）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">手元資金</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（D1）自力で賄う場合</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">調達可能金額</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（D2）借入を要する場合</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">新規借入金額</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（E）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">新規借入可能金額　</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（e）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">災害防止対策費用</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（F）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">災害防止対策資金借入金額</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（G）</label>
        </div>
    </div>
    <div class="row mt-3 ms-5 ">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">災害対策の必要資金総額（事前対策費用を含む）</label>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">（H）</label>
        </div>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-10 btn btn-bcp-middle" value="4. 財務診断と事前対策計画｜保険情報リスト へ" @click="showNext">
            </div>
        </div>
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-4 btn btn-bcp-basic" value="目次構成 へ戻る">
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');

        const showNext = () => {
            const url = '/bcp/bcp_financial/18';

            location.href = url;
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        return {
            setLevel,

            showNext,

            callReplaceWord,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-yellow {
    background-color: #ffd966;
    border: 1px solid #404040;
    color: #404040;
}

.bg-funds {
    background-color: #009900;
    color: white;
}

.bg-title {
    background: #fce4d6;
}

.font-orange {
    color: #ff6600;
}
</style>