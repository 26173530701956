<template>
    <div class="row">
        <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
            <label for="" class="form-label">マッチング交渉結果［{{ 送受区分 }}専用］</label>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="text">
            <label class="text-primary" style="font-size:16pt">{{ matching_negotiation.組織名 }} 様</label>
            <label class="ms-3" style="font-size:12pt" v-show="mode === '101'">から受け取られたB&B災害協定ビジネスマッチング交渉の結果についてお聞かせください。</label>
            <label class="ms-3" style="font-size:12pt" v-show="mode === '201'">に対するB&B災害協定ビジネスマッチング交渉の結果についてお聞かせください。</label>
        </div>
    </div>

    <form @submit.prevent="post_form">
        <div class="row ms-3 mt-5">
            <div class="col-md-10">
                <div class="form-check form-check-inline mx-3" v-for="item in result_list" :key="item.code">
                    <input class="form-check-input" type="radio" :id="'result_' + item.code" :value="item.code" v-model="result" required>
                    <label class="form-check-label" :for="'result_' + item.code">{{ item.name }}</label>
                </div>
            </div>
        </div>

        <div class="row mt-5 ms-3">
            <div class="text">
                <label class="col-md">
                    TOTONO-L事務局への連絡事項
                </label>
            </div>
        </div>

        <div class="row mt-3 ms-3">
            <div class="col-md-10">
                <textarea type="text" rows="10" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="contact"></textarea>
            </div>
        </div>

        <div class="row ms-1 mt-5 d-flex felx-row">
            <div class="col-md">
                <div class="d-flex align-items-center justify-content-start flex-row">
                    <input type="submit" class="col-md-4 text-center btn btn btn-bnb-blue" value="マッチング交渉結果をTOTONO-Lへ送ります" :disabled="isProcessing">
                </div>
                <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import axios from 'axios';
import { computed, onMounted, ref } from 'vue'

export default{
    setup(props) {
        onMounted(() => {
            if(props.matching_negotiation.結果){
                result.value = props.matching_negotiation.結果;
            }
            if(props.matching_negotiation.メッセージ){
                contact.value = props.matching_negotiation.メッセージ;
            }
        })

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const 送受区分 = computed(() => {
            if(props.mode === "101"){
                return '受信者';
            }else{
                return '送信者';
            }
        });

        const result = ref(0);
        const contact = ref('');
        const isProcessing = ref(false);

        const post_form = () => {
            if(result.value == 0){
                alert('結果を選択してください');
                return
            }
            const url = '/api/bnb/postAnswer';

            isProcessing.value = true;
            axios.post(url, {
                negotiation_day: props.matching_negotiation.negotiation_day,
                seq: props.matching_negotiation.seq,
                mode: props.mode,
                result: result.value,
                contact: contact.value,
            }).then(res => {
                visibleFlashMessage('正常に登録されました', 'success');
            }).finally(fin => {
                isProcessing.value = false;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');
        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false;
            }, 3000)
        }

        return {
            送受区分,
            result,
            contact,
            post_form,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            isProcessing,
        }
    },
    props: {
        matching_negotiation: {
            type: Object,
        },
        result_list: {
            type: Array,
        },
        mode: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
#notice_area {
    overflow: scroll;
    height: 21em;
}

.border-notice {
    border-bottom: 3px solid #7f7f7f;
}

</style>