<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            4.　財務診断と事前対策計画｜復旧費用の算定
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-middle btn-sm" value="中級｜4. 財務診断と事前対策計画 へ進む" v-show="is_completed_basic" :disabled="isProcessing" @click="showMiddle">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="card-title">
                            <label class="d-flex align-items-center justify-content-start">
                                &emsp;
                            </label>
                        </div>

<!--------------------------------------建物全壊時---------------------------------------------------------------->

                        <div class="row ms-1 d-flex flex-row">
                            <div class="col-md-3 d-flex flex-row border bg-gray text-white">
                                建物全壊時
                            </div>
                            <div class="col-md-2 d-flex flex-row border bg-light text-dark">
                                復旧時間（日）
                            </div>
                            <div class="col-md-2 d-flex flex-row border bg-light text-dark">
                                復旧費用（円）
                            </div>
                            <div class="col-md-1 d-flex flex-row border bg-light text-dark">
                                &emsp;
                            </div>
                            <div class="col-md-3 d-flex flex-row border bg-light text-dark">
                                備考
                            </div>
                            <div class="col-md-1 d-flex align-items-center justify-content-center">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>必要な金額を確認し例示を修正又不要な箇所は削除してください。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>

                        <div v-for="(form1, form1Index) in valueFormsField1" :key="form1Index">
                            <div class="row ms-1 d-flex flex-row">
                                <div class="col-md-3 d-flex align-items-center flex-row border bg-light text-dark">
                                    <span v-if="form1Index === 0">建物</span>
                                    <span v-else-if="form1Index === 1">機械</span>
                                    <span v-else-if="form1Index === 2">{{ callReplaceWord('棚卸資産') }}</span>
                                    <span v-else-if="form1Index === 3">器具・工具等</span>
                                    <span v-else-if="form1Index === 4">資産関係 計</span>
                                    <span v-else-if="form1Index === 5">{{ callReplaceWord('事業') }}中断損失</span>
                                    <span v-else>復旧期間・費用推定値</span>
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-white text-dark " v-if="form1Index <= 3">
                                    <!-- <input type="number" min="0" class="form-control bg-white text-end" id="" :placeholder="form1.values[0].placeholder" v-model="form1.values[0].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form1.values[0].placeholder" v-model="form1.values[0].value"></commaInput>
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form1Index <= 3">
                                    <!-- <input type="number" min="0" class="form-control bg-white text-end" id="" :placeholder="form1.values[1].placeholder" v-model="form1.values[1].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form1.values[1].placeholder" v-model="form1.values[1].value"></commaInput>
                                </div>
                                <div class="col-md-1 d-flex flex-row border bg-light text-dark" v-if="form1Index <= 3">
                                    &emsp;
                                </div>
                                <div class="col-md-3 d-flex flex-row border bg-white text-dark" v-if="form1Index <= 3">
                                    <input type="text" class="form-control bg-white" id="" :placeholder="form1.values[2].placeholder" v-model="form1.values[2].value">
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-white text-dark " v-if="form1Index === 4">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ maxField1RecoveryTime() }}
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form1Index === 4">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ sumField1RecoveryCost().toLocaleString() }}
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex flex-row border bg-light text-dark" v-if="form1Index === 4">
                                    （A）
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-light text-dark " v-if="form1Index === 5">
                                    &emsp;
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form1Index === 5">
                                    <!-- <input type="text" class="form-control bg-white text-end" id="" :placeholder="form1.values[1].placeholder" v-model="form1.values[1].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form1.values[1].placeholder" v-model="form1.values[1].value"></commaInput>
                                </div>
                                <div class="col-md-1 d-flex flex-row border bg-light text-dark" v-if="form1Index === 5">
                                    （B）
                                </div>
                                <div class="col-md-3 d-flex flex-row border bg-white text-dark" v-if="form1Index === 5">
                                    &emsp;
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-white text-dark " v-if="form1Index === 6">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ maxField1RecoveryTime() }}
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form1Index === 6">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ totalField1Recovery().toLocaleString() }}
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex flex-row border bg-light text-dark" v-if="form1Index === 6">
                                    （A）＋（B）＝（C）
                                </div>

                                <div class="col-md-1 d-flex align-items-center justify-content-center" v-if="form1Index === 0">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                        <template #popper>
                                            <div class="row">
                                                <label>復旧費用とは、災害時にあなたの{{ callReplaceWord('組織') }}の資産（建物や機械）が損壊し、「資産の損害」が生じたとき、立て直す費用と、災害の結果あなたの{{ callReplaceWord('組織') }}の{{ callReplaceWord('事業') }}がストップし、その間「{{ callReplaceWord('事業') }}中断による損賠」に備えて、{{ callReplaceWord('売上') }}1ヶ月分くらいの現金・預金を用意していることをお勧めします。緊急時に備え、平時から「{{ callReplaceWord('売上') }}1ヶ月分くらいの資金」を用意しておくのは、流動性リスクに対する経験則です。緊急事態発生直後は、{{ callReplaceWord('工場') }}の整備、{{ callReplaceWord('事業') }}再開への対策等で資金の手当てを考える暇はありません。また当面{{ callReplaceWord('事業') }}がストップすることを覚悟しなければなりません。そのために最低1ヶ月くらいの出費を賄えるだけの資金を持っていることが必要となります。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                        </div>

<!--------------------------------------建物半壊時---------------------------------------------------------------->
                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="col-md-3 d-flex flex-row border bg-gray text-white">
                                建物半壊時
                            </div>
                            <div class="col-md-2 d-flex flex-row border bg-light text-dark">
                                復旧時間（日）
                            </div>
                            <div class="col-md-2 d-flex flex-row border bg-light text-dark">
                                復旧費用（円）
                            </div>
                            <div class="col-md-1 d-flex flex-row border bg-light text-dark">
                                &emsp;
                            </div>
                            <div class="col-md-3 d-flex flex-row border bg-light text-dark">
                                備考
                            </div>
                        </div>

                        <div v-for="(form2, form2Index) in valueFormsField2" :key="form2Index">
                            <div class="row ms-1 d-flex flex-row">
                                <div class="col-md-3 d-flex align-items-center flex-row border bg-light text-dark">
                                    <span v-if="form2Index === 0">建物</span>
                                    <span v-else-if="form2Index === 1">機械</span>
                                    <span v-else-if="form2Index === 2">{{ callReplaceWord('棚卸資産') }}</span>
                                    <span v-else-if="form2Index === 3">器具・工具等</span>
                                    <span v-else-if="form2Index === 4">資産関係 計</span>
                                    <span v-else-if="form2Index === 5">{{ callReplaceWord('事業') }}中断損失</span>
                                    <span v-else>復旧期間・費用推定値</span>
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-white text-dark " v-if="form2Index <= 3">
                                    <!-- <input type="number" min="0" class="form-control bg-white text-end" id="" :placeholder="form2.values[0].placeholder" v-model="form2.values[0].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form2.values[0].placeholder" v-model="form2.values[0].value"></commaInput>
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form2Index <= 3">
                                    <!-- <input type="number" min="0" class="form-control bg-white text-end" id="" :placeholder="form2.values[1].placeholder" v-model="form2.values[1].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form2.values[1].placeholder" v-model="form2.values[1].value"></commaInput>
                                </div>
                                <div class="col-md-1 d-flex flex-row border bg-light text-dark" v-if="form2Index <= 3">
                                    &emsp;
                                </div>
                                <div class="col-md-3 d-flex flex-row border bg-white text-dark" v-if="form2Index <= 3">
                                    <input type="text" class="form-control bg-white" id="" :placeholder="form2.values[2].placeholder" v-model="form2.values[2].value">
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-white text-dark " v-if="form2Index === 4">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ maxField2RecoveryTime() }}
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form2Index === 4">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ sumField2RecoveryCost().toLocaleString() }}
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex flex-row border bg-light text-dark" v-if="form2Index === 4">
                                    （A）
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-light text-dark " v-if="form2Index === 5">
                                    &emsp;
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form2Index === 5">
                                    <!-- <input type="text" class="form-control bg-white text-end" id="" :placeholder="form2.values[1].placeholder" v-model="form2.values[1].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form2.values[1].placeholder" v-model="form2.values[1].value"></commaInput>
                                </div>
                                <div class="col-md-1 d-flex flex-row border bg-light text-dark" v-if="form2Index === 5">
                                    （B）
                                </div>
                                <div class="col-md-3 d-flex flex-row border bg-white text-dark" v-if="form2Index === 5">
                                    &emsp;
                                </div>

                                <div class="col-md-2 d-flex flex-row border bg-white text-dark " v-if="form2Index === 6">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ maxField2RecoveryTime() }}
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex flex-row border bg-white text-dark" v-if="form2Index === 6">
                                    <div class="form-control bg-light-gray text-end" id="" >
                                        {{ totalField2Recovery().toLocaleString() }}
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex flex-row border bg-light text-dark" v-if="form2Index === 6">
                                    （A）＋（B）＝（C）
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-12 d-flex flex-row align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜4.　財務診断と事前対策計画｜緊急時に使える資金・財務診断とキャッシュフロー  へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted} from 'vue';
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import commaInput from "../../common/CommaInputComponent.vue";
import ReplaceWord from '../../../function/CommonReplaceWord';
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
        commaInput,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_values_info.length > 0) {
                props.bcp_values_info.forEach(fetchData =>{
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        viewLimit.value = fetchData.value == 1 ? true : false;
                    }
                    // フィールド１
                    valueFormsField1.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                    // フィールド２
                    valueFormsField2.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('010000');
        const isProcessing = ref(false);
        const pageName = 'F5';
        const setDefaultFieldNumber = 1;
        const viewLimit = ref(0);

        // フィールド１
        const valueFormsField1 = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00081',
                values: [
                    {value: '', placeholder: '60'},
                    {value: '', placeholder: '20,000,000'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00082',
                values: [
                    {value: '', placeholder: '30'},
                    {value: '', placeholder: '1,000,000'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00083',
                values: [
                    {value: '', placeholder: '15'},
                    {value: '', placeholder: '1,000,000'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00084',
                values: [
                    {value: '', placeholder: '20'},
                    {value: '', placeholder: '500,000'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00085',
                values: [
                    {value: '', placeholder: '60'},
                    {value: '', placeholder: '22,500,000'},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00086',
                values: [
                    {value: '', placeholder: ''},
                    {value: '', placeholder: '5,000,000'},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00087',
                values: [
                    {value: '', placeholder: '60'},
                    {value: '', placeholder: '27,500,000'},
                ]
            },
        ]);

         // フィールド２
         const valueFormsField2 = reactive([
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00088',
                values: [
                    {value: '', placeholder: '30'},
                    {value: '', placeholder: '10,000,000'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00089',
                values: [
                    {value: '', placeholder: '0'},
                    {value: '', placeholder: '5,000,000'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00090',
                values: [
                    {value: '', placeholder: '0'},
                    {value: '', placeholder: '0'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00091',
                values: [
                    {value: '', placeholder: '0'},
                    {value: '', placeholder: '0'},
                    {value: '', placeholder: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00092',
                values: [
                    {value: '', placeholder: '30'},
                    {value: '', placeholder: '15,000,000'},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00093',
                values: [
                    {value: '', placeholder: ''},
                    {value: '', placeholder: '2,500,000'},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K00094',
                values: [
                    {value: '', placeholder: '30'},
                    {value: '', placeholder: '17,500,000'},
                ]
            },
        ]);

        // フィールド１の計算
        const maxField1RecoveryTime = () => {
            const targetColumnName = 'K00084';
            return maxInput(valueFormsField1, targetColumnName)
        }

        let field1RecoveryCost = 0;
        const sumField1RecoveryCost = () => {
            const targetIndex = 1;
            const targetColumnName = 'K00084';
            field1RecoveryCost = sumInput(valueFormsField1, targetIndex, targetColumnName);
            return field1RecoveryCost;
        }

        const totalField1Recovery = () => {
            const inputValue = parseInt(valueFormsField1[5].values[1].value) >= 0 ? parseInt(valueFormsField1[5].values[1].value) : 0;
            const sum = field1RecoveryCost + inputValue;
            return sum;
        }

        // フィールド２の計算
        const maxField2RecoveryTime = () => {
            const targetColumnName = 'K00091';
            return maxInput(valueFormsField2, targetColumnName)
        }

        let field2RecoveryCost = 0;
        const sumField2RecoveryCost = () => {
            const targetIndex = 1;
            const targetColumnName = 'K00091';
            field2RecoveryCost = sumInput(valueFormsField2, targetIndex, targetColumnName);
            return field2RecoveryCost;
        }

        const totalField2Recovery = () => {
            const inputValue = parseInt(valueFormsField2[5].values[1].value) >= 0 ? parseInt(valueFormsField2[5].values[1].value) : 0;
            const sum = field2RecoveryCost + inputValue;
            return sum;
        }

        // 最大値計算
        const maxInput = (valueFormsField, targetColumnName) => {
            const valueList = valueFormsField.filter(data =>
                data.columnName <= targetColumnName
            ).map(data =>
                parseInt(data.values[0].value) >= 0 ? parseInt(data.values[0].value) : 0
            );
            return Math.max(...valueList);
        }

        // 合計値計算
        const sumInput = (valueFormsField, targetIndex, targetColumnName) => {
            let sum = 0;
            valueFormsField.forEach(data => {
                if (data.columnName <= targetColumnName && parseInt(data.values[targetIndex].value) >= 0) {
                    sum = sum + parseInt(data.values[targetIndex].value);
                }
            })
            return sum;
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // 復旧費用の算定を登録
            const result = await registerFirst5();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 復旧費用の算定を登録
            const result = await registerFirst5();

            if (result == '200') {
                const url = '/bcp/bcp_first/6';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 復旧費用の算定を登録
            const result = await registerFirst5();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showMiddle = async () => {
            // 復旧費用の算定を登録
            const result = await registerFirst5();

            if (result == '200') {
                const url = '/bcp/bcp_financial/home';
                location.href = url;
            }
        }

        // 復旧費用の算定登録
        const registerFirst5 = async () => {
            const reqValueForms = [];

            // 閲覧制限
            reqValueForms.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'view_limit',
                seq: 1,
                value: viewLimit.value,
            });

            // フィールド１
            valueFormsField1.forEach(data => {
                data.values.forEach((val, index) => {
                    let selValue = val.value;
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index + 1,
                            value: selValue,
                        });
                    }
                });
            });

            // フィールド２
            valueFormsField2.forEach(data => {
                data.values.forEach((val, index) => {
                    let selValue = val.value;
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index + 1,
                            value: selValue,
                        });
                    }
                });
            });

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst5';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            viewLimit,
            valueFormsField1,
            valueFormsField2,
            maxField1RecoveryTime,
            sumField1RecoveryCost,
            totalField1Recovery,
            maxField2RecoveryTime,
            sumField2RecoveryCost,
            totalField2Recovery,
            showBack,
            showNext,
            showContents,
            showMiddle,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_texts_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
        is_completed_basic: {
            type: Boolean,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
}

.bg-gray {
    background-color: #a6a6a6;
}

</style>
