<template>
    <div>
      <input
        type="text"
        :value="formattedValue"
        @input="onInput"
        @blur="formatNumber"
        :placeholder="placeholder"
        :class="class"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'NumberInput',
    props: {
      modelValue: {
        type: [Number, String],
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      class: {
        type: String,
        default: "form-control text-end"
      }
    },
    computed: {
      formattedValue() {
        return this.formatWithCommas(this.modelValue);
      }
    },
    methods: {
      formatWithCommas(value) {
        if (!value) return '';
        // 3桁区切りにフォーマットする
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      removeCommas(value) {
        if (!value) return '';
        // カンマを削除する
        return value.toString().replace(/,/g, '');
      },
      onInput(event) {
        const inputValue = event.target.value;
        const rawValue = this.removeCommas(inputValue);
        this.$emit('update:modelValue', rawValue);
      },
      formatNumber() {
        this.$emit('update:modelValue', this.removeCommas(this.modelValue));
      }
    }
  };
  </script>