<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>

            <div>
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">財務診断シート一覧</label>
                    </div>
                </div>

                <div class="row mt-3 mx-md-3">
                    <div class="col-md">
                        <table class="table table-font">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>ファイル名</th>
                                    <th>作成日時</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(file, index) in analysis_file_array" :key="file.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ file.name }}</td>
                                    <td>{{ this.$root.dayjs(file.register_date).format('YYYY-MM-DD HH:mm:ss') }}</td>
                                    <td>
                                        <input type="btn" class="btn btn-success btn-sm btn-size" value="ダウンロード" @click="downloadAnalysisFile(file.id, file.name)" :disabled="isProcessing">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div> -->
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);

        const isProcessing = ref(false);

        const analysis_file_array = ref([]);
        onMounted(() => {
            if (props.analysis_file_list.length > 0) {
                analysis_file_array.value = [...props.analysis_file_list];
            }

        });

        const showBack = () => {
            const url = '/browse/C';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C3';
            location.href = url;
        }

        const downloadAnalysisFile = (id, fileName) => {
            const url = '/api/bcp/downloadFinancialPDF';

            isProcessing.value = true;
            axios.post(url, {
                'id': id
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = fileName;
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);

            }).finally(fin => {
                isProcessing.value = false;
            });
        }


        return {
            setLevel,
            isMargin1,
            isProcessing,
            analysis_file_array,

            showBack,
            showNext,

            downloadAnalysisFile,
        }
    },
    props:{
        bcp_basic_frame_info: {
            type: Object,
        },
        analysis_file_list: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

table {
    width: 100%;
}
@media (max-width: 767px) {
    .table-font {
        font-size: 0.8rem;
    }

    .btn-size {
        font-size: 0.8rem;
        width: 150px;
    }
}

@media (max-width: 576px) {
    .table-font {
        font-size: 0.6rem;
    }

    .btn-size {
        width: 100px;
        font-size: 0.6rem;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
