<template>
    <nav class="bg-resilience-green">
        <div class="container">
            <div class="row py-5">
                <div class="col-md-6">
                    <i id="logo_browse"></i>
                </div>
                <div class="col-md-6">
                    <div class="mt-2 d-flex flex-row justify-content-end" v-for="(version, index) in bcp_version" :key="version.version">
                        <label class="form-label day-class border border-light text-light py-2 px-4 font-s-11 text-center">
                            {{ version.version==1 ? '作成日' : '改定日' }}
                        </label>
                        <label class="form-label bg-light-gray border border-light color-resilience-green py-2 px-5 font-s-11 text-center">
                            {{ this.$root.dayjs(version.作成日).format('YYYY年MM月DD日') }}
                        </label>
                        <label class="ms-3 form-label bg-orange text-light py-2 px-4 font-s-11 width-100 text-center">
                            {{ version.version==1 ? '初版' : '第' + version.version + '版' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-3">
                <div class="card cursor-pointer" @click="showBCPIntroduction">
                    <div class="card-header bg-introduction text-light font-s-half-up text-center py-3">
                        入門コース
                    </div>
                    <div class="card-footer font-introduction border-introduction text-center">
                        ページへ移動する
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card cursor-pointer" @click="showBCPBasic">
                    <div class="card-header bg-basic text-light font-s-half-up text-center py-3">
                        基本コース
                    </div>
                    <div class="card-footer font-basic border-basic text-center">
                        ページへ移動する
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card cursor-pointer" @click="showBCPMiddle">
                    <div class="card-header bg-middle text-light font-s-half-up text-center py-3">
                        中級コース
                    </div>
                    <div class="card-footer font-middle border-middle text-center">
                        ページへ移動する
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card cursor-pointer" @click="showBCPAdvanced">
                    <div class="card-header bg-advanced text-light font-s-half-up text-center py-3">
                        上級コース
                    </div>
                    <div class="card-footer font-advanced border-advanced text-center">
                        ページへ移動する
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5 pb-5">
            <div class="col-md-3">
                <div class="card cursor-pointer" @click="showBCPDisaster">
                    <div class="card-header bg-disaster text-light font-s-half-up text-center py-3">
                        介護・自然災害コース
                    </div>
                    <div class="card-footer font-disaster border-disaster text-center">
                        ページへ移動する
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card cursor-pointer" @click="showBCPInfection">
                    <div class="card-header bg-infection text-light font-s-half-up text-center py-3">
                        介護・感染症コース
                    </div>
                    <div class="card-footer font-infection border-infection text-center">
                        ページへ移動する
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-1 bg-member_card d-flex">
                <label class="form-label text-center flex-fill align-self-center">
                    このページの使い方
                </label>
            </div>
            <div class="col-md-11">
                <div class="row">
                    <div class="col-md-2 d-flex">
                        <label class="form-label text-center align-self-center flex-fill border-green color-resilience-green">閲覧制限</label>
                    </div>
                    <div class="col-md-10">
                        <label class="form-label">入門コースは利用者全員が閲覧できます。基本・中級・上級の各コースの一部項目は、管理者が閲覧制限を設定することができます。内部監査人はすべて閲覧できます。</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 d-flex">
                        <label class="form-label text-center align-self-center flex-fill border-green color-resilience-green">コースごと</label>
                    </div>
                    <div class="col-md-10">
                        <label class="form-label">「入門コース」は入門専用様式、「基本・中級・上級の各コース」は共通様式です。各コースのページには、下位コースから追加された様式のみを配置しています。</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 d-flex">
                        <label class="form-label text-center align-self-center flex-fill border-green color-resilience-green">印刷方法</label>
                    </div>
                    <div class="col-md-10">
                        <label class="form-label">「入門コース」と「基本コース」は、そのまま印刷してください。「中級・上級の各コース」一式を印刷する際は、目次を参考に下位コースページにも移動しながら1ページずつ印刷されることをお勧めします。</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 d-flex">
                        <label class="form-label text-center align-self-center flex-fill border-green color-resilience-green">印刷設定</label>
                    </div>
                    <div class="col-md-10">
                        <label class="form-label">BCP閲覧ページは、A4サイズで見栄えよく印刷できるようCSS設定で背景デザインを一部省略しています。正式なBCP書類としてご活用ください。</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <input type="button" class="btn btn-resilience-green py-2 px-4" value="マイページへ戻る" @click="backMyPage" />
        </div>
    </div>
</template>

<script>
export default {
    setup(props) {
        const showBCPIntroduction = () => {
            if(!props.bcp_info || !props.bcp_info.入門確定日){
                alert('入門コースの策定が終了していないため、閲覧できません。');
                return false;
            }

            const url = '/browse/N';

            location.href = url;
        }

        const showBCPBasic = () => {
            if(!props.bcp_info || !props.bcp_info.基本確定日){
                alert('基本コースの策定が終了していないため、閲覧できません。');
                return false;
            }

            const url = '/browse/K';

            location.href = url;
        }

        const showBCPMiddle = () => {
            if(!props.bcp_info || !props.bcp_info.中級確定日){
                alert('中級コースの策定が終了していないため、閲覧できません。');
                return false;
            }

            const url = '/browse/C';

            location.href = url;
        }

        const showBCPAdvanced = () => {
            if(!props.bcp_info || !props.bcp_info.上級確定日){
                alert('上級コースの策定が終了していないため、閲覧できません。');
                return false;
            }

            const url = '/browse/J';

            location.href = url;
        }
        
        const showBCPDisaster = () => {
            alert('閲覧できません');

            return false;
        }

        const showBCPInfection = () => {
            alert('閲覧できません');

            return false;
        }

        const backMyPage = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        return {
            showBCPIntroduction,
            showBCPBasic,
            showBCPMiddle,
            showBCPAdvanced,
            showBCPDisaster,
            showBCPInfection,
            backMyPage,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        bcp_info: {
            type: Object,
        },
        bcp_version: {
            type: Array,
        }
    }
}

</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

#logo_browse {
    background-image: url('../../../svg/18-01.svg');
    display: inline-flex;
    width: 575px;
    height: 468px;
    background-size: 575px auto;
    background-repeat: no-repeat;
}

.day-class {
    background-color: #00C0BC;
}

.color-resilience-green {
    color: common_colors.colors('resilience-green');
}

.bg-orange {
    background-color: common_colors.colors('resilience-orange');
}

.bg-introduction {
    background-color: common_colors.colors('bcp-introduction');
}

.font-introduction {
    color: common_colors.colors('bcp-introduction');
}

.border-introduction {
    border: 1px solid common_colors.colors('bcp-introduction');
}

.bg-basic {
    background-color: common_colors.colors('bcp-basic');
}

.font-basic {
    color: common_colors.colors('bcp-basic');
}

.border-basic {
    border: 1px solid common_colors.colors('bcp-basic');
}

.bg-middle {
    background-color: common_colors.colors('bcp-middle');
}

.font-middle {
    color: common_colors.colors('bcp-middle');
}

.border-middle {
    border: 1px solid common_colors.colors('bcp-middle');
}

.bg-advanced {
    background-color: common_colors.colors('bcp-advanced');
}

.font-advanced {
    color: common_colors.colors('bcp-advanced');
}

.border-advanced {
    border: 1px solid common_colors.colors('bcp-advanced');
}

.bg-disaster {
    background-color: common_colors.colors('bcp-disaster');
}

.font-disaster {
    color: common_colors.colors('bcp-disaster');
}

.border-disaster {
    border: 1px solid common_colors.colors('bcp-disaster');
}

.bg-infection {
    background-color: common_colors.colors('bcp-infection');
}

.font-infection {
    color: common_colors.colors('bcp-infection');
}

.border-infection {
    border: 1px solid common_colors.colors('bcp-infection');
}

.width-100 {
    width: 100px;
}

.border-green {
    border: 1px solid common_colors.colors('resilience-green');
}

@media screen and (max-width: 767px){
    #logo_browse {
        background-image: url('../../../svg/18-01.svg');
        display: inline-flex;
        width: 287.5px;
        height: 234px;
        background-size: 287.5px auto;
        background-repeat: no-repeat;
    }
}

</style>