<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">1. 基本方針</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式02</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 当社においてBCP（事業継続計画）を策定・運用する目的とともに、当社の特性を踏まえ、緊急時に事業継続を図る上で要点となり得る事項は以下のとおりである。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">1. BCP策定･運用の目的</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ callReplaceWord('顧客') }}にとって</label>
                </div>
                <div class="form-control">{{ textForms['K00001'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ callReplaceWord('従業員') }}にとって</label>
                </div>
                <div class="form-control">{{ textForms['K00002'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">地域にとって</label>
                </div>
                <div class="form-control">{{ textForms['K00003'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">その他</label>
                </div>
                <div class="form-control">{{ textForms['K00004'].value || '&emsp;' }}</div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">2. 緊急時に事業継続を図る上での要点</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ callReplaceWord('組織') }}同士の助け合い</label>
                </div>
                <div class="form-control">{{ textForms['K00005'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">商取引上のモラル</label>
                </div>
                <div class="form-control">{{ textForms['K00006'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">地域への貢献</label>
                </div>
                <div class="form-control">{{ textForms['K00007'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">公的支援制度の活用</label>
                </div>
                <div class="form-control">{{ textForms['K00008'].value || '&emsp;' }}</div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">その他</label>
                </div>
                <div class="form-control">{{ textForms['K00009'].value || '&emsp;' }}</div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">3. BCP及び災害計画の更新時期</label>
                </div>
            </div>

            <div class="row mt-3 mx-1 mb-5">
                <div class="col-md-4 d-flex flex-row justify-content-end justify-content-md-start">
                    <label class="col-form-label text-nowrap">毎年</label>
                    <input type="text" class="mx-3 w-50 form-control text-end" :value="valueForms['K00010'].value || '&emsp;'" readonly>
                    <label class="col-form-label  text-nowrap">月</label>
                </div>
                <div class="col-md-4 d-flex flex-row justify-content-end justify-content-md-start">
                    <label class="col-form-label text-nowrap">更新回数（年）</label>
                    <input type="text" class="mx-3 w-50 form-control text-end" :value="valueForms['K00011'].value || '&emsp;'" readonly>
                    <label class="col-form-label  text-nowrap">回</label>
                </div>
            </div>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_texts_info.length > 0) {
                Object.keys(textForms).forEach(columnName => {
                    textForms[columnName].value = props.bcp_texts_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.text_value;
                });
            }

            if (props.bcp_values_info.length > 0) {
                Object.keys(valueForms).forEach(columnName => {
                    valueForms[columnName].value = props.bcp_values_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.value;
                });
            }
        });

        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive({
            K00001: {
                value: '',
            },
            K00002: {
                value: '',
            },
            K00003: {
                value: '',
            },
            K00004: {
                value: '',
            },
            K00005: {
                value: '',
            },
            K00006: {
                value: '',
            },
            K00007: {
                value: '',
            },
            K00008: {
                value: '',
            },
            K00009: {
                value: '',
            },
        });

        const valueForms = reactive({
            K00010: {
                value: '',
            },
            K00011: {
                value: '',
            },
        });

        const showBack = () => {
            const url = '/browse/K';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K2';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            textForms,
            valueForms,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_texts_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}


@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }
}
</style>
