<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card card-bg-gray">
        <div class="card-body">

            <div class="row">
                <div class="p2 col-md">
                    <label class="form-label">3. 被害想定と事前対策</label>
                    <label class="ms-3 bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式3</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">重要{{ callReplaceWord('商品') }}</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-12 d-flex align-items-center justify-content-start">
                    <label class="form-label">○ 緊急時においても、{{ callReplaceWord('自社') }}で優先的に継続もしくは復旧させる{{ callReplaceWord('商品') }}（以下、重要{{ callReplaceWord('商品') }}という）は、以下のとおりである。</label>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">重要{{ callReplaceWord('商品') }}</label>
                </div>
                <div class="p2 col-md-12 d-flex align-items-center justify-content-start">
                    <div class="form-control d-flex py-2 important-product-content">{{ valueForms['N00016'] || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">被害想定　</label>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-12 d-flex align-items-center justify-content-start">
                    <label class="form-label">○ 本計画における緊急時の被害状況を以下のとおり想定する。</label>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">［大規模地震（震度5弱以上）で想定される影響］</label>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-12 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-2">
                    <label class="form-label">インフラへの影響</label>
                </div>
            </div>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">ライフライン</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">情報通信</label>
                </div>
            </div>

            <template v-for="(item, index) in createArray(Math.max(valueForms['N00017'].values.length, valueForms['N00018'].values.length))">
                <div class="row ms-md-1" v-if="index !== 0">
                    <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00017'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-bottom-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00018'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions17" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00017'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions17" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00017'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions17" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00017'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions18" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00018'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions18" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00018'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions18" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00018'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">道路</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">鉄道</label>
                </div>
            </div>

            <template v-for="(item, index) in createArray(Math.max(valueForms['N00019'].values.length, valueForms['N00020'].values.length))">
                <div class="row ms-md-1" v-if="index !== 0">
                    <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00019'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-bottom-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00020'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-end-0 d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions19" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00019'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions19" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00019'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions19" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00019'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
                <div class="p2 col-md-6 border d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions20" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00020'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions20" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00020'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions20" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00020'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-12 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-2">
                    <label class="form-label">{{ callReplaceWord('組織') }}への影響</label>
                </div>
            </div>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">人（ヒト）</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">情報</label>
                </div>
            </div>

            <template v-for="(item, index) in createArray(Math.max(valueForms['N00021'].values.length, valueForms['N00024'].values.length))">
                <div class="row ms-md-1">
                    <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00021'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-bottom-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00024'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">物（モノ）</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">金（カネ）</label>
                </div>
            </div>

            <template v-for="(item, index) in arr = createArray(Math.max(valueForms['N00022'].values.length, valueForms['N00023'].values.length))">
                <div class="row ms-md-1">
                    <div class="p2 col-md-6 border border-end-0 d-flex justify-content-start" :class="{ 'border-bottom-0' : index !== arr.length - 1}">
                        <label class="form-label">{{ valueForms['N00022'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border d-flex justify-content-start" :class="{ 'border-bottom-0' : index !== arr.length - 1}">
                        <label class="form-label">{{ valueForms['N00023'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">［新型コロナウイルス感染症で想定される影響］</label>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-12 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-2">
                    <label class="form-label">インフラへの影響</label>
                </div>
            </div>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">ライフライン</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">情報通信</label>
                </div>
            </div>

            <template v-for="(item, index) in createArray(Math.max(valueForms['N00025'].values.length, valueForms['N00026'].values.length))">
                <div class="row ms-md-1" v-if="index !== 0">
                    <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00025'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-bottom-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00026'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions25" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00025'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions25" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00025'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions25" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00025'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions26" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00026'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions26" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00026'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions26" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00026'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">道路</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">鉄道</label>
                </div>
            </div>

            <template v-for="(item, index) in createArray(Math.max(valueForms['N00027'].values.length, valueForms['N00028'].values.length))">
                <div class="row ms-md-1" v-if="index !== 0">
                    <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00027'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-bottom-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00028'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-end-0 d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions27" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00027'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions27" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00027'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions27" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00027'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>
                    </div>
                </div>
                <div class="p2 col-md-6 border d-flex flex-column flex-sm-row">
                    <label class="form-label pe-5">
                        利用の可否
                    </label>
                    <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions28" id="inlineRadio1" value="×" onclick="return false;" v-model="valueForms['N00028'].values[0]">
                            <label class="form-check-label" for="inlineRadio1">×</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions28" id="inlineRadio2" value="△" onclick="return false;" v-model="valueForms['N00028'].values[0]">
                            <label class="form-check-label" for="inlineRadio2">△ </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="ms-md-3 form-check-input" type="radio" name="inlineRadioOptions28" id="inlineRadio3" value="○" onclick="return false;" v-model="valueForms['N00028'].values[0]">
                            <label class="form-check-label" for="inlineRadio3">○</label>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-12 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-2">
                    <label class="form-label">{{ callReplaceWord('組織') }}への影響</label>
                </div>
            </div>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">人（ヒト）</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">情報</label>
                </div>
            </div>

            <template v-for="(item, index) in createArray(Math.max(valueForms['N00029'].values.length, valueForms['N00032'].values.length))">
                <div class="row ms-md-1">
                    <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00029'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-bottom-0 d-flex justify-content-start">
                        <label class="form-label">{{ valueForms['N00032'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row ms-md-1">
                <div class="p2 col-md-6 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">物（モノ）</label>
                </div>
                <div class="p2 col-md-6 border border-bottom-0 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">金（カネ）</label>
                </div>
            </div>

            <template v-for="(item, index) in arr = createArray(Math.max(valueForms['N00030'].values.length, valueForms['N00031'].values.length))">
                <div class="row ms-md-1">
                    <div class="p2 col-md-6 border border-end-0 d-flex justify-content-start" :class="{ 'border-bottom-0' : index !== arr.length - 1}">
                        <label class="form-label">{{ valueForms['N00030'].values[index] || "&emsp;" }}</label>
                    </div>
                    <div class="p2 col-md-6 border d-flex justify-content-start" :class="{ 'border-bottom-0' : index !== arr.length - 1}">
                        <label class="form-label">{{ valueForms['N00031'].values[index] || "&emsp;" }}</label>
                    </div>
                </div>
            </template>

            <div class="row mt-3 ms-md-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ callReplaceWord('経営') }}資源（人・モノ・金・情報）への事前対策</label>
                </div>
            </div>

            <div class="card card-bg-gray scale-half ">
                <!-- <div class="card-body"> -->

                    <div class="row">
                        <div class="p2 col-md">
                            <label class="form-label">{{ callReplaceWord('経営') }}資源：人（ヒト）</label>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 1</label>
                                <label class="form-label">事前対策の実施状況の把握</label>
                            </div>
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 2</label>
                                <label class="form-label">事前対策の検討・実施</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="form-label w-40">何をやる？</label>
                                <label class="form-label w-35">誰がやる？</label>
                                <label class="form-label w-25">いつやる？</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">1</label>
                                </div>
                                <div class="w-65 border border-end-0">
                                    <label class="form-label px-1 px-md-2">{{ callReplaceWord('従業員') }}の安否確認ルールの決定や安否確認手段の確保を行っていますか？</label>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_1" value="はい" onclick="return false;" v-model="valueForms['N00033'][1]">
                                        <label for="formRadioDefault33_1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_2" value="いいえ" onclick="return false;" v-model="valueForms['N00033'][1]">
                                        <label for="formRadioDefault33_2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00033'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00033'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00033'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border d-flex">
                                <label class="form-label">{{ valueForms['N00033'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-top-0 border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">2</label>
                                </div>
                                <div class="w-65 border border-top-0 border-end-0">
                                    <label class="form-label px-1 px-md-2">緊急時に必要な{{ callReplaceWord('従業員') }}が{{ callReplaceWord('出社') }}できない場合に、代行できる{{ callReplaceWord('従業員') }}を育成していますか？</label>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault1" value="はい" onclick="return false;" v-model="valueForms['N00034'][1]">
                                        <label for="formRadioDefault1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault2" value="いいえ" onclick="return false;" v-model="valueForms['N00034'][1]">
                                        <label for="formRadioDefault2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00034'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00034'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00034'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border border-top-0 d-flex">
                                <label class="form-label">{{ valueForms['N00034'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="p2 col-md">
                            <label class="form-label">{{ callReplaceWord('経営') }}資源：物（モノ）</label>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 1</label>
                                <label class="form-label">事前対策の実施状況の把握</label>
                            </div>
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 2</label>
                                <label class="form-label">事前対策の検討・実施</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="form-label w-40">何をやる？</label>
                                <label class="form-label w-35">誰がやる？</label>
                                <label class="form-label w-25">いつやる？</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">1</label>
                                </div>
                                <div class="w-65 border border-end-0">
                                    <label class="form-label px-1 px-md-2">什器や棚等、設備を固定していますか？</label>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_1" value="はい" onclick="return false;" v-model="valueForms['N00035'][1]">
                                        <label for="formRadioDefault33_1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_2" value="いいえ" onclick="return false;" v-model="valueForms['N00035'][1]">
                                        <label for="formRadioDefault33_2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00035'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00035'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00035'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border d-flex">
                                <label class="form-label">{{ valueForms['N00035'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-top-0 border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">2</label>
                                </div>
                                <div class="w-65 border border-top-0 border-end-0">
                                    <label class="form-label px-1 px-md-2">緊急時に必要な{{ callReplaceWord('従業員') }}が{{ callReplaceWord('出社') }}できない場合に、代行できる{{ callReplaceWord('従業員') }}を育成していますか？</label>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault1" value="はい" onclick="return false;" v-model="valueForms['N00036'][1]">
                                        <label for="formRadioDefault1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault2" value="いいえ" onclick="return false;" v-model="valueForms['N00036'][1]">
                                        <label for="formRadioDefault2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00036'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00036'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00036'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border border-top-0 d-flex">
                                <label class="form-label">{{ valueForms['N00036'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="p2 col-md">
                            <label class="form-label">{{ callReplaceWord('経営') }}資源：金（カネ）</label>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 1</label>
                                <label class="form-label">事前対策の実施状況の把握</label>
                            </div>
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 2</label>
                                <label class="form-label">事前対策の検討・実施</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="form-label w-40">何をやる？</label>
                                <label class="form-label w-35">誰がやる？</label>
                                <label class="form-label w-25">いつやる？</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">1</label>
                                </div>
                                <div class="w-65 border border-end-0">
                                    <label class="form-label px-1 px-md-2">操業が停止した場合に、必要な運転資金を把握していますか？</label>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_1" value="はい" onclick="return false;" v-model="valueForms['N00037'][1]">
                                        <label for="formRadioDefault33_1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_2" value="いいえ" onclick="return false;" v-model="valueForms['N00037'][1]">
                                        <label for="formRadioDefault33_2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00037'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00037'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00037'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border d-flex">
                                <label class="form-label">{{ valueForms['N00037'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-top-0 border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">2</label>
                                </div>
                                <div class="w-65 border border-top-0 border-end-0">
                                    <label class="form-label px-1 px-md-2">緊急時に運転資金として活用できる現金・預金を準備していますか？</label>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault1" value="はい" onclick="return false;" v-model="valueForms['N00038'][1]">
                                        <label for="formRadioDefault1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault2" value="いいえ" onclick="return false;" v-model="valueForms['N00038'][1]">
                                        <label for="formRadioDefault2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00038'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00038'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00038'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border border-top-0 d-flex">
                                <label class="form-label">{{ valueForms['N00038'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="p2 col-md">
                            <label class="form-label">{{ callReplaceWord('経営') }}資源：情報</label>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 1</label>
                                <label class="form-label">事前対策の実施状況の把握</label>
                            </div>
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 2</label>
                                <label class="form-label">事前対策の検討・実施</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="form-label w-40">何をやる？</label>
                                <label class="form-label w-35">誰がやる？</label>
                                <label class="form-label w-25">いつやる？</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">1</label>
                                </div>
                                <div class="w-65 border border-end-0">
                                    <label class="form-label px-1 px-md-2">重要なデータを特別に保管（バックアップ、耐火金庫等）していますか？</label>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_1" value="はい" onclick="return false;" v-model="valueForms['N00039'][1]">
                                        <label for="formRadioDefault33_1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_2" value="いいえ" onclick="return false;" v-model="valueForms['N00039'][1]">
                                        <label for="formRadioDefault33_2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00039'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00039'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00039'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border d-flex">
                                <label class="form-label">{{ valueForms['N00039'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-top-0 border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">2</label>
                                </div>
                                <div class="w-65 border border-top-0 border-end-0">
                                    <label class="form-label px-1 px-md-2">緊急時に{{ callReplaceWord('取引先') }}等へ情報を発信、{{ callReplaceWord('取引先') }}等の情報を収集する手段を整備していますか？</label>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault1" value="はい" onclick="return false;" v-model="valueForms['N00040'][1]">
                                        <label for="formRadioDefault1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault2" value="いいえ" onclick="return false;" v-model="valueForms['N00040'][1]">
                                        <label for="formRadioDefault2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00040'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00040'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00040'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border border-top-0 d-flex">
                                <label class="form-label">{{ valueForms['N00040'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="p2 col-md">
                            <label class="form-label">その他の事前対策</label>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 1</label>
                                <label class="form-label">事前対策の実施状況の把握</label>
                            </div>
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="border px-2 form-label">ステップ 2</label>
                                <label class="form-label">事前対策の検討・実施</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                        </div>
                        <div class="separate-area">

                        </div>
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <label class="form-label w-40">何をやる？</label>
                                <label class="form-label w-35">誰がやる？</label>
                                <label class="form-label w-25">いつやる？</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row mt-1 mt-md-3 px-0 pe-md-0">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">1</label>
                                </div>
                                <div class="w-65 border border-end-0">
                                    <label class="form-label px-1 px-md-2">{{ callReplaceWord('取引先') }}及び同業者等と災害発生時の相互支援について取り決めていますか？</label>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_1" value="はい" onclick="return false;" v-model="valueForms['N00041'][1]">
                                        <label for="formRadioDefault33_1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault33" id="formRadioDefault33_2" value="いいえ" onclick="return false;" v-model="valueForms['N00041'][1]">
                                        <label for="formRadioDefault33_2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00041'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00041'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00041'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border d-flex">
                                <label class="form-label">{{ valueForms['N00041'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row px-0 pe-md-0 pb-5">
                        <div class="table-area">
                            <div class="d-flex flex-row">
                                <div class="w-15 border border-top-0 border-end-0 d-flex align-items-center justify-content-center ">
                                    <label class="form-label">2</label>
                                </div>
                                <div class="w-65 border border-top-0 border-end-0">
                                    <label class="form-label px-1 px-md-2">緊急時に自治体・公共団体・地域住民等、多様な連携体制はありますか？</label>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-none d-md-block d-print-block">
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault1" value="はい" onclick="return false;" v-model="valueForms['N00042'][1]">
                                        <label for="formRadioDefault1" class="form-check-label">
                                            はい
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" name="formRadioDefault34" id="formRadioDefault2" value="いいえ" onclick="return false;" v-model="valueForms['N00042'][1]">
                                        <label for="formRadioDefault2" class="form-check-label">
                                            いいえ
                                        </label>
                                    </div>
                                </div>
                                <div class="w-20 border border-top-0 radio-section px-1 px-md-2 d-md-none d-print-none">
                                    <span>{{ valueForms['N00042'][1] || '&emsp;' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="separate-area d-flex align-items-center justify-content-center">
                            <label class="form-label">⇨</label>
                        </div>
                        <div class="table-area d-flex flex-row">
                            <div class="w-40 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00042'][2] || '&emsp;' }}</label>
                            </div>
                            <div class="w-35 px-1 px-md-2 border border-top-0 border-end-0 d-flex">
                                <label class="form-label">{{ valueForms['N00042'][3] || '&emsp;' }}</label>
                            </div>
                            <div class="w-25 px-1 px-md-2 border border-top-0 d-flex">
                                <label class="form-label">{{ valueForms['N00042'][4] || '&emsp;' }}</label>
                            </div>
                        </div>
                    </div>

                <!-- </div> -->
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_values_info.length > 0) {
                Object.keys(valueForms).forEach(columnName => {
                    if (valueForms[columnName] === '') {
                        valueForms[columnName] = props.bcp_introduction_values_info.find(
                            (fetchData) => fetchData.column_name === columnName
                        )?.value;
                    } else {
                        const data = props.bcp_introduction_values_info
                            .filter(
                                (fetchData) => fetchData.column_name === columnName
                            )
                            .sort((a, b) => (a.seq < b.seq ? -1 : 1));

                        if (valueForms[columnName].hasOwnProperty('values')) {
                                valueForms[columnName].values = data.map((d) => d.value);
                        } else {
                            data.forEach(
                                (d) => (valueForms[columnName][d.seq] = d.value)
                            );
                        }
                    }
                });
            }
        });

        const setLevel = ref('100000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms = reactive({
            N00016: '',
            N00017: {
                values: [],
            },
            N00018: {
                values: [],
            },
            N00019: {
                values: [],
            },
            N00020: {
                values: [],
            },
            N00021: {
                values: [],
            },
            N00022: {
                values: [],
            },
            N00023: {
                values: [],
            },
            N00024: {
                values: [],
            },
            N00025: {
                values: [],
            },
            N00026: {
                values: [],
            },
            N00027: {
                values: [],
            },
            N00028: {
                values: [],
            },
            N00029: {
                values: [],
            },
            N00030: {
                values: [],
            },
            N00031: {
                values: [],
            },
            N00032: {
                values: [],
            },
            N00033: {},
            N00034: {},
            N00035: {},
            N00036: {},
            N00037: {},
            N00038: {},
            N00039: {},
            N00040: {},
            N00041: {},
            N00042: {},
        });

        const showBack = () => {
            const url = "/browse/N2";
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/N4';
            location.href = url;
        }

        const createArray = (number) => {
            return [...Array(number)];
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            valueForms,
            showBack,
            showNext,
            createArray,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.step-section {
    padding-right: 0;
}

.radio-section {
    padding-right: 0;
}

.important-product-content {
    min-height: 7.5em;
}

.card-bg-gray {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%
}

.w-25 {
    width: 25%
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.table-area {
    width: 45%;
}

.separate-area {
    width: 10%;
}

@media (max-width: 576px) {
    .scale-half {
        font-size: 0.5em;
    }


}

@media (max-width: 767px) {
    .table-area {
        width: 47.5%;
    }

    .separate-area {
        width: 5%;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-6 {
        width: 50%;
    }

}

@media print {
    .card-bg-gray {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
