<template>
    <div class="row mt-5">
        <div class="col-md-3 border-notice">
            <label>お知らせ</label>
        </div>
    </div>
    <div class="row mt-3 pb-5" id="notice_area">
        <div v-for="notice in notice_list" :key="notice.id">
            <div class="row mt-3">
                <div class="col-md-2 color-resilience-orange">{{ this.$root.dayjs(notice.created_at).format('YYYY年MM月DD日') }}</div>
                <div class="col-md-10">{{ notice.title }}</div>
            </div>
            <div class="row mt-1">
                <div>{{ notice.detail }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        notice_list: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
#notice_area {
    overflow: scroll;
    height: 21em;
}

.border-notice {
    border-bottom: 3px solid #7f7f7f;
}

</style>