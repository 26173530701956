<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>

    <div class="card">
        <div class="card-body">

            <div class="row">
                <div class="p2 col-md">
                    <label class="form-label">5. BCPの運用</label>
                    <label class="ms-3 bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式5</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">BCPの定着</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ BCPの重要性や進捗状況等を社内に周知するため、定期的に従業員に対して、以下の教育を実施する。</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">教育計画</label>
                </div>
            </div>

            <div class="row mt-3 mx-1 width-min-600">
                <div class="p2 col-3 col-md-2 border border-gray width-min-100 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">誰が？</label>
                </div>

                <div class="p2 col-6 col-md-7 border border-gray width-min-300 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">何をする？</label>
                </div>

                <div class="p2 col-3 border border-gray width-min-150 d-flex align-items-center justify-content-start bg-gray-3">
                    <label class="form-label">いつ？もしくは<br>どのくらいの頻度で？</label>
                </div>
            </div>

            <div class="row mx-1 width-min-600" v-for="item in valueForms">
                <div class="p2 col-3 col-md-2 border border-gray width-min-100 border-top-0  d-flex justify-content-start">
                    <div class="border-white form-control">{{ item[1] || '&emsp;' }}</div>
                </div>

                <div class="p2 col-6 col-md-7 border border-gray width-min-300 border-top-0  d-flex justify-content-start">
                    <div class="border-white form-control">{{ item[3] || '&emsp;' }}</div>
                </div>

                <div class="p2 col-3 border border-gray width-min-150 border-top-0 d-flex justify-content-start">
                    <div class="border-white form-control">{{ item[2] || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">BCPの見直し</label>
                </div>
            </div>

            <div class="row mt-3 mx-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ BCPの実効性を確保するため、以下の基準に基づきBCPの見直しを行う。</label>
                </div>
            </div>

            <div class="row mx-1">
                <div class="p2 col-md-12 border border-gray d-flex align-items-center justify-content-start review-content">
                    <div class="border-white form-control">{{ textForms['N00061'].value || '&emsp;' }}</div>
                </div>

                <div class="p2 col-md-12 border border-gray border-top-0 d-flex align-items-center justify-content-start review-content">
                    <div class="border-white form-control">{{ textForms['N00062'].value || '&emsp;' }}</div>
                </div>

                <div class="p2 col-md-12 border border-gray border-top-0 d-flex align-items-center justify-content-start review-content">
                    <div class="border-white form-control">{{ textForms['N00063'].value || '&emsp;' }}</div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="BCP閲覧へ戻る" @click="showHome" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                Object.keys(textForms).forEach(columnName => {
                    textForms[columnName].value = props.bcp_introduction_texts_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.text_value;
                });
            }

            if (props.bcp_introduction_values_info.length > 0) {
                const data = props.bcp_introduction_values_info
                    .filter((fetchData) => {
                        const column = fetchData.column_name.replace('N', '');
                        return Number(column) >= 56;
                    })
                    .sort((a, b) => {
                        const column1 = a.column_name.replace('N', '');
                        const column2 = b.column_name.replace('N', '');

                        if (column1 === column2) {
                            return a.seq < b.seq ? -1 : 1;
                        }
                        return Number(column1) < Number(column2) ? -1 : 1;
                    });
                const uniqueList = data.reduce((array, item) => {
                    const key = item.column_name;

                    return array.some(k => k === key)
                        ? array
                        : array.concat(key);
                }, []);

                uniqueList.forEach((key) => {
                    const filteredData = data.filter((d) => d.column_name === key);
                    const obj = {};
                    filteredData.forEach((d) => obj[d.seq] = d.value)
                    valueForms.push(obj);
                });
            }
        });

        const setLevel = ref('100000');
        const isMargin1 = ref(true);

        const textForms = reactive({
            N00061: {
                value: '',
            },
            N00062: {
                value: '',
            },
            N00063: {
                value: '',
            },
        });

        const valueForms = reactive([]);

        const showBack = () => {
            const url = "/browse/N4";
            location.href = url;
        }

        const showHome = async () => {
            const url = '/browse/home';
            location.href = url;
        }

        const createArray = (number) => {
            return [...Array(number)];
        }

        return {
            setLevel,
            isMargin1,
            textForms,
            valueForms,
            showBack,
            showHome,
            createArray,
        }
    },
    props:{
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    color: common_colors.colors(resilience-black);
}

.border-gray {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-min-600 {
    min-width: 600px;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border-gray {
        border-color: black !important;
    }
}
</style>
