<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                会員資格更新
            </div>
            <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="text">
            <label class="ms-4 mt-5">〇&emsp;TOTONO-Lの有効期間は、毎年10月1日から翌年9月30日までとなります。</label><br>
            <label class="ms-4 mt-3">〇&emsp;TOTONO-Lの更新受付期間は以下のとおりです。自動更新の場合、更新期間終了後、ご登録のクレジットカード等から決済されます。</label><br>
        </div>

        <div class="row ms-4 mx-5 mt-3">
            <div class="p2 col-md-2 py-2">
                <input type="text" class="form-control" disabled :value="払込開始日" />
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <label>から</label>
            </div>
            <div class="p2 col-md-2 py-2">
                <input type="text" class="form-control" disabled :value="払込終了日" />
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                <label>まで</label>
            </div>
        </div>

        <div class="text">
            <label class="ms-4 mt-3">〇&emsp;会員資格の更新に係る設定は、「自動更新」のみです。TOTONO-Lの利用継続が不明確な場合は、脱退予告・予告取消機能をご活用ください。</label><br>
            <label class="ms-4 mt-3">〇&emsp;更新受付期間を過ぎますと、ご利用休止期間に入り、ログインできなくなります。なお、策定データは残ります。その後更新手続が完了いたしますと利用を再開できます。</label><br>
            <label class="ms-4 mt-3">〇&emsp;年会費又は従量課金は一括払いのみとし、ご利用休止期間中の月割計算はいたしませんのでご了承ください。</label><br>
        </div>

        <div class="row ms-4 mx-5 mt-5">
            <div class="p2 col-md-2 border bg-white d-flex align-items-center py-2">
                2023年度
            </div>
            <div class="p2 col-md-6 border bg-white d-flex align-items-center border-start-0 py-2">
                会員種別｜従量課金の内容
            </div>
            <div class="p2 col-md-3 border bg-white d-flex align-items-center justify-content-center border-start-0 py-2">
                ご請求金額（税込）
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
                <input type="button" class="btn btn-black btn-sm" value="料金プラン" @click="showPrice">
            </div>
        </div>

        <div class="row ms-4 mx-5">
            <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 py-2">
                年会費
            </div>
            <div class="p2 col-md-6 border bg-white d-flex align-items-center border-start-0 border-top-0 py-2">
                {{ corp_info.会員種別名 }}
            </div>
            <div class="p2 col-md-2 border bg-white border-start-0 border-top-0 py-2 text-end">
                {{ 年会費_表示 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                円
            </div>
        </div>

        <div class="row ms-4 mx-5">
            <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 border-bottom-0 py-2">
                従量課金
            </div>
            <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                登録している利用者（役員・従業員）の人数
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-end border-top-0 border-end-0 py-2">
                {{ pay_per_use_info.利用者数 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                人
            </div>
            <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
                {{ 従量課金_利用者数 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                円
            </div>
        </div>

        <div class="row ms-4 mx-5">
            <div class="p2 col-md-2 border bg-white d-flex align-items-center justify-content-center border-top-0 border-bottom-0 py-2">
            </div>
            <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-top-0 border-end-0 py-2">
                登録する拠点・支店の数
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-end border-top-0 border-end-0 py-2">
                {{ pay_per_use_info.拠点数 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                ヶ所
            </div>
            <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
                {{ 従量課金_拠点数 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                円
            </div>
        </div>

        <div class="row ms-4 mx-5">
            <div class="p2 col-md-2 border bg-white d-flex align-items-center border-top-0 py-2">
            </div>
            <div class="p2 col-md-4 border bg-white d-flex align-items-center border-start-0 border-end-0 border-top-0 py-2">
                登録する利用者家族の人数
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-end border-top-0 border-end-0 py-2">
                {{ pay_per_use_info.利用家族数 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                人
            </div>
            <div class="p2 col-md-2 border bg-white text-end border-start-0 border-top-0 py-2">
                {{ 従量課金_家族数 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 border-top-0 py-2">
                円
            </div>
        </div>

        <div class="row ms-4 mt-5 mx-5">
            <div class="p2 col-md-2 border bg-white d-flex align-items-center py-2">
                合計
            </div>
            <div class="p2 col-md-2 border bg-white text-end border-start-0 py-2">
                {{ 合計額_表示 }}
            </div>
            <div class="p2 col-md-1 border bg-white d-flex align-items-center justify-content-center border-start-0 py-2">
                円
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
            </div>
            <div class="p2 col-md-2 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0 py-2">
                <input type="button" class="btn btn-orange btn-sm" value="請求書PDFをダウンロード" @click="makeInvoice">
            </div>
        </div>

        <div class="p2 col-md-2 ms-4 mt-5 d-flex align-items-center py-2">
            <input type="button" class="btn btn-black text-white" value="💰上記金額を電算システム支払います" :disabled="corp_info.会員資格更新種別 === 10060001">
        </div>

        <div class="p2 col-md-2 ms-4 mt-5 d-flex align-items-center py-2">
            <input type="button" class="btn btn-gray btn-sm" value="管理者ページへ戻る" @click="backHome">
        </div>
    </div>
</template>

<script>
import { CommonNumberFormat } from '../../../function/CommonNumberFormat.js';
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import { computed, inject, onMounted, reactive, ref } from 'vue'
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');
        const dayjs = inject('dayjs');

        onMounted(() => {
            set年会費();
            set合計額();
        });

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const set年会費 = () => {
            switch(props.corp_info.会員種別){
                case 10010002:
                    //企業会員
                    年会費.value = props.annual_charge.年会費_企業会員;
                case 10010003:
                    //団体会員
                    年会費.value = props.annual_charge.年会費_団体会員;
                case 10010004:
                    //公共会員
                    年会費.value = props.annual_charge.年会費_公共会員;
                default:
                    //その他
                    年会費.value = props.annual_charge.年会費_企業会員;
            }
        }

        const showPayPerUse = () => {
            const url ="/al/administrator/register_pay_per_use?back=RegisterUser";

            location.href = url;
        }

        const 払込開始日 = computed(() => {
            return dayjs(props.annual_charge.制定日).add(1, 'd').format('YYYY年MM月DD日');
        });

        const 払込終了日 = computed(() => {
            return dayjs(props.annual_charge.year + '-09-30').format('YYYY年MM月DD日');
        });

        const 年会費 = ref(0);

        const 年会費_表示 = computed(() => {
            return CommonNumberFormat(年会費.value);
        });

        const 従量課金_利用者数 = computed(() => {
            return CommonNumberFormat(props.charge.従量_従業員総額);
        });

        const 従量課金_拠点数 = computed(() => {
            return CommonNumberFormat(props.charge.従量_支店総額);
        });

        const 従量課金_家族数 = computed(() => {
            return CommonNumberFormat(props.charge.従量_家族総額);
        });

        const 合計額 = ref(0);

        const set合計額 = () => {
            合計額.value = 年会費.value + props.charge.従量_従業員総額 + props.charge.従量_支店総額 + props.charge.従量_家族総額
        }

        const 合計額_表示 = computed(() => {
            return CommonNumberFormat(合計額.value);
        });

        const makeInvoice = () => {
            const url = '/api/registerInvoice';

            axios.post(url, {
                '宛名': props.corp_info.組織名,
                '請求方法区分': 10050001, //仮
                '請求金額': 合計額.value,
                '請求年度': props.charge.年度,
                '請求年会費': 年会費.value,
                '請求従業員数': props.pay_per_use_info.利用者数,
                '請求従業員従量課金': props.charge.従量_従業員総額,
                '請求支店数': props.pay_per_use_info.拠点数,
                '請求支店従量課金': props.charge.従量_支店総額,
                '請求家族数': props.pay_per_use_info.利用家族数,
                '請求家族従量課金': props.charge.従量_家族総額,
                'kbn': '継続',
            }).then(res => {
                const url_print = '/api/makeReports';

                axios.post(url_print, {
                    'ReportName': 'invoice',
                    'year': res.data.year,
                    'seq': res.data.seq,
                    '発行日' : res.data.発行日,
                    '宛名': res.data.宛名,
                    '請求方法区分': res.data.請求方法区分,
                    '請求金額': res.data.請求金額,
                    '請求年度': res.data.請求年度,
                    '請求年会費': res.data.請求年会費,
                    '請求従業員数': res.data.請求従業員数,
                    '請求従業員従量課金': res.data.請求従業員従量課金,
                    '請求支店数': res.data.請求支店数,
                    '請求支店従量課金': res.data.請求支店従量課金,
                    '請求家族数': res.data.請求家族数,
                    '請求家族従量課金': res.data.請求家族従量課金,
                },{
                    responseType: 'arraybuffer',
                }).then(res => {
                    const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                    let link = document.createElement('a');
                    link.href = data;
                    link.download = '請求書.pdf';
                    link.click();

                    setTimeout(function(){
                        window.URL.revokeObjectURL(data);
                    }, 100);
                });
            })
        }

        const showPrice = () => {
            const url = '/price'

            let newWindow = window.open(url, '_blank');
        }

        return {
            setLevel,
            backHome,

            set年会費,

            showPayPerUse,

            払込開始日,
            払込終了日,
            年会費,
            年会費_表示,
            従量課金_利用者数,
            従量課金_拠点数,
            従量課金_家族数,
            合計額,
            set合計額,
            合計額_表示,

            makeInvoice,

            showPrice,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        pay_per_use_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        annual_charge: {
            type: Object,
        },
        charge: {
            type: Object,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>