<template>
    <div class="container">
        <div class="d-flex justify-content-end mt-2">
            <input type="button" class="btn btn-inquiry-button" value="前のページへ戻る" @click="historyBack" />
        </div>
        <div class="relative d-flex flex-column min-h-screen pt-2 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">WEBサイト利用上の注意</div>
        </div>
        <div class="row mx-1 mx-md-5 mt-3">
            <div class="text-break">
                当サイトは、一般社団法人岐阜レジリエンス推進協議会（以下「当会」といいます）が運営・管理しています。当サイトをご利用されるにあたり、以下の利用条件を十分にお読みいただき、ご同意のうえご利用いただくものといたします。なお、当サイトおよび各サービスコンテンツは、必要に応じて利用条件を変更することがございますので、ご利用の都度、本ページをご確認いただきますようお願い申し上げます。
            </div>
            <div class="mt-5">
                <div class="fw-bold">個人情報の取扱いについて</div>
                <div>本サイトにおける個人情報保護方針については別ページにてご確認ください。（<a href="privacy_policy">個人情報保護方針</a>）</div>
            </div>
            <div class="mt-5">
                <div class="fw-bold">著作権について</div>
                <div class="text-break">当サイト内に掲載されている情報、デザイン、レイアウト、ロゴマーク、キャラクター、商標などに関しては、当会または当会にその利用を認めた権利者が著作権などの知的財産権、使用権その他の権利を有しています。お客さまは、これらの権利を侵害しない範囲でのみ当サイトをご利用いただくことができます。権利者の許可なく複製、転用などされることのないようお願いいたします。</div>
            </div>
            <div class="mt-5">
                <div class="fw-bold">提供している情報について</div>
                <div class="text-break">当サイトで提供している各種情報につきましては、お客さまに不測の損害・不利益などが発生しないよう適切に努力し、最新かつ正確な情報を掲載するよう注意を払っておりますが、その内容の完全性・正確性・有用性・安全性などについて保証をするものではありません。したがいまして当会は、お客さまが当該情報に基づいて起こされた行動によって生じた損害・不利益などに対していかなる責任も負いません。</div>
            </div>
            <div class="mt-5">
                <div class="fw-bold">閲覧時の障害について</div>
                <div class="text-break">また当会は、当サイトのご利用に起因しソフトウエア、ハードウエア上に生じた障害、コンピュータウイルスによる汚染、データの滅失・毀損などの一切の損害・不利益など、およびご利用のお客さま間またはお客さまと第三者間において生じたトラブルなどによる損害・不利益などについていかなる責任も負いません。</div>
            </div>
            <div class="mt-5">
                <div class="fw-bold">お客さまからのコンタクトについて</div>
                <div class="text-break">お客さまからのメールや資料請求などが、インターネット上の不具合・障害などにより当会に着信しなかった場合、当会はそれに対するいかなる責任も負いません。</div>
            </div>
            <div class="mt-5">
                <div class="fw-bold">お客さまへ発信した情報について</div>
                <div class="text-break">当会がお客さまに発信したメールなどについての著作権は当会に帰属しており、当会に無断で他のWebサイトや印刷物などへ転用することはできません。</div>
            </div>
            <div class="mt-5">
                <div class="fw-bold">掲載内容の変更について</div>
                <div class="text-break">当サイトは、お客さまに事前に予告することなく、情報やサービス内容、アドレスなどの追加、変更、削除を行うことがございます。</div>
            </div>
            <div class="my-5">
                <div class="fw-bold">当サイトへのリンクについて</div>
                <div class="text-break">当サイトのトップページへリンクを希望される場合は、お問い合わせフォームよりウェブマスター宛にご連絡ください。ただし、公序良俗に反する内容、アダルトコンテンツ、当会などを誹謗中傷する内容を含むWebサイトなど、当会がリンクを不適当と判断する場合には、リンクをお断りすることがございます。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const historyBack = () =>{
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        };
        return {
            historyBack,
        }
    },
}
</script>