<template>
    <div class="p2 row mt-5 mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white">
        <div class="p2 row d-flex text-center align-items-center justify-content-center py-2" style="font-size:20pt">
            <br>チュートリアルを見る<br>
            <div class="text" style="font-size:14pt">
                （参考にした国の公表資料｜BCP事業継続計画とは）<br>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center py-2">
            <input type="button" class="p2 col-md-5 btn btn-white bg-white text-dark btn-sm" style="font-size:16pt" value="ページへ移動します" @click="showTutorial">
        </div>
        <div class="p2 d-flex align-items-center justify-content-center py-2">
            &emsp;
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showTutorial = () => {
            const url = '/bcp/bcp_introduction/チ';
            location.href = url;
        }

        return {
            showTutorial,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
