<template>
    <div class="row">
        <div class="col-md-8">
            <div class="h5 heading-orange mt-3" style="font-size:14pt">
                みんなの安否
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-start justify-content-end me-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backPrevious">
        </div>
    </div>

    <div class="row mt-5 mt-1 pb-5">
        <table class="table table-bordered table-striped fs-md-half">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>配信日時</th>
                    <th>配信拠点・支店</th>
                    <th>登録名称</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(safety, index) in safety_list" :key="safety.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ safety.配信日時 }}</td>
                    <td>{{ safety.拠点名 }}</td>
                    <td>{{ safety.登録名称 }}</td>
                    <td><input type="button" class="btn btn-green fs-md-half" value="詳細" @click="showDetail(safety.id)" /></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref } from 'vue';
import levelIcon from '../common/UserLevelIconsComponent.vue';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111111');

        const showDetail = (id) => {
            const url = '/safety/detail/' + id;

            location.href = url;
        }

        const backPrevious = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        return {
            setLevel,

            showDetail,

            backPrevious,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        safety_list: {
            type: Array,
        },
    },
}

</script>

<style lang="scss" scoped>
    @media (max-width: 767px) {
        .fs-md-half {
            font-size: 0.5rem;
        }
    }

</style>