<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            自己診断・内部監査結果
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />
    <div class="row ms-3">
        <div class="p2 row mt-5 col-md-4 d-flex ">
            <input type="button" class="p2 row d-flex text-center align-items-center justify-content-center btn-bcp-basic py-2" style="font-size:20pt"
                value="運用状況の自己診断&#13;&#10;基本コース" @click="showBasic"
            >
        </div>
        <div class="p2 row mt-5 ms-3 col-md-4 d-flex">
            <input type="button" class="p2 row d-flex text-center align-items-center justify-content-center btn-bcp-middle py-2" style="font-size:20pt"
                value="運用状況の自己診断&#13;&#10;中級・上級コース" @click="showMiddleAdvance"
            >
        </div>
    </div>

    <div class="row mt-5">
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" @click="backMenu">
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('011100');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBasic = () => {
            const url = '/pdca/7a';
            location.href = url;
        }

        const showMiddleAdvance = () => {
            const url = '/pdca/7b';
            location.href = url;
        }

        const backMenu = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        return {
            setLevel,
            callReplaceWord,
            showBasic,
            showMiddleAdvance,
            backMenu,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
