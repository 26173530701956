<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label text-nowrap">様式12-2</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 情報連絡</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">従業員連絡リスト［従業員個別］</label>
                </div>
            </div>
            <template v-for="(item, index) in userList">
                <div class="row ms-md-1" :class="[ index === 0 ? 'mt-3' : 'mt-5' ]">
                    <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">個別リストNo.</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ userList.length === 1 && individualListNo ? individualListNo : String(index + 1).padStart(6, 0) }}</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">氏名</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.氏名 || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">役職</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.役職 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">主要な責務</label>
                    </div>
                    <div class="p2 col-md-8 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.主な責務 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-4 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('組織') }}用e-mail</label>
                    </div>
                    <div class="p2 col-md-3 border border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item['組織用e-mail'] || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-2 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">自宅郵便番号</label>
                    </div>
                    <div class="p2 col-md-3 border bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.自宅郵便番号 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">◯　BCP発動</label>
                    </div>
                </div>
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">緊急時依頼事項</label>
                    </div>
                    <div class="p2 col-md-8 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.order_matter || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">緊急時における{{ callReplaceWord('出社') }}可能性</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">自宅から{{ callReplaceWord('組織') }}までの距離</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                        <label class="form-label text-start">約</label>
                        <label class="form-label text-center">{{ item.自宅までの距離 == null ? '&emsp;' : item.自宅までの距離 }}</label>
                        <label class="form-label text-end">Km</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">平時の通勤手段</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.交通手段 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('事業') }}復旧に有効な資格・技能等</label>
                    </div>
                    <div class="p2 col-md-8 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.資格技能 || '&emsp;' }} {{ item.資格技能詳細 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-4 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考</label>
                    </div>
                    <div class="p2 col-md-8 border bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.備考 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">◯　緊急連絡先</label>
                    </div>
                </div>
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">自宅の住所</label>
                    </div>
                    <div class="p2 col-md-9 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.自宅都道府県 + item.自宅市区町村 + item.自宅番地 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">自宅電話番号</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.自宅電話 || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">携帯電話番号等</label>
                    </div>
                    <div class="p2 col-md-3 border bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.個人携帯電話 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">自宅用Eメール</label>
                    </div>
                    <div class="p2 col-md-3 border bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.自宅連絡先_メールアドレス || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">◯　自宅以外の緊急連絡先</label>
                    </div>
                </div>
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">氏名</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.緊急連絡先_氏名 || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">本人との続柄</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.緊急連絡先_続柄 || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row ms-md-1 pb-5">
                    <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">緊急時連絡先</label>
                    </div>
                    <div class="p2 col-md-3 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.緊急連絡先_電話番号 || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">予備電話番号</label>
                    </div>
                    <div class="p2 col-md-3 border bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : item.緊急連絡先_予備電話番号 || '&emsp;' }}</label>
                    </div>
                </div>

                <p class=" border-bottom border-3 border-dark"></p>
            </template>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="BCP閲覧 HOMEへ戻る" @click="showHome" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);
        const individualListNo = ref('');
        const isViewLimit = ref(false);

        let userList = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/C3';
            location.href = url;
        }

        const showHome = () => {
            const url = '/browse/home';
            location.href = url;
        }

        if (props.bcp_values_info.length) {
            isViewLimit.value = props.bcp_values_info.find(
                (fetchData) => fetchData.column_name === 'view_limit'
            )?.value === '1';
        }

        if (props.user_list.length) {
            if (props.individual_list_no) {
                // URLパラメータ（個別リストNo.）が存在する場合
                individualListNo.value = props.individual_list_no;

                if (individualListNo.value.length === 6 && /^\d+$/.test(individualListNo.value)) {
                    // 個別リストNo.が正しい形式の場合
                    const no = Number(individualListNo.value);

                    if (no <= props.user_list.length) {
                        // データが存在する個別リストNo.の場合データを格納する
                        userList.push(props.user_list[no - 1]);
                    }
                }
            } else {
                // URLパラメータ（個別リストNo.）が存在しない場合
                userList = props.user_list;
            }

            userList.forEach(user => {
                user.主な責務 = user.主な責務?.replace(/(@@顧客@@)/g, callReplaceWord('顧客'));
            });
        }

        return {
            setLevel,
            isMargin1,
            individualListNo,
            isViewLimit,
            userList,
            callReplaceWord,
            showBack,
            showHome,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
        user_list: {
            type: Array,
        },
        individual_list_no: {
            type: String,
        }
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-9 {
        width: 75%;
    }
}
</style>
