const password_numeric = '1234567890';
const password_uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const password_lowercase = 'abcdefghijklmnopqrstuvwxyz';
const password_symbol = '!"#$%&()=~|@[];:+-*<>?_>.,\'';

export function CommonMakePassword(length = 20){
    let password = '';
    let password_base = '';

    password_base += password_numeric;
    password_base += password_lowercase;
    password_base += password_uppercase;
    password_base += password_symbol;

    for(let i = 0; i < length; i++){
        password += password_base.charAt(Math.floor(Math.random() * password_base.length));
    }

    return password;
}