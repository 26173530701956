<template>
    <div class="container">
        <div class="row mt-3">
            <div class="heading mt-3">内部情報管理</div>
        </div>
        <div class="row mt-2">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">年会費登録</label></div>
        </div>
        <form @submit.prevent="年会費登録">
            <div class="row mt-3 px-5">
                <div class="col-md-2"><i class="bi bi-asterisk"></i><label class="ms-3 col-form-label">制定日</label></div>
                <div class="col-md-2 me-5"><input type="date" class="form-control" v-model="input年会費制定日" :min="today"></div>
                <div class="col-md-2 ms-5"><input type="button" class="btn btn-red" value="過去の年会費" @click="showPastAnnualCharge"></div>
            </div>
            <div class="row mt-3 px-5">
                <div class="col-md-2">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>年度</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="text" maxlength="4" minlength="4" v-model="input年会費年度" class="form-control" required  :class="{'is-invalid': hasYearError}" /><div class="invalid-feedback">{{ year_エラーメッセージ }}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>有償会員の種類</th>
                                <th>年会費</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="button" class="btn btn-white" value="企業会員" @click="change種別('企業会員')" /></td>
                                <td><input type="number" class="form-control text-end" v-model="input企業会員年会費"></td>
                            </tr>
                            <tr>
                                <td><input type="button" class="btn btn-white" value="団体会員" @click="change種別('団体会員')" /></td>
                                <td><input type="number" class="form-control text-end" v-model="input団体会員年会費"></td>
                            </tr>
                            <tr>
                                <td><input type="button" class="btn btn-white" value="公共会員" @click="change種別('公共会員')" /></td>
                                <td><input type="number" class="form-control text-end" v-model="input公共会員年会費"></td>
                            </tr>
                            <tr>
                                <td><input type="button" class="btn btn-white" value="クラファン会員" @click="change種別('クラファン会員')" /></td>
                                <td><input type="number" class="form-control text-end" v-model="inputクラファン会員年会費"></td>
                            </tr>
                            <tr>
                                <td><input type="button" class="btn btn-white" value="賛助会員" @click="change種別('賛助会員')" /></td>
                                <td><input type="number" class="form-control text-end" v-model="input賛助会員年会費"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>適用開始</th>
                                <th>適用終了</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="date" class="form-control" v-model="input年会費適用開始日" required /></td>
                                <td><input type="date" class="form-control" v-model="input年会費適用終了日" required /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="col-md-2"><i class="bi bi-asterisk"></i><label class="ms-3">月割会費</label></div>
            </div>
            <div class="row mt-3 px-5">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td class="text-center border-td-black">{{ this.input年会費年度 }}</td>
                            <td class="text-center">年</td>
                            <td class="text-center border-td-black">10</td>
                            <td class="text-center">月ご加入</td>
                            <td class="text-center">（</td>
                            <td class="text-center">12</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(12) }}</td>
                            <td class="text-center">円</td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ this.input年会費年度 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">11</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">11</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(11) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ this.input年会費年度 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">12</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">10</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(10) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">1</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">9</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(9) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">2</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">8</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(8) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">3</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">7</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(7) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">4</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">6</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(6) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">5</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">5</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(5) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">6</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">4</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(4) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">7</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">3</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(3) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">8</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">2</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(2) }}</td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center border-td-black">{{ Number(this.input年会費年度) + 1 }}</td>
                            <td class="text-center"></td>
                            <td class="text-center border-td-black">9</td>
                            <td class="text-center"></td>
                            <td class="text-center">（</td>
                            <td class="text-center">1</td>
                            <td class="text-center">ヶ月分</td>
                            <td class="text-center">）</td>
                            <td class="text-end border-td-black">{{ this.monthlyInstallment(1) }}</td>
                            <td class="text-center"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 pb-3 px-5 border-resilience-black">
                <div class="col-2">
                    <input type="submit" class="btn btn-black" value="登録" :disabled="isProcessing" />
                </div>
                <div class="flash col-5 alert" :class="this.annualChargeStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showAnnualCharge">
                    {{ annualChargeMessage }}
                </div>
            </div>
        </form>
        <div class="row mt-3">
            <div id="pay_per_use"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">従量課金登録</label></div>
        </div>
        <form @submit.prevent="従量課金登録">
            <div class="row mt-3 px-5">
                <div class="col-md-2"><i class="bi bi-asterisk"></i><label class="ms-3 col-form-label">制定日</label></div>
                <div class="col-md-2 me-5"><span>{{ this.$root.dayjs(input年会費制定日).format("YYYY/MM/DD") }}</span></div>
                <div class="col-md-2 ms-5"><input type="button" class="btn btn-red" value="過去の従量課金" @click="showPastPayPerUse"></div>
            </div>
            <div class="row mt-3 px-5">
                <div class="col-md-6"><i class="bi bi-asterisk"></i><label class="ms-3">登録する役員・従業員人数</label></div>
            </div>
            <div class="row mt-3 px-5">
                <table class="table">
                    <tbody>
                        <tr v-for="(employee, index) in input従業員arr" :key="index">
                            <td><input type="number" class="form-control text-end" v-model="employee.開始数" @change="addRow従業員(index)" /></td>
                            <td>人から</td>
                            <td><input type="number" class="form-control text-end" v-model="employee.終了数" @change="addRow従業員(index)" /></td>
                            <td>人まで</td>
                            <td>1人につき</td>
                            <td><input type="number" class="form-control text-end" v-model="employee.課金額" @change="addRow従業員(index)" /></td>
                            <td>円</td>
                            <td><input type="button" class="btn btn-red" value="削除" @click="deleteRow従業員arr(index)" :disabled="isProcessing"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 px-5">
                <div class="col-md-6"><i class="bi bi-asterisk"></i><label class="ms-3">登録する拠点・支店の数</label></div>
            </div>
            <div class="row mt-3 px-5">
                <table class="table">
                    <tbody>
                        <tr v-for="(branch, index) in input支店arr" :key="index">
                            <td><input type="number" class="form-control text-end" v-model="branch.開始数" @change="addRow支店(index)" /></td>
                            <td>ヶ所から</td>
                            <td><input type="number" class="form-control text-end" v-model="branch.終了数" @change="addRow支店(index)" /></td>
                            <td>ヶ所まで</td>
                            <td>1ヶ所につき</td>
                            <td><input type="number" class="form-control text-end" v-model="branch.課金額" @change="addRow支店(index)" /></td>
                            <td>円</td>
                            <td><input type="button" class="btn btn-red" value="削除" @click="deleteRow支店arr(index)" :disabled="isProcessing"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 px-5">
                <div class="col-md-6"><i class="bi bi-asterisk"></i><label class="ms-3">登録する家族の人数</label></div>
            </div>
            <div class="row mt-3 px-5">
                <table class="table">
                    <tbody>
                        <tr v-for="(family, index) in input家族arr" :key="index">
                            <td><input type="number" class="form-control text-end" v-model="family.開始数" @change="addRow家族(index)" /></td>
                            <td>人から</td>
                            <td><input type="number" class="form-control text-end" v-model="family.終了数" @change="addRow家族(index)" /></td>
                            <td>人まで</td>
                            <td>1人につき</td>
                            <td><input type="number" class="form-control text-end" v-model="family.課金額" @change="addRow家族(index)" /></td>
                            <td>円</td>
                            <td><input type="button" class="btn btn-red" value="削除" @click="deleteRow家族arr(index)" :disabled="isProcessing"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 pb-3 px-5 border-resilience-black">
                <div class="col-md-4 col-form-label"><i class="bi bi-asterisk"></i><label class="ms-3">LINE連携オプション</label></div>
                <div class="col-md-2">
                    <div class="d-flex flex-row">
                        <input type="number" class="form-control text-end" v-model="LINE連携" /><label class="col-form-label ms-3">円</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 pb-3 px-5 border-resilience-black">
                <div class="col-2">
                    <input type="submit" class="btn btn-black" value="登録" :disabled="isProcessing" />
                </div>
                <div class="flash col-5 alert" :class="this.alertPayPerUseStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showPayPerUseMessage">
                    {{ alertPayPerUseMessage }}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';
import axios from 'axios';

export default {
    setup(props) {
        const dayjs = inject('dayjs');

        onMounted(() => {
            today.value = dayjs().format('YYYY-MM-DD');
            input年会費制定日.value = dayjs().format('YYYY-MM-DD');
            // input従量課金制定日.value = dayjs().format('YYYY-MM-DD');
            input年会費年度.value = props.next_year;
            input年会費適用開始日.value = dayjs([props.next_year, 10]).add(-1, 'y').startOf('month').format('YYYY-MM-DD');
            input年会費適用終了日.value = dayjs([props.next_year, 9]).endOf('month').format('YYYY-MM-DD');
            計算元年会費種別.value = '企業会員';
        });
        
        const isProcessing = ref(false);

        const input年会費制定日 = ref('');
        const input年会費年度 = ref(0);
        const input企業会員年会費 = ref(0);
        const input団体会員年会費 = ref(0);
        const input公共会員年会費 = ref(0);
        const inputクラファン会員年会費 = ref(0);
        const input賛助会員年会費 = ref(0);
        const input年会費適用開始日 = ref('');
        const input年会費適用終了日 = ref('');
        
        // const input従量課金制定日 = ref('');

        const input従業員arr = reactive([{開始数: 0, 終了数: 0, 課金額: 0}]);
        const addRow従業員 = (index) => {
            if(input従業員arr.length === index + 1){
                input従業員arr.push({開始数: 0, 終了数: 0, 課金額: 0});
            }
        };
        const deleteRow従業員arr = (index) => {
            if(input従業員arr.length === 1){
                return;
            }
            input従業員arr.splice(index, 1);
        };

        const input支店arr = reactive([{開始数: 0, 終了数: 0, 課金額: 0}]);
        const addRow支店 = (index) => {
            if(input支店arr.length === index + 1){
                input支店arr.push({開始数: 0, 終了数: 0, 課金額: 0});
            }
        };
        const deleteRow支店arr = (index) => {
            if(input支店arr.length === 1){
                return;
            }
            input支店arr.splice(index, 1);
        };

        const input家族arr = reactive([{開始数: 0, 終了数: 0, 課金額: 0}]);
        const addRow家族 = (index) => {
            if(input家族arr.length === index + 1){
                input家族arr.push({開始数: 0, 終了数: 0, 課金額: 0});
            }
        };
        const deleteRow家族arr = (index) => {
            if(input家族arr.length === 1){
                return;
            }
            input家族arr.splice(index, 1);
        };

        const annualChargeMessage = ref('');
        const showAnnualCharge = ref(false);
        const annualChargeStyle = ref('');

        const showPayPerUseMessage = ref(false);
        const alertPayPerUseMessage = ref('');
        const alertPayPerUseStyle = ref('');

        const 計算元年会費種別 = ref('');
        const 計算元年会費 = computed(() => {
            switch(計算元年会費種別.value) {
                case '企業会員':
                    return input企業会員年会費.value;
                case '団体会員':
                    return input団体会員年会費.value;
                case '公共会員':
                    return input公共会員年会費.value;
                case 'クラファン会員':
                    return inputクラファン会員年会費.value;
                case '賛助会員':
                    return input賛助会員年会費.value;
                default:
                    return input企業会員年会費.value;
            }
        });

        const monthlyInstallment = (month) => {
            return CommonNumberFormat(Math.floor(計算元年会費.value / 12 * month));
        };

        const change種別 = (会員種別) => {
            計算元年会費種別.value = 会員種別;
        };

        const year_エラーメッセージ = ref('');
        const hasYearError = ref(false);

        const 年会費登録 = () => {
            year_エラーメッセージ.value = '';
            hasYearError.value = false;

            isProcessing.value = true;

            const url = '/api/s-admin/registerAnnualCharge';

            axios.post(url, {
                year: input年会費年度.value,
                制定日: input年会費制定日.value,
                適用開始日: input年会費適用開始日.value,
                適用終了日: input年会費適用終了日.value,
                年会費_企業会員: input企業会員年会費.value,
                年会費_団体会員: input団体会員年会費.value,
                年会費_公共会員: input公共会員年会費.value,
                年会費_クラウドファンディング会員: inputクラファン会員年会費.value,
                年会費_賛助会員: input賛助会員年会費.value,
            }).then(res => {
                showFlashAnnualCharge('正常に登録されました', 'success');

                input年会費年度.value = parseInt(res.data.year) + 1;
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.year){
                        year_エラーメッセージ.value = '既に登録されています。';
                        hasYearError.value = true;
                    }
                }else{
                    console.log(err.response.data);
                }
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showFlashAnnualCharge = (msg, kind) => {
            showAnnualCharge.value = true;
            annualChargeMessage.value = msg;
            annualChargeStyle.value = kind;
            setTimeout(() => {
                showAnnualCharge.value = false
            }, 3000)
        }

        const LINE連携 = ref(0);

        const 従量課金登録 = () => {
            isProcessing.value = true;

            const url = '/api/s-admin/registerPayPerUse';
            axios.post(url, {
                制定日: input年会費制定日.value,
                従業員リスト: input従業員arr,
                支店リスト: input支店arr,
                家族リスト: input家族arr,
                LINE連携: LINE連携.value,
            }).then(res => {
                showFlashPayPerUse('正常に登録されました', 'success');
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showFlashPayPerUse = (msg, kind) => {
            showPayPerUseMessage.value = true;
            alertPayPerUseMessage.value = msg;
            alertPayPerUseStyle.value = kind;
            setTimeout(() => {
                showPayPerUseMessage.value = false
            }, 3000)
        }

        const showPastAnnualCharge = () => {
            const url = '/s-admin/showPastAnnualCharge';

            window.open(url, '_blank');
        }

        const showPastPayPerUse = () => {
            const url = '/s-admin/showPastPayPerUse';

            window.open(url, '_blank');
        }

        watch(input年会費年度, (newVal, oldVal) => {
            input年会費適用開始日.value = dayjs([newVal, 10]).add(-1, "y").startOf('month').format('YYYY-MM-DD');
            input年会費適用終了日.value = dayjs([newVal, 9]).endOf('month').format('YYYY-MM-DD');
        });

        const today = ref("");

        return{
            isProcessing,

            input年会費制定日,
            input年会費年度,
            input企業会員年会費,
            input団体会員年会費,
            input公共会員年会費,
            inputクラファン会員年会費,
            input賛助会員年会費,
            input年会費適用開始日,
            input年会費適用終了日,
            計算元年会費種別,
            計算元年会費,

            // input従量課金制定日,

            input従業員arr,
            addRow従業員,
            deleteRow従業員arr,

            input支店arr,
            addRow支店,
            deleteRow支店arr,

            input家族arr,
            addRow家族,
            deleteRow家族arr,

            annualChargeMessage,
            showAnnualCharge,
            annualChargeStyle,

            alertPayPerUseMessage,
            showPayPerUseMessage,
            alertPayPerUseStyle,

            monthlyInstallment,
            change種別,

            year_エラーメッセージ,
            hasYearError,

            年会費登録,
            従量課金登録,
            showFlashAnnualCharge,
            showFlashPayPerUse,

            showPastAnnualCharge,
            showPastPayPerUse,

            today,

            LINE連携,
        }
    },
    props: {
        next_year: {
            type: Number,
        }
    },
}
</script>

<style lang="scss" scoped>
    .border-td-black {
        border-style: solid;
        border-color: #404040;
        border-width: thin;
    }

    .btn-white {
        border: 1px solid;
        color: #404040;
        background-color: white;
    }

    .btn-white:hover {
        border: 1px solid;
        color: white;
        border-color: #404040;
        background-color: #404040;
    }

</style>