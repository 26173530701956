<template>
    <div class="text mt-5" v-if="!isFormulation">
        　入門コースの策定を進めていくと、上位コースのボタンが表示されます。このボタンは、下位コースの入力が完了していない場合、先へは進めませんのでご注意ください。
        <br><label>　それでは、「新しくBCP策定を始める（初めての方はこちらから）」をクリックしてスタートしましょう。</label>
    </div>

    <div class="p2 row mt-5 mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white" v-if="!isFormulation">
        <div class="p2 row d-flex text-center align-items-center justify-content-center py-2" style="font-size:20pt">
            <br>新しく中小企業BCP策定を始める<br>
            <div class="text" style="font-size:14pt">
                （初めての方はこちらから）<br>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center py-2">
            <input type="button" class="p2 col-md-6 btn btn-bcp-introduction btn-sm" style="font-size:16pt" value="入門コース｜ページへ移動します" @click="showIntroduction">
        </div>
        <div class="p2 d-flex align-items-center justify-content-center py-2">
            &emsp;
        </div>
    </div>

    <div class="p2 row mt-5 mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white" v-if="isFormulation">
        <div class="p2 row d-flex text-center align-items-center justify-content-center py-2" style="font-size:20pt">
            <br>目次から項目を選んで策定を続ける<br>
            <div class="text" style="font-size:14pt">
                （策定の続き｜策定の見直し）<br>
            </div>
        </div>
        <div class="pt-2 row d-flex text-center align-items-center justify-content-center">
            <button type="button" class="col-md-2 d-flex align-items-center justify-content-center py-2" @click="showIntroduction" :disabled="is_bcp_edit == 1" :class="is_bcp_edit == 1 ? 'title-bcp-introduction' : 'btn-bcp-introduction'">
                入門コース
            </button>
            <button type="button" class="col-md-2 d-flex align-items-center justify-content-center py-2" @click="showBasic" :disabled="!bcp_title_info.入門確定日 || is_bcp_edit == 1" :class="!bcp_title_info.入門確定日 || is_bcp_edit == 1 ? 'title-bcp-basic' : 'btn-bcp-basic'">
                基本コース
            </button>
            <button type="button" class="col-md-2 d-flex align-items-center justify-content-center py-2" @click="showMiddle" :disabled="!bcp_title_info.基本確定日 || is_bcp_edit == 1" :class="!bcp_title_info.基本確定日 || is_bcp_edit == 1 ? 'title-bcp-middle' : 'btn-bcp-middle'">
                中級コース
            </button>
            <button type="button" class="col-md-2 d-flex align-items-center justify-content-center py-2" @click="showAdvanced" :disabled="!bcp_title_info.中級確定日 || is_bcp_edit == 1" :class="!bcp_title_info.中級確定日 || is_bcp_edit == 1 ? 'title-bcp-advanced' : 'btn-bcp-advanced'">
                上級コース
            </button>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <input type="button" class="p2 col-md-8 btn btn-block btn-white bg-white text-dark btn-sm" style="font-size:16pt" value="目次構成へ移動します" @click="showContents">
        </div>
        <div class="p2 d-flex align-items-center justify-content-center py-2">
            &emsp;
        </div>
    </div>

</template>

<script>
export default {
    setup(props) {
        const isFormulation = props.is_formulation;

        const showIntroduction = () => {
            const url = '/bcp/bcp_introduction/1';
            location.href = url;
        }

        const showBasic = () => {
            const url = '/bcp/bcp_first/1';
            location.href = url;
        }

        const showMiddle = () => {
            const url = '/bcp/bcp_financial/home';
            location.href = url;
        }

        const showAdvanced = () => {
            const url = '/bcp/bcp_multiple/1';
            location.href = url;
        }

        const showContents = () => {
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        return {
            isFormulation,
            showIntroduction,
            showContents,
            showBasic,
            showMiddle,
            showAdvanced,
        }
    },
    props:{
        is_formulation: {
            type: Boolean,
        },
        bcp_title_info: {
            type: Object,
        },
        is_bcp_edit: {
            type: Number,
        },
    },}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

.title-bcp-introduction {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-introduction);
    background-color: common_colors.colors(bcp-introduction);
}

.title-bcp-basic {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-basic);
    background-color: common_colors.colors(bcp-basic);
}

.title-bcp-middle {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-middle);
    background-color: common_colors.colors(bcp-middle);
}

.title-bcp-advanced {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-advanced);
    background-color: common_colors.colors(bcp-advanced);
}

</style>
