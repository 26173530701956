<template>
    <div>
        <div class="h5 heading-orange mt-3">
            利用契約書提出
        </div>

        <label class="ms-4 mt-5">ご提出前に以下の注意点をご確認いただき、アップロードをお願いいたします。</label><br>

        <label class="ms-5 mt-5">○&emsp;契約者の欄に記載したご住所、{{ str組織 }}の名称、代表者の氏名は、合っていますか？</label><br>
        <label class="ms-5 mt-3">○&emsp;契約者の欄に、代表印は押印されていますか？（実印である必要はございません。）</label><br>
        <label class="ms-5 mt-3">○&emsp;ページごとに記した○マークのすべてに、ご印鑑が押されていますか？</label><br>
        <label class="ms-5 mt-3">○&emsp;利用契約書をPDF化してください。（データ名は、「ととのエル利用契約書（○○○○○株式会社）.pdf」としてください。</label><br>

        <div class="row mt-5 ms-5">
            <div class="col-md-6">
                <input type="file" style="display:none" @change="dropFile"/>
                <div class="px-5 py-3 drop-aria text-center" 
                        @dragleave="dragLeave"
                        @dragover.prevent="dragEnter"
                        @drop.prevent="dropFile"
                        :class="{enter: isEnter}">
                    <label class="h2">
                        電子押印した利用契約書を<br>アップロードする
                    </label>
                    <div class="h5 mt-2">
                        利用契約書PDFデータをTOTONO-L事務局に提出する
                    </div>
                    <label class="col-form-label btn btn-gray" for="submit_pdf">
                        ファイルを選択
                        <input id="submit_pdf" type="file" class="no-display" accept="application/pdf" @change="dropFile" />
                    </label>
                </div>
            </div>
        </div>

        <div class="color-resilience-red">
            <label class="ms-5 mt-5 ">×&emsp;著作権侵害など違法なファイルはアップロードしないでください。</label><br>
            <label class="ms-5 mt-3">×&emsp;フォルダのアップロードはできません。</label><br>
        </div>

        <div class="row ms-5 mx-5 mt-5">
            <div class="p2 col-md-2 d-flex align-items-center">
                <input type="button" class="btn btn-gray btn-sm" value="管理者ページへ戻る" @click="back">
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const str組織 = ref('組織');

        const back = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const isEnter = ref(false);

        const dragEnter = () => {
            isEnter.value = true;
        }

        const dragLeave = () => {
            isEnter.value = false;
        }

        const files = ref([]);

        const dropFile = (e, key = '', image = {}) => {
            isEnter.value = false;

            const fileList = e.target.files ? e.target.files : e.dataTransfer.files;

            if(fileList.length === 0){
                return false;
            }

            let files = [];

            for(let i = 0; i < fileList.length; i++){
                files.push(fileList[i]);
            }

            // 今回は1ファイルのみ送ることにする。
            let file = files.length > 0 ? files[0] : [];
            if(file.type !== 'application/pdf'){
                return false;
            }
            if(file.size > 10000000){
                return false;
            }
            let fd   = new FormData(); //★②
            fd.append('file', file);
            fd.append('id', props.corp_info.企業ID);
            fd.append('corp_name', props.corp_info.組織名);

            const url = '/api/postUseContract';

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(url, fd, config).then(res => {

            }).finally(fin => {
                e.target.value = null;
            });
 
            // this.$root.ajax('post', url, fd, (data) => {
            //     // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。//★③
            //     e.target.value = null;
            //     // あとは、コールバック処理に色々書く。
            // });            
        }

        return {
            setLevel,
            str組織,
            back,
            isEnter,
            dragEnter,
            dragLeave,
            files,
            dropFile,
        }

    },
    props: {
        corp_info: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
    .font-gray {
        color: #bfbfbf;
    }

    .drop-aria {
        background-color: #a6a6a6;
        color: white;
        border: thick  #a6a6a6;
    }

    .enter {
        background-color: #bfbfbf;
        color: #808080;
        border: thick double #808080;
    }

    .no-display {
        display: none;
    }
</style>