import { computed } from 'vue';

export function useEnv() {
  const VITE_PUSHER_APP_KEY = computed(() => import.meta.env.VITE_PUSHER_APP_KEY);
  const VITE_PUSHER_APP_CLUSTER = computed(() => import.meta.env.VITE_PUSHER_APP_CLUSTER);
  const VITE_BANK_NAME = computed(() => import.meta.env.VITE_BANK_NAME);
  const VITE_BANK_CODE = computed(() => import.meta.env.VITE_BANK_CODE);
  const VITE_BRANCH_NAME = computed(() => import.meta.env.VITE_BRANCH_NAME);
  const VITE_BRANCH_CODE = computed(() => import.meta.env.VITE_BRANCH_CODE);
  const VITE_ACCOUNT_TYPE = computed(() => import.meta.env.VITE_ACCOUNT_TYPE);
  const VITE_ACCOUNT_NO = computed(() => import.meta.env.VITE_ACCOUNT_NO);
  const VITE_ACCOUNT_HOLDER = computed(() => import.meta.env.VITE_ACCOUNT_HOLDER);
  const VITE_SB_MERCHANT_ID = computed(() => import.meta.env.VITE_SB_MERCHANT_ID);
  const VITE_SB_SERVICE_ID = computed(() => import.meta.env.VITE_SB_SERVICE_ID);
  const VITE_SB_HASH_KEY = computed(() => import.meta.env.VITE_SB_HASH_KEY);
  const VITE_SB_GET_TOKEN_URL = computed(() => import.meta.env.VITE_SB_GET_TOKEN_URL);
  const VITE_SB_DENSAN_URL = computed(() => import.meta.env.VITE_SB_DENSAN_URL);
  const VITE_RE_CAPTCHA_SITE_KEY = computed(() => import.meta.env.VITE_RE_CAPTCHA_SITE_KEY);
  const VITE_SB_REGISTER_CREDIT_URL = computed(() => import.meta.env.VITE_SB_REGISTER_CREDIT_URL);
  const VITE_ENABLE_LINE = computed(() =>  import.meta.env.VITE_ENABLE_LINE);

  return {
    VITE_PUSHER_APP_KEY,
    VITE_PUSHER_APP_CLUSTER,
    VITE_BANK_NAME,
    VITE_BANK_CODE,
    VITE_BRANCH_NAME,
    VITE_BRANCH_CODE,
    VITE_ACCOUNT_TYPE,
    VITE_ACCOUNT_NO,
    VITE_ACCOUNT_HOLDER,
    VITE_SB_MERCHANT_ID,
    VITE_SB_SERVICE_ID,
    VITE_SB_HASH_KEY,
    VITE_SB_GET_TOKEN_URL,
    VITE_SB_DENSAN_URL,
    VITE_RE_CAPTCHA_SITE_KEY,
    VITE_SB_REGISTER_CREDIT_URL,
    VITE_ENABLE_LINE,
  };
}