<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:12pt">
            4. 財務診断と事前対策計画｜緊急事態発生後のキャッシュフローの算定
        </div>

        
        <div class="col-md-4 d-flex justify-content-evenly">
            <h4 class="align-self-end"><span class="badge bg-badge-yellow">製造業</span></h4>
            <h5 class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る"></h5>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row">
        <div class="mt-5 col-md-1 ms-auto d-flex flex-row">
            <input type="button" class="btn btn-bcp-restrictions btn-sm" value="閲覧制限｜設定">
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">

<!--------------------------------------① 災害後のキャッシュフロー検討表（6ヶ月後まで）---------------------------------------------------------------->

                        <div class="row ms-1 mt-3">
                            <div class="d-flex">
                                <div class="p2 me-auto">
                                    <label for="" class="form-label">① 災害後のキャッシュフロー検討表（6ヶ月後まで）</label>
                                </div>
                                <div>
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                        <template #popper>
                                            <div class="row">
                                                <label>災害発生後、{{ callReplaceWord('事業') }}稼働率が100％に戻る月数を予想し、下表に月別稼働率を記入します。これによって、事業中断によるキャッシュフローの悪化額のピークを算定できます。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                            <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
                                <label for="" class="col-md-11 form-label">&emsp;</label>
                                <label for="" class="form-label">単位：千円</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-light text-center" rowspan="9" colspan="">
                                            事<br>
                                            業<br>
                                            中<br>
                                            断
                                        </td>
                                        <td class="bg-light-gray" colspan="2">科目</td>
                                        <td class="text-center bg-light-gray" colspan="">1ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">2ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">3ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">4ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">5ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">6ヶ月目</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">（稼働率の参考値）</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">（稼働率）</td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">営業収入</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light text-center" colspan="" rowspan="3">
                                        営<br>
                                        業<br>
                                        支<br>
                                        出
                                    </td>
                                    <td class="bg-light" colspan="">変動費</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="">固定費</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="">小計</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">月次事業資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">累積事業資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="3">資産の復旧費用</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="3">月次資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="3">累計資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

<!--------------------------------------② 災害後のキャッシュフロー検討表（7ヶ月後以降）---------------------------------------------------------------->

                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="p2 d-flex align-items-center justify-content-start ">
                                <label for="" class="form-label">② 災害後のキャッシュフロー検討表（7ヶ月後以降）</label>
                            </div>
                            <div class="p2 d-flex align-items-center justify-content-start ">
                                <label for="" class="col-md-11 form-label">&emsp;</label>
                                <label for="" class="form-label">単位：千円</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-light text-center" rowspan="9" colspan="">
                                            事<br>
                                            業<br>
                                            中<br>
                                            断
                                        </td>
                                        <td class="bg-light-gray" colspan="2">科目</td>
                                        <td class="text-center bg-light-gray" colspan="">7ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">8ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">9ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">10ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">11ヶ月目</td>
                                        <td class="text-center bg-light-gray" colspan="">12ヶ月目</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">（稼働率の参考値）</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">（稼働率）</td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    <td class="bg-white" colspan="">
                                        <input type="text" class="form-control border-white text-end" id="稼働率" placeholder="">
                                    </td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">営業収入</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light text-center" colspan="" rowspan="3">
                                        営<br>
                                        業<br>
                                        支<br>
                                        出
                                    </td>
                                    <td class="bg-light" colspan="">変動費</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="">固定費</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="">小計</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">月次事業資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="2">累積事業資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="3">資産の復旧費用</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="3">月次資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>

                                    <tr>
                                    <td class="bg-light" colspan="3">累計資金収支</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    <td class="bg-light" colspan="">&emsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-12 align-items-center justify-content-start">
        <input type="button" class="ms-4 btn btn-bcp-middle" value="4. 財務診断と事前対策計画｜復旧費用総額の算出 へ" style="font-size:12pt" @click="showNext">
        <input type="button" class="ms-3 btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt">
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');

        const showNext = () => {
            const url = '/bcp/bcp_financial/製5';

            location.href = url;
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        return {
            setLevel,

            showNext,

            callReplaceWord,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-yellow {
    background-color: #ffd966;
    border: 1px solid #404040;
    color: #404040;
}

.bg-resilience-gray {
    background-color: #bfbfbf;
}

</style>