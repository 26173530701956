<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ 返済原資の計算</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-7 d-flex align-items-center justify-content-end">
                    <label class="form-label">単位：千円</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">科目</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                    <label class="form-label">金額</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">Ⅰ．税引後当期純利益</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">Ⅱ．年間減価償却金額</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">Ⅲ．返済原資合計（Ⅰ＋Ⅱ）</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">Ⅳ．既往借入金年間 約定返済額</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">Ⅴ．新規借入金返済原資（年間）（Ⅲ－Ⅳ）</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">Ⅵ．新規借入可能金額（e）（Ⅴ×8）</label>
                </div>
                <div class="p2 col-md-3 border bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>

            <br><br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);

        const showBack = () => {
            const url = '/browse/C1c';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C2';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            showBack,
            showNext,
        }
    },
    props:{},
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
