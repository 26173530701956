<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">更新履歴編集画面</div>
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>

            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-2">
                <i class="bi bi-record-circle col-form-label"></i><label class="ms-3 form-label">更新履歴更新</label>
            </div>
        </div>
        <form @submit.prevent="updateHistory">
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">日付</label>
                <div class="col-md-2">
                    <input type="date" class="form-control" v-model="更新履歴_登録日" required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">内容</label>
                <div class="col-md-10">
                    <textarea class="form-control" v-model="更新履歴_内容" rows=5 required />
                </div>
            </div>
            <div class="row mt-3 pb-5">
                <div class="col-md-2 offset-md-2">
                    <input type="submit" class="btn btn-black px-3" value="更新" />
                </div>
                <div class="flash col-5 alert" :class="this.flashHistoryStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashHistory">
                    {{ flashHistoryMessage }}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        const dayjs = inject('dayjs');

        const back = () => {
            const url = '/s-admin/showHistoryList';

            location.href = url;
        }

        const updateHistory = () => {
            const url = '/api/s-admin/updateHistory';

            axios.post(url, {
                id: props.history.id,
                notice_date: 更新履歴_登録日.value,
                detail: 更新履歴_内容.value,
            }).then(res => {
                showFlashHistoryMethod('正常に登録されました', 'success');

                back();
            }).catch(err => {
                console.log(err.response.data);
            });
        }

        const 更新履歴_登録日 = ref('');
        const 更新履歴_内容 = ref('');

        onMounted(() => {
            更新履歴_登録日.value = dayjs(props.history.notice_date).format('YYYY-MM-DD');
            更新履歴_内容.value = props.history.detail;
        });

        const flashHistoryMessage = ref('');
        const showFlashHistory = ref(false);
        const flashHistoryStyle = ref('');

        const showFlashHistoryMethod = (msg, kind) => {
            showFlashHistory.value = true;
            flashHistoryMessage.value = msg;
            flashHistoryStyle.value = kind;
            setTimeout(() => {
                showFlashHistory.value = false
            }, 3000)
        }

        return {
            back,
            updateHistory,

            更新履歴_登録日,
            更新履歴_内容,

            flashHistoryMessage,
            showFlashHistory,
            flashHistoryStyle,

            showFlashHistoryMethod,

        }
        
    },
    props: {
        history: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
    .v-middle{
        vertical-align: middle;
    }

    .t-center {
        text-align: center;
    }

</style>