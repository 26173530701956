<template>
    <div class="row no-print">
        <div class="col-md-10">
            <div class="h5 heading-orange mt-3">
                携帯カード印刷
            </div>
        </div>
        <div class="col-md-2 mt-3">
            <input type="button" class="btn btn-black" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <levelIcon class="no-print"
        :set_level="setLevel"
    />
    <div class="row mt-3">
        <div class="col-md-6 col-12">
            <span class="border border-dark px-4">お願い</span>このカードを拾得された方は下記までご連絡ください。
        </div>
        <div class="col-md-2 mt-3 no-print">
            <input type="button" class="btn btn-black px-5" value="印刷" @click="windowPrint" />
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-8 col-12">
            <table class="table table-bordered table-border-resilience-gray">
                <tr>
                    <td rowspan="4" class="first_column tate">
                        勤務先
                    </td>
                    <td class="second_column ps-2">{{ callReplaceWord('組織') + '名' }}</td>
                    <td class="ps-2">{{ corp_info.組織名 }}</td>
                </tr>
                <tr>
                    <td rowspan="2" class="second_column ps-2">住所</td>
                    <td class="ps-2">{{ corp_info.都道府県 + corp_info.市区町村 }}</td>
                </tr>
                <tr>
                    <td class="ps-2">{{ corp_info.番地 }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2">代表電話番号</td>
                    <td class="ps-2">{{ corp_info.電話番号 }}</td>
                </tr>
            </table>
            <table class="table table-bordered table-border-resilience-gray">
                <tr>
                    <td rowspan="3" class="first_column tate title-letter-spacing">
                        緊急時の行動
                    </td>
                    <td class="second_column ps-2">地震</td>
                    <td class="ps-2 px-3">1. 火の元を始末<br />2. 海外近くでは、高台に避難<br />3. 作業場では、所定の場所へ避難<br />4. 震度5強で{{ callReplaceWord('組織') }}に自動参集</td>
                </tr>
                <tr>
                    <td class="second_column ps-2">風水害</td>
                    <td class="ps-2 px-3">1. 気象情報に注意<br />2. 書類や資機材を2階へ移動<br />3. 「避難情報」が発令されたら速やかに行動</td>
                </tr>
                <tr>
                    <td class="second_column ps-2">火災</td>
                    <td class="ps-2 px-3">1. 大声で周囲に知らせ119番<br />2. 初期消火、火が天井まで回ったら退避<br />3. 煙に注意、口に濡れタオル、姿勢低く</td>
                </tr>
            </table>
            <table class="table table-bordered table-border-resilience-gray">
                <tr>
                    <td rowspan="2" class="first_column tate">
                        安否確認ツール
                    </td>
                    <td class="second_column ps-2 align-middle">基本ルール</td>
                    <td class="ps-2 px-3">・ 安否情報を所属長へ連絡する。<br />・ 所属長への連絡がつかない場合、対策本部のアドレスへメールまたは電話を通じて報告する。</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">報告内容</td>
                    <td class="ps-2 px-3">・ 本人および同居家族の安否<br />・ 自宅の損傷状況、出社の見込み（避難している場合）<br />・ その場所、電話番号</td>
                </tr>
            </table>
            <table class="table table-bordered table-border-resilience-gray">
                <tr>
                    <td rowspan="7" class="first_column tate title-letter-spacing">
                        本人情報
                    </td>
                    <td class="second_column ps-2 align-middle">氏名</td>
                    <td class="ps-2">{{ user_info.姓 + ' ' + user_info.名 }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">血液型</td>
                    <td class="ps-2">{{ user_info.血液型 }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">自宅住所</td>
                    <td class="ps-2">{{ (user_info.自宅都道府県 ?? "") + ' ' + (user_info.自宅市区町村 ?? "") + ' ' + (user_info.自宅番地 ?? "") }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">携帯番号</td>
                    <td class="ps-2">{{ user_info.電話番号 }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">自宅電話番号</td>
                    <td class="ps-2">{{ user_info.自宅電話 }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">持病・アレルギー</td>
                    <td class="ps-2">{{ string_user_conditions }}</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">かかりつけ医師</td>
                    <td class="ps-2">{{ string_user_cares }}</td>
                </tr>
            </table>
            <table class="table table-bordered table-border-resilience-gray">
                <template v-for="(family, index) in family_array" :key="family.family_id">
                    <tr>
                        <td :rowspan="family_array.length * 3" class="first_column tate" v-if="index === 0">
                            家族との連絡手段
                        </td>
                        <td class="second_column ps-2 align-middle">{{ family.family_id }} 氏名</td>
                        <td class="ps-2" >{{ family.家族氏名_姓 + ' ' + family.家族氏名_名 }}</td>
                    </tr>
                    <tr>
                        <td class="second_column ps-3 align-middle">連絡先</td>
                        <td class="ps-2" >{{ family.電話番号}}</td>
                    </tr>
                    <tr>
                        <td class="second_column ps-3 align-middle">通勤通学先</td>
                        <td class="ps-2" >{{ family.通勤通学先 }}</td>
                    </tr>
                </template>
            </table>
            <div class="row ms-1 my-5">
                <div class="col-1">
                    <table class="table table-bordered table-border-resilience-gray" id="dial_title_table">
                        <tr>
                            <td class="first_column div-tate text-center">ＮＴＴ災害伝言ダイヤル</td>
                        </tr>
                    </table>
                    
                </div>
                <div class="col">
                    <div class="row">
                        <div class="p-2 col-3 offset-1 table-border-resilience-gray bg-record text-center">
                            伝言を録音
                        </div>
                        <div class="p-2 col-3 offset-4 table-border-resilience-gray bg-play text-center">
                            伝言を再生
                        </div>
                    </div>
                        
                    <div class="row">
                        <div class="p-2 col-3 offset-1 text-center">
                            ⇩
                        </div>
                        <div class="p-2 col-3 offset-4 text-center">
                            ⇩
                        </div>
                    </div>

                    <div class="row">
                        <div class="p-2 col-10 offset-1 table-border-resilience-gray bg-light text-center">
                            177をダイヤル
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="p-2 col-3 offset-1 table-border-resilience-gray bg-record text-center">
                            1
                        </div>
                        <div class="p-2 col-4 text-center">
                            メニューを選択
                        </div>
                        <div class="p-2 col-3 table-border-resilience-gray bg-play text-center">
                            2
                        </div>
                    </div>

                    <div class="row">
                        <div class="p-2 col-3 offset-1 text-center">
                            ⇩
                        </div>
                        <div class="p-2 col-3 offset-4 text-center">
                            ⇩
                        </div>
                    </div>

                    <div class="row">
                        <div class="p-2 col-10 offset-1 table-border-resilience-gray bg-light text-center">
                            （被災地の市外局番）-　×××　-　××××
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="p-2 col-3 offset-1 table-border-resilience-gray bg-record text-center">
                            録音（30秒以内）
                        </div>
                        <div class="p-2 col-4 text-center">
                            ガイダンスに従ってください
                        </div>
                        <div class="p-2 col-3 table-border-resilience-gray bg-play text-center">
                            再生
                        </div>
                    </div>
                </div>
            </div>

            <table class="table table-bordered table-border-resilience-gray">
                <tr>
                    <td rowspan="10" class="first_column tate title-letter-spacing">
                        勤務先への連絡方法
                    </td>
                    <td class="second_column ps-2 align-middle" colspan="2">1.　所属長に連絡をする</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">①	氏名</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長1_氏名" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">②	携帯電話</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長1_携帯電話" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">③	携帯メール</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長1_携帯メール" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">④	自宅電話</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長1_電話番号" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle" colspan="2">2.　所属長に連絡取れない場合</td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">①	氏名</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長2_氏名" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">②	携帯電話</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長2_携帯電話" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">③	携帯メール</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長2_携帯メール" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">④	自宅電話</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input所属長2_電話番号" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
            </table>
            <table class="table table-bordered table-border-resilience-gray">
                <tr>
                    <td rowspan="7" class="first_column tate title-letter-spacing">
                        {{ callReplaceWord('取引先') }}情報
                    </td>
                    <td class="second_column ps-2 align-middle">
                        <div class="d-flex  justify-content-between pe-3">
                            <div>{{ callReplaceWord('取引先') }}</div>
                            <div>1</div>
                        </div>
                    </td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input取引先1" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">
                        <div class="d-flex  justify-content-end pe-3">
                            <div>2</div>
                        </div>
                    </td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input取引先2" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">
                        <div class="d-flex  justify-content-end pe-3">
                            <div>3</div>
                        </div>
                    </td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input取引先3" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">
                        <div class="d-flex  justify-content-end pe-3">
                            <div>4</div>
                        </div>
                    </td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input取引先4" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">関係団体</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="input関係団体" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">その他</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="inputその他1" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
                <tr>
                    <td class="second_column ps-2 align-middle">&emsp;</td>
                    <td class="ps-2 px-3"><input type="text" maxlength=255 v-model="inputその他2" class="form-control form-control-sm" :class="{'form-control-plaintext': isPlainText}" @focus="onFocus" @blur="onBlur" @mouseover="onFocus" @mouseleave="onBlur"></td>
                </tr>
            </table>

        </div>
    </div>

</template>
<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const returnHome = async ()=> {
            const result = await registerUserDetail();

            if (result == '200' || result == 500) {
                const url = '/home';

                location.href = url;
            }
        }

        const showBack = async () => {
            const result = await registerUserDetail();

            if (result == '200' || result == 500) {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const registerUserDetail = async () => {
            const postUrl = '/api/registerUserDetailFromCard';

            return await axios.post(postUrl, {
                所属長1_氏名: input所属長1_氏名.value,
                所属長1_携帯電話: input所属長1_携帯電話.value,
                所属長1_携帯メール: input所属長1_携帯メール.value,
                所属長1_電話番号: input所属長1_電話番号.value,
                所属長2_氏名: input所属長2_氏名.value,
                所属長2_携帯電話: input所属長2_携帯電話.value,
                所属長2_携帯メール: input所属長2_携帯メール.value,
                所属長2_電話番号: input所属長2_電話番号.value,
                取引先1: input取引先1.value,
                取引先2: input取引先2.value,
                取引先3: input取引先3.value,
                取引先4: input取引先4.value,
                関係団体: input関係団体.value,
                その他1: inputその他1.value,
                その他2: inputその他2.value,
            }).then(res => {
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
            });
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const family_array = ref([]);

        const windowPrint = () => {
            window.print();
        }

        onMounted(() => {
            family_array.value = [...props.family_list];

            if(family_array.value.length < 3){
                for(let i = props.family_list.length + 1; i <= 3; i++){
                    family_array.value.push({
                        'family_id': i,
                        '家族氏名_姓': '',
                        '家族氏名_名': '',
                        '通勤通学先': '',
                        '電話番号': '',
                    });
                }
            }

            input所属長1_氏名.value = props.user_info.所属長1_氏名 ?? "";
            input所属長1_携帯電話.value = props.user_info.所属長1_携帯電話 ?? "";
            input所属長1_携帯メール.value = props.user_info.所属長1_携帯メール ?? "";
            input所属長1_電話番号.value = props.user_info.所属長1_電話番号 ?? "";
            input所属長2_氏名.value = props.user_info.所属長2_氏名 ?? "";
            input所属長2_携帯電話.value = props.user_info.所属長2_携帯電話 ?? "";
            input所属長2_携帯メール.value = props.user_info.所属長2_携帯メール ?? "";
            input所属長2_電話番号.value = props.user_info.所属長2_電話番号 ?? "";
            input取引先1.value = props.user_info.取引先1 ?? "";
            input取引先2.value = props.user_info.取引先2 ?? "";
            input取引先3.value = props.user_info.取引先3 ?? "";
            input取引先4.value = props.user_info.取引先4 ?? "";
            input関係団体.value = props.user_info.関係団体 ?? "";
            inputその他1.value = props.user_info.その他1 ?? "";
            inputその他2.value = props.user_info.その他2 ?? "";
        });

        const input所属長1_氏名 = ref('');
        const input所属長1_携帯電話 = ref('');
        const input所属長1_携帯メール = ref('');
        const input所属長1_電話番号 = ref('');
        const input所属長2_氏名 = ref('');
        const input所属長2_携帯電話 = ref('');
        const input所属長2_携帯メール = ref('');
        const input所属長2_電話番号 = ref('');
        const input取引先1 = ref('');
        const input取引先2 = ref('');
        const input取引先3 = ref('');
        const input取引先4 = ref('');
        const input関係団体 = ref('');
        const inputその他1 = ref('');
        const inputその他2 = ref('');

        const isPrinting = ref(false);

        const isPlainText = ref(true);
        // 印刷が開始されたときにisPrintingをtrueに設定
        window.onbeforeprint = () => {
            isPrinting.value = true;
        }

        // 印刷が終了したときにisPrintingをfalseに設定
        window.onafterprint = () => {
            isPrinting.value = false;
        }

        const onFocus = () => {
            isPlainText.value = false;
        }
        
        const onBlur = () => {
            isPlainText.value = true;
        }

        return {
            setLevel,
            returnHome,
            showBack,
            callReplaceWord,
            family_array,

            windowPrint,

            input所属長1_氏名,
            input所属長1_携帯電話,
            input所属長1_携帯メール,
            input所属長1_電話番号,
            input所属長2_氏名,
            input所属長2_携帯電話,
            input所属長2_携帯メール,
            input所属長2_電話番号,
            input取引先1,
            input取引先2,
            input取引先3,
            input取引先4,
            input関係団体,
            inputその他1,
            inputその他2,

            isPrinting,
            isPlainText,

            onFocus,
            onBlur,

            registerUserDetail,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Object,
        },
        user_info: {
            type: Object,
        },
        string_user_conditions: {
            type: String,
        },
        string_user_cares: {
            type: String,
        },
        family_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

.header {
    background-color: common_colors.colors(resilience-light-gray);
}

.table-border-resilience-gray {
    border: 1px solid common_colors.colors(resilience-gray);
}

.first_column {
    width: 5%;
    background-color: #bfbfbf;
}

.second_column {
    width: 25%;
    background-color: #d8d8d8;
    border: 1px solid common_colors.colors(resilience-gray);
}

.tate {
    // position: absolute;
	writing-mode: tb-rl; /* 縦書き */
	top:0;
	bottom:0;
	text-align: center;
	left:50%;
	// transform:translate(-50%, 0%);
}

.div-tate {
    position: static;
	writing-mode: tb-rl; /* 縦書き */
	top:0;
	bottom:0;
    right:auto;
	text-align: center;
    vertical-align: middle;
	// left:50%;
	// transform:translate(-50%, 0%);
}

.bg-record {
    background-color: #c5e0b3;
}

.bg-play {
    background-color: #f7caac;
}

.title-letter-spacing {
    letter-spacing: 0.8em;
}

#dial_title_table, #dial_title_table tr{
    height: 100%;
    max-height: 100%;
}
</style>
