<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜情報連絡（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-middle btn-sm" value="中級｜5.　緊急時におけるBCP発動｜情報連絡（つづき）へ進む" v-show="is_completed_basic" :disabled="isProcessing" @click="showMiddleNext">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />
    <div class="row ms-3 mt-5">
        <div class="p2 col-md-4 border bg-light-gray py-2">
            ◯　従業員連絡リスト［基本情報整理用］
        </div>
    </div>

    <div v-for="(userList, groupIndex) in groupUserList" :key="groupIndex">
        <div class="row ms-3 mt-3">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2">
                部署
            </div>
        </div>
        <div class="ms-3 d-flex justify-content-between">
            <div class="row col-md-3 ms-3">
                <div class="p2 border d-flex align-items-center justify-content-start py-2">
                    {{ groupIndex }}
                </div>
            </div>
            <div class="col-md-3 d-flex align-items-center justify-content-center py-2">
                <VDropdown
                        theme="browsing_restrictions"
                        v-if="Object.keys(groupUserList)[0] == groupIndex"
                    >
                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                    <template #popper>
                        <div class="d-flex justify-content-center">
                            <label>閲覧制限</label>
                            <div class="form-check ms-3">
                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="emergencyContactViewLimit">
                                <label class="form-check-label" for="checkbox_1">
                                    あり
                                </label>
                            </div>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
        <div class="table-responsive-md d-flex align-items-top justify-content-start py-2">
            <div class="w-75">
                <table class="ms-3 mt-3 table table-bordered border-black">
                    <thead>
                        <tr height="50px">
                            <th >利用者ID</th>
                            <th >{{ callReplaceWord('従業員')}}氏名</th>
                            <th >主要な責務</th>
                            <th >
                                <div class="row">
                                    <div class="d-flex justify-content-between align-items-end">
                                        <label>緊急時の依頼事項</label>
                                        <VDropdown
                                            theme="hint-theme"
                                            v-if="Object.keys(groupUserList)[0] == groupIndex"
                                        >
                                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                            <template #popper>
                                                <div class="row">
                                                    <label>{{ hint_word_1 }}</label>
                                                </div>
                                            </template>
                                        </VDropdown>
                                    </div>
                                </div>
                            </th>
                            <th colspan="2" scope="col" >{{ callReplaceWord('事業')}}復旧に有効な資格・技能等</th>
                        </tr>
                    </thead>
                    <tbody v-for="(user, index) in userList" :key="index">
                        <tr ref="tableElement">
                            <td rowspan="2" class="col-md-1" v-bind:style="table1Height">{{ user['利用者ID'] }} &emsp;</td>
                            <td rowspan="2" class="col-md-1" v-bind:style="table1Height">{{ user['氏名'] }} &emsp;</td>
                            <td rowspan="2" class="col-md-2" v-bind:style="table1Height">{{ user['主な責務'] }} &emsp;</td>
                            <td rowspan="2" class="col-md-2" v-bind:style="table1Height"><textarea class="form-control " v-model="user['order_matter']" /></td>
                            <td rowspan="2" class="col-md-2" v-bind:style="table1Height">{{ user['資格技能'] }} &emsp;</td>
                            <td rowspan="2" class="col-md-1" v-bind:style="table1Height">{{ user['資格技能詳細'] }} &emsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="ms-4">
                <div class="mx-3 card bg-light-blue border-green">
                    <div class="card-body">
                        <table class="table table-bordered border-black">
                            <thead>
                                <tr height="50px">
                                    <th>自宅電話／携帯電話</th>
                                </tr>
                            </thead >
                            <tbody v-for="(user, index) in userList" :key="index">
                                <tr>
                                    <td rowspan="1" class="col-md-2" v-bind:style="table2Height">{{ user['自宅電話'] }}&emsp;</td>
                                </tr>
                                <tr>
                                    <td rowspan="1" class="col-md-2" v-bind:style="table2Height">{{ user['個人携帯電話'] }}&emsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜情報連絡（つづき）へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showBasicNext">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:12pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, onMounted, nextTick } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted (() => {
            emergencyContactViewLimit.value = props.is_view_limit;
            props.group_user_list.forEach( user => {
                user.主な責務 = user.主な責務.replace(/(@@顧客@@)/g, callReplaceWord('顧客'))
                if (user.資格技能詳細 !== '' && user.備考 !== null) {
                    user.資格技能詳細 = [user.資格技能詳細, user.備考].join('、')
                } else if (user.資格技能詳細 === '' && user.備考 !== null) {
                    user.資格技能詳細 = user.備考
                }
            });
            groupUserList.value = groupBy(props.group_user_list, '部署');

            // テーブルの高さ調整
            nextTick(() => {
                const clientHeightArray = tableElement.value.map(ref => {
                    return ref.clientHeight;
                })
                const maxHeight = Math.max(...clientHeightArray);
                changeTableHeight(maxHeight);
            })
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const groupUserList = ref(0);
        let tableElement = ref([]);
        let table1Height = ref(0);
        let table2Height = ref(0);

        let emergencyContactViewLimit = ref(0);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const hint_word_1 = ref(`${callReplaceWord('従業員')}の役割や資格などに応じて、緊急時に依頼したい事項を整理します。`);

        const changeTableHeight = async (maxHeight) => {
            table1Height.value = 'height:' + maxHeight + 'px';
            table2Height.value = 'height:' + (maxHeight / 2) + 'px';
        }

        const groupBy = (xs, key) => {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };

        const showBack = async () => {
            const result = await registerSecond3();
            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showMiddleNext = async () => {
            const result = await registerSecond3();
            if (result == '200') {
                const url = '/bcp/bcp_second/4';
                location.href = url;
            }
        }

        const showBasicNext = async () => {
            const result = await registerSecond3();
            if (result == '200') {
                const url = '/bcp/bcp_second/6';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 主要組織連絡先を登録
            const result = await registerSecond3();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            const result = await registerSecond3();
            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 基本情報整理登録
        const registerSecond3 = async () => {
            // リクエスト内容の生成
            const reqUserValueForms = [];
            for (let [key, group] of Object.entries(groupUserList.value)) {
                group.forEach(user => {
                    reqUserValueForms.push({
                        user_id: user.利用者ID,
                        order_matter: user.order_matter
                    });
                });
            }

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond3';

            const setDefaultSeq = 1;
            const setDefaultFieldNumber = 1;
            const pageName = 'S4';
            const reqValueForms = [
                {
                    fieldNumber: setDefaultFieldNumber,
                    columnName: 'view_limit',
                    seq: setDefaultSeq,
                    value: emergencyContactViewLimit.value,
                }
            ];

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'user_value_form_list': reqUserValueForms,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            hint_word_1,
            isProcessing,
            groupUserList,
            tableElement,
            table1Height,
            table2Height,
            emergencyContactViewLimit,
            callReplaceWord,
            changeTableHeight,
            showBack,
            showMiddleNext,
            showBasicNext,
            showPrevious,
            showContents,
            registerSecond3,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        group_user_list: {
            type: Array,
        },
        is_completed_basic: {
            type: Boolean,
        },
        is_view_limit: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>
.no-display {
    display: none;
}

.border-black {
    border-color: #808080;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}

</style>
