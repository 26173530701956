<template>
    <div>
        <div class="row mt-3 pb-1 border-bottom-red">
            <div class="h4 mt-5 color-resilience-red">
                無償会員規約
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
                本規約は、TOTONO-Lサイトの利用規約を補完する無償会員専用の規約です。無償会員は、利用規約と共に本規約を十分に理解しこれを承諾したうえで、本サービスを利用するものとします。
            </div>
        </div>
            
        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                1&nbsp;&emsp;&emsp;用語の定義
            </div>
        </div>
        
        <div class="row">
            <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
                &emsp;&emsp;&emsp;用語の定義は、利用規約に準じます。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                2&nbsp;&emsp;&emsp;無償会員の地位
            </div>
        </div>
        
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                無償会員は、「一般社団法人及び一般財団法人に関する法律」（以下「一般法人法」といいます。）よる社員の地位に該当しないものとします。また、当会定款第5条の「会員の構成」のいずれにも該当しないものとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                3&nbsp;&emsp;&emsp;トライアル利用期間
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                無償会員は、当会が定める年会費又は従量課金を負担することなく、次の日数（時間）のトライアル利用期間を付与するものとします。
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-5 col-md-1 d-flex align-items-center justify-content-center py-2">
                &emsp;&emsp;ⅰ   
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                岐阜県内に本店を置く無償会員：30日間（720時間）
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-5 col-md-1 d-flex align-items-center justify-content-center py-2">
                &emsp;&emsp;ⅱ    
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                岐阜県以外に本店を置く無償会員：20日間（480時間）
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                ・
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                無償会員のトライアル利用権限は、1回限りとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                4&nbsp;&emsp;&emsp;利用の終了
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                　
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                無償会員は、前号が経過した場合、予告なく自動的に利用を終了するものとします。
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                5&nbsp;&emsp;&emsp;有償会員への切替
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
                　
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                無償会員は、トライアル利用期間中に有償会員への切替を行うことができます。ただし、トライアル利用期間を過ぎた場合、新たなユーザー登録に際し、再度の無償会員は選択できないものとします。
            </div>
        </div>
        <div class="row">
            <div class="p2 ms-4 mt-3 color-resilience-red d-flex align-items-center justify-content-start py-2">
                &nbsp;&nbsp;&emsp;&emsp;付則
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                本規約の改廃は、当会の理事会において行なう。
            </div>
        </div>
        <div class="row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-end py-2">
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
                制定：2022年12月1日
            </div>
        </div>

        <div class="mt-5">
            <input type="button" class="btn btn-black px-3" value="確認したので、このページを綴じる" @click="closeTab" />
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const closeTab = () => {
            window.close();
        }

        return {
            closeTab,
        }
        
    },
}
</script>

