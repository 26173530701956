<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜資源（つづき）
        </div>
        <div class="mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　中核事業に係るボトルネック資源［その他の器具類］
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-11">
            <div class="table-responsive">
                <table class="table table-bordered border-black">
                    <thead>
                        <tr>
                            <th>資源の項目</th>
                            <th>数量</th>
                            <th>供給事業者</th>
                            <th>供給事業者｜予備</th>
                            <th>{{ callReplaceWord('事業') }}継続の際の想定設置場所</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(bottleneckResource, index) in bottleneckResourceOthersInfo" :key="index">
                            <td>
                                <input type="text" class="form-control" v-model="bottleneckResource.resourceItem" />
                            </td>
                            <td>
                                <input type="number" min="0" class="form-control" v-model="bottleneckResource.quantity" />
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="bottleneckResource.supplyCompany" />
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="bottleneckResource.supplyCompanyExtra" />
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="bottleneckResource.installationLocation" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="d-flex justify-content-end">
                    <input type="button" class="btn btn-black btn-sm" value="1行増やす" @click="addRow">
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>{{ callReplaceWord('自社') }}の中核{{ callReplaceWord('事業') }}を代替施設で復旧・継続させる際に必要となる各種の機材や道具類を整理します。例示以外の道具類については、空欄に記入してください。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-advanced" value="上級｜5.　緊急時におけるBCP発動｜地域貢献活動 へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted} from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bottleneck_resource_others_info.length > 0) {
                bottleneckResourceOthersInfo.splice(0);
                props.bottleneck_resource_others_info.forEach(fetchData => {
                    // ボトルネック資源情報
                    bottleneckResourceOthersInfo.push({
                        resourceItem: fetchData.resource_item,
                        quantity: fetchData.quantity,
                        supplyCompany: fetchData.supply_company,
                        supplyCompanyExtra: fetchData.supply_company_extra,
                        installationLocation: fetchData.installation_location
                    })
                });
            }

            // 必要な入力枠の作成
            const currentCount = props.bottleneck_resource_others_info.length;
            for (let i = currentCount; i < 10; i++) {
                addRow();
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('000100');
        const isProcessing = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const placeholderItems = [
            'イス',
            '机',
            'キャビネット',
            '会議テーブル',
            'ゴミ箱',
            '移動用エアコン',
        ]

        const bottleneckResourceOthersInfo = reactive([]);
        for (let i = 0; i < 10; i++) {
            bottleneckResourceOthersInfo.push({
                resourceItem: placeholderItems[i] !== undefined ? placeholderItems[i] : '',
                quantity: '',
                supplyCompany: '',
                supplyCompanyExtra: '',
                installationLocation: '',
            });
        }

        const addRow = () => {
            bottleneckResourceOthersInfo.push({
                resourceItem: '',
                quantity: '',
                supplyCompany: '',
                supplyCompanyExtra: '',
                installationLocation: '',
            });
        }

        const showBack = async () => {
            // ボトルネック資源（その他器具類）を登録
            const result = await registerSecond10();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // ボトルネック資源（その他器具類）を登録
            const result = await registerSecond10();

            if (result == '200') {
                const url = '/bcp/bcp_second/13';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // ボトルネック資源（その他器具類）を登録
            const result = await registerSecond10();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // ボトルネック資源（その他器具類）を登録
            const result = await registerSecond10();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // ボトルネック資源（その他器具類）登録
        const registerSecond10 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond10';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'bottleneck_resource_others_info': bottleneckResourceOthersInfo,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            bottleneckResourceOthersInfo,
            placeholderItems,
            showBack,
            showNext,
            showPrevious,
            showContents,
            addRow,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bottleneck_resource_others_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
.border-black {
    border-color: #808080;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}


</style>
