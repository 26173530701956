<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            チュートリアル
        </div>
        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="text">
        <label class="ms-3 mt-3">⦿&emsp;はじめに</label>
    </div>
    <hr>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;大震災が起きたら、洪水に見舞われたら、新型コロナウイルスに感染したら、原因が何であれ重要な事業を継続できない場合、</label>
        <br>
        <label class="ms-3">&emsp;・　あなたは自分の{{ callReplaceWord('組織') }}をどうしますか。
        <br>&emsp;・　あなたご自身、{{ callReplaceWord('従業員') }}、その家族の安全を守れますか。
        <br>&emsp;・　建物や設備はすぐに直して稼働できますか。
        <br>&emsp;・　{{ callReplaceWord('顧客') }}の信頼を維持できますか。</label>
    </div>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;様々な危機的発生事象（インシデント）に直面しても、取引先をはじめ、{{ callReplaceWord('社内') }}外の利害関係者から、重要な事業の継続または早期の復旧を望まれることは確実です。経営者は、危機的事象に備えて必要な対応・対策を行いますが、その精度は、その後の事業活動の成否に大きく影響します。それが、「事業継続能力」です。</label>
    </div>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;そこで、経営者自らがリーダーシップを発揮し、平常時から「事業継続能力」の強化に取り組む環境を整備する必要があります。何が起こっても事業を継続させる意志と、それを実現させる的確で責任の伴った判断と努力が必要となります。そして、相応の時間や労力、又は資金を費やして取り組んだ事業継続能力強化への取組みを内外にアピールされ、社会的責任を果たして欲しいと思います。</label>
    </div>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;本サイトは、国立大学法人東海国立大学機構岐阜大学小山准教授主催の研究チームとの共同研究により、2023年に誕生した新しいサイトです。内閣府の事業継続ガイドライン、経済産業省の中小企業BCP策定運用指針を参考に、日本標準産業分類（業種）を基準として、事業継続計画（BCP）の策定手順の標準化に成功しました。また、すべての業種のリーダーが、その策定・整備への実行力を高めるため、「取り組みやすい容易さ」と「持続しやすい機能」にこだわりました。なお、チャットボットの回答や設問ごとに付したヒント表示などは、上述研究チームの監修による知見が反映されています。</label>
        <br>&emsp;&emsp;なお、さまざまな業種に先立ち、介護施設・事業所に対するBCP策定の義務化が勧められました。そこで、厚生労働省老健局の介護施設施設・事業所における業務継続ガイドライン等とひな形を参考にした専用ページも設置しました。
    </div>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;多くの利用者が本サイトをご利用いただくことにより、{{ callReplaceWord('従業員') }}や地域との絆を深め、官民や{{ callReplaceWord('組織') }}同士をつなぎたいと思います。これによって、日本全体の「強靭な防災ネットワーク」が実現します。</label>
    </div>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;本サイトでは、様々な業種の利用者がBCPの策定を進めていただくごとに、AI（人工知能）が学習・経験を重ね、ますます進化していく仕組みとなっています。</label>
        <br>&emsp;&emsp;どうか、末永くご愛用くださいますようお願い申し上げます。
    </div>

    <div class="text">
        <label class="ms-3 mt-5">⦿&emsp;BCP（事業継続計画）とは</label>
    </div>
    <hr>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;BCP（事業継続計画）とは、企業が自然災害、大火災、テロ攻撃などの緊急事態に遭遇した場合において、事業資産の損害を最小限にとどめつつ、中核となる事業の継続あるいは早期復旧を可能とするために、平常時に行うべき活動や緊急時における事業継続のための方法、手段などを取り決めておく計画のことです。</label>
    </div>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;BCPの特徴は、①優先して継続・復旧すべき中核事業を特定する、②緊急時における中核事業の目標復旧時間を定めておく、③緊急時に提供できるサービスのレベルについて{{ callReplaceWord('顧客') }}と予め協議しておく、④事業拠点や生産設備、仕入品調達等の代替策を用意しておく、⑤全ての{{ callReplaceWord('従業員') }}と事業継続についてコニュニケーションを図っておくことにあります。</label>
    </div>

    <div class="row">
        <div class="ms-3 mt-5 col-md-4 img-hidden">
            <i id="benefits_image" />
            <label style="font-size:10.5pt" class="text-center">図　企業の事業復旧に対するBCP導入効果のイメージ<br>（出典：中小企業BCP策定運用指針）</label>
        </div>
        <div class="mt-5 col-md">
            <label>&emsp;{{ callReplaceWord('組織') }}が大地震などの緊急事態に遭遇すると操業率が大きく落ちます（下図参照）。何も備えを行っていない{{ callReplaceWord('組織') }}では、事業の復旧が大きく遅れて事業の縮小を余儀なくされたり、復旧できずに廃業に追い込まれたりするおそれがあります。</label>
            <br><br><label>&emsp;一方、BCP導入している{{ callReplaceWord('組織') }}は、緊急時でも中核事業を維持・早期復旧することができ、その後、操業率を100％に戻したり、さらには周囲の信頼を得て事業が拡大したりすることも期待できます。</label>
            <br><br><label>&emsp;BCPの策定・運用にあたっては、まずBCPの基本方針の立案と運用体制を確立し、日常的に策定・運用のサイクルを回すことがポイントとなります。</label>
        </div>
    </div>

    <div class="text">
        <label class="ms-3 mt-5">⦿&emsp;BCP策定手順</label>
    </div>
    <hr>

    <div class="text">
        <label class="ms-3 mt-3">&emsp;利用者の皆様には、策定の設問ごとに現れる「ヒント」表示や「AIチャットボット」のシナリオを参考にしながら入力していただきます。入力した情報は、体系的に場合分けされながら、サイト内をフローチャート式に進んでいきます。</label>
        <br>&emsp;&emsp;なお、本サイトのBCPの策定手順は、2つの公表資料を参考に制作しました。
    </div>

    <div class="text color-tutorial-orange">
        <label class="ms-3 mt-5 ">事業継続ガイドライン（内閣府）</label>
    </div>
    <div class="text">
        <label class="ms-3">&emsp;内閣府は、令和3年4月に、事業継続ガイドラインを約8年ぶり、3度目の改定をしました。中央防災会議の下に設置されたワーキンググループが2020年3月に取りまとめた報告書を踏まえたものです。台⾵接近時に個⼈の都合による外出を控えた⼈は約8割にのぼる一方、勤務先への出勤または勤務先からの帰宅を取りやめた⼈は5割強に留まったとしています。危険が予想されても仕事に関しては予定を変更しない傾向があるとし、「従業員等の安全確保のため、職場等が率先して外出の抑制等に取組む必要がある」と提言していました。</label>
    </div>

    <div class="p2 ms-3 mt-3 d-flex align-items-center justify-content-start py-2">
        <span class="badge col-md-1 bg-dark text-white">URL</span>
        <label class="p2 ms-5 d-flex align-items-center justify-content-start py-2"><a href="https://www.bousai.go.jp/kyoiku/kigyou/keizoku/pdf/guideline202104.pdf" target="_blank">https://www.bousai.go.jp/kyoiku/kigyou/keizoku/pdf/guideline202104.pdf</a></label>
    </div>

    <div class="text color-tutorial-orange">
        <label class="ms-3 mt-5 ">中小企業BCP策定運用指針（経済産業省中小企業庁）</label>
    </div>
    <div class="text">
        <label class="ms-3">&emsp;中小企業庁では、わが国の中小企業へのBCP（緊急時企業存続計画または事業継続計画）の普及を促進することを目的として、平成18年に「中小企業BCP策定運用指針（第1版）」を公表、平成24年には、小規模事業者を含めた初心者を念頭に「入門コース」を新たに加え、第2版が公表されました。</label>
    </div>

    <div class="p2 ms-3 mt-3 d-flex align-items-center justify-content-start py-2">
        <span class="badge col-md-1 bg-dark text-white">URL</span>
        <label class="p2 ms-5 d-flex align-items-center justify-content-start py-2"><a href="https://www.chusho.meti.go.jp/bcp/download/bcppdf/bcpguide.pdf" target="_blank">https://www.chusho.meti.go.jp/bcp/download/bcppdf/bcpguide.pdf</a></label>
    </div>

    <div class="p2 ms-3 mt-5">
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start py-2">
            <input type="button" class="btn btn-bcp-introduction btn-sm" value="入門｜BCP策定へ進みます" @click="showIntroduction">
        </div>
    </div>

    <div class="text color-tutorial-orange">
        <label class="ms-3 mt-5 ">介護施設施設・事業所における自然災害発生時の業務継続ガイドライン（厚生労働省老健局）</label>
    </div>
    <div class="text">
        <label class="ms-3">&emsp;介護サービスは、要介護者、家族等の生活を支える上で欠かせないものであり、昨今大規模な災害の発生がみられる中、介護施設・事業所において、災害発生時に適切な対応を行い、その後も利用者に必要なサービスを継続的に提供できる体制を構築することが重要です。大地震や水害等の自然災害に備え、介護サービスの業務継続のために平時から準備・検討しておくべきことや発生時の対応に関する介護サービス類型に応じたガイドラインが、令和2年12月に制定されました。</label>
    </div>

    <div class="p2 ms-3 mt-3 d-flex align-items-center justify-content-start py-2">
        <span class="badge col-md-1 bg-dark text-white">URL</span>
        <label class="p2 ms-5 d-flex align-items-center justify-content-start py-2">https://www.mhlw.go.jp/content/000749543.pdf</label>
    </div>

    <div class="text color-tutorial-orange">
        <label class="ms-3 mt-5 ">介護施設施設・事業所における新型コロナウイルス感染症発生時の業務継続ガイドライン（厚生労働省老健局）</label>
    </div>
    <div class="text">
        <label class="ms-3">&emsp;介護サービスは、要介護者、家族等の生活を支える上で欠かせないものであり、新型コロナウイルス感染症の感染拡大に伴う緊急事態宣言下などの制限下であっても、感染防止対策等の徹底を前提とした継続的なサービスの提供が求められています。そのためには、業務継続に向けた計画の作成が重要であるため、施設・事業所内で新型コロナウイルス感染症が発生した場合の対応、それらを踏まえて平時から準備・検討しておくべきことに関する介護サービス類型に応じた業務継続ガイドラインが、令和2年12月に制定されました。</label>
    </div>

    <div class="p2 ms-3 mt-3 d-flex align-items-center justify-content-start py-2">
        <span class="badge col-md-1 bg-dark text-white">URL</span>
        <label class="p2 ms-5 d-flex align-items-center justify-content-start py-2">https://www.mhlw.go.jp/content/000922077.pdf</label>
    </div>

    <div class="p2 ms-3 mt-5">
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start py-2">
            <input type="button" class="btn btn-bcp-care-natural-disasters btn-sm" value="介護・自然災害｜BCP策定へ進みます">
        </div>
    </div>

    <div class="p2 ms-3 mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('111111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showIntroduction = () => {
            const url = '/bcp/bcp_introduction/1';
            location.href = url;
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,
            showIntroduction,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
    #benefits_image {
        background-image: url('../../../../svg/07-11.svg');
        display: inline-flex;
        width: 440px;
        height: 250px;
        background-size: 440px auto;
        background-repeat: no-repeat;
    }
    .color-tutorial-orange {
        color: #ff6600;
    }
</style>
