<template>
    <div class="row mt-5">
        <span class="ms-3 color-resilience-red fs-5">管理者は、次の項目を登録してから、TOTONO-Lをご利用ください。</span>
    </div>
    <div class="mt-5">
        <div class="d-flex flex-column">
            <span class="ms-3">BCPの設問やヒントで登場する既定の用語を読み替えます。</span>
            <div class="row mt-2 col-md-2 ms-3">
                <button class="btn btn-white" @click="showYomikae"><i id="yomikae"></i>読替え登録</button>
            </div>
        </div>
    </div>
    <div class="mt-5">
        <div class="d-flex flex-column">
            <span class="ms-3">BCP策定の対象となる事業所等の拠点を登録します</span>
            <div class="row mt-2 col-md-2 ms-3">
                <button class="btn btn-white" @click="showBranch"><i id="kyoten"></i>拠点・支店登録</button>
            </div>
        </div>
    </div>
    <div class="mt-5">
        <div class="d-flex flex-column">
            <span class="ms-3">役員・職員等を1人ずつ登録し「利用者ID」を発行します</span>
            <div class="row mt-2 col-md-2 ms-3">
                <button class="btn btn-white" @click="showUser"><i id="riyousya"></i>利用者登録</button>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    setup(props) {
        const showYomikae = () => {
            const url = '/al/before/replacement';

            location.href = url;
        }

        const showBranch = () => {
            const url = '/al/before/branch';
            location.href = url;
        }

        const showUser = () => {
            const url = '/al/before/user';
            location.href = url;
        }

        return {
            showYomikae,
            showBranch,
            showUser,
        }
    },
    props:{
        corp_info:{
            type: Object,
        }

    }
}
</script>

<style lang="scss" scoped>
.btn-white {
    border: 1px solid;
    color: #404040;
    background-color: white;
}

.btn-white:hover {
    border: 1px solid;
    color: white;
    border-color: #404040;
    background-color: #404040;
}

#yomikae {
    background-image: url('../../../svg/initial_registration_button/before_yomikae.svg');
    display: inline-flex;
    width: 25px;
    height: 24.5px;
    background-size: 25px auto;
    background-repeat: no-repeat;
}

#kyoten {
    background-image: url('../../../svg/initial_registration_button/before_kyoten.svg');
    display: inline-flex;
    width: 25px;
    height: 22.5px;
    background-size: 25px auto;
    background-repeat: no-repeat;
}

#riyousya {
    background-image: url('../../../svg/initial_registration_button/before_riyosya.svg');
    display: inline-flex;
    width: 25px;
    height: 25px;
    background-size: 25px auto;
    background-repeat: no-repeat;
}

</style>