<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式17-2</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・  中核事業を復旧・継続するために必要な供給品目（［様式 17-1］で整理したもの）を供給する業者/代替業者に関する情報を以下に整理する。</label>
                </div>
            </div>
            <div class="row ms-md-3" v-for="(item, index) in mainSupplierInfo" :class="{ 'mt-3' : index !== 0 }">
                <div class="d-none d-md-block d-print-block">
                    <div class="row mt-3">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">提供される製品 ／材料／サービス </label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : convertSupplyItemInfoIdToString(item.supplyItemInfoId) || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">法人名／屋号</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.corporateName || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">業者との取引状況</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-check form-check-inline">
                                <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" onclick="return false;">
                                <input v-else class="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" onclick="return false;" :checked="item.tradingStatus.includes('1')">
                                <label class="form-check-label">現在取引中の供給者／業者</label>
                            </div>
                            <div class="ms-5 form-check form-check-inline">
                                <input v-if="!is('administrator') && item.isViewLimit" class="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" onclick="return false;">
                                <input v-else class="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" onclick="return false;" :checked="item.tradingStatus.includes('2')">
                                <label class="form-check-label">予備の供給者／業者</label>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">平時における製品等の提供手段（輸送手段等）</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.method || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">口座番号（必要な場合）</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.bankAccountNumber || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">住所</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : `${item.prefecture || ''}${item.city || ''}${item.otherAddress || ''}` || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">ホームページアドレス</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.url || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">電話番号（代表）</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.representPhoneNumber || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">部署</label>
                        </div>
                        <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactDivision || '&emsp;' }}</div>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">担当者</label>
                        </div>
                        <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPerson || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">第1連絡先</label>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">電話番号</label>
                        </div>
                        <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPhoneNumber || '&emsp;' }}</div>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">携帯電話等</label>
                        </div>
                        <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactMobilePhoneNumber || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">FAX番号</label>
                        </div>
                        <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactFax || '&emsp;' }}</div>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">E-mail</label>
                        </div>
                        <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactEmail || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">部署</label>
                        </div>
                        <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactDivision || '&emsp;' }}</div>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">担当者</label>
                        </div>
                        <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPerson || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">第2連絡先</label>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">電話番号</label>
                        </div>
                        <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPhoneNumber || '&emsp;' }}</div>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">携帯電話等</label>
                        </div>
                        <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactMobilePhoneNumber || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">&emsp;</label>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">FAX番号</label>
                        </div>
                        <div class="p2 col-md-3 bg-info border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactFax || '&emsp;' }}</div>
                        </div>
                        <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                            <label class="form-label">E-mail</label>
                        </div>
                        <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactEmail || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">備考</label>
                        </div>
                        <div class="p2 col-md-9 bg-info border d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.note || '&emsp;' }}</div>
                        </div>
                    </div>
                </div>
                <div class="d-md-none d-print-none">
                    <div class="row mt-3">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">提供される製品 ／材料／サービス </label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : convertSupplyItemInfoIdToString(item.supplyItemInfoId) || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">法人名／屋号</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.corporateName || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">業者との取引状況</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : tradingStatusWord(item.tradingStatus) || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">平時における製品等の提供手段（輸送手段等）</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.method || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">口座番号（必要な場合）</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.bankAccountNumber || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">住所</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : `${item.prefecture || ''}${item.city || ''}${item.otherAddress || ''}` || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">ホームページアドレス</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.url || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">電話番号（代表）</label>
                        </div>
                        <div class="p2 col-md-7 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.representPhoneNumber || '&emsp;' }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">第1連絡先</label>
                        </div>
                        <div class="col-1 border border-bottom-0 border-end-0 border-top-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        </div>
                        <div class="col px-0">
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">部署</label>
                            </div>
                            <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactDivision || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">担当者</label>
                            </div>
                            <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPerson || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">電話番号</label>
                            </div>
                            <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactPhoneNumber || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">携帯電話等</label>
                            </div>
                            <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactMobilePhoneNumber || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">FAX番号</label>
                            </div>
                            <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactFax || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">E-mail</label>
                            </div>
                            <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.firstContactEmail || '&emsp;' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">第2連絡先</label>
                        </div>
                        <div class="col-1 border border-bottom-0 border-end-0 border-top-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        </div>
                        <div class="col px-0">
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">部署</label>
                            </div>
                            <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactDivision || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">担当者</label>
                            </div>
                            <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPerson || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">電話番号</label>
                            </div>
                            <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactPhoneNumber || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">携帯電話等</label>
                            </div>
                            <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactMobilePhoneNumber || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">FAX番号</label>
                            </div>
                            <div class="p2 col-md-3 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactFax || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-md-2 bg-gray-3 border border-bottom-0 d-flex align-items-center justify-content-start">
                                <label class="form-label ms-2">E-mail</label>
                            </div>
                            <div class="p2 col-md-2 bg-info border border-bottom-0 d-flex align-items-center justify-content-start">
                                <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.secondContactEmail || '&emsp;' }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="p2 col-md-3 border bg-gray-3 d-flex align-items-center justify-content-start">
                            <label class="form-label">備考</label>
                        </div>
                        <div class="p2 col-md-9 bg-info border d-flex align-items-center justify-content-start">
                            <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.note || '&emsp;' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>

    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let mainSupplierInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K16';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/K18';
            location.href = url;
        }

        const convertSupplyItemInfoIdToString = (value) => {
            return props.supply_item_info.find(
                (data) => data.id === value
            )?.supply_item || '';
        };

        if (props.main_supplier_info.length > 0) {
            mainSupplierInfo = props.main_supplier_info.map((fetchData) => ({
                bankAccountNumber: fetchData.bank_account_number,
                city: fetchData.city,
                contactType: fetchData.contact_type,
                corporateName: fetchData.corporate_name,
                firstContactDivision: fetchData.first_contact_division,
                firstContactEmail: fetchData.first_contact_email,
                firstContactFax: fetchData.first_contact_fax,
                firstContactMobilePhoneNumber: fetchData.first_contact_mobile_phone_number,
                firstContactPerson: fetchData.first_contact_person,
                firstContactPhoneNumber: fetchData.first_contact_phone_number,
                isViewLimit: fetchData.is_view_limit === 1,
                method: fetchData.method,
                note: fetchData.note,
                otherAddress: fetchData.other_address,
                prefecture: fetchData.prefecture,
                representPhoneNumber: fetchData.represent_phone_number,
                secondContactDivision: fetchData.second_contact_division,
                secondContactEmail: fetchData.second_contact_email,
                secondContactFax: fetchData.second_contact_fax,
                secondContactMobilePhoneNumber: fetchData.second_contact_mobile_phone_number,
                secondContactPerson: fetchData.second_contact_person,
                secondContactPhoneNumber: fetchData.second_contact_phone_number,
                supplyItemInfoId: fetchData.supply_item_info_id,
                tradingStatus: fetchData.trading_status?.split(',') || [],
                url: fetchData.url,
            }));
        }

        const tradingStatusWord = (status) => {
            switch(status){
                case "1":
                    return "現在取引中の供給者／業者";
                case "2":
                    return "";
                default:
                    return "予備の供給者／業者";
            }
        }

        return {
            setLevel,
            isMargin1,
            mainSupplierInfo,
            callReplaceWord,
            showBack,
            showNext,
            convertSupplyItemInfoIdToString,
            tradingStatusWord,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        supply_item_info: {
            type: Array,
        },
        main_supplier_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="checkbox"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.form-check {
    margin-bottom: 0;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.form-check-label {
    font-size: 16px;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
