<template>
    <div class="row">
        <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
            協定書保管サービス
        </div>
        <div class="p2 mt-3 ms-auto col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bnb-blue btn-sm" value="協定書保管規約" @click="showStorageRule">
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="historyBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md d-flex align-items-center justify-content-start">
            <label for="" class="form-label">ご提出前に以下の注意点をご確認いただき、アップロードをお願いいたします。</label>
        </div>
        <div class="ms-4 p2 d-flex align-items-center justify-content-start">
            <label for="" class="form-label col-md-6">○&emsp;本サービスを利用するにあたり、協定書保管規約に同意するものとします</label>
        </div>
        <div class="ms-4 p2 d-flex align-items-center justify-content-start">
            <label for="" class="form-label col-md">○&emsp;災害応援協定書をPDF化してください。（データ名は、「TOTONO-L災害応援協定書（あなたの{{ callReplaceWord('組織') }}の名称＆相手の名称20231001）.pdf」としてください。</label>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-6" :disabled="isProcessing">
            <input type="file" style="display:none" @change="dropFile"/>
            <div class="px-5 py-3 btn-upload-blue text-center" 
                    @dragleave="dragLeave"
                    @dragover.prevent="dragEnter"
                    @drop.prevent="dropFile"
                    :class="{enter: isEnter}"
                >
                <label class="h2">
                    締結した災害応援協定書を<br>アップロードする
                </label>
                <div class="h5 mt-2">
                    災害応援協定書PDFデータをTOTONO-Lクラウドに保管する
                </div>
                <label class="col-form-label btn btn-bnb-blue" for="submit_pdf">
                    ファイルを選択
                    <input id="submit_pdf" type="file" class="no-display" accept=".pdf" @change="dropFile" />
                </label>
            </div>
        </div>
    </div>
    <div class="row mt-5 ms-3 ">
        <div class="ms-4 p2 d-flex align-items-center justify-content-start">
            <label for="" class="form-label col-md-6 font-color-danger">×&emsp;著作権侵害など違法なファイルはアップロードしないでください</label>
        </div>
        <div class="ms-4 p2 d-flex align-items-center justify-content-start">
            <label for="" class="form-label col-md font-color-danger">×&emsp;フォルダのアップロードはできません。</label>
        </div>
    </div>

    <div class="row mt-5 mx-1">
        <span>〇　保管されている災害応援協定書一覧</span>
    </div>
    <div class="row mt-3 mx-3">
        <div class="col-md">
            <table class="table table-responsive">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>協定書名</th>
                        <th>作成日時</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(agreement, index) in agreement_array" :key="agreement.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ agreement.name }}</td>
                        <td>{{ this.$root.dayjs(agreement.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
                        <td>
                            <div class="d-flex justify-content-evenly">
                                <input type="btn" class="btn btn-success" value="ダウンロード" @click="downloadAgreement(agreement.id, agreement.name)" :disabled="isProcessing">
                                <input type="btn" class="btn btn-danger" value="削除" @click="deleteAgreement(agreement.id, index)" :disabled="isProcessing">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- <div class="row mt-5 ms-3 ">
        <div class="p2 col-md-7 d-flex align-items-center justify-content-start">
            <button type="button" class="btn btn-sm btn-secondary" id="災害応援協定書ダウンロード">
                <div style="font-size:24pt">
                    <label for="" class="form-label">
                        保管されている災害応援協定書を<br>
                        ダウンロードする<br>                  
                    </label>
                </div>
                <div style="font-size:14pt">
                    <label for="" class="form-label">
                        TOTONO-Lクラウドから災害応援協定書PDFデータを取り出す
                    </label>
                </div>
            </button>
        </div>
    </div>

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md-7 d-flex align-items-center justify-content-start">
            <button type="button" class="btn btn-sm btn-secondary" id="災害応援協定書削除">
                <div style="font-size:24pt">
                    <label for="" class="form-label">
                        保管されている災害応援協定書を<br>
                        削除する<br>                  
                    </label>
                </div>
                <div style="font-size:14pt">
                    <label for="" class="form-label">
                        TOTONO-Lクラウドから災害応援協定書PDFデータを削除する
                    </label>
                </div>
            </button>
        </div>
    </div> -->

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-5 text-center btn btn-bnb-back" value="B&B応援災害協定ビジネスマッチングHOMEへ戻る" @click="backHome">
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default{
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            if(props.agreement_list.length > 0){
                agreement_array.value = [...props.agreement_list];
            }
        })

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011111');

        const isProcessing = ref(false);

        const isEnter = ref(false);

        const dragEnter = () => {
            isEnter.value = true;
        }

        const dragLeave = () => {
            isEnter.value = false;
        }

        const files = ref([]);
        const fd_2 = ref(new FormData());

        const dropFile = (e, key = '', image = {}) => {
            isEnter.value = false;

            const fileList = e.target.files ? e.target.files : e.dataTransfer.files;

            if(fileList.length === 0){
                return false;
            }

            let files = [];

            for(let i = 0; i < fileList.length; i++){
                files.push(fileList[i]);
            }

            // 今回は1ファイルのみ送ることにする。
            let file = files.length > 0 ? files[0] : [];
            if(file.type !== 'application/pdf'){
                return false;
            }
            if(file.size > 10000000){
                return false;
            }
            // fd_2.value = new FormData(); //★②
            fd_2.value.append('file', file);
            fd_2.value.append('corp_id', props.corp_info.id);

           const url = '/api/bnb/postAgreementPDF';

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            isProcessing.value = true;
            axios.post(url, fd_2.value, config).then(res => {
                agreement_array.value = [...res.data];
            }).finally(fin => {
                e.target.value = null;
                isProcessing.value = false;
            });
        }


        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }
        
        const showStorageRule = () => {
            const url = '/bnb/4K';

            location.href = url;
        }

        const historyBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const agreement_array = ref([]);

        const downloadAgreement = (id, fileName) => {
            const url = '/api/bnb/downloadAgreement';

            isProcessing.value = true;
            axios.post(url, {
                'id': id
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = fileName;
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);

            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const deleteAgreement = (id, index) => {
            if(!confirm(agreement_array.value[index].name + "を削除します。よろしいですか？")){
                return false;
            }

            const url = '/api/bnb/deleteAgreement';

            isProcessing.value = true;
            axios.delete(url, {data: {'id': id}})
            .then(res => {
                agreement_array.value = [...res.data];
            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const backHome = () => {
            const url = '/bnb/home';
            location.href=url;
        }

        return {
            setLevel,
            isProcessing,
            isEnter,
            dragEnter,
            dragLeave,
            files,
            fd_2,
            dropFile,
            callReplaceWord,
            showStorageRule,
            historyBack,
            agreement_array,
            downloadAgreement,
            deleteAgreement,
            backHome,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        agreement_list: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
#notice_area {
    overflow: scroll;
    height: 21em;
}

.border-notice {
    border-bottom: 3px solid #7f7f7f;
}

.btn-bnb-back {
    border: 1px solid;
    color: #1e4e79;
    border-color: #bdd6ee;
    background-color: #bdd6ee;
}

.btn-bnb-back:hover {
    border: 1px solid;
    color: #bdd6ee;
    background-color: #1e4e79;
}

.no-display {
    display: none;
}

.btn-upload-blue {
    border: 1px solid;
    color: white;
    border-color: #1f4e78;
    background-color: #1f4e78;
}

.enter {
    border: 1px solid;
    color: #1f4e78;
    border-color: #1f4e78;
    background-color: white;
}

.font-color-danger{
    color: #C00000;
}

</style>