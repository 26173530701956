<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2" v-if="isViewLimit">閲覧制限｜あり</label>
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2" v-else>閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label text-nowrap">様式18</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 保険代理店と損害補償の範囲の情報について以下に整理する。</label>
                </div>
            </div>

            <div v-for="(insurance, index) in insurance_infos" :key="index">
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">保険情報リスト</label>
                    </div>
                </div>

                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">保険代理店名</label>
                    </div>
                    <div class="p2 col-md-9 border border-bottom-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.組織名 }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">住所</label>
                    </div>
                    <div class="p2 col-md-9 border border-bottom-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : format_address(insurance.都道府県, insurance.市区町村, insurance.番地) }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">連絡先部署</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.担当者_部署 }}</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">担当者名</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.担当者_担当者名 }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">連絡先電話</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.担当者_電話番号 }}</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">携帯電話等</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.担当者_携帯電話番号 }}</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-3 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">FAX番号</label>
                    </div>
                    <div class="p2 col-md-3 border border-end-md-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.担当者_FAX番号 }}</label>
                    </div>
                    <div class="p2 col-md-3 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">E-mail</label>
                    </div>
                    <div class="p2 col-md-3 border bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.担当者_メールアドレス }}</label>
                    </div>
                </div>

                <div class="row mt-3 ms-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="form-label" style="font-size:12pt">保険証情報</label>
                    </div>
                </div>

                <div class="row mt-md-3 ms-1">
                    <div class="col-md-2">
                        
                    </div>
                </div>
                
                <div class="row mt-md-3 ms-md-1">
                    <div class="col-md-10">
                        <table class="table table-bordered border-dark" id="insurance_list">
                            <thead>
                                <tr class="bg-gray-3">
                                    <th>保険のタイプ</th>
                                    <th>保険証番号</th>
                                    <th>免責金額</th>
                                    <th>補償限度額</th>
                                    <th>補償範囲</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-resilience-light-green" v-for="(policy, idx) in insurance.policy_list" :key="idx" >
                                    <td>{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.type }}</td>
                                    <td>{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.insurance_no }}</td>
                                    <td>{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.deductible_amount }}</td>
                                    <td>{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.compensation_limit }}</td>
                                    <td>{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.compensation_limit }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="col col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">保険のタイプ</label>
                    </div>
                    <div class="col col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">保険証番号</label>
                    </div>
                    <div class="col col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">免責金額</label>
                    </div>
                    <div class="col col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">補償限度額</label>
                    </div>
                    <div class="col col-md-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">補償範囲</label>
                    </div> -->
                </div>
                <!-- <div class="row ms-md-1" v-for="(policy, idx) in insurance.policy_list" :key="idx" >
                    <div class="col col-md-2 border border-end-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.type }}</label>
                    </div>
                    <div class="col col-md-2 border border-end-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.insurance_no }}</label>
                    </div>
                    <div class="col col-md-2 border border-end-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.deductible_amount }}</label>
                    </div>
                    <div class="col col-md-2 border border-end-0 bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.compensation_limit }}</label>
                    </div>
                    <div class="col col-md-2 border bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : policy.coverage }}</label>
                    </div>
                </div> -->

                <div class="row mt-3 ms-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="col-form-label">損害補償の範囲検討</label>
                    </div>
                </div>
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">洪水保険を必要としますか？</label>
                    </div>
                    <div class="p2 col-md-3 border bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.洪水保険有無 == 1 ? 'はい' : 'いいえ' }}</label>
                    </div>
                </div>
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">地震保険を必要としますか？</label>
                    </div>
                    <div class="p2 col-md-3 border bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.地震保険有無 == 1 ? 'はい' : 'いいえ' }}</label>
                    </div>
                </div>
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                        <label class="col-form-label">事業収益及びを特別損失に関する保険を必要としますか？</label>
                    </div>
                    <div class="p2 col-md-3 border bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.特別損失保険有無 == 1 ? 'はい' : 'いいえ' }}</label>
                    </div>
                </div>
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md d-flex align-items-center justify-content-start">
                        <label class="col-form-label">その他の災害関連の保険に関する質問 等</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-md-12 border bg-resilience-light-green d-flex align-items-center justify-content-start">
                        <label class="col-form-label">{{ !is('administrator') && isViewLimit ? '&emsp;' : insurance.その他 ?? '&emsp;' }}</label>
                    </div>
                </div>

                <hr>
            </div>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);

        const isViewLimit = ref(false);

        const insurance_infos = reactive([{
            会員ID: '',
            組織名: '',
            都道府県: '',
            市区町村: '',
            番地: '',
            url: '',
            電話番号: '',
            担当者_部署: '',
            担当者_担当者名: '',
            担当者_電話番号: '',
            担当者_携帯電話番号: '',
            担当者_FAX番号: '',
            担当者_メールアドレス: '',
            洪水保険有無: 0,
            地震保険有無: 0,
            特別損失保険有無: 0,
            その他: '',
            policy_list: [{
                type: '',
                insurance_no: '',
                deductible_amount: '',
                compensation_limit: '',
                coverage: '',
            }],

        }]);

        onMounted(() => {
            if(props.insurance_list.length > 0){
                insurance_infos.splice(0);
                for(let i = 0; i < props.insurance_list.length; i++){
                    const ins_policy_list = [];
                    for(let j = 0; j < props.insurance_list[i].policy.length; j++){
                        ins_policy_list.push({
                            type: props.insurance_list[i].policy[j].type,
                            insurance_no: props.insurance_list[i].policy[j].insurance_no,
                            deductible_amount: props.insurance_list[i].policy[j].deductible_amount,
                            compensation_limit: props.insurance_list[i].policy[j].compensation_limit,
                            coverage: props.insurance_list[i].policy[j].coverage,
                        })
                    }
                    if(ins_policy_list.length === 0){
                        ins_policy_list.push({
                            type: '',
                            insurance_no: '',
                            deductible_amount: '',
                            compensation_limit: '',
                            coverage: '',
                        })
                    }
                    

                    insurance_infos.push({
                        会員ID: props.insurance_list[i]['会員ID'],
                        組織名: props.insurance_list[i].組織名,
                        都道府県: props.insurance_list[i].都道府県,
                        市区町村: props.insurance_list[i].市区町村,
                        番地: props.insurance_list[i].番地,
                        url: props.insurance_list[i].url,
                        電話番号: props.insurance_list[i].電話番号,
                        担当者_部署: props.insurance_list[i].担当者_部署,
                        担当者_担当者名: props.insurance_list[i].担当者_担当者名,
                        担当者_電話番号: props.insurance_list[i].担当者_電話番号,
                        担当者_携帯電話番号: props.insurance_list[i].担当者_携帯電話番号,
                        担当者_FAX番号: props.insurance_list[i].担当者_FAX番号,
                        担当者_メールアドレス: props.insurance_list[i].担当者_メールアドレス,
                        洪水保険有無: props.insurance_list[i].洪水保険有無,
                        地震保険有無: props.insurance_list[i].地震保険有無,
                        特別損失保険有無: props.insurance_list[i].特別損失保険有無,
                        その他: props.insurance_list[i].その他,
                        policy_list: ins_policy_list,
                    });

                }
            }

            if (props.bcp_values_info.length > 0) {
                props.bcp_values_info.forEach(fetchData =>{
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        isViewLimit.value = fetchData.value == 1 ? true : false;
                    }
                });
            }

        });

        const showBack = () => {
            const url = '/browse/C1d';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C3';
            location.href = url;
        }

        const format_address = (都道府県, 市区町村, 番地) => {
            return (都道府県 ?? '') + (市区町村 ?? '') + (番地 ?? '');
        }

        return {
            setLevel,
            isMargin1,
            isViewLimit,
            insurance_infos,

            showBack,
            showNext,
            format_address,
        }
    },
    props:{
        bcp_basic_frame_info: {
            type: Object,
        },
        insurance_list: {
            type: Object,
        },
        bcp_values_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
#insurance_list {
    font-size: 0.5rem;
}
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-9 {
        width: 75%;
    }
}
</style>
