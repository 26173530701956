<template>
    <div class="container" v-if="confirmMode === false">
        <div class="row mt-3">
            <div class="heading mt-3">企業ID・パスワード生成</div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">組織・企業の情報</label></div>
        </div>
        <form @submit.prevent="post_form">
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">組織・企業の名称</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-6 me-5"><input type="text" class="form-control" v-model="input組織名" required @input="handleCorpNameInput" id="corp_name_kanji"></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">フリガナ</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-6 me-5"><input type="text" class="form-control" v-model="input組織名_カナ" required></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">住所</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <label class="col-md-1 col-form-label">郵便番号</label>
                <div class="col-md-2 me-5"><input type="text" class="form-control" v-model="input郵便番号" required @blur="getAddress" maxlength="7"></div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-3"></div>
                <label class="col-md-1 col-form-label">都道府県</label>
                <div class="col-md-2 me-5"><input type="text" class="form-control" v-model="input都道府県" required maxlength="10"></div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-3"></div>
                <label class="col-md-1 col-form-label">市区町村</label>
                <div class="col-md-4 me-5"><input type="text" class="form-control" v-model="input市区町村" required maxlength="50"></div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <label class="col-md-1 col-form-label">町名・番地</label>
                <div class="col-md-6 me-5"><input type="text" class="form-control" v-model="input番地" required maxlength="255"></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">代表電話番号</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                
                <div class="col-md-6 me-5">
                    <div class="row">
                        <div class="col-2">
                            <input type="text" class="form-control" v-model="input電話番号1" required maxlength="10">
                            <div class="form-text">半角数字</div>
                        </div>
                        <label class="col-1 col-form-label">-</label>
                        <div class="col-2">
                            <input type="text" class="form-control" v-model="input電話番号2" required maxlength="10">
                        </div>
                        <label class="col-1 col-form-label">-</label>
                        <div class="col-2">
                            <input type="text" class="form-control" v-model="input電話番号3" required maxlength="10">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label">代表者</label>
                <label class="col-md-1 col-form-label">氏名</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <label class="col-form-label col-1">（姓）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='representative_family_name' v-model="input代表者名_姓" required @input="handleRepresentativeFamilyNameInput" maxlength="255">
                        </div>
                        <label class="col-form-label col-1">（名）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='representative_first_name' v-model="input代表者名_名" required @input="handleRepresentativeFirstNameInput" maxlength="255">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label">フリガナ</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <label class="col-form-label col-1">（姓）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='representative_family_name_kana' v-model="input代表者名_姓_カナ" required maxlength="255">
                        </div>
                        <label class="col-form-label col-1">（名）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='representative_first_name_kana' v-model="input代表者名_名_カナ" required maxlength="255">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label">役職</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <div class="col-4 offset-1">
                            <input type="text" class="form-control" v-model="input代表者_役職" required maxlength="255">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label">管理者</label>
                <label class="col-md-1 col-form-label">氏名</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <label class="col-form-label col-1">（姓）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='administrator_family_name' v-model="input管理者名_姓" required @input="handleAdministratorFamilyNameInput" maxlength="255">
                        </div>
                        <label class="col-form-label col-1">（名）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='administrator_first_name' v-model="input管理者名_名" required @input="handleAdministratorFirstNameInput" maxlength="255">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label">フリガナ</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <label class="col-form-label col-1">（姓）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='administrator_family_name_kana' v-model="input管理者名_姓_カナ" required maxlength="255">
                        </div>
                        <label class="col-form-label col-1">（名）</label>
                        <div class="col-4">
                            <input type="text" class="form-control" id='administrator_first_name_kana' v-model="input管理者名_名_カナ" required maxlength="255">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label">部署・役職</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <label class="col-form-label col-1">部署</label>
                        <div class="col-4">
                            <input type="text" class="form-control" v-model="input部署" required maxlength="255">
                        </div>
                        <label class="col-form-label col-1">役職</label>
                        <div class="col-4">
                            <input type="text" class="form-control" v-model="input役職" required maxlength="255">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label">電話番号</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                
                <div class="col-md-6 me-5">
                    <div class="row">
                        <div class="col-2">
                            <input type="text" class="form-control" v-model="input管理者電話番号1" required maxlength="10">
                            <div class="form-text">半角数字</div>
                        </div>
                        <label class="col-1 col-form-label">-</label>
                        <div class="col-2">
                            <input type="text" class="form-control" v-model="input管理者電話番号2" required maxlength="10">
                        </div>
                        <label class="col-1 col-form-label">-</label>
                        <div class="col-2">
                            <input type="text" class="form-control" v-model="input管理者電話番号3" required maxlength="10">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-5">
                <div class="ms-3 col-md-3"></div>
                <div class="col-md-6 me-5">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="input代表電話番号と同じ" id="sameTel" @change="sameTel" />
                                <label class="form-check-label text-nowrap" for="sameTel">代表電話番号と同じ</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label text-nowrap">メールアドレス</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-6 me-5"><input type="mail" class="form-control" v-model="inputメールアドレス" required maxlength="255"></div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-1 col-form-label"></label>
                <label class="col-md-1 col-form-label text-nowrap">メルマガ登録</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-6 me-5 mt-2">
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" v-model="inputメルマガ登録" id='メルマガ登録あり' value="あり" />
                        <label for="メルマガ登録あり" class="form-check-label">登録を希望する</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" v-model="inputメルマガ登録" id='メルマガ登録なし' value="しない" />
                        <label for="メルマガ登録なし" class="form-check-label">登録を希望しない</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">業種<br />（日本標準産業分類）</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <label class="col-md-2 col-form-label">大分類（A～T）</label>
                <div class="col-md-3">
                    <select name="業種_大分類" class="form-select" v-model="業種_大分類" required @change="changeLargeClass">
                        <option type="text" v-for="large in japan_standard_industrial_large_list" :key="large.id" :value="large.大分類">{{ large.大分類 + ':' + large.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <label class="col-md-2 col-form-label">中分類（2桁）</label>
                <div class="col-md-3">
                    <select name="業種_中分類" class="form-select" v-model="業種_中分類" required @change="changeMiddleClass">
                        <option type="text" v-for="middle in middle_class_list" :key="middle.id" :value="middle.中分類">{{ middle.中分類 + ':' + middle.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <label class="col-md-2 col-form-label">小分類（3桁）</label>
                <div class="col-md-3">
                    <select name="業種_中分類" class="form-select" v-model="業種_小分類" required @change="changeMinorClass">
                        <option type="text" v-for="minor in minor_class_list" :key="minor.id" :value="minor.小分類">{{ minor.小分類 + ':' + minor.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <label class="col-md-2 col-form-label">細分類（4桁）</label>
                <div class="col-md-3">
                    <select name="業種_中分類" class="form-select" v-model="業種_細分類" required @change="changeSubClass">
                        <option type="text" v-for="sub in sub_class_list" :key="sub.id" :value="sub.細分類">{{ sub.細分類 + ':' + sub.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"><i class="bi bi-caret-right-fill"></i><label class="col-form-label">従課金情報登録</label></div>
                <div class="col-md-1 text-end"><label class="col-form-label">1</label></div>
                <label class="col-md-3 col-form-label">登録する拠点・支店の数</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-8">
                            <input type="number" class="form-control text-end" v-model="input支店数" required max="65535">
                        </div>
                        <label class="col-4 col-form-label">ヶ所</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 text-end"><label class="col-form-label">2</label></div>
                <label class="col-md-3 col-form-label">登録する利用者（役員・従業員）の数</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-8">
                            <input type="number" class="form-control text-end" v-model="input従業員数" required max="4294967295">
                        </div>
                        <label class="col-4 col-form-label">人</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 text-end"><label class="col-form-label">3</label></div>
                <label class="col-md-3 col-form-label">登録する利用者家族の数</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-8">
                            <input type="number" class="form-control text-end" v-model="input家族数" required max="4294967295">
                        </div>
                        <label class="col-4 col-form-label">人</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"></div>
                <div class="col-md-1 text-end"><label class="col-form-label">4</label></div>
                <label class="col-md-3 col-form-label">LINE連携オプション</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-8">
                            <select class="form-select" v-model="selectLINE連携オプション">
                                <option value="1">はい</option>
                                <option value="0">いいえ</option>
                            </select>
                            <div class="invalid-feedback">{{ LINE連携オプション_エラーメッセージ }}</div>
                        </div>
                        <label class="col-4 col-form-label"></label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">サイトの利用開始希望日<br />（年会費発生日）</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="row">
                        <label class="col-form-label">入力がない場合は、本日の日付となります。利用開始希望日の属する月から年会費が月割りで発生します。</label>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <input type="date" class="form-control" v-model="input利用開始希望日" required>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 px-5">
                <label class="ms-3 col-md-2 col-form-label">有償会員の種類</label>
                <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                <div class="col-md-8 me-5">
                    <div class="form-check mt-2">
                        <input class="form-check-input" type="radio" v-model="input会員種別" id='企業会員' value="企業会員" />
                        <label for="企業会員" class="form-check-label">企業会員<br />（個人事業主、法人等はこちらをご選択ください。）</label>
                    </div>
                    <div class="form-check mt-3">
                        <input class="form-check-input" type="radio" v-model="input会員種別" id='団体会員' value="団体会員" />
                        <label for="団体会員" class="form-check-label">団体会員<br />（会員を有する同業団体や組合等はこちらをご選択ください。）</label>
                    </div>
                    <div class="form-check mt-3">
                        <input class="form-check-input" type="radio" v-model="input会員種別" id='公共会員' value="公共会員" />
                        <label for="公共会員" class="form-check-label">公共会員<br />（地方自治体や学校等はこちらをご選択ください。）</label>
                    </div>
                    <!-- <div class="form-check mt-3">
                        <input class="form-check-input" type="radio" v-model="input会員種別" id='クラファン会員' value="クラウドファンディング会員" />
                        <label for="クラファン会員" class="form-check-label">クラファン会員<br />（クラウドファンディングにお申込みいただいた方が限定です。）</label>
                    </div> -->
                </div>
            </div>
            <div class="row mt-3 px-5">
                <div class="ms-3 col-md-2"><i class="bi bi-caret-right-fill"></i><label class="col-form-label">年会費受領状況</label></div>
                <div class="col-md-1 text-end"></div>
                <label class="col-md-1 col-form-label">{{ 年会費受領状況_年度 }}年度</label>
                <label class="col-md-2 col-form-label border-bottom border-dark text-end">{{ formatNumber(合計額) }}</label>
                <label class="col-md-1 col-form-label">円</label>
                <div class="col-md-4 me-5 mt-2">
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" v-model="年会費受領状況" id='受領済み' value="受領済み" />
                        <label for="受領済み" class="form-check-label">受領済み</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" v-model="年会費受領状況" id='未受領・請求書発行' value="未受領・請求書発行" />
                        <label for="未受領・請求書発行" class="form-check-label">未受領・請求書発行</label>
                    </div>
                </div>
            </div>
            <div class="row mt-5 px-5 pb-5 border-bottom border-dark">
                <label class="col-form-label">メモ（加入した経緯、引き継ぎ事項など）</label>
                <div class="col-md-8">
                    <textarea class="form-control" v-model="inputメモ" rows="5" ></textarea>
                </div>
            </div>

            <div class="row my-5 px-5">
                <div class="col-3 ms-5">
                    <button class="py-3 px-5 btn-black" type="submit">入力内容を確認する</button>
                </div>
            </div>
        </form>
    </div>
    <div class="container" v-else-if="confirmMode === true && showResult === false">
        <div class="row mt-3">
            <div class="heading mt-3">企業ID・パスワード生成</div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">組織・企業の情報</label></div>
        </div>
        <div class="mt-3 px-5">
            <table class="table">
                <tbody>
                    <tr>
                        <td colspan="2">組織・企業の名称</td>
                        <td colspan="4">{{ input組織名 }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" rowspan="2">住所</td>
                        <td colspan="4">{{ input郵便番号 }}</td>
                    </tr>
                    <tr>
                        <td colspan="4">{{ input都道府県 + input市区町村 + input番地 }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">代表電話番号</td>
                        <td colspan="4">{{ input電話番号1 + '-' + input電話番号2 + '-' + input電話番号3 }}</td>
                    </tr>
                    <tr>
                        <td rowspan="3">代表者</td>
                        <td>氏名</td>
                        <td colspan="4">{{ input代表者名_姓 + '　' + input代表者名_名 }}</td>
                    </tr>
                    <tr>
                        <td>フリガナ</td>
                        <td colspan="4">{{ input代表者名_姓_カナ + '　' + input代表者名_名_カナ }}</td>
                    </tr>
                    <tr>
                        <td>役職</td>
                        <td colspan="4">{{ input代表者_役職 }}</td>
                    </tr>
                    <tr>
                        <td rowspan="7">管理者</td>
                        <td>利用者ID</td>
                        <td colspan="4">{{ 利用者ID }}</td>
                    </tr>
                    <tr>
                        <td>氏名</td>
                        <td colspan="4">{{ input管理者名_姓 + '　' + input管理者名_名 }}</td>
                    </tr>
                    <tr>
                        <td>フリガナ</td>
                        <td colspan="4">{{ input管理者名_姓_カナ + '　' + input管理者名_名_カナ }}</td>
                    </tr>
                    <tr>
                        <td>部署・役職</td>
                        <td colspan="4">{{ input部署 + '　' + input役職 }}</td>
                    </tr>
                    <tr>
                        <td>電話番号</td>
                        <td colspan="4">{{ input管理者電話番号1 + '-' + input管理者電話番号2 + '-' + input管理者電話番号3 }}</td>
                    </tr>
                    <tr>
                        <td>メールアドレス</td>
                        <td colspan="4">{{ inputメールアドレス }}</td>
                    </tr>
                    <tr>
                        <td>メールアドレス</td>
                        <td colspan="4">{{ '登録' + inputメルマガ登録 }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">業種（日本標準産業分類）</td>
                        <td colspan="4"> {{ 業種_細分類 + '　' + get細分類名(業種_細分類) }}</td>
                    </tr>
                    <tr>
                        <td rowspan="4" colspan="2">従量課金情報登録</td>
                        <td>1</td>
                        <td>登録する拠点・支店の数</td>
                        <td>{{ input支店数 }}</td>
                        <td>ヶ所</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>登録する利用者（役員・従業員）の人数</td>
                        <td>{{ input従業員数 }}</td>
                        <td>人</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>登録する利用者家族の人数</td>
                        <td>{{ input家族数 }}</td>
                        <td>人</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>LINE連携オプション</td>
                        <td colspan="2">{{ selectLINE連携オプション == "1" ? "あり" : "なし" }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">サイトの利用開始希望日<br />（年会費発生日）</td>
                        <td colspan="4"> {{ this.$root.dayjs(input利用開始希望日).format('YYYY年MM月DD日') }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">有償会員の種類</td>
                        <td colspan="4"> {{ input会員種別 }}</td>
                    </tr>
                    <tr>
                        <td rowspan="3" colspan="2">有償会員の種類</td>
                        <td>受領年度</td>
                        <td colspan="3">{{ 年会費受領状況_年度 + '　年度' }}</td>
                    </tr>
                    <tr>
                        <td>年会費</td>
                        <!-- <td colspan="3">{{ formatNumber(年会費受領状況_年会費) + '　円' }}</td> -->
                        <td colspan="3">{{ formatNumber(合計額) + '　円' }}</td>
                    </tr>
                    <tr>
                        <td>受領状況</td>
                        <td colspan="3">{{ 年会費受領状況 }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="my-5 px-5">
            <div class="d-flex justify-content-between">
                <input type="button" class="btn btn-black py-3 px-5 " value="上記内容で登録し、会員向けの郵送書類をダウンロードします" @click="makeLoginInfo">
                <input type="button" class="btn btn-outline-register-button py-3 px-5 " value="入力画面に戻る" @click="returnInput">
            </div>
        </div>
    </div>
    <div class="container" v-else>
        <div class="row mt-3">
            <div class="heading mt-3">企業ID・パスワード生成</div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">ID・パスワード自動生成</label></div>
        </div>
        <div class="row mt-3">
            <table class="table table-bordered" id="resultTable">
                <tbody>
                    <tr>
                        <td>会員ID（会員番号）</td>
                        <td>{{ 企業ID }}</td>
                    </tr>
                    <tr>
                        <td>会員パスワード</td>
                        <td>{{ 企業パスワード }}</td>
                    </tr>
                    <tr>
                        <td>利用者ID</td>
                        <td>{{ 利用者ID }}</td>
                    </tr>
                    <tr>
                        <td>利用者パスワード</td>
                        <td>{{ ユーザーパスワード }}</td>
                    </tr>
                </tbody>
            </table>
            パスワードは、ログイン後マイページから変更することができます。
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">郵送書類</label></div>
        </div>
        <div class="row mt-3">
            <div class="col-6"><button class="btn btn-reports px-5" :disabled="isProcessing"><i class="bi bi-book"></i>　利用契約書をダウンロード</button></div>
            <div class="col-6"><button class="btn btn-reports px-5" :disabled="isProcessing" @click="makeReport('login_info')"><i class="bi bi-book"></i>　ID/パスワード会員企業向け通知文ダウンロード</button></div>
        </div>
        <div class="row mt-3">
            <div class="col-6"><button class="btn btn-reports px-5" :disabled="isProcessing"><i class="bi bi-book"></i>　個人情報保護方針をダウンロード</button></div>
            <div class="col-6"><button class="btn btn-reports px-5" :disabled="isProcessing" @click="makeReport('invoice')"><i class="bi bi-book"></i>　請求書PDFをダウンロード</button></div>
        </div>
        <div class="row my-3">
            <div class="col-6"><button class="btn btn-reports px-5" :disabled="isProcessing"><i class="bi bi-book"></i>　加入審査基準をダウンロード</button></div>
            <div class="col-6"><button class="btn btn-reports px-5" :disabled="isProcessing" @click="makeReport('receipt')"><i class="bi bi-book"></i>　領収書PDFをダウンロード</button></div>
        </div>
    </div>
</template>

<script>
import { computed, inject, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';
import { CommonMakePassword } from '../../function/CommonMakePassword.js';
import * as AutoKana from 'vanilla-autokana';
import axios from 'axios';

let autokana_representative_family;
let autokana_representative_first;
let autokana_administrator_family;
let autokana_administrator_first;
let autokana_corp_name;

export default {
    setup(props) {
        const dayjs = inject('dayjs');
        onMounted(() => {
            autokana_representative_family = AutoKana.bind('#representative_family_name');
            autokana_representative_first = AutoKana.bind('#representative_first_name');
            autokana_administrator_family = AutoKana.bind('#administrator_family_name');
            autokana_administrator_first = AutoKana.bind('#administrator_first_name');
            autokana_corp_name = AutoKana.bind("#corp_name_kanji");

            input利用開始希望日.value = dayjs().format('YYYY-MM-DD');
        });

        const handleRepresentativeFamilyNameInput = () => {
            input代表者名_姓_カナ.value = convertKatakana(autokana_representative_family.getFurigana());
        }

        const handleRepresentativeFirstNameInput = () => {
            input代表者名_名_カナ.value = convertKatakana(autokana_representative_first.getFurigana());
        }

        const handleAdministratorFamilyNameInput = () => {
            input管理者名_姓_カナ.value = convertKatakana(autokana_administrator_family.getFurigana());
        }

        const handleAdministratorFirstNameInput = () => {
            input管理者名_名_カナ.value = convertKatakana(autokana_administrator_first.getFurigana());
        }

        const handleCorpNameInput = () => {
            input組織名_カナ.value = convertKatakana(autokana_corp_name.getFurigana());
        }

        const convertKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function(match) {
                const chr = match.charCodeAt(0) + 0x60;
                return String.fromCharCode(chr);
            });
        }

        const confirmMode = ref(false);

        const input組織名 = ref('');
        const input組織名_カナ = ref("");
        const input郵便番号 = ref('');
        const input都道府県 = ref('');
        const input市区町村 = ref('');
        const input番地 = ref('');

        const getAddress = () => {
            const url = '/api/getAddressSeparate?zip=' + input郵便番号.value;

            axios.get(url).then(res =>{
                input都道府県.value = res.data.prefecture;
                input市区町村.value = res.data.municipalitie + res.data.town;
            });
        }

        const input電話番号1 = ref('');
        const input電話番号2 = ref('');
        const input電話番号3 = ref('');

        const input代表者名_姓 = ref('');
        const input代表者名_名 = ref('');
        const input代表者名_姓_カナ = ref('');
        const input代表者名_名_カナ = ref('');
        const input代表者_役職 = ref('');

        const input管理者名_姓 = ref('');
        const input管理者名_名 = ref('');
        const input管理者名_姓_カナ = ref('');
        const input管理者名_名_カナ = ref('');

        const 利用者ID = ref('000001');

        const input部署 = ref('');
        const input役職 = ref('');

        const input管理者電話番号1 = ref('');
        const input管理者電話番号2 = ref('');
        const input管理者電話番号3 = ref('');

        const input代表電話番号と同じ = ref(false);
        const sameTel = () => {
            if(input代表電話番号と同じ.value === true){
                input管理者電話番号1.value = input電話番号1.value;
                input管理者電話番号2.value = input電話番号2.value;
                input管理者電話番号3.value = input電話番号3.value;
            }
        }

        const inputメールアドレス = ref('');

        const inputメルマガ登録 = ref('あり');

        const 業種_大分類 = ref('');
        const 業種_中分類 = ref('');
        const 業種_小分類 = ref('');
        const 業種_細分類 = ref('');

        const get細分類名 = (細分類) => {
            const wkArray = sub_class_list.value.filter(ele => ele.細分類 === 細分類);
            return wkArray[0].項目名;
        }

        const middle_class_list = ref([]);
        const minor_class_list = ref([]);
        const sub_class_list = ref([]);

        const changeLargeClass = () => {
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMiddleClassList?largeClass=' + 業種_大分類.value;

            axios.get(url).then(res => {
                middle_class_list.value = res.data;
            });
        }

        const changeMiddleClass = () => {
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMinorClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value;

            axios.get(url).then(res => {
                minor_class_list.value = res.data;
            });
        }

        const changeMinorClass = () => {
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getSubClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value + '&minorClass=' + 業種_小分類.value;

            axios.get(url).then(res => {
                sub_class_list.value = res.data;
            });
        }

        const input支店数 = ref(1);
        const 従量_支店 = ref(0);
        const input従業員数 = ref(1);
        const 従量_従業員 = ref(0);
        const input家族数 = ref(0);
        const 従量_家族 = ref(0);
        const 従量_LINE = ref(0);

        const selectLINE連携オプション = ref("0");
        const LINE連携オプション_エラーメッセージ = ref("");

        const input利用開始希望日 = ref(0);

        const input会員種別 = ref('');

        watch(input会員種別, () => {
            getAnnualCharge(input会員種別.value, input利用開始希望日.value);
        });

        watch(input支店数, () =>{
            getAnnualCharge(input会員種別.value, input利用開始希望日.value);
        });

        watch(input従業員数, () =>{
            getAnnualCharge(input会員種別.value, input利用開始希望日.value);
        });

        watch(input家族数, () =>{
            getAnnualCharge(input会員種別.value, input利用開始希望日.value);
        });

        watch(selectLINE連携オプション, () =>{
            getAnnualCharge(input会員種別.value, input利用開始希望日.value);
        });

        const 年会費受領状況_年度 = ref('');
        const 年会費受領状況_年会費 = ref(0);
        const 年会費受領状況 = ref('');

        const 合計額 = computed(() => {
            return 年会費受領状況_年会費.value + 従量_支店.value + 従量_従業員.value + 従量_家族.value + 従量_LINE.value;
        });

        const getAnnualCharge = (会員種別, 利用開始希望日) => {
            if(会員種別 === ''){
                return false;
            }
            const url = '/api/getAnnualCharge?kind=' + 会員種別 + '&day=' + 利用開始希望日 + '&branch=' + input支店数.value + '&employee=' + input従業員数.value + '&family=' + input家族数.value + "&LINE連携=" + selectLINE連携オプション.value;

            axios.get(url).then(res => {
                年会費受領状況_年度.value = res.data.年度;
                年会費受領状況_年会費.value = res.data.年会費;
                従量_支店.value = res.data.従量_支店総額;
                従量_従業員.value = res.data.従量_従業員総額;
                従量_家族.value = res.data.従量_家族総額;
                従量_LINE.value = res.data.従量_LINE連携;
            });
        }

        const inputメモ = ref('');

        const formatNumber = (price) => {
            return CommonNumberFormat(price);
        }

        const makePassword = (length) => {
            return CommonMakePassword(length);
        }

        const post_form = () =>{
            confirmMode.value = true;
        }

        const showResult = ref(false);

        const 企業ID = ref('');
        const 企業パスワード = ref('');
        const ユーザーパスワード = ref('');
        const corp_id = ref('');
        const bill_id = ref('');
        const 有効期限日 = ref('');

        const makeLoginInfo = () => {
            const url = '/api/s-admin/registerLoginInfo';

            axios.post(url, {
                組織名: input組織名.value,
                組織名_フリガナ: input組織名_カナ.value,
                郵便番号: input郵便番号.value,
                都道府県: input都道府県.value,
                市区町村: input市区町村.value,
                町名番地: input番地.value,
                電話番号: input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                代表者名_姓: input代表者名_姓.value,
                代表者名_名: input代表者名_名.value,
                代表者_役職: input代表者_役職.value,
                代表者名_カナ_姓: input代表者名_姓_カナ.value,
                代表者名_カナ_名: input代表者名_名_カナ.value,
                管理者名_姓: input管理者名_姓.value,
                管理者名_名: input管理者名_名.value,
                管理者名_カナ_姓: input管理者名_姓_カナ.value,
                管理者名_カナ_名: input管理者名_名_カナ.value,
                管理者_所属部署: input部署.value,
                管理者_役職: input役職.value,
                管理者_電話番号: input管理者電話番号1.value  + '-' + input管理者電話番号2.value + '-' + input管理者電話番号3.value,
                email: inputメールアドレス.value,
                メルマガ登録: inputメルマガ登録.value === 'あり' ? true : false,
                拠点数: input支店数.value,
                利用者数: input従業員数.value,
                利用家族数: input家族数.value,
                利用開始日: input利用開始希望日.value,
                LINE連携: selectLINE連携オプション.value,
                請求従業員従量課金: 従量_従業員.value,
                請求支店従量課金: 従量_支店.value,
                請求家族従量課金: 従量_家族.value,
                請求LINE従量課金: 従量_LINE.value,

                会員種別: input会員種別.value,
                memo: inputメモ.value,
                業種: 業種_細分類.value,

                利用者ID: 利用者ID.value,
                年会費受領状況: 年会費受領状況.value === '受領済み' ? true : false,
                年会費受領状況_年会費: 年会費受領状況_年会費.value,
                年会費受領状況_年度: 年会費受領状況_年度.value,
                合計額: 年会費受領状況_年会費.value + 従量_支店.value + 従量_従業員.value + 従量_家族.value + 従量_LINE.value,

                請求方法区分: 10050004, //請求書払い

            }).then(res=>{
                showResult.value = true;

                企業ID.value = res.data.corp.企業ID;
                企業パスワード.value = res.data.password;
                ユーザーパスワード.value = res.data.user_password;

                corp_id.value = res.data.corp.id;
                bill_id.value = res.data.bill.id;
                有効期限日.value = res.data.有効期限日;
            });
            
        }

        const returnInput = () => {
            confirmMode.value = false;
        }

        const isProcessing = ref(false);

        const makeReport = (report_name) => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            let array_para;
            let report_japanese_name;

            switch(report_name) {
                case 'invoice':
                    array_para = {
                        ReportName: 'invoice_from_payment_status',
                        bill_id: bill_id.value,
                        corp_id: corp_id.value,
                    }
                    report_japanese_name = '請求書';
                    break;
                case 'receipt':
                    array_para = {
                        'ReportName': 'receipt',
                        'bill_id': bill_id.value,
                        'corp_id': corp_id.value,
                    }
                    report_japanese_name = '領収書';
                    break;
                case 'login_info': 
                    array_para = {
                        ReportName: 'login_info',
                        開始日: input利用開始希望日.value,
                        有効期限日: 有効期限日.value,
                        corp_id: 企業ID.value,
                        corp_password: 企業パスワード.value,
                        user_id: 利用者ID.value,
                        user_password: ユーザーパスワード.value,
                        LINE連携: selectLINE連携オプション.value,
                    }
                    report_japanese_name = 'ログインパスワード通知';
                    break;
            }
            axios.post(url, array_para,{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = report_japanese_name + '.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return{
            handleRepresentativeFamilyNameInput,
            handleRepresentativeFirstNameInput,
            handleAdministratorFamilyNameInput,
            handleAdministratorFirstNameInput,
            handleCorpNameInput,
            convertKatakana,
            formatNumber,
            makePassword,

            confirmMode,
            
            input組織名,
            input組織名_カナ,
            input郵便番号,
            input都道府県,
            input市区町村,
            input番地,
            
            getAddress,

            input電話番号1,
            input電話番号2,
            input電話番号3,

            input代表者名_姓,
            input代表者名_名,
            input代表者名_姓_カナ,
            input代表者名_名_カナ,
            input代表者_役職,

            input管理者名_姓,
            input管理者名_名,
            input管理者名_姓_カナ,
            input管理者名_名_カナ,

            利用者ID,

            input部署,
            input役職,

            input管理者電話番号1,
            input管理者電話番号2,
            input管理者電話番号3,

            input代表電話番号と同じ,
            sameTel,

            inputメールアドレス,

            inputメルマガ登録,

            業種_大分類,
            業種_中分類,
            業種_小分類,
            業種_細分類,

            get細分類名,

            middle_class_list,
            minor_class_list,
            sub_class_list,
            changeLargeClass,
            changeMiddleClass,
            changeMinorClass,

            input支店数,
            従量_支店,
            input従業員数,
            従量_従業員,
            input家族数,
            従量_家族,

            selectLINE連携オプション,
            LINE連携オプション_エラーメッセージ,
            従量_LINE,

            input利用開始希望日,

            input会員種別,

            年会費受領状況_年度,
            年会費受領状況_年会費,
            年会費受領状況,
            合計額,

            inputメモ,

            getAnnualCharge,

            post_form,

            makeLoginInfo,
            returnInput,

            showResult,

            企業ID,
            企業パスワード,
            ユーザーパスワード,
            corp_id,
            bill_id,
            有効期限日,

            isProcessing,

            makeReport,
        }
    },
    props: {
        japan_standard_industrial_large_list: {
            type: Array,
        }
    },
}
</script>

<style lang="scss" scoped>
    #resultTable tr td:nth-of-type(1) {
        width: 20%;
    }

    .btn-reports {
        border: 1px solid;
        color: white;
        border-color: #ed7d31;
        background-color: #ed7d31;
    }

    .btn-reports:hover {
        border: 1px solid;
        color: #ed7d31;
        border-color: #ed7d31;
        background-color: white;
    }

</style>
