<template>
    <div id="confirm-modal" v-show="modalConfirmVisible">
        <div id="content">
            <div class="btn-close" @click="closeConfirm"></div>
            <label>BCPを「初版」として保存し、{{ callReplaceWord('従業員') }}が閲覧できるようなります。<br />よろしいですか？</label>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-bcp-introduction" @click="viewSelect">初版として確定、閲覧を開始する</button>
            </div>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-new-window-button" @click="closeConfirm">キャンセル</button>
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="modalSelectPageVisible">
        <div id="content">
            <label>初版データを確定しました。閲覧ページからご確認ください。</label>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-bcp-restrictions" @click="showBCPBrowse">BCP閲覧ページへ</button>
            </div>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-new-window-button" @click="showBackContents">目次構成へ戻る</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            初版の作成年月日
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 position-relative" id="complete_message">
        <div class="position-absolute speech_bubble">おつかれさまでした。<span class="fw-bold font-color-basic">入門コース</span>のBCP策定が終了しました。<br />これを「第初版」として確定し、{{ callReplaceWord('従業員') }}が閲覧できるようにします。<br />作成年月日を入力してください。</div>
        <i id="chatbot-A" class="position-absolute"></i>
    </div>

    <div class="row ms-4">
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
            初版の作成年月日
        </div>
        <div class="row ms-4 col-md-2">
            <input type="date" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="2022/7/21" v-model="creationDate">
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label >入門コースを策定し終えたら初版BCPとして、{{ callReplaceWord('従業員') }}に周知しましょう。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-5 ms-4">
        <div class="row ms-4">
            <input type="button" class="btn btn-bcp-introduction btn-sm width-400 py-2" value="入門｜初版として確定、閲覧を開始する" :disabled="isProcessing" @click="viewConfirm"/>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label >続けて「基本コース」のBCPを策定したい場合も、いったん「初版」を確定してください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>
    <div class="row mt-3 pb-5 ms-4">
        <div class="row ms-4">
            <input type="button" class="btn btn-bcp-introduction btn-sm width-400 py-2" value="入門｜いったん保存し目次へ戻る" :disabled="isProcessing" @click="showContents" />
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label >確定する前に入力項目をチェックしたい場合は、こちらをご選択ください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.bcp_version_info.length > 0){
                creationDate.value = props.bcp_version_info[0].作成日;
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const modalConfirmVisible = ref(false);
        const modalSelectPageVisible = ref(false);
        const creationDate = ref('')
        let isCompleted = false;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // // BCP初版の作成日のみ一旦登録（確定ではない）
            // isCompleted = false;
            // const result = await registerIntroductionComplete(isCompleted);

            // if (result == '200') {
                // 前のページへ遷移
                const url = '/bcp/bcp_introduction/8';
                location.href = url;
            // }
        }

        const showBCPBrowse = () => {
            const url = '/browse/home';
            location.href = url;
        }

        const showBackContents = async () => {
            // 目次ページへ遷移
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        const showContents = async () => {
            // BCP初版の作成日のみ一旦登録（確定ではない）
            isCompleted = false;
            const result = await registerIntroductionComplete(isCompleted);

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 確認モーダルの表示
        const viewConfirm = () => {
            modalConfirmVisible.value = true;
            modalSelectPageVisible.value = false;
        }

        // 確認モーダルを閉じる
        const closeConfirm = () => {
            modalConfirmVisible.value = false;
            modalSelectPageVisible.value = false;
        }

        // 画面選択モーダルの表示
        const viewSelect = async () => {
            // 確認モーダルを閉じる
            modalConfirmVisible.value = false;
            isCompleted = true;

            // BCP初版を登録
            const result = await registerIntroductionComplete(isCompleted);

            if (result == '200') {
                // 次のモーダル表示
                modalSelectPageVisible.value = true;
            }
        }

        // 初版登録
        const registerIntroductionComplete = async (isCompleted) => {
            const postUrl = '/api/bcp/registerIntroductionComplete';
            const form = [
                {
                    version: 1,
                    create_date: creationDate.value == null ? new Date().toLocaleDateString() : creationDate.value,
                    is_completed: isCompleted,
                }
            ]
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'bcp_id': props.bcp_title_info.id,
                'form_list': form,
            }).then(res => {
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            modalConfirmVisible,
            modalSelectPageVisible,
            creationDate,
            callReplaceWord,
            showBack,
            showBCPBrowse,
            showBackContents,
            showContents,
            viewConfirm,
            closeConfirm,
            viewSelect,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_title_info: {
            type: Object,
        },
        bcp_version_info: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
.speech_bubble{
    position:relative;
    width:600px;
    height:100px;
    background:#EEEEEE;
    padding:10px;
    text-align:left;
    border:1px solid #9F9F9F;
    color:#666666;
    font-size:15px;
    border-radius:8px;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    top: 40px;
    left: 30px;
}
.speech_bubble:after,.speech_bubble:before{
    border: solid transparent;
    content:'';
    height:0;
    width:0;
    pointer-events:none;
    position:absolute;
    left:100%;
    top:50%;
}
.speech_bubble:after{
    border-color: rgba(238, 238, 238, 0);
    border-top-width:10px;
    border-bottom-width:10px;
    border-left-width:20px;
    border-right-width:20px;
    margin-top: -10px;
    border-left-color:#EEEEEE;
}
.speech_bubble:before{
    border-color: rgba(159, 159, 159, 0);
    border-top-width:11px;
    border-bottom-width:11px;
    border-left-width:22px;
    border-right-width:22px;
    margin-top: -11px;
    margin-left: 1px;
    border-left-color:#9F9F9F;
}

#chatbot-A {
    display: inline-block;
    width: 90px;
    height: 151px;
    background-image: url('../../../../svg/09_A.svg');
    background-size: 90px auto;
    background-repeat: no-repeat;
    top: 20px;
    left: 650px;
}

#complete_message {
    height: 200px;
}

.width-600 {
    width: 600px;
}

.width-400 {
    width: 400px;
}

.font-color-basic {
    color: #4472c4;
}

.font-color-middle {
    color: #2353a4;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 30%;
    padding: 1em 3em;
    background: #fff;
}

.btn-close {
    position: relative;
    top: 0px;
    left: 490px;
}

.btn-close:hover{
    cursor: pointer;
}
</style>

