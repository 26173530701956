<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:12pt">
            4. 財務診断と事前対策計画｜損益計算書・完成工事原価計算書
        </div>

        
        <div class="col-md-4 d-flex justify-content-evenly">
            <h5 class="align-self-end"><span class="badge bg-resilience-green text-light">閲覧・出力なし</span></h5>
            <h4 class="align-self-end"><span class="badge bg-badge-blue">建設業</span></h4>
            <h5 class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る"></h5>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

<!--------------------------------------○ 損益計算書---------------------------------------------------------------->

    <div class="row mt-3 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">○ 損益計算書</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-5 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            科目
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
            金額
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>直接原価計算による損益計算書・製造原価計算書を作成します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            売上高
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            完成工事高
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="完成工事高" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            売上原価
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            完成工事原価
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="完成工事原価" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-end">
            （内減価償却費）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white  text-end" id="（内減価償却費）" placeholder="5">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            売上総利益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            完成工事総利益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="完成工事総利益" placeholder="0" disabled>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            一般管理費および販売費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="一般管理費および販売費" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-end">
            （内減価償却費）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white  text-end" id="（内減価償却費）" placeholder="3">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            営業利益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="営業利益" placeholder="-1" disabled>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            営業外収益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="営業外収益" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            営業外費用
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="営業外費用" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            経常利益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="経常利益" placeholder="-1" disabled>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            特別利益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="特別利益" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            特別損失
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="特別損失" placeholder="1">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            税引前当期純利益
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="税引前当期純利益" placeholder="-1" disabled>
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            法人税・住民税
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="法人税・住民税" placeholder="3">
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-4 border border-dark border-end-0 d-flex align-items-center justify-content-start">
            税引後当期純利益
        </div>
        <div class="p2 col-md-2 border border-dark d-flex align-items-center justify-content-end">
            <input type="text" class="form-control border-white text-end" id="税引後当期純利益" placeholder="-4" disabled>
        </div>
    </div>

<!--------------------------------------○ 一般管理費・販売費---------------------------------------------------------------->

    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">○ 一般管理費・販売費</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-8 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            固定費
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0  bg-light-gray d-flex align-items-center justify-content-start">
            科目
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            金額
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 border border-dark border-top-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-top-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            内訳
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            合計
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            1. 労務費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="1. 労務費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            2. 建設現場旅費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="2. 建設現場旅費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            3. 広告宣伝費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="3. 広告宣伝費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            4. 発送費，配達費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="4. 発送費，配達費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            5. 役員報酬
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="5. 役員報酬" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            6. 事務員給与手当
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="6. 事務員給与手当" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            7. 減価償却費　
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="7. 減価償却費　" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            8. 地代･家賃
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="8. 地代･家賃" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            9. 修繕費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="9. 修繕費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            10. 事務用消耗品費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="10. 事務用消耗品費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
           11. 通信費・交通費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="11. 通信費・交通費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            12. 雑費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="12. 雑費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            13. その他（固定費）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="13. その他（固定費）" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            14. その他 （変動費）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="14. その他 （変動費）" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            一般管理費・販売費　計
        </div>
        <div class="p2 col-md-2 border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="一般管理費・販売費　計" placeholder="42">
        </div>
    </div>

<!--------------------------------------○ 完成工事原価計算書---------------------------------------------------------------->

<div class="row mt-3 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">○ 完成工事原価計算書</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-8 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            固定費
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0  bg-light-gray d-flex align-items-center justify-content-start">
            科目
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            金額
        </div>
    </div>
    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 border border-dark border-top-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-top-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            内訳
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            合計
        </div>
    </div>

<!--------------------------------------Ⅰ  材料費---------------------------------------------------------------->

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            Ⅰ  材料費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            1. 主要材料費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="1. 主要材料費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            2. 買入部品費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="2. 買入部品費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            3. 補助材料費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="3. 補助材料費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            4. 仮設材料の損耗額
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="4. 仮設材料の損耗額" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            5. 仮設材料の賃貸額
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="5. 仮設材料の賃貸額" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            当期材料費（1＋2＋3＋4＋5）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="当期材料費（1＋2＋3＋4＋5）" placeholder="15" disabled>
        </div>
    </div>

    <!--------------------------------------Ⅱ  労務費---------------------------------------------------------------->

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            Ⅱ  労務費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            ◯
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            1. 基本給
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="1. 基本給" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            2. 諸手当、福利厚生費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="2. 諸手当、福利厚生費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            当期労務費（1＋2）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="当期労務費（1＋2）" placeholder="6" disabled>
        </div>
    </div>

<!--------------------------------------Ⅲ  経費---------------------------------------------------------------->

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            Ⅲ  経費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            1. 電力費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="1. 電力費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            2. ガス・水道料
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="2. ガス・水道料" placeholder="4">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            3. 運賃
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="3. 運賃" placeholder="5">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            4. 減価償却費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="4. 減価償却費" placeholder="6">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            5. 修繕費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="5. 修繕費" placeholder="7">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            6. 租税・公課
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="6. 租税・公課" placeholder="8">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            7. 不動産賃借料
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="7. 不動産賃借料" placeholder="9">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            8. 保険料
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="8. 保険料" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            9. 旅費・交通費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="9. 旅費・交通費" placeholder="3">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            10. 通信費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="10. 通信費" placeholder="4">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            11. 外注加工費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="11. 外注加工費" placeholder="5">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            12. 雑費
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="12. 雑費" placeholder="6">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            13. その他（固定費）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="13. その他（固定費）" placeholder="7">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            14. その他（変動費）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="14. その他（変動費）" placeholder="8">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-3 border border-dark border-start-0 border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            当期経費（1～14 の合計）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="当期経費（1～14 の合計）" placeholder="78" disabled>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            1.  当期製造総費用（Ⅰ＋Ⅱ＋Ⅲ）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="1.  当期製造総費用（Ⅰ＋Ⅱ＋Ⅲ）" placeholder="87">
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            2.  期首仕掛品棚卸高
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="2.  期首仕掛品棚卸高" placeholder="20">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            合　計（1＋2）
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="合　計（1＋2）" placeholder="107" disabled>
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
            3.  期末仕掛品棚卸高
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="3.  期末仕掛品棚卸高" placeholder="2">
        </div>
        <div class="p2 col-md-2 border border-dark border-bottom-0 d-flex align-items-center justify-content-center">
            &emsp;
        </div>
    </div>

    <div class="row ms-3 d-flex flex-row">
        <div class="p2 col-md-1 text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-4 border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
            当期製品製造原価（1＋2－3）
        </div>
        <div class="p2 col-md-2 border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            &emsp;
        </div>
        <div class="p2 col-md-2 border border-dark bg-light-gray d-flex align-items-center justify-content-center">
            <input type="text" class="form-control border-white text-end" id="当期製品製造原価（1＋2－3）" placeholder="105" disabled>
        </div>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md text-center btn btn-bcp-middle" value="中級｜4. 財務診断と事前対策計画｜資産復旧費用の算定 へ進む" @click="showNext">
            </div>
        </div>
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-4 text-center btn btn-bcp-basic" value="目次構成 へ戻る">
            </div>
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('001000');

        const showNext = () => {
            const url = '/bcp/bcp_financial/製2';

            location.href = url;
        }

        return {
            setLevel,

            showNext,
        }
    },
    props:{
    }

}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-blue {
    background-color: #9bc2e6;
    border: 1px solid #404040;
    color: #404040;
}
</style>