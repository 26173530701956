<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　中核{{ callReplaceWord('事業') }}と復旧目標 ｜中核{{ callReplaceWord('事業') }}に係る情報
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-advanced btn-sm" :value="'上級｜中核' + callReplaceWord('事業') + '影響度評価フォーム へ進む'" v-show="is_completed_middle" :disabled="isProcessing" @click="showAdvanced">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る"  :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="card bg-member_card">
        <div class="card-body">
            <div class="row ms-3 ">
                <div class="p2 ms-4 col-md-8 text-dark d-flex align-items-end justify-content-start">
                    中核{{ callReplaceWord('事業') }}
                </div>
            </div>

            <div class="row ms-3 ">
                <input type="text" class="p2 ms-4 col-md-6 bg-blue text-white d-flex align-items-center justify-content-start py-2" style="font-size:14pt" placeholder="○○社向けの△△商品" v-model="coreBusinessContent">
                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>あなたの{{ callReplaceWord('組織') }}におけるいくつかの事業において、「○○事業の操業が停止してしまったらどうなるか？」、「どのような損害が出るか？」をイメージしながら考えてみてください。<br />
                                        ①あなたの{{ callReplaceWord('組織') }}の売上げに最も寄与している事業は何ですか？<br />
                                        ②{{ callReplaceWord('顧客') }}と確約している{{ callReplaceWord('商品') }}やサービスの提供期限が定められている事業のうち、その延滞があなたの{{ callReplaceWord('組織') }}に与える損害が最も大きい事業は何ですか？また、その事業は、どの程度の遅延時間までならば許容されますか?<br />
                                        ③あなたの{{ callReplaceWord('組織') }}に課せられている法的または財政的な責務はありますか？ある場合、それ満たすためには、どの事業が必要ですか?<br />
                                        ④市場シェアや{{ callReplaceWord('組織') }}の評判を維持するためには、どの事業が重要ですか？</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>

            <div class="row ms-3 ">
                <div class="p2 ms-4 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start">
                    中核{{ callReplaceWord('事業') }}の社内責任者 氏名
                </div>
                <div class="p2 ms-4 mt-3 col-md-4 text-dark d-flex align-items-end justify-content-start">
                    役職
                </div>
            </div>

            <div class="row ms-3 ">
                <div class="p2 ms-4 col-md-4 d-flex align-items-center justify-content-start">
                    <select name="selectAdminCandidate" class="form-select" v-model="inCompanyManager" @change="setJobTitle(inCompanyManager)">
                        <option type="text" v-for="adminCandidate in admin_candidate_list" :key="adminCandidate" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                    </select>
                </div>
                <div class="p2 ms-4 col-md-4 d-flex align-items-center justify-content-start">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="jobTitle">
                </div>
            </div>

            <div class="row ms-3 ">
                <div class="p2 ms-4 mt-3 col-md-6 text-dark d-flex align-items-end justify-content-start">
                    中核{{ callReplaceWord('事業') }}中断の場合の損失額（含む違約金等）
                </div>
            </div>

            <div class="row ms-3 pb-5">
                <div class="p2 ms-4 col-md-2 d-flex align-items-center justify-content-start">
                    <input type="number" class="form-control d-flex align-items-center justify-content-center py-2 text-end" placeholder="" v-model="loss">
                </div>
                <div class="p2 ms-4 mt-3 col-md-1 text-dark d-flex align-items-end justify-content-start">
                    円
                </div>
                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>会社の事業所建屋の状態や現在の資産状況、損益の状況をもとに、会社が地震等により被災した場合、事業を復旧・継続するのに必要な金額を算出します。建物・設備の復旧費用と事業が中断されることによる損失（キャッシュフローの悪化額）を予測して、復旧費用の総額を計算します。財務状況を評価するために、「財務診断」を利用してください。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>

            <!-----------------------------------ワンセット------------------------------------------------->

            <div class="row" :class="{'mt-5': index > 0}" v-for="(deliveryInfo, index) in deliveriesInfo" :key="index">
                <div class="col-md-9">
                    <div class="card bg-light-blue">
                        <div class="card-body">
                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-9   text-dark d-flex align-items-center justify-content-start py-2">
                                    ◯　中核{{ callReplaceWord('事業') }}による{{ callReplaceWord('商品') }}の{{ callReplaceWord('納入') }}先
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-4 text-dark d-flex align-items-center justify-content-start py-2">
                                    TOTONO-L 会員ID
                                </div>
                                <div class="p2 col-md-5 text-dark d-flex align-items-center justify-content-start py-2">
                                    法人名
                                </div>
                            </div>

                            <div class="row ms-3">
                                <div class="ms-4 col-md-3">
                                    <input type="text" class="form-control bg-resilience-light-blue" placeholder="" v-model="deliveryInfo.corpId">
                                </div>
                                <div class="col-md-1">
                                    <input type="button" class="btn btn-black" value="検索" @click="getMemberInfo(index, deliveryInfo.corpId, typeDelivery)">
                                </div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control " placeholder="" v-model="deliveryInfo.corporateName">
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="ms-4 col-md-9 text-dark d-flex align-items-center justify-content-start">
                                    ※入力すると{{ callReplaceWord('納入') }}先名の情報が自動表示されます。
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-2 text-dark d-flex align-items-center justify-content-start py-2">
                                    電話番号
                                </div>
                                <div class="p2 col-md-3 text-dark d-flex align-items-center justify-content-start py-2">
                                    {{ callReplaceWord('納入') }}先担当者
                                </div>
                                <div class="p2 col-md-4 text-dark d-flex align-items-center justify-content-start py-2">
                                    {{ callReplaceWord('納入') }}先担当者のメールアドレス
                                </div>
                            </div>
                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-2 text-dark d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="deliveryInfo.phoneNumber">
                                </div>
                                <div class="p2 col-md-3 text-dark d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="deliveryInfo.person">
                                </div>
                                <div class="p2 col-md-4 text-dark d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="deliveryInfo.email">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 d-flex" :class="[{'align-content-between flex-wrap' : index === 0}, 'align-items-end']">
                    <div class="d-flex justify-content-between" v-if="index === 0">
                        <VDropdown
                            theme="hint-theme"
                            tabindex="-1"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>会社の経営状態に甚大な影響を与える事業の納入・提供先を整理します。</label>
                                </div>
                            </template>
                        </VDropdown>
                        <VDropdown
                            theme="browsing_restrictions"
                        >
                            <input type="button" class="ms-5 btn btn-bcp-restrictions btn-sm" value="閲覧制限｜設定" />
                            <template #popper>
                                <div class="d-flex justify-content-start">
                                    <label>閲覧制限</label>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="deliveryInfo.isViewLimit">
                                        <label class="form-check-label" for="checkbox_1">
                                            あり
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="d-flex flex-column">
                        <div v-if="index !== 0">
                            <input type="button" class="btn btn-delete" value="削除" @click="deleteDeliverySet(index)">
                        </div>
                        <div v-if="index === deliveriesInfo.length - 1">
                            <input type="button" class="btn btn-black mt-3" value="ワンセット増やす" @click="addDeliveryVendorSet(typeDelivery)">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5" v-for="(vendorInfo, index) in vendorsInfo" :key="index">
                <div class="col-md-9">
                    <div class="card bg-light-blue">
                        <div class="card-body">
                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-9   text-dark d-flex align-items-center justify-content-start py-2">
                                    ◯　中核{{ callReplaceWord('事業') }}による{{ callReplaceWord('商品') }}の{{ callReplaceWord('仕入') }}先
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-4 text-dark d-flex align-items-center justify-content-start py-2">
                                    TOTONO-L 会員ID
                                </div>
                                <div class="p2 col-md-5 text-dark d-flex align-items-center justify-content-start py-2">
                                    法人名
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="ms-4 col-md-3 ">
                                    <input type="text" class="form-control bg-resilience-light-blue" placeholder="" v-model="vendorInfo.corpId">
                                </div>
                                <div class="col-md-1">
                                    <input type="button" class="btn btn-black" value="検索" @click="getMemberInfo(index, vendorInfo.corpId, typeVendor)">
                                </div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" placeholder="" v-model="vendorInfo.corporateName">
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="ms-4 col-md-9 text-dark d-flex align-items-center justify-content-start">
                                    ※入力すると{{ callReplaceWord('仕入先') }}名の情報が自動表示されます。
                                </div>
                            </div>

                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-2 text-dark d-flex align-items-center justify-content-start py-2">
                                    電話番号
                                </div>
                                <div class="p2 col-md-3 text-dark d-flex align-items-center justify-content-start py-2">
                                    {{ callReplaceWord('仕入') }}先担当者
                                </div>
                                <div class="p2 col-md-4 text-dark d-flex align-items-center justify-content-start py-2">
                                    {{ callReplaceWord('仕入') }}先担当者のメールアドレス
                                </div>
                            </div>
                            <div class="row ms-3 ">
                                <div class="p2 ms-4 col-md-2 text-dark d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="vendorInfo.phoneNumber">
                                </div>
                                <div class="p2 col-md-3 text-dark d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="vendorInfo.person">
                                </div>
                                <div class="p2 col-md-4 text-dark d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="vendorInfo.email">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 d-flex" :class="[{'align-content-between flex-wrap' : index === 0}, 'align-items-end']">
                    <div class="d-flex justify-content-between" v-if="index === 0">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>会社の経営状態に甚大な影響を与える事業の原材料等の入手先やサプライヤーについて整理します。</label>
                                </div>
                            </template>
                        </VDropdown>
                        <VDropdown
                            theme="browsing_restrictions"
                        >
                            <input type="button" class="ms-5 btn btn-bcp-restrictions btn-sm" value="閲覧制限｜設定" />
                            <template #popper>
                                <div class="d-flex justify-content-start">
                                    <label>閲覧制限</label>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="vendorInfo.isViewLimit">
                                        <label class="form-check-label" for="checkbox_1">
                                            あり
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="d-flex flex-column">
                        <div v-if="index !== 0">
                            <input type="button" class="btn btn-delete" value="削除" @click="deleteVendorSet(index)">
                        </div>
                        <div class="mt-3" v-if="index === vendorsInfo.length - 1">
                            <input type="button" class="btn btn-black" value="ワンセット増やす" @click="addDeliveryVendorSet(typeVendor)">
                        </div>
                    </div>
                </div>
            </div>
            <!-----------------------------------マス------------------------------------------------->


            <!-----------------------------------マス------------------------------------------------->
            <div class="row" :class="{'mt-5': index === 0}" v-for="(disasterAssumption, index) in disasterAssumptionsInfo" :key="index">
                <div class="card mt-3">
                    <div class="card-body">
                        <div class="row ms-3">
                            <div class="p2 ms-4 col-md-6  border-top-0 border-bottom-0 border-end-0 text-dark d-flex align-items-center justify-content-start py-2">
                                中核{{ callReplaceWord('事業') }}中断の可能性がある災害等（災害想定）
                            </div>
                            <div class="p2 col-md-4  border-start-0 border-top-0 border-bottom-0 text-dark d-flex align-items-center justify-content-start py-2">
                                目標復旧時間
                            </div>
                        </div>

                        <div class="row ms-3 ">
                            <div class="col-md-4 d-flex align-items-center justify-content-center">
                                <label class="col-form-label border border-dark px-3">{{ index + 1 }}</label>
                                    <input type="text" class="col-form-label border bg-disaster flex-fill px-3" v-model="disasterAssumption.disasterAssumptionContent">
                            </div>
                            <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                                <VDropdown
                                    theme="hint-theme"
                                    v-if="index === 0"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>ここで登録した「災害想定」は、「中核{{ callReplaceWord('事業') }}影響度評価フォーム」や「発動フロー」など、その後の災害対策を進めるための基礎データとなります。<br />また、緊急事態発生時は、責任者がこの災害想定を選択してBCPを発動することとなります。<br />災害想定には、地震災害の他、風水害、集団感染、火災などが考えられますが、基本コースでは、地震災害に絞って策定することから始めます。<br />中級コース以上は、複数の災害を想定しそれぞれの対策を講じましょう。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                            <div class="p2 col-md-2  text-white bg-white d-flex align-items-center justify-content-start py-2">
                                <input type="number" class="form-control" min="0" v-model="disasterAssumption.targetRecoveryTime">
                            </div>
                            <div class="p2 col-md-2 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                                <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="時間" v-model="disasterAssumption.timeDivision">
                                <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;時間</label>

                                <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="ms-3 custom-control-input" value="日" v-model="disasterAssumption.timeDivision">
                                <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;日</label>

                                <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="ms-3 custom-control-input" value="週間" v-model="disasterAssumption.timeDivision">
                                <label class="custom-control-label" :for="'customRadioInline3' + index">&nbsp;週間</label>
                            </div>
                            <div class="p2 col-md-1  border-start-0 border-top-0 border-bottom-0 d-flex align-items-start justify-content-start">
                                <VDropdown
                                    theme="hint-theme"
                                    v-if="index === 0"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>目標復旧時間を決めるにあたっては、最低限、以下の2つを考慮する必要があります。<br />
                                                    ①中核{{ callReplaceWord('事業') }}に関わる取引先やサプライチェーンの要請<br />
                                                    ②あなたの{{ callReplaceWord('組織') }}の財務状況にもとづく時間<br />
                                                    まず①については、中核{{ callReplaceWord('事業') }}の特定により、<br />
                                                    それに関連する{{ callReplaceWord('取引先') }}やサプライチェーンに含まれる{{ callReplaceWord('組織') }}が把握できますので、<br />
                                                    あなたの{{ callReplaceWord('組織') }}が被災した場合に、それらの{{ callReplaceWord('取引先') }}から許容される事業停止時間の限度を把握しなければなりません。<br />
                                                    これは、{{ callReplaceWord('取引先') }}の経営者や幹部{{ callReplaceWord('従業員') }}との直接的なコミュニケーション等を通して把握・調整しておくべき事項です。<br />
                                                    一方、②については、特定した中核{{ callReplaceWord('事業') }}の停止による損失に対して、あなたの{{ callReplaceWord('組織') }}の資金が耐えられる限界の期間を見積もっておく必要があります。<br />
                                                    具体的には、中核{{ callReplaceWord('事業') }}が停止した場合の収入の途絶に加えて、{{ callReplaceWord('納入') }}遅延等による違約金、<br />
                                                    その間の{{ callReplaceWord('従業員') }}の賃金、災害対応のための臨時人員の賃金、事業所や設備機器が被災した場合の修繕や新規調達費用等が発生しますので、<br />
                                                    それらの費用負担に対して、どれだけの期間耐えられる資金があなたの{{ callReplaceWord('組織') }}にあるかを見極めなければなりません。
                                            </label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-2" v-if="index === disasterAssumptionsInfo.length - 1">
                    <input type="button" class="btn btn-black" value="マスを増やす" @click="addDisasterLine">
                </div>
            </div>

            <div class="row ms-3 ">
                <div class="p2 ms-3 mt-3 col-md-6 text-dark d-flex align-items-end justify-content-start">
                    備考
                </div>
            </div>
            <div class="row ms-3 ">
                <div class="p2 ms-3 col-md-10 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="note">
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-blue" :value="'基本｜3.中核' + callReplaceWord('事業') + 'と復旧目標｜中核' + callReplaceWord('事業') + 'に係る情報（つづき）へ進む'" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(async () => {
            // 取得データをセット

            // 中核事業
            if (props.core_businesses_info.length > 0) {
                coreBusinessContent.value = props.core_businesses_info[0].core_business_content;
                inCompanyManager.value = props.core_businesses_info[0].in_company_manager;
                jobTitle.value = props.core_businesses_info[0].job_title;
                loss.value = props.core_businesses_info[0].loss;
                note.value = props.core_businesses_info[0].note
            } else {
                // 重要商品情報
                if (props.introduction_important_product_info.length > 0) {
                    coreBusinessContent.value = props.introduction_important_product_info[0].value;
                }
                // 責任者情報
                if (props.introduction_manager_info.length > 0) {
                    inCompanyManager.value = props.introduction_manager_info[0].value;
                    jobTitle.value = props.introduction_manager_info[1].value;
                }
            }

            // 納入仕入先
            if (props.delivery_vendors_info.length > 0) {
                deliveriesInfo.splice(0);
                vendorsInfo.splice(0)
                await props.delivery_vendors_info.forEach(fetchData => {
                    if (fetchData.delivery_vendor_type == typeDelivery) {
                        // 納入先データセット
                        deliveriesInfo.push({
                            deliveryVendorType: typeDelivery,
                            isViewLimit: fetchData.is_view_limit == 1 ? true : false,
                            corpId: fetchData.corp_id,
                            corporateName: fetchData.corporate_name,
                            phoneNumber: fetchData.phone_number,
                            person: fetchData.person,
                            email: fetchData.email,
                        });
                    } else {
                        // 仕入先データセット
                        vendorsInfo.push({
                            deliveryVendorType: typeVendor,
                            isViewLimit: fetchData.is_view_limit == 1 ? true : false,
                            corpId: fetchData.corp_id,
                            corporateName: fetchData.corporate_name,
                            phoneNumber: fetchData.phone_number,
                            person: fetchData.person,
                            email: fetchData.email,
                        });
                    }
                });
                if(deliveriesInfo.length === 0){
                    deliveriesInfo.push({
                        deliveryVendorType: typeDelivery,
                        isViewLimit: 0,
                        corpId: '',
                        corporateName: '',
                        phoneNumber: '',
                        person: '',
                        email: '',
                    });
                }
                if(vendorsInfo.length === 0){
                    vendorsInfo.push({
                        deliveryVendorType: typeVendor,
                        isViewLimit: 0,
                        corpId: '',
                        corporateName: '',
                        phoneNumber: '',
                        person: '',
                        email: '',
                    });
                }
            }

            // 災害想定
            if (props.disaster_assumptions_info.length > 0) {
                console.log(props.disaster_assumptions_info);
                disasterAssumptionsInfo.splice(0);
                props.disaster_assumptions_info.forEach(fetchData => {
                    disasterAssumptionsInfo.push({
                        disasterAssumptionId: fetchData.id,
                        disasterAssumptionContent: fetchData.disaster_assumption_content,
                        targetRecoveryTime: fetchData.target_recovery_time,
                        timeDivision: fetchData.time_division,
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const coreBusinessContent = ref('○○社向けの△△商品');
        const inCompanyManager = ref('');
        const jobTitle = ref('代表取締役');
        const loss = ref(0);
        const note = ref('');
        const typeDelivery = 1;
        const typeVendor = 2;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const deliveriesInfo = reactive([
            {
                deliveryVendorType: typeDelivery,
                isViewLimit: 0,
                corpId: '',
                corporateName: '',
                phoneNumber: '',
                person: '',
                email: '',
            }
        ]);

        const vendorsInfo = reactive([
            {
                deliveryVendorType: typeVendor,
                isViewLimit: 0,
                corpId: '',
                corporateName: '',
                phoneNumber: '',
                person: '',
                email: '',
            }
        ]);

        const disasterAssumptionPlaceholders = [
            '震度6弱の地震（東海地震等）',
            '◯◯◯川の氾濫',
            callReplaceWord('工場') + 'の火災',
        ];

        const disasterAssumptionsInfo = reactive([]);
        for (let i = 0; i < 3; i++) {
            disasterAssumptionsInfo.push({
                disasterAssumptionId: '',
                disasterAssumptionContent: disasterAssumptionPlaceholders[i] !== undefined ? disasterAssumptionPlaceholders[i] : '',
                targetRecoveryTime: '',
                timeDivision: '',
            });
        }

        const addDeliveryVendorSet = (type) => {
            if (type === typeDelivery) {
                deliveriesInfo.push({
                    deliveryVendorType: typeDelivery,
                    isViewLimit: 0,
                    corpId: '',
                    corporateName: '',
                    phoneNumber: '',
                    person: '',
                    email: '',
                });
            } else {
                vendorsInfo.push({
                    deliveryVendorType: typeVendor,
                    isViewLimit: 0,
                    corpId: '',
                    corporateName: '',
                    phoneNumber: '',
                    person: '',
                    email: '',
                });
            }
        }

        const deleteDeliverySet = (index) => {
            if(!confirm((index + 1) + "番目（" + deliveriesInfo[index].corporateName + "）を削除します。よろしいですか？")){
                return false;
            }

            deliveriesInfo.splice(index, 1);
        }

        const deleteVendorSet = (index) => {
            if(!confirm((index + 1) + "番目（" + vendorsInfo[index].corporateName + "）を削除します。よろしいですか？")){
                return false;
            }

            vendorsInfo.splice(index, 1);
        }

        const addDisasterLine = () => {
            disasterAssumptionsInfo.push({
                disasterAssumptionId: '',
                disasterAssumptionContent: '',
                targetRecoveryTime: '',
                timeDivision: '',
            });
        }

        // 役職の自動設定
        const setJobTitle = (name) => {
            jobTitle.value = props.admin_candidate_list.filter(v => v.氏名 == name)[0].役職;
        }

        const showBack = async () => {
            // 中核事業に係る情報を登録
            const result = await registerFirst3();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 中核事業に係る情報を登録
            const result = await registerFirst3();

            if (result == '200') {
                const url = '/bcp/bcp_first/3a';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 中核事業に係る情報を登録
            const result = await registerFirst3();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showAdvanced = async () => {
            // 中核事業に係る情報を登録
            const result = await registerFirst3();

            if (result == '200') {
                const url = '/bcp/bcp_first/form';
                location.href = url;
            }
        }

        const getMemberInfo = (index, searchId, type) => {
            const url="/api/bcp/getMemberInfo";

            // 企業情報取得
            return axios.post(url, {
                corp_id: searchId,
            }).then(res=>{
                if (type === typeDelivery) {
                    deliveriesInfo[index].corpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    deliveriesInfo[index].corporateName = res.data.corporate_name !== undefined ? res.data.corporate_name : null;
                    deliveriesInfo[index].phoneNumber = res.data.phone_number !== undefined ? res.data.phone_number : null;
                } else {
                    vendorsInfo[index].corpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    vendorsInfo[index].corporateName = res.data.corporate_name !== undefined ? res.data.corporate_name : null;
                    vendorsInfo[index].phoneNumber = res.data.phone_number !== undefined ? res.data.phone_number : null;
                }
            });
        }

        // 中核事業に係る情報登録
        const registerFirst3 = async () => {
            const reqCoreBusinessInfo = {
                coreBusinessContent: coreBusinessContent.value,
                inCompanyManager: inCompanyManager.value,
                jobTitle: jobTitle.value,
                loss: !Number.isNaN(parseInt(loss.value)) ? parseInt(loss.value) : 0,
                note: note.value,
            };

            const reqDeliveryVendorsInfo = deliveriesInfo.concat(vendorsInfo);

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst3';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'core_businesses_info': reqCoreBusinessInfo,
                'delivery_vendors_info': reqDeliveryVendorsInfo,
                'disaster_assumptions_info': disasterAssumptionsInfo,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            coreBusinessContent,
            inCompanyManager,
            jobTitle,
            loss,
            note,
            typeDelivery,
            typeVendor,
            deliveriesInfo,
            vendorsInfo,
            disasterAssumptionsInfo,
            disasterAssumptionPlaceholders,
            addDeliveryVendorSet,
            deleteDeliverySet,
            deleteVendorSet,
            addDisasterLine,
            setJobTitle,
            callReplaceWord,
            showBack,
            showNext,
            showContents,
            showAdvanced,
            getMemberInfo,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        admin_candidate_list: {
            type: Object,
        },
        introduction_important_product_info: {
            type: Object,
        },
        introduction_manager_info: {
            type: Object,
        },
        core_businesses_info: {
            type: Object,
        },
        delivery_vendors_info: {
            type: Object,
        },
        disaster_assumptions_info: {
            type: Object,
        },
        is_completed_middle: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4;
}
.bg-blue::placeholder {
  color: white;
}
.bg-disaster {
    background-color: #c00000;
    color: white;
}
.bg-disaster::placeholder {
  color: white;
}
</style>
