<template>
    <div id="change-paid-modal" v-show="modalVisible" @click.self="closeModal">
        <div id="content">
            <div class="row">
                <label class="col-form-label">これ以降のページを利用される場合は、<br />有償会員に切り替える必要がございます。</label>
            </div>
            <div class="row mt-3">
                <a href="#" @click.prevent.stop="changePaid" class="link-orange">➡ 有償会員切替ページへ</a>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="col-md-8">
            <div class="mt-5 pb-5">
                <i id="bb-logo"></i>
            </div>
            <div class="mt-5 d-flex" v-if="matching_cnt >= 1000">
                <label class="col-form-label label-title px-3">マッチング登録者数</label>
                <label class="col-form-label label-body px-3 text-end">{{ number_format(matching_cnt) }}</label>
            </div>
            <div class="my-3 d-flex" v-if="agreement_cnt >= 1000">
                <label class="col-form-label label-title px-3">協定書保管数</label>
                <label class="col-form-label label-body px-3 text-end">{{ number_format(agreement_cnt) }}</label>
            </div>
        </div>
        <div class="col-md-4">
            <div class="top_button cursor-pointer" @click="showTerms">
                <i id="bb-terms"></i>
            </div>
            <div class="mt-4 cursor-pointer" @click="showSearch">
                <i id="bb-search"></i>
            </div>
            <div class="mt-4 cursor-pointer" @click="showRegisterMatchingWord">
                <i id="bb-word"></i>
            </div>
            <div class="mt-4 cursor-pointer" v-show="corp_info.isNegotiate == true" @click="showDownload">
                <i id="bb-download"></i>
            </div>
            <div class="mt-4 pb-5 cursor-pointer" @click="showStorage">
                <i id="bb-storage"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';

export default {
    setup(props) {
        const number_format = (num) => {
            return CommonNumberFormat(num);
        }

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const showTerms = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            
            const url = '/bnb/2K';

            location.href = url;
        }

        const showSearch = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }

            const url = '/bnb/1';

            location.href = url;
        }

        const showRegisterMatchingWord = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }

            const url = '/al/administrator/register_matching_word';

            location.href = url;
        }

        const showStorage = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }

            const url = '/bnb/3';

            location.href = url;
        }

        const showDownload = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }

            const url = '/bnb/9';

            location.href = url;
        }

        const judgeFreeMember = () => {
            if(props.corp_info.会員種別.indexOf('無償') != -1){
                return true;
            }
            return false;
        }

        const modalVisible = ref(false);

        const closeModal = () => {
            modalVisible.value = false;
        }

        const changePaid = () => {
            const url = '/al/administrator/switchPaidMember';

            location.href = url;
        }

        return {
            number_format,

            showTerms,
            showSearch,
            showRegisterMatchingWord,
            showStorage,
            showDownload,

            judgeFreeMember,
            modalVisible,
            closeModal,
            changePaid,
        }
    },
    props: {
        matching_cnt: {
            type: Number
        },
        corp_info: {
            type: Object
        },
        agreement_cnt: {
            type: Number
        },
    }
}
</script>

<style lang="scss" scoped>
#bb-logo {
    display: inline-block;
    width: 713px;
    height: 362px;
    background-image: url('../../../svg/16-bb_logo.svg');
    background-size: 713px auto;
    background-repeat: no-repeat;

}

#bb-terms {
    display: inline-block;
    width: 340px;
    height: 94px;
    background-image: url('../../../svg/bnb_button/bb_kiyaku.svg');
    background-size: 340px auto;
    background-repeat: no-repeat;

}

#bb-search {
    display: inline-block;
    width: 340px;
    height: 94px;
    background-image: url('../../../svg/bnb_button/bb_sagasu.svg');
    background-size: 340px auto;
    background-repeat: no-repeat;

}

#bb-word {
    display: inline-block;
    width: 340px;
    height: 94px;
    background-image: url('../../../svg/bnb_button/bb_matching.svg');
    background-size: 340px auto;
    background-repeat: no-repeat;

}

#bb-download {
    display: inline-block;
    width: 340px;
    height: 94px;
    background-image: url('../../../svg/bnb_button/bb_model.svg');
    background-size: 340px auto;
    background-repeat: no-repeat;

}

#bb-storage {
    display: inline-block;
    width: 340px;
    height: 94px;
    background-image: url('../../../svg/bnb_button/bb_hokan.svg');
    background-size: 340px auto;
    background-repeat: no-repeat;

}

.label-title {
    border: 1px solid white;
    background-color: white;
    width: 250px;
}

.label-body {
    border: 1px solid white;
    color: white;
    width: 150px;
}

#change-paid-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 30%;
    padding: 5em;
    background: #fff;
}

.link-orange,
.link-orange:link,
.link-orange:visited,
.link-orange:hover,
.link-orange:active{
color: #Ff6600;
}

.top_button {
    margin-top: 350px;
}

@media screen and (max-width: 767px){
    .top_button {
        margin-top: 10px;
    }
}
</style>