<template>
    <div class="text mt-5">
        <label>　入門コースの策定を進めていくと、上位コースのボタンが表示されます。このボタンは、下位コースの入力が完了していない場合、先へは進めませんのでご注意ください。
        <br>　それでは、「新しくBCP策定を始める（初めての方はこちらから）」をクリックしてスタートしましょう。</label>
    </div>

    <div class="p2 row mt-5 mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white">
        <div class="p2 row d-flex text-center align-items-center justify-content-center py-2" style="font-size:20pt">
            <br>新しく介護BCP策定を始める<br>
            <div class="text" style="font-size:14pt">
                （初めての方はこちらから）<br>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center py-2">
            <input type="button" class="p2 col-md-8 btn btn-bcp-care-natural-disasters text-white btn-sm" style="font-size:16pt" value="介護・自然災害｜ページへ移動します">
        </div>
        <div class="p2 d-flex align-items-center justify-content-center py-2">
            &emsp;
        </div>
    </div>

    <div class="p2 row mt-5 mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white">
        <div class="p2 row d-flex text-center align-items-center justify-content-center py-2" style="font-size:20pt">
            <br>目次から項目を選んで策定を続ける<br>
            <div class="text" style="font-size:14pt">
                （策定の続き｜策定の見直し）<br>
            </div>
        </div>
    </div>


    <div class="p2 row mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white">
        <div class="p2 col-md-3 btn-bcp-care-natural-disasters d-flex align-items-center justify-content-center py-2" style="font-size:16pt">
            介護・自然災害
        </div>
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-3 btn-bcp-care-infection d-flex align-items-center justify-content-center py-2" style="font-size:16pt">
            介護・感染症
        </div>
    </div>
    <div class="p2 row mx-auto col-md-8 d-flex align-items-center justify-content-center bg-resilience-orange text-white">
        <div class="p2 col-md-3 d-flex align-items-center justify-content-center py-2">
            <input type="button" class="btn btn-block btn-white bg-white text-dark btn-sm" style="font-size:16pt" value="目次構成へ移動します">
        </div>
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-center py-2">
            <input type="button" class="btn btn-block btn-white bg-white text-dark btn-sm" style="font-size:16pt" value="目次構成へ移動します">
        </div>
    </div>
</template>

<script>
export default {
    setup(props) {
        const showIntroduction = () => {
            const url = '/bcp/bcp_introduction/1';
            location.href = url;
        }

        const showContents = () => {
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        return {
            showIntroduction,
            showContents,
        }
    },
    props:{
    },}
</script>

<style lang="scss" scoped>

</style>
