<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜情報連絡（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-4 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　情報通信手段
        </div>
    </div>

    <template v-for="(infoComMethod, index) in infoComMethods" :key="index">

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-12 border border-dark border-bottom-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-4 col-md-1 border border-dark bg-light-gray text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                {{ index + 1 }}
            </div>
            <div class="p2 ms-4 col-md-3 text-dark d-flex align-items-center justify-content-start py-2">
                利用を想定する情報通信機器
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                <template v-for="equipment in equipments" :key="equipment.equipmentId">
                    <template v-if="equipment.equipmentId <= 5">
                        <input type="checkbox" :id="'customRadioInline' + index + equipment.equipmentId" :name="'customRadioInline1' + index"
                            class="custom-control-input" :class="{'ms-3': equipment.equipmentId >= 2}"
                            :value="equipment.equipmentId" v-model="infoComMethod.useEquipments"
                        >
                        <label class="custom-control-label" :for="'customRadioInline' + index + equipment.equipmentId">&nbsp;{{ equipment.equipmentName }}</label>
                    </template>
                </template>
            </div>
            <div class="p2 ms-1 col-md-1 d-flex align-items-start justify-content-start">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>緊急時における{{ callReplaceWord('従業員') }}、{{ callReplaceWord('取引先') }}、供給業者などとの連絡は、災害発生後の{{ callReplaceWord('事業') }}活動再開に重大に影響を与えます。その連絡のために利用する通信機器を整理します。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-4 col-md-2 d-flex align-items-center justify-content-start py-2">
                <input type="button" class="btn btn-delete" value="削除" v-if="index > 0" @click="deleteInfo(index)" />
            </div>
            <div class="p2 ms-4 col-md-2 text-dark d-flex align-items-center justify-content-start py-2">
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                <template v-for="equipment in equipments" :key="equipment.equipmentId">
                    <template v-if="equipment.equipmentId === 6">
                        <input type="checkbox" :id="'customRadioInline' + index + equipment.equipmentId" :name="'customRadioInline' + index"
                            class="custom-control-input"
                            :value="equipment.equipmentId" v-model="infoComMethod.useEquipments"
                        >
                        <label class="custom-control-label" :for="'customRadioInline' + index + equipment.equipmentId">&nbsp;{{ equipment.equipmentName }}</label>
                    </template>
                </template>
                <div class="p2 ms-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.useEquipmentOther">
                </div>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                当該資源の現状
            </div>
            <div class="p2 col-md d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                <template v-for="current in currentStatus" :key="current.statusId">
                    <input type="checkbox" :id="'customRadioInline1' + index + current.statusId" :name="'customRadioInline1' + index"
                        class="custom-control-input" :class="{'ms-3': current.statusId >= 2}"
                        :value="current.statusId" v-model="infoComMethod.currentResource"
                    >
                    <label class="custom-control-label" :for="'customRadioInline1' + index + current.statusId">&nbsp;{{ current.status }}</label>
                </template>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                平時活用している機種・台数
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.model">
            </div>
            <div class="p2 ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                <input type="Number" min="0" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.count">
            </div>
            <div class="p2 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                台
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                緊急時に必要と予測される台数
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
        <div class="p2 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-end">
                約
            </div>
            <div class="p2 col-md-4 d-flex align-items-center justify-content-start py-2">
                <input type="Number" min="0" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.predictionCount">
            </div>
            <div class="p2 col-md-2 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                台
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                緊急時の障害等により機能する台数が上記に満たない場合の対応
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-1 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                <input type="checkbox" :id="'customRadioInline3'  + index" :name="'customRadioInline3'  + index" class="custom-control-input"
                    value="1" v-model="infoComMethod.isLease">
                <label class="custom-control-label" :for="'customRadioInline3'  + index">&nbsp;リース</label>
            </div>
            <div class="p2 ms-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                TOTONO-L 会員ID
            </div>
            <div class="p2 ms-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 ms-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                TOTONO-L 会員ID
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-1 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
            </div>
            <div class="p2 ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control bg-resilience-light-blue d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.purchasePlanCompanyCorpId">
            </div>
            <button type="button" class="p2 ms-3 col-md-1 btn btn-black my-2" @click="getMemberInfo(index, infoComMethod.purchasePlanCompanyCorpId, isMain)">
                検　索
            </button>
            <div class="p2 ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control bg-resilience-light-blue d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.purchasePlanCompanyExtraCorpId">
            </div>
            <button type="button" class="p2 ms-3 col-md-1 btn btn-black my-2" @click="getMemberInfo(index, infoComMethod.purchasePlanCompanyExtraCorpId, isSub)">
                検　索
            </button>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-1 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
            </div>
            <div class="p2 ms-3 col-md-4 bg-light-gray text-dark d-flex align-items-top justify-content-start" style="font-size:10.5pt">
                ※入力すると購入事業者の情報が自動表示されます。
            </div>
            <div class="p2 ms-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 ms-3 col-md-5 bg-light-gray text-dark d-flex align-items-top justify-content-start" style="font-size:10.5pt">
                ※入力すると購入事業者｜予備の情報が自動表示されます。
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-1 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
            </div>
            <div class="p2 ms-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                ➔ 購入予定事業者名
            </div>
            <div class="p2 ms-3 mt-3 col-md-1 bg-light-gray text-dark d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 ms-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                ➔ 購入予定事業者名｜予備
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-1 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
            </div>
            <div class="p2 ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.purchasePlanCompanyName">
            </div>
            <div class="p2 ms-4 col-md-5 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.purchasePlanCompanyNameExtra">
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-3 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                <input type="checkbox" :id="'customRadioInline4' + index" :name="'customRadioInline4' + index" class="custom-control-input"
                    value="1" v-model="infoComMethod.isPurchase">
                <label class="custom-control-label" :for="'customRadioInline4' + index">&nbsp;事業復旧場所向けに購入</label>
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-1 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                <input type="checkbox" :id="'customRadioInline5' + index" :name="'customRadioInline5' + index" class="custom-control-input"
                    value="1" v-model="infoComMethod.isOther">
                <label class="custom-control-label" :for="'customRadioInline5' + index">&nbsp;その他</label>
            </div>
            <div class="p2 ms-3 mt-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.other">
            </div>

        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                {{ callReplaceWord('事業') }}継続の際の想定設置場所
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.installationLocation">
            </div>
        </div>

        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                備考
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-3 col-md-11 d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="infoComMethod.note">
            </div>
        </div>


        <div class="row ms-3">
            <div class="p2 col-md-12 border border-dark border-top-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
        </div>
    </template>

    <div class="row mt-1">
        <div class="p2 ms-3 col-md-10 d-flex align-items-start justify-content-start">
        </div>
        <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-black" value="ワンセット増やす" @click="addInfoComMethodSet">
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-advanced" value="上級｜5.　緊急時におけるBCP発動｜資源（つづき）へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(async () => {
            // 取得データをセット
            if (props.info_com_methods.length > 0) {
                infoComMethods.splice(0);
                props.info_com_methods.forEach(fetchData => {
                    // 主要組織連絡先情報
                    infoComMethods.push({
                        useEquipments: fetchData.use_equipments !== null ? fetchData.use_equipments.split(',') : fetchData.use_equipments,
                        useEquipmentOther: fetchData.use_equipment_other,
                        currentResource: fetchData.current_resource !== null ? fetchData.current_resource.split(',') : fetchData.current_resource,
                        model: fetchData.model,
                        count: fetchData.count,
                        predictionCount: fetchData.prediction_count,
                        isLease: fetchData.is_lease === 1 ? true : false,
                        purchasePlanCompanyCorpId: fetchData.purchase_plan_company_corp_id,
                        purchasePlanCompanyName: fetchData.purchase_plan_company_name,
                        purchasePlanCompanyExtraCorpId: fetchData.purchase_plan_company_extra_corp_id,
                        purchasePlanCompanyNameExtra: fetchData.purchase_plan_company_name_extra,
                        isPurchase: fetchData.is_purchase === 1 ? true : false,
                        isOther: fetchData.is_other === 1 ? true : false,
                        other: fetchData.other,
                        installationLocation: fetchData.installation_location,
                        note: fetchData.note,
                    })
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('000100');
        const isProcessing = ref(false);
        const isMain = 1;
        const isSub = 2;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const equipments = [
            {equipmentId: 1, equipmentName: '電話（外線）'},
            {equipmentId: 2, equipmentName: '電話（内線）'},
            {equipmentId: 3, equipmentName: 'ファックス'},
            {equipmentId: 4, equipmentName: 'インターネット'},
            {equipmentId: 5, equipmentName: '携帯電話'},
            {equipmentId: 6, equipmentName: 'その他'},
        ]

        const currentStatus = [
            {statusId: 1, status: '現在、使用中'},
            {statusId: 2, status: 'リース予定／購入予定'},
        ]

        const infoComMethods = reactive([
            {
                useEquipments: [],
                useEquipmentOther: '',
                currentResource: [],
                model: '',
                count: '',
                predictionCount: '',
                isLease: '',
                purchasePlanCompanyCorpId: '',
                purchasePlanCompanyName: '',
                purchasePlanCompanyExtraCorpId: '',
                purchasePlanCompanyNameExtra: '',
                isPurchase: '',
                isOther: '',
                other: '',
                installationLocation: '',
                note: '',
            }
        ]);

        // 検索ボタン
        const getMemberInfo = (index, searchId, target) => {
            const url="/api/bcp/getMemberInfo";

            // 企業情報取得
            return axios.post(url, {
                corp_id: searchId,
            }).then(res=>{
                if (target === isMain) {
                    infoComMethods[index].purchasePlanCompanyCorpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    infoComMethods[index].purchasePlanCompanyName = res.data.corporate_name !== undefined ? res.data.corporate_name : null;
                } else {
                    infoComMethods[index].purchasePlanCompanyExtraCorpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    infoComMethods[index].purchasePlanCompanyNameExtra = res.data.corporate_name !== undefined ? res.data.corporate_name : null;
                }
            });
        }

        // ワンセット増やすボタン
        const addInfoComMethodSet = () => {
            infoComMethods.push({
                useEquipments: [],
                useEquipmentOther: '',
                currentResource: [],
                model: '',
                count: '',
                predictionCount: '',
                isLease: '',
                purchasePlanCompanyCorpId: '',
                purchasePlanCompanyName: '',
                purchasePlanCompanyExtraCorpId: '',
                purchasePlanCompanyNameExtra: '',
                isPurchase: '',
                isOther: '',
                other: '',
                installationLocation: '',
                note: '',
            });
        }

        const deleteInfo = (index) => {
            if(!confirm((index + 1) + "番目を削除します。よろしいですか？")){
                return false;
            }

            infoComMethods.splice(index, 1);
        }

        const showBack = async () => {
            // 情報通信手段を登録
            const result = await registerSecond5();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 情報通信手段を登録
            const result = await registerSecond5();

            if (result == '200') {
                const url = '/bcp/bcp_second/9';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 情報通信手段を登録
            const result = await registerSecond5();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 情報通信手段を登録
            const result = await registerSecond5();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 情報通信手段登録
        const registerSecond5 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond5';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'info_com_methods': infoComMethods,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            isMain,
            isSub,
            callReplaceWord,
            equipments,
            currentStatus,
            infoComMethods,
            getMemberInfo,
            addInfoComMethodSet,
            deleteInfo,
            showBack,
            showNext,
            showPrevious,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        info_com_methods: {
            type: Object,
        },
    }

}
</script>
