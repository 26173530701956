<template>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                訓練実施記録の閲覧
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end ne-md-5 ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>


    <div class="row fs-half mt-3">
        <template v-for="(item, index) in training_implementation" :key="item.implementation_record_id">
            <div class="mx-1 mx-md-3 mb-3">
                <table class="table" id="training_implementation_table">
                    <thead>
                        <tr>
                            <td>No.</td>
                            <td>実施日</td>
                            <td>訓練シナリオの名称・テーマ</td>
                            <td>訓練の方法</td>
                            <td>記録内容詳細</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ index + 1 }}</td>
                            <td>{{ this.$root.dayjs(item.implementation_date).format('YYYY/MM/DD') }}</td>
                            <td>{{ item.training_name }}</td>
                            <td>{{ returnTrainingMethod(item.training_method, item.training_method_other) }}</td>
                            <td><input type="button" class="btn btn-small btn-resilience-light-blue btn-responsive" value="訓練実施記録" @click="showDetail(item.implementation_record_id)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" @click="showTop">
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            // 前のページへ遷移
            history.back();
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        const returnTrainingMethod = (code, other) => {
            switch(code){
                case '1':
                    return '机上訓練（模擬災害対策訓練）';
                case '2':
                    return 'バックアップデータを取り出す訓練';
                case '3':
                    return '机上訓練（ワークショップ訓練）';
                case '4':
                    return 'BCP手順確認訓練';
                case '5':
                    return '机上訓練（ロールプレイング訓練）';
                case '6':
                    return 'BCP全体を通して行う総合訓練';
                case '7':
                    return '安否確認システム操作訓練・緊急時通報診断';
                case '9':
                    return 'その他:' + other;
                default:
                    return '代替施設への移動訓練';
            }
        }

        const showDetail = (id) => {
            const url = '/fixation/showTrainingRecordDetail/' + id;

            location.href = url;
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,
            showTop,
            returnTrainingMethod,
            showDetail,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        training_implementation: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

#training_implementation_table thead tr{
    background-color: #d8d8d8;
}

#training_implementation_table thead tr td,
#training_implementation_table tbody tr td{
    border: 1px solid common_colors.colors(resilience-gray);
}

#training_implementation_table thead tr td:nth-child(1) {
    width: 5%;
}

#training_implementation_table thead tr td:nth-child(2),
#training_implementation_table thead tr td:nth-child(5)
{
    width: 15%;
}

#training_implementation_table thead tr td:nth-child(3){
    width: 40%;
}


.btn-resilience-light-blue {
    background-color: common_colors.colors(resilience-light-blue);
    color: white;
    border: 1px solid common_colors.colors(resilience-light-blue);
}

.btn-resilience-light-blue:hover {
    background-color: white;
    color: common_colors.colors(resilience-light-blue);
    border: 1px solid common_colors.colors(resilience-light-blue);
}

@media screen and (max-width: 756px){
    .fs-half {
        font-size: 0.5em;
    }

    .btn-responsive {
        font-size: 0.5em;
    }

}
</style>
