<template>
    <div class="row">
        <div id="question-modal" v-show="modalQuestionVisible" @click.self="closeQuestionModal">
            <div id="question-content">
                <div class="row" v-show="modal_page===1">
                    <div class="col-md-1">

                    </div>
                    <div class="col-md-5">
                        <table class="mt-5 table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="bg-question-no text-white">設問1<span class="badge badge-required ms-3" v-if="select_weather !== 0">必須</span></td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問1_タイトル" maxlength="50" :disabled="select_weather !== 0">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-answer-setting" rowspan="5">
                                        <label class="form-label">選択肢</label>
                                        <div class="mt-3 ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問1" id="1" value="30080001" v-model="設問1_選択肢タイプ">
                                            <label class="form-check-label" for="1">
                                                単数
                                            </label>
                                        </div>
                                        <div class="ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問1" id="2" value="30080002" v-model="設問1_選択肢タイプ">
                                            <label class="form-check-label" for="2">
                                                複数
                                            </label>
                                        </div>
                                        <div class="mt-3 form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問1_グラフ集計要否">
                                            <label class="form-check-label" for="1">
                                                グラフ集計
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問1_未回答可">
                                            <label class="form-check-label" for="2">
                                                未回答可
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問1_問1" maxlength="50" :disabled="select_weather !== 0">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問1_問2" maxlength="50" :disabled="select_weather !== 0">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問1_問3" maxlength="50" :disabled="select_weather !== 0">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問1_問4" maxlength="50" :disabled="select_weather !== 0">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問1_問5" maxlength="50" :disabled="select_weather !== 0">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-5">
                        <table class="mt-5 table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="bg-question-no text-white">設問2</td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問2_タイトル" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-answer-setting" rowspan="5">
                                        <label class="form-label">選択肢</label>
                                        <div class="mt-3 ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問2" id="3" value="30080001" v-model="設問2_選択肢タイプ">
                                            <label class="form-check-label" for="3">
                                                単数
                                            </label>
                                        </div>
                                        <div class="ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問2" id="4" value="30080002" v-model="設問2_選択肢タイプ">
                                            <label class="form-check-label" for="4">
                                                複数
                                            </label>
                                        </div>
                                        <div class="mt-3 form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問2_グラフ集計要否">
                                            <label class="form-check-label" for="3">
                                                グラフ集計
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問2_未回答可">
                                            <label class="form-check-label" for="4">
                                                未回答可
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問2_問1" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問2_問2" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問2_問3" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問2_問4" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問2_問5" maxlength="50">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-1 d-flex justify-content-center align-items-center">
                        <button type="button" class="btn border border-dark rounded" @click="moveQuestion(2)"><i class="bi bi-caret-right-fill"></i><br />次の設問</button>
                    </div>
                </div>
                <div class="row" v-show="modal_page===2">
                    <div class="col-md-1 d-flex justify-content-center align-items-center">
                        <button type="button" class="btn border border-dark rounded" @click="moveQuestion(1)"><i class="bi bi-caret-left-fill"></i><br />前の設問</button>
                    </div>
                    <div class="col-md-5">
                        <table class="mt-5 table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="bg-question-no text-white">設問3</td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問3_タイトル" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-answer-setting" rowspan="5">
                                        <label class="form-label">選択肢</label>
                                        <div class="mt-3 ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問3" id="5" value="30080001" v-model="設問3_選択肢タイプ">
                                            <label class="form-check-label" for="5">
                                                単数
                                            </label>
                                        </div>
                                        <div class="ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問3" id="6" value="30080002" v-model="設問3_選択肢タイプ">
                                            <label class="form-check-label" for="6">
                                                複数
                                            </label>
                                        </div>
                                        <div class="mt-3 form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問3_グラフ集計要否">
                                            <label class="form-check-label" for="5">
                                                グラフ集計
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問3_未回答可">
                                            <label class="form-check-label" for="6">
                                                未回答可
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問3_問1" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問3_問2" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問3_問3" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問3_問4" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問3_問5" maxlength="50">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-5">
                        <table class="mt-5 table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="bg-question-no text-white">設問4</td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問4_タイトル" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-answer-setting" rowspan="5">
                                        <label class="form-label">選択肢</label>
                                        <div class="mt-3 ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問4" id="7" value="30080001" v-model="設問4_選択肢タイプ">
                                            <label class="form-check-label" for="7">
                                                単数
                                            </label>
                                        </div>
                                        <div class="ms-3 form-check">
                                            <input class="form-check-input" type="radio" name="設問4" id="8" value="30080002" v-model="設問4_選択肢タイプ">
                                            <label class="form-check-label" for="8">
                                                複数
                                            </label>
                                        </div>
                                        <div class="mt-3 form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問4_グラフ集計要否">
                                            <label class="form-check-label" for="7">
                                                グラフ集計
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="設問4_未回答可">
                                            <label class="form-check-label" for="8">
                                                未回答可
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問4_問1" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問4_問2" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問4_問3" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問4_問4" maxlength="50">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" class="form-control" v-model="設問4_問5" maxlength="50">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-1">
                        
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-blue" @click="closeQuestionModal">閉じる</button>
                    </div>
                </div>
            </div> 
        </div>

        <div id="training-modal" v-show="modalTrainingVisible" @click.self="closeTrainingModal">
            <div id="training-content">
                <div class="row">
                    <label>訓練用配信予約　実施タイミング登録</label>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label>実行間隔</label>
                        <select class="form-select" v-model="trainingTiming" @change="clearTrainingInput(1)">
                            <option v-for="trainingTimingValue in training_timing_list" :value="trainingTimingValue.code" :key="trainingTimingValue.code">{{ trainingTimingValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2" v-show="trainingTiming===30000004">
                        <label>指定月</label>
                        <select class="form-select" v-model="trainingTimingMonth" @change="clearTrainingInput(2)">
                            <option v-for="trainingTimingMonthValue in training_timing_month_list" :value="trainingTimingMonthValue.code" :key="trainingTimingMonthValue.code">{{ trainingTimingMonthValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2" v-show="(trainingTiming !== 0 && trainingTiming !== 30000004) || (trainingTiming === 30000004 && trainingTimingMonth !== 0)">
                        <label>訓練日または曜日</label>
                        <select class="form-select" v-model="trainingDayKind" @change="clearTrainingInput(3)">
                            <option v-for="trainingDayKindValue in training_day_kind_list" :value="trainingDayKindValue.code" :key="trainingDayKindValue.code">{{ trainingDayKindValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2" v-show="trainingDayKind === 30020001">
                        <label>訓練を行う日</label>
                        <select class="form-select" v-model="trainingDay" @change="clearTrainingInput(4)">
                            <option v-for="trainingDayValue in training_day_list" :value="trainingDayValue.code" :key="trainingDayValue.code">{{ trainingDayValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2" v-show="trainingDayKind === 30020002">
                        <label>訓練を行う週</label>
                        <select class="form-select" v-model="trainingWeek" @change="clearTrainingInput(5)">
                            <option v-for="trainingWeekValue in training_week_list" :value="trainingWeekValue.code" :key="trainingWeekValue.code">{{ trainingWeekValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2" v-show="trainingDayKind === 30020002 && trainingWeek > 0">
                        <label>訓練を行う曜日</label>
                        <select class="form-select" v-model="trainingDayOfWeek" @change="clearTrainingInput(5)">
                            <option v-for="trainingDayOfWeekValue in training_day_of_week_list" :value="trainingDayOfWeekValue.code" :key="trainingDayOfWeekValue.code">{{ trainingDayOfWeekValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2" v-show="(trainingDay !== 0 || trainingDayOfWeek !== 0)">
                        <label>訓練を行う時間</label>
                        <select class="form-select" v-model="trainingTime">
                            <option v-for="trainingTimeValue in training_time_list" :value="trainingTimeValue.code" :key="trainingTimeValue.code">{{ trainingTimeValue.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-2 d-flex flex-row justify-content-between align-items-end">
                        <input type="button" class="btn btn-darkblue" value="確定" :disabled="trainingTime===0" @click="confirmTrainingTiming" />
                        <input type="button" class="btn btn-gray ms-2" value="キャンセル" @click="closeTrainingModal" />
                    </div>

                </div> 
            </div>
        </div>

        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            安否メッセージ新規登録
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backPage">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5 ms-3 ">
        <form @submit.prevent="post_form">
            <div class="row mt-3">
                <div id="condition_scroll"></div>
                <div class="col-md-3 d-flex flex-column">
                    <label class="col-form-label">連携する気象条件</label>
                    <label class="col-form-label">（選択は1つのみ）</label>
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-3">
                            <select class="form-select" v-model="select_weather" @change="changeWeatherClass">
                                <option value="" disabled></option>
                                <option v-for="weatherValue in weather_list" :value="weatherValue.code" :key="weatherValue.code">{{ weatherValue.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-5 d-flex flex-wrap">
                            <select class="form-select" v-model="select_condition" @change="changeConditionClass" :class="{'is-invalid': 気象条件_has_error}">
                                <option v-for="conditionValue in conditionList" :value="conditionValue.code" :key="conditionValue.code">{{ conditionValue.name }}</option>
                            </select>
                            <div class='invalid-feedback'>{{ 気象条件_message }}</div>
                        </div>
                        <div class="col-md-1">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>連携する気象条件をこの中から1つずつ登録してください。BCPでは、これに合わせた「災害想定」を策定しておくことをお勧めします。<br />登録した気象条件が気象庁から発せられた場合、すべての利用者（{{ callReplaceWord('従業員') }}等）に対し、安否確認メールが自動送信されます。<br />安否確認後、管理者は被害状況に応じて手動でBCPを発動することとなります。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" v-for="(branch, index) in branch_list" :key="branch.idx">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label" v-show="index === 0">気象庁自動連携<br>（拠点・支店所在地）</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
                    <label class="col-form-label py-3">{{ index + 1 }}</label>
                </div>
                <div class="p2 col-md-7 border d-flex align-items-center justify-content-start">
                    <label class="col-form-label">{{ branch.都道府県 + branch.市区町村 + branch.番地 }}</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                        v-show="index === 0"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>あなたの{{ callReplaceWord('組織') }}の拠点・支店の所在地を、気象庁の地震速報、津波速報、大雨や洪水の注意報・警報と連動して、自動で安否確認メールを配信します。解除することができません。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3" v-if="branch_list.length === 1">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
                    <label class="col-form-label py-3">2</label>
                </div>
                <div class="p2 col-md-7 border d-flex align-items-center justify-content-start">
                    <label class="col-form-label">&emsp;</label>
                </div>
            </div>

            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">登録名称</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <span class="badge bg-resilience-gray">必須</span>
                </div>
                <div class="p2 col-md-7 d-flex align-items-center justify-content-center">
                    <input type="text" class="form-control" v-model="登録名称" required>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>「登録一覧」に表示される連絡の名称となります。地震用や洪水用など自動配信に備えます。メールの件名ではありません。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">メール件名</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <span class="badge bg-resilience-gray">必須</span>
                </div>
                <div class="p2 col-md-7 d-flex align-items-center justify-content-center">
                    <input type="text" class="form-control" v-model="メール件名" required>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>この連絡を電子メールで受信した場合のメール件名となります。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">固定文</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    
                </div>
                <div class="p2 col-md-7 border d-flex align-items-center justify-content-start">
                    <label class="col-form-label">{{ corp_info.組織名 }}［TOTONO-L安否確認システム］からのお知らせです。</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="固定文有無">不要
                    </div>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>メール本文の冒頭に自動挿入される固定文です。不要な場合は「不要」にチェックを入れてください。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">本文</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <span class="badge bg-resilience-gray">必須</span>
                </div>
                <div class="p2 col-md-9 d-flex align-items-center justify-content-start">
                    <textarea rows="10" class="form-control" v-model="本文" required />
                </div>
            </div>
            <div class="row">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-9 d-flex align-items-center justify-content-start">
                    <textarea type="text" rows="6" class="form-control text-start" placeholder="設問回答ページのURLが自動表示されます。"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="mt-3 p2 col-md-9 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-sm btn-darkblue" id="設問・選択肢作成" @click="setQuestion">設問・選択肢作成</button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">家族への安否確認</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="家族への安否確認" id="family_safety_1" value="1" v-model="家族への安否確認">
                        <label class="form-check-label" for="family_safety_1">要</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="家族への安否確認" id="family_safety_2" value="0" v-model="家族への安否確認">
                        <label class="form-check-label" for="family_safety_2">不要</label>
                    </div>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>利用者の登録家族に安否確認メールの送信が不要な場合は「不要」にチェックを入れてください。初期設定では「要」になっています。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">確認機能</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                    <label class="col-form-label">位置情報</label>
                </div>
                <div class="p2 col-md-4 border d-flex align-items-center justify-content-start">
                    <label class="col-form-label">位置情報の通知を受ける</label>
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="確認機能" id="position_safety_1" value="1" v-model="位置情報確認要否">
                        <label class="form-check-label" for="position_safety_1">要</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="確認機能" id="position_safety_2" value="0" v-model="位置情報確認要否">
                        <label class="form-check-label" for="position_safety_2">不要</label>
                    </div>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>安否確認メールに位置情報の通知有無の設問を組み込みます。回答者が「通知する」と回答するとGoogleマップの位置情報で現在地を管理者に表示することができます。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                    <label class="col-form-label">{{ callReplaceWord('出社') }}可能時間</label>
                </div>
                <div class="p2 col-md-4 border d-flex align-items-center justify-content-start">
                    <label class="col-form-label">{{ callReplaceWord('出社') }}可能時間の通知を受ける</label>
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="出社可能時間" id="office_safety_1" value="1" v-model="出社可能時間確認要否">
                        <label class="form-check-label" for="office_safety_1">要</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="出社可能時間" id="office_safety_2" value="0" v-model="出社可能時間確認要否">
                        <label class="form-check-label" for="office_safety_2">不要</label>
                    </div>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>安否確認メールに{{ callReplaceWord('出社') }}可能時間の設問を組み込みます。回答は「いつでも可能」「3日以内」「見通しが立たない」「コメント欄へ」の4択で返信してもらいます。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">未回答者への自動再配信</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                    <label class="col-form-label">連絡間隔</label>
                </div>
                <div class="col-md-3">
                    <select class="form-select text-center" aria-label="連絡間隔" v-model="再配信_間隔" :class="{'is-invalid': 自動再配信_間隔_message.length > 0}">
                        <option value="">‐ 連絡間隔 ‐</option>
                        <option v-for="interval in contact_interval_list" :key="interval.code" :value="interval.code">{{ interval.name }}</option>
                    </select>
                    <div class='invalid-feedback'>{{ 自動再配信_間隔_message }}</div>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>未回答者へ自動再配信ができます。再配信の回数、送信間隔の設定を行ってください。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="col-form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                    <label class="col-form-label">再連絡回数</label>
                </div>
                <div class="col-md-3">
                    <select class="form-select text-center" aria-label="再連絡回数" v-model="再配信_連絡回数" :class="{'is-invalid': 自動再配信_回数_message.length > 0}">
                        <option value="">‐ 再連絡回数 ‐</option>
                        <option v-for="re_contacts in re_contacts_list" :key="re_contacts.code" :value="re_contacts.code">{{ re_contacts.name }}</option>
                    </select>
                    <div class='invalid-feedback'>{{ 自動再配信_回数_message }}</div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                    <label class="col-form-label">訓練用配信予約</label>
                </div>
                <div class="p2 col-md-2 border d-flex align-items-center justify-content-center ms-3">
                    <label class="col-form-label">{{ trainingTimingName }} {{ trainingTimingMonthName }}</label>
                </div>
                <div class="ms-3 p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
                    <label class="col-form-label" v-show="trainingDayKind === 30020001">{{ trainingDayKindName }}</label>
                    <label class="col-form-label" v-show="trainingDayKind === 30020002">{{ trainingWeekName }}</label>
                </div>
                <div class="p2 col-md-1 border d-flex align-items-center justify-content-center">
                    <label class="col-form-label" v-show="trainingDayKind === 30020001">{{ trainingDayName }}</label>
                    <label class="col-form-label" v-show="trainingDayKind === 30020002">{{ trainingDayOfWeekName }}</label>
                </div>
                <div class="ms-3 p2 col-md-1 border d-flex align-items-center justify-content-center">
                    <label class="col-form-label">{{ trainingTimeName }}</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-sm btn-darkblue" @click="setTraining">設 定</button>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>安否確認訓練やシステム操作訓練を行う際の配信予約をします。予め設定した日時に登録した安否メッセージが送信されます。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>

            <br><br>

            <div class="row mt-5 pb-5 d-flex flex-row">
                <div class="col-md-3">
                    <div class="d-flex align-items-center justify-content-start flex-row">
                        <input type="submit" class="text-center btn btn-black" value="登録する">
                    </div>
                    <div class="row flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                        {{ flashMessage }}
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="d-flex align-items-center justify-content-start flex-row">
                        <input type="button" class="text-center btn btn-gray" value="安否確認HOMEへ戻る" @click="backHome">
                    </div>
                </div>
            </div>
        </form>
        
    </div>

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const isProcessing = ref(false);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const 登録名称 = ref('');
        const メール件名 = ref('');
        const 固定文有無 = ref(false);
        const 本文 = ref('');
        const 家族への安否確認 = ref("0");
        const 位置情報確認要否 = ref("1");
        const 出社可能時間確認要否 = ref("1");
        const 再配信_間隔 = ref('');
        const 再配信_連絡回数 = ref('');

        const 設問1_選択肢タイプ = ref(30080001);
        const 設問1_グラフ集計要否 = ref(true);
        const 設問1_未回答可 = ref(false);
        const 設問1_タイトル = ref('');
        const 設問1_問1 = ref('');
        const 設問1_問2 = ref('');
        const 設問1_問3 = ref('');
        const 設問1_問4 = ref('');
        const 設問1_問5 = ref('');
        const 設問2_選択肢タイプ = ref(30080002);
        const 設問2_グラフ集計要否 = ref(true);
        const 設問2_未回答可 = ref(false);
        const 設問2_タイトル = ref('');
        const 設問2_問1 = ref('');
        const 設問2_問2 = ref('');
        const 設問2_問3 = ref('');
        const 設問2_問4 = ref('');
        const 設問2_問5 = ref('');
        const 設問3_選択肢タイプ = ref(30080002);
        const 設問3_グラフ集計要否 = ref(true);
        const 設問3_未回答可 = ref(false);
        const 設問3_タイトル = ref('');
        const 設問3_問1 = ref('');
        const 設問3_問2 = ref('');
        const 設問3_問3 = ref('');
        const 設問3_問4 = ref('');
        const 設問3_問5 = ref('');
        const 設問4_選択肢タイプ = ref(30080001);
        const 設問4_グラフ集計要否 = ref(true);
        const 設問4_未回答可 = ref(false);
        const 設問4_タイトル = ref('');
        const 設問4_問1 = ref('');
        const 設問4_問2 = ref('');
        const 設問4_問3 = ref('');
        const 設問4_問4 = ref('');
        const 設問4_問5 = ref('');

        onMounted(() => {
            登録名称.value = "震度5弱以上の地震速報が気象庁から発表されたとき";
            メール件名.value = "［要返信］気象庁震度速報による安否確認 TOTONO-L";
            本文.value = "気象庁地震速報により、" + callReplaceWord('従業員') + 'およびその家族の皆様の安否確認を実施しています。\r下記のURLをクリックし、安否状況についてお知らせださい。';
            設問1_タイトル.value = 'あなたの被害状況を教えてください';
            設問1_問1.value = '被害があります';
            設問1_問2.value = '被害はありません';
            設問2_タイトル.value = 'ケガの程度はどうですか？';
            設問2_問1.value = 'かすり傷';
            設問2_問2.value = '出血';
            設問2_問3.value = '捻挫・骨折';
            設問2_問4.value = 'やけど';
            設問2_問5.value = '問題なし';
            設問3_タイトル.value = 'その他被害状況はどうですか？';
            設問3_問1.value = '自宅の被害';
            設問3_問2.value = '周辺道路の被害';
            設問3_問3.value = 'マイカーの被害';
            設問3_問4.value = '問題なし';
            設問3_問5.value = 'コメント欄へ';
        })

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backPage = () => {
            let url;

            switch(props.back){
                case 'home':
                    url = '/safety/home';
                    break;
                case 'k2':
                    url = '/safety/k2';
                    break;
                default:
                    url = '/safety/home';
                    break;
            }

            location.href = url;
        }

        const backHome = () => {
            const url = '/safety/home';

            location.href = url;
        }

        const modalQuestionVisible = ref(false);

        const closeQuestionModal = () => {
            modalQuestionVisible.value = false;
        }

        const setQuestion = () => {
            modalQuestionVisible.value = true;
        }

        const modalTrainingVisible = ref(false);

        const closeTrainingModal = () => {
            trainingTiming.value = 0;
            trainingTimingMonth.value = 0;
            trainingDayKind.value = 0;
            trainingDay.value = 0;
            trainingDayOfWeek.value = 0;
            trainingTime.value = 0;
            trainingWeek.value = 0;

            modalTrainingVisible.value = false;
        }

        const trainingTimingName = computed(() => {
            const temp = props.training_timing_list.reduce((acc, value) => {
                if(value.code === trainingTiming.value){
                    acc = value.name;
                }
                return acc;
            }, "");
            return temp;
        });

        const trainingTimingMonthName = computed(() => {
            const name = props.training_timing_month_list.reduce((acc, value) => {
                if(value.code === trainingTimingMonth.value){
                    acc = value.name;
                }
                return acc;
            }, "");
            return name;
        });

        const trainingDayKindName = computed(() => {
            const name = props.training_day_kind_list.reduce((acc, value) => {
                if(value.code === trainingDayKind.value){
                    acc = value.name;
                }
                return acc;
            }, "");
            return name;
        });

        const trainingDayName = computed(() => {
            const name = props.training_day_list.reduce((acc, value) => {
                if(value.code === trainingDay.value){
                    acc = value.name;
                }
                return acc;
            }, "");
            return name;
        });

        const trainingDayOfWeekName = computed(() => {
            const name = props.training_day_of_week_list.reduce((acc, value) => {
                if(value.code === trainingDayOfWeek.value){
                    acc = value.name;
                }
                return acc;
            }, "");
            return name;
        });

        const trainingTimeName = computed(() => {
            const name = props.training_time_list.reduce((acc, value) => {
                if(value.code === trainingTime.value){
                    acc = value.name;
                }
                return acc;
            }, "");
            return name;
        });

        const trainingWeekName = computed(() => {
            const name = props.training_week_list.reduce((acc, value) => {
                if(value.code === trainingWeek.value){
                    acc = value.name;
                }
                return acc;
            }, "");

            return name;
        });

        const confirmTrainingTiming = () => {
            modalTrainingVisible.value = false;
        }

        const setTraining = () => {
            modalTrainingVisible.value = true;
        }

        const trainingTiming = ref(0);
        const trainingTimingMonth = ref(0);
        const trainingDayKind = ref(0);
        const trainingDay = ref(0);
        const trainingDayOfWeek = ref(0);
        const trainingTime = ref(0);
        const trainingWeek = ref(0);

        const clearTrainingInput = (mode) => {
            switch(mode){
                case 1:
                    //実行間隔変更
                    trainingTimingMonth.value = 0;
                    trainingDayKind.value = 0;
                    trainingDay.value = 0;
                    trainingDayOfWeek.value = 0;
                    trainingTime.value = 0;
                    trainingWeek.value = 0;
                    break;
                case 2:
                    //指定月変更
                    //処理なし
                    break;
                case 3:
                    //訓練日または曜日変更
                    trainingDay.value = 0;
                    trainingDayOfWeek.value = 0;
                    trainingTime.value = 0;
                    trainingWeek.value = 0;
                    break;
                case 4:
                    //訓練を行う日変更
                    trainingDayOfWeek.value = 0;
                    trainingTime.value = 0;
                    break;
                case 5:
                    //訓練を行う時間変更
                    trainingTime.value = 0;
                    break;
                default:

            }

        }

        const select_weather = ref(0);
        const conditionList = ref([]);

        const changeWeatherClass = () => {
            switch(select_weather.value){
                case 41000001:
                    conditionList.value = props.seismic_list;
                    break;
                case 41000002:
                    conditionList.value = props.tsunami_list;
                    break;
                case 41000003:
                    conditionList.value = props.special_alarm_list;
                    break;
                case 41000004:
                    conditionList.value = props.alarm_list;
                    break;
                case 41000005:
                    conditionList.value = props.advisory_list;
                    break;
                default:
                    conditionList.value = [];
                    break;
            }
        }

        const changeConditionClass = () => {
            気象条件_has_error.value = false;
            気象条件_message.value = '';

            if(select_condition.value === '' || select_condition.value === 0){
                return;
            }

            const url = '/api/safety/getByCorpIDAndCondition?corp_id=' + props.corp_info.id + '&condition=' + select_condition.value;

            axios.get(url).then(res => {
                if(res.data){
                    if(confirm("指定された気象条件は既に登録済みです。指定された気象条件の編集画面に遷移しますか？")){
                        const url_edit = '/safety/editSafetySetting?back=k1&corp_id=' + res.data.corp_id + '&condition=' + res.data.condition;

                        location.href = url_edit;
                    }else{
                        select_weather.value = 0;
                        select_condition.value = 0;
                    }
                }else{
                    //重複なしの場合、登録名称、メール件名を更新する
                    登録名称.value = calcTitle() + "が気象庁から発表されたとき";
                    メール件名.value = "［要返信］気象庁" + calcMailTitle() + "による安否確認 TOTONO-L";
                    本文.value = "気象庁" + weatherName.value + "速報により、" + callReplaceWord('従業員') + 'およびその家族の皆様の安否確認を実施しています。\r下記のURLをクリックし、安否状況についてお知らせださい。';
                }
            });
        }

        const calcTitle = () => {
            switch(select_weather.value){
                case 41000001:
                    return conditionName.value + "の" + weatherName.value + '速報';
                default:
                    return conditionName.value;
            }
        }

        const calcMailTitle = () => {
            switch(select_weather.value){
                case 41000001:
                case 41000002:
                    return weatherName.value + '速報';
                default:
                    return conditionName.value;
            }
        }

        const weatherName = computed(() => {
            const name = props.weather_list.reduce((acc, value) => {
                if(value.code === select_weather.value){
                    acc = value.name;
                }
                return acc;
            }, "");

            return name;
        });

        const conditionName = computed(() => {
            if(select_weather.value === 0){
                return "";
            }
            
            const name = conditionList.value.reduce((acc, value) => {
                if(value.code === select_condition.value){
                    acc = value.name;
                }
                return acc;
            }, "");

            return name;

        });

        const select_condition = ref(0);

        const modal_page = ref(1);
        const moveQuestion = (page) => {
            modal_page.value = page;
        }

        const 自動再配信_間隔_message = ref("");
        const 自動再配信_回数_message = ref("");

        const post_form = () => {
            const url = '/api/registerSafetyConfirmationSettings';

            気象条件_has_error.value = false;
            isProcessing.value = true;
            自動再配信_間隔_message.value = "";
            自動再配信_回数_message.value = "";

            axios.post(url, {
                corp_id: props.corp_info.id,
                登録名称: 登録名称.value,
                メール件名: メール件名.value,
                固定文有無: !固定文有無.value,
                本文: 本文.value,
                condition: select_condition.value,
                家族への安否確認: 家族への安否確認.value,
                位置情報確認要否: 位置情報確認要否.value,
                出社可能時間確認要否: 出社可能時間確認要否.value,
                自動再配信_間隔: 再配信_間隔.value,
                自動再配信_回数: 再配信_連絡回数.value,
                訓練_月: trainingTiming.value,
                訓練_指定した月: trainingTimingMonth.value,
                訓練_日_曜日: trainingDayKind.value,
                訓練_指定日_指定曜日: trainingDayKind.value === 30020001 ? trainingDay.value : trainingDayOfWeek.value,
                訓練_時間: trainingTime.value,
                訓練_週: trainingWeek.value,
                設問1_選択肢タイプ: 設問1_選択肢タイプ.value,
                設問1_グラフ集計要否: 設問1_グラフ集計要否.value,
                設問1_未回答可: 設問1_未回答可.value,
                設問1_タイトル: 設問1_タイトル.value,
                設問1_問1: 設問1_問1.value,
                設問1_問2: 設問1_問2.value,
                設問1_問3: 設問1_問3.value,
                設問1_問4: 設問1_問4.value,
                設問1_問5: 設問1_問5.value,
                設問2_選択肢タイプ: 設問2_選択肢タイプ.value,
                設問2_グラフ集計要否: 設問2_グラフ集計要否.value,
                設問2_未回答可: 設問2_未回答可.value,
                設問2_タイトル: 設問2_タイトル.value,
                設問2_問1: 設問2_問1.value,
                設問2_問2: 設問2_問2.value,
                設問2_問3: 設問2_問3.value,
                設問2_問4: 設問2_問4.value,
                設問2_問5: 設問2_問5.value,
                設問3_選択肢タイプ: 設問3_選択肢タイプ.value,
                設問3_グラフ集計要否: 設問3_グラフ集計要否.value,
                設問3_未回答可: 設問3_未回答可.value,
                設問3_タイトル: 設問3_タイトル.value,
                設問3_問1: 設問3_問1.value,
                設問3_問2: 設問3_問2.value,
                設問3_問3: 設問3_問3.value,
                設問3_問4: 設問3_問4.value,
                設問3_問5: 設問3_問5.value,
                設問4_選択肢タイプ: 設問4_選択肢タイプ.value,
                設問4_グラフ集計要否: 設問4_グラフ集計要否.value,
                設問4_未回答可: 設問4_未回答可.value,
                設問4_タイトル: 設問4_タイトル.value,
                設問4_問1: 設問4_問1.value,
                設問4_問2: 設問4_問2.value,
                設問4_問3: 設問4_問3.value,
                設問4_問4: 設問4_問4.value,
                設問4_問5: 設問4_問5.value,
            }).then(res => {
                visibleFlashMessage('正常に更新されました', 'success');

                select_weather.value = 0;
                select_condition.value = 0;
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.condition){
                        気象条件_message.value = err.response.data.errors.condition[0];
                        気象条件_has_error.value = true;
                        // location.hash = "#気象条件";
                        document.getElementById('condition_scroll').scrollIntoView();
                    }
                    if(err.response.data.errors.自動再配信_間隔){
                        自動再配信_間隔_message.value = err.response.data.errors.自動再配信_間隔[0];
                    }
                    if(err.response.data.errors.自動再配信_回数){
                        自動再配信_回数_message.value = err.response.data.errors.自動再配信_回数[0];
                    }
                }
            }).finally(fin => {
                isProcessing.value = false;
            })
        }

        const 気象条件_has_error = ref(false);
        const 気象条件_message = ref('');

        return {
            setLevel,
            isProcessing,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            callReplaceWord,
            backHome,
            backPage,

            modalQuestionVisible,
            closeQuestionModal,
            setQuestion,

            modalTrainingVisible,
            closeTrainingModal,
            confirmTrainingTiming,
            setTraining,

            trainingTiming,
            trainingTimingMonth,
            trainingDayKind,
            trainingDay,
            trainingDayOfWeek,
            trainingTime,
            trainingWeek,

            trainingTimingName,
            trainingTimingMonthName,
            trainingDayKindName,
            trainingDayName,
            trainingDayOfWeekName,
            trainingTimeName,
            trainingWeekName,

            clearTrainingInput,

            conditionList,

            changeWeatherClass,
            changeConditionClass,

            select_weather,
            select_condition,

            modal_page,
            moveQuestion,

            登録名称,
            メール件名,
            固定文有無,
            本文,
            家族への安否確認,
            位置情報確認要否,
            出社可能時間確認要否,
            再配信_間隔,
            再配信_連絡回数,
            設問1_選択肢タイプ,
            設問1_グラフ集計要否,
            設問1_未回答可,
            設問1_タイトル,
            設問1_問1,
            設問1_問2,
            設問1_問3,
            設問1_問4,
            設問1_問5,
            設問2_選択肢タイプ,
            設問2_グラフ集計要否,
            設問2_未回答可,
            設問2_タイトル,
            設問2_問1,
            設問2_問2,
            設問2_問3,
            設問2_問4,
            設問2_問5,
            設問3_選択肢タイプ,
            設問3_グラフ集計要否,
            設問3_未回答可,
            設問3_タイトル,
            設問3_問1,
            設問3_問2,
            設問3_問3,
            設問3_問4,
            設問3_問5,
            設問4_選択肢タイプ,
            設問4_グラフ集計要否,
            設問4_未回答可,
            設問4_タイトル,
            設問4_問1,
            設問4_問2,
            設問4_問3,
            設問4_問4,
            設問4_問5,

            post_form,

            気象条件_has_error,
            気象条件_message,

            weatherName,
            conditionName,

            calcTitle,
            calcMailTitle,

            自動再配信_間隔_message,
            自動再配信_回数_message,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        branch_list: {
            type: Array,
        },
        replacement_word_list: {
            type: Array,
        },
        training_timing_list: {
            type: Array,
        },
        training_timing_month_list: {
            type: Array,
        },
        training_day_kind_list: {
            type: Array,
        },
        training_day_list: {
            type: Array,
        },
        training_day_of_week_list: {
            type: Array,
        },
        training_time_list: {
            type: Array,
        },
        training_week_list: {
            type: Array,
        },
        contact_interval_list: {
            type: Array,
        },
        re_contacts_list: {
            type: Array,
        },
        weather_list: {
            type: Array,
        },
        seismic_list: {
            type: Array,
        },
        tsunami_list: {
            type: Array,
        },
        special_alarm_list: {
            type: Array,
        },
        alarm_list: {
            type: Array,
        },
        advisory_list: {
            type: Array,
        },
        back: {
            type: String,
        },
    }

}
</script>

<style lang="scss" scoped>
    .btn-darkblue {
        border: 1px solid;
        color: white;
        border-color: #2f75b5;
        background-color: #2f75b5;
    }

    .btn-darkblue:hover {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

    .badge-required {
        border: 1px solid;
        color: #9cb4e0;
        border-color: #172c51;
        background-color: white;
    }

    .bg-question-no {
        background-color: #2f75b5;
    }

    .bg-answer-setting {
        background-color: #bdd7ee;
    }

    #question-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #question-content {
        z-index: 2;
        width: 90%;
        height: 80%;
        padding: 1em;
        background: #f2f2f2;
    }

    #training-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #training-content {
        z-index: 2;
        width: 80%;
        padding: 5em;
        background: #f2f2f2;
    }

    .btn-circle.btn-lg,
    .btn-circle:hover.btn-lg:hover
     {
        width: 50px;
        height: 50px;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.33;
        border-radius: 25px;
    }

    .btn-blue {
        border: 1px solid;
        color: white;
        border-color: #2f75b5;
        background-color: #2f75b5;
    }

    .btn-blue:hover {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

</style>