<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="row">
                <div class="h4 d-flex justify-content-center color-resilience-red">主な機能（TOTONO-Lが解決してくれること）</div>
            </div>
            <div class="row d-flex justify-content-center mb-2">
                <div class="h4 col-1 bg-color-white border-resilience-red d-flex justify-content-center color-resilience-red fw-bold">
                    2
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center my-3">
            <div class="h1 d-flex justify-content-center color-resilience-red fw-bold">
                効率的な在庫管理を実現した「防災備蓄品リスト」
            </div>
        </div>
        <div class="row">
            <label class="color-resilience-red">「どこに、なにが、どれだけ、いつまで」----- 備蓄品の情報をすべての役職員が簡単に確認できます。</label>
        </div>
        <div class="row mt-5">
            <label>防災備蓄品管理では、（1）紙の台帳では保管場所や状態が把握しにくい、（2）消費期限を見逃しやすい、（3）在庫管理が職員に負担をかける、といった課題があります。</label>
        </div>
        <div class="row mb-5">
            <label>TOTONO-Lは、消費期限（賞味期限・使用期限）と補充のタイミングを自動でお知らせし、これらの問題を解決します。</label>
        </div>
        <div class="relative d-flex justify-content-center py-3">
            <i id="explanation_image_1" class="explanation_image" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .td-bg-resilience-red {
        background-color: #990033 !important;
    }

    .td-center {
        text-align: center;
        vertical-align: middle;
    }
    
    .explanation_image {
        display: inline-flex;
        width: 1100.341px;
        height: 523.567px;
        background-size: 1100.341px auto;
        background-repeat: no-repeat;

    }

    #explanation_image_1 {
        background-image: url('../../../svg/01-L2.svg');
    }

    @media (max-width: 1199px) {
        .explanation_image {
            display: inline-flex;
            width: 900px;
            height: 447.273px;
            background-size: 900px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 991px) {
        .explanation_image {
            display: inline-flex;
            width: 700px;
            height: 447.273px;
            background-size: 700px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 767px) {
        .explanation_image {
            display: inline-flex;
            width: 500px;
            height: 447.273px;
            background-size: 500px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 575px) {
        .explanation_image {
            display: inline-flex;
            width: 400px;
            height: 447.273px;
            background-size: 400px auto;
            background-repeat: no-repeat;
        }
    }

</style>

<script>

</script>