<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            自己診断・内部監査結果
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-1 mt-3 d-flex flex-row">
        <div class="col-md-2 bg-color-basic d-flex align-items-center justify-content-center">
            基本コース
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <table class="ms-1 mt-3 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="administrator_title text-center" style="width: 50%;" colspan="4">経営者による自己診断</td>
                        <td class="auditor-title text-center" colspan="4">内部監査人による点検</td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" style="width: 13%;">はいの数</td>
                        <td class="text-end">{{ self_diagnoses_yes_count }}</td>
                        <td class="bg-light-gray" style="width: 13%;">いいえの数</td>
                        <td class="text-end">{{ self_diagnoses_no_count }}</td>
                        <td class="bg-light-gray" style="width: 13%;">はいの数</td>
                        <td class="text-end">{{ auditor_inspection_yes_count }}</td>
                        <td class="bg-light-gray" style="width: 13%;">いいえの数</td>
                        <td class="text-end">{{ auditor_inspection_no_count }}</td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="4">いいえの項目</td>
                        <td class="bg-light-gray" colspan="4">いいえの項目</td>
                    </tr>
                    <tr v-for="i of noListCount" :key="i">
                        <td colspan="4">{{ self_diagnoses_no_item_list[i - 1] }}</td>
                        <td colspan="4">{{ auditor_inspection_no_item_list[i - 1] }}</td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray" colspan="4">不明の項目</td>
                        <td class="bg-light-gray" colspan="4">不明の項目</td>
                    </tr>
                    <tr v-for="i of unknownListCount" :key="i">
                        <td colspan="4">{{ self_diagnoses_unknown_item_list[i - 1] }}</td>
                        <td colspan="4">{{ auditor_inspection_unknown_item_list[i - 1] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md-11">
            この自己診断は、あなたの会社のBCP運用状況をチェックするためのものですが、「はい」の数の合計により「合格」「不合格」を判定することが目的ではなく、「はい」にチェックが付けられなかった項目を把握して、その部分の対応について、今後もう少し力を入れて取り組む必要があるということを認識することが本来の目的です。ですから、「はい」の数を深く気にすることなく、「いいえ」にチェックが付いた項目を一つずつ減らしていくように努力してください。
        </div>
    </div>

    <div class="row mt-5">
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" @click="backMenu">
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('010000');
        const noListCount = Math.max(props.self_diagnoses_no_item_list.length, props.auditor_inspection_no_item_list.length);
        const unknownListCount = Math.max(props.self_diagnoses_unknown_item_list.length, props.auditor_inspection_unknown_item_list.length);

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const backMenu = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        return {
            setLevel,
            noListCount,
            unknownListCount,
            showBack,
            backMenu,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        self_diagnoses_yes_count: {
            type: Number,
        },
        self_diagnoses_no_count: {
            type: Number,
        },
        self_diagnoses_no_item_list: {
            type: Array,
        },
        self_diagnoses_unknown_item_list: {
            type: Array,
        },
        auditor_inspection_yes_count: {
            type: Number,
        },
        auditor_inspection_no_count: {
            type: Number,
        },
        auditor_inspection_no_item_list: {
            type: Array,
        },
        auditor_inspection_unknown_item_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-color-basic {
    background-color: #4472c4;
    color: white;
}

.auditor-title {
    background-color: #00cc99;
    color: white;
}

.administrator_title {
    background-color: #ff6600;
    color: white;
}
</style>
