<template>
    <div id="industrial-modal" v-show="modalIndustrialVisible" @click.self="closeIndustrialModal">
        <div id="modal-content">
            <div class="row">
                <label class="col-form-label">探したい業種を選択してください。</label>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">大分類（A～T）</label>
                <div class="col-md-8">
                    <select name="業種_大分類" class="form-select" v-model="業種_大分類" required @change="changeLargeClass">
                        <option type="text" value="0">こだわらない・特定しない</option>
                        <option type="text" v-for="large in large_class_list" :key="large.id" :value="large.大分類">{{ large.大分類 + ':' + large.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">中分類（2桁）</label>
                <div class="col-md-8">
                    <select name="業種_中分類" class="form-select" v-model="業種_中分類" required @change="changeMiddleClass">
                        <option type="text" v-for="middle in middle_class_list" :key="middle.id" :value="middle.中分類">{{ middle.中分類 + ':' + middle.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">小分類（3桁）</label>
                <div class="col-md-8">
                    <select name="業種_小分類" class="form-select" v-model="業種_小分類" required @change="changeMinorClass">
                        <option type="text" v-for="minor in minor_class_list" :key="minor.id" :value="minor.小分類">{{ minor.小分類 + ':' + minor.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-md-4 col-form-label">細分類（4桁）</label>
                <div class="col-md-8">
                    <select name="業種_細分類" class="form-select" v-model="業種_細分類" required @change="changeSubClass">
                        <option type="text" v-for="sub in sub_class_list" :key="sub.id" :value="sub.細分類">{{ sub.細分類 + ':' + sub.項目名 }}</option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
                <input type="button" class="btn btn-red" value="選択" :disabled="業種_大分類 !== '0' && 業種_小分類.length === 0" @click="select分類" />
                <input type="button" class="btn btn-gray" value="閉じる" @click="closeIndustrialModal" />
            </div>
        </div>
    </div>
    <div id="region-modal" v-show="modalRegionVisible" @click.self="closeRegionModal">
        <div id="modal-content">
            <div class="row mb-3">
                <label class="col-form-label">探したい地域を選択してください。</label>
            </div>
            <div class="row border border-black">
                <div class="col-md-4">
                    <span class="ms-3">北海道・東北地方</span>
                    <div class="ms-4">
                        <span class="badge btn-gray cursor-pointer" @click="PrefSelect('北海道・東北')">全選択</span>
                        <span class="badge btn-gray cursor-pointer ms-3" @click="PrefErase('北海道・東北')">全解除</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div v-for="pref in 北海道東北list" :key="pref.code" class="col-auto">
                            <input type="checkbox" v-model="selectPref" class="form-check-input ms-3" :id="pref.name" :value="pref.code">
                            <label class="form-check-label ms-3" :for="pref.name" >{{ pref.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border border-black">
                <div class="col-md-4">
                    <span class="ms-3">関東地方</span>
                    <div class="ms-4">
                        <span class="badge btn-gray cursor-pointer" @click="PrefSelect('関東')">全選択</span>
                        <span class="badge btn-gray cursor-pointer ms-3" @click="PrefErase('関東')">全解除</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div v-for="pref in 関東list" :key="pref.code" class="col-auto">
                            <input type="checkbox" v-model="selectPref" class="form-check-input ms-3" :id="pref.name" :value="pref.code">
                            <label class="form-check-label ms-3" :for="pref.name" >{{ pref.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border border-black">
                <div class="col-md-4">
                    <span class="ms-3">中部地方</span>
                    <div class="ms-4">
                        <span class="badge btn-gray cursor-pointer" @click="PrefSelect('中部')">全選択</span>
                        <span class="badge btn-gray cursor-pointer ms-3" @click="PrefErase('中部')">全解除</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div v-for="pref in 中部list" :key="pref.code" class="col-auto">
                            <input type="checkbox" v-model="selectPref" class="form-check-input ms-3" :id="pref.name" :value="pref.code">
                            <label class="form-check-label ms-3" :for="pref.name" >{{ pref.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border border-black">
                <div class="col-md-4">
                    <span class="ms-3">近畿地方</span>
                    <div class="ms-4">
                        <span class="badge btn-gray cursor-pointer" @click="PrefSelect('近畿')">全選択</span>
                        <span class="badge btn-gray cursor-pointer ms-3" @click="PrefErase('近畿')">全解除</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div v-for="pref in 近畿list" :key="pref.code" class="col-auto">
                            <input type="checkbox" v-model="selectPref" class="form-check-input ms-3" :id="pref.name" :value="pref.code">
                            <label class="form-check-label ms-3" :for="pref.name" >{{ pref.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border border-black">
                <div class="col-md-4">
                    <span class="ms-3">中国・四国地方</span>
                    <div class="ms-4">
                        <span class="badge btn-gray cursor-pointer" @click="PrefSelect('中国・四国')">全選択</span>
                        <span class="badge btn-gray cursor-pointer ms-3" @click="PrefErase('中国・四国')">全解除</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div v-for="pref in 中国四国list" :key="pref.code" class="col-auto">
                            <input type="checkbox" v-model="selectPref" class="form-check-input ms-3" :id="pref.name" :value="pref.code">
                            <label class="form-check-label ms-3" :for="pref.name" >{{ pref.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border border-black">
                <div class="col-md-4">
                    <span class="ms-3">九州・沖縄地方</span>
                    <div class="ms-4">
                        <span class="badge btn-gray cursor-pointer" @click="PrefSelect('九州・沖縄')">全選択</span>
                        <span class="badge btn-gray cursor-pointer ms-3" @click="PrefErase('九州・沖縄')">全解除</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div v-for="pref in 九州沖縄list" :key="pref.code" class="col-auto">
                            <input type="checkbox" v-model="selectPref" class="form-check-input ms-3" :id="pref.name" :value="pref.code">
                            <label class="form-check-label ms-3" :for="pref.name" >{{ pref.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
                <!-- <input type="button" class="btn btn-red" value="選択" @click="closeRegionModal" /> -->
                <input type="button" class="btn btn-gray" value="閉じる" @click="closeRegionModal" />
            </div>
        </div>
    </div>
    <div id="confirm-modal" v-show="modalConfirmVisible" @click.self="closeConfirmModal">
        <div id="confirm-content">
            <div class="text-center">
                マッチング交渉入力が完了していませんが、
            </div>
            <div class="mt-2 text-center">
                ページを移動しますか？
            </div>
            <div class="d-flex justify-content-between mt-5">
                <input type="button" class="btn btn-gray" value="はい、終了します" @click="returnSelect" />
                <input type="button" class="btn btn-gray" value="いいえ、続けます" @click="closeConfirmModal" />
            </div>
        </div>
    </div>
    <div v-show="mode === 1">
        <div class="row">
            <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
                マッチング先を探す
            </div>
            <div class="p2 mt-3 ms-auto col-md d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-bnb-blue btn-sm" value="ビジネスマッチング規約" @click="showBnBTerms">
            </div>
            <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="row mt-5 ms-3 ">
            <div class="col-md-4">
                <input type="button" class="btn btn-lg btn-search-condition py-3 px-5" value="業種［日本標準産業分類］" @click="openIndustrialModal" />
                <div class="mx-3">
                    <span>{{ select業種コード + '　' + select業種名}}</span>
                </div>
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-lg btn-search-condition py-3 px-5" value="地域［都道府県］" @click="openRegionModal" />
                <div class="mx-3">
                    <span>{{ convertPrefCodeToName() }}</span>
                </div>
            </div>
            <div class="col-md-3">
                <button type="button" class="btn btn-lg btn-bnb-blue py-3 px-5" id="検索する" @click="clickSearchCorp">
                    <label>
                        <i class="bi bi-search"></i>検索する
                    </label>
                </button>
            </div>
        </div>

    <div class="row ms-3 mt-5 d-flex flex-row">
            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
                <label for="" class="form-label">絞込み検索</label>
            </div>
        </div>
        <div class="row ms-3 d-flex flex-row">
            <div class="p2 col-md-12 border border-dark border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-10 border border-start-0 border-top-0 border-end-0">
                    <label for="" class="mt-3 form-label">カテゴリー　　　※複数選択ができます。</label>
                </div>
            </div>
            <div class="p2 col-md-12 border border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-1 align-items-center">
                    <label for="" class="mt-3 form-label border bg-info">&nbsp;ヒト&nbsp;</label>
                </div>
                <div class="ms-3 mt-3 col-md-2 d-flex align-items-center">
                    <label for="" class="form-label border">応援要員&emsp;&emsp;&emsp;&emsp;</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-3" type="checkbox" id="inlineCheckbox1_1" value="1_1" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox1_1">協力を依頼したい</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-5" type="checkbox" id="inlineCheckbox1_2" value="1_2" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox1_2">要請に応えたい</label>
                </div>
                <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>相互協定を原則としているため、他の{{ callReplaceWord('組織') }}に求めることは、{{ callReplaceWord('自社') }}が実行することが必要です。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
        </div>
        <div class="row ms-3 d-flex flex-row">
            <div class="p2 col-md-12 border border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-1 align-items-center">
                    <label for="" class="mt-3 form-label border bg-info">&nbsp;モノ&nbsp;</label>
                </div>
                <div class="ms-3 mt-3 col-md-2 d-flex align-items-center">
                    <label for="" class="form-label border">資機材・車両等&emsp;</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-3" type="checkbox" id="inlineCheckbox2_1" value="2_1" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox2_1">協力を依頼したい</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-5" type="checkbox" id="inlineCheckbox2_2" value="2_2" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox2_2">要請に応えたい</label>
                </div>
            </div>
        </div>
        <div class="row ms-3 d-flex flex-row">
            <div class="p2 col-md-12 border border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-1 align-items-center">
                    <label for="" class="mt-3 form-label border bg-info">&nbsp;コト&nbsp;</label>
                </div>
                <div class="ms-3 mt-3 col-md-2 d-flex align-items-center">
                    <label for="" class="form-label border">代替拠点&emsp;&emsp;&emsp;&emsp;</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-3" type="checkbox" id="inlineCheckbox3_1" value="3_1" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox3_1">協力を依頼したい</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-5" type="checkbox" id="inlineCheckbox3_2" value="3_2" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox3_2">要請に応えたい</label>
                </div>
            </div>
        </div>
        <div class="row ms-3 d-flex flex-row">
            <div class="p2 col-md-12 border border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-1 align-items-center">
                    <label for="" class="mt-3 form-label">&nbsp;</label>
                </div>
                <div class="ms-3 mt-3 col-md-2 d-flex align-items-center">
                    <label for="" class="form-label border">代替業務〔生産〕</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-3" type="checkbox" id="inlineCheckbox4_1" value="4_1" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox4_1">協力を依頼したい</label>
                </div>
                <div class="form-check form-check-inline d-flex align-items-center">
                    <input class="form-check-input ms-5" type="checkbox" id="inlineCheckbox4_2" value="4_2" v-model="selectWork">
                    <label class="form-check-label ms-3" for="inlineCheckbox4_2">要請に応えたい</label>
                </div>
            </div>
        </div>

        <div class="row ms-3 d-flex flex-row">
            <div class="p2 col-md-12 border border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3  mt-3 col-md-10 border border-start-0 border-top-0 border-end-0">
                    <label for="" class="mt-3 form-label">マッチングワード　　　※言葉の一部でヒットします。</label>
                </div>
            </div>
        </div>

        <div class="row ms-3 d-flex flex-row">
            <div class="p2 col-md-12 border border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-8 align-items-center">
                    <input type="text" class="mt-3 form-control border-white text-start" id="プラスチック射出成形機　Si-1300-6" placeholder="プラスチック射出成形機　Si-1300-6" @click="inputMatchingWord">
                </div>
                <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>設備の名称・型式・品番、{{ callReplaceWord('商品') }}の具体的な内容などを記載し、検索をヒットさせましょう。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
                <div class="p2 ms-5 col-md-3 d-flex align-items-center justify-content-start">
                    <button type="button" class="btn btn-lg btn-bnb-blue py-3 px-5" id="検索する" @click="clickSearchCorp" v-scroll-to="'#top'">
                        <label>
                            <i class="bi bi-search"></i>検索する
                        </label>
                    </button>
            </div>
        </div>
            
        <div class="p2 col-md-12 border border-dark border-top-0 d-flex align-items-center justify-content-start">
                <div class="ms-3 col-md-10 border border-start-0 border-top-0 border-end-0">
                    <label for="" class="form-label">&emsp;</label>
                </div>
            </div>
        </div>

        <div class="row ms-3 mt-5 d-flex flex-row">
            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
                <label for="" class="form-label">都道府県検索</label>
            </div>
        </div>
        <div class="row ms-3 p-5 border border-dark justify-content-center position-relative" id="canvas-map">
            <i id="search-japan"></i>
            <input type="button" class="btn-pref position-absolute " id="map_hokkaido" :value="btn_name('01')" :disabled="matching_cnt('01') === 0" @click="searchPref('01')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_aomori" :value="btn_name('02')" :disabled="matching_cnt('02') === 0" @click="searchPref('02')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_iwate" :value="btn_name('03')" :disabled="matching_cnt('03') === 0" @click="searchPref('03')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_miyagi" :value="btn_name('04')" :disabled="matching_cnt('04') === 0" @click="searchPref('04')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_akita" :value="btn_name('05')" :disabled="matching_cnt('05') === 0" @click="searchPref('05')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_yamagata" :value="btn_name('06')" :disabled="matching_cnt('06') === 0" @click="searchPref('06')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_fukushima" :value="btn_name('07')" :disabled="matching_cnt('07') === 0" @click="searchPref('07')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_ibaraki" :value="btn_name('08')" :disabled="matching_cnt('08') === 0" @click="searchPref('08')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_tochigi" :value="btn_name('09')" :disabled="matching_cnt('09') === 0" @click="searchPref('09')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_gunma" :value="btn_name('10')" :disabled="matching_cnt('10') === 0" @click="searchPref('10')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_saitama" :value="btn_name('11')" :disabled="matching_cnt('11') === 0" @click="searchPref('11')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_chiba" :value="btn_name('12')" :disabled="matching_cnt('12') === 0" @click="searchPref('12')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_tokyo" :value="btn_name('13')" :disabled="matching_cnt('13') === 0" @click="searchPref('13')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_kanagawa" :value="btn_name('14')" :disabled="matching_cnt('14') === 0" @click="searchPref('14')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_niigata" :value="btn_name('15')" :disabled="matching_cnt('15') === 0" @click="searchPref('15')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_toyama" :value="btn_name('16')" :disabled="matching_cnt('16') === 0" @click="searchPref('16')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_ishikawa" :value="btn_name('17')" :disabled="matching_cnt('17') === 0" @click="searchPref('17')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_fukui" :value="btn_name('18')" :disabled="matching_cnt('18') === 0" @click="searchPref('18')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_yamanashi" :value="btn_name('19')" :disabled="matching_cnt('19') === 0" @click="searchPref('19')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_nagano" :value="btn_name('20')" :disabled="matching_cnt('20') === 0" @click="searchPref('20')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_gifu" :value="btn_name('21')" :disabled="matching_cnt('21') === 0" @click="searchPref('21')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_shizuoka" :value="btn_name('22')" :disabled="matching_cnt('22') === 0" @click="searchPref('22')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_aichi" :value="btn_name('23')" :disabled="matching_cnt('23') === 0" @click="searchPref('23')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_mie" :value="btn_name('24')" :disabled="matching_cnt('24') === 0" @click="searchPref('24')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_shiga" :value="btn_name('25')" :disabled="matching_cnt('25') === 0" @click="searchPref('25')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_kyoto" :value="btn_name('26')" :disabled="matching_cnt('26') === 0" @click="searchPref('26')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_osaka" :value="btn_name('27')" :disabled="matching_cnt('27') === 0" @click="searchPref('27')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_hyogo" :value="btn_name('28')" :disabled="matching_cnt('28') === 0" @click="searchPref('28')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_nara" :value="btn_name('29')" :disabled="matching_cnt('29') === 0" @click="searchPref('29')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_wakayama" :value="btn_name('30')" :disabled="matching_cnt('30') === 0" @click="searchPref('30')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_tottori" :value="btn_name('31')" :disabled="matching_cnt('31') === 0" @click="searchPref('31')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_shimane" :value="btn_name('32')" :disabled="matching_cnt('32') === 0" @click="searchPref('32')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_okayama" :value="btn_name('33')" :disabled="matching_cnt('33') === 0" @click="searchPref('33')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_hiroshima" :value="btn_name('34')" :disabled="matching_cnt('34') === 0" @click="searchPref('34')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_yamaguchi" :value="btn_name('35')" :disabled="matching_cnt('35') === 0" @click="searchPref('35')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_tokushima" :value="btn_name('36')" :disabled="matching_cnt('36') === 0" @click="searchPref('36')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_kagawa" :value="btn_name('37')" :disabled="matching_cnt('37') === 0" @click="searchPref('37')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_ehime" :value="btn_name('38')" :disabled="matching_cnt('38') === 0" @click="searchPref('38')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_kochi" :value="btn_name('39')" :disabled="matching_cnt('39') === 0" @click="searchPref('39')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_fukuoka" :value="btn_name('40')" :disabled="matching_cnt('40') === 0" @click="searchPref('40')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_saga" :value="btn_name('41')" :disabled="matching_cnt('41') === 0" @click="searchPref('41')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_nagasaki" :value="btn_name('42')" :disabled="matching_cnt('42') === 0" @click="searchPref('42')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_kumamoto" :value="btn_name('43')" :disabled="matching_cnt('43') === 0" @click="searchPref('43')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_oita" :value="btn_name('44')" :disabled="matching_cnt('44') === 0" @click="searchPref('44')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_miyazaki" :value="btn_name('45')" :disabled="matching_cnt('45') === 0" @click="searchPref('45')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_kagoshima" :value="btn_name('46')" :disabled="matching_cnt('46') === 0" @click="searchPref('46')" v-scroll-to="'#top'" />
            <input type="button" class="btn-pref position-absolute " id="map_okinawa" :value="btn_name('47')" :disabled="matching_cnt('47') === 0" @click="searchPref('47')" v-scroll-to="'#top'" />
        </div>
            
        <div class="row ms-1 mt-5 d-flex flex-row">
            <div class="col-md">
                <div class="d-flex align-items-center justify-content-start flex-row">
                    <input type="button" class="col-md-5 text-center btn btn-back-home" value="B&B応援災害協定ビジネスマッチングHOMEへ戻る" @click="backHome">
                </div>
            </div>
        </div>
    </div>
    <div v-show="mode===2">
        <div id="top"></div>
        <div class="row">
            <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
                検索結果
            </div>
            <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backSearch">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="text">
            <label class="ms-4 mt-3">HOME{{ breadcrumb }}</label>
        </div>

        <div class="text">
        <label class="ms-4 mt-3" style="font-size:19pt">{{ total }}</label>
        <label class="ms-4 mt-3" style="font-size:10.5pt">件中、</label>
        <label class="ms-4 mt-3" style="font-size:19pt">{{ from }}～{{ to }}</label>
        <label class="ms-4 mt-3" style="font-size:10.5pt"> 件表示</label>
        </div>

        <div class="mt-3 ms-4" v-for="(corp, index) in corpResult" :key="corp.id">
            <div class="d-flex justify-content-start">
                <div class="select-column p-3">
                    <input type="checkbox" class="form-check-input" v-model="selectCorp" :value="corp.id" :id="'selectCorp_' + index" >
                    <label class="mt-3" :for="'selectCorp_' + index" >選択する</label>
                </div>
                <div class="flex-fill">
                    <!-- 従業員数の定義確認 -->
                    <corpInfoForBnB
                        :組織名="corp.組織名"
                        :郵便番号=corp.郵便番号
                        :住所="corp.住所"
                        :業種="corp.業種"
                        :業種名="corp.業種名"
                        :label従業員="corp.読替_従業員"
                        :従業員数="Number(corp.従業員数)"
                        :url="corp.url"
                        :img_src="corp.image"
                        :matching_list = "corp.matching_list"
                        :協定実績 = "Number(corp.協定実績)"
                        :BCP策定レベル = "corp.BCP策定レベル"
                        :BCP発動回数 = "Number(corp.BCP発動回数)"
                        :協定書保管数 = "Number(corp.協定書保管数)"
                        :TOTONOL歴 = "Number(corp.TOTONOL歴)"
                    />
                </div>
            </div>
        </div>

        <div class="row ms-3 mt-5">
            <div class="col">
                <ul class="pagination">
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(1)" aria-label="Top" v-scroll-to="'#top'">&laquo;</a></li>
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(current_page - 1)" aria-label="Previous" v-scroll-to="'#top'">&lt;</a></li>
                    <li v-for="page in pages" :key="page" :class="[{active: page === current_page}, 'page-item', 'mx-3']">
                        <a href="#" class="page-link" @click="change(page)" v-scroll-to="'#top'">{{page}}</a>
                    </li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(current_page + 1)" aria-label="Next" v-scroll-to="'#top'">&gt;</a></li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link mx-3" @click="change(last_page)" aria-label="Last" v-scroll-to="'#top'">&raquo;</a></li>
                </ul>
            </div>
        </div>
        
        <div class="row ms-3 mt-5">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-5 text-center btn btn-back-home" value="B&B応援災害協定ビジネスマッチングHOMEへ戻る" @click="backHome">
            </div>
        </div>

        <div class="row" id="floatingmenu">
            <div class="col d-flex justify-content-center align-items-baseline">
                <label class="h3 fw-bold">{{ selectCount }}</label>
                <label class="me-3">件を選択中</label>
                <button type="button" class="btn btn-blue py-2 px-3" @click="showMatching" :disabled="selectCount == 0" v-scroll-to="'#top'"><i id="matching_svg" class="mx-2"></i>マッチング交渉を行う</button>
            </div>
        </div>
    </div>
    <div v-show="mode === 3">
        <div class="row">
            <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
                マッチング交渉を行う
            </div>
            <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showConfirmMovePage">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="row mt-5">
            <div class="col-6">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td rowspan="2" class="border-gray select-step text-white text-center py-3">1</td>
                            <td rowspan="2" class="border-gray text-center align-middle">入力</td>
                            <td class="border-gray-bottom"></td>
                            <td rowspan="2" class="border-gray non-select text-white text-center py-3">2</td>
                            <td rowspan="2" class="border-gray text-center align-middle">確認</td>
                            <td class="border-gray-bottom"></td>
                            <td rowspan="2" class="border-gray non-select text-white text-center py-3">3</td>
                            <td rowspan="2" class="border-gray text-center align-middle">完了</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-3">
            <div class="d-flex align-items-baseline">
                <span class="font-s-half-up">{{ matchingIndex + 1 }}</span><span>件目</span>
            </div>
        </div>
        <div class="row mt-3">
            <div class="d-flex align-items-baseline">
                <span class="font-s-half-up font-blue">{{ matchingCorpName }}</span><span>へマッチング交渉を行う</span>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col">
                <span>メッセージ（交渉内容・確認事項など）</span><span class="badge bg-secondary">必須</span>
            </div>
        </div>
        <form @submit.prevent="confirmMatching">
            <div class="row mt-2">
                <textarea class="form-control" rows="10" v-model="contactMessage" required></textarea>
            </div>
            <div class="row mt-3">
                <span>メッセージ送信後の連絡方法</span>
            </div>
            <div class="row mt-3">
                <div class="form-check form-check-inline col-3 mx-5" v-for="contact in contact_list" :key="contact.code">
                    <input class="form-check-input" type="radio" :id="contact.code" :value="contact.code" v-model="selectContact">
                    <label class="form-check-label" :for="contact.code">{{ contact.name }}</label>
                </div>
            </div>
            <div class="row mt-3">
                <span>送り主［あなたの{{ callReplaceWord('組織') }}］</span>
            </div>
            <div class="row mt-3 mx-3">
                <label class="col-md-1 col-form-label">管理者</label>
                <label class="col-md-1 col-form-label">氏名</label>
                <div class="col-md-6">
                    <input type="text" class="form-control" readonly :value="corp_info.管理者名_姓 + ' ' + corp_info.管理者名_名">
                </div>
            </div>
            <div class="row mt-3 mx-3">
                <label class="col-md-1 offset-md-1 col-form-label">役職</label>
                <div class="col-md-6">
                    <input type="text" class="form-control" readonly :value="corp_info.管理者_役職 === 'なし' ? '' : corp_info.管理者_役職">
                </div>
            </div>
            <div class="row mt-3 mx-3">
                <label class="col-md-2 col-form-label">メールアドレス</label>
                <div class="col-md-6">
                    <input type="text" class="form-control" readonly :value="corp_info.管理者_メールアドレス">
                </div>
            </div>
            <div class="row mt-3 mx-3">
                <label class="col-md-2 col-form-label">電話番号</label>
                <div class="col-md-6">
                    <input type="text" class="form-control" readonly :value="corp_info.管理者_電話番号">
                </div>
            </div>
            <div class="row mt-3">
                <div>
                    <span>※ 送り主（管理者）を変更する場合は、</span><span class="font-pink border-bottom-pink">管理者専用＞会員情報変更</span>で行います。
                </div>
            </div>
            <div class="row mt-5">
                <div>
                    <input type="submit" class="btn btn-bnb-blue px-5" value="確認画面へ">
                </div>
            </div>
        </form>
    </div>
    <div v-show="mode === 4">
        <div class="row">
            <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
                マッチング交渉を行う
            </div>
            <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showMatchingPage" :disabled="isProcessing">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="row mt-5">
            <div class="col-6">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td rowspan="2" class="border-gray non-select text-white text-center py-3">1</td>
                            <td rowspan="2" class="border-gray text-center align-middle">入力</td>
                            <td class="border-gray-bottom"></td>
                            <td rowspan="2" class="border-gray select-step text-white text-center py-3">2</td>
                            <td rowspan="2" class="border-gray text-center align-middle">確認</td>
                            <td class="border-gray-bottom"></td>
                            <td rowspan="2" class="border-gray non-select text-white text-center py-3">3</td>
                            <td rowspan="2" class="border-gray text-center align-middle">完了</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-3">
            <div class="d-flex align-items-baseline">
                <span class="font-s-half-up">{{ matchingIndex + 1 }}</span><span>件目</span>
            </div>
        </div>
        <div class="row mt-3">
            <div class="d-flex align-items-baseline">
                <span class="font-s-half-up font-blue">{{ matchingCorpName }}</span><span>へ下記のメールを送信する</span>
            </div>
        </div>

        <div class="row mt-3">
            <textarea class="form-control" rows="30" readonly :value="confirmMessage"></textarea>
        </div>

        <div class="row mt-3">
            <span>※メッセージの控えは、管理者のメールアドレスに送信されます。</span>
        </div>

        <div class="row mt-3">
            <div>
                <input type="button" class="btn btn-bnb-blue px-5" value="上記内容で送信する" @click="sendMatchingMail" :disabled="isProcessing">
            </div>
        </div>

    </div>
    <div v-show="mode === 5">
        <div class="row">
            <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
                マッチング交渉を行う
            </div>
            <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showMatchingPage">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="row mt-5">
            <div class="col-6">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td rowspan="2" class="border-gray non-select text-white text-center py-3">1</td>
                            <td rowspan="2" class="border-gray text-center align-middle">入力</td>
                            <td class="border-gray-bottom"></td>
                            <td rowspan="2" class="border-gray non-select text-white text-center py-3">2</td>
                            <td rowspan="2" class="border-gray text-center align-middle">確認</td>
                            <td class="border-gray-bottom"></td>
                            <td rowspan="2" class="border-gray select-step text-white text-center py-3">3</td>
                            <td rowspan="2" class="border-gray text-center align-middle">完了</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-3">
            <span>マッチング交渉メッセージの送信が完了しました。</span>
        </div>
        <div class="row mt-2 mb-5">
            <span>（交渉No：{{ 交渉No }}）</span>
        </div>
        <div class="row mt-3">
            <div class="col-2">
                <div>
                    <input type="button" class="btn btn-bnb-blue px-5" :value="matchingIndex + 2 + '件目を送信する'" :disabled="selectCorp.length < (matchingIndex + 2)" @click="matchingNext" />
                </div>
            </div>
            <div class="col-2 ms-3">
                <div>
                    <input type="button" class="btn btn-bnb-blue px-5" value="マッチング先を探す" @click="returnSearch" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';
import corpInfoForBnB from '../common/CorpInfoForBnBComponent.vue'

export default {
    components: {
        levelIcon,
        corpInfoForBnB,
    },
    setup(props) {
        const mode = ref(1);

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const modalIndustrialVisible = ref(false);

        const closeIndustrialModal = () => {
            modalIndustrialVisible.value = false;
        }

        const openIndustrialModal = () => {
            modalIndustrialVisible.value = true;
        }

        const 業種_大分類 = ref('');
        const 業種_中分類 = ref('');
        const 業種_小分類 = ref('');
        const 業種_細分類 = ref('');

        const large_class_list = ref([]);
        const middle_class_list = ref([]);
        const minor_class_list = ref([]);
        const sub_class_list = ref([]);

        const getLargeClassList = () => {
            large_class_list.value = [];
            業種_大分類.value = '0';
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getLargeClassList';
            axios.get(url).then(res => {
                large_class_list.value = res.data;
            });
        }

        const changeLargeClass = () => {
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMiddleClassList?largeClass=' + 業種_大分類.value;

            axios.get(url).then(res => {
                middle_class_list.value = res.data;
            });
        }

        const changeMiddleClass = () => {
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMinorClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value;

            axios.get(url).then(res => {
                minor_class_list.value = res.data;
            });
        }

        const changeMinorClass = () => {
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getSubClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value + '&minorClass=' + 業種_小分類.value;

            axios.get(url).then(res => {
                sub_class_list.value = res.data;
            });
        }

        const select業種_大分類 = ref("");
        const select業種_中分類 = ref("");
        const select業種_小分類 = ref("");
        const select業種_細分類 = ref("");
        const select業種コード = ref("");
        const select業種名 = ref("指定なし");

        const select分類 = () => {
            if(業種_大分類.value === '0'){
                select業種コード.value = '';
                select業種名.value = '指定なし';
            }else if(業種_細分類.value === ''){
                const selected = minor_class_list.value.filter((item) => {
                    return item.小分類 === 業種_小分類.value;
                })
                select業種コード.value = 業種_小分類.value;
                select業種名.value = selected[0].項目名;
            }else{
                const selected = sub_class_list.value.filter((item) => {
                    return item.細分類 === 業種_細分類.value;
                })
                select業種コード.value = 業種_細分類.value;
                select業種名.value = selected[0].項目名;
            }

            select業種_大分類.value = 業種_大分類.value;
            select業種_中分類.value = 業種_中分類.value;
            select業種_小分類.value = 業種_小分類.value;
            select業種_細分類.value = 業種_細分類.value;

            closeIndustrialModal();
        }

        const modalRegionVisible = ref(false);

        const closeRegionModal = () => {
            modalRegionVisible.value = false;
        }

        const openRegionModal = () => {
            modalRegionVisible.value = true;
        }

        const selectPref = ref([]);
        const 北海道_東北地方 = ref(false);
        const 北海道東北list = ref([]);
        const 関東地方 = ref(false);
        const 関東list = ref([]);
        const 中部地方 = ref(false);
        const 中部list = ref([]);
        const 近畿地方 = ref(false);
        const 近畿list = ref([]);
        const 中国_四国地方 = ref(false);
        const 中国四国list = ref([]);
        const 九州_沖縄地方 = ref(false);
        const 九州沖縄list = ref([]);

        const setLevel = ref('011111');

        onMounted(() => {
            getLargeClassList();

            get地方list();
        });

        const get地方list = () => {
            get北海道東北list();
            get関東list();
            get中部list();
            get近畿list();
            get中国四国list();
            get九州沖縄list();
        }

        const get北海道東北list = () => {
            const regionRange = regionRanges['北海道・東北'];
            北海道東北list.value = props.pref_list.filter(pref => {
                const codeNum = parseInt(pref.code, 10);
                return codeNum >= parseInt(regionRange.from, 10) && codeNum <= parseInt(regionRange.to, 10);
            });
        }

        const get関東list = () => {
            const regionRange = regionRanges['関東'];
            関東list.value = props.pref_list.filter(pref => {
                const codeNum = parseInt(pref.code, 10);
                return codeNum >= parseInt(regionRange.from, 10) && codeNum <= parseInt(regionRange.to, 10);
            });
        }

        const get中部list = () => {
            const regionRange = regionRanges['中部'];
            中部list.value = props.pref_list.filter(pref => {
                const codeNum = parseInt(pref.code, 10);
                return codeNum >= parseInt(regionRange.from, 10) && codeNum <= parseInt(regionRange.to, 10);
            });
        }

        const get近畿list = () => {
            const regionRange = regionRanges['近畿'];
            近畿list.value = props.pref_list.filter(pref => {
                const codeNum = parseInt(pref.code, 10);
                return codeNum >= parseInt(regionRange.from, 10) && codeNum <= parseInt(regionRange.to, 10);
            });
        }

        const get中国四国list = () => {
            const regionRange = regionRanges['中国・四国'];
            中国四国list.value = props.pref_list.filter(pref => {
                const codeNum = parseInt(pref.code, 10);
                return codeNum >= parseInt(regionRange.from, 10) && codeNum <= parseInt(regionRange.to, 10);
            });
        }

        const get九州沖縄list = () => {
            const regionRange = regionRanges['九州・沖縄'];
            九州沖縄list.value = props.pref_list.filter(pref => {
                const codeNum = parseInt(pref.code, 10);
                return codeNum >= parseInt(regionRange.from, 10) && codeNum <= parseInt(regionRange.to, 10);
            });
        }

        const PrefSelect = (name) => {
            //つける
            for(let i = regionRanges[name].from; i <= regionRanges[name].to; i++) {
                if(!selectPref.value.includes(Number(i))){
                    selectPref.value.push(Number(i));
                }
            }
        }        

        const PrefErase = (name) => {
            //つける
            selectPref.value = selectPref.value.filter(item => item < regionRanges[name].from || item > regionRanges[name].to);
        }        

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backHome = () => {
            const url = '/bnb/home';

            location.href = url;
        }

        const showBnBTerms = () => {
            const url = '/bnb/2K';

            location.href = url;
        }

        const btn_name = (pref_code) => {
            const code = 91010000 + Number(pref_code);
            const prefName = props.pref_list.find(item => Number(item.code) === Number(code));
            const cnt = props.matching_cnt_prefecture.find(item => Number(item.自治体コード) === Number(pref_code));

            return prefName.name + '(' + (cnt ? cnt.CNT : 0) +')';
        }

        const matching_cnt = (pref_code) => {
            const cnt = props.matching_cnt_prefecture.find(item => Number(item.自治体コード) === Number(pref_code));
            return cnt ? cnt.CNT : 0;
        }

        const regionRanges = {
            '北海道・東北': { from: 91010001, to: 91010007 },
            '関東': { from: 91010008, to: 91010014 },
            '中部': { from: 91010015, to: 91010023 },
            '近畿': { from: 91010024, to: 91010030 },
            '中国・四国': { from: 91010031, to: 91010039 },
            '九州・沖縄': { from: 91010040, to: 91010047 },
        };

        const convertPrefCodeToName = () => {
            if(selectPref.value.length === 0 || selectPref.value.length === 47){
                return '指定なし';
            }
            const nameArray = selectPref.value.map(code => {
                const found = props.pref_list.find(item => Number(item.code) === Number(code));
                return found ? found.name : '';
            });
            return nameArray.join(' / ');
        }

        const corpResult = ref([]);

        const clickSearchCorp = () => {
            matchingIndex.value = 0;
            searchCorp(1);
        }

        const searchPref = (pref_code) => {
            select業種_大分類.value = '';
            select業種_中分類.value = '';
            select業種_小分類.value = '';
            select業種_細分類.value = '';
            select業種コード.value = '';
            select業種名.value = '';
            selectWork.value = '';
            inputMatchingWord.value = '';
            selectPref.value.splice(0);
            selectPref.value.push(pref_code);

            searchCorp(1);
        }

        const searchCorp = (page) => {
            const url = '/api/bnb/searchCorp'
            axios.post(url, {
                page: page,
                業種_大分類: select業種_大分類.value,
                業種_中分類: select業種_中分類.value,
                業種_小分類: select業種_小分類.value,
                業種_細分類: select業種_細分類.value,
                業種コード: select業種コード.value,
                業種名: select業種名.value,
                都道府県リスト: selectPref.value.map(item=>{
                    return ('00' + (item % 100)).slice(-2);
                }),
                selectWork: selectWork.value,
                inputMatchingWord: inputMatchingWord.value,
            }).then(res => {
                current_page.value = res.data.current_page;
                last_page.value = res.data.last_page;
                total.value = res.data.total;
                from.value = res.data.from;
                to.value = res.data.to;
                corpResult.value = res.data.data;
                mode.value = 2;
            });
        }

        const change = (page) => {
            searchCorp(page);
        }

        const current_page = ref(1);
        const last_page = ref(1);
        const total = ref(0);
        const from = ref(0);
        const to = ref(0);

        const pages = computed(() => {
            let start = Math.max(current_page.value - 2, 1);
            let end = Math.min(start + 5, last_page.value + 1);
            start = Math.max(end - 5, 1);
    
            // Array.from を使って範囲を生成する方法
            return Array.from({ length: end - start }, (_, index) => start + index);
        })

        const selectCorp = ref([]);

        const selectCount = computed(() => {
            return selectCorp.value.length;
        })

        const breadcrumb = computed(() => {
            let breadcrumb_word = "";
            if(select業種_大分類.value !== ''){
                const selected_large = large_class_list.value.filter((item) => {
                    return item.大分類 === select業種_大分類.value;
                });

                const selected_large_name = selected_large.map(function(item) {
                    return item.項目名;
                });

                breadcrumb_word = breadcrumb_word + "　＞　" + select業種_大分類.value + ' ' + selected_large_name;
            }

            if(select業種_中分類.value !== ''){
                const selected_middle = middle_class_list.value.filter((item) => {
                    return item.中分類 === select業種_中分類.value;
                });

                const selected_middle_name = selected_middle.map(function(item) {
                    return item.項目名;
                });

                breadcrumb_word = breadcrumb_word + "　＞　" + select業種_中分類.value + ' ' + selected_middle_name;
            }

            if(select業種_小分類.value !== ''){
                const selected_minor = minor_class_list.value.filter((item) => {
                    return item.小分類 === select業種_小分類.value;
                });

                const selected_minor_name = selected_minor.map(function(item) {
                    return item.項目名;
                });

                breadcrumb_word = breadcrumb_word + "　＞　" + select業種_小分類.value + ' ' + selected_minor_name;
            }

            if(select業種_細分類.value !== ''){
                const selected_sub = sub_class_list.value.filter((item) => {
                    return item.細分類 === select業種_細分類.value;
                });

                const selected_sub_name = selected_sub.map(function(item) {
                    return item.項目名;
                });

                breadcrumb_word = breadcrumb_word + "　＞　" + select業種_細分類.value + ' ' + selected_sub_name;
            }

            if(selectPref.value.length > 0){
                breadcrumb_word += '　＞　' + convertPrefCodeToName();
            }

            return breadcrumb_word;
        });

        const backSearch = () => {
            mode.value = 1;
        }

        const selectWork = ref([]);
        const inputMatchingWord = ref('');

        const matchingIndex = ref(0);
        const showMatching = () => {
            mode.value = 3;
            getCorpName();
        }

        const matchingCorpName = ref('');

        const getCorpName = () => {
            const url = '/api/getCorpName?corp_id=' + selectCorp.value[matchingIndex.value];

            axios.get(url).then(res => {
                matchingCorpName.value = res.data;
            });
        };

        const selectContact = ref(30220001);
        const contactMessage = ref('');

        const confirmMatching = () => {
            mode.value = 4;
        }

        const showMatchingPage = () => {
            mode.value = 3;
        }

        const confirmMessage = computed(() => {
            return "「B&B災害応援ビジネスマッチング」の交渉フォームにより、" 
                        + matchingCorpName.value + 
                        "へのメッセージを預かりましたので、下記の通りお知らせいたします。" +
                        "\n\n◇発信者（マッチング交渉を依頼されたTOTONO-L会員）\n" +
                        props.corp_info.組織名 + "　様\n\n" +
                        "◇発信者からの交渉メッセージ\n" +
                        contactMessage.value + "\n\n" +
                        "★このメッセージをお受け取りになられましたら、下記の方法により、" +
                        props.corp_info.組織名 + "　様へ直接ご連絡をお願いいたします。\n\n" +
                        "◇連絡方法\n" + 連絡方法名.value + "\n\n" +
                        "メールアドレス：" + props.corp_info.管理者_メールアドレス + "\n" +
                        "電話番号：" + props.corp_info.管理者_電話番号 + "\n\n" +
                        "◇ご担当者\n" + props.corp_info.組織名 + "\n" +
                        (props.corp_info.管理者_役職 === 'なし' ? '' : props.corp_info.管理者_役職 + "　") + props.corp_info.管理者名_姓 + ' ' + props.corp_info.管理者名_名 + " 様";
        });

        const 連絡方法名 = computed(() => {
            if(selectContact.value > 0){
                const wkArray = props.contact_list.filter(ele => ele.code === selectContact.value);
                return wkArray[0].name;
            }
            return "";
        });

        const isProcessing = ref(false);

        const sendMatchingMail = async() => {
            const url = '/api/bnb/registerMatchingNegotiation';

            isProcessing.value = true;

            await axios.post(url, {
                corp_id: props.corp_info.id,
                連絡方法: selectContact.value,
                組織名: props.corp_info.組織名,
                管理者名_姓: props.corp_info.管理者名_姓,
                管理者名_名: props.corp_info.管理者名_名,
                管理者_役職: props.corp_info.管理者_役職,
                管理者_メールアドレス: props.corp_info.管理者_メールアドレス,
                管理者_電話番号: props.corp_info.管理者_電話番号,
                メッセージ: contactMessage.value,
                negotiating_corp_id: selectCorp.value[matchingIndex.value],
            }).then(res => {
                if(res.status === 200){
                    交渉No.value = res.data.交渉No;
                    mode.value = 5;
                }else{

                }
            }).finally(fin => {
                contactMessage.value = "";
                selectContact.value = 30220001;
                isProcessing.value = false;
            });

        }

        const 交渉No = ref('');

        const matchingNext = () => {
            matchingIndex.value += 1;
            getCorpName();
            mode.value = 3;
        }

        const returnSearch = () => {
            matchingIndex.value = 0;
            selectCorp.value.splice(0);
            mode.value = 1;
        }

        const showConfirmMovePage = () => {
            modalConfirmVisible.value = true;
        }

        const modalConfirmVisible = ref(false);

        const closeConfirmModal = () => {
            modalConfirmVisible.value = false;
        }

        const openConfirmModal = () => {
            modalConfirmVisible.value = true;
        }

        const returnSelect = () => {
            selectCorp.value.splice(0);
            mode.value = 1;
            modalConfirmVisible.value = false;
        }

        return {
            mode,

            isProcessing,

            regionRanges,

            modalIndustrialVisible,
            closeIndustrialModal,
            openIndustrialModal,
            業種_大分類,
            業種_中分類,
            業種_小分類,
            業種_細分類,

            large_class_list,
            middle_class_list,
            minor_class_list,
            sub_class_list,
            getLargeClassList,
            changeLargeClass,
            changeMiddleClass,
            changeMinorClass,

            select分類,

            select業種_大分類,
            select業種_中分類,
            select業種_小分類,
            select業種_細分類,
            select業種コード,
            select業種名,

            modalRegionVisible,
            closeRegionModal,
            openRegionModal,

            selectPref,
            get地方list,
            北海道_東北地方,
            北海道東北list,
            get北海道東北list,
            関東地方,
            関東list,
            get関東list,
            中部地方,
            中部list,
            get中部list,
            近畿地方,
            近畿list,
            get近畿list,
            中国_四国地方,
            中国四国list,
            get中国四国list,
            九州_沖縄地方,
            九州沖縄list,
            get九州沖縄list,

            PrefSelect,
            PrefErase,

            setLevel,
            callReplaceWord,
            backHome,
            showBnBTerms,
            btn_name,
            matching_cnt,
            searchPref,

            convertPrefCodeToName,

            clickSearchCorp,
            searchCorp,
            change,
            corpResult,

            current_page,
            last_page,
            total,
            from,
            to,
            pages,

            selectCorp,
            selectCount,

            breadcrumb,

            backSearch,

            selectWork,
            inputMatchingWord,

            matchingIndex,
            showMatching,

            matchingCorpName,
            getCorpName,

            selectContact,
            contactMessage,

            confirmMatching,

            showMatchingPage,

            confirmMessage,
            連絡方法名,

            sendMatchingMail,

            交渉No,

            matchingNext,
            returnSearch,

            showConfirmMovePage,
            modalConfirmVisible,
            closeConfirmModal,
            openConfirmModal,
            returnSelect,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        industrial_tree: {
            type: Object,
        },
        region_list: {
            type: Object,
        },
        pref_list: {
            type: Object,
        },
        contact_list: {
            type: Object,
        },
        matching_cnt_prefecture: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.btn-search-condition {
    border: 1px solid;
    color: #404040;
    border-color: #d9d9d9;
    background-color: #d9d9d9;
}

.btn-search-condition:hover {
    border: 1px solid;
    color: #d9d9d9;
    border-color: #d9d9d9;
    background-color: #404040;
}

.btn-back-home {
    border: 1px solid;
    color: #1f4e78;
    border-color: #bdd7ee;
    background-color: #bdd7ee;
}

.btn-back-home:hover {
    border: 1px solid;
    color: #bdd7ee;
    border-color: #1f4e78;
    background-color: #1f4e78;
}

#search-japan {
    display: inline-block;
    width: 692px;
    height: 548px;
    background-image: url('../../../svg/16-21map.svg');
    background-size: 692px auto;
    background-repeat: no-repeat;
}

.bg-light-gray{
    background-color: #d9d9d9 !important;
}

.btn-pref {
    width: 120px;
    height:40px;
    background-color: #f2f2f2;
    color: #404040;
    border-color: #404040;
}

.btn-pref:hover {
    // width: 120px;
    // height:40px;
    background-color: #1f4e78;
    color: #f2f2f2;
    border-color: #404040;
}

.btn-pref:disabled {
    background-color: #f2f2f2;
    color: #404040;
    border-color: #404040;
}

#canvas-map {
    height: 900px;
}

#map_hokkaido {
    top: 30px;
    left: 1100px;
}

#map_aomori {
    top: 75px;
    left: 1100px;
}

#map_iwate {
    top: 120px;
    left: 1100px;
}

#map_miyagi {
    top: 165px;
    left: 1100px;
}

#map_akita {
    top: 210px;
    left: 1100px;
}

#map_yamagata {
    top: 255px;
    left: 1100px;
}

#map_fukushima {
    top: 300px;
    left: 1100px;
}

#map_ibaraki {
    top: 400px;
    left: 950px;
}

#map_tochigi {
    top: 445px;
    left: 950px;
}

#map_gunma {
    top: 490px;
    left: 950px;
}

#map_saitama {
    top: 535px;
    left: 950px;
}

#map_chiba {
    top: 400px;
    left: 1100px;
}

#map_tokyo {
    top: 445px;
    left: 1100px;
}

#map_kanagawa {
    top: 490px;
    left: 1100px;
}

#map_niigata {
    top: 110px;
    left: 550px;
}

#map_toyama {
    top: 155px;
    left: 550px;
}

#map_ishikawa {
    top: 200px;
    left: 550px;
}

#map_fukui {
    top: 245px;
    left: 550px;
}

#map_yamanashi {
    top: 290px;
    left: 550px;
}

#map_nagano {
    top: 110px;
    left: 400px;
}

#map_gifu {
    top: 155px;
    left: 400px;
}

#map_shizuoka {
    top: 200px;
    left: 400px;
}

#map_aichi {
    top: 245px;
    left: 400px;
}

#map_mie {
    top: 560px;
    left: 750px;
}

#map_shiga {
    top: 605px;
    left: 750px;
}

#map_kyoto {
    top: 605px;
    left: 600px;
}

#map_osaka {
    top: 560px;
    left: 600px;
}

#map_hyogo {
    top: 695px;
    left: 600px;
}

#map_nara {
    top: 650px;
    left: 600px;
}

#map_wakayama {
    top: 650px;
    left: 750px;
}

#map_tottori {
    top: 245px;
    left: 250px;
}

#map_shimane {
    top: 290px;
    left: 250px;
}

#map_okayama {
    top: 335px;
    left: 250px;
}

#map_hiroshima {
    top: 380px;
    left: 250px;
}

#map_yamaguchi {
    top: 245px;
    left: 100px;
}

#map_tokushima {
    top: 290px;
    left: 100px;
}

#map_kagawa {
    top: 335px;
    left: 100px;
}

#map_ehime {
    top: 380px;
    left: 100px;
}

#map_kochi {
    top: 425px;
    left: 100px;
}

#map_fukuoka {
    top: 650px;
    left: 300px;
}

#map_saga {
    top: 695px;
    left: 300px;
}

#map_nagasaki {
    top: 740px;
    left: 300px;
}

#map_kumamoto {
    top: 785px;
    left: 300px;
}

#map_oita {
    top: 650px;
    left: 150px;
}

#map_miyazaki {
    top: 695px;
    left: 150px;
}

#map_kagoshima {
    top: 740px;
    left: 150px;
}

#map_okinawa {
    top: 785px;
    left: 150px;
}

#industrial-modal, #region-modal, #confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#modal-content {
    z-index: 2;
    width: 50%;
    padding: 5em;
    background: #fff;
}

#confirm-content {
    z-index: 2;
    width: 30%;
    padding: 5em;
    background: #fff;
}

.gnav {
	display: flex;
	height: 1.5rem;
	margin: 0 auto;
    flex-direction: column; /* 1階層目のメニューを縦に配置 */
}
.gnav > li {/*親階層のみ幅を25%にする*/
	width: 25%;
}
/*全てのリスト・リンク共通*/
.gnav li {
	list-style: none;
	position: relative;
}
.gnav li a {
	// background: #fff;
	// border-right: 1px solid #000;
	// color: #000;
	// display: block;
	height: 2rem;
	line-height: 2rem;
	// text-align: center;
	// text-decoration: none;
	// width: 100%;

    background: #fff;
    border-bottom: 1px solid #000; /* メニューの区切り線 */
    color: #000;
    display: block;
    // padding: 0.5rem; /* メニューの上下のスペース */
    text-align: center;
    text-decoration: none;
    width: 100%;
}
/*子階層以降共通*/
.gnav li li {
	height: 0;
	overflow: hidden;
	transition: .5s;
    padding-left: 0px;
}
.gnav ul {
    padding-left: 0px;
}
.gnav li li a {
	border-top: 1px solid #eee;
}
.gnav li:hover > ul > li {
	height: 2rem;
	overflow: visible;
}
/*孫階層以降共通*/
.gnav li ul {
	left: 100%;
	position: absolute;
	top: 0;
	width: 100%;
    // padding-left: 0px;
}

/*aの背景色を指定*/   
.gnav > li:hover > a {/*親階層*/
    background: #2e75b5;
    color: white;
}
// .gnav li li a {/*子階層*/
//     background: #00305c;
// }
.gnav > li > ul > li:hover > a {
    background: #9cc2e5;
}
// .gnav li ul li ul li a {/*孫階層*/
//     background: #004789;
// }
.gnav > li > ul > li > ul > li:hover > a {
    background: #bdd6ee;
}
// .gnav li ul li ul li ul li a {/*ひ孫階層*/
//     background: #0065c1;
// }
.gnav > li > ul > li > ul > li > ul > li:hover > a {
    background: #deeaf6;
}

.gnav > li > ul > li > ul > li > ul > li > ul > li:hover > a {
    background: #1e7d00;
}

.gnav > li > ul:before{/*子階層*/
    border: 5px solid transparent;
    border-top: 5px solid #fff;
    content: "";
    right: 1rem;
    position: fixed;
    top: 1rem;
    transform: translateY(-40%);
}
.gnav li ul li ul:before {/*孫階層*/
    border: 5px solid transparent;
    border-left: 5px solid #fff;
    content: "";
    left: -20px;
    position: absolute;
    top: 1em;
    transform: translateY(-50%);
}
.gnav li:nth-child(5) ul li ul:before {/*一番右のメニューの孫階層*/
    border: 5px solid transparent;
    border-right: 5px solid #fff;
    left: auto;
    right: -20px;
}

.btn-back-home {
    border: 1px solid;
    color: #1f4e78;
    border-color: #bdd7ee;
    background-color: #bdd7ee;
}

.btn-back-home:hover {
    border: 1px solid;
    color: #bdd7ee;
    border-color: #1f4e78;
    background-color: #1f4e78;
}

#floatingmenu {
    display: block;
    width: 50%;
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 9999;
    text-align: center;
    padding: 10px 0px;
    background-color: #f2f2f2;
    opacity: 0.75;
}

#floatingmenu:hover {
    opacity: 1;
}

.btn-blue {
    border: 1px solid;
    color: white;
    border-color: #2f75b5;
    background-color: #2f75b5;
}

.btn-blue:hover {
    border: 1px solid;
    color: #2f75b5;
    border-color: #2f75b5;
    background-color: white;
}

.select-column {
    background-color: #1f4e78;
    color: white;
    writing-mode: vertical-rl;
}

#matching_svg {
    background-image: url('../../../svg/matching.svg');
    display: inline-flex;
    width: 14px;
    height: 22.8px;
    background-size: 14px auto;
    background-repeat: no-repeat;
}

.border-gray {
    border: thin solid #a5a5a5;
}

.border-gray-bottom {
    border-bottom: thin solid #a5a5a5;
}

.non-select {
    background-color: #bfbfbf;
}

.select-step {
    background-color: #1f4e78;
}

.font-blue {
    color: #2f75b5;
}

.font-pink {
    color: #ff6699;
}

.border-bottom-pink {
    border-bottom: thin solid #ff6699;
}
</style>