<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">3. 中核{{ callReplaceWord('事業') }}と復旧目標</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式08</span>
                </div>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 緊急事態発生後に中核事業を復旧させるための代替方針に関する情報を整理する。</label>
                </div>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">事業継続に係る各種資源の代替の情報</label>
                </div>
            </div>
            <div class="row mt-5 mx-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">③ 応援要員</label>
                </div>
            </div>
            <div class="for_small_monitor">
                <div class="row mt-3 mx-1">
                    <div class="p2 col-12 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">1. {{ callReplaceWord('事業') }}復旧のための要員</label>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-3 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">協力依頼想定者</label>
                    </div>
                    <div class="p2 col-4 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">第一電話番号、携帯電話番号等の連絡先</label>
                    </div>
                    <div class="p2 col-5 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">支援を依頼すべき業務内容等</label>
                    </div>
                </div>
                <template v-for="(item, index) in businessRecoveryStaffsInfo">
                    <div class="row mx-1">
                        <div class="p2 col-3 col-md-3 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== businessRecoveryStaffsInfo.length - 1 }">
                            <label class="bg-info form-label ">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.cooperatingOrderAssumptionPerson || '&emsp;' }}</label>
                        </div>
                        <div class="p2 col-4 col-md-4 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== businessRecoveryStaffsInfo.length - 1 }">
                            <label class="bg-info form-label ">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.phoneNumber || '&emsp;' }}</label>
                        </div>
                        <div class="p2 col-5 col-md-5 border bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== businessRecoveryStaffsInfo.length - 1 }">
                            <label class="bg-info form-label ">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.orderWorkContent || '&emsp;' }}</label>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md-3 border bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-9 bg-info border d-flex align-items-center justify-content-start">
                    <div class="bg-info form-control-border">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00059'].value || '&emsp;' }}</div>
                </div>
            </div>
            <div class="for_small_monitor">
                <div class="row mt-3 mx-1">
                    <div class="p2 col-12 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">2. 被災生活支援のための要員</label>
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="p2 col-3 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">協力依頼想定者</label>
                    </div>
                    <div class="p2 col-4 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">第一電話番号、携帯電話番号等の連絡先</label>
                    </div>
                    <div class="p2 col-5 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">支援を依頼すべき業務内容等</label>
                    </div>
                </div>
                <template v-for="(item, index) in livelihoodSupportStaffsInfo">
                    <div class="row mx-1">
                        <div class="p2 col-3 col-md-3 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== livelihoodSupportStaffsInfo.length - 1 }">
                            <label class="form-label bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.cooperatingOrderAssumptionPerson || '&emsp;' }}</label>
                        </div>
                        <div class="p2 col-4 col-md-4 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== livelihoodSupportStaffsInfo.length - 1 }">
                            <label class="form-label bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.phoneNumber || '&emsp;' }}</label>
                        </div>
                        <div class="p2 col-5 col-md-5 border bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== livelihoodSupportStaffsInfo.length - 1 }">
                            <label class="form-label bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.orderWorkContent || '&emsp;' }}</label>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md-3 border bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-9 bg-info border d-flex align-items-center justify-content-start">
                    <div class="bg-info form-control-border">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00061'].value || '&emsp;' }}</div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const isViewLimit = ref(false);
        const businessRecovery = '1';
        const livelihoodSupport = '2';

        const businessRecoveryStaffsInfo = reactive([]);
        const livelihoodSupportStaffsInfo = reactive([]);

        const valueForms = reactive({
            K00059: {
                value: '',
            },
            K00061: {
                value: '',
            },
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K4b';
            location.href = url;
        }

        const showNextForAdmin = () => {
            const url = '/browse/K4d';
            location.href = url;
        }

        const showNextForUser = () => {
            const url = '/browse/K8';
            location.href = url;
        }

        // 取得データをセット
        // 要員
        if (props.staffs_info.length > 0) {
            props.staffs_info.forEach((fetchData, index) => {
                if (index === 0) {
                    // 備考で使用するの閲覧制限
                    isViewLimit.value = fetchData.is_view_limit === 1;
                }

                if (fetchData.staff_type === businessRecovery) {
                    // 事業復旧のための要員データセット
                    businessRecoveryStaffsInfo.push({
                        staffType: businessRecovery,
                        isViewLimit: fetchData.is_view_limit === 1,
                        corpId: fetchData.corp_id,
                        cooperatingOrderAssumptionPerson: fetchData.cooperating_order_assumption_person,
                        phoneNumber: fetchData.phone_number,
                        orderWorkContent: fetchData.order_work_content,
                        email: fetchData.email,
                    });
                } else {
                    // 被災生活支援のための要員データセット
                    livelihoodSupportStaffsInfo.push({
                        staffType: livelihoodSupport,
                        isViewLimit: fetchData.is_view_limit === 1,
                        corpId: fetchData.corp_id,
                        cooperatingOrderAssumptionPerson: fetchData.cooperating_order_assumption_person,
                        phoneNumber: fetchData.phone_number,
                        orderWorkContent: fetchData.order_work_content,
                        email: fetchData.email,
                    });
                }
            });
        }

        if (props.bcp_values_info.length > 0) {
            Object.keys(valueForms).forEach(columnName => {
                valueForms[columnName].value = props.bcp_values_info.find(
                    (fetchData) => fetchData.column_name === columnName
                )?.value;
            });
        }

        return {
            setLevel,
            isMargin1,
            isViewLimit,
            businessRecoveryStaffsInfo,
            livelihoodSupportStaffsInfo,
            valueForms,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        staffs_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control-border {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 576px) {
    .for_small_monitor {
        min-width: 100%;
        font-size: 0.7em;
        // transform: translate(0, -200px) scale(0.7, 0.7);
    }

}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control-border {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-9 {
        width: 75%;
    }
}
</style>
