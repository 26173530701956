import dayjs from 'dayjs';

export default function(){
    const calculateYearsPassed = (targetDate) => {
        const now = dayjs();
        const target = dayjs(targetDate);

        return now.diff(target, 'year') + 1;
    }

    return {
        calculateYearsPassed,
    }
}