<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">お知らせ編集画面</div>
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>

            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-2">
                <i class="bi bi-record-circle col-form-label"></i><label class="ms-3 form-label">お知らせ更新</label>
            </div>
        </div>
        <form @submit.prevent="updateNotice">
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">日付</label>
                <div class="col-md-2">
                    <input type="date" class="form-control" v-model="お知らせ_開始日" required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">件名</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" v-model="お知らせ_件名" required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">内容</label>
                <div class="col-md-10">
                    <textarea class="form-control" v-model="お知らせ_内容" rows=5 required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <div class="col-md-3" v-for="notice in notice_kind" :key="notice.code" :class="{'is-invalid': has_notice_kind_Error}">
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" v-model="お知らせ_通知範囲" :id="notice.code" :value="notice.code" :class="{'is-invalid': has_notice_kind_Error}" />
                        <label :for="notice.code" class="form-check-label">{{ notice.name }}</label>
                    </div>
                </div>
                <div class="invalid-feedback">{{ notice_kind_エラーメッセージ }}</div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">表示終了日</label>
                <div class="col-md-2">
                    <input type="date" class="form-control" v-model="お知らせ_終了日" :class="{'is-invalid': has_notice_to_Error}" />
                    <div class="invalid-feedback">{{ notice_to_エラーメッセージ }}</div>
                </div>
            </div>
            <div class="row mt-3 pb-5">
                <div class="col-md-2 offset-md-2">
                    <input type="submit" class="btn btn-black px-3" value="更新" />
                </div>
                <div class="flash col-5 alert" :class="this.flashNoticeStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashNotice">
                    {{ flashNoticeMessage }}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        const dayjs = inject('dayjs');

        const back = () => {
            const url = '/s-admin/showNoticeList';

            location.href = url;
        }

        const updateNotice = () => {
            has_notice_kind_Error.value = false;
            notice_kind_エラーメッセージ.value = ''
            has_notice_to_Error.value = false;
            notice_to_エラーメッセージ.value = ''

            const url = '/api/s-admin/updateNotice';

            axios.post(url, {
                id: props.notice.id,
                notice_kind: お知らせ_通知範囲.value,
                notice_from: お知らせ_開始日.value,
                notice_to: お知らせ_終了日.value,
                title: お知らせ_件名.value,
                detail: お知らせ_内容.value,
            }).then(res => {
                showFlashNoticeMethod('正常に更新されました', 'success');

                back();
                
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.notice_kind){
                        has_notice_kind_Error.value = true;
                        notice_kind_エラーメッセージ.value = 'お知らせの範囲を選択してください。';
                    }
                    if(err.response.data.errors.notice_to){
                        has_notice_to_Error.value = true;
                        notice_to_エラーメッセージ.value = err.response.data.errors.notice_to[0];
                    }
                }else{
                    console.log(err.response.data);
                }
            });
        }

        const お知らせ_開始日 = ref('');
        const お知らせ_件名 = ref('');
        const お知らせ_内容 = ref('');
        const お知らせ_通知範囲 = ref('');
        const お知らせ_終了日 = ref('');

        const has_notice_kind_Error = ref(false);
        const notice_kind_エラーメッセージ = ref('');
        const has_notice_to_Error = ref(false);
        const notice_to_エラーメッセージ = ref('');

        onMounted(() => {
            お知らせ_開始日.value = dayjs(props.notice.notice_from).format('YYYY-MM-DD');
            お知らせ_件名.value = props.notice.title;
            お知らせ_内容.value = props.notice.detail;
            お知らせ_終了日.value = dayjs(props.notice.notice_to).format('YYYY-MM-DD');
            お知らせ_通知範囲.value = props.notice.notice_kind;
        });

        const flashNoticeMessage = ref('');
        const showFlashNotice = ref(false);
        const flashNoticeStyle = ref('');

        const showFlashNoticeMethod = (msg, kind) => {
            showFlashNotice.value = true;
            flashNoticeMessage.value = msg;
            flashNoticeStyle.value = kind;
            setTimeout(() => {
                showFlashNotice.value = false
            }, 3000)
        }

        return {
            back,
            updateNotice,

            お知らせ_開始日,
            お知らせ_件名,
            お知らせ_内容,
            お知らせ_通知範囲,
            お知らせ_終了日,

            has_notice_kind_Error,
            notice_kind_エラーメッセージ,
            has_notice_to_Error,
            notice_to_エラーメッセージ,

            flashNoticeMessage,
            showFlashNotice,
            flashNoticeStyle,

            showFlashNoticeMethod,

        }
        
    },
    props: {
        notice: {
            type: Object,
        },
        notice_kind: {
            type: Array,
        }
    }

}
</script>

<style lang="scss" scoped>
    .v-middle{
        vertical-align: middle;
    }

    .t-center {
        text-align: center;
    }

</style>