<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式09</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 事業継続の能力を高めるための施設や設備の整備に関する投資計画として以下のものを立案する。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">事前対策のための投資計画</label>
                </div>
            </div>
            <div class="d-none d-md-block d-print-block pb-5">
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">対策項目</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">現状の対策レベル</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">対策後のレベル</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">必要資金（円）</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">資金調達方法</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">実施年（予定）</label>
                    </div>
                </div>
                <div class="row ms-1" v-for="(item, index) in investmentPlansInfo" :key="item.id">
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== investmentPlansInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.measuresItems || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== investmentPlansInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.currentMeasuresLevel || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== investmentPlansInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.afterMeasuresLevel || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== investmentPlansInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.requiredFund == null ? '&emsp;' : item.requiredFund }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== investmentPlansInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.financingMethod || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== investmentPlansInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.planImplementationYear == null ? '&emsp;' : item.planImplementationYear }}</div>
                    </div>
                </div>
            </div>
            <div class="d-md-none d-print-none pb-5">
                <div class="row" v-for="(item, index) in investmentPlansInfo" :key="item.id">
                    <span class="col-form-label">{{ (index + 1) + "." }}</span>
                    <div class="col-12 bg-info border">
                        <span class="col-form-label">対策項目</span>
                    </div>
                    <div class="col-12 border">
                        <span class="col-form-label">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.measuresItems || '&emsp;' }}</span>
                    </div>
                    <div class="col-12 bg-info border">
                        <span class="col-form-label">現状の対策レベル</span>
                    </div>
                    <div class="col-12 border">
                        <span class="col-form-label">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.currentMeasuresLevel || '&emsp;' }}</span>
                    </div>
                    <div class="col-12 bg-info border">
                        <span class="col-form-label">対策後のレベル</span>
                    </div>
                    <div class="col-12 border">
                        <span class="col-form-label">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.afterMeasuresLevel || '&emsp;' }}</span>
                    </div>
                    <div class="col-12 bg-info border">
                        <span class="col-form-label">必要資金（円）</span>
                    </div>
                    <div class="col-12 border">
                        <span class="col-form-label">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.requiredFund == null ? '&emsp;' : item.requiredFund }}</span>
                    </div>
                    <div class="col-12 bg-info border">
                        <span class="col-form-label">資金調達方法</span>
                    </div>
                    <div class="col-12 border">
                        <span class="col-form-label">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.financingMethod || '&emsp;' }}</span>
                    </div>
                    <div class="col-12 bg-info border">
                        <span class="col-form-label">実施年（予定）</span>
                    </div>
                    <div class="col-12 border">
                        <span class="col-form-label">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.planImplementationYear == null ? '&emsp;' : item.planImplementationYear }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let investmentPlansInfo = reactive([]);

        const showBack = () => {
            const url = '/browse/K6';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K8';
            location.href = url;
        }

        // 取得データをセット
        if (props.bcp_investment_plans_info.length > 0) {
            investmentPlansInfo = props.bcp_investment_plans_info.map(
                (fetchData) => ({
                    id: fetchData.id,
                    isViewLimit: fetchData.is_view_limit,
                    measuresItems: fetchData.measures_items,
                    currentMeasuresLevel: fetchData.current_measures_level,
                    afterMeasuresLevel: fetchData.after_measures_level,
                    requiredFund: fetchData.required_fund,
                    financingMethod: fetchData.financing_method,
                    planImplementationYear: fetchData.plan_implementation_year,
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            investmentPlansInfo,
            showBack,
            showNext,
        }
    },
    props:{
        bcp_investment_plans_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-100 {
    width: 100%;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
