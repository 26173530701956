<template>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 col-md-6 heading-orange mt-3">
                教育実施記録の閲覧
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end me-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>


    <div class="mt-3">
        <div class="row mx-1 mx-md-3">
            <div class="col-2 border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                実施日
            </div>
            <div class="col border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                教育計画のテーマ・目的
            </div>
            <div class="col border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                教育の方法
            </div>
            <div class="col-2 border border-dark border-bottom-0 bg-light-gray text-dark d-flex align-items-center py-2">
                責任者
            </div>
        </div>
        <div class="row mx-1 mx-md-3">
            <div class="col-2 border border-dark border-bottom-0 border-end-0 text-dark d-flex align-items-center py-2 fs-half">
                {{ this.$root.dayjs(implementation_record_info.implementation_date).format('YYYY/MM/DD') }}
            </div>
            <div class="col border border-dark border-bottom-0 border-end-0 text-dark d-flex align-items-center py-2">
                {{ implementation_record_info.theme }}
            </div>
            <div class="col border border-dark border-bottom-0 border-end-0 text-dark d-flex align-items-center py-2">
                {{ calcEducationMethodName(implementation_record_info.education_method, implementation_record_info.education_method_other) }}
            </div>
            <div class="col-2 border border-dark border-bottom-0 text-dark d-flex align-items-center py-2">
                {{ implementation_record_info.manager }}
            </div>
        </div>
        <div class="row mx-1 mx-md-3">
            <div class="col border border-dark bg-light-gray text-dark d-flex align-items-center py-2">
                訓練参加者
            </div>
        </div>
        <div class="row mx-1 mx-md-3">
            <template v-for="participant, index in participants_list" :key="index">
                <div class="col-2 border border-dark border-top-0 bg-white text-dark d-flex align-items-center py-2"
                    :class="{'border-end-0 ': (index + 1) % 6 && index + 1  !== participants_list.length,
                    'border-bottom-0': index >= (participants_list.length - ((participants_list.length) % 6)) && index < participants_list.length }"
                >
                    {{ participant.participant }}
                </div>
            </template>
        </div>

        <div class="row mx-1 mx-md-3">
            <div class="col border border-dark bg-light-gray text-dark d-flex align-items-center py-2" :class="{'border-top-0': (participants_list.length) % 6 === 0}">
                教育終了後に気づいた課題・BCP見直しのポイントなど（自由記述）
            </div>
        </div>
        <div class="row mx-1 mx-md-3">
            <div class="col border border-dark border-top-0 py-2">
                <textarea type="text" class="form-control"  style="height: 200px;" placeholder="" v-model="input_free_description" :readonly="!is('administrator')"></textarea>
            </div>
        </div>
    </div>

    <div class="row mx-3 mt-5">
        <div class="d-flex">
            <input type="button" class="btn btn-gray me-auto" value="文化定着［訓練＆教育］TOP へ戻る" @click="showTop" :disabled="isProcessing">
            <input type="button" class="btn btn-black me-3 px-4" value="修正" @click="updateDetail" :disabled="isProcessing || !is('administrator')">
            <input type="button" class="btn btn-delete px-4" value="削除" @click="deleteDetail" :disabled="isProcessing || !is('administrator')">
        </div>
        <div class="d-flex justify-content-end">
            <div class=" col-md-3 mx-3 flash alert mt-2" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
        </div>
    </div>

</template>

<script>
import { inject, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject("dayjs");

        onMounted(() => {
            input_free_description.value = props.implementation_record_info.free_description;
        });

        const isProcessing = ref(false);
        const input_free_description = ref("");

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            // 前のページへ遷移
            history.back();
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        // 教育の方法
        const educationMethodList = [
            {id: 1, methodName: '社内ディスカッション（BCPの周知・防災）'},
            {id: 2, methodName: '防災に関する勉強会への参加'},
            {id: 3, methodName: '応急救護の受講（心肺蘇生法等）'},
            {id: 4, methodName: 'BCPや防災対策関連のセミナーへの参加'},
            {id: 5, methodName: 'その他'},
        ]

        const calcEducationMethodName = (code, other) => {
            const findEducation = educationMethodList.find(item => {
                return item.id == code;
            });

            if(findEducation){
                if(findEducation.methodName === 'その他'){
                    return findEducation.methodName + '：' + other;
                }
                return findEducation.methodName;
            }else{
                return "";
            }
        }

        const updateDetail = () => {
            const url = "/api/fixation/updateEducationFreeDescription";

            isProcessing.value = true;

            axios.post(url, {
                id: props.implementation_record_info.id,
                free_description: input_free_description.value,
            }).then(res=>{
                visibleFlashMessage('正常に更新されました', 'success');

            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const deleteDetail = () => {
            const url = "/api/fixation/deleteEducationDetail";

            const message = "実施日" + dayjs(props.implementation_record_info.implementation_date).format('YYYY/MM/DD') + "の教育実施記録を削除しますか？";
            if(!confirm(message)){
                return;
            }

            isProcessing.value = true;

            axios.delete(url, {data: {id: props.implementation_record_info.id}})
            .then(res=>{
                const back_url = "/fixation/k4";
                location.href = back_url;
            }).finally(fin => {
                isProcessing.value = false;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,
            showTop,
            educationMethodList,
            calcEducationMethodName,

            isProcessing,
            input_free_description,
            updateDetail,
            deleteDetail,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        implementation_record_info: {
            type: Object,
        },
        participants_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

#training_implementation_table {
    margin-bottom: 0px !important;
}

#training_implementation_table thead tr{
    background-color: #d8d8d8;
}

#training_implementation_table thead tr td,
#training_implementation_table tbody tr td{
    border: 1px solid common_colors.colors(resilience-gray);
}

.btn-resilience-light-blue {
    background-color: common_colors.colors(resilience-light-blue);
    color: white;
    border: 1px solid common_colors.colors(resilience-light-blue);
}

.btn-resilience-light-blue:hover {
    background-color: white;
    color: common_colors.colors(resilience-light-blue);
    border: 1px solid common_colors.colors(resilience-light-blue);
}

.border-title {
    border: thin solid #7f7f7f;
}

.bg-title {
    background-color: #d8d8d8;;
}

.disaster-image {
    width: 120px;
}

.other-image {
    // width: 50%;
    width: 400px;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
    height: 39.5px;
}
.bg-blue {
    background-color: #4472c4;
    color: white;
    height: 39.5px;
}

.bg-orange {
  background-color: #FF6600;
  height: 39.5px;
}

.bg-header {
    background-color: #F2F2F2;
    height: 39.5px;
}

@media screen and (max-width: 756px){

    .fs-half {
        font-size: 50%;
    }
}
</style>
