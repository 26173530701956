<template>
    <div v-for="mainSupplier, index in supplierList" :key="index">
        <div class="mt-3">
            <div class="row mx-3 border border-dark border-bottom-0 bg-light-gray">
                <div class="p2 ms-4 col-md-1 d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                    &emsp;
                </div>
                <!-- <div class="p2 mt-2 mb-0 col-md-3 text-dark d-flex align-items-center justify-content-start py-2">
                    提供する製品／材料／サービス
                </div> -->
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 ms-4 col-md-1 border border-dark bg-light-gray text-dark d-flex align-items-center justify-content-center" style="font-size:12pt">
                    {{ index + 1 }}
                </div>
                <div class="p2 col-md-9 align-items-center justify-content-start py-2">
                    <div class="ms-2 mb-2">
                        提供する製品／材料／サービス
                    </div>
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="supplies.供給品目">
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    平時における製品等の納入手段（輸送手段等）
                </div>
            </div>
            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-10 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.method">
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                    TOTONO-L 会員ID
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control bg-resilience-light-blue d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.corp_id">
                </div>
                <button type="button" class="p2 mx-3 col-md-1 btn btn-black my-2" @click="getMemberInfo(index, mainSupplier.corp_id)">
                    検　索
                </button>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                    ※入力すると主要顧客の情報が自動表示されます。
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    法人名／屋号
                </div>
                <div class="p2 mx-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    口座番号（必要な場合）
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.corporate_name">
                </div>
                <div class="p2 mx-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.bank_account_number">
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    都道府県
                </div>
                <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    市区町村
                </div>
                <div class="p2 mx-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    町名番地、ビル名等
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.prefecture">
                </div>
                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.city">
                </div>
                <div class="p2 mx-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.other_address">
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    ホームページアドレス
                </div>
                <div class="p2 mx-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    電話番号（代表）
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.url">
                </div>
                <div class="p2 mx-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.represent_phone_number">
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="mx-3 mt-3 col-md-2 bg-light-gray text-dark ">
                    業者との取引状況
                </div>
                <template v-for="current, currentIndex in currentStatusList" :key="current.statusId">
                    <div class="mt-3 col-md-3 d-flex align-items-center custom-control custom-radio custom-control-inline">
                        <input type="checkbox" :id="'customRadioInline1' + index + current.statusId" :name="'customRadioInline1' + index"
                            class="custom-control-input" :class="{'ms-3': current.statusId >= 1}"
                            :value="mainSupplier.trading_status[currentIndex]" v-model="mainSupplier.trading_status[currentIndex]"
                        >
                        <label class="custom-control-label" :for="'customRadioInline1' + index + current.statusId">&nbsp;{{ current.status }}</label>
                    </div>
                </template>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="ms-4 mt-5 col-md-1 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                    第<br>
                    1<br>
                    連<br>
                    絡<br>
                    先<br>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="p2 mx-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            部署
                        </div>
                        <div class="p2 mx-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            担当者
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.first_contact_division">
                                </div>
                                <div class="p2 mx-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.first_contact_person">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            電話番号
                        </div>
                        <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            携帯電話等
                        </div>
                        <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            FAX番号
                        </div>
                        <div class="p2 mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            E-mail
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.first_contact_phone_number">
                                </div>
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.first_contact_mobile_phone_number">
                                </div>
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.first_contact_fax">
                                </div>
                                <div class="p2 mx-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.first_contact_email">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="ms-4 mt-5 col-md-1 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                    第<br>
                    2<br>
                    連<br>
                    絡<br>
                    先<br>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="p2 mx-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            部署
                        </div>
                        <div class="p2 mx-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            担当者
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.second_contact_division">
                                </div>
                                <div class="p2 mx-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.second_contact_person">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            電話番号
                        </div>
                        <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            携帯電話等
                        </div>
                        <div class="p2 mx-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            FAX番号
                        </div>
                        <div class="p2 mx-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            E-mail
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.second_contact_phone_number">
                                </div>
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.second_contact_mobile_phone_number">
                                </div>
                                <div class="p2 mx-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.second_contact_fax">
                                </div>
                                <div class="p2 mx-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                                    <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainSupplier.second_contact_email">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 mt-5 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                    備考
                </div>
            </div>
            <div class="row mx-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="p2 mx-3 col-md-11 d-flex align-items-center justify-content-start py-2">
                    <textarea class="form-control" placeholder="" v-model="mainSupplier.note" />
                </div>
            </div>

            <div class="row mx-3">
                <div class="p2 col-md-12 border border-dark border-top-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                    &emsp;
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    emits: ['getMemberInfo'],
    setup(props, { emit }) {
        onMounted(() => {
            supplierList.value = props.supplierList;
            supplies = props.supplies;
        });
        let supplies = ref(0);
        let supplierList = ref([]);

        const currentStatusList = [
            {statusId: 0, status: '現在取引中の供給者／業者'},
            {statusId: 1, status: '予備の供給者／業者'},
        ]

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const getMemberInfo = (index, searchId) => {
            emit('getMemberInfo', index, searchId);
        }

        return {
            supplies,
            supplierList,
            currentStatusList,
            callReplaceWord,
            getMemberInfo,
        }
    },
    props:{
        supplierList: {
            type: Array,
        },
        supplies: {
            type: Object,
        }
    }

}
</script>

<style lang="scss" scoped>
    .border-black {
        border-color: #808080;
    }

    table thead tr th {
        background-color: #d9d9d9;
    }

    table tbody tr td {
        background-color: #f2f2f2;
    }

    table thead tr th:not(:last-child),
    table tbody tr td:not(:last-child)
    {
        border: 1px solid #808080
    }

    table thead tr th:last-child,
    table tbody tr td:last-child {
        background-color: transparent !important;
    }
</style>
