<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            経営者による自己診断
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-1 mt-3 d-flex flex-row">
        <div class="col-md-2 align-items-center justify-content-center">
            BCP策定・運用状況の診断
        </div>
        <div class="col-md-2 btn-bcp-middle d-flex align-items-center justify-content-center">
            中級コース
        </div>
        <div class="col-md-2 ms-3 btn-bcp-advanced d-flex align-items-center justify-content-center">
            上級コース
        </div>
        <div class="col-md-3 ms-5">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>策定した現状のBCPを{{ callReplaceWord('経営者') }}自ら評価し、見直すべき改善点を洗い出します。また担当者により事業継続計画（BCP）の整備状況の有効性を評価します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-1 mt-3 d-flex flex-row">
        <div class="col-md-10">
            策定したBCPおよびBCP運用状況の診断チェックを行ってください。以下の設問に、はい／いいえ／不明でお答えください。
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="col-md-11">
            <table class="ms-1 mt-3 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-gray text-center" colspan="2">優先度</td>
                        <td class="bg-gray text-center" rowspan="2">診断項目</td>
                        <td class="bg-gray text-center" colspan="3" rowspan="2">経営者のチェック</td>
                    </tr>
                    <tr>
                        <td class="bg-gray text-center">必須</td>
                        <td class="bg-gray text-center">推奨</td>
                    </tr>
                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">1.　{{ callReplaceWord('事業') }}継続基本方針の立案</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index < 3">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline3' + index"></label>
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">2.　BCPサイクルの運用体制確立</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 3 && index < 7">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline3' + index"></label>
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>

            <div class="row ms-1 mt-3 d-flex flex-row">
                ①「事業を理解する」
            </div>
            <table class="ms-1 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray">1.　{{ callReplaceWord('事業') }}への影響度を評価する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 7 && index < 12">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline3' + index"></label>
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">2.　中核{{ callReplaceWord('事業') }}が受ける被害を評価する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 12 && index < 15">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline3' + index"></label>
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">3.　財務状況を診断する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 15 && index < 21">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <label class="custom-control-label" :for="'customRadioInline3' + index"></label>
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>

            <div class="row ms-1 mt-3 d-flex flex-row">
                ②「BCPの準備、事前対策を検討する」
            </div>
            <table class="ms-1 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray">1.　{{ callReplaceWord('事業') }}継続のための代替策の特定と選択をする</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 21 && index < 27">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">2.　事前対策を検討・実施する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 27 && index < 30">
                            <td class="text-center">
                                <template v-if="index !== 28">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index === 28">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>

            <div class="row ms-1 mt-3 d-flex flex-row">
                ③「BCPを作成する」
            </div>
            <table class="ms-1 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray">1.　BCP発動基準を明確にする</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 30 && index < 35">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">2.　BCP発動時の体制を明確にする</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 35 && index < 42">
                            <td class="text-center">
                                <template v-if="index !== 38 && index !== 39">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index === 38 || index === 39">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">3.　事業継続に関連する情報の整理と文書化をする</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 42 && index < 47">
                            <td class="text-center">
                                〇
                            </td>
                            <td class="text-center">
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>

            <div class="row ms-1 mt-3 d-flex flex-row">
                ④「BCP文化を定着させる」
            </div>
            <table class="ms-1 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray">1.　従業員へのBCP教育を実施する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 47 && index < 49">
                            <td class="text-center">
                                <template v-if="index === 48">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index === 47">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">2.　BCP訓練を実施する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 49 && index < 54">
                            <td class="text-center">
                                <template v-if="index === 51 || index === 53">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index !== 51 && index !== 53">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">3.　BCP文化を醸成する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 54 && index < 61">
                            <td class="text-center">
                                <template v-if="index === 54 || index === 55 || index === 57">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index !== 54 && index !== 55 && index !== 57">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>

            <div class="row ms-1 mt-3 d-flex flex-row">
                ⑤「BCPの診断、維持・更新を行う」
            </div>
            <table class="ms-1 table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray text-center" style="width:54px"></td>
                        <td class="bg-light-gray">1.　BCPの診断・チェックを行う</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 61 && index < 64">
                            <td class="text-center">
                                <template v-if="index === 63">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index === 61 || index === 62">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray text-center"></td>
                        <td class="bg-light-gray">2.　BCP文化を醸成する</td>
                        <td class="bg-light-gray text-center">はい</td>
                        <td class="bg-light-gray text-center">いいえ</td>
                        <td class="bg-light-gray text-center">不明</td>
                    </tr>
                    <tr v-for="item, index in checkItems" :key="index">
                        <template v-if="index >= 64 && index < 72">
                            <td class="text-center">
                                <template v-if="index < 71">〇</template>
                            </td>
                            <td class="text-center">
                                <template v-if="index === 71">〇</template>
                            </td>
                            <td class="">{{ diagnosisItems[index] }}</td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" value="1" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline1' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline2' + index" :name="'customRadioInline2' + index" class="custom-control-input" value="2" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline2' + index">&nbsp;</label> -->
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="form-check form-check-inline">
                                    <input type="radio" :id="'customRadioInline3' + index" :name="'customRadioInline3' + index" class="custom-control-input" value="9" v-model="item.checkType">
                                    <!-- <label class="custom-control-label" :for="'customRadioInline3' + index"></label> -->
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-md-1">
            <div class="card border-0 bg-transparent">
                <div class="card-body">
                    <div class="d-flex flex-row">
                        <VDropdown
                            theme="browsing_restrictions"
                        >
                            <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                            <template #popper>
                                <div class="d-flex justify-content-start">
                                    <label>閲覧制限</label>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                        <label class="form-check-label" for="checkbox_1">
                                            あり
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-new-window-button btn-sm" value="診断結果を見る" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="ms-3 btn btn-new-window-button btn-sm" value="初期値に戻る" @click="backDefaultValue">
        </div>
    </div>

</template>

<script>
import { ref, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.self_diagnoses_info.length > 0) {
                props.self_diagnoses_info.forEach(fetchData => {
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        viewLimit.value = fetchData.check_type == 1 ? true : false;
                    }
                    checkItems.value.forEach(initData => {
                        if (initData.columnName === fetchData.column_name) {
                            initData.checkType = fetchData.check_type;
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const viewLimit = ref(false);
        const middleAdvanceCourse = '2';
        const diagnosisClassJ = 'j';

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const diagnosisItems = [
            callReplaceWord('経営者') + 'が関与して規定された事業継続の基本方針がありますか？',
            '上記の' + callReplaceWord('事業') + '継続の基本方針について、すべての' + callReplaceWord('従業員') + 'に内容が周知されるための仕組みがありますか？',
            '上記の事業継続の基本方針について、' + callReplaceWord('取引先') + 'や協力' + callReplaceWord('組織') + 'などに内容が公開されていますか？',
            '平時においてBCPサイクルの運用を推進する' + callReplaceWord('社内') + '体制が確立されていますか？',
            '上記の' + callReplaceWord('社内') + '体制は、' + callReplaceWord('経営者') + '自らが率先してBCPの策定・運用にあたるものになっていますか？',
            'BCPサイクル運用体制について、当該メンバー以外の' + callReplaceWord('従業員') + 'も、その存在を明確に認識していますか？',
            'BCPサイクルの運用体制の状況を確認し評価する機能はありますか？',
            '事業影響度評価を通して、あなたの' + callReplaceWord('組織') + 'における中核' + callReplaceWord('事業') + 'を明確に認識しましたか？',
            '上記の中核事業に不可欠な、あなたの会社の重要業務を明確に認識しましたか？',
            '上記の中核事業を継続するにあたっての障害となるボトルネック資源を可能な限り漏れなく把握できていますか？',
            '上記の中核事業を継続するにあたっての障害となるボトルネック資源に順位付けはされていますか？',
            '上記の中核' + callReplaceWord('事業') + 'について、' + callReplaceWord('取引先') + 'やサプライチェーンの要請を考慮して目標復旧時間を設定しましたか？',
            '自然災害、人的災害を含めて、中核' + callReplaceWord('事業') + 'が影響を受ける災害を明確に認識しましたか？',
            '上記の各災害が中核事業の各ボトルネックに与える影響について、可能な限り漏れなく評価しましたか？',
            '中核' + callReplaceWord('事業') + 'が影響を受ける災害について、影響度の順位付けはされていますか？',
            '「財務診断モデル」を利用して、あなたの' + callReplaceWord('組織') + 'の被災後のキャッシュフローを把握しましたか？',
            '財務診断の結果にもとづき、政府系中小企業金融機関などの災害復旧貸付制度の利用を検討しましたか？',
            '財務診断の結果にもとづき、1ヶ月程度の操業停止に耐え得るだけの資金確保の必要性を検討しましたか？',
            '財務診断の結果にもとづき、損害保険の追加加入の必要性を検討しましたか？',
            '財務診断の結果にもとづき、目標復旧時間を財務状況面から再検討しましたか？',
            '最新の事業影響度評価、被害評価及び財務診断は、それぞれ過去12ヶ月以内に実施されたものですか？',
            '緊急時における情報連絡の拠点場所の確保について検討しましたか？',
            '被災したあなたの' + callReplaceWord('組織') + 'の重要施設・設備の代替確保について検討しましたか？',
            '臨時' + callReplaceWord('従業員') + 'の確保について検討しましたか？',
            '資金調達の方針について検討しましたか？',
            '中核' + callReplaceWord('事業') + 'の復旧・継続に必要となる重要情報のバックアップ方針について検討しましたか？',
            '通信手段や電力などのインフラに関する二重化対策やバックアップの必要性を検討しましたか？',
            '中核' + callReplaceWord('事業') + 'への影響が高いと思われる災害と、それにより影響を受けるボトルネック資源の両面から事前対策を検討し、可能なソフトウェア対策を実施しましたか？',
            '事前対策に投入できる年間予算が明確に割り当てられていますか？',
            'ハードウェア面での事前対策のための融資制度の活用を検討しましたか？',
            '中核事業に影響を与え得る災害とその大きさ、ボトルネック資源への影響にもとづくBCP発動基準が明確に設定されていますか？',
            'BCP発動を判断する人物が明確に決められていますか？',
            'BCP発動を判断すべき人物が不在などで判断できない場合、代理の判断者が決められていますか？',
            'BCP発動を判断する人物（もしくはその代理者）を、平時よりすべての' + callReplaceWord('従業員') + 'に周知させていますか？',
            'BCP発動基準を定期的に見直す仕組みがありますか？',
            'BCP発動時の' + callReplaceWord('社内') + '体制を明確に規定していますか？',
            'BCP発動時の' + callReplaceWord('社内') + '体制は' + callReplaceWord('経営者') + 'が指揮命令を下せるようになっていますか？',
            'BCP発動時の体制に規定されている全体のリーダー（' + callReplaceWord('経営者') + '）が不在の場合に、代理のリーダーが決められていますか？',
            '上記の代理リーダーが適切な指揮命令ができるように、平時より' + callReplaceWord('経営者') + 'のノウハウの共有が図られていますか？',
            '代理リーダーを、平時よりすべての' + callReplaceWord('従業員') + 'が周知していますか？',
            'BCP発動時の体制における各自の役割を、各' + callReplaceWord('従業員') + 'は明確に把握していますか？',
            '取引先や協力会社からの問い合わせへの対応体制および責任者が明確にされていますか？',
            callReplaceWord('従業員') + '連絡先リスト、もしくは電話連絡網が作成され、最新の情報に更新されていますか？',
            '主要な' + callReplaceWord('取引先') + 'の連絡先リストが作成され、最新の情報に更新されていますか？',
            '緊急避難計画は規定されていますか？',
            '救急や公共サービス、行政組織等への連絡先リストが作成され、最新の情報に更新されていますか？',
            'あなたの' + callReplaceWord('組織') + 'が保有する電話／FAX番号リストを整理し、中核' + callReplaceWord('事業') + 'の継続・復旧において各番号が不可欠かどうかを評価しましたか？',
            'BCPや防災に関して' + callReplaceWord('従業員') + 'と平時より議論したり、勉強会などを開催したりしていますか？',
            'BCPや防災に関する知識や技能を' + callReplaceWord('従業員') + 'に身につけさせるための支援を行っていますか？',
            'BCPで特定された様々な災害を想定した従業員による訓練を定期的に実施していますか？',
            '机上訓練や電話連絡診断など様々なレベルの訓練を、必要に応じて定期的に実施していますか？',
            '自治体などで開催されている防災訓練に関する情報を入手する体制がありますか？',
            '自治体などで開催されている防災訓練に参加していますか？',
            '各' + callReplaceWord('従業員') + 'の役割や義務、責任や権限が本人に理解されていますか？',
            callReplaceWord('経営者') + 'がBCPを積極的に支持していますか？',
            callReplaceWord('経営者') + 'のBCPに対する積極的な姿勢が、' + callReplaceWord('従業員') + 'に理解されていますか？',
            '全社的なBCPの運用に対して' + callReplaceWord('従業員') + 'が積極的に取り組んでいますか？',
            callReplaceWord('経営者') + '層と' + callReplaceWord('従業員') + 'との平時からのコミュニケーションが適切に取れていますか？',
            '社宅の耐震化や家庭用防災用具の配布など、' + callReplaceWord('従業員') + 'の安全を守るための対策を' + callReplaceWord('組織') + 'として実施していますか？',
            callReplaceWord('社内') + '報やイントラネット、口頭での連絡も含め、BCPや防災に関する情報が' + callReplaceWord('社内') + 'に周知されていますか？',
            callReplaceWord('従業員') + 'のBCPへの参加や貢献を評価するための制度がありますか？',
            'BCPの診断計画が明確に規定されていますか？',
            'BCP診断を定期的に実施していますか？',
            '本チェックリストを用いてBCPをチェックしましたか？',
            'BCPの維持・定期的な更新が明確に規定されていますか？',
            'BCPの運用体制の見直しの必要性を判断し、必要に応じて見直しを行っていますか？',
            '事前対策や教育訓練の費用等、BCPの運用に必要な経費を見積もり、その資金を確保するための手順が明確にされていますか？',
            '訓練や診断により明らかとなった問題点がBCPの更新に反映される仕組みがありますか？',
            'あなたの' + callReplaceWord('組織') + 'の組織体制に大きな変更があった場合、' + callReplaceWord('取引先') + 'に大きな変更があった場合、新' + callReplaceWord('商品') + 'を開発した場合などの際に、BCPの更新に反映される仕組みがありますか？',
            '現状のBCPの不適切な点等について、' + callReplaceWord('従業員') + 'が' + callReplaceWord('経営者') + 'へ伝える仕組みがありますか？',
            'BCPの更新が' + callReplaceWord('従業員') + 'に周知される仕組みがありますか？',
            'BCPの外部審査を受けていますか？',
        ]

        const checkItems = ref([]);
        for (let i = 1; i <= 72; i++) {
            checkItems.value.push({
                columnName: ('00000' + i).slice(-5),
                diagnosisItem: '',
                checkType: '',
            });
        }

        const showBack = async () => {
            // 自己診断登録
            const result = await registerJ2a();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 自己診断登録
            const result = await registerJ2a();

            if (result == '200') {
                const url = '/pdca/j2b';
                location.href = url;
            }
        }

        // 初期値に戻るボタン
        const backDefaultValue = () => {
            if (props.self_diagnoses_info.length > 0) {
                props.self_diagnoses_info.forEach(fetchData => {
                    checkItems.value.forEach(initData => {
                        if (initData.columnName === fetchData.column_name) {
                            initData.checkType = fetchData.check_type;
                        }
                    });
                });
            } else {
                checkItems.value.forEach(initData => {
                    initData.checkType = '';
                });
            }
        }

        // 自己診断登録
        const registerJ2a = async () => {
            // 診断項目をセット
            checkItems.value.forEach ((data, index) => {
                data.diagnosisItem = diagnosisItems[index];
            })

            // 閲覧制限
            checkItems.value.push({
                columnName: 'view_limit',
                diagnosisItem: '',
                checkType: viewLimit.value,
            });

            isProcessing.value = true;
            const postUrl = '/api/pdca/registerJ2a';

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'diagnosis_class': diagnosisClassJ,
                'course_type': middleAdvanceCourse,
                'check_items': checkItems.value,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            viewLimit,
            callReplaceWord,
            diagnosisItems,
            checkItems,
            showBack,
            showNext,
            backDefaultValue,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        self_diagnoses_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-color-middle {
    background-color: #2353a4;
    color: white;
}
.bg-color-advanced {
    background-color: #002060;
    color: white;
}
.bg-gray {
    background-color: #a6a6a6;
}
</style>
