<template>
    <div id="confirm-modal" v-show="modalConfirmVisible">
        <div id="content">
            <label>BCPを第{{ bcp_version_info.length }}版として確定しました。</label>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-new-window-button" @click="closeConfirm">O　K</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            版行作成年月日の確定処理
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-new-window-button btn-sm" style="font-size:12pt" value="BCPの更新記録へ" :disabled="isProcessing" @click="showUpdateRecords">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る"  :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div v-for="(form, index) in forms" :key="index">
        <div class="row ms-4 mt-5">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="row ms-4 col-md-2">
                <span v-if="form.version == 1">作成年月日</span>
            </div>
        </div>

        <div class="row ms-4">
            <div class="p2 col-md-1 bg-resilience-orange text-white d-flex align-items-center justify-content-center py-2">
                <span v-if="form.version == 1">初版</span>
                <span v-else>第{{ form.version }}版</span>
            </div>
            <div class="row ms-4 col-md-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" v-if="form.is_completed || isCompleted" v-model="form.create_date" disabled>
                <input type="date" class="form-control d-flex align-items-center justify-content-center py-2" v-else v-model="form.create_date">
            </div>
            <div class="row ms-4 col-md-1" v-if="index === forms.length - 1 && !isCompleted">
                <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="確定" :disabled="isProcessing" @click="viewFixedVersionConfirm">
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="form.version === 2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>BCPを{{ callReplaceWord('従業員') }}に周知した日を「作成年月日」とします。版行は変更できませんので、修正した場合次の版となります。一般的に、BCPを改訂する基準は、{{ callReplaceWord('顧客') }}、仕入先管理の大幅な変更、{{ callReplaceWord('商品') }}等の変更・追加、生産ラインの組み替え、人事異動の他、運用サイクルによってBCPを見直す必要があると判断した場合です。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { onMounted, ref, reactive, inject } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const dayjs = inject('dayjs');

        onMounted(() => {
            if(props.bcp_version_info.length > 0){
                forms.splice(0);
                props.bcp_version_info.forEach(data => {
                    if (data.確定フラグ == 1) {
                        forms.push({
                            'version': data.version,
                            'create_date': dayjs(data.作成日).format('YYYY/MM/DD'),
                            'is_completed': (data.確定フラグ == 1),
                        });
                    }
                });
                // 空の入力欄追加
                forms.push({
                    'version': props.bcp_version_info.length,
                    'create_date': dayjs().format('YYYY-MM-DD'),
                    'is_completed': false,
                });
            }
        });

        const setLevel = ref('111100');
        const isProcessing = ref(false);
        const modalConfirmVisible = ref(false);
        const isCompleted = ref(false);

        // 入力欄
        const forms = reactive([
            {
                version: 1,
                create_date: '',
                is_completed: false,
            }
        ]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showUpdateRecords = async () => {
            // 版数確定はしない
            // ページ遷移
            const url = '/pdca/8';
            location.href = url;
        }

        const showBack = async () => {
            // 戻る場合は版数確定はしない
            // 前のページへ遷移
            const url = '/bcp/bcp_introduction/8';
            location.href = url;
        }

        const showContents = async () => {
            // 目次ページへ遷移
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        // 確定モーダルの表示
        const viewFixedVersionConfirm = async () => {
           // BCP版数を登録
           const result = await registerVersionFix();

           if (result == '200') {
                // 確認モーダルの表示
                modalConfirmVisible.value = true;
           }
        }

        // 確認モーダルを閉じる
        const closeConfirm = () => {
            modalConfirmVisible.value = false;
            isCompleted.value = true;
        }

        // 版数登録
        const registerVersionFix = async () => {
            const postUrl = '/api/bcp/registerVersionFix';
            isProcessing.value = true;

            forms.forEach(data => {
                data.create_date = dayjs(data.create_date).format('YYYY/MM/DD');
            });

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_version_info[0].bcp_id,
                'form_list': forms.filter((value) => { return !value.is_completed }),
            }).then(res => {
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            forms,
            modalConfirmVisible,
            isCompleted,
            callReplaceWord,
            showUpdateRecords,
            showBack,
            showContents,
            viewFixedVersionConfirm,
            closeConfirm,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_version_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
.speech_bubble{
    position:relative;
    width:600px;
    height:100px;
    background:#EEEEEE;
    padding:10px;
    text-align:left;
    border:1px solid #9F9F9F;
    color:#666666;
    font-size:15px;
    border-radius:8px;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    top: 40px;
    left: 30px;
}
.speech_bubble:after,.speech_bubble:before{
    border: solid transparent;
    content:'';
    height:0;
    width:0;
    pointer-events:none;
    position:absolute;
    left:100%;
    top:50%;
}
.speech_bubble:after{
    border-color: rgba(238, 238, 238, 0);
    border-top-width:10px;
    border-bottom-width:10px;
    border-left-width:20px;
    border-right-width:20px;
    margin-top: -10px;
    border-left-color:#EEEEEE;
}
.speech_bubble:before{
    border-color: rgba(159, 159, 159, 0);
    border-top-width:11px;
    border-bottom-width:11px;
    border-left-width:22px;
    border-right-width:22px;
    margin-top: -11px;
    margin-left: 1px;
    border-left-color:#9F9F9F;
}

#chatbot-A {
    display: inline-block;
    width: 90px;
    height: 151px;
    background-image: url('../../../../svg/09_A.svg');
    background-size: 90px auto;
    background-repeat: no-repeat;
    top: 20px;
    left: 650px;
}

#complete_message {
    height: 200px;
}

.width-600 {
    width: 600px;
}

.width-400 {
    width: 400px;
}

.font-color-basic {
    color: #4472c4;
}

.font-color-middle {
    color: #2353a4;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 30%;
    padding: 1em 3em;
    background: #fff;
}

</style>

