<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            4.　財務診断と事前対策計画｜緊急時に使える資金・財務診断とキャッシュフロー対策
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-middle btn-sm" value="中級｜4. 財務診断と事前対策計画 へ進む" v-show="is_completed_basic" :disabled="isProcessing" @click="showMiddle">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="card-title">
                            <label class="d-flex align-items-center justify-content-start">
                                &emsp;
                            </label>
                            <label class="ms-1 d-flex align-items-center justify-content-start">
                                緊急時に使える資金 ―――――――――――――――――――――――――――――――――――――――――――――――――――
                            </label>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-5">
                                <label for="" class="form-label d-flex align-items-center">◯　手元資金の状況</label>
                            </div>
                            <div class="col-md-5">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>緊急時には、応急対策のための費用や{{ callReplaceWord('従業員') }}の給料、{{ callReplaceWord('仕入') }}品の支払いなどの運転資金が必要となります。下記の表を埋めてください。損害保険金は支払い時期はあらかじめ取扱代理店に確認します。{{ callReplaceWord('組織') }}資産には、株券等の有価証券も含まれます。基本コースで検討するのは、自力で賄える手元資金額（D1）です。これに対して借入可能金額を含めた資金額（D2）については、中級コースで検討します。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>

<!--------------------------------------緊急時に使える資金---------------------------------------------------------------->

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-3 border bg-light-gray text-dark d-flex align-items-center">
                                種類
                            </div>
                            <div class="col-md-2 border bg-light-gray text-dark">
                                金額（円）
                            </div>
                            <div class="col-md-3 border bg-light-gray text-dark">
                                投入時期
                            </div>
                            <div class="col-md-4 border bg-light-gray text-dark">
                                備考
                            </div>
                        </div>

                        <div v-for="(form, formIndex) in valueForms" :key="formIndex">
                            <div class="row ms-1 d-flex flex-row">
                                <div class="col-md-3 d-flex align-items-center flex-row border bg-light text-dark">
                                    <span v-if="formIndex === 0">現金・預金</span>
                                    <span v-else-if="formIndex === 1">損害保険金</span>
                                    <span v-else-if="formIndex === 2">{{ callReplaceWord('組織') }}資産売却</span>
                                    <span v-else>{{ callReplaceWord('経営者') }}から支援</span>
                                </div>
                                <div class="col-md-2 border bg-white text-dark ">
                                    <!-- <input type="number" min="0" class="form-control bg-white text-end" id="" :placeholder="form.values[0].placeholder" v-model="form.values[0].value"> -->
                                    <commaInput :class="'form-control bg-white text-end'" id="" :placeholder="form.values[0].placeholder" v-model="form.values[0].value"></commaInput>
                                </div>
                                <div class="col-md-3 d-flex align-items-center flex-row border bg-light text-dark">
                                    <span v-if="formIndex === 0">即時可能</span>
                                    <span v-else-if="formIndex === 1">支払までに時間がかかる</span>
                                    <span v-else-if="formIndex === 2">換金までに時間がかかる</span>
                                    <span v-else>{{ callReplaceWord('経営者') }}の意向次第</span>
                                </div>
                                <div class="col-md-4 border bg-light text-dark">
                                    <input type="text" class="form-control bg-white" id="" placeholder="" v-model="form.values[1].value">
                                </div>
                            </div>
                        </div>

                        <div class="row ms-1 d-flex flex-row">
                            <div class="col-md-3 d-flex align-items-center flex-row border bg-light text-dark">
                                計
                            </div>
                            <div class="col-md-2 border bg-white text-dark ">
                                <div class="form-control bg-light-gray text-end" id="" >
                                    {{ sumPrice().toLocaleString() }}
                                </div>
                            </div>
                            <div class="col-md-3 border bg-white text-dark">
                                &emsp;
                            </div>
                            <div class="col-md-4 border d-flex align-items-center bg-light text-dark">
                                （D1）
                            </div>
                        </div>

                        <div class="card-title">
                            <label class="d-flex align-items-center justify-content-start">
                                &emsp;
                            </label>
                            <label class="ms-1 d-flex align-items-center justify-content-start">
                                財務診断とキャッシュフロー対策 ―――――――――――――――――――――――――――――――――――――――――――――
                            </label>
                        </div>

<!--------------------------------------財務診断---------------------------------------------------------------->

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-5">
                                <label for="" class="form-label d-flex align-items-center">◯　財務診断</label>
                            </div>
                            <div class="col-md-5">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>手元資金と復旧費用を比較します。<br>
                                                ①手元資金（D1）＞復旧費用（C）<br>
                                                この場合は一安心です。緊急時は、お金の心配なしに、復旧対策に集中しましょう。<br>
                                                ②手元資金（D1）＝復旧費用（C）<br>
                                                この場合は、資金調達がいつでもできるよう事前に準備をしておきましょう。<br>
                                                ③手元資金（D1）＜復旧費用（C）<br>
                                                この場合は、災害時貸付制度などを利用して資金を借りる必要があります。
                                            </label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-5">
                                <label for="" class="form-label d-flex align-items-center">1　建物全壊時</label>
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-2 border d-flex align-items-center bg-light text-dark">
                                手元資金
                            </div>
                            <div class="col-md-3 border d-flex align-items-center justify-content-end bg-light-gray text-dark" >
                                {{ sumPrice().toLocaleString() }}
                            </div>
                            <div class="col-md-1 border d-flex align-items-center justify-content-center bg-light text-dark" style="font-size:16pt">
                                {{ comparisonMark1().retComparisonMark }}
                            </div>
                            <div class="col-md-2 border d-flex align-items-center bg-light text-dark">
                                復旧費用
                            </div>
                            <div class="col-md-3 border d-flex align-items-center justify-content-end bg-light-gray text-dark">
                                {{ recoveryCost1.toLocaleString() }}
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-2 d-flex align-items-center text-dark">
                                ⇩
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-2 border border-orange d-flex align-items-center justify-content-center bg-white text-dark" style="font-size:12pt">
                                診断結果
                            </div>
                            <div class="col-md-9 border d-flex align-items-center bg-resilience-orange text-white" style="font-size:12pt">
                                {{ comparisonMark1().retDiagnosisResult }}
                            </div>
                        </div>

                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="col-md-5">
                                <label for="" class="form-label d-flex align-items-center">2　建物半壊時</label>
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-2 border d-flex align-items-center bg-light text-dark">
                                手元資金
                            </div>
                            <div class="col-md-3 border d-flex align-items-center justify-content-end bg-light-gray text-dark" >
                                {{ sumPrice().toLocaleString() }}
                            </div>
                            <div class="col-md-1 border d-flex align-items-center justify-content-center bg-light text-dark" style="font-size:16pt">
                                {{ comparisonMark2().retComparisonMark }}
                            </div>
                            <div class="col-md-2 border d-flex align-items-center bg-light text-dark">
                                復旧費用
                            </div>
                            <div class="col-md-3 border d-flex align-items-center justify-content-end bg-light-gray text-dark">
                                {{ recoveryCost2.toLocaleString() }}
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-2 d-flex align-items-center text-dark">
                                ⇩
                            </div>
                        </div>

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-2 border border-orange d-flex align-items-center justify-content-center bg-white text-dark" style="font-size:12pt">
                                診断結果
                            </div>
                            <div class="col-md-9 border d-flex align-items-center bg-resilience-orange text-white" style="font-size:12pt">
                                {{ comparisonMark2().retDiagnosisResult }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row ms-3 mt-5">
        <div class="p2 col-md-12 align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜4.　財務診断と事前対策計画｜損害保険の整理 へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import levelIcon from '../../common/UserLevelIconsComponent.vue';
import commaInput from "../../common/CommaInputComponent.vue";
import ReplaceWord from '../../../function/CommonReplaceWord';
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
        commaInput,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_values_info_page_5.length > 0) {
                // 復旧費用の算定ページの復旧費用をセット
                let sumCost1 = 0;
                props.bcp_values_info_page_5.forEach(fetchData =>{
                    if (fetchData.field_number === 1 && fetchData.seq === 2) {
                        sumCost1 = sumCost1 + parseInt(fetchData.value);
                    }
                });
                recoveryCost1.value = sumCost1;

                let sumCost2 = 0;
                props.bcp_values_info_page_5.forEach(fetchData =>{
                    if (fetchData.field_number === 2 && fetchData.seq === 2) {
                        sumCost2 = sumCost2 + parseInt(fetchData.value);
                    }
                });
                recoveryCost2.value = sumCost2;
            }

            if (props.bcp_values_info.length > 0) {
                props.bcp_values_info.forEach(fetchData =>{
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        viewLimit.value = fetchData.value == 1 ? true : false;
                    }
                    valueForms.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.values.splice(fetchData.seq - 1, 1, {value: fetchData.value});
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('010000');
        const isProcessing = ref(false);
        const pageName = 'F6';
        const setDefaultFieldNumber = 1;
        const viewLimit = ref(0);
        const recoveryCost1 = ref(0);
        const recoveryCost2 = ref(0);

        const valueForms = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00095',
                values: [
                    {value: '', placeholder: '5,000,000'},
                    {value: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00096',
                values: [
                    {value: '', placeholder: '10,000,000'},
                    {value: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00097',
                values: [
                    {value: '', placeholder: '2,000,000'},
                    {value: ''},
                ]
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00098',
                values: [
                    {value: '', placeholder: '2,000,000'},
                    {value: ''},
                ]
            },
        ]);

        const sumPrice = () => {
            let sum = 0;
            valueForms.forEach(data => {
                if (parseInt(data.values[0].value) >= 0) {
                    sum = sum + parseInt(data.values[0].value);
                }
            });
            return sum;
        }

        const comparisonMark1 = () => {
            return setComparisonMark(recoveryCost1);
        }

        const comparisonMark2 = () => {
            return setComparisonMark(recoveryCost2);
        }

        const setComparisonMark = (recoveryCost) => {
            const totalPrice = sumPrice();
            let retComparisonMark = '＜';
            let retDiagnosisResult = '';
            if (parseInt(totalPrice) > parseInt(recoveryCost.value)) {
                retComparisonMark = '＞';
                retDiagnosisResult = '緊急時はお金の心配なしに復旧対策に集中しましょう。';
            } else if (parseInt(totalPrice) < parseInt(recoveryCost.value)) {
                retComparisonMark = '＜';
                retDiagnosisResult = '災害貸付制度などを利用して資金を借りる必要があります。';
            } else if (parseInt(totalPrice) === parseInt(recoveryCost.value)) {
                retComparisonMark = '＝';
                retDiagnosisResult = '資金調達がいつでもできるよう事前に準備をしておきましょう。';
            }
            return {retComparisonMark, retDiagnosisResult};
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // 資金状況を登録
            const result = await registerFirst6();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 資金状況を登録
            const result = await registerFirst6();

            if (result == '200') {
                const url = '/bcp/bcp_first/7';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 資金状況を登録
            const result = await registerFirst6();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showMiddle = async () => {
            // 資金状況を登録
            const result = await registerFirst6();

            if (result == '200') {
                const url = '/bcp/bcp_financial/home';
                location.href = url;
            }
        }

        // 資金状況登録
        const registerFirst6 = async () => {
            const reqValueForms = [];

            // 閲覧制限
            reqValueForms.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'view_limit',
                seq: 1,
                value: viewLimit.value,
            });

            valueForms.forEach(data => {
                data.values.forEach((val, index) => {
                    let selValue = val.value;
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index + 1,
                            value: selValue,
                        });
                    }
                });
            });

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst6';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            viewLimit,
            recoveryCost1,
            recoveryCost2,
            valueForms,
            sumPrice,
            comparisonMark1,
            comparisonMark2,
            showBack,
            showNext,
            showContents,
            showMiddle,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_texts_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
        bcp_values_info_page_5: {
            type: Object,
        },
        is_completed_basic: {
            type: Boolean,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
}

.bg-gray {
    background-color: #a6a6a6;
}

.border-orange{
    border-style: solid;
    border-width: 1px;
    border-color: #FF6600;
}

</style>
