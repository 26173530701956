<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            みんなの安否
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome" :disabled="isProcessing">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row mt-5 ms-1">
        <div class="p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            <label class="col-form-label text-center">配信日</label>
        </div>
        <div class="p2 col-md-1 border border-end-0 d-flex align-items-center justify-content-start">
            <label class="col-form-label">{{ this.$root.dayjs(配信日時).format('YYYY.MM.DD') }}</label>
        </div>
        <div class="p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            <label class="col-form-label text-center">時間</label>
        </div>
        <div class="p2 col-md-1 border d-flex align-items-center justify-content-center">
            <label class="col-form-label">{{ this.$root.dayjs(配信日時).format('HH:mm') }}</label>
        </div>
        <div class="p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            <label class="col-form-label text-center">登録名称</label>
        </div>
        <div class="p2 col-md-5 border d-flex align-items-center justify-content-start">
            <label class="col-form-label">{{ 登録名称 }}</label>
        </div>
        <div class="p2 col-md-1 border border-start-0 border-top-0 border-end-0 d-flex align-items-center justify-content-end ">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label></label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div class="row ms-1">
        <div class="p2 col-md-1 border border-end-0 bg-light-gray d-flex align-items-center justify-content-center">
            <label class="col-form-label text-center">設問</label>
        </div>
        <div class="p2 col-md-1 border border-top-0 border-end-0 d-flex align-items-center justify-content-center">
            <label class="col-form-label">1</label>
        </div>
        <div class="ms-auto p2 col-md border border-top-0 border-end-0 d-flex align-items-center justify-content-start">
            <label class="col-form-label">{{ 設問1_タイトル }}</label>
        </div>
        <div class="p2 col-md-1 border border-top-0 border-end-0 d-flex align-items-center justify-content-center">
            <label class="col-form-label">2</label>
        </div>
        <div class="ms-auto p2 col-md border border-top-0 border-end-0 d-flex align-items-center justify-content-start">
            <label class="col-form-label">{{ 設問2_タイトル }}</label>
        </div>
        <div class="p2 col-md-1 border border-top-0 border-end-0 d-flex align-items-center justify-content-center">
            <label class="col-form-label">3</label>
        </div>
        <div class="ms-auto p2 col-md border border-top-0 border-end-0 d-flex align-items-center justify-content-start">
            <label class="col-form-label">{{ 設問3_タイトル }}</label>
        </div>
        <div class="p2 col-md-1 border border-top-0 border-end-0 d-flex align-items-center justify-content-center">
            <label class="col-form-label">4</label>
        </div>
        <div class="ms-auto p2 col-md border border-top-0 d-flex align-items-center justify-content-start">
            <label class="col-form-label">{{ 設問4_タイトル }}</label>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-3" v-show="last_safety && last_safety.設問1_グラフ集計要否 === 1 && last_safety.設問1_タイトル">
            <canvas id="q1" class="bg-light px-2 border border-secondary"></canvas>
            <div class="text-center">
                <label class="col-form-label">設問1</label>
            </div>
        </div>
        <div class="col-md-3" v-show="last_safety && last_safety.設問2_グラフ集計要否 === 1 && last_safety.設問2_タイトル">
            <canvas id="q2" class="bg-light px-2 border border-secondary"></canvas>
            <div class="text-center">
                <label class="col-form-label">設問2</label>
            </div>
        </div>
        <div class="col-md-3" v-show="last_safety && last_safety.設問3_グラフ集計要否 === 1 && last_safety.設問3_タイトル">
            <canvas id="q3" class="bg-light px-2 border border-secondary"></canvas>
            <div class="text-center">
                <label class="col-form-label">設問3</label>
            </div>
        </div>
        <div class="col-md-3" v-show="last_safety && last_safety.設問4_グラフ集計要否 === 1 && last_safety.設問4_タイトル">
            <canvas id="q4" class="bg-light px-2 border border-secondary"></canvas>
            <div class="text-center">
                <label class="col-form-label">設問4</label>
            </div>
        </div>
    </div>
    <div class="row mt-3 ">
        <div class="col-md-3">
            <canvas id="q5" class="bg-light px-2 border border-secondary"></canvas>
            <div class="text-center">
                <label class="col-form-label">{{ callReplaceWord('出社') }}可能時間</label>
            </div>
        </div>
        <div class="col-md-3">
            <canvas id="q6" class="bg-light px-2 border border-secondary"></canvas>
            <div class="text-center">
                <label class="col-form-label">返信回答率</label>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <table class="table table-responsive table-bordered">
            <thead>
                <tr>
                    <th class="cursor-pointer" @click="sort管理者">管理者<i class="bi bi-caret-up-fill" v-show="sort_type == '1-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '1-2'"></i></th>
                    <th class="cursor-pointer" @click="sort部署">部署<i class="bi bi-caret-up-fill" v-show="sort_type == '2-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '2-2'"></i></th>
                    <th class="cursor-pointer" @click="sort従業員">回答があった{{ callReplaceWord('従業員') }}等<i class="bi bi-caret-up-fill" v-show="sort_type == '3-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '3-2'"></i></th>
                    <th class="cursor-pointer" @click="sort設問1">設問1<i class="bi bi-caret-up-fill" v-show="sort_type == '4-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '4-2'"></i></th>
                    <th class="cursor-pointer" @click="sort設問2">設問2<i class="bi bi-caret-up-fill" v-show="sort_type == '5-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '5-2'"></i></th>
                    <th class="cursor-pointer" @click="sort設問3">設問3<i class="bi bi-caret-up-fill" v-show="sort_type == '6-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '6-2'"></i></th>
                    <th class="cursor-pointer" @click="sort設問4">設問4<i class="bi bi-caret-up-fill" v-show="sort_type == '7-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '7-2'"></i></th>
                    <th class="cursor-pointer" @click="sort出社可能時間">{{ callReplaceWord('出社') }}可能時間<i class="bi bi-caret-up-fill" v-show="sort_type == '8-1'"></i><i class="bi bi-caret-down-fill" v-show="sort_type == '8-2'"></i></th>
                    <th>コメント</th>
                    <th>位置情報</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="detail in safety_detail_list" :key="detail.id">
                    <td class="text-center">{{ detail.family_id ? '' : detail.管理者 }}</td>
                    <td>{{ detail.部署 }}</td>
                    <td>{{ detail.ユーザ名 }}{{ detail.家族ID ? ' | ' + (detail.family_id ?? 0) + ' / ' + (detail.家族人数 ?? 0) : '' }}</td>
                    <td>{{ detail.設問1_回答 }}</td>
                    <td>{{ detail.設問2_回答 }}</td>
                    <td>{{ detail.設問3_回答 }}</td>
                    <td>{{ detail.設問4_回答 }}</td>
                    <td>{{ detail.出社可能時間名 }}</td>
                    <td>{{ detail.コメント }}</td>
                    <td><input type="button" class="btn btn-primary" :disabled="detail.位置情報有無 != 1" :value="detail.位置情報有無 != 1 ? 'なし' : 'あり'" @click="showMap(detail.latitude, detail.longitude)" /></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row mt-3 pb-5 d-flex flex-row">
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-gray" value="安否確認HOMEへ戻る" @click="backHome" :disabled="isProcessing" />
            </div>
        </div>
    </div>

</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
import { Chart, PieController, ArcElement, Legend, LinearScale, Tooltip } from 'chart.js';
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

Chart.register(PieController, ArcElement, Legend, LinearScale, Tooltip);

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const graph_background_color = ref(['#4472c4', '#ed7d31', '#a5a5a5', '#ffc000', '#5a9bd5']);

        const label_1 = reactive([]);
        const label_2 = reactive([]);
        const label_3 = reactive([]);
        const label_4 = reactive([]);
        const label_5 = reactive([
            'いつでも可能',
            '3日以内',
            '見通しが立たない',
            'コメント欄へ',
        ]);
        const label_6 = reactive([
            '返信あり',
            '返信なし',
        ]);

        const safety_confirm_id = ref(0); 

        const 配信日時 = ref('');
        const 登録名称 = ref('');
        const 設問1_タイトル = ref('');
        const 設問2_タイトル = ref('');
        const 設問3_タイトル = ref('');
        const 設問4_タイトル = ref('');

        const safety_detail_list = reactive([]);

        let myChart1;
        let myChart2;
        let myChart3;
        let myChart4;
        let myChart5;
        let myChart6;

        onMounted(() => {
            if(props.last_safety.id){
                safety_confirm_id.value = props.last_safety.id;
            }
            if(props.last_safety.配信日時){
                配信日時.value = props.last_safety.配信日時;
            }
            if(props.last_safety.登録名称){
                登録名称.value = props.last_safety.登録名称;
            }
            if(props.last_safety.設問1_タイトル){
                設問1_タイトル.value = props.last_safety.設問1_タイトル;
            }
            if(props.last_safety.設問2_タイトル){
                設問2_タイトル.value = props.last_safety.設問2_タイトル;
            }
            if(props.last_safety.設問3_タイトル){
                設問3_タイトル.value = props.last_safety.設問3_タイトル;
            }
            if(props.last_safety.設問4_タイトル){
                設問4_タイトル.value = props.last_safety.設問4_タイトル;
            }
            if(props.last_safety.設問1_問1){
                label_1.push(props.last_safety.設問1_問1);
            }
            if(props.last_safety.設問1_問2){
                label_1.push(props.last_safety.設問1_問2);
            }
            if(props.last_safety.設問1_問3){
                label_1.push(props.last_safety.設問1_問3);
            }
            if(props.last_safety.設問1_問4){
                label_1.push(props.last_safety.設問1_問4);
            }
            if(props.last_safety.設問1_問5){
                label_1.push(props.last_safety.設問1_問5);
            }

            if(props.last_safety.設問2_問1){
                label_2.push(props.last_safety.設問2_問1);
            }
            if(props.last_safety.設問2_問2){
                label_2.push(props.last_safety.設問2_問2);
            }
            if(props.last_safety.設問2_問3){
                label_2.push(props.last_safety.設問2_問3);
            }
            if(props.last_safety.設問2_問4){
                label_2.push(props.last_safety.設問2_問4);
            }
            if(props.last_safety.設問2_問5){
                label_2.push(props.last_safety.設問2_問5);
            }

            if(props.last_safety.設問3_問1){
                label_3.push(props.last_safety.設問3_問1);
            }
            if(props.last_safety.設問3_問2){
                label_3.push(props.last_safety.設問3_問2);
            }
            if(props.last_safety.設問3_問3){
                label_3.push(props.last_safety.設問3_問3);
            }
            if(props.last_safety.設問3_問4){
                label_3.push(props.last_safety.設問3_問4);
            }
            if(props.last_safety.設問3_問5){
                label_3.push(props.last_safety.設問3_問5);
            }

            if(props.last_safety.設問4_問1){
                label_4.push(props.last_safety.設問4_問1);
            }
            if(props.last_safety.設問4_問2){
                label_4.push(props.last_safety.設問4_問2);
            }
            if(props.last_safety.設問4_問3){
                label_4.push(props.last_safety.設問4_問3);
            }
            if(props.last_safety.設問4_問4){
                label_4.push(props.last_safety.設問4_問4);
            }
            if(props.last_safety.設問4_問5){
                label_4.push(props.last_safety.設問4_問5);
            }

            myChart1 = new Chart('q1', {
                type: 'pie',
                data: {
                    labels: label_1,
                    datasets: [{
                        data: props.rtn_summary_array.設問1サマリ,
                        backgroundColor: graph_background_color.value,
                        weight: 100,
                    }],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const sum = props.rtn_summary_array.設問1サマリ.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                    const label_num = Number(context.formattedValue);

                                    return Math.round(label_num / sum * 100) + "%";
                                }
                            }
                        }
                    }
                },
            });

            myChart2 = new Chart('q2', {
                type: 'pie',
                data: {
                    labels: label_2,
                    datasets: [{
                        data: props.rtn_summary_array.設問2サマリ,
                        backgroundColor: graph_background_color.value,
                        weight: 100,
                    }],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const sum = props.rtn_summary_array.設問2サマリ.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                    const label_num = Number(context.formattedValue);

                                    return Math.round(label_num / sum * 100) + "%";
                                }
                            }
                        }
                    }
                },
            });

            myChart3 = new Chart('q3', {
                type: 'pie',
                data: {
                    labels: label_3,
                    datasets: [{
                        data: props.rtn_summary_array.設問3サマリ,
                        backgroundColor: graph_background_color.value,
                        weight: 100,
                    }],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const sum = props.rtn_summary_array.設問3サマリ.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                    const label_num = Number(context.formattedValue);

                                    return Math.round(label_num / sum * 100) + "%";
                                }
                            }
                        }
                    }
                },
            });

            myChart4 = new Chart('q4', {
                type: 'pie',
                data: {
                    labels: label_4,
                    datasets: [{
                        data: props.rtn_summary_array.設問4サマリ,
                        backgroundColor: graph_background_color.value,
                        weight: 100,
                    }],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const sum = props.rtn_summary_array.設問4サマリ.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                    const label_num = Number(context.formattedValue);

                                    return Math.round(label_num / sum * 100) + "%";
                                }
                            }
                        }
                    }
                },
            });

            myChart5 = new Chart('q5', {
                type: 'pie',
                data: {
                    labels: label_5,
                    datasets: [{
                        data: props.rtn_summary_array.出社可能時間サマリ,
                        backgroundColor: graph_background_color.value,
                        weight: 100,
                    }],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const sum = props.rtn_summary_array.出社可能時間サマリ.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                    const label_num = Number(context.formattedValue);

                                    return Math.round(label_num / sum * 100) + "%";
                                }
                            }
                        }
                    }
                },
            });

            myChart6 = new Chart('q6', {
                type: 'pie',
                data: {
                    labels: label_6,
                    datasets: [{
                        data: props.rtn_summary_array.回答サマリ,
                        backgroundColor: graph_background_color.value,
                        weight: 100,
                    }],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const sum = props.rtn_summary_array.回答サマリ.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                                    const label_num = Number(context.formattedValue);

                                    return Math.round(label_num / sum * 100) + "%";
                                }
                            }
                        }
                    }
                },
            });

            if(props.detail_list.length > 0){
                safety_detail_list.splice(0);
                for(let i = 0; i < props.detail_list.length; i++){
                    safety_detail_list.push(props.detail_list[i]);
                }
            }
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backHome = () => {
            const url = '/family/top';

            location.href = url;
        }

        const showMap = (latitude, longitude) => {
            const url = 'https://www.google.co.jp/maps/@' + latitude + ',' + longitude + '?q=' + latitude + ',' + longitude;

            window.open(url, '_blank');
        }

        const sort管理者 = () => {
            if(sort_type.value === '1-1'){
                sort_type.value = '1-2';
            }else{
                sort_type.value = '1-1';
            }
        }

        const sort部署 = () => {
            if(sort_type.value === '2-1'){
                sort_type.value = '2-2';
            }else{
                sort_type.value = '2-1';
            }
        }

        const sort従業員 = () => {
            if(sort_type.value === '3-1'){
                sort_type.value = '3-2';
            }else{
                sort_type.value = '3-1';
            }
        }

        const sort設問1 = () => {
            if(sort_type.value === '4-1'){
                sort_type.value = '4-2';
            }else{
                sort_type.value = '4-1';
            }
        }

        const sort設問2 = () => {
            if(sort_type.value === '5-1'){
                sort_type.value = '5-2';
            }else{
                sort_type.value = '5-1';
            }
        }

        const sort設問3 = () => {
            if(sort_type.value === '6-1'){
                sort_type.value = '6-2';
            }else{
                sort_type.value = '6-1';
            }
        }

        const sort設問4 = () => {
            if(sort_type.value === '7-1'){
                sort_type.value = '7-2';
            }else{
                sort_type.value = '7-1';
            }
        }

        const sort出社可能時間 = () => {
            if(sort_type.value === '8-1'){
                sort_type.value = '8-2';
            }else{
                sort_type.value = '8-1';
            }
        }

        const sort_type = ref('');

        watch(sort_type, () => {
            const url = '/api/safety/getSafetyDetailList?id=' + safety_confirm_id.value + '&sort_type=' + sort_type.value;

            axios.get(url).then(res => {
                if(res.data.length > 0){
                safety_detail_list.splice(0);
                for(let i = 0; i < res.data.length; i++){
                    safety_detail_list.push(res.data[i]);
                }
            }
            });
        });

        const isProcessing = ref(false);

        const deleteShow = () => {
            const url = '/api/safety/deleteShow'

            isProcessing.value = true;

            axios.delete(url, {data: {id: safety_confirm_id.value}})
            .then(res => {
                if(res.data){
                    getNewSafety(res.data.id);
                }else{
                    safety_confirm_id.value = 0;
                    配信日時.value = '';
                    登録名称.value = '';
                    設問1_タイトル.value = '';
                    設問2_タイトル.value = '';
                    設問3_タイトル.value = '';
                    設問4_タイトル.value = '';
                    safety_detail_list.splice(0);
                    label_1.splice(0);
                    label_2.splice(0);
                    label_3.splice(0);
                    label_4.splice(0);
                }
            })
            .finally(fin => {
                isProcessing.value = false;
            });
        }

        const getNewSafety = (id) => {
            const url = '/api/safety/getSafetyList?id=' + id;

            axios.get(url).then(res => {
                if(res.data.last_safety.id){
                    safety_confirm_id.value = res.data.last_safety.id;
                }
                if(res.data.last_safety.配信日時){
                    配信日時.value = res.data.last_safety.配信日時;
                }
                if(res.data.last_safety.登録名称){
                    登録名称.value = res.data.last_safety.登録名称;
                }
                if(res.data.last_safety.設問1_タイトル){
                    設問1_タイトル.value = res.data.last_safety.設問1_タイトル;
                }
                if(res.data.last_safety.設問2_タイトル){
                    設問2_タイトル.value = res.data.last_safety.設問2_タイトル;
                }
                if(res.data.last_safety.設問3_タイトル){
                    設問3_タイトル.value = res.data.last_safety.設問3_タイトル;
                }
                if(res.data.last_safety.設問4_タイトル){
                    設問4_タイトル.value = res.data.last_safety.設問4_タイトル;
                }
                if(res.data.last_safety.設問1_問1){
                    label_1.push(res.data.last_safety.設問1_問1);
                }
                if(res.data.last_safety.設問1_問2){
                    label_1.push(res.data.last_safety.設問1_問2);
                }
                if(res.data.last_safety.設問1_問3){
                    label_1.push(res.data.last_safety.設問1_問3);
                }
                if(res.data.last_safety.設問1_問4){
                    label_1.push(res.data.last_safety.設問1_問4);
                }
                if(res.data.last_safety.設問1_問5){
                    label_1.push(res.data.last_safety.設問1_問5);
                }

                if(res.data.last_safety.設問2_問1){
                    label_2.push(res.data.last_safety.設問2_問1);
                }
                if(res.data.last_safety.設問2_問2){
                    label_2.push(res.data.last_safety.設問2_問2);
                }
                if(res.data.last_safety.設問2_問3){
                    label_2.push(res.data.last_safety.設問2_問3);
                }
                if(res.data.last_safety.設問2_問4){
                    label_2.push(res.data.last_safety.設問2_問4);
                }
                if(res.data.last_safety.設問2_問5){
                    label_2.push(res.data.last_safety.設問2_問5);
                }

                if(res.data.last_safety.設問3_問1){
                    label_3.push(res.data.last_safety.設問3_問1);
                }
                if(res.data.last_safety.設問3_問2){
                    label_3.push(res.data.last_safety.設問3_問2);
                }
                if(res.data.last_safety.設問3_問3){
                    label_3.push(res.data.last_safety.設問3_問3);
                }
                if(res.data.last_safety.設問3_問4){
                    label_3.push(res.data.last_safety.設問3_問4);
                }
                if(res.data.last_safety.設問3_問5){
                    label_3.push(res.data.last_safety.設問3_問5);
                }

                if(res.data.last_safety.設問4_問1){
                    label_4.push(res.data.last_safety.設問4_問1);
                }
                if(res.data.last_safety.設問4_問2){
                    label_4.push(res.data.last_safety.設問4_問2);
                }
                if(res.data.last_safety.設問4_問3){
                    label_4.push(res.data.last_safety.設問4_問3);
                }
                if(res.data.last_safety.設問4_問4){
                    label_4.push(res.data.last_safety.設問4_問4);
                }
                if(res.data.last_safety.設問4_問5){
                    label_4.push(res.data.last_safety.設問4_問5);
                }

                myChart1.destroy();
                myChart2.destroy();
                myChart3.destroy();
                myChart4.destroy();
                myChart5.destroy();
                myChart6.destroy();

                myChart1 = new Chart('q1', {
                    type: 'pie',
                    data: {
                        labels: label_1,
                        datasets: [{
                            data: res.data.rtn_summary_array.設問1サマリ,
                            backgroundColor: graph_background_color.value,
                            weight: 100,
                        }],
                    },
                });

                myChart2 = new Chart('q2', {
                    type: 'pie',
                    data: {
                        labels: label_2,
                        datasets: [{
                            data: res.data.rtn_summary_array.設問2サマリ,
                            backgroundColor: graph_background_color.value,
                            weight: 100,
                        }],
                    },
                });

                myChart3 = new Chart('q3', {
                    type: 'pie',
                    data: {
                        labels: label_3,
                        datasets: [{
                            data: res.data.rtn_summary_array.設問3サマリ,
                            backgroundColor: graph_background_color.value,
                            weight: 100,
                        }],
                    },
                });

                myChart4 = new Chart('q4', {
                    type: 'pie',
                    data: {
                        labels: label_4,
                        datasets: [{
                            data: res.data.rtn_summary_array.設問4サマリ,
                            backgroundColor: graph_background_color.value,
                            weight: 100,
                        }],
                    },
                });

                myChart5 = new Chart('q5', {
                    type: 'pie',
                    data: {
                        labels: label_5,
                        datasets: [{
                            data: res.data.rtn_summary_array.出社可能時間サマリ,
                            backgroundColor: graph_background_color.value,
                            weight: 100,
                        }],
                    },
                });

                myChart6 = new Chart('q6', {
                    type: 'pie',
                    data: {
                        labels: label_6,
                        datasets: [{
                            data: res.data.rtn_summary_array.回答サマリ,
                            backgroundColor: graph_background_color.value,
                            weight: 100,
                        }],
                    },
                });

                if(res.data.detail_list.length > 0){
                    safety_detail_list.splice(0);
                    for(let i = 0; i < res.data.detail_list.length; i++){
                        safety_detail_list.push(res.data.detail_list[i]);
                    }
                }else{
                    safety_detail_list.splice(0);
                }
            });

        }

        return {
            setLevel,
            callReplaceWord,
            backHome,

            配信日時,
            登録名称,
            設問1_タイトル,
            設問2_タイトル,
            設問3_タイトル,
            設問4_タイトル,

            graph_background_color,
            label_1,
            label_2,
            label_3,
            label_4,
            label_5,
            label_6,

            safety_confirm_id,

            safety_detail_list,

            showMap,

            sort管理者,
            sort部署,
            sort従業員,
            sort設問1,
            sort設問2,
            sort設問3,
            sort設問4,
            sort出社可能時間,

            sort_type,

            isProcessing,
            deleteShow,
            getNewSafety,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        last_safety: {
            type: Object,
        },
        rtn_summary_array: {
            type: Object,
        },
        detail_list: {
            type: Array,
        },
        prev_id: {
            type: Number,
        },
    }

}
</script>