<template>
    <div class="heading mt-3">有償会員切替</div>
    <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
        <div class="col-form-label color-resilience-gray">1.有償会員への切替登録</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">2.利用規約・有償会員規約への同意</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">3.入力内容の確認</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-orange-80 fw-bold">4.年会費・従量課金支払い</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">5.本登録完了</div>
    </div>
    <div class="mt-5">
        <div class="d-flex justify-content-center">
            <label class="label-message">{{ label_message }}</label>
        </div>
    </div>
    <div class="mt-5">
        <div class="row">
            <div class="d-flex justify-content-center">
                <input type="button" class="btn btn-red btn" value="切替登録" @click="backSwitchTop">
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref } from 'vue';

export default {
    setup(props){
        const label_message = ref("");

        onMounted(() => {
            if(props.mode === "cancel"){
                label_message.value = "決済処理がキャンセルされました。\nお手数をおかけいたしますが、最初から登録しなおしてください。";
            }else{
                label_message.value = "決済処理にてエラーが発生しました。\nお手数をおかけいたしますが、最初から登録しなおしてください。";
            }
        });

        const backSwitchTop = () => {
            const url = "return_switch_top?uuid=" + props.uuid + "&corp_id=" + props.corp_id;

            location.href = url;
        }

        return {
            label_message,
            backSwitchTop,
        }
    },
    props: {
        mode: {
            type: String,
        },
        uuid: {
            type: String,
        },
        corp_id: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
.label-message {
    white-space: pre-wrap;
}
</style>