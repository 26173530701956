<template>
    <div id="confirm-modal" v-show="availableStatusModalVisible" @click.self="closeAvailableStatusModal">
        <div id="content">
            <div class="btn-close" @click="closeAvailableStatusModal"></div>
            <div class="row mt-3 ms-auto fs-half">
                <table class="table table-bordered border-dark">
                    <tbody>
                        <tr>
                            <td class="bg-light-gray" colspan="2">電気</td>
                            <td class="bg-white" style="width: 120px;">
                                {{ availableStatusInfo.electricity }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">通信（固定電話、携帯電話、インターネット、社内イントラネット等）</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.communication }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">ガス</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.gas }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">上下水道</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.waterworks }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">交通機関（高速道路、国道、鉄道等）</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.trafficMethod }}
                             </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">小売業（金融機関、スーパー等）</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.retailBusiness }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">地域住民等</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.regionalResident }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray">その他</td>
                            <td class="bg-white" style="width: 400px;">
                                {{ availableStatusInfo.otherInputValue }}
                            </td>
                            <td class="bg-white">
                                {{ availableStatusInfo.other }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 ms-auto align-items-center justify-content-center py-2">
                <button class="col-md-5 btn btn-gray btn-sm" @click="closeAvailableStatusModal">閉じる</button>
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="participantModalVisible" @click.self="closeParticipantModal">
        <div id="content">
            <div class="btn-close" @click="closeParticipantModal"></div>
            <div class="mt-3 ms-auto fs-half">
                <template v-if="participantInfo.length > 0">
                    <div class="row">
                        <template v-for="participant, index in participantInfo" :key="index">
                                <span class="border border-dark bg-white text-dark text-center py-2"
                                    :class="{'border-bottom-0': index !== participantInfo.length - 1}"
                                >
                                    {{ participant.participant }}
                                </span>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md ms-auto d-flex align-items-center justify-content-center py-2">
                        参加者がいません
                    </div>
                </template>
            </div>
            <div class="row mt-3">
                <button class="col-md-5 btn btn-gray btn-sm" @click="closeParticipantModal">閉じる</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                訓練計画の閲覧
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>

        <div class="p2 mt-3 ms-md-auto col-md-2 d-flex align-items-start justify-content-end pe-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <div class="mt-1">
        <div class="row ms-md-3 ">
            <template v-for="implementation, index in implementationRecordInfo" :key="index">
                <div class="d-flex col-md-11">
                    <table class="table table-bordered border-dark col-md-11 fs-half">
                        <thead v-if="index === 0">
                            <tr>
                                <td class="bg-light-gray text-dark" style="width: 3%;">No</td>
                                <td class="bg-light-gray text-dark" colspan="3" style="width: 45%;">訓練シナリオの名称・テーマ｜訓練の目的</td>
                                <td class="bg-light-gray text-dark" colspan="5">災害想定｜影響を受ける中核事業・重要業務</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-items-center" rowspan="4" style="width: 3%;">
                                    {{ index + 1 }}
                                </td>
                                <td class="" colspan="3" style="width: 45%;">
                                    {{ implementation.trainingName }}
                                </td>
                                <td class="" colspan="5">
                                    {{ implementation.disasterAssumption }}
                                </td>
                            </tr>
                            <tr>
                                <td class="" colspan="3">
                                    {{ implementation.trainingTarget }}
                                </td>
                                <td class="" colspan="2">
                                    {{ implementation.coreBusiness }}
                                </td>
                                <td class="" colspan="3">
                                    {{ implementation.importantBusiness }}
                                </td>
                            </tr>
                            <tr>
                                <td class="bg-light-gray">訓練の方法</td>
                                <td class="bg-light-gray" style="width: 8%;">発生曜日</td>
                                <td class="bg-light-gray" style="width: 8%;">発生時間</td>
                                <td class="bg-light-gray" style="width: 10%;">経過想定日数</td>
                                <td class="bg-light-gray">被害場所</td>
                                <td class="bg-light-gray" style="width: 10%;">災害イメージ</td>
                                <td class="bg-light-gray" style="width: 10%;">参加者｜{{ callReplaceWord('自社') }}</td>
                                <td class="bg-light-gray" style="width: 10%;">参加者｜外部</td>
                            </tr>
                            <tr>
                                <td class="">
                                    <template v-if="trainingMethodList.find(data => data.id == implementation.trainingMethod)">
                                        <template v-if="trainingMethodList.find(data => data.id == implementation.trainingMethod).id == trainingMethodList[7].id">
                                            {{ trainingMethodList.find(data => data.id == implementation.trainingMethod).methodName }} ：
                                            {{ implementation.trainingMethodOther }}
                                        </template>
                                        <template v-else>
                                            {{ trainingMethodList.find(data => data.id == implementation.trainingMethod).methodName }}
                                        </template>
                                    </template>
                                </td>
                                <td class="">
                                    <template v-if="weekdayList.find(data => data.id == implementation.occurrenceWeekday)">
                                        {{ weekdayList.find(data => data.id == implementation.occurrenceWeekday).weekday }}
                                    </template>
                                </td>
                                <td class="">
                                    {{ implementation.occurrenceTime }}
                                </td>
                                <td class="">
                                    <template v-if="assumptionDaysList.find(data => data.id == implementation.progressAssumptionDays)">
                                        {{ assumptionDaysList.find(data => data.id == implementation.progressAssumptionDays).assumptionDays }}
                                    </template>
                                </td>
                                <td class="">
                                    {{ implementation.damage }}
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-green d-none d-md-block d-print-block" value="利用可能状況" @click="showAvailableStatusModal(implementation)">
                                    <button type="button" class="btn btn-green btn-responsive d-md-none d-print-none" @click="showAvailableStatusModal(implementation)">利用可<br>能状況</button>
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-green btn-responsive" value="参加者一覧" @click="showParticipantModal(implementation, ownCompanyDepartmentType)">
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-green btn-responsive" value="参加者一覧" @click="showParticipantModal(implementation, externalOrganizationType)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" @click="showTop">
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.implementation_record_list).length > 0) {

                // 登録降順に並び替え
                const sortedKeys = Object.values(props.implementation_record_list).sort((a, b) => {
                    return a.implementation_record_id > b.implementation_record_id ? -1 : 1;
                });

                sortedKeys.forEach(async (fetchData, index) => {
                    // 訓練計画実施情報
                    implementationRecordInfo.value.push({
                        trainingName: fetchData.training_name,
                        trainingTarget: fetchData.training_target,
                        trainingMethod: fetchData.training_method,
                        trainingMethodOther: fetchData.training_method_other,
                        disasterAssumption: fetchData.disaster_assumption,
                        coreBusiness: fetchData.core_business,
                        importantBusiness: fetchData.important_business,
                        occurrenceWeekday: fetchData.occurrence_weekday,
                        occurrenceTime: fetchData.occurrence_time ? fetchData.occurrence_time.substring(0, 5) : fetchData.occurrence_time,
                        progressAssumptionDays: fetchData.progress_assumption_days,
                        damage: fetchData.damage,
                        electricity: fetchData.electricity,
                        communication: fetchData.communication,
                        gas: fetchData.gas,
                        waterworks: fetchData.waterworks,
                        trafficMethod: fetchData.traffic_method,
                        retailBusiness: fetchData.retail_business,
                        regionalResident: fetchData.regional_resident,
                        other: fetchData.other,
                        otherInputValue: fetchData.other_input_value,
                        ownCompanyDepartment: [],
                        externalOrganization: [],
                    });

                    // 自社部署
                    if (typeof fetchData.own_company_department !== 'undefined') {
                        implementationRecordInfo.value[index].ownCompanyDepartment = Object.values(fetchData.own_company_department).map(
                            fetchDepartment => ({
                                participantType: fetchDepartment.own_company_department,
                                participant: fetchDepartment.participant,
                            })
                        );
                    }
                    // 外部組織
                    if (typeof fetchData.external_organization !== 'undefined') {
                        implementationRecordInfo.value[index].externalOrganization = Object.values(fetchData.external_organization).map(
                            fetchExternal => ({
                                participantType: fetchData.external_organization,
                                participant: fetchExternal.participant,
                            })
                        );
                    }
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001111');
        const ownCompanyDepartmentType = '1';
        const externalOrganizationType = '2';
        const availableStatusModalVisible = ref(false);
        const availableStatusInfo = ref({});
        const participantModalVisible = ref(false);

        // 訓練計画実施情報
        const implementationRecordInfo = ref([]);

        // 参加者情報
        const participantInfo = ref([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 訓練の方法
        const trainingMethodList = [
            {id: 1, methodName: '机上訓練（模擬災害対策訓練）'},
            {id: 2, methodName: 'バックアップデータを取り出す訓練'},
            {id: 3, methodName: '机上訓練（ワークショップ訓練）'},
            {id: 4, methodName: 'BCP手順確認訓練'},
            {id: 5, methodName: '机上訓練（ロールプレイング訓練）'},
            {id: 6, methodName: 'BCP全体を通して行う総合訓練'},
            {id: 7, methodName: '安否確認システム操作訓練・緊急時通報診断'},
            {id: 8, methodName: 'その他'},
            {id: 9, methodName: '代替施設への移動訓練'},
        ]

        // 曜日
        const weekdayList = [
            {id: 1, weekday: '月曜日'},
            {id: 2, weekday: '火曜日'},
            {id: 3, weekday: '水曜日'},
            {id: 4, weekday: '木曜日'},
            {id: 5, weekday: '金曜日'},
            {id: 6, weekday: '土曜日'},
            {id: 0, weekday: '日曜日'},
        ]

        // 経過想定日数
        const assumptionDaysList = [
            {id: 1, assumptionDays: '発災初日'},
            {id: 2, assumptionDays: '発災後4～5日目'},
        ]

        // 利用可能状況
        const availableStatusList = [
            {id: 1, availableStatus: '使える＝◯'},
            {id: 0, availableStatus: '使えない＝✕'},
        ]

        // 利用可能状況モーダル
        const showAvailableStatusModal = implementation => {
            availableStatusInfo.value = {
                electricity: availableStatusList.find(data => data.id == implementation.electricity)
                    ? availableStatusList.find(data => data.id == implementation.electricity).availableStatus
                    : '',
                communication: availableStatusList.find(data => data.id == implementation.communication)
                    ? availableStatusList.find(data => data.id == implementation.communication).availableStatus
                    : '',
                gas: availableStatusList.find(data => data.id == implementation.gas)
                    ? availableStatusList.find(data => data.id == implementation.gas).availableStatus
                    : '',
                waterworks: availableStatusList.find(data => data.id == implementation.waterworks)
                    ? availableStatusList.find(data => data.id == implementation.waterworks).availableStatus
                    : '',
                trafficMethod: availableStatusList.find(data => data.id == implementation.trafficMethod)
                    ? availableStatusList.find(data => data.id == implementation.trafficMethod).availableStatus
                    : '',
                retailBusiness: availableStatusList.find(data => data.id == implementation.retailBusiness)
                    ? availableStatusList.find(data => data.id == implementation.retailBusiness).availableStatus
                    : '',
                regionalResident: availableStatusList.find(data => data.id == implementation.regionalResident)
                    ? availableStatusList.find(data => data.id == implementation.regionalResident).availableStatus
                    : '',
                other: availableStatusList.find(data => data.id == implementation.other)
                    ?  availableStatusList.find(data => data.id == implementation.other).availableStatus
                    : '',
                otherInputValue: implementation.otherInputValue,
            }
            availableStatusModalVisible.value = true;
        }

        // 参加者モーダル
        const showParticipantModal = (implementation, participantType) => {
            participantInfo.value.splice(0);
            if (participantType === ownCompanyDepartmentType) {
                participantInfo.value = implementation.ownCompanyDepartment.map(
                    fetchData => ({
                        participant: fetchData.participant
                    })
                );
            } else {
                participantInfo.value = implementation.externalOrganization.map(
                    fetchData => ({
                        participant: fetchData.participant
                    })
                );
            }
            participantModalVisible.value = true;
        }

        const closeAvailableStatusModal = () => {
            availableStatusModalVisible.value = false;
        }

        const closeParticipantModal = () => {
            participantModalVisible.value = false;
        }

        const showBack = () => {
            // 前のページへ遷移
            history.back();
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        return {
            setLevel,
            ownCompanyDepartmentType,
            externalOrganizationType,
            availableStatusModalVisible,
            availableStatusInfo,
            participantModalVisible,
            implementationRecordInfo,
            participantInfo,
            callReplaceWord,
            showAvailableStatusModal,
            showParticipantModal,
            closeAvailableStatusModal,
            closeParticipantModal,
            trainingMethodList,
            weekdayList,
            assumptionDaysList,
            availableStatusList,
            showBack,
            showTop,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        implementation_record_list: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-light-gray {
    height: 39.5px;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 40%;
    padding: 1em 3em;
    background: #F2F2F2;
}

.btn-close {
    position: relative;
    top: 0px;
    left: 680px;
}

.btn-close:hover{
    cursor: pointer;
}

@media screen and (max-width: 756px){
    .fs-half {
        font-size: 0.4rem;
    }
    
    .btn-responsive {
        padding-left: 1px;
        padding-right: 1px;
        width: 30px;
        font-size: 0.4rem;;
    }

    #content {
        z-index: 2;
        width: 80%;
        padding: 1em 3em;
        background: #F2F2F2;
    }

}

</style>
