<template>
    <div class="row">
        <div class="col-md-7">
            <div class="row mt-3 pb-5">
                <i id="fixation-logo"></i>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row mt-5 mx-1">
                <button type="button" class="btn btn-light p-3" @click="showFixation">
                    <div class="d-flex justify-content-start align-items-center mx-3">
                        <i id="fixation-button" />
                        <div class="ms-md-3">
                            <span class="font-20">文化定着</span>
                            <span class="font-16">［訓練＆教育］</span>
                        </div>
                    </div>
                </button>
            </div>
            <div class="row mt-3 mx-1">
                <button type="button" class="btn btn-light p-3" @click="showPDCAHome">
                    <div class="d-flex justify-content-start align-items-center mx-3">
                        <i id="pdca-button" />
                        <div class="ms-3">
                            <span class="font-20">診断改善</span>
                            <span class="font-16">［PDCA］</span>
                        </div>
                    </div>
                </button>
            </div>
            <div class="row mt-3 mx-1" v-if="is('administrator')">
                <button type="button" class="btn btn-light p-3" @click="showAuditorList">
                    <div class="d-flex justify-content-start align-items-center mx-3">
                        <i id="internal-auditor-button" />
                        <div class="ms-3">
                            <label class="font-20">内部監査人登録</label>
                        </div>
                    </div>
                </button>
            </div>
            <div class="row mt-3 mx-1">
                <button type="button" class="btn btn-light p-3" @click="showSafetyHome">
                    <div class="d-flex justify-content-start align-items-center mx-3">
                        <i id="safety-button" />
                        <div class="ms-3">
                            <label class="font-20">安否確認</label>
                        </div>
                    </div>
                </button>
            </div>
            <div class="row mt-3 mb-5 mx-1">
                <button type="button" class="btn btn-light p-3" @click="showStockpileList">
                    <div class="d-flex justify-content-start align-items-center mx-3">
                        <i id="stockpile-button" />
                        <div class="ms-3">
                            <label class="font-20">防災備蓄品リスト</label>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showSafetyHome = () => {
            const url = '/safety/home';

            location.href = url;
        }

        const showPDCAHome = () => {
            const url = '/pdca/home';

            location.href = url;
        }

        const showAuditorList = () => {
            const url = '/fixation/editInternalAuditor';

            location.href = url;
        }

        const showFixation = () => {
            const url = '/fixation/fixation';

            location.href = url;
        }

        const showStockpileList = () => {
            const url = '/fixation/Bi?back=fixation';

            location.href = url;
        }

        return {
            showSafetyHome,
            showPDCAHome,
            showAuditorList,
            showFixation,
            showStockpileList,
        }
    },
}
</script>

<style lang="scss" scoped>
#fixation-logo {
    display: inline-block;
    width: 692px;
    height: 237px;
    background-image: url('../../../svg/13-01title.svg');
    background-size: 692px auto;
    background-repeat: no-repeat;

}

#fixation-button {
    display: inline-block;
    width: 71px;
    height: 56px;
    background-image: url('../../../svg/13-01.svg');
    background-size: 71px auto;
    background-repeat: no-repeat;
}

#safety-button {
    display: inline-block;
    width: 68px;
    height: 53px;
    background-image: url('../../../svg/13-02.svg');
    background-size: 68px auto;
    background-repeat: no-repeat;
}

#pdca-button {
    display: inline-block;
    width: 70px;
    height: 48px;
    background-image: url('../../../svg/13-03.svg');
    background-size: 70px auto;
    background-repeat: no-repeat;
}

#internal-auditor-button {
    display: inline-block;
    width: 63px;
    height: 60px;
    background-image: url('../../../svg/13-04.svg');
    background-size: 63px auto;
    background-repeat: no-repeat;
}

#stockpile-button {
    display: inline-block;
    width: 63px;
    height: 56px;
    background-image: url('../../../svg/13-05.svg');
    background-size: 63px auto;
    background-repeat: no-repeat;
}

.font-20 {
    font-size: 20pt;
}

.font-16 {
    font-size: 16pt;
}

@media screen and (max-width: 756px){
    #fixation-logo {
        display: inline-block;
        width: 346px;
        height: 118.5px;
        background-image: url('../../../svg/13-01title.svg');
        background-size: 346px auto;
        background-repeat: no-repeat;
    }

    .font-20 {
        font-size: 16pt;
    }

    .font-16 {
        font-size: 12.8pt;
    }
}
</style>