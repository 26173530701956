<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">3. 中核{{ callReplaceWord('事業') }}と復旧目標</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式08</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 緊急事態発生後に中核事業を復旧させるための代替方針に関する情報を整理する。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">事業継続に係る各種資源の代替の情報</label>
                </div>
            </div>
            <div class="row mt-5 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">⑥ 中核{{ callReplaceWord('事業') }}に必要となる情報に関する情報（バックアップに関する情報を含む）</label>
                </div>
            </div>
            <div class="d-none d-md-block d-print-block">
                <div class="min-width-1280 pb-5 mx-1">
                    <div class="table-responsive">
                        <table class="table table-bordered border-dark">
                            <thead>
                                <tr class="bg-gray-3">
                                    <td>情報名書類名等</td>
                                    <td>関連する社内重要業務</td>
                                    <td>保管場所</td>
                                    <td>{{ callReplaceWord('社内') }}責任者</td>
                                    <td>バックアップの有無</td>
                                    <td>バックアップの記録媒体</td>
                                    <td>バックアップの保管場所</td>
                                    <td>バックアップの頻度</td>
                                    <td>左記の情報が無効になった場合の対応方針（再作成が可能な場合はその方法など）</td>
                                    <td>備考</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in coreBusinessAdditionalInfo" :key="item.id" class="bg-white">
                                    <td>{{ item.informationName || '&emsp;' }}</td>
                                    <td>{{ item.companyImportantBusiness || '&emsp;' }}</td>
                                    <td>{{ item.storingPlace || '&emsp;' }}</td>
                                    <td>{{ item.manager || '&emsp;' }}</td>
                                    <td>{{ convertBackupExistenceToString(item.backupExistence) || '&emsp;' }}</td>
                                    <td>{{ item.backupMedia || '&emsp;' }}</td>
                                    <td>{{ item.backupStoringPlace || '&emsp;' }}</td>
                                    <td>{{ item.backupFrequency || '&emsp;' }}</td>
                                    <td>{{ item.supportedPolicy || '&emsp;' }}</td>
                                    <td>{{ item.note || '&emsp;' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="d-md-none d-print-none">
                <div class="row mt-2" v-for="(item, index) in coreBusinessAdditionalInfo" :key="item.id">
                    {{ (index + 1) + "." }}
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">情報名書類名等</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.informationName || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">関連する社内重要業務</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.companyImportantBusiness || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">保管場所</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.storingPlace || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">{{ callReplaceWord('社内') }}責任者</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.manager || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">バックアップの有無</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ convertBackupExistenceToString(item.backupExistence) || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">バックアップの記録媒体</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.backupMedia || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">バックアップの保管場所</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.backupStoringPlace || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">バックアップの頻度</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.backupFrequency || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">左記の情報が無効になった場合の対応方針（再作成が可能な場合はその方法など）</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.supportedPolicy || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">備考</label>
                    </div>
                    <div class="col-12 border">
                        <label class="col-form-label">{{ item.note || '&emsp;' }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let coreBusinessAdditionalInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K4e';
            location.href = url;
        }

        const showNextForAdmin = () => {
            const url = '/browse/K5';
            location.href = url;
        }

        const showNextForUser = () => {
            const url = '/browse/K8';
            location.href = url;
        }

        const convertBackupExistenceToString = (value) => {
            switch (value) {
                case '2':
                    return 'あり';
                case '3':
                    return 'なし';
                default:
                    return '';
            }
        }

        // 取得データをセット
        if (props.core_business_additional_info.length > 0) {
            coreBusinessAdditionalInfo = props.core_business_additional_info.map(
                (fetchData) => ({
                    id: fetchData.additional_id,
                    informationName: fetchData.information_name,
                    companyImportantBusiness: fetchData.company_important_business,
                    storingPlace: fetchData.storing_place,
                    manager: fetchData.manager,
                    media: fetchData.media,
                    backupExistence: fetchData.backup_existence,
                    backupMedia: fetchData.backup_media,
                    backupStoringPlace: fetchData.backup_storing_place,
                    backupFrequency: fetchData.backup_frequency,
                    supportedPolicy: fetchData.supported_policy,
                    note: fetchData.note,
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            coreBusinessAdditionalInfo,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
            convertBackupExistenceToString,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        core_business_additional_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #f2f2f2;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

// @media (max-width: 767px) {
//     .col-md-1 {
//         width: 8.33333333%;
//     }

//     .col-md-2 {
//         width: 16.66666667%;
//     }
// }

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    table {
    min-width: 800px;
}

}
</style>
