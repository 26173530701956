<template>
    <div class="container">
        <div class="row mt-3">
            <div class="heading mt-3">内部情報管理（過去の年会費）</div>
        </div>
        <div class="row mt-3" v-for="annualCharge in annual_charge_list" :key="annualCharge.year">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th class="text-center">年度</th>
                        <th class="text-center">有償会員の種類</th>
                        <th class="text-center">年会費</th>
                        <th class="text-center">適用開始</th>
                        <th class="text-center">適用終了</th>
                        <th class="text-center">制定日</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="5" class="text-center align-middle">{{ annualCharge.year }}</td>
                        <td>企業会員</td>
                        <td class="text-end">{{ this.formatNumber(annualCharge.年会費_企業会員) }}</td>
                        <td rowspan="5" class="text-center align-middle">{{ this.$root.dayjs(annualCharge.適用開始日).format('YYYY/MM/DD') }}</td>
                        <td rowspan="5" class="text-center align-middle">{{ this.$root.dayjs(annualCharge.適用終了日).format('YYYY/MM/DD') }}</td>
                        <td rowspan="5" class="text-center align-middle">{{ this.$root.dayjs(annualCharge.制定日).format('YYYY/MM/DD') }}</td>
                    </tr>
                    <tr>
                        <td>団体会員</td>
                        <td class="text-end">{{ this.formatNumber(annualCharge.年会費_団体会員) }}</td>
                    </tr>
                    <tr>
                        <td>公共会員</td>
                        <td class="text-end">{{ this.formatNumber(annualCharge.年会費_公共会員) }}</td>
                    </tr>
                    <tr>
                        <td>クラファン会員</td>
                        <td class="text-end">{{ this.formatNumber(annualCharge.年会費_クラファン会員) }}</td>
                    </tr>
                    <tr>
                        <td>賛助会員</td>
                        <td class="text-end">{{ this.formatNumber(annualCharge.年会費_賛助会員) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';

export default {
    setup() {
        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        return {
            formatNumber,
        }

    },
    props: {
        annual_charge_list: {
            type: Array,
        }
    }
}
</script>