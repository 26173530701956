<template>
    <div class="row">
        <div class="h5 col-md-7 heading-orange mt-3" style="font-size:12pt">
            2.　BCPの運用体制（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-1 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-primary btn-sm text-white" value="B&B災害協定ビジネスマッチング へ進む" :disabled="isProcessing" @click="showBizMatch">
        </div>
        <div class="p2 mt-3 ms-auto col-md-1 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-dark btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="card-group">
            <div class="card bg-light-blue border-green">
                <div class="card-body">
                    <div class="row mt-3">
                        <div class="p2">
                            <label for="" class="form-label color-resilience-orange">複数企業連携によるBCPの策定・運用体制　――――――――――――――――――――――――――――――――――――――――――――――――――――</label>
                        </div>
                    </div>
                    <div class="row my-1" v-for="(val, valIndex) in valueForms" :key="valIndex">
                        <div class="col-md-10">
                            <div class="card bg-light-gray border border-dark">
                                <div class="card-body">
                    <!--------------------------------------◯　連携企業---------------------------------------------------------------->
                                    <div class="row ms-1 mt-3 d-flex felx-row">
                                        <div class="col-md-6 bg-title">
                                            <label for="" class="form-label d-flex align-items-center">◯　連携企業</label>
                                        </div>
                                    </div>

                                    <div class="row ms-1 mt-3 d-flex felx-row">
                                        <div class="col-md-6">
                                            <label for="" class="form-label d-flex align-items-center">1）種類</label>
                                        </div>
                                    </div>
                                    <div class="row ms-4 d-flex flex-row">
                                        <div class="col-md-12">
                                            <template v-for="typeContent in typeList" :key="typeContent.typeId">
                                                <div class="form-check form-check-inline" :class="{'ms-5': typeContent.typeId >= 2}">
                                                    <input type="checkbox" :id="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`" :name="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`"
                                                        class="custom-control-input"
                                                        :value="typeContent.typeId" v-model="val.values[0].value"
                                                    >
                                                    <label class="form-check-label" :for="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`">&nbsp;{{ typeContent.type }}</label>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="row ms-1 mt-3 d-flex felx-row">
                                        <div class="col-md-6">
                                            <label for="" class="form-label d-flex align-items-center">2）企業名</label>
                                        </div>
                                    </div>
                                    <div class="row ms-4 d-flex felx-row">
                                        <div class="col-md-7">
                                            <label for="" class="form-label">幹事{{callReplaceWord('組織')}}名</label>
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="幹事{{callReplaceWord('組織')}}名" placeholder="" v-model="val.values[1].value">
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <label for="" class="form-label">連絡先</label>
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="連絡先" placeholder="" v-model="val.values[2].value">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-4 d-flex felx-row">
                                        <div class="col-md-12">
                                            <label for="" class="mt-3 form-label">構成企業</label>
                                            <div class="d-flex flex-row" v-for="text, textIndex in textForms" :key="textIndex">
                                                <template v-if="text.fieldNumber === val.fieldNumber && text.columnName === val.columnName && valIndex === textIndex">
                                                    <textarea type="text" rows="3" class="form-control" id="構成企業" placeholder="" v-model="text.value"></textarea>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                    <!--------------------------------------◯　連携内容---------------------------------------------------------------->
                                <div class="row ms-1 mt-3 d-flex felx-row">
                                        <div class="mt-5 col-md-6 bg-title">
                                            <label for="" class="form-label d-flex align-items-center">◯　連携内容</label>
                                        </div>
                                    </div>

                                    <div class="row ms-4 mt-5 d-flex felx-row">
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="`inlineCheckbox2${valIndex}`" value="true" v-model="val.values[3].value">
                                                <label class="form-check-label" :for="`inlineCheckbox2${valIndex}`">目標復旧時間の目処を予め調整の上、{{callReplaceWord('組織')}}間で共通認識を持っておく。</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-5 mt-3 d-flex felx-row">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">補足</label>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="目標復旧時間の目処を予め調整の上、{{callReplaceWord('組織')}}間で共通認識を持っておく。" placeholder="" v-model="val.values[4].value">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-4 mt-5 d-flex felx-row">
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="`inlineCheckbox3${valIndex}`" value="true" v-model="val.values[5].value">
                                                <label class="form-check-label" :for="`inlineCheckbox3${valIndex}`">共同でBCPに関する勉強会を開催したり、訓練を行ったりする。</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-5 mt-3 d-flex felx-row">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">補足</label>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="共同でBCPに関する勉強会を開催したり、訓練を行ったりする。" placeholder="" v-model="val.values[6].value">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-4 mt-5 d-flex felx-row">
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="`inlineCheckbox4${valIndex}`" value="true" v-model="val.values[7].value">
                                                <label class="form-check-label" :for="`inlineCheckbox4${valIndex}`">緊急時対策のための施設や資機材を共同で設置・備蓄する。</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-5 mt-3 d-flex felx-row">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">補足</label>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="緊急時対策のための施設や資機材を共同で設置・備蓄する。" placeholder="" v-model="val.values[8].value">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-4 mt-5 d-flex felx-row">
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="`inlineCheckbox5${valIndex}`" value="true" v-model="val.values[9].value">
                                                <label class="form-check-label" :for="`inlineCheckbox5${valIndex}`">緊急時に操業停止した場合、他の{{callReplaceWord('企業')}}が{{callReplaceWord('製造')}}や{{callReplaceWord('納入')}}を代替する。</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-5 mt-3 d-flex felx-row">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">補足</label>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="緊急時に操業停止した場合、他の{{callReplaceWord('企業')}}が{{callReplaceWord('製造')}}や{{callReplaceWord('納入')}}を代替する。" placeholder="" v-model="val.values[10].value">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-4 mt-5 d-flex felx-row">
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="`inlineCheckbox6${valIndex}`" value="true" v-model="val.values[11].value">
                                                <label class="form-check-label" :for="`inlineCheckbox6${valIndex}`">緊急時に被災{{callReplaceWord('企業')}}に対して要員応援を行う。</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-5 mt-3 d-flex felx-row">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">補足</label>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="緊急時に被災{{callReplaceWord('企業')}}に対して要員応援を行う。" placeholder="" v-model="val.values[12].value">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-4 mt-5 d-flex felx-row">
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="`inlineCheckbox7${valIndex}`" value="true" v-model="val.values[13].value">
                                                <label class="form-check-label" :for="`inlineCheckbox7${valIndex}`">その他</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ms-5 mt-3 d-flex felx-row">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">補足</label>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="その他" placeholder="" v-model="val.values[14].value">
                                            </div>
                                        </div>
                                    </div>
                    <!--------------------------------------◯　その他---------------------------------------------------------------->
                                    <div class="row ms-1 mt-5 d-flex felx-row justify-content-start">
                                        <div class="col-md-6 bg-title">
                                            <label for="" class="form-label d-flex align-items-center">◯　その他</label>
                                        </div>
                                    </div>

                                    <div class="row mt-3 d-flex felx-row justify-content-start">
                                        <div class="col-md-3 nowrap">
                                            <label for="" class="form-label d-flex flex-nowrap align-items-center">連携活動によって発生する費用は、</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="構成企業で等配分／応援を受ける企業が負担など" placeholder="" v-model="val.values[15].value">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex align-items-center">とする。</label>
                                        </div>
                                    </div>

                                    <div class="row mt-3 d-flex felx-row justify-content-start">
                                        <div class="col-md-2">
                                            <label for="" class="form-label d-flex flex-nowrap align-items-center">幹事{{callReplaceWord('組織')}}は、</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="d-flex flex-row">
                                                <input type="text" class="form-control" id="少なくとも年に1回" placeholder="" v-model="val.values[16].value">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="form-label d-flex align-items-center">頃に全ての構成企業の担当幹部が出席する連絡会を開催し、情報交換を行うとともに、必要に応じて連携内容を見直す。</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex align-items-start flex-column">
                            <div v-if="valIndex !== 0" class="">
                                <input type="button" class="btn btn-delete" value="削除" @click="deleteOneSet(valIndex)">
                            </div>
                            <div class="mt-auto" v-if="valueForms.length === valIndex + 1">
                                <input type="button" class="btn btn-black mt-3" value="ワンセット増やす" @click="addOneSet(val.columnName, val.fieldNumber)">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-1">
                <div class="card border-0 bg-transparent">
                    <div class="card-body">
                        <div class="d-flex flex-row">
                            <VDropdown
                                theme="browsing_restrictions"
                            >
                                <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                <template #popper>
                                    <div class="d-flex justify-content-start">
                                        <label>閲覧制限</label>
                                        <div class="form-check ms-3">
                                            <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                            <label class="form-check-label" for="checkbox_1">
                                                あり
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="row mt-5">
            <div class="p2 d-flex flex-row align-items-center justify-content-start">
                <input type="button" class="btn btn-primary text-white" :value="`基本｜3. 中核${callReplaceWord('事業')}と復旧目標 へ進む`" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
                <input type="button" class="ms-3 btn btn-primary text-white" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
            </div>
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(async () => {
            // バリュー値
            if (props.bcp_values_info.length > 0) {
                valueForms.value.splice(0);
                let wkFieldNumber = null;
                await props.bcp_values_info.forEach(fetchData => {
                    if (fetchData.column_name === 'view_limit') {
                        // 閲覧制限
                        viewLimit.value = fetchData.value == 1 ? true : false;
                        return;
                    } else if (wkFieldNumber !== fetchData.field_number) {
                        // 初期値作成
                        valueForms.value.push(
                            {
                                fieldNumber: fetchData.field_number,
                                columnName: fetchData.column_name,
                                values: [],
                            }
                        );
                        for (let i = 0; i <= 16; i++) {
                            const setValue = i === 0 ? {value:[]} : {value: ''};
                            valueForms.value[fetchData.field_number -1 ].values.push(
                                setValue
                            );
                        }
                        wkFieldNumber = fetchData.field_number;
                    }
                    // 取得データをセット
                    valueForms.value.forEach(initData => {
                         initData.values.forEach((initValues, index) => {
                            if (initData.fieldNumber === fetchData.field_number && initData.columnName === fetchData.column_name && index + 1 === fetchData.seq) {
                               let setValue = fetchData.value;
                                if (index === 0) {
                                    // 最初のチェックボックス部分
                                    setValue = fetchData.value.split(",");
                                } else if (index >= 3 && index <= 13 && index/2 != 0 && fetchData.value === '1') {
                                    // 「連携内容連携内」のチェックボックス部分
                                    setValue = 'true';
                                }
                                initData.values[index].value = setValue;
                            }
                        })
                    });
                })
            }

            // テキスト値
            if (props.bcp_texts_info.length > 0) {
                textForms.value.splice(0);
                // 初期値作成
                for (let i = 0; i < valueForms.value.length; i++) {
                    textForms.value.push({
                        fieldNumber: i + 1,
                        columnName: 'J' + ('00000' + (i + 1)).slice(-5),
                        value: '',
                    });
                }
                // 取得データをセット
                props.bcp_texts_info.forEach(fetchData => {
                    textForms.value.forEach(initData => {
                        if (fetchData.field_number === initData.fieldNumber && fetchData.column_name === initData.columnName) {
                            initData.value = fetchData.text_value
                        }
                    })
                })
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('000100');
        const isProcessing = ref(false);
        const pageName = 'M1';
        const setDefaultFieldNumber = 1;
        const viewLimit = ref(0);

        const typeList =[
            {typeId: 1, type: 'サプライチェーン形成企業'},
            {typeId: 2, type: '同業者の協同組合等'},
            {typeId: 3, type: '地域的な協同組合等'},
        ]

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms = ref([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'J00001',
                values: [],
            }
        ]);

        // バリュー値の入力欄作成
        const initSetValues = () => {
            const values = [];
            for (let i = 0; i <= 16; i++) {
                let setValue = {value: ''}
                if (i === 0) {
                    setValue = {value:[]};
                } else if (i === 8) {
                    setValue = {value: '共同施設・資機材'};
                } else if (i === 10) {
                    setValue = {value: '代替業務の内容例'};
                } else if (i === 12) {
                    setValue = {value: '要員応援の主な業務例'};
                } else if (i === 15) {
                    setValue = {value: '構成企業で等配分／応援を受ける企業が負担など'};
                } else if (i === 16) {
                    setValue = {value: '少なくとも年に1回'};
                }
                values.push(setValue);
            }
            return values;
        }
        valueForms.value[0].values = initSetValues();

        // テキスト値の入力欄作成
        const textForms = ref([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'J00001',
                value: '',
            },
        ]);

        // ワンセット増やすボタン
        const addOneSet = (currentColumnName, currentFieldNumber) => {
            const columnNameNumber = parseInt(currentColumnName.substring(1));
            // バリュー値
            valueForms.value.push({
                fieldNumber: currentFieldNumber + 1,
                columnName: 'J' + ('00000' + (columnNameNumber + 1)).slice(-5),
                values: [],
            });
            valueForms.value[valueForms.value.length - 1].values = initSetValues();
            // テキスト値
            textForms.value.push({
                fieldNumber: currentFieldNumber + 1,
                columnName: 'J' + ('00000' + (columnNameNumber + 1)).slice(-5),
                value: '',
            });
        }

        const deleteOneSet = (index) => {
            if(!confirm((index + 1) + "番目を削除します。よろしいですか？")){
                return false;
            }

            valueForms.value.splice(index, 1);
            textForms.value.splice(index, 1);
        }

        const showBack = async () => {
            // 複数企業連携を登録
            const result = await registerMultiple1();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showBizMatch = async () => {
            // 複数企業連携を登録
            const result = await registerMultiple1();

            // TODO 遷移先決まり次第（仮）
            if (result == '200') {
                const url = '/bcp/bcp_first/3';
                location.href = url;
            }
        }

        const showNext = async () => {
            // 複数企業連携を登録
            const result = await registerMultiple1();

            if (result == '200') {
                const url = '/bcp/bcp_first/3';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 複数企業連携を登録
            const result = await registerMultiple1();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 複数企業連携登録
        const registerMultiple1 = async () => {
            // リクエストの整形
            const reqValueForms = [];
            valueForms.value.forEach(data => {
                data.values.forEach((val, index) => {
                    if (val.value != '') {
                        // 入力済みの場合
                        const setValue = Array.isArray(val.value) ? val.value.join(',') : val.value;
                        reqValueForms.push({
                            fieldNumber: data.fieldNumber,
                            columnName: data.columnName,
                            seq: index + 1,
                            value: setValue,
                        });
                    }
                });
            });

            // 閲覧制限
            reqValueForms.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'view_limit',
                seq: 1,
                value: viewLimit.value,
            });

            console.log(reqValueForms);
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerMultiple1';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
                'text_form_list': textForms.value.filter(data => data.value !== ''),
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            viewLimit,
            callReplaceWord,
            typeList,
            valueForms,
            textForms,
            addOneSet,
            deleteOneSet,
            showBack,
            showBizMatch,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
        bcp_texts_info: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-title {
    background-color: #ffc197;
}

</style>
