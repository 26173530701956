<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜資源（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row">
        <div class="col-md-11">
            <div class="card bg-light-blue border-green">
                <div class="card-body">
                    <div class="card-title">
                        <label class="d-flex align-items-center justify-content-start">
                            ◯　中核事業に必要な供給品目情報
                        </label>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>供給品目</th>
                                            <th>注文番号</th>
                                            <th>数量</th>
                                            <th>供給事業者</th>
                                            <th>関連する{{ callReplaceWord('社内') }}重要業務</th>
                                            <th>
                                                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                                                    <VDropdown theme="hint-theme">
                                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                                                        <template #popper>
                                                            <div class="row">
                                                            <label>{{ hint_word_1 }}</label>
                                                        </div>
                                                        </template>
                                                    </VDropdown>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in suppliesList" :key="item.seq">
                                            <td>
                                                <input type="text" class="form-control" v-model="item.供給品目" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" v-model="item.注文番号" />
                                            </td>
                                            <td>
                                                <input type="Number" min="0" class="form-control text-end" v-model="item.数量" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" v-model="item.供給事業者" />
                                            </td>
                                            <td class="bg-resilience-orange">
                                                <select name="selectImportantBusiness" class="form-select form-control bg-resilience-orange d-flex align-items-center justify-content-center py-2"
                                                    v-model="item.重要業務"
                                                >
                                                    <option type="text" ></option>
                                                    <option type="text" v-for="importantBusinessList in important_businesses_info" :key="importantBusinessList" :value="importantBusinessList.id">
                                                        {{ importantBusinessList.important_business_content }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="button" class="btn btn-al-inquiry-button" value="供給品目別" @click="showDetail(item.seq, item.id)" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="d-flex justify-content-end">
                                    <input type="button" class="btn btn-black" value="1行増やす" @click="addRow">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <VDropdown
                theme="browsing_restrictions"
            >
                <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                <template #popper>
                    <div class="d-flex justify-content-start">
                        <label>閲覧制限</label>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="supplyItemViewLimit">
                            <label class="form-check-label" for="checkbox_1">
                                あり
                            </label>
                        </div>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-11">
            <div class="card bg-light-blue border-green">
                <div class="card-body">
                    <div class="card-title">
                        <label class="d-flex align-items-center justify-content-start">
                            ◯　主要供給者・業者情報［供給品目別］
                        </label>
                        <div class="mt-3 d-flex justify-content-start" v-if="supplierList.length === 0">
                            <div class="col-form-label ms-5">
                                上の供給品目別ボタンを押してください。
                            </div>
                        </div>
                        <div class="mt-3 d-flex justify-content-start" v-else>
                            <div id="detail">
                                <detail
                                    :supplierList="supplierList" :supplies="supplies"
                                    @getMemberInfo="getMemberInfo"
                                />
                            </div>
                            <div class="d-flex align-items-end justify-content-start">
                                <input type="button" class="btn btn-black" value="１セット増やす" @click="addMainSupplierInfoSet" v-if="isVisible">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <VDropdown
                theme="browsing_restrictions"
            >
                <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                <template #popper>
                    <div class="d-flex justify-content-start">
                        <label>閲覧制限</label>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="supplierViewLimit">
                            <label class="form-check-label" for="checkbox_1">
                                あり
                            </label>
                        </div>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜資源（つづき）" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import detail from "./11DetailComponent.vue";
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
        detail
    },
    setup(props) {
        onMounted(() => {
            if (props.supply_item_info.length > 0) {
                    supplyItemViewLimit.value = props.supply_item_info[0].is_view_limit == 1 ? true : false;
                    for (const [index, item] of Object.entries(props.supply_item_info)) {
                        suppliesList.value.push({
                            'seq': parseInt(index) + 1,
                            'id': item.id,
                            '供給品目': item.supply_item,
                            '注文番号': item.order_number,
                            '数量': item.quantity,
                            '供給事業者': item.supply_company,
                            '重要業務': item.important_business_id,
                        })
                    }
            } else {
                suppliesList.value = [
                    {
                        'seq': 1,
                        'id': '',
                        '供給品目': '機械用の特殊な液体など',
                        '注文番号': '',
                        '数量': '',
                        '供給事業者': '',
                        '重要業務': '',
                    }
                ];
            }

            supplierAllList.value = props.main_supplier_info;
            if (supplierAllList.value.length > 0) {
                supplierAllList.value.forEach(supplier => {
                    const tradingStatus = supplier.trading_status !== null ? supplier.trading_status.split(',') : [];
                    supplier.trading_status = [];
                    tradingStatus.forEach(status => {
                        supplier.trading_status.push( status === '1' ? true : false);
                    })
                })
                supplierViewLimit.value = supplierAllList.value[0].is_view_limit == 1 ? true : false;
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 500);

        const setLevel = ref('011100');
        const suppliesList = ref([]);
        let supplies = ref({});
        let currentSeq = 0;

        let supplierAllList = ref([]);
        let supplierList = ref([]);
        const isVisible = ref(false);

        const isProcessing = ref(false);
        let supplyItemViewLimit = ref(false);
        let supplierViewLimit = ref(false);

        const contactType = '3'

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const hint_word_1 = ref(`中核${callReplaceWord('事業')}を復旧・継続するために必要な供給品目には、機械用の特殊な液体など、設備や作業過程の維持に不可欠な品目を入力します。ペン、紙、ホッチキス等の基本的な事務用品、及び、ファイル用棚、机、イス等のオフィス家具は、上級コースで整理するため、ここには入力しません。`);

        // 主要供給事業者情報表示
        const showDetail = (seq, id) => {
            isVisible.value = false;
            // 選択中の主要供給事業者情報を退避・格納
            supplierAllList.value = supplierAllList.value.filter((s) => s.item_seq !== currentSeq || (s.supply_item_info_id !== '' && s.supply_item_info_id === id));
            supplierAllList.value = [...supplierAllList.value, ...supplierList.value];

            // 該当供給品情報の主要供給事業者を抽出し設定
            supplies.value = suppliesList.value.find((i) => i.seq === seq);
            supplierList.value = supplierAllList.value.filter(
                (s) => (s.supply_item_info_id !== '' && s.supply_item_info_id === id) || s.item_seq === seq);
            if (supplierList.value.length === 0) {
                supplierList.value.push({
                    contact_type: contactType,
                    is_view_limit: supplierViewLimit,
                    supply_item_info_id: id,
                    item_seq: seq,
                    provision_service: '',
                    method: '',
                    corp_id: '',
                    corporate_name: '',
                    bank_account_number: '',
                    prefecture: '',
                    city: '',
                    other_address: '',
                    url: '',
                    represent_phone_number: '',
                    first_contact_division: '',
                    first_contact_person: '',
                    first_contact_phone_number: '',
                    first_contact_mobile_phone_number: '',
                    first_contact_fax: '',
                    first_contact_email: '',
                    second_contact_division: '',
                    second_contact_person: '',
                    second_contact_phone_number: '',
                    second_contact_mobile_phone_number: '',
                    second_contact_fax: '',
                    second_contact_email: '',
                    trading_status: [],
                    note: '',
                });
            } else {
                // 供給品目特定用のシーケンス番号埋め込み
                supplierList.value.forEach(data => {
                    data.item_seq = seq;
                });
            }
            currentSeq = seq;
            isVisible.value = true;

            // 詳細表示にスクロールする
            location.href = '#detail';
        }

        // 供給品目情報・１行増やすボタン
        const addRow = () => {
            const max = suppliesList.value.reduce((temp, value) => temp.seq > value.seq ? temp : value);
            suppliesList.value.push({
                'seq': max.seq + 1,
                'id': '',
                '供給品目': '',
                '注文番号': '',
                '数量': '',
                '供給事業者': '',
                '重要業務': '',
            });
        }

        // 主要供給事業者・ワンセット増やすボタン
        const addMainSupplierInfoSet = () => {
            supplierList.value.push({
                    contact_type: contactType,
                    is_view_limit: supplierViewLimit,
                    supply_item_info_id: supplierList.value[0].supply_item_info_id,
                    item_seq: supplierList.value[0].item_seq,
                    provision_service: '',
                    method: '',
                    corp_id: '',
                    corporate_name: '',
                    bank_account_number: '',
                    prefecture: '',
                    city: '',
                    other_address: '',
                    url: '',
                    represent_phone_number: '',
                    first_contact_division: '',
                    first_contact_person: '',
                    first_contact_phone_number: '',
                    first_contact_mobile_phone_number: '',
                    first_contact_fax: '',
                    first_contact_email: '',
                    second_contact_division: '',
                    second_contact_person: '',
                    second_contact_phone_number: '',
                    second_contact_mobile_phone_number: '',
                    second_contact_fax: '',
                    second_contact_email: '',
                    trading_status: [],
                    note: '',
                });
        }

        // 検索ボタン
        const getMemberInfo = (index, searchId) => {
            const url="/api/bcp/getMemberInfo";

            // 企業情報取得
            return axios.post(url, {
                corp_id: searchId,
            }).then( res => {
                console.log(res);
                supplierList.value[index].corporate_name = res.data.corporate_name ?? '';
                supplierList.value[index].prefecture = res.data.prefecture ?? '';
                supplierList.value[index].city = res.data.city ?? '';
                supplierList.value[index].other_address = res.data.other_address ?? '';
                supplierList.value[index].url = res.data.url ?? '';
                supplierList.value[index].represent_phone_number = res.data.phone_number ?? '';
            }).catch((err) => {
                console.log(err);
            });
        }

        // 供給品目情報および、主要供給事業者情報登録
        const registerSecond11 = async () => {

            // 選択中の主要供給事業者情報を退避・格納
            if (currentSeq != 0) {
                supplierAllList.value = supplierAllList.value.filter((s) => s.item_seq !== currentSeq);
                supplierAllList.value = [...supplierAllList.value, ...supplierList.value];
            }
            // リクエスト内容の調整
            supplierAllList.value.forEach(data => {
                data.is_view_limit = supplierViewLimit.value;
            });
            suppliesList.value.forEach(data => {
                data.is_view_limit = supplyItemViewLimit.value;
                data.supplier_list =
                    supplierAllList.value.filter(
                        (s) => (s.supply_item_info_id !== '' && s.supply_item_info_id === data.id) || s.item_seq === data.seq);
            });

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond11';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'contact_type': contactType,
                'supplies_list': suppliesList.value,
            }).then(res => {
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showBack = async () => {
            const result = await registerSecond11();
            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            const result = await registerSecond11();
            if (result == '200') {
                const url = '/bcp/bcp_second/12';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            const result = await registerSecond11();
            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            const result = await registerSecond11();
            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }

        }

        return {
            setLevel,
            hint_word_1,
            supplies,
            suppliesList,
            supplierList,
            isVisible,
            isProcessing,
            supplyItemViewLimit,
            supplierViewLimit,
            callReplaceWord,
            addRow,
            showDetail,
            addMainSupplierInfoSet,
            getMemberInfo,
            registerSecond11,
            showNext,
            showPrevious,
            showContents,
            showBack,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        important_businesses_info: {
            type: Object,
        },
        supply_item_info: {
            type: Array,
        },
        main_supplier_info: {
            type: Array,
        }
    }

}
</script>

<style lang="scss" scoped>
    .border-black {
        border-color: #808080;
    }

    table thead tr th {
        background-color: #d9d9d9;
    }

    table tbody tr td {
        background-color: #f2f2f2;
    }

    table thead tr th:not(:last-child),
    table tbody tr td:not(:last-child)
    {
        border: 1px solid #808080
    }

    table thead tr th:last-child,
    table tbody tr td:last-child {
        background-color: transparent !important;
    }

</style>
