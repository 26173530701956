<template>
    <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item mx-2">
                <a class="nav-link font-s-075" href="#" v-scroll-to="'#annual_charge'">▶年会費登録</a>
            </li>
            <li class="nav-item mx-2">
                <a class="nav-link font-s-075" href="#" v-scroll-to="'#pay_per_use'">▶従量課金登録</a>
            </li>
        </ul>
    </div>
</template>