<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            教育計画登録
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />
    <div class="row">
        <div class="row col-md-11">
            <template v-for="planInfo, index in educationPlanInfo" :key="index">
                <div class="row ms-3 border border-dark border-bottom-0 bg-light-gray" :id="`list-${planInfo.educationPlanId}`">
                    <div class="p2 col-md-12 d-flex align-items-center justify-content-start py-2">
                        &emsp;
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="p2 ms-4 col-md-1 py-2"></div>
                    <div class="p2 ms-4 col-md-10 text-dark d-flex align-items-center justify-content-start">
                        教育計画のテーマ・目的
                    </div>
                    <div class="p2 ms-4 col-md-1 border border-dark bg-light-gray text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                        {{ index + 1 }}
                    </div>
                    <div class="p2 ms-4 col-md-8 d-flex align-items-center justify-content-start py-2">
                        <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="planInfo.theme">
                    </div>
                    <div class="p2 ms-1 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.theme }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-4 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        参加者
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-3 col-md-5 text-dark d-flex align-items-center justify-content-start">
                        {{ callReplaceWord('自社') }}部署
                    </div>

                    <div class="list ms-3 d-flex">
                        <div class="col-md-5 align-items-center justify-content-start">
                            <template v-for="participant, participantIndex in planInfo.participants" :key="participantIndex">
                                <div class="align-items-center justify-content-start d-flex">
                                    <div class="col-md-9 border border-dark bg-white text-dark d-flex align-items-center justify-content-center"
                                        :class="{'border-bottom-0': participantIndex !== planInfo.participants.length - 1}"
                                    >
                                        <select name="selectDepartment" class="form-select form-control"
                                            v-model="participant.participant"
                                        >
                                            <option type="text"></option>
                                            <option type="text" v-for="item in corp_department" :key="item" :value="item.部署">
                                                {{ item.部署 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="ms-2 d-flex justify-content-end" v-if="participantIndex === planInfo.participants.length - 1">
                                        <input type="button" class="btn btn-black" value="マスを増やす" :disabled="isProcessing" @click="addLine(index)">
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-5 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        教育の方法
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="row ms-4 col-md-10">
                        <template v-for="method, methodIndex in educationMethodList" :key="method.id">
                            <div class="mt-3 col-md-5 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline"
                                :class="{'col-md-10': methodIndex === 4}"
                            >
                                <input type="radio" :id="`customRadioInline${index}${method.id}`" :name="`customRadioInline${index}`"
                                    class="custom-control-input"
                                    :value="method.id" v-model="planInfo.educationMethod"
                                >
                                <label class="custom-control-label" :for="`customRadioInline${index}${method.id}`">&nbsp;{{ method.methodName }}</label>
                            </div>
                            <div class="mt-3 col-md-1 d-flex align-items-start justify-content-start" v-if="methodIndex !== 4">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>{{ hintContents.educationMethod[methodIndex] }}</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </template>
                        <div class="ms-3 col-md-5 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                            <input type="text" class="form-control" placeholder="" v-model="planInfo.educationMethodOther">
                        </div>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-5 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        実施サイクル
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="row ms-4 col-md-10">
                        <template v-for="cycle in implementationCycleList" :key="cycle.id">
                            <div class="mt-3 col-md-5 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                                <input type="radio" :id="`customRadioInline1${index}${cycle.id}`" :name="`customRadioInline1${index}`"
                                    class="custom-control-input"
                                    :value="cycle.id" v-model="planInfo.implementationCycle"
                                >
                                <label class="custom-control-label" :for="`customRadioInline1${index}${cycle.id}`">&nbsp;{{ cycle.cycleName }}</label>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 bg-light-gray text-white">
                    <div class="p2 col-md-12  d-flex align-items-center justify-content-start py-2">
                        &emsp;
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="p2 ms-3 col-md-10 d-flex align-items-start justify-content-start">
                    </div>
                    <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start" v-if="index === educationPlanInfo.length - 1">
                        <input type="button" class="btn btn-black" value="ワンセット増やす" :disabled="isProcessing" @click="addOneSet(index + 1)">
                    </div>
                </div>
            </template>
        </div>
        <div class="ms-3 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>{{ hintContents.educationPlan }}</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="上記、教育計画を登録する" style="font-size:10.5pt" :disabled="isProcessing" @click="register">
        </div>
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>
    <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.education_plan_list).length > 0) {
                // 教育計画
                educationPlanInfo.value.splice(0);
                Object.values(props.education_plan_list).forEach((fetchData, index) => {
                    educationPlanInfo.value.push({
                        educationPlanId: fetchData.education_plan_id,
                        theme: fetchData.theme,
                        participants: [],
                        educationMethod: fetchData.education_method,
                        educationMethodOther: fetchData.education_method_other,
                        implementationCycle: fetchData.implementation_cycle,
                    });

                    // 参加者
                    if (typeof fetchData.participants !== 'undefined') {
                        educationPlanInfo.value[index].participants = Object.values(fetchData.participants).map(
                            fetchParticipant => ({
                                participant: fetchParticipant.participant,
                            })
                        );
                        if (educationPlanInfo.value[index].participants.length < 5) {
                            // 5つまで入力欄作成
                            for (let i = educationPlanInfo.value[index].participants.length; i < 5; i++) {
                                educationPlanInfo.value[index].participants.push({
                                    participant: '',
                                });
                            }
                        }
                    } else {
                        // 初期入力欄作成
                        for (let i = 0; i < 5; i++) {
                            educationPlanInfo.value[index].participants.push({
                                participant: '',
                            });
                        }
                    }
                })
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 1000);

        const setLevel = ref('011111');
        const isProcessing = ref(false);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
                window.location.reload();
            }, 3000)
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // ヒント内容
        const hintContents = {
            theme: `BCP教育の内容は、大きく分けて２つあります。${callReplaceWord('従業員')}にBCPを受け入れてもらうこと、防災や災害時対応に関する知識や技能を${callReplaceWord('従業員')}に身に付けてもらうことです。教育の有効性を評価するため、目標を明確に定め教育方法を選択しましょう。`,
            educationMethod: [
                `${callReplaceWord('自社')}のBCPについて${callReplaceWord('従業員')}と議論し、BCPの概要、${callReplaceWord('従業員')}各々に求められる役割等について習得させます。また災害記事について話し合ったりすることにより、${callReplaceWord('従業員')}の防災に対して意識づけも行います。`,
                '同業組合や商工会等で勉強会を開催する等の取組みが望まれます。このような活動は災害時における地域企業同士の協力体制強化にも有効です。',
                `地元消防署等では、定期的に心肺蘇生法のような応急救護の講習が開催されていることがありますので、そのような掲示や広報を見かけたら、${callReplaceWord('従業員')}に紹介して受講を促してください。
                消防庁 https://www.fdma.go.jp/relocation/kyukyukikaku/oukyu/`,
                `各地の商工会議所等が、企業のBCPや防災対策に関連するセミナーや講習を開催していることがありますので、${callReplaceWord('従業員')}に対して積極的な参加を促してください。`,
            ],
            educationPlan: `BCPの運用は継続的な活動であり、${callReplaceWord('会社')}が存続する限り、維持・更新とそれにもとづく教育や研修は継続的に実施していくことが必要です。そのためには、${callReplaceWord('従業員')}に対するBCP教育を通してBCPを定着させることが重要となってきます。また、BCP教育は、継続的に実施されることが非常に重要です。`,
        }

        // 教育の方法
        const educationMethodList = [
            {id: 1, methodName: '社内ディスカッション（BCPの周知・防災）'},
            {id: 2, methodName: '防災に関する勉強会への参加'},
            {id: 3, methodName: '応急救護の受講（心肺蘇生法等）'},
            {id: 4, methodName: 'BCPや防災対策関連のセミナーへの参加'},
            {id: 5, methodName: 'その他'},
        ]

        // 実施サイクル
        const implementationCycleList = [
            {id: 1, cycleName: '定期教育'},
            {id: 2, cycleName: '講習・セミナーの予約有無'},
        ]

        // 教育計画情報
        const educationPlanInfo = ref([
            {
                educationPlanId: '',
                theme: `${callReplaceWord('従業員')}へのBCP周知`,
                participants: [],
                educationMethod: '',
                educationMethodOther: '',
                implementationCycle: '',
            }
        ]);

        // 参加者
        for (let i = 0; i < 5; i++) {
            educationPlanInfo.value[0].participants.push({
                participant: '',
            });
        }

        // 1行増やすボタン
        const addLine = index => {
            educationPlanInfo.value[index].participants.push({
                participant: '',
            });
        }

        // ワンセット増やすボタン
        const addOneSet = index => {
            educationPlanInfo.value.push({
                educationPlanId: '',
                theme: `${callReplaceWord('従業員')}へのBCP周知`,
                participants: [],
                educationMethod: '',
                educationMethodOther: '',
                implementationCycle: '',
            });

            for (let i = 0; i < 5; i++) {
                educationPlanInfo.value[index].participants.push({
                    participant: '',
                });
            }
        }

        const showBack = async () => {
            // 教育計画を登録
            const result = await registerK1();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const register = async () => {
            // 教育計画を登録
            await registerK1();
        }

        const showTop = async () => {
            // 教育計画を登録
            const result = await registerK1();

            if (result == '200') {
                const url = '/fixation/fixation';
                location.href = url;
            }
        }

        // 教育計画登録
        const registerK1 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/registerK1';

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'education_plan_info': educationPlanInfo.value,
            }).then(async (res) => {
                await visibleFlashMessage('正常に登録されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                // isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            callReplaceWord,
            hintContents,
            educationMethodList,
            implementationCycleList,
            educationPlanInfo,
            addLine,
            addOneSet,
            showBack,
            register,
            showTop,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        corp_department: {
            type: Array,
        },
        education_plan_list: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
