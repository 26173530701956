<template>
    <div class="row">
        <div class="col-md-3">
            <div class="h5 heading-orange mt-3" style="font-size:14pt">
                マイページ
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="col-md-7">
            <div class="mt-3">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <div>利用者がマイページを訪れたら、まず初めに「わたし登録」と「わたしの家族登録」を行います。<br />その後、変更があった場合は都度登録内容を書き換え最新の情報にしておきます。<br />管理者は、「管理者専用」において、あらかじめ必要な設定を行い利用者が利用しやすい環境をととのえます。</div>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
        <div class="col-lg-2 mt-3">
            <input type="button" class="btn btn-black" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showRegisterMyself">
                        <div class="d-flex fill align-middle">
                            <i id="mypage-watashi"></i>
                            <span class="ps-3 flex-fill text-start">わたし登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>利用者自らのプライベートな情報を登録します。なお、管理者は利用者の登録内容を見ることができません。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
        <div class="col-md-5 mt-sm-less">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showMemberInfo">
                        <div class="d-flex fill">
                            <i id="mypage-kaiin"></i>
                            <span class="ps-3 flex-fill text-start">会員情報の閲覧</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>会員情報、従量課金情報を利用者全員が閲覧できます。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
    </div>
    <div class="row mt-sm-5 mt-sm-less">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showRegisterMyFamily">
                        <div class="d-flex fill align-middle">
                            <i id="mypage-family"></i>
                            <span class="ps-3 flex-fill text-start">わたしの家族登録</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>利用者が、自ら家族を登録し家族IDを発行します。なお、管理者は利用者の登録内容を見ることができません。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showAdministratorHome" :disabled="!is('administrator')">
                        <div class="d-flex fill">
                            <i id="mypage-kanri"></i>
                            <span class="ps-3 flex-fill text-start">管理者専用</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>［管理者専用］拠点・支店の登録、利用者ID発行、読替え登録、B&B災害応援協定検索キーワード登録等を行います。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
    </div>
    <div class="row mt-sm-5 mt-sm-less">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-none w-100" @click="showBCPCard">
                        <div class="d-flex fill align-middle">
                            <i id="mypage-card"></i>
                            <span class="ps-3 flex-fill text-start">携帯カード印刷</span>
                        </div>
                    </button>
                </div>
                <div class="col-md-4">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <span>迅速な初動対応ができるよう、すべての{{ replacement_text('従業員') }}が財布等に入れて携帯します。なお、管理者は利用者の登録内容を見ることができません。</span>
                            </div>
                        </template>
                    </VDropdown>

                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row mt-5">
        <div class="col-md-4 cursor-pointer" @click="showRegisterMyself">
            <i id="mp_watashi" />
        </div>
        <div class="col-md-4 cursor-pointer" @click="showRegisterMyFamily">
            <i id="mp_family" />
        </div>
        <div class="col-md-4 cursor-pointer" >
            <i id="mp_keitaicard" @click="showBCPCard"/>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4 cursor-pointer" @click="showMemberInfo">
            <i id="mpk_kaiin" v-if="is('administrator')" />
            <i id="mpr_kaiin" v-else />
        </div>
        <div class="col-md-4 cursor-pointer" @click="showAdministratorHome" v-if="is('administrator')" >
            <i id="mpk_kanri" />
        </div>
    </div> -->
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon
    },
    setup() {
        const setLevel = ref('111111');

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const returnHome = ()=> {
            const url = '/home';

            location.href = url;

        }

        const showBack = () => {
            // 前のページへ遷移
            // sessionStorage.setItem('refresh', 'true');
            // history.back();

            const url = '/home';

            location.href = url;
        }

        const replacement_text = (word) => {
            return word;
        }

        const showAdministratorHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const showMemberInfo = () => {
            const url = '/al/myPage/showMemberInfo';

            location.href = url;
        }

        const showRegisterMyself = () => {
            const url = '/al/myPage/registerMyself';

            location.href = url;
        }

        const showRegisterMyFamily = () => {
            const url = '/al/myPage/registerMyFamily';

            location.href = url;
        }

        const showBCPCard = () => {
            const url = '/al/myPage/showBCPCard';

            location.href = url;
        }
        
        return {
            setLevel,
            returnHome,
            showBack,
            replacement_text,
            showAdministratorHome,
            showMemberInfo,
            showRegisterMyself,
            showRegisterMyFamily,
            showBCPCard,
        }
    },
}
</script>

<style lang="scss" scoped>
.btn-none {
    color: #3f3f3f;
    background-color: snow;
    border: thin solid #3f3f3f;
    height: 45px;
}

.btn-none:hover {
    color: snow;
    background-color: #3f3f3f;
    border: thin solid #3f3f3f;
    height: 45px;
}

#mypage-watashi {
    background-image: url('../../../../svg/my_page_button/mypage_watashi.svg');
    display: inline-flex;
    width: 24px;
    height: 31.3px;
    background-size: 24px auto;
    background-repeat: no-repeat;
}

#mypage-kaiin {
    background-image: url('../../../../svg/my_page_button/mypage_kaiin.svg');
    display: inline-flex;
    width: 24px;
    height: 26.7px;
    background-size: 24px auto;
    background-repeat: no-repeat;
}

#mypage-family {
    background-image: url('../../../../svg/my_page_button/mypage_family.svg');
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-size: 24px auto;
    background-repeat: no-repeat;
}

#mypage-kanri {
    background-image: url('../../../../svg/my_page_button/mypage_kanri.svg');
    display: inline-flex;
    width: 24px;
    height: 25.6px;
    background-size: 24px auto;
    background-repeat: no-repeat;
}

#mypage-card {
    background-image: url('../../../../svg/my_page_button/mypage_card.svg');
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-size: 24px auto;
    background-repeat: no-repeat;
}

#mp_watashi {
    background-image: url('../../../../svg/my_page_button/mp_watashi.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#mp_family {
    background-image: url('../../../../svg/my_page_button/mp_family.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#mp_keitaicard {
    background-image: url('../../../../svg/my_page_button/mp_keitaicard.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#mpk_kaiin {
    background-image: url('../../../../svg/my_page_button/mpk_kaiin.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#mpr_kaiin {
    background-image: url('../../../../svg/my_page_button/mpr_kaiin.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

#mpk_kanri {
    background-image: url('../../../../svg/my_page_button/mpk_kanri.svg');
    display: inline-flex;
    width: 400px;
    height: 245.88px;
    background-size: 400px auto;
    background-repeat: no-repeat;
}

@media (max-width: 576px) {
    /* sm未満の場合のスタイル */
    .mt-sm-less {
        margin-top: 10px; /* 例として10pxを指定 */
    }
}
</style>