<template>
    <div class="heading mt-3">有償会員登録（年会費・従量課金支払い）</div>
    <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
        <div class="col-form-label color-resilience-gray">1.仮登録</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">3.有償会員登録</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">4.利用規約・有償会員規約への同意</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-gray">6.年会費・従量課金支払い</div>
        <div class="col-form-label mx-3 color-resilience-gray">＞</div>
        <div class="col-form-label color-resilience-orange-80 fw-bold">7.本登録完了</div>
    </div>
    <div class="row mt-3">
        <label>登録が完了いたしました。ログインに必要な専用URL、ID・パスワードは、登録メールアドレスに送信しました。</label>
    </div>
    <div class="row mt-3">
        <div class="col-md-8">
            <table class="table table-bordered bg-light" id="result_table">
                <tbody>
                    <tr>
                        <td>企業ID（会員番号）</td>
                        <td>{{ corp_info.企業ID }}</td>
                    </tr>
                    <tr>
                        <td>開始日（開始月）</td>
                        <td>
                            <div class="row">
                                <div class="col-3">
                                    {{ this.$root.dayjs(corp_info.利用開始日).format('YYYY年M月D日') }}
                                </div>
                                <div class="col-9">
                                    （{{ this.$root.dayjs(corp_info.利用開始日).format('YYYY年M月') }}）
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>終了日</td>
                        <td>{{ this.$root.dayjs(corp_info.有効期限日).format('YYYY年M月D日') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3 mb-5">
        <div class="col-md-8">
            <label>終了・更新日前に、ご登録のメールアドレスへ更新案内をお知らせし、その後自動更新を行います。</label>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-8">
            <div class="d-flex justify-content-start">
                <input type="button" class="btn btn-orange-80" value="請求書PDFをダウンロード" @click="makeInvoice" :disabled="isProcessing" />
                <input type="button" class="btn btn-orange-80 ms-5" value="年会費・従量課金の領収書PDFをダウンロード" @click="makeReceipt" :disabled="isProcessing" />
                <input type="button" class="btn btn-orange-80 ms-5" value="登録内容PDFをダウンロード" @click="downloadPDF" :disabled="isProcessing" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default{
    setup(props) {
        const isProcessing = ref(false);

        const makeInvoice = () => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'invoice_from_payment_status',
                'bill_id': props.bill_info.id,
                'corp_id': props.corp_info.id,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '請求書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const makeReceipt = () => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'receipt',
                'bill_id': props.bill_info.id,
                'corp_id': props.corp_info.id,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '領収書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const downloadPDF = () => {
            const url_print = '/api/downloadUserInfo';

            isProcessing.value = true;

            axios.post(url_print, {
                filePath: props.attach_file,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '有償会員の登録内容.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        return {
            isProcessing,

            makeInvoice,
            makeReceipt,
            downloadPDF,
        }

    },
    props: {
        bill_info: {
            type: Object,
        },
        corp_info: {
            type: Object,
        },
        attach_file: {
            type: String,
        },
    }
}
</script>