<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">3. 中核{{ callReplaceWord('事業') }}と復旧目標</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式08</span>
                </div>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 緊急事態発生後に中核事業を復旧させるための代替方針に関する情報を整理する。</label>
                </div>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">事業継続に係る各種資源の代替の情報</label>
                </div>
            </div>
            <div class="row mt-5 mx-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">⑤ 通信手段・各種インフラ</label>
                </div>
            </div>
            <div class="row mt-3 mx-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">電話等の通信手段の代替方針</label>
                </div>
                <div class="p2 col-md-9 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ textForms['K00064'].value || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mx-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">電力</label>
                </div>
                <div class="p2 col-md-9 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ textForms['K00065'].value || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mx-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">ガス</label>
                </div>
                <div class="p2 col-md-9 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ textForms['K00066'].value || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mx-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">水道</label>
                </div>
                <div class="p2 col-md-9 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ textForms['K00067'].value || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mx-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">その他</label>
                </div>
                <div class="p2 col-md-9 border border-bottom-0 d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ textForms['K00068'].value || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mx-1 pb-5">
                <div class="p2 col-md-3 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-9 border d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ textForms['K00069'].value || '&emsp;' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        const textForms = reactive({
            K00064: {
                value: '',
            },
            K00065: {
                value: '',
            },
            K00066: {
                value: '',
            },
            K00067: {
                value: '',
            },
            K00068: {
                value: '',
            },
            K00069: {
                value: '',
            },
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K4d';
            location.href = url;
        }

        const showNextForAdmin = async () => {
            const url = '/browse/K4f';
            location.href = url;
        }

        const showNextForUser = async () => {
            const url = '/browse/K8';
            location.href = url;
        }

        // 取得データをセット
        if (props.bcp_texts_info.length > 0) {
            Object.keys(textForms).forEach(columnName => {
                textForms[columnName].value = props.bcp_texts_info.find(
                    (fetchData) => fetchData.column_name === columnName
                )?.text_value;
            });
        }

        return {
            setLevel,
            isMargin1,
            textForms,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_texts_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-9 {
        width: 75%;
    }
}
</style>
