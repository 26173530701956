<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式09-2</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 発動フロー</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">緊急事態が発生した場合のBCPの発動手順は次のとおりです。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">①   緊急事態が発覚したら、初動対応（災害の種類ごとに違いあり）を行います。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">②   なるべく速やかに、{{ callReplaceWord('顧客') }}等へ被災状況を連絡するとともに、中核{{ callReplaceWord('事業') }}の継続方針を立案し、その実施体制を確立します。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">③   中核{{ callReplaceWord('事業') }}継続方針に基づき、{{ callReplaceWord('顧客') }}・協力会社向け対策、{{ callReplaceWord('従業員') }}・{{ callReplaceWord('事業') }}資源対策、財務対策を併行して進めます。また、地域貢献活動も実施します。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">④   緊急事態の進展・収束にあわせて、応急対策、復旧対策、復興対策を進めます。</label>
                </div>
            </div>

            <br>

            <div class="card" id="flow_card">
                <div class="card-body scale-half" id="flow_card_body">
                    <div class="d-flex justify-content-center my-2 flow-diagram">
                        <div class="flow-diagram-row-1">
                            <div class="bg-light criterion">大規模災害時の目安</div>
                            <div class="timetable">
                                <div>当日〜</div>
                                <div>数日～</div>
                                <div>1ヶ月～</div>
                                <div>数か月～</div>
                            </div>
                        </div>
                        <div class="flow-diagram-row-2">
                            <div>復旧目標時間</div>
                        </div>
                        <div class="flow-diagram-row-3">
                            <div class="flow-diagram-section d-flex">
                                <div class="flow-diagram-content content-1 me-3">緊急事態の発覚</div>
                            </div>
                            <div class="flow-diagram-border">
                                <div class="flow-diagram-border-1 d-flex">
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="flow-diagram-border-2 d-flex">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div class="flow-diagram-section d-flex">
                                <div class="flow-diagram-content content-2 me-3">
                                    <div class="flow-diagram-title">初動対応［緊急事態・災害ごと］</div>
                                    <div class="flow-diagram-menu">
                                        <div class="btn flow-diagram-item">二次災害の防止措置</div>
                                        <div class="btn flow-diagram-item">{{ callReplaceWord('従業員') }}の参集</div>
                                        <div class="btn flow-diagram-item">安否・被災状況の把握</div>
                                    </div>
                                </div>
                            </div>
                            <div class="flow-diagram-border">
                                <div class="flow-diagram-border-1 d-flex">
                                    <div></div>
                                    <div class="border-b"></div>
                                    <div class="border-b"></div>
                                </div>
                                <div class=" flow-diagram-border-2 d-flex">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div class="flow-diagram-section d-flex">
                                <div class="flow-diagram-content content-3 me-3">
                                    <div class="flow-diagram-title">事業継続のための緊急対策</div>
                                    <div class="flow-diagram-menu">
                                        <div class="btn flow-diagram-item">{{ callReplaceWord('顧客') }}・協力会社への連絡</div>
                                        <div class="btn flow-diagram-item">中核{{ callReplaceWord('事業') }}継続方針立案・体制確立</div>
                                    </div>
                                </div>
                                <div class="flow-diagram-section-hint">
                                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                                    </div>
                                    <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                                    </div>
                                </div>
                            </div>
                            <div class="flow-diagram-border">
                                <div class="flow-diagram-border-1 d-flex">
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="flow-diagram-border-2 d-flex">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div class="flow-diagram-section d-flex">
                                <div class="flow-diagram-content content-4 me-3">
                                    <div class="flow-diagram-title">事業継続のための応急・復旧対策</div>
                                    <div class="flow-diagram-menu">
                                        <div class="btn flow-diagram-item">{{ callReplaceWord('顧客') }}・協力会社向け対策</div>
                                        <div class="btn flow-diagram-item">{{ callReplaceWord('社員') }}・{{ callReplaceWord('事業') }}資源対策</div>
                                        <div class="btn flow-diagram-item">財務対策</div>
                                    </div>
                                </div>
                            </div>
                            <div class="flow-diagram-border">
                                <div class="flow-diagram-border-1 d-flex">
                                    <div></div>
                                    <div class="border-b"></div>
                                    <div class="border-b"></div>
                                </div>
                                <div class="flow-diagram-border-2 d-flex">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div class="flow-diagram-section d-flex">
                                <div class="flow-diagram-content content-5 me-3">災害復興・公的支援制度</div>
                            </div>

                        </div>
                        <div class="flow-diagram-row-4">
                            <div class="flow-diagram-border"></div>
                            <div class="katudou border-bottom-0">地域貢献活動</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom">&emsp;</div>
                            <div class="katudou-bottom last">&emsp;</div>
                            <div class="flow-diagram-border"></div>
                        </div>
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K7';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K9';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.flow-diagram-item:hover {
    color: var(--bs-btn-color);
}

@media (max-width: 767px) {
    .scale-half {
        transform: translate(0, -120px) scale(0.5, 0.5);
        height: 500px
        // transform: translate(0, 50px);
    }

    #flow_card {
        height: 500px;
    }
    #flow_card_body {
        height: 500px;
    }
    //     height: 1600px;
    //     // height: auto;
    // }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }
}
</style>

<style lang="css" scoped>
.flow-diagram-row-1 {
    width: 160px;
    text-align: center;
}

.flow-diagram-row-1 .criterion {
    height: 38.5px;
    line-height: 38.5px;
    border: 1px #404040 solid;
}

.flow-diagram-row-1 .timetable {
    width: 80px;
    margin: auto;
}

.flow-diagram-row-1 .timetable div {
    padding: 10px 0;
}


.flow-diagram-row-1 .timetable div:nth-child(1) {
    background-color: #404040;
    color: #fff;
    height: 524px;
}

.flow-diagram-row-1 .timetable div:nth-child(2) {
    background-color: #808080;
    color: #fff;
    height: 111px;
}

.flow-diagram-row-1 .timetable div:nth-child(3) {
    background-color: #A6A6A6;
    color: #fff;
    height: 111px;
}

.flow-diagram-row-1 .timetable div:nth-child(4) {
    background-color: #BFBFBF;
    height: 74px;
}

.flow-diagram-row-2 {
    padding-left: 20px;
    padding-right: 20px;
    width: 100px;
}

.flow-diagram-row-2 div {
    height: 180px;
    background-color: #FF6600;
    color: #fff;
    border: 1px #404040 solid;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    letter-spacing: 0.4em;
    padding: 20px 10px;
    margin-top: 586px;
}

.flow-diagram-row-2 {
    height: 100%;
}

.flow-diagram-row-3 {
    text-align: center;
    margin-top: 40px;
}

.flow-diagram-item {
    cursor: auto;
    text-align: start;
}

.flow-diagram-row-3 .flow-diagram-content {
    width: 400px;
}

.flow-diagram-row-3 .content-1 {
    height: 44px;
    background-color: #c00000;
    color: #fff;
    border: 1px #404040 solid;
    padding: 10px;
}

.flow-diagram-row-3 .content-5 {
    height: 44px;
    background-color: #A9D08E;
    border: 1px #404040 solid;
    padding: 10px;
}

.flow-diagram-border-1 div:nth-child(1),
.flow-diagram-border-2 div:nth-child(1) {
    border-right: 1px #909aaf solid;
    display: flex !important;
}

.flow-diagram-border-1 .border-b {
    border-bottom: 1px #909aaf solid;
}

.flow-diagram-border div div {
    width: 200px;
    height: 15px;
}

.flow-diagram-border div div:nth-child(3) {
    width: 100px;
    height: 15px;
}

.flow-diagram-row-3 .flow-diagram-title {
    background-color: #BFBFBF;
    border-bottom: 1px #404040 solid;
    padding: 10px;
}

.flow-diagram-row-3 .flow-diagram-menu .flow-diagram-item {
    border: 1px #404040 solid;
}

.flow-diagram-row-3 .content-2 {
    height: 222px;
    background-color: #FDCFEA;
    border: 1px #404040 solid;
}

.flow-diagram-row-3 .content-2 .flow-diagram-item {
    background-color: #FB9FD6;
}

.flow-diagram-row-3 .content-3 {
    height: 168px;
    background-color: #FFF2CC;
    border: 1px #404040 solid;
}

.flow-diagram-row-3 .content-3 .flow-diagram-item {
    background-color: #F6C700;
}

.flow-diagram-row-3 .content-4 {
    height: 222px;
    background-color: #D0D4F8;
    border: 1px #404040 solid;
}

.flow-diagram-row-3 .content-4 .flow-diagram-item {
    background-color: #8A94EE;
}

.flow-diagram-row-3 .flow-diagram-menu {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding: 16px;
}

.flow-diagram-menu button {
    width: 100%;
}

.flow-diagram-row-3 .flow-diagram-section-hint {
    margin-top: 60px;
}

.flow-diagram-row-3 .flow-diagram-section .flow-diagram-section-hint div:last-of-type .flow-diagram-item {
    margin-bottom: 0px;
}


.flow-diagram-row-3 .flow-diagram-section-hint .flow-diagram-item {
    margin-bottom: 24px;
}


.flow-diagram-row-4 {
    padding-top: 350px;
}

.flow-diagram-row-4 .flow-diagram-border {
    height: 20px;
    width: 22px;
    border-right: 1px #909aaf solid;
}

.flow-diagram-row-4 .flow-diagram-border:nth-child(1) {
    border-top: 1px #909aaf solid;
}

.flow-diagram-row-4 .flow-diagram-border:last-child {
    border-bottom: 1px #909aaf solid;
}

.flow-diagram-row-4 .katudou {
    height: 340px;
    background-color: #5B9BD5;
    border: 1px #404040 solid;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    letter-spacing: 0.4em;
    padding: 142px 10px 0px;
}

.flow-diagram-row-4 .katudou-bottom {
    height: 1px;
    background-color: #5B9BD5;
    border-right: 1px #404040 solid;
    border-left: 1px #404040 solid;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    letter-spacing: 0.4em;
    padding: 0px 10px;
}

.flow-diagram-row-4 .katudou-bottom.last {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #404040;
}

.min-width-800 {
    min-width: 800px;
}

</style>
