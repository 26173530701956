<template>
    <div id="confirm-modal" v-show="modalConfirmVisible">
        <div id="content">
            <div class="btn-close" @click="closeConfirm"></div>
            <label>BCPを次版（第{{ bcp_version_info.version }}版）として保存し、{{ callReplaceWord('従業員') }}が閲覧できるようなります。<br />よろしいですか？</label>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-bcp-introduction" @click="viewSelect">次版として確定、閲覧を開始する</button>
            </div>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-new-window-button" @click="closeConfirm">キャンセル</button>
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="modalSelectPageVisible">
        <div id="content">
            <label>次版（第{{ bcp_version_info.version }}版）データを確定しました。閲覧ページからご確認ください。</label>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-bcp-restrictions" @click="showEtsuran">BCP閲覧ページへ</button>
            </div>
            <div class="row mt-3 ms-auto">
                <button class="btn btn-new-window-button" @click="showContents">目次構成へ戻る</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜終了
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 position-relative" id="complete_message">
        <div class="position-absolute speech_bubble">おつかれさまでした。<span class="fw-bold font-color-middle">中級コース</span>のBCP策定が終了しました。<br />これを「第{{ bcp_version_info.version }}版」として確定し、{{ callReplaceWord('従業員') }}が閲覧できるようにするか、<br />そのまま<span class="fw-bold font-color-advanced">上級コース</span>へ進むかご選択ください。</div>
        <i id="chatbot-A" class="position-absolute"></i>
    </div>

    <div class="row mt-3">
        <div class="">
            <label class="border-black width-600 px-3 py-2">策定し終えたBCPを第{{ bcp_version_info.version }}版として確定し、閲覧に供します。</label>
            <label class="mx-3 my-2">⇨</label>
            <input type="button" class="btn btn-new-window-button width-400 py-2" :value="'策定し終えたBCPを第' + bcp_version_info.version + '版として確定する'" :disabled="isProcessing" @click="viewConfirm" />
        </div>
    </div>
    <div class="row mt-3">
        <div class="">
            <label class="border-black width-600 px-3 py-2"><span class="fw-bold font-color-advanced">上級コース</span>へ進むことができます。</label>
            <label class="mx-3 my-2">⇨</label>
            <input type="button" class="btn btn-bcp-advanced py-2" value="上級｜2. BCPの運用体制｜複数企業連携によるBCPの策定・運用体制へ進む" :disabled="isProcessing" @click="showAdvanced"/>
        </div>
    </div>
    <div class="row mt-3 pb-5">
        <div class="">
            <label class="border-black width-600 px-3 py-2"><span class="fw-bold font-color-middle">中級コース</span>BCPをもとに、<span class="fw-bold">訓練計画</span>を立てることができます。</label>
            <label class="mx-3 my-2">⇨</label>
            <input type="button" class="btn btn-new-window-button py-2" value="BCP訓練計画登録 へ" :disabled="isProcessing" @click="showTrainingPlan"/>
        </div>
    </div>


</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001000');
        const isProcessing = ref(false);
        const modalConfirmVisible = ref(false);
        const modalSelectPageVisible = ref(false);
        let isVersionCompleted = true;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showAdvanced = async () => {
            isVersionCompleted = false;
            // BCP中級コースを確定にする（版数は上げない）
            const result = await registerMiddleComplete(isVersionCompleted);

            if (result == '200') {
                const url = '/bcp/bcp_multiple/1';
                location.href = url;
            }
        }

        const showTrainingPlan = async () => {
            isVersionCompleted = false;
            // BCP中級コースを確定にする（版数は上げない）
            const result = await registerMiddleComplete(isVersionCompleted);

            if (result == '200') {
                // TODO 一旦仮
                const url = '/bcp/bcp_financial/home';
                location.href = url;
            }
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showEtsuran = () => {
            // 閲覧ページへ遷移
            const url = '/browse/home';
            location.href = url;
        }

        const showContents = () => {
            // 目次ページへ遷移
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        // 確認モーダルの表示
        const viewConfirm = () => {
            modalConfirmVisible.value = true;
            modalSelectPageVisible.value = false;
        }

        // 確認モーダルを閉じる
        const closeConfirm = () => {
            modalConfirmVisible.value = false;
            modalSelectPageVisible.value = false;
        }

        // 画面選択モーダルの表示
        const viewSelect = async () => {
            // 確認モーダルを閉じる
            modalConfirmVisible.value = false;
            isVersionCompleted = true;

            // BCP中級コースを確定にする（版数も上げる）
            const result = await registerMiddleComplete(isVersionCompleted);

            if (result == '200') {
                // 次のモーダル表示
                modalSelectPageVisible.value = true;
            }
        }

        // 中級コース登録
        const registerMiddleComplete = async isVersionCompleted => {
            const postUrl = '/api/bcp/registerMiddleComplete';
            const form =[
                {
                    version: props.bcp_version_info.version,
                    create_date: new Date().toLocaleDateString(),
                    is_completed: isVersionCompleted,
                }
            ];
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'bcp_id': props.bcp_version_info.bcp_id,
                'form_list': form,
            }).then(res => {
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            modalConfirmVisible,
            modalSelectPageVisible,
            callReplaceWord,
            showAdvanced,
            showTrainingPlan,
            showBack,
            showEtsuran,
            showContents,
            viewConfirm,
            closeConfirm,
            viewSelect,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_version_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
.speech_bubble{
    position:relative;
    width:600px;
    height:100px;
    background:#EEEEEE;
    padding:10px;
    text-align:left;
    border:1px solid #9F9F9F;
    color:#666666;
    font-size:15px;
    border-radius:8px;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    top: 40px;
    left: 30px;
}
.speech_bubble:after,.speech_bubble:before{
    border: solid transparent;
    content:'';
    height:0;
    width:0;
    pointer-events:none;
    position:absolute;
    left:100%;
    top:50%;
}
.speech_bubble:after{
    border-color: rgba(238, 238, 238, 0);
    border-top-width:10px;
    border-bottom-width:10px;
    border-left-width:20px;
    border-right-width:20px;
    margin-top: -10px;
    border-left-color:#EEEEEE;
}
.speech_bubble:before{
    border-color: rgba(159, 159, 159, 0);
    border-top-width:11px;
    border-bottom-width:11px;
    border-left-width:22px;
    border-right-width:22px;
    margin-top: -11px;
    margin-left: 1px;
    border-left-color:#9F9F9F;
}

#chatbot-A {
    display: inline-block;
    width: 90px;
    height: 151px;
    background-image: url('../../../../svg/09_A.svg');
    background-size: 90px auto;
    background-repeat: no-repeat;
    top: 20px;
    left: 650px;
}

#complete_message {
    height: 200px;
}

.width-600 {
    width: 600px;
}

.width-400 {
    width: 400px;
}

.font-color-basic {
    color: #4472c4;
}

.font-color-middle {
    color: #2353a4;
}

.font-color-advanced {
    color: #002060;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 30%;
    padding: 1em 3em;
    background: #fff;
}

.btn-close {
    position: relative;
    top: 0px;
    left: 490px;
}

.btn-close:hover{
    cursor: pointer;
}
</style>

