<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            内部監査人による点検
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-1 mt-3 d-flex flex-row">
        <div class="col-md-2 align-items-center justify-content-center">
            BCP策定・運用状況の診断
        </div>
        <div class="col-md-2 bg-color-middle d-flex align-items-center justify-content-center">
            中級コース
        </div>
        <div class="col-md-2 ms-3 bg-color-advanced d-flex align-items-center justify-content-center">
            上級コース
        </div>
    </div>

    <div class="row ms-1 mt-3 ">
        <div class="col-md-11 d-flex flex-row">
            <div class="p2 col-md border d-flex align-items-center justify-content-center bg-light-gray py-2">
                はいの数
            </div>
            <div class="p2 col-md border d-flex align-items-center justify-content-center py-2" >
                {{ yes_count }}
            </div>
            <div class="p2 col-md ms-5 border d-flex align-items-center justify-content-center bg-light-gray py-2">
                いいえの数
            </div>
            <div class="p2 col-md border d-flex align-items-center justify-content-center py-2" >
                {{ no_count }}
            </div>
            <div class="p2 col-md ms-5 border d-flex align-items-center justify-content-center bg-light-gray py-2">
                不明の数
            </div>
            <div class="p2 col-md border d-flex align-items-center justify-content-center py-2" >
                {{ unknown_count }}
            </div>
        </div>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md-11">
            この自己診断は、あなたの会社のBCP運用状況をチェックするためのものですが、「はい」の数の合計により「合格」「不合格」を判定することが目的ではなく、「はい」にチェックが付けられなかった項目を把握して、その部分の対応について、今後もう少し力を入れて取り組む必要があるということを認識することが本来の目的です。ですから、「はい」の数を深く気にすることなく、「いいえ」にチェックが付いた項目を一つずつ減らしていくように努力してください。
        </div>
    </div>

    <div class="row ms-1 mt-5">
        <div class="col-md-11">
            <table class="table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray">いいえ、不明の項目</td>
                    </tr>
                    <tr v-for="item, index in no_unknown_item_list" :key="index">
                        <td>{{ item }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-5">
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" @click="backMenu">
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const backMenu = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,
            backMenu,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        yes_count: {
            type: Number,
        },
        no_count: {
            type: Number,
        },
        unknown_count: {
            type: Number,
        },
        no_unknown_item_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-color-middle {
    background-color: #2353a4;
    color: white;
}
.bg-color-advanced {
    background-color: #002060;
    color: white;
}
</style>
