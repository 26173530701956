<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            訓練計画登録
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />
    <div class="row">
        <div class="row col-md-11">
            <template v-for="planInfo, index in trainingPlanInfo" :key="index">
                <div class="row ms-3 border border-dark border-bottom-0 bg-light-gray" :id="`list-${planInfo.trainingPlanId}`">
                    <div class="p2 col-md-12 d-flex align-items-center justify-content-start py-2">
                        &emsp;
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="p2 ms-4 col-md-1 py-2"></div>
                    <div class="p2 ms-4 col-md-10 text-dark d-flex align-items-center justify-content-start">
                        訓練シナリオの名称・テーマ
                    </div>
                    <div class="p2 ms-4 col-md-1 border border-dark bg-light-gray text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                        {{ index + 1 }}
                    </div>
                    <div class="p2 ms-4 col-md-8 d-flex align-items-center justify-content-start py-2">
                        <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="planInfo.trainingName">
                    </div>
                    <div class="p2 ms-1 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.theme }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="p2 ms-3 mt-3 col-md-10 text-dark d-flex align-items-center justify-content-start">
                        訓練の目的
                    </div>
                    <div class="p2 ms-3 col-md-10 d-flex align-items-center justify-content-start py-2">
                        <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="planInfo.trainingTarget">
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-4 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        参加者
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-3 col-md-5 text-dark d-flex align-items-center justify-content-start">
                        {{ callReplaceWord('自社') }}部署
                    </div>
                    <div class="ms-4 mt-3 col-md-5 text-dark d-flex align-items-center justify-content-start">
                        外部組織
                    </div>
                    <div class="p2 mt-3 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.participant }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="list ms-3 d-flex">
                        <div class="col-md-5 align-items-center justify-content-start">
                            <template v-for="department, depIndex in planInfo.ownCompanyDepartment" :key="depIndex">
                                <div class="align-items-center justify-content-start d-flex">
                                    <div class="col-md-9 border border-dark bg-white text-dark d-flex align-items-center justify-content-center"
                                        :class="{'border-bottom-0': depIndex !== planInfo.ownCompanyDepartment.length - 1}"
                                    >
                                        <select name="selectDepartment" class="form-select form-control"
                                            v-model="department.participant"
                                        >
                                            <option type="text"></option>
                                            <option type="text" v-for="item in corp_department" :key="item" :value="item.部署">
                                                {{ item.部署 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="ms-2 d-flex justify-content-end" v-if="depIndex === planInfo.ownCompanyDepartment.length - 1">
                                        <input type="button" class="btn btn-black" value="マスを増やす" :disabled="isProcessing" @click="addLine(index, ownCompanyDepartmentType)">
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="ms-4 col-md-5 align-items-center justify-content-start">
                            <template v-for="external, extIndex in planInfo.externalOrganization" :key="extIndex">
                                <div class="align-items-center justify-content-start d-flex">
                                    <div class="col-md-9 border border-dark bg-white text-dark d-flex align-items-center justify-content-center"
                                        :class="{'border-bottom-0': extIndex !== planInfo.externalOrganization.length - 1}"
                                    >
                                        <select name="selectExternal" class="form-select form-control"
                                            v-model="external.participant"
                                        >
                                            <option type="text"></option>
                                            <option type="text" v-for="item in external_organization_list" :key="item" :value="item">
                                                {{ item.ext_org_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="ms-2 d-flex justify-content-end" v-if="extIndex === planInfo.externalOrganization.length - 1">
                                        <input type="button" class="btn btn-black" value="マスを増やす" :disabled="isProcessing" @click="addLine(index, externalOrganizationType)">
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-5 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        訓練の方法
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="row ms-4 col-md-10">
                        <template v-for="method, methodIndex in trainingMethodList" :key="method.id">
                            <div class="mt-3 col-md-5 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                                <input type="radio" :id="`customRadioInline${index}${method.id}`" :name="`customRadioInline${index}`"
                                    class="custom-control-input"
                                    :value="method.id" v-model="planInfo.trainingMethod"
                                >
                                <label class="custom-control-label" :for="`customRadioInline${index}${method.id}`">&nbsp;{{ method.methodName }}</label>
                            </div>
                            <div class="mt-3 col-md-1 d-flex align-items-start justify-content-start" v-if="methodIndex !== 7">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>{{ hintContents.trainingMethod[methodIndex] }}</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </template>
                        <div class="ms-3 col-md-5 d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                            <input type="text" class="form-control" placeholder="" v-model="planInfo.trainingMethodOther">
                        </div>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="p2 ms-4 mt-5 col-md-1 border border-danger bg-light-gray text-danger d-flex align-items-center justify-content-start">
                        災害想定
                    </div>
                    <div class="p2 mt-5 col-md-7 border border-danger bg-disaster text-white d-flex align-items-center justify-content-start">
                        <select name="selectDisasterAssumption" class="form-select bg-disaster form-control text-white py-2" v-model="planInfo.disasterAssumption">
                            <option type="text"></option>
                            <option type="text" v-for="disasterAssumption in disaster_assumption_list" :key="disasterAssumption" :value="disasterAssumption.disaster_assumption_content" >
                                {{ disasterAssumption.disaster_assumption_content }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-5 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.disasterAssumption }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-5 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        影響を受ける中核事業
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-2 mt-3 col-md-8 d-flex align-items-center justify-content-start">
                        <select name="selectCoreBusiness" class="form-select form-control bg-blue text-white py-2" v-model="planInfo.coreBusiness">
                            <option type="text"></option>
                            <option type="text" v-for="coreBusiness in core_business_list" :key="coreBusiness" :value="coreBusiness.core_business_content" >
                                {{ coreBusiness.core_business_content }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-3 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.coreBusiness }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 mt-5 col-md-3 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                        影響を受ける重要業務
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-2 mt-3 col-md-8 d-flex align-items-center justify-content-start">
                        <select name="selectImportantBusiness" class="form-select form-control bg-orange text-white py-2"
                            v-model="planInfo.importantBusiness"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="importantBusiness in important_business_list" :key="importantBusiness" :value="importantBusiness.important_business_content">
                                {{ importantBusiness.important_business_content }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-3 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.importantBusiness }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-3 mt-5 col-md-2 text-dark d-flex align-items-center justify-content-start">
                        発生の曜日
                    </div>
                    <div class="ms-3 mt-5 col-md-2 text-dark d-flex align-items-center justify-content-start">
                        発生時間
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-2 col-md-2 d-flex align-items-center justify-content-start py-2">
                        <select name="selectWeekday" class="form-select form-control"
                            v-model="planInfo.occurrenceWeekday"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in weekdayList" :key="item" :value="item.id">
                                {{ item.weekday }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                        <input type="time" min="00:00:00" class="form-control d-flex align-items-center justify-content-center" placeholder="" v-model="planInfo.occurrenceTime">
                    </div>
                    <div class="col-md-1 d-flex align-items-start justify-content-start py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.occurrenceDateTime }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-3 mt-3 col-md-3 text-dark d-flex align-items-center justify-content-start">
                        発生からの経過想定日数
                    </div>
                    <div class="ms-3 mt-3 col-md-3 text-dark d-flex align-items-center justify-content-start">
                        {{ callReplaceWord('自社') }}の被害
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-2 col-md-3 d-flex align-items-center justify-content-start py-2">
                        <select name="selectAssumptionDays" class="form-select form-control"
                            v-model="planInfo.progressAssumptionDays"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in assumptionDaysList" :key="item" :value="item.id">
                                {{ item.assumptionDays }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                        <input type="text" class="form-control d-flex align-items-center justify-content-center" placeholder="" v-model="planInfo.damage">
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-3 mt-3 col-md-10 text-dark d-flex align-items-center justify-content-start">
                        経過想定日数時の災害イメージ（ライフラインの利用可能状況）
                    </div>
                </div>
                <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        電気
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.electricity"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-3 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hintContents.availableStatus }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        通信（固定電話、携帯電話、インターネット、社内イントラネット等）
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.communication"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        ガス
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.gas"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        上下水道
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.waterworks"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        交通機関（高速道路、国道、鉄道等）
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.trafficMethod"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        小売業（金融機関、スーパー等）
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.retailBusiness"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-bottom-0 border-end-0 bg-header text-dark d-flex align-items-center py-2">
                        地域住民等
                    </div>
                    <div class="col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.regionalResident"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                    <div class="ms-4 col-md-7 border border-dark border-end-0 bg-header d-flex text-dark align-items-center py-2">
                        <div class="col-md-1 text-dark align-items-center py-2">
                            その他
                        </div>
                        <div class="ms-1 col-md-11 text-dark align-items-center py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center" placeholder="" v-model="planInfo.otherInputValue">
                        </div>
                    </div>
                    <div class="col-md-2 border border-dark text-dark bg-white d-flex align-items-center justify-content-start py-2">
                        <select name="selectAvailableStatus" class="form-select form-control"
                            v-model="planInfo.other"
                        >
                            <option type="text"></option>
                            <option type="text" v-for="item in availableStatusList" :key="item" :value="item.id">
                                {{ item.availableStatus }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row ms-3 border border-dark border-top-0 bg-light-gray text-white">
                    <div class="p2 col-md-12  d-flex align-items-center justify-content-start py-2">
                        &emsp;
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="p2 ms-3 col-md-10 d-flex align-items-start justify-content-start">
                    </div>
                    <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start" v-if="index === trainingPlanInfo.length - 1">
                        <input type="button" class="btn btn-black" value="ワンセット増やす" :disabled="isProcessing" @click="addOneSet(index + 1)">
                    </div>
                </div>
            </template>
        </div>
        <div class="ms-3 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>{{ hintContents.trainingPlan }}</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="上記、訓練計画シナリオを登録する" style="font-size:10.5pt" :disabled="isProcessing" @click="register">
        </div>
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>
    <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.training_plan_list).length > 0) {
                trainingPlanInfo.value.splice(0);
                Object.values(props.training_plan_list).forEach((fetchData, index) => {
                    // 訓練計画
                    trainingPlanInfo.value.push({
                        trainingPlanId: fetchData.training_plan_id,
                        trainingName: fetchData.training_name,
                        trainingTarget: fetchData.training_target,
                        ownCompanyDepartment: [],
                        externalOrganization: [],
                        trainingMethod: fetchData.training_method,
                        trainingMethodOther: fetchData.training_method_other,
                        disasterAssumption: fetchData.disaster_assumption,
                        coreBusiness: fetchData.core_business,
                        importantBusiness: fetchData.important_business,
                        occurrenceWeekday: fetchData.occurrence_weekday,
                        occurrenceTime: fetchData.occurrence_time,
                        progressAssumptionDays: fetchData.progress_assumption_days,
                        damage: fetchData.damage,
                        electricity: fetchData.electricity,
                        communication: fetchData.communication,
                        gas: fetchData.gas,
                        waterworks: fetchData.waterworks,
                        trafficMethod: fetchData.traffic_method,
                        retailBusiness: fetchData.retail_business,
                        regionalResident: fetchData.regional_resident,
                        other: fetchData.other,
                        otherInputValue: fetchData.other_input_value,

                    });

                    // 自社部署
                    if (typeof fetchData.own_company_department !== 'undefined') {
                        trainingPlanInfo.value[index].ownCompanyDepartment = Object.values(fetchData.own_company_department).map(
                            fetchDepartment => ({
                                participantType: ownCompanyDepartmentType,
                                participant: fetchDepartment.participant,
                            })
                        );
                        if (trainingPlanInfo.value[index].ownCompanyDepartment.length < 5) {
                            // 5つまで入力欄作成
                            for (let i = trainingPlanInfo.value[index].ownCompanyDepartment.length; i < 5; i++) {
                                trainingPlanInfo.value[index].ownCompanyDepartment.push({
                                    participantType: ownCompanyDepartmentType,
                                    participant: '',
                                });
                            }
                        }
                    } else {
                        // 初期入力欄作成
                        for (let i = 0; i < 5; i++) {
                            trainingPlanInfo.value[index].ownCompanyDepartment.push({
                                participantType: ownCompanyDepartmentType,
                                participant: '',
                            });
                        }
                    }

                    // 外部組織
                    if (typeof fetchData.external_organization !== 'undefined') {
                        trainingPlanInfo.value[index].externalOrganization = Object.values(fetchData.external_organization).map(
                            fetchExternal => ({
                                participantType: externalOrganizationType,
                                participant: {
                                    corp_id: fetchExternal.external_organization_corp_id,
                                    ext_org_name: fetchExternal.participant,
                                }
                            })
                        );
                        if (trainingPlanInfo.value[index].externalOrganization.length < 5) {
                            // 5つまで入力欄作成
                            for (let i = trainingPlanInfo.value[index].externalOrganization.length; i < 5; i++) {
                                trainingPlanInfo.value[index].externalOrganization.push({
                                    participantType: externalOrganizationType,
                                    participant: {
                                        corp_id: '',
                                        ext_org_name: '',
                                    }
                                });
                            }
                        }
                    } else {
                        // 初期入力欄作成
                        for (let i = 0; i < 5; i++) {
                            trainingPlanInfo.value[index].externalOrganization.push({
                                participantType: externalOrganizationType,
                                participant: {
                                    corp_id: '',
                                    ext_org_name: '',
                                }
                            });
                        }
                    }
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 1000);

        const setLevel = ref('001111');
        const isProcessing = ref(false);
        const ownCompanyDepartmentType = '1';
        const externalOrganizationType = '2';

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false;
                window.location.reload();
            }, 3000)
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // ヒント内容
        const hintContents = {
            theme: 'テーマとは、訓練の目的を実現するために、訓練参加者に取り組んでほしい具体的な課題です。訓練の目的に沿って、参加者に「身につけて欲しいこと」や「検討してほしいこと」を行動目標レベルに落とし込み、具体的なテーマとして設定します',
            participant: `訓練内で情報のやり取りをする架空の登場人物を設定します。主な登場人物は、危機時に連携・対応が必要となる人や組織であり、${callReplaceWord('社内')}の関係部門や業務機能や${callReplaceWord('取引先')}、${callReplaceWord('顧客')}、サプライヤー、物流会社やシステム会社等の関係会社が挙げられます`,
            trainingMethod: [
                `災害をイメージする机上訓練です。災害発生から終息までの状況を各${callReplaceWord('従業員')}に説明し、模擬的に災害を体験させます。災害対策本部のメンバーとして、与えられた役割に応じた対応を理解します。`,
                'バックアップしている電子データや書類を迅速に取り出すことができるか確認する訓練です。情報システムを利用している場合は、稼動しているシステムの被災に備えて代替システムを用意し、問題なく起動させられるかどうかも確認します。この訓練を行うためには、日頃から定期的にバックアップを行っていることが必要となります。平常時のバックアップ計画も事業継続の前提としてBCPに含めるべき項目です。',
                'シナリオに基づき課題を与え、各' + callReplaceWord('従業員') + 'は対応を検討します。課題の例として、通行予定の橋梁の落橋、資源や協力会社の被災等があります。地図等を用いて災害時の行動を確認したり、課題を洗い出します。危機発生時の状況と、それぞれの対応方法について、多人数でさまざまな視点で考えることに力点が置かれた訓練です。',
                `BCPの内容によって現場に移動して実行可能性を確認する実動訓練です。各${callReplaceWord('従業員')}が実動することにより作成したBCPの問題、課題に対する解決策を検討し、見直しにつなげます。`,
                `シナリオに基づき状況を出題していき、シナリオ内容を知らない各${callReplaceWord('従業員')}は、即座に状況判断、意思決定、業務処理等を実施し、柔軟な対応要求する訓練です。時々刻々と変化する状況を提供し、厳しい時間的制約の中で自身の役割に応じて与えられた状況に対応していくものです。詳細なシナリオ等を作成する必要があります。`,
                `BCPを発動したときと同様に${callReplaceWord('組織')}の全員と資源を動員して始めから終わりまで通して行う訓練です。ロールプレイング訓練の要素もとりいれ、${callReplaceWord('従業員')}にシナリオを知らせず訓練の中で被災状況を提示します。`,
                `緊急事態が発生した際に、速やかに${callReplaceWord('従業員')}に連絡が行くかどうかを確認する訓練です。TOTONO-Lの安否確認ツールを利用し、実際に連絡することで確認します。BCPのためだけでなく、安否確認の手段としても緊急連絡網を整備しておくことは重要です。訓練することで、連絡が漏れなく行き渡るか、連絡先が最新かをチェックできます。`,
                '',
                `バックアップの${callReplaceWord('工場')}を準備している場合に行う訓練です。復旧にあたる要員の一部を実際にバックアップの拠点に移動させ、その場所で計画通りに事業を復旧させられるかどうかを確認します。`,
            ],
            disasterAssumption: '参加者を訓練で設定した状況の中に入り込ませるために、被害想定や展開はリアリティが必要です。BCP策定基本コースで登録した災害想定がリストになっていますので選択してください。',
            coreBusiness: `訓練の中で災害時に影響を受ける中核事業です。BCP策定基本コース「3.中核事業と復旧目標 ｜中核事業に係る情報」で登録したあなたの${callReplaceWord('組織')}の存続に関わる最も重要性の高い事業です。`,
            importantBusiness: '訓練の中で、災害時に影響を受ける重要業務です。BCP策定基本コース「3.中核事業と復旧目標 ｜中核事業に係る情報（つづき）」で登録した重要業務がリストになっていますので選択してください。',
            occurrenceDateTime: '被災が就業時間中か時間外かによって、初動対応はまったく違ってきます。BCPは、発災当日に発動します。',
            availableStatus: '災害が発生したイメージを膨らませ、ライフラインの被害状況やその他の危機状態などを設定します。これが訓練の前提条件となります。状況設定にあたっては、国や地方自治体が公表している被害想定（震度分布図、ハザードマップ、新型インフルエンザの罹患率等）なども参考にしましょう。',
            trainingPlan: `BCP訓練を無理なく実行するとともに、あなたの${callReplaceWord('組織')}にBCPを定着させていくためには、BCP全体を通した訓練を初めから無理に行おうとするのではなく、現在実施している防災訓練に${callReplaceWord('事業')}継続に資する要素を追加したり、BCP発動手順の一部分を採り上げた訓練（要素訓練）を実施したりすることにより、${callReplaceWord('従業員')}に着実に習得させていくことが望ましいでしょう。`,
        }

        // 訓練の方法
        const trainingMethodList = [
            {id: 1, methodName: '机上訓練（模擬災害対策訓練）'},
            {id: 2, methodName: 'バックアップデータを取り出す訓練'},
            {id: 3, methodName: '机上訓練（ワークショップ訓練）'},
            {id: 4, methodName: 'BCP手順確認訓練'},
            {id: 5, methodName: '机上訓練（ロールプレイング訓練）'},
            {id: 6, methodName: 'BCP全体を通して行う総合訓練'},
            {id: 7, methodName: '安否確認システム操作訓練・緊急時通報診断'},
            {id: 8, methodName: 'その他'},
            {id: 9, methodName: '代替施設への移動訓練'},
        ]

        // 曜日
        const weekdayList = [
            {id: 1, weekday: '月曜日'},
            {id: 2, weekday: '火曜日'},
            {id: 3, weekday: '水曜日'},
            {id: 4, weekday: '木曜日'},
            {id: 5, weekday: '金曜日'},
            {id: 6, weekday: '土曜日'},
            {id: 0, weekday: '日曜日'},
        ]

        // 経過想定日数
        const assumptionDaysList = [
            {id: 1, assumptionDays: '発災初日'},
            {id: 2, assumptionDays: '発災後4～5日目'},
        ]

        // 利用可能状況
        const availableStatusList = [
            {id: 1, availableStatus: '使える＝◯'},
            {id: 0, availableStatus: '使えない＝✕'},
        ]

        // 訓練計画
        const trainingPlanInfo = ref([
            {
                trainingPlanId: '',
                trainingName: '震度6弱の東海地震時、自身の役割を机上訓練において確認する',
                trainingTarget: '何が起きるか想像力を働かせる。',
                ownCompanyDepartment: [],
                externalOrganization: [],
                trainingMethod: '',
                trainingMethodOther: '',
                disasterAssumption: '',
                coreBusiness: '',
                importantBusiness: '',
                occurrenceWeekday: '',
                occurrenceTime: '',
                progressAssumptionDays: '',
                damage: '本社被災',
                electricity: '',
                communication: '',
                gas: '',
                waterworks: '',
                trafficMethod: '',
                retailBusiness: '',
                regionalResident: '',
                other: '',
                otherInputValue: '',
            }
        ]);

        // 自社部署
        for (let i = 0; i < 5; i++) {
            trainingPlanInfo.value[0].ownCompanyDepartment.push({
                participantType: ownCompanyDepartmentType,
                participant: '',
            });
        }

        // 外部組織
        for (let i = 0; i < 5; i++) {
            trainingPlanInfo.value[0].externalOrganization.push({
                participantType: externalOrganizationType,
                participant: {
                    corp_id: '',
                    ext_org_name: '',
                },
            });
        }

        // 1行増やすボタン
        const addLine = (index, targetField) => {
            if (targetField === ownCompanyDepartmentType) {
                trainingPlanInfo.value[index].ownCompanyDepartment.push({
                    participantType: ownCompanyDepartmentType,
                    participant: '',
                });
            } else {
                trainingPlanInfo.value[index].externalOrganization.push({
                    participantType: externalOrganizationType,
                    participant: {
                        corp_id: '',
                        ext_org_name: '',
                    },
                });
            }
        }

        // ワンセット増やすボタン
        const addOneSet = index => {
            trainingPlanInfo.value.push({
                trainingPlanId: '',
                trainingName: '震度6弱の東海地震時、自身の役割を机上訓練において確認する',
                trainingTarget: '何が起きるか想像力を働かせる。',
                ownCompanyDepartment: [],
                externalOrganization: [],
                trainingMethod: '',
                trainingMethodOther: '',
                disasterAssumption: '',
                coreBusiness: '',
                importantBusiness: '',
                occurrenceWeekday: '',
                occurrenceTime: '',
                progressAssumptionDays: '',
                damage: '本社被災',
                electricity: '',
                communication: '',
                gas: '',
                waterworks: '',
                trafficMethod: '',
                retailBusiness: '',
                regionalResident: '',
                other: '',
                otherInputValue: '',
            });

            // 自社部署
            for (let i = 0; i < 5; i++) {
                trainingPlanInfo.value[index].ownCompanyDepartment.push({
                    participantType: ownCompanyDepartmentType,
                    participant: '',
                });
            }
            // 外部組織
            for (let i = 0; i < 5; i++) {
                trainingPlanInfo.value[index].externalOrganization.push({
                    participantType: externalOrganizationType,
                    participant: {
                        corp_id: '',
                        ext_org_name: '',
                    },

                });
            }
        }

        const showBack = async () => {
            // 訓練計画を登録
            const result = await registerB1();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const register = () => {
            // 訓練計画を登録
            registerB1();
        }

        const showTop = async () => {
            // 訓練計画を登録
            const result = await registerB1();

            if (result == '200') {
                const url = '/fixation/fixation';
                location.href = url;
            }
        }

        // 訓練計画登録
        const registerB1 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/registerB1';

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'training_plan_info': trainingPlanInfo.value,
            }).then(res => {
                visibleFlashMessage('正常に登録されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                // isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            ownCompanyDepartmentType,
            externalOrganizationType,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            callReplaceWord,
            hintContents,
            trainingMethodList,
            weekdayList,
            assumptionDaysList,
            availableStatusList,
            trainingPlanInfo,
            addLine,
            addOneSet,
            showBack,
            register,
            showTop,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        corp_department: {
            type: Array,
        },
        external_organization_list: {
            type: Array,
        },
        disaster_assumption_list: {
            type: Array,
        },
        core_business_list: {
            type: Array,
        },
        important_business_list: {
            type: Array,
        },
        training_plan_list: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4 !important;
}

.bg-orange {
    background-color: #FF6600 !important;
}

.bg-disaster {
    background-color: #c00000 !important;
}
.bg-header {
    background-color: #F2F2F2;
}

.width-half {
    max-width: 25%;
}
</style>
