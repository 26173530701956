<template>
    <div>
        <div class="bg-resilience-orange pt-3">
            <div class="container d-flex justify-content-between">
                <i id="title_image" />
                <input type="button" class="btn btn-sm btn-black align-self-start" value="前ページへ戻る" @click="showBack" />
            </div>
        </div>
        <div class="container">
            <div class="row mt-2">
                <label class="color-resilience-orange">～ どんな緊急事態に遭っても{{ callReplaceWord('組織') }}が生き抜くための準備 ～</label>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 cursor-pointer" @click="showCheck">
                    <i id="bcp_genjyoninshiki" />
                </div>
                <div class="col-md-4 cursor-pointer" @click="showSoshiki">
                    <i id="bcp_soshiki" />
                </div>
                <div class="col-md-4 cursor-pointer" @click="showKaigo">
                    <i id="bcp_kaigo" />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 cursor-pointer" @click="showBCPBrowse">
                    <i id="bcp_etsuran" />
                </div>
                <div class="col-md-4 cursor-pointer">
                    <i id="bcp_hyoka" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReplaceWord from '../../function/CommonReplaceWord';
const { getReplaceWord } = ReplaceWord();

export default {
    setup(props){
        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const backMenu = () => {
            const url = '/home';

            location.href = url;
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showCheck = () => {
            const url = '/bcp/bcp_check'
            location.href = url;
        }

        const showSoshiki = () => {
            const url = '/bcp/bcp_introduction/A';
            location.href = url;
        }

        const showKaigo = () => {
            const url = '/bcp/bcp_introduction/B';
            location.href = url;
        }

        const showBCPBrowse = () => {
            const url = '/browse/home';
            location.href = url;
        }

        return {
            callReplaceWord,
            backMenu,
            showBack,
            showCheck,
            showSoshiki,
            showKaigo,
            showBCPBrowse,
        }

    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
    #title_image {
        background-image: url('../../../svg/07-00title.svg');
        display: inline-flex;
        width: 672px;
        height: 548px;
        background-size: 672px auto;
        background-repeat: no-repeat;
    }

    #bcp_genjyoninshiki{
        background-image: url('../../../svg/bcp_button/bcp_genjyoninshiki.svg');
        display: inline-flex;
        width: 400px;
        height: 180px;
        background-size: 400px auto;
        background-repeat: no-repeat;
    }

    #bcp_soshiki{
        background-image: url('../../../svg/bcp_button/bcp_soshiki.svg');
        display: inline-flex;
        width: 400px;
        height: 180px;
        background-size: 400px auto;
        background-repeat: no-repeat;
    }

    #bcp_kaigo{
        background-image: url('../../../svg/bcp_button/bcp_kaigo.svg');
        display: inline-flex;
        width: 400px;
        height: 180px;
        background-size: 400px auto;
        background-repeat: no-repeat;
    }

    #bcp_etsuran{
        background-image: url('../../../svg/bcp_button/bcp_etsuran.svg');
        display: inline-flex;
        width: 400px;
        height: 180px;
        background-size: 400px auto;
        background-repeat: no-repeat;
    }

    #bcp_hyoka{
        background-image: url('../../../svg/bcp_button/bcp_hyoka.svg');
        display: inline-flex;
        width: 400px;
        height: 180px;
        background-size: 400px auto;
        background-repeat: no-repeat;
    }

 @media screen and (max-width: 767px){
    #title_image {
        background-image: url('../../../svg/07-00title.svg');
        display: inline-flex;
        width: 336px;
        height: 274px;
        background-size: 336px auto;
        background-repeat: no-repeat;
    }

 }
</style>
