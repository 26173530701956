<template>
    <div class="container">
        <div class="d-flex justify-content-end mt-2">
            <input type="button" class="btn btn-inquiry-button" value="前のページへ戻る" @click="historyBack" />
        </div>
        <div class="relative d-flex flex-column min-h-screen pt-2 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">クラウドとセキュリティについて</div>
        </div>
        <div class="row mx-1 mx-md-5 mt-3">
            <div class="text-break">
                当サイトは、Google Cloud を使用しています。
            </div>
            <div class="mt-3">
                <div class="color-resilience-red text-decoration-underline fw-bold">Google Cloud インフラストラクチャ</div>
                <div>Google の世界規模のインフラストラクチャで、最高レベルのパフォーマンス、可用性を安全かつ持続可能な方法で実現できます。</div>
            </div>
            <div class="mt-3">
                <div class="fw-bold">信頼性の高いグローバル プレゼンス</div>
                <div class="text-break">Google Cloud のロケーションは、Google がお客様のために行う重要な作業すべての基盤となります。冗長な Cloud リージョンから海底ケーブルを介した高帯域幅接続まで、Google のインフラストラクチャのすべてが、世界中のどこにいるユーザーにも貴社のサービスを提供できるように設計されています。</div>
            </div>
            <div class="mt-3">
                <div class="fw-bold">安全かつ効率的なデータセンター</div>
                <div class="text-break">Google Cloud のデータセンターのグローバル ネットワークは、世界で最も安全でエネルギー効率の高い施設の一つから、最大級の速度、信頼性で24時間365日のサービスを提供しています。データセンターでは、階層化されたセキュリティ、組み込み済みの冗長性、フォールト トレランスが導入されており、従業員のアクセスは厳しく制限されています。</div>
            </div>
            <div class="mt-3">
                <div class="fw-bold">高速かつ信頼性の高いグローバル ネットワーク</div>
                <div class="text-break">高度にプロビジョニングされた低レイテンシ ネットワーク（Gmail、Google 検索、YouTube などのプロダクトを支えるネットワークと同じ）により、トラフィックのほとんどが Google のプライベート バックボーンに留まります。比類なきユーザー エクスペリエンス、高いパフォーマンスを実現できます。</div>
            </div>
            <div class="mt-3">
                <div class="fw-bold">多層的なセキュリティ</div>
                <div class="text-break">多層防御を実現するプログレッシブ インフラストラクチャ レイヤでデータを保護します。Google Cloud はデータ プライバシーとセキュリティの基準を遵守しており、インフラストラクチャと運用がユーザーデータの安全性とコンプライアンスを維持していることを証明するサードパーティの監査員からの信頼を得ています。</div>
            </div>
            <div class="mt-3">
                <div class="fw-bold">高可用性を実現する設計</div>
                <div class="text-break">Google のデータセンターとネットワーク アーキテクチャは、信頼性、稼働時間を最大化するように設計されています。ワークロードは複数のリージョン、可用性ゾーン、拠点、ネットワーク ケーブルに安全に分散されます。強力な組み込みの冗長性、アプリケーションの可用性を実現できます。</div>
            </div>
            <div class="my-3">
                <div class="fw-bold">持続性が組み込み済み</div>
                <div class="text-break">Google は、グローバル事業で消費されるエネルギーの100%を再生可能エネルギーの購入で賄っているため、使用されるすべての Google Cloud プロダクトの正味炭素排出量はゼロです。また、極めて効率性の高い Google のデータセンターでは、他のほとんどのシステムよりもエネルギー消費量が50%少なくなります。持続可能性に対する Google の取り組みの詳細をご覧ください。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const historyBack = () =>{
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        };
        return {
            historyBack,
        }
    },
}
</script>