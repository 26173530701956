<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row">
                <div class="p2 col-md">
                    <label class="form-label">2. BCPの運用体制</label>
                    <label class="ms-3 bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式4</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex justify-content-start">
                    <label class="form-label" style="font-size:12pt">緊急時の統括責任者</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-12 d-flex justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ 地震等の災害発生により、緊急事態となった際の統括責任者及び代理責任者は以下のとおりとする。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex justify-content-start">
                    <label class="form-label">統括責任者の役割</label>
                </div>
                <div class="p2 col-md-12 d-flex justify-content-start">
                    <div class="form-control d-flex py-2 role-content">{{ textForms[0].value || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <label class="form-label">統括責任者 氏名</label>
                </div>
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <label class="form-label">役職</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms[0].value || '&emsp;' }}</div>
                </div>
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms[1].value || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <label class="form-label">代理責任者 氏名</label>
                </div>
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <label class="form-label">役職</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms[2].value || '&emsp;' }}</div>
                </div>
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms[3].value || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms[4].value || '&emsp;' }}</div>
                </div>
                <div class="p2 col-md-5 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms[5].value || '&emsp;' }}</div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                textForms.forEach(initData => {
                    props.bcp_introduction_texts_info.forEach(fetchData => {
                        if (initData.columnName === fetchData.column_name) {
                            initData.value = fetchData.text_value;
                        }
                    });
                })
            }

            if (props.bcp_introduction_values_info.length > 0) {
                valueForms.forEach(initData => {
                    const data = props.bcp_introduction_values_info.find(
                        (fetchData) =>
                            fetchData.column_name === initData.columnName &&
                            fetchData.seq === initData.seq
                    );
                    if (data) {
                        initData.value = data.value;
                    }
                });
            }
        });

        const setLevel = ref('100000');
        const isMargin1 = ref(true);

        const textForms = reactive([
            {
                columnName: 'N00012',
                value: '',
            },
        ]);

        const valueForms = reactive([
            {
                columnName: 'N00013',
                seq: 1,
                value: '',
            },
            {
                columnName: 'N00013',
                seq: 2,
                value: '',
            },
            {
                columnName: 'N00014',
                seq: 1,
                value: '',
            },
            {
                columnName: 'N00014',
                seq: 2,
                value: '',
            },
            {
                columnName: 'N00015',
                seq: 1,
                value: '',
            },
            {
                columnName: 'N00015',
                seq: 2,
                value: '',
            },
        ]);

        const showBack = () => {
            const url = "/browse/N1";
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/N3';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            textForms,
            valueForms,
            showBack,
            showNext,
        }
    },
    props:{
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.role-content {
    min-height: 7.5em;
}

@media (max-width: 767px) {
    .col-md-5 {
        width: 41.66666667%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }
}
</style>
