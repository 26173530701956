<template>
    <div class="ms-md-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row">
                <div class="p2 col-md">
                    <label class="form-label">4. BCP発動フロー</label>
                    <label class="ms-3 bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式4</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">○ 統括責任者が意思決定及び指揮命令すべき全社の対応の例　</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-12 d-flex align-items-center justify-content-start">
                    <label class="form-label">当日～（初動対応）</label>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row mt-3 ms-1">
                        <div class="col-6 col-md-8">
                            <div class="row">
                                <div class="col col-md-4 border d-flex align-items-center justify-content-center">
                                    <label class="form-label">初動対応の内容例</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 col-md-2 border d-flex align-items-center justify-content-center adjust-print ms-3">
                            <label class="form-label">他所との連携の有無</label>
                        </div>
                    </div>

                    <div class="row mt-3" v-for="(item, index) in valueForms['1']">
                        <div class="col-6 col-md-8 d-flex align-items-center justify-content-start adjust-print">
                            <div class="form-control">{{ item[1] || '&emsp;' }}</div>
                        </div>

                        <div class="col-6 col-md-2 d-flex align-items-center justify-content-evenly adjust-print">
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" :name="'inlineRadioOptions1-' + index" id="inlineRadio1" value="〇" onclick="return false;" v-model="item[2]">
                                <label for="inlineRadio1" class="form-check-label ms-1">○</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" :name="'inlineRadioOptions1-' + index" id="inlineRadio2" value="×" onclick="return false;" v-model="item[2]">
                                <label for="inlineRadio2" class="form-check-label ms-1">×</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-12 d-flex align-items-center justify-content-start">
                    <label class="form-label">数日～（復旧に向けた対応）</label>
                </div>
            </div>

            <div class="card">
                <div class="card-body">

                    <div class="row mt-3 ms-1">
                        <div class="col-6 col-md-8">
                            <div class="row">
                                <div class="col col-md-4 border d-flex align-items-center justify-content-center">
                                    <label class="form-label">初動対応の内容例</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 col-md-2 border d-flex align-items-center justify-content-center adjust-print ms-3">
                            <label class="form-label">他所との連携の有無</label>
                        </div>
                    </div>
                    <div class="row mt-3" v-for="(item, index) in valueForms['2']">
                        <div class="col-6 col-md-8 d-flex align-items-center justify-content-start adjust-print">
                            <div class="form-control">{{ item[1] || '&emsp;' }}</div>
                        </div>

                        <div class="col-6 col-md-2 d-flex align-items-center justify-content-evenly adjust-print">
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" :name="'inlineRadioOptions2-' + index" id="inlineRadio1" value="〇" onclick="return false;" v-model="item[2]">
                                <label for="inlineRadio1" class="form-check-label ms-1">○</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" :name="'inlineRadioOptions2-' + index" id="inlineRadio2" value="×" onclick="return false;" v-model="item[2]">
                                <label for="inlineRadio2" class="form-check-label ms-1">×</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_values_info.length > 0) {
                Object.keys(valueForms).forEach(key => {
                    const re = new RegExp(`^N\\d*-${key}$`);
                    const data = props.bcp_introduction_values_info
                        .filter(
                            (fetchData) => re.test(fetchData.column_name)
                        )
                        .sort((a, b) => {
                            const column1 = a.column_name.replace('N', '').replace(/\-\d/, '');
                            const column2 = b.column_name.replace('N', '').replace(/\-\d/, '');

                            if (column1 === column2) {
                                return a.seq < b.seq ? -1 : 1;
                            }
                            return Number(column1) < Number(column2) ? -1 : 1;
                        });
                    const uniqueList = data.reduce((array, item) => {
                        const key = item.column_name;

                        return array.some((k) => k === key)
                            ? array
                            : array.concat(key);
                    }, []);

                    valueForms[key] = uniqueList.map((key) => {
                        const filteredData = data.filter((d) => d.column_name === key);
                        const obj = {};
                        filteredData.forEach((d) => obj[d.seq] = d.value)
                        return obj;
                    });
                });
            }
        });

        const setLevel = ref('100000');
        const isMargin1 = ref(true);

        const valueForms = reactive({
            1: [[{}]],
            2: [[{}]],
        });

        const showBack = () => {
            const url = "/browse/N3";
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/N5';
            location.href = url;
        }

        const createArray = (number) => {
            return [...Array(number)];
        }

        return {
            setLevel,
            isMargin1,
            valueForms,
            showBack,
            showNext,
            createArray,
        }
    },
    props:{
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
