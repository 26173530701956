<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">2. BCPの運用体制</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap text-nowrap">様式03</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 当社において、BCP（事業継続計画）を策定する体制、平常時にBCPの運用を推進する体制、及び緊急時にBCPを発動し継続対策を推進する体制は以下のとおりである。</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 各責任者は、経営者自らがあたるべきである。なお、サブリーダー、緊急時の体制におけるそれぞれの代行者について、以下のように定めることとする。</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ BCPの策定体制</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">①　責任者</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms['K00012'].value || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">②　サブリーダー</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('顧客') }}・協力会社担当（外部対応）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00013'].value || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">事業資源担当（復旧対応）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00014'].value || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">財務担当（財務管理）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00015'].value || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">従業員支援担当（後方支援）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00016'].value || '&emsp;' }}</div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 平常時におけるBCPの運用推進体制</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">①　責任者</label>
                </div>
            </div>
            <div class="row ms-1">
                <div v-for="item in valueForms['K00017'].values" class="p2 col-md-3 d-flex justify-content-start">
                    <div class="form-control">{{ item }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">②　サブリーダー</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('顧客') }}・協力会社担当（外部対応）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00018'].value || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">事業資源担当（復旧対応）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00019'].value || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">財務担当（財務管理）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00020'].value || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">従業員支援担当（後方支援）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00021'].value || '&emsp;' }}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">③　連携する{{ callReplaceWord('取引先') }}{{ callReplaceWord('組織') }}や協力{{ callReplaceWord('組織') }}</label>
                </div>
            </div>
            <div class="row ms-1">
                <div v-for="item in valueForms['K00022'].values" class="p2 col-md-4 d-flex justify-content-start">
                    <div class="form-control">{{ item }}</div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">④　一緒に取組む組合等の組織</label>
                </div>
            </div>
            <div class="row ms-1">
                <div v-for="item in valueForms['K00023'].values" class="p2 col-md-4 d-flex justify-content-start">
                    <div class="form-control">{{ item }}</div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label">⑤　BCP運用の対象者</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-4 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms['K00024'].value || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 緊急時におけるBCPの発動体制</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">①　責任者</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms['K00025'][1] || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">代行者</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 d-flex justify-content-start">
                    <div class="form-control">{{ valueForms['K00025'][2] || '&emsp;' }}</div>
                </div>
            </div>


            <div class="row mt-3 ms-1">
                <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">②　サブリーダー</label>
                </div>
            </div>
            <div class="row mt-3 ms-1 pb-5">

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('顧客') }}・協力会社担当（外部対応）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00026'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 d-flex align-items-center justify-content-start mt-md-3">
                        <label class="form-label">{{ callReplaceWord('顧客') }}・協力会社担当（外部対応）代行者</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00026'][2] || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">事業資源担当（復旧対応）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00027'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 d-flex align-items-center justify-content-start mt-md-3">
                        <label class="form-label">事業資源担当（復旧対応）代行者</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00027'][2] || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">財務担当（財務管理）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00028'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 d-flex align-items-center justify-content-start mt-md-3">
                        <label class="form-label">財務担当（財務管理）代行者</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00028'][2] || '&emsp;' }}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="p2 d-flex align-items-center justify-content-start">
                        <label class="form-label">従業員支援担当（後方支援）</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00029'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 d-flex align-items-center justify-content-start mt-md-3">
                        <label class="form-label">従業員支援担当（後方支援）代行者</label>
                    </div>
                    <div class="p2 d-flex justify-content-start">
                        <div class="form-control">{{ valueForms['K00029'][2] || '&emsp;' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_values_info.length > 0) {
                Object.keys(valueForms).forEach(columnName => {
                    if (Object.keys(valueForms[columnName]).includes('value')) {
                        valueForms[columnName].value = props.bcp_values_info.find(
                            (fetchData) => fetchData.column_name === columnName
                        )?.value;
                    } else {
                        const data = props.bcp_values_info
                            .filter(
                                (fetchData) => fetchData.column_name === columnName
                            )
                            .sort((a, b) => (a.seq < b.seq ? -1 : 1));

                        if (valueForms[columnName].hasOwnProperty('values')) {
                            valueForms[columnName].values = data.map((d) => d.value);
                        } else {
                            data.forEach(
                                (d) => (valueForms[columnName][d.seq] = d.value)
                            );
                        }
                    }
                });
            }
        });

        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms = reactive({
            K00012: {
                value: '',
            },
            K00013: {
                value: '',
            },
            K00014: {
                value: '',
            },
            K00015: {
                value: '',
            },
            K00016: {
                value: '',
            },
            K00017: {
                values: [],
            },
            K00018: {
                value: '',
            },
            K00019: {
                value: '',
            },
            K00020: {
                value: '',
            },
            K00021: {
                value: '',
            },
            K00022: {
                values: [],
            },
            K00023: {
                values: [],
            },
            K00024: {
                value: '',
            },
            K00025: {},
            K00026: {},
            K00027: {},
            K00028: {},
            K00029: {},
        });

        const showBack = () => {
            const url = '/browse/K1';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K3';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            valueForms,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }
}
</style>
