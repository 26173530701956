<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">3. 中核{{ callReplaceWord('事業') }}と復旧目標 </label>
                    <span class="ms-3 bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap text-nowrap">様式07</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">中核事業影響度評価フォーム</label>
                </div>
            </div>
            <div class="row mx-md-3 d-none d-md-block d-print-block">
                <div class="row" v-for="(disaster_assumption, index) in disaster_assumptions_info" :key="disaster_assumption.id">
                    <div v-for="(important) in disaster_assumption.important_businesses" :key="important.id">
                        <div class="mt-3 row">
                            <span class="col-2 col-form-label text-nowrap me-1">災害想定</span>
                            <div class="col-7 col-md-6">
                                <input type="text" class="form-control" disabled :value="disaster_assumption.disaster_assumption_content">
                            </div>
                            <span class="col-2 col-form-label text-nowrap ms-md-1">の場合</span>
                        </div>
                        <div class="mt-1 row">
                            <span class="col-2 col-form-label text-nowrap me-1">重要業務</span>
                            <div class="col-7 col-md-6">
                                <input type="text" class="form-control" disabled :value="important.important_business_content">
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="table-responsive-md">
                                <table class="table table-bordered border border-table table-width-900">
                                    <thead>
                                        <tr>
                                            <th>
                                                ①中核{{ callReplaceWord('事業') }}の継続に必要な資源（ボトルネック資源）
                                            </th>
                                            <th>
                                                ②中核{{ callReplaceWord('事業') }}に対する重要度の大きさ
                                            </th>
                                            <th>
                                                ③想定している災害がボトルネックに与える影響
                                            </th>
                                            <th>
                                                ④影響の大きさ（②×③）
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="resource in important.required_resources" :key="resource.required_resource_id">
                                            <td>{{ resource.resource || '&emsp;'  }}</td>
                                            <td>{{ convertImportanceDimensionToString(resource.importance_dimension) || '&emsp;' }}</td>
                                            <td>{{ convertImpactToString(resource.impact) || '&emsp;' }}</td>
                                            <td>{{ resource.impact_dimension || '&emsp;' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-md-none d-print-none">
                <div class="row" v-for="(disaster_assumption, index) in disaster_assumptions_info" :key="disaster_assumption.id">
                    <div v-for="(important) in disaster_assumption.important_businesses" :key="important.id">
                        <div class="mt-3 row">
                            <span class="col-12 col-form-label">災害想定</span>
                            <div class="col-12 border background-body p-2">
                                <span class="col-form-label">{{ disaster_assumption.disaster_assumption_content }}</span>
                            </div>
                            <span class="col-12 col-form-label">の場合</span>
                        </div>
                        <div class="mt-1 row">
                            <span class="col-12 col-form-label">重要業務</span>
                            <div class="col-12 border background-body p-2">
                                <span class="col-form-label">{{ important.important_business_content }}</span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="fs-half">
                                <table class="table table-bordered border border-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                ①中核{{ callReplaceWord('事業') }}の継続に必要な資源（ボトルネック資源）
                                            </th>
                                            <th>
                                                ②中核{{ callReplaceWord('事業') }}に対する重要度の大きさ
                                            </th>
                                            <th>
                                                ③想定している災害がボトルネックに与える影響
                                            </th>
                                            <th>
                                                ④影響の大きさ（②×③）
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="resource in important.required_resources" :key="resource.required_resource_id">
                                            <td>{{ resource.resource || '&emsp;'  }}</td>
                                            <td>{{ convertImportanceDimensionToString(resource.importance_dimension) || '&emsp;' }}</td>
                                            <td>{{ convertImpactToString(resource.impact) || '&emsp;' }}</td>
                                            <td>{{ resource.impact_dimension || '&emsp;' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        let requiredResources = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/J1';
            location.href = url;
        }

        const showNextForAdmin = () => {
            const url = '/browse/J3';
            location.href = url;
        }

        const showNextForUser = () => {
            const url = '/browse/J4';
            location.href = url;
        }

        const convertImportanceDimensionToString = (value) => {
            return props.evaluation_resource_list.find(
                (data) => data.code.toString().slice(-1) === value
            )?.name || '';
        };

        const convertImpactToString = (value) => {
            return props.impact_bottleneck_list.find(
                (data) => data.code.toString().slice(-1) === value
            )?.name || '';
        };

        if (props.disaster_assumptions_info.length > 0) {
            requiredResources = props.disaster_assumptions_info.flatMap((obj) =>
                obj.important_businesses.flatMap(
                    (obj2) => obj2.required_resources
                )
            );
        }

        return {
            setLevel,
            isMargin1,
            requiredResources,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
            convertImportanceDimensionToString,
            convertImpactToString,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        disaster_assumptions_info: {
            type: Object,
        },
        evaluation_resource_list: {
            type: Array,
        },
        impact_bottleneck_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border-table {
    border-color: common_colors.colors(resilience-gray) !important;
}

table thead tr {
    background-color: #f2f2f2;
}

.table-width-900 {
    min-width: 900px;
}

.background-body {
  background-color: #f2f2f2;
}

@media (max-width: 767px) {
    .fs-half {
        font-size: 0.5rem;;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
