<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            4.　BCP発動フロー｜初動対応・復旧活動
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5. 緊急時におけるBCP発動 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
            :set_level="setLevel"
        />

    <div class="row mx-3 mt-5">
        <span>災害発生に直面した時の初動対応や復旧活動を例示し、あなたの{{ callReplaceWord('組織') }}が行うべき行動指針・対策を記載します。</span>
        <span>また、あなたの{{ callReplaceWord('組織') }}単独での実施が困難な場合も、他所と連携することにより効果的な場合もあります。現時点における各対策の連携の有無をチェックしてください。</span>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2 font-orange">
            発動フロー　――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>緊急事態発生時、あなたの{{callReplaceWord('組織')}}が事業継続のために適切な行動ができるよう、発生当日の「初動対応」、発生後数日で行う「復旧活動」を整理します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-2 border border-dark d-flex align-items-center justify-content-center py-2">
            初動対応の内容例
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>初動対応とは、緊急事態発覚後の被害を最小にとどめるための防災対策や、その後の復旧へつなげていくための準備作業です。対応の内容例を確認し、修正してください。その他、{{callReplaceWord('自社')}}に必要な対応内容があれば追記、必要のない対応は削除してください。各項目の具体的な対応方法は、基本コースで入力します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start py-2">
        </div>
        <div class="p2 col-md-2 border border-dark d-flex align-items-start justify-content-center py-2">
            他所との連携の有無
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>緊急時の対応には、個々の{{callReplaceWord('組織')}}だけでなく、例えば同じ地域の{{callReplaceWord('組織')}}や同じサプライチェーンに属する{{callReplaceWord('組織')}}等と連携すると効果的な場合があります。具体的な代替戦略先については、基本コースで入力します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div v-for="(form, formIndex) in valueForms1" :key="formIndex">
        <div v-if="form.columnName.slice(-1) === typeActivationFlow">
        <div class="row ms-3" :class="{'mt-5':formIndex === 0, 'mt-3':formIndex > 0}">
            <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="form.values[0].value">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2">
            </div>

            <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                <input type="radio" :id="'customRadioInline1' + formIndex" :name="'customRadioInline1' + formIndex" class="custom-control-input" value="〇" v-model="form.values[1].value">
                <label class="custom-control-label" :for="'customRadioInline1' + formIndex">&emsp;&emsp;〇</label>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                <input type="radio" :id="'customRadioInline2' + formIndex" :name="'customRadioInline2' + formIndex" class="custom-control-input" value="×" v-model="form.values[1].value">
                <label class="custom-control-label" :for="'customRadioInline2' + formIndex">&emsp;&emsp;×</label>
            </div>

            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex === valueForms1.length - 1">
                <input type="button" class="btn btn-black" value="行を増やす" @click="addLine(typeActivationFlow)">
            </div>
        </div>
    </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2 font-orange">
            復旧活動　―――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-2 border d-flex align-items-center justify-content-center py-2">
            復旧活動の内容例
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>復旧活動とは、事業を継続するために緊急事態で生じた被害を復旧して再開させる活動や、代替手段により事業を継続する事後対応策をいいます。対応の内容例を確認し、修正してください。その他、{{callReplaceWord('自社')}}に必要な対応内容があれば追記、必要のない対応は削除してください。各項目の具体的な活動方法は、基本コースで入力します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 col-md-3 d-flex align-items-center justify-content-start py-2">
        </div>
        <div class="p2 col-md-2 border d-flex align-items-start justify-content-center py-2">
            他所との連携の有無
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>緊急事態発生時の対応は、個社だけでなく、同じ商店街内やサプライチェーンに属する企業等と連携すると効果的です。具体的な代替戦略先については、基本コースで入力します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
    <div v-for="(form, formIndex) in valueForms2" :key="formIndex">
        <div class="row ms-3" :class="{'mt-5':formIndex === 0, 'mt-3':formIndex > 0}">
            <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="form.values[0].value">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2">
            </div>
            <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                <input type="radio" :id="'customRadioInline3' + formIndex" :name="'customRadioInline3' + formIndex" class="custom-control-input" value="〇" v-model="form.values[1].value">
                <label class="custom-control-label" :for="'customRadioInline3' + formIndex">&emsp;&emsp;〇</label>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
            </div>
            <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                <input type="radio" :id="'customRadioInline4' + formIndex" :name="'customRadioInline4' + formIndex" class="custom-control-input" value="×" v-model="form.values[1].value">
                <label class="custom-control-label" :for="'customRadioInline4' + formIndex">&emsp;&emsp;×</label>
            </div>

            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex === valueForms2.length - 1">
                <input type="button" class="btn btn-black" value="行を増やす" @click="addLine(typeRecoveryActivities)">
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 mt-5 col-md-6 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction" value="入門｜5.BCPの運用 へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-5 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted( async () => {
            let isExistForm1 = false;
            let isExistForm2 = false;
            // 取得データをセット
            if(props.bcp_introduction_values_info.length > 0) {
                await props.bcp_introduction_values_info.forEach(data => {
                    if(field1Regex.test(data.column_name) && data.seq === 1) {
                        // 同一カラムのvalue値を取得
                        const findData = props.bcp_introduction_values_info.find(fetchData => {
                            return fetchData.column_name === data.column_name && fetchData.seq === 2
                        });
                        valueForms1.value.push({
                            columnName: data.column_name,
                            values:[
                                {value: data.value},
                                {value: typeof findData !== 'undefined' ? findData.value : ''},
                                ]
                        });
                        isExistForm1 = true;
                    }

                    if(field2Regex.test(data.column_name) && data.seq == 1) {
                        // 同一カラムのvalue値を取得
                        const findData = props.bcp_introduction_values_info.find(fetchData => {
                            return fetchData.column_name === data.column_name && fetchData.seq === 2
                        });
                        valueForms2.value.push({
                            columnName: data.column_name,
                            values:[
                                {value: data.value},
                                {value: typeof findData !== 'undefined' ? findData.value : ''},
                            ]
                        });
                        isExistForm2 = true;
                    }
                });
            }
            if(!isExistForm1) {
                valueForms1.value = [...valueForms1InitialData];
            }
            if(!isExistForm2) {
                valueForms2.value = [...valueForms2InitialData];
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const typeActivationFlow = '1';
        const typeRecoveryActivities = '2';
        const field1Regex = new RegExp(`^N\\d*-${typeActivationFlow}$`);
        const field2Regex = new RegExp(`^N\\d*-${typeRecoveryActivities}$`);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms1 = ref([]);
        const valueForms1InitialData = reactive([
            {
                columnName: 'N00043-1',
                values:[
                    {value: callReplaceWord('従業員') + '・' + callReplaceWord('顧客') + 'の避難'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00044-1',
                values:[
                    {value: callReplaceWord('従業員') + '・' + callReplaceWord('顧客') + 'の安否確認'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00045-1',
                values:[
                    {value: '被災した' + callReplaceWord('従業員') + '・' + callReplaceWord('顧客') + 'の対応'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00046-1',
                values:[
                    {value: '初期消火'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00047-1',
                values:[
                    {value: '地域への対応'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00048-1',
                values:[
                    {value: '瓦礫処理による避難経路確保'},
                    {value: ''},
                ]
            },
        ]);

        const valueForms2 = ref([]);
        const valueForms2InitialData = reactive([
            {
                columnName: 'N00049-2',
                values:[
                    {value: '重要' + callReplaceWord('商品') + 'の提供・休止'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00050-2',
                values:[
                    {value: '各種' +callReplaceWord('取引先') + 'との連絡調整'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00051-2',
                values:[
                    {value: '行政・業界団体への対応'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00052-2',
                values:[
                    {value: '対外への情報発信'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00053-2',
                values:[
                    {value: '資金の確保'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00054-2',
                values:[
                    {value: '電気・ガス・水道設備等の確保'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00055-2',
                values:[
                    {value: '周辺地域の被災建物の片付け作業'},
                    {value: ''},
                ]
            },
        ]);

        const addLine = (addType) => {
            let columnNameArray = [];
            let columnNameNumber = 0
            if (addType == typeActivationFlow) {
                columnNameArray = valueForms1.value.slice(-1)[0].columnName.split('-');
                columnNameNumber = parseInt(columnNameArray[0].substring(1));
                valueForms1.value.push({
                    columnName: 'N' + ('00000' + (columnNameNumber + 1)).slice(-5) + '-' + addType,
                    values:[
                        {value: ''},
                        {value: ''},
                    ]
                });
            } else {
                columnNameArray = valueForms2.value.slice(-1)[0].columnName.split('-');
                columnNameNumber = parseInt(columnNameArray[0].substring(1));
                valueForms2.value.push({
                    columnName: 'N' + ('00000' + (columnNameNumber + 1)).slice(-5) + '-' + addType,
                    values:[
                        {value: ''},
                        {value: ''},
                    ]
                });
            }
        }

        const showBasic = async () => {
            // 初動対応・復旧活動を登録
            const result = await registerIntroduction7();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/9';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 初動対応・復旧活動を登録
            const result = await registerIntroduction7();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 初動対応・復旧活動を登録
            const result = await registerIntroduction7();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/8';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 初動対応・復旧活動を登録
            const result = await registerIntroduction7();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 初動対応・復旧活動の登録
        const registerIntroduction7 = async () => {
            // リクエストの整形
            const reqValueForms = [];
            valueForms1.value.forEach(data => {
                data.values.forEach((val, index) => {
                    if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: index + 1,
                            value: val.value,
                        });
                    }
                });
            });

            valueForms2.value.forEach(data => {
                data.values.forEach((val, index) => {
                    if (val.value != '') {
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: index + 1,
                            value: val.value,
                        });
                    }
                });
            });

            const postUrl = '/api/bcp/registerIntroduction7';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            valueForms1,
            valueForms1InitialData,
            valueForms2,
            valueForms2InitialData,
            isProcessing,
            typeActivationFlow,
            typeRecoveryActivities,
            callReplaceWord,
            addLine,
            showBasic,
            showBack,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

    .font-orange {
        color: common_colors.colors(resilience-orange);
    }

</style>
