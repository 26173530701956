<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜情報連絡（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-4 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　電話／FAX番号シート［{{ callReplaceWord('自社') }}用］
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-11">
            <div class="table-responsive">
                <table class="table table-bordered border-black">
                    <thead>
                        <tr>
                            <th>電話番号</th>
                            <th>タイプ</th>
                            <th>状態</th>
                            <th>重要度</th>
                            <th>重要度「高」の場合の対応策</th>
                            <th>関連する{{ callReplaceWord('社内') }}重要業務</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in infoContactPhoneFaxNumbers" :key="index">
                            <td>
                                <input type="text" class="form-control" v-model="item.phoneNumber" />
                            </td>
                            <td>
                                <select class="form-select" v-model="item.type">
                                    <option value=""></option>
                                    <option v-for="tel_type in tel_type_list" :key="tel_type.code" :value="tel_type.code">{{ tel_type.name }}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select" v-model="item.status">
                                    <option value=""></option>
                                    <option v-for="tel_state in tel_state_list" :key="tel_state.code" :value="tel_state.code">{{ tel_state.name }}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select" v-model="item.priority">
                                    <option value=""></option>
                                    <option v-for="tel_importance in tel_importance_list" :key="tel_importance.code" :value="tel_importance.code">{{ tel_importance.name }}</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="item.measures" :disabled="getImportanceValue(item.priority) !== '高'">
                            </td>
                            <td class="bg-resilience-orange">
                                <select name="selectImportantBusiness" class="form-select form-control bg-resilience-orange d-flex align-items-center justify-content-center py-2"
                                    v-model="item.importantBusinessId"
                                >
                                    <option type="text" ></option>
                                    <option type="text" v-for="importantBusinessList in important_businesses_info" :key="importantBusinessList" :value="importantBusinessList.id">
                                        {{ importantBusinessList.important_business_content }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="d-flex justify-content-end">
                    <input type="button" class="btn btn-black btn-sm" value="1行増やす" @click="addRow">
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>BCP発動時において、中核{{ callReplaceWord('事業') }}の復旧・継続のために、各電話・FAX番号が不可欠かどうかを検討しておきます。不可欠な番号である場合は、事前対策や代替手段の確保方法を検討しておく必要があります。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-3 text-dark d-flex align-items-end justify-content-start">
            備考
        </div>
    </div>
    <div class="row ms-3">
        <div class="p2 col-md-11 d-flex align-items-center justify-content-start py-2">
            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="note[0].value">
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜情報連絡（つづき）" style="font-size:10.5pt" :disabled="isProcessing"  @click="showNext">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing"  @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.info_contact_phone_fax_numbers.length > 0) {
                // 情報連絡_電話FAX番号
                infoContactPhoneFaxNumbers.splice(0);
                props.info_contact_phone_fax_numbers.forEach(fetchData => {
                    infoContactPhoneFaxNumbers.push({
                        phoneNumber: fetchData.phone_number,
                        type: fetchData.type,
                        status: fetchData.status,
                        priority: fetchData.priority,
                        measures: fetchData.measures,
                        importantBusinessId: fetchData.important_business_id,
                    })
                });
            }
            if (props.bcp_values_info.length > 0) {
                // 備考
                note.splice(0);
                note.push({
                    fieldNumber: props.bcp_values_info[0].field_number,
                    columnName: props.bcp_values_info[0].column_name,
                    seq: props.bcp_values_info[0].seq,
                    value: props.bcp_values_info[0].value,
                })
            }

            // 必要な入力枠の作成
            const currentCount = props.info_contact_phone_fax_numbers.length;
            for (let i = currentCount; i < 7; i++) {
                addRow();
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const pageName = 'S6';
        const setDefaultFieldNumber = 1;
        const setDefaultSeq = 1;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const infoContactPhoneFaxNumbers = reactive([]);
        for (let i = 0; i < 7; i++) {
            infoContactPhoneFaxNumbers.push({
                phoneNumber: '',
                type: '',
                status: '',
                priority: '',
                measures: '',
                importantBusinessId: '',
            });
        }

        const note = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00217',
                seq: setDefaultSeq,
                value: '',
            },
        ]);

        const addRow = () => {
            infoContactPhoneFaxNumbers.push({
                phoneNumber: '',
                type: '',
                status: '',
                priority: '',
                measures: '',
                importantBusinessId: '',
            });
        }

        const getImportanceValue = computed(() => (importance_code) => {
            const temp = props.tel_importance_list.reduce((acc, value) => {
                if(value.code === importance_code){
                    acc = value.name;
                }
                return acc;
            }, "");
            return temp;
        });

        const showBack = async () => {
            // 情報連絡（電話FAX番号）を登録
            const result = await registerSecond6();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 情報連絡（電話FAX番号）を登録
            const result = await registerSecond6();

            if (result == '200') {
                const url = '/bcp/bcp_second/7';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 情報連絡（電話FAX番号）を登録
            const result = await registerSecond6();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 情報連絡（電話FAX番号）を登録
            const result = await registerSecond6();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 情報連絡（電話FAX番号）登録
        const registerSecond6 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond6';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'info_contact_phone_fax_numbers': infoContactPhoneFaxNumbers,
                'value_form_list': note.filter(data => data.value != ''),
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            infoContactPhoneFaxNumbers,
            note,
            showBack,
            showNext,
            showPrevious,
            showContents,
            getImportanceValue,
            addRow,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        tel_type_list: {
            type: Array,
        },
        tel_state_list: {
            type: Array,
        },
        tel_importance_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        important_businesses_info: {
            type: Array,
        },
        info_contact_phone_fax_numbers: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.border-black {
    border-color: #808080;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}


</style>
