<template>
    <div class="row">
        <div class="col-md-8">
            <div class="row mt-3 pb-5">
                <i id="safety-logo"></i>
            </div>
            <div class="row my-5">
                <div class="col-md-6 px-5">
                    <div class="row">
                        <label class="user-menu h4 px-3 py-2">□利用者メニュー</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showMessage">安否メッセージ<span class="ms-2 badge rounded-pill badge-unread" v-show="unread_messages_count > 0">{{ unread_messages_count }}</span></label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showEveryonesSafety">みんなの安否</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showRegisterMyself">わたし登録</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showRegisterMyFamily">わたしの家族登録</label>
                    </div>
                </div>
                <div class="col-md-6 px-5 mt-5 mt-md-0" v-if="is('administrator')">
                    <div class="row">
                        <label class="administrator_menu h4 px-3 py-2">□管理者メニュー</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showCreateSafetyMessage">安否メッセージ新規登録</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showSendAlertMail">登録一覧から配信・編集</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showSendHistory">配信履歴</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showCreateSafetyMessage = () => {
            const url = '/safety/k1?back=home';

            location.href = url;
        }

        const showSendAlertMail = () => {
            const url = '/safety/k2';

            location.href = url;
        }

        const showSendHistory = () => {
            const url = '/safety/k3';

            location.href = url;
        }

        const showMessage = () => {
            const url ='/safety/R1';

            location.href = url;
        }

        const showEveryonesSafety = () => {
            // const url ='/safety/R2';
            const url = '/safety/list';
            
            location.href = url;
        }

        const showRegisterMyself = () => {
            const url = '/al/myPage/registerMyself';

            location.href = url;
        }

        const showRegisterMyFamily = () => {
            const url = '/al/myPage/registerMyFamily';

            location.href = url;
        }

        return {
            showCreateSafetyMessage,
            showSendAlertMail,
            showSendHistory,
            showMessage,
            showEveryonesSafety,
            showRegisterMyself,
            showRegisterMyFamily,
        }
    },
    props: {
        unread_messages_count: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
#safety-logo {
    display: inline-block;
    width: 703px;
    height: 314.5px;
    background-image: url('../../../svg/13-05_anpi.svg');
    background-size: 703px auto;
    background-repeat: no-repeat;

}

.user-menu {
    background-color: #33cccc;
    border: 1px solid white;
    color: white;
}

.administrator_menu {
    background-color: #ff6600;
    border: 1px solid white;
    color: white;
}

.menu-button {
    background-color: #808080;
    border: 1px solid white;
    color: white;
}

.badge-unread {
    background-color: #2e75b6;
}

@media (max-width: 767px) {
    #safety-logo {
        display: inline-block;
        width: 300px;
        height: 134.2px;
        background-image: url('../../../svg/13-05_anpi.svg');
        background-size: 300px auto;
        background-repeat: no-repeat;
    }
}

</style>