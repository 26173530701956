<template>
    <div class="row">
        <div>
            <div class="row mt-3 pb-5">
                <i id="pdca-logo"></i>
            </div>
            <div class="row my-5">
                <div class="col-md-4 px-5">
                    <div class="row">
                        <label class="user-menu h4 px-3 py-2">□ 利用者メニュー</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showBrowseUpdateRecords">BCP更新記録の閲覧</label>
                    </div>
                </div>
                <div class="col-md-4 px-5" v-if="is('internal_auditor')">
                    <div class="row">
                        <label class="auditor-menu h4 px-3 py-2">□ 内部監査人メニュー</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showCheckForAuditor">内部監査人による点検</label>
                    </div>
                </div>
                <div class="col-md-4 px-5" v-if="is('administrator')">
                    <div class="row">
                        <label class="administrator_menu h4 px-3 py-2">□ 管理者メニュー</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showCheckForManager">経営者による自己診断</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showResult">自己診断・内部監査結果</label>
                    </div>
                    <div class="row mt-3">
                        <label class="menu-button px-3 py-2 cursor-pointer" @click="showUpdateRecords">BCP更新記録</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showBrowseUpdateRecords = () => {
            const url = '/pdca/9';
            location.href = url;
        }

        const showCheckForAuditor = () => {
            const url = '/pdca/n';
            location.href = url;
        }

        const showCheckForManager = () => {
            const url = '/pdca/j';
            location.href = url;
        }

        const showResult = () => {
            const url = '/pdca/7';
            location.href = url;
        }

        const showUpdateRecords = () => {
            const url = '/pdca/8';
            location.href = url;
        }

        return {
            showBrowseUpdateRecords,
            showCheckForAuditor,
            showCheckForManager,
            showResult,
            showUpdateRecords,
        }
    },
}
</script>

<style lang="scss" scoped>
#pdca-logo {
    display: inline-block;
    width: 845px;
    height: 244px;
    background-image: url('../../../svg/15-top.svg');
    background-size: 845px auto;
    background-repeat: no-repeat;
}

.user-menu {
    background-color: #33cccc;
    border: 1px solid white;
    color: white;
}

.auditor-menu {
    background-color: #00cc99;
    border: 1px solid white;
    color: white;
}

.administrator_menu {
    background-color: #ff6600;
    border: 1px solid white;
    color: white;
}

.menu-button {
    background-color: #808080;
    border: 1px solid white;
    color: white;
}

</style>
