<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:12pt">
            4. 財務診断と事前対策計画｜事業中断によるキャッシュフローの悪化額の算定
        </div>

        
        <div class="col-md-4 d-flex justify-content-evenly">
            <h5 class="align-self-end"><span class="badge bg-resilience-green text-light">閲覧・出力なし</span></h5>
            <h4 class="align-self-end"><span class="badge bg-badge-yellow">製造業</span></h4>
            <h5 class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る"></h5>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

<!--------------------------------------○ 損益計算書---------------------------------------------------------------->

    <div class="row mt-3 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">事業停止期間とキャッシュフローの関係</label>
        </div>
    </div>

<!-------------① 事業が1ヶ月間ストップした場合のキャッシュフローの悪化額（B）------------------------------------->
    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex justify-content-between">
            <label for="" class="form-label">① 事業が1ヶ月間ストップした場合のキャッシュフローの悪化額（B）</label>
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                <template #popper>
                    <div class="row">
                        <label>現金収入（売上の入金、不動産などの資産の売却収入、補助金の受給等）から現金支出（仕入代金の支払、不動産などの資産の購入等）を差し引いた余剰資金のこと。現金収支ともいいます。<br />商品受け渡しと金銭の授受がずれる商慣行により、損益計算書で示される利益と現金として手元にある利益（金額）がずれることがあり、その場合の手元にある利益がキャッシュフローに相当します。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
        <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark business-suspension-period-table">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">1ヶ月</th>
                    <th scope="col-md">1ヶ月（通常ベース）</th>
                    <th scope="col-md">通常ベース比</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支（B）</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------② 事業が2ヶ月間ストップした場合のキャッシュフローの悪化額（B）------------------------------------->
    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">② 事業が2ヶ月間ストップした場合のキャッシュフローの悪化額（B）</label>
        </div>
        <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark business-suspension-period-table">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">1ヶ月×2</th>
                    <th scope="col-md">2ヶ月（通常ベース）</th>
                    <th scope="col-md">通常ベース比</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支（B）</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>
<!-------------③ 事業が3ヶ月間ストップした場合のキャッシュフローの悪化額（B）------------------------------------->
    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">③ 事業が3ヶ月間ストップした場合のキャッシュフローの悪化額（B）</label>
        </div>
        <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark business-suspension-period-table">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">1ヶ月×3</th>
                    <th scope="col-md">3ヶ月（通常ベース）</th>
                    <th scope="col-md">通常ベース比</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支（B）</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------④ 事業が10ヶ月間ストップした場合のキャッシュフローの悪化額（B）------------------------------------->
    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">④ 事業が10ヶ月間ストップした場合のキャッシュフローの悪化額（B）</label>
        </div>
        <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark business-suspension-period-table">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">1ヶ月</th>
                    <th scope="col-md">1ヶ月（通常ベース）</th>
                    <th scope="col-md">通常ベース比</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支（B）</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------⑤ 事業が20ヶ月間ストップした場合のキャッシュフローの悪化額（B）------------------------------------->
    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">⑤ 事業が20ヶ月間ストップした場合のキャッシュフローの悪化額（B）</label>
        </div>
        <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark business-suspension-period-table">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">1ヶ月</th>
                    <th scope="col-md">1ヶ月（通常ベース）</th>
                    <th scope="col-md">通常ベース比</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支（B）</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------⑥ 事業がN日間ストップした場合のキャッシュフローの悪化額（B）　※Nが30を上回る場合は30日間の状況------------------------------------->
    <div class="row mt-5 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">⑥ 事業がN日間ストップした場合のキャッシュフローの悪化額（B）　※Nが30を上回る場合は30日間の状況</label>
        </div>

        <div class="row mt-3">
            <div class="p2 col-md-3 border d-flex align-items-center justify-content-start">
                <label for="" class="form-label">ストップする期間（N日）</label>
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-start">
                <input type="text" class="form-control border-white text-end" id="ストップする期間（N日）" placeholder="">
            </div>
        </div>

        <div class="row">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label for="" class="form-label">※資産復旧費用の算定で設定した復旧日を利用</label>
            </div>
        </div>

        <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark business-suspension-period-table">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">1ヶ月</th>
                    <th scope="col-md">1ヶ月（通常ベース）</th>
                    <th scope="col-md">通常ベース比</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支（B）</td>
                    <td colspan="">&emsp;</td>
                    <td rowspan="" colspan=""  class="normal_pace">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md d-flex align-items-center justify-content-start">
            <label for="" class="form-label">稼働率と1ヶ月あたりのキャッシュフローの関係</label>
        </div>
    </div>

<!-------------① 稼働率0％の場合のキャッシュフローの悪化額（B）------------------------------------->

    <div class="row mt-3 ms-3 ">
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">① 稼働率0％の場合のキャッシュフローの悪化額（B）</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">稼働率100％</th>
                    <th scope="col-md">稼働率0％の場合</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------② 稼働率30％の場合のキャッシュフローの悪化額（B）［月あたり］------------------------------------->

<div class="row mt-3 ms-3 ">
        <div class="p2 mt-5 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">② 稼働率30％の場合のキャッシュフローの悪化額（B）［月あたり］</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">稼働率100％</th>
                    <th scope="col-md">稼働率0％の場合</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------③ 稼働率60％の場合のキャッシュフローの悪化額（B）［月あたり］------------------------------------->

<div class="row mt-3 ms-3 ">
        <div class="p2 mt-5 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">③ 稼働率60％の場合のキャッシュフローの悪化額（B）［月あたり］</label>
        </div>
        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">稼働率100％</th>
                    <th scope="col-md">稼働率0％の場合</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

<!-------------④ 稼働率N％の場合のキャッシュフローの悪化額（B）［月あたり］------------------------------------->

    <div class="row mt-3 ms-3 ">
        <div class="p2 mt-5 d-flex align-items-center justify-content-start ">
            <label for="" class="form-label">④ 稼働率N％の場合のキャッシュフローの悪化額（B）［月あたり］</label>
        </div>

        <div class="row mt-3">
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-start">
                <label for="" class="form-label">稼働率（N）</label>
            </div>
            <div class="p2 col-md-2 border d-flex align-items-center justify-content-start">
                <input type="text" class="form-control border-white text-end" id="稼働率（N）" placeholder="">
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                <label for="" class="form-label">％</label>
            </div>
        </div>

        <div class="p2 d-flex align-items-center justify-content-start ">
            <label for="" class="col-md-11 form-label">&emsp;</label>
            <label for="" class="form-label">単位：千円</label>
        </div>
    </div>

    <div class="table-responsive-md">
        <table class="ms-3 table table-bordered border-dark">
            <thead>
                <tr>
                    <th scope="col-md" colspan="2">科目</th>
                    <th scope="col-md">稼働率100％</th>
                    <th scope="col-md">稼働率0％の場合</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="" colspan="2">営業収入</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="3" colspan="1" class="text-center">
                        営<br>
                        業<br>
                        支<br>
                        出
                    </td>
                    <td colspan="">変動費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">固定費</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td colspan="">小計</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
                <tr>
                    <td rowspan="" colspan="2">月次資金収支</td>
                    <td colspan="">&emsp;</td>
                    <td colspan="">&emsp;</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md text-center btn btn-bcp-middle" value="4. 財務診断と事前対策計画｜緊急事態発生後のキャッシュフローの算定 へ" @click="showNext">
            </div>
        </div>
        <div class="col-md">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="col-md-4 text-center btn btn-bcp-basic" value="目次構成 へ戻る">
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('001000');

        const showNext = () => {
            const url = '/bcp/bcp_financial/製4';

            location.href = url;
        }

        return {
            setLevel,

            showNext,

        }
    },
    props:{
    }

}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-yellow {
    background-color: #ffd966;
    border: 1px solid #404040;
    color: #404040;
}

.bg-resilience-gray {
    background-color: #bfbfbf;
}

.business-suspension-period-table thead tr th:nth-child(1),
.business-suspension-period-table thead tr th:nth-child(2),
.business-suspension-period-table thead tr th:nth-child(4) {
    background-color: #d9d9d9;
}

.business-suspension-period-table thead tr th:nth-child(3) {
    background-color: #f8cbad;
    color: #ff6600;
}

.normal_pace {
    background-color: #fce4d6;
    color: #ff6600;
}


</style>