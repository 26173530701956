<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式14</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 情報連絡</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 当社が所有する電話/FAX 番号には以下のものがある。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">電話／FAX番号シート［{{ callReplaceWord('自社') }}用］</label>
                </div>
            </div>
            <div class="d-none d-md-block d-print-block">
                <div class="row mt-3 ms-1">
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">電話番号</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">タイプ</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">状態</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">重要度</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">重要度「高」の場合の対応策</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">関連する{{ callReplaceWord('社内') }}需要業務</label>
                    </div>
                </div>
                <div class="row ms-1" v-for="(item, index) in infoContactPhoneFaxNumbers" :key="item.id">
                    <div class="p2 col-md-2 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== infoContactPhoneFaxNumbers.length - 1 }">
                        <div class="form-control border-white ">{{ item.phoneNumber || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-1 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== infoContactPhoneFaxNumbers.length - 1 }">
                        <div class="form-control border-white ">{{ convertTelTypeToString(item.type) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-2 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== infoContactPhoneFaxNumbers.length - 1 }">
                        <div class="form-control border-white ">{{ convertTelStateToString(item.status) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-1 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== infoContactPhoneFaxNumbers.length - 1 }">
                        <div class="form-control border-white ">{{ convertTelImportanceToString(item.priority) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== infoContactPhoneFaxNumbers.length - 1 }">
                        <div class="form-control border-white ">{{ item.measures || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== infoContactPhoneFaxNumbers.length - 1 }">
                        <div class="form-control border-white ">{{ convertImportantBusinessIdToString(item.importantBusinessId) || '&emsp;' }}</div>
                    </div>
                </div>
            </div>
            <div class="d-md-none d-print-none">
                <div class="row mt-2" v-for="(item, index) in infoContactPhoneFaxNumbers" :key="item.id">
                    <span class="form-label">{{ (index + 1) + "." }}</span>
                    <div class="col-12 border bg-gray-3">
                        <label class="col-form-label">電話番号</label>
                    </div>
                    <div class="col-12 border border-top-0">
                        <label class="col-form-label">{{ item.phoneNumber || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3 border-top-0">
                        <label class="col-form-label">タイプ</label>
                    </div>
                    <div class="col-12 border border-top-0">
                        <label class="col-form-label">{{ convertTelTypeToString(item.type) || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3 border-top-0">
                        <label class="col-form-label">状態</label>
                    </div>
                    <div class="col-12 border border-top-0">
                        <label class="col-form-label">{{ convertTelStateToString(item.status) || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3 border-top-0">
                        <label class="col-form-label">重要度</label>
                    </div>
                    <div class="col-12 border border-top-0">
                        <label class="col-form-label">{{ convertTelImportanceToString(item.priority) || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3 border-top-0">
                        <label class="col-form-label">重要度「高」の場合の対応策</label>
                    </div>
                    <div class="col-12 border border-top-0">
                        <label class="col-form-label">{{ item.measures || '&emsp;' }}</label>
                    </div>
                    <div class="col-12 border bg-gray-3 border-top-0">
                        <label class="col-form-label">関連する{{ callReplaceWord('社内') }}需要業務</label>
                    </div>
                    <div class="col-12 border border-top-0">
                        <label class="col-form-label">{{ convertImportantBusinessIdToString(item.importantBusinessId) || '&emsp;' }}</label>
                    </div>
                </div>
            </div>
            <div class="row ms-md-1 mt-3">
                <div class="p2 col-md-2 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-10 border bg-white d-flex align-items-center justify-content-start">
                    <div class="form-control border-white ">{{ valueForms['K00217'][1] || '&emsp;' }}</div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let infoContactPhoneFaxNumbers = reactive([]);

        const valueForms = reactive({
            K00217: {},
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K12';
            location.href = url;
        }

        const showNextForAdmin = async () => {
            const url = '/browse/K14';
            location.href = url;
        }

        const showNextForUser = async () => {
            const url = '/browse/K15';
            location.href = url;
        }

        const convertTelTypeToString = (value) => {
            return props.tel_type_list.find(
                (data) => data.code === value
            )?.name || '';
        };

        const convertTelStateToString = (value) => {
            return props.tel_state_list.find(
                (data) => data.code === value
            )?.name || '';
        };

        const convertTelImportanceToString = (value) => {
            return props.tel_importance_list.find(
                (data) => data.code === value
            )?.name || '';
        };

        const convertImportantBusinessIdToString = (value) => {
            return props.important_businesses_info.find(
                (data) => data.id === value
            )?.important_business_content || '';
        };

        if (props.info_contact_phone_fax_numbers.length > 0) {
            infoContactPhoneFaxNumbers = props.info_contact_phone_fax_numbers.map((fetchData) => ({
                id: fetchData.id,
                phoneNumber: fetchData.phone_number,
                type: fetchData.type,
                status: fetchData.status,
                priority: fetchData.priority,
                measures: fetchData.measures,
                importantBusinessId: fetchData.important_business_id,
            }));
        }

        if (props.bcp_values_info.length > 0) {
            Object.keys(valueForms).forEach((columnName) => {
                const data = props.bcp_values_info
                    .filter((fetchData) => fetchData.column_name === columnName)
                    .sort((a, b) => (a.seq < b.seq ? -1 : 1));

                data.forEach((d) => (valueForms[columnName][d.seq] = d.value));
            });
        }

        return {
            setLevel,
            isMargin1,
            infoContactPhoneFaxNumbers,
            valueForms,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
            convertTelTypeToString,
            convertTelStateToString,
            convertTelImportanceToString,
            convertImportantBusinessIdToString,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        tel_type_list: {
            type: Array,
        },
        tel_state_list: {
            type: Array,
        },
        tel_importance_list: {
            type: Array,
        },
        important_businesses_info: {
            type: Array,
        },
        info_contact_phone_fax_numbers: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-100 {
    width: 100%;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }
}
</style>
