<template>
    <div>
        <div class="row">
            <div class="col-md-9">
                <div class="h5 heading-gray mt-3">
                    従量課金支払い				
                </div>
            </div>
            <!-- <div class="col-md-3 mt-3">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black" value="前ページへ戻る" @click="goBack" :disabled="isProcessing" />
            </div> -->
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">お支払／請求書発行</div>
        </div>

        <div class="row">
            <div class="col-md-11 offset-md-1">従業課金の支払いが完了いたしました。</div>
        </div>
        <div class="row mt-3">
            <div class="col-md-11 offset-md-1">こちらから請求書をダウンロードください。</div>
        </div>
        <div class="row mt-1">
            <div class="col-md-11 offset-md-1">
                <input type="button" class="btn btn-orange-80" value="請求書PDFをダウンロード" :disabled="isProcessing" @click="makeInvoice" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-11 offset-md-1">※領収書は管理者専用ページからダウンロードください。</div>
        </div>

        <div class="row mt-5" v-if="select_line_連携 == 1">
            <div class="col-md-11 offset-md-1">
                ※LINE連携オプション申込会員<br>
                ・LINE連携オプションを申込の会員は、LINEアプリからTOTONO-L公式アカウントの友だち追加をしてください。また、利用者にもご周知ください。<br>
                ・スマートファンでご利用の利用者は、下記もしくはTOP画面の「LINE友だち追加」ボタンから公式アカウントの追加もできます。
            </div>
        </div>
        <div class="row mt-2" v-if="select_line_連携 == 1">
            <div>
                <div class="col-md-11 offset-md-1">
                    <div class="d-flex align-items-end">
                        <img :src="image_src" class="text-right" id="img-LINE">
                        <input type="button" class="btn btn-send-barcode ms-3" value="左記２次元コードをメールで送信" @click="send_code" :disabled="isProcessing" >
                    </div>
                </div>
                <div class="row ms-5 flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>
            </div>
        </div>
        <div class="row mt-2" v-if="select_line_連携 == 1">
            <div class="col-md-11 offset-md-1">
                <a href="https://lin.ee/gw92o2E" target="_blank"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"></a>
            </div>
        </div>


        <div class="row my-5">
            <div class="col-md-11 offset-md-1">
                <input type="button" class="btn btn-gray px-5" value="管理者専用TOPへ" @click="backAdministratorTop" :disabled="isProcessing" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default{
    setup(props) {
        const isProcessing = ref(false);
        const makeInvoice = () => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'invoice_from_payment_status',
                'bill_id': props.bill_info.id,
                'corp_id': props.bill_info.corp_id,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '請求書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const backAdministratorTop = () => {
            const url = "/al/administrator/home";
            location.href = url;
        }

        const send_code = () => {
            const url = "/api/line/send_code";

            isProcessing.value = true;

            axios.post(url).then(res=>{
                visibleFlashMessage('メールを送信しました。', 'success');
            }).catch(err => {
                visibleFlashMessage('メール送信に失敗しました。時間をあけて再度操作してください。', 'error');
            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        return {
            isProcessing,
            makeInvoice,
            backAdministratorTop,
            send_code,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
        }
    },
    props: {
        bill_info: {
            type: Object,
        },
        image_src: {
            type: String,
        },
        select_line_連携: {
            type: Number,
        }
    },
}
</script>

<style lang="scss" scoped>
    .btn-send-barcode {
        border: 1px solid #a6a6a6;
        color: white;
        background-color: #a6a6a6;
    }

    .btn-send-barcode:hover,
    .btn-send-barcode:disabled {
        border: 1px solid #a6a6a6;
        color: #a6a6a6;
        background-color: white;
    }

    #img-LINE {
        height: 72px;
    }

</style>