<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　中核{{ callReplaceWord('事業') }}と復旧目標 ｜{{ callReplaceWord('事業') }}継続に係る各種資源の代替の情報
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-advanced btn-sm" value="上級｜複数企業連携によるBCPの策定・運用体制 へ進む" v-show="is_completed_middle" :disabled="isProcessing" @click="showAdvanced">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3" v-for="(recoveryLocation, index) in recoveryLocationsInfo" :key="index">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="card-title">
                            <label class="d-flex align-items-center justify-content-start">
                                <span v-if="index === 0">◯　情報連絡の拠点となる場所</span>
                                <span v-else>◯　事業復旧を実施するための場所</span>
                            </label>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <label for="" class="form-label">TOTONO-L 会員ID</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control bg-resilience-light-blue" id="会員ID" v-model="recoveryLocation.corpId">
                                    <input type="button" class="ms-3 btn btn-sm btn-dark" value="検索" @click="getMemberInfo(index, recoveryLocation.corpId, implementationFiled)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="" class="form-label">建物所有者／管理者</label>
                                <input type="text" class="form-control" id="建物所有者／管理者" v-model="recoveryLocation.owner">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-9">
                                <label for="" class="mt-3 form-label">拠点となる場所｜住所</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control me-3" id="拠点となる場所｜住所" v-model="recoveryLocation.address">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                                        <template #popper>
                                            <div class="row">
                                                <label v-if="index === 0">緊急事態発生時において、{{ callReplaceWord('取引先') }}等への早期の連絡は非常に重要であるとともに、{{ callReplaceWord('従業員') }}に対して事業継続対応の指揮命令を連絡するための拠点場所を確保する必要があります。あなたの{{ callReplaceWord('組織') }}の建物自体に影響がなければその場所で構いませんが、被害を受けた場合にどうするかを考えておく必要があります。例えば、事業所が複数ある場合は、被災してない方の事業所を採用することが考えられます。また、事業所が一つしかなく、そこが被災してしまった場合には、近所の商工会議所や公的施設等が利用可能かどうかを検討する必要があるでしょう。また、場合によっては、自動車内を拠点とせざるを得ない場合もあります。</label>
                                                <label v-else>中核事業継続に関わる重要施設や設備が被災した場合の代替先を事業復旧場所としご記入ください。施設・設備の代替確保には、同一の機能をもつ施設を協力会社等に所有する方法や、回復用の作業場所のみ確保する方法、回復用の作業場所を他社と提供し合えるように協定を締結しておく方法などがあります。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-10">
                                <label for="" class="mt-3 form-label">※　必要に応じて、上記場所までの地図を用意します。</label>
                                <div class="d-flex flex-row"
                                    @dragleave="dragLeave"
                                    @dragover.prevent="dragEnter"
                                    @drop.prevent="dropFile($event, index)"
                                    :class="{enter: isEnter}"
                                >
                                    <div class="d-flex flex-row">
                                        <label type="button" class="btn btn-sm btn-secondary">
                                            <div style="font-size:28pt">
                                                地図をアップロード
                                            </div>
                                            <div style="font-size:10.5pt">
                                                拠点となる場所の地図データを{{ callReplaceWord('従業員') }}と共有する
                                            </div>
                                            <input id="submit_pdf" type="file" accept=".jpg, .jpeg" @change="dropFile($event, index)" />
                                            <span v-if="!!recoveryLocation.fileName">アップロードファイル：{{ recoveryLocation.fileName }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-md-4">
                                <label for="" class="form-label">第一電話番号</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="第一電話番号" v-model="recoveryLocation.firstPhoneNumber">
                                </div>
                            </div>
                            <div class="mt-3 col-md-4">
                                <label for="" class="form-label">予備電話番号</label>
                                <input type="text" class="form-control" id="予備電話番号" v-model="recoveryLocation.extraPhoneNumber">
                            </div>
                            <div class="mt-3 col-md-4">
                                <label for="" class="form-label">携帯電話、PHS等</label>
                                <input type="text" class="form-control" id="携帯電話、PHS等" v-model="recoveryLocation.mobilePhoneNumber">
                            </div>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-md-5">
                                <label for="" class="form-label">E-メール</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="E-メール" v-model="recoveryLocation.email">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-md-8" v-if="index === 0">
                                <label for="" class="form-label">連絡すべき内容</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="連絡すべき内容" v-model="recoveryLocation.detail">
                                </div>
                            </div>
                            <div class="mt-3 col-md-8" v-else>
                                <label for="" class="form-label">{{ callReplaceWord('事業') }}復旧場所で継続される中核{{ callReplaceWord('事業') }}</label>
                                <div class="d-flex flex-row">
                                    <select name="selectCoreBusiness" class="form-select bg-blue text-white" v-model="recoveryLocation.detail">
                                        <option type="text" v-for="coreBusiness in core_business_list" :key="coreBusiness" :value="coreBusiness.core_business_content" >{{ coreBusiness.core_business_content }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3 col-md-4">
                                <label for="" class="form-label">当該場所に行くべき{{ callReplaceWord('従業員') }}</label>
                                <input type="text" class="form-control" id="当該場所に行くべき{{ callReplaceWord('従業員') }}" v-model="recoveryLocation.employeeName">
                            </div>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-md-12">
                                <label for="" class="form-label">備考</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="備考" v-model="recoveryLocation.note">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="recoveryLocation.isViewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="card-title">
                            <label class="d-flex align-items-center justify-content-start">
                                ◯　応援要員
                            </label>
                            <label class="d-flex align-items-center justify-content-start">
                                1　事業復旧のための要員
                            </label>
                        </div>

                        <div class="row" :class="{'mt-3': index > 0}" v-for="(businessRecoveryStaff, index) in businessRecoveryStaffsInfo" :key="index">
                            <div class="col-md-12">
                                <div class="card-group">
                                    <div class="card bg-member_card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label for="" class="form-label">TOTONO-L 会員ID</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control bg-resilience-light-blue" id="会員ID" v-model="businessRecoveryStaff.corpId">
                                                        <input type="button" class="ms-3 btn btn-sm btn-dark" value="検索" @click="getMemberInfo(index, businessRecoveryStaff.corpId, businessRecoveryFiled)">
                                                    </div>
                                                    <label for="" class="form-label">※入力すると協力依頼想定者名の情報が自動表示されます。</label>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-7">
                                                    <label for="" class="mt-3 form-label">協力依頼想定者</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="協力依頼想定者" v-model="businessRecoveryStaff.cooperatingOrderAssumptionPerson">
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <label for="" class="mt-3 form-label">第一電話番号、携帯電話番号等の連絡先</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="第一電話番号、携帯電話番号等の連絡先" v-model="businessRecoveryStaff.phoneNumber">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-7">
                                                    <label for="" class="mt-3 form-label">支援を依頼すべき業務内容等</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="支援を依頼すべき業務内容等" v-model="businessRecoveryStaff.orderWorkContent">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="" class="mt-3 form-label">メールアドレス</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="メールアドレス" v-model="businessRecoveryStaff.email">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 d-flex" :class="[{'align-content-between flex-wrap' : index === 0}, 'align-items-end']">
                                        <div class="ms-3" v-if="index === 0">
                                            <VDropdown
                                                theme="hint-theme"
                                            >
                                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                                                <template #popper>
                                                    <div class="row">
                                                        <label>従業員が業務に従事できない場合の人員代替を決めます。「事業復旧のための要員」は業務について知見があることが望まれますので、会社のOB等に依頼する等考えられます。</label>
                                                    </div>
                                                </template>
                                            </VDropdown>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div v-if="index !== 0">
                                                <input type="button" class="ms-3 btn btn-delete" value="削除" @click="deleteRecoveryStaffSet(index)">
                                            </div>
                                            <div v-if="index === businessRecoveryStaffsInfo.length - 1" class="mt-3">
                                                <input type="button" class="ms-3 btn btn-black" value="ワンセット増やす" @click="addStaffSet(businessRecovery)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="mt-3 col-md-12">
                                <label for="" class="form-label">備考</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="備考" v-model="valueForms[0].value">
                                </div>
                            </div>
                        </div>

                        <div class="card-title">
                            <label class="mt-3 d-flex align-items-center justify-content-start">
                                2　被災生活支援のための要員
                            </label>
                        </div>

                        <div class="row" :class="{'mt-3': index > 0}" v-for="(livelihoodSupportStaff, index) in livelihoodSupportStaffsInfo" :key="index">
                            <div class="col-md-12">
                                <div class="card-group">
                                    <div class="card bg-member_card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label for="" class="form-label">TOTONO-L 会員ID</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control bg-resilience-light-blue" id="会員ID" v-model="livelihoodSupportStaff.corpId">
                                                        <input type="button" class="ms-3 btn btn-sm btn-dark" value="検索" @click="getMemberInfo(index, livelihoodSupportStaff.corpId, livelihoodSupportFiled)">
                                                    </div>
                                                    <label for="" class="form-label">※入力すると協力依頼想定者名の情報が自動表示されます。</label>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-7">
                                                    <label for="" class="mt-3 form-label">協力依頼想定者</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="協力依頼想定者" v-model="livelihoodSupportStaff.cooperatingOrderAssumptionPerson">
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <label for="" class="mt-3 form-label">第一電話番号、携帯電話番号等の連絡先</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="第一電話番号、携帯電話番号等の連絡先" v-model="livelihoodSupportStaff.phoneNumber">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-7">
                                                    <label for="" class="mt-3 form-label">支援を依頼すべき業務内容等</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="支援を依頼すべき業務内容等" v-model="livelihoodSupportStaff.orderWorkContent">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="" class="mt-3 form-label">メールアドレス</label>
                                                    <div class="d-flex flex-row">
                                                        <input type="text" class="form-control" id="メールアドレス" v-model="livelihoodSupportStaff.email">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 d-flex" :class="[{'align-content-between flex-wrap' : index === 0}, 'align-items-end']">
                                        <div class="ms-3" v-if="index === 0">
                                            <VDropdown
                                                theme="hint-theme"
                                            >
                                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                                                <template #popper>
                                                    <div class="row">
                                                        <label>従業員が業務に従事できない場合の人員代替を決めます。「被災生活支援のための要員」組合や、日頃より親交の深い近隣の方等に支援を依頼します。</label>
                                                    </div>
                                                </template>
                                            </VDropdown>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div v-if="index !== 0">
                                                <input type="button" class="ms-3 btn btn-delete" value="削除" @click="deleteSupportStaffSet(index)">
                                            </div>
                                            <div v-if="index === livelihoodSupportStaffsInfo.length - 1" class="mt-3">
                                                <input type="button" class="ms-3 btn btn-black" value="ワンセット増やす" @click="addStaffSet(livelihoodSupport)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="mt-3 col-md-12">
                                <label for="" class="form-label">備考</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="備考" v-model="valueForms[1].value">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="staffsViewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">
                        <div class="card-title">
                            <label class="d-flex align-items-center justify-content-start">
                                ◯　資金調達
                            </label>
                        </div>
                        <div class="row" v-for="(textForms1, index) in textFormsField1" :key="index">
                            <div class="col-md-10">
                                <label for="" class="mt-3 form-label" v-if="index === 0">資金調達手段、方針等</label>
                                <label for="" class="mt-3 form-label" v-else>備考</label>
                                <div class="d-flex flex-row">
                                    <textarea type="text" rows="3" class="form-control" id="" placeholder="" v-model="textForms1.value"></textarea>
                                </div>
                            </div>
                            <div class="col-md-1" v-if="index === 0">
                                <label for="" class="mt-3 form-label">&emsp;</label>
                                <div class="d-flex flex-row">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                                        <template #popper>
                                            <div class="row">
                                                <label>緊急事態発生後は少なからずの資金が必要となります。損害保険への加入、共済制度の活用、各種融資の活用、手持ち資金の事前確保などの資金調達手段や方針を検討しご記入ください。なお、備考欄にはすでに加入済みの保険内容等記入するとよいでしょう。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-1">
                    <div class="card border-0 bg-transparent">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <VDropdown
                                    theme="browsing_restrictions"
                                >
                                    <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                                    <template #popper>
                                        <div class="d-flex justify-content-start">
                                            <label>閲覧制限</label>
                                            <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="financingViewLimit">
                                                <label class="form-check-label" for="checkbox_1">
                                                    あり
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="col-md-12">
            <label class="d-flex align-items-center justify-content-start">
                ◯　通信手段・各種インフラ
            </label>
        </div>

        <div class="row" v-for="(textForms2, index) in textFormsField2" :key="index">
            <div class="col-md-10">
                <label for="" class="mt-3 form-label" v-if="index === 0">電話等の通信手段の代替方針</label>
                <label for="" class="mt-3 form-label" v-else-if="index === 1">電力</label>
                <label for="" class="mt-3 form-label" v-else-if="index === 2">ガス</label>
                <label for="" class="mt-3 form-label" v-else-if="index === 3">水道</label>
                <label for="" class="mt-3 form-label" v-else-if="index === 4">その他</label>
                <label for="" class="mt-3 form-label" v-else>備考</label>
                <div class="d-flex flex-row">
                    <textarea type="text" rows="3" class="form-control" id="" placeholder="" v-model="textForms2.value"></textarea>
                </div>
            </div>

            <div class="col-md-1" v-if="index === 0">
                <label for="" class="mt-3 form-label">&emsp;</label>
                <div class="d-flex flex-row">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                        <template #popper>
                            <div class="row">
                                <label>電話や電力、ガス、水道等が必要な場合には、可能な限りの代替策を検討しご記入ください。（例えば電力であれば小型の自家用発電機を整備など。水道であれば従業員×家族分かける3日分をポリタンクに備蓄など）</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="col-md-12">
            <label class="d-flex align-items-center justify-content-start">
                ◯　中核{{ callReplaceWord('事業') }}に必要となる情報に関する情報
            </label>
        </div>
    </div>

    <div class="row ms-3 mt-3" v-for="(coreBusinessAdditional, index) in coreBusinessAdditionalInfo" :key="index">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-member_card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <label for="" class="form-label">情報名書類名等</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="情報名書類名等" v-model="coreBusinessAdditional.informationName">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label for="" class="form-label">関連する社内重要業務</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="関連する社内重要業務" v-model="coreBusinessAdditional.companyImportantBusiness">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label for="" class="form-label">保管場所</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="保管場所" v-model="coreBusinessAdditional.storingPlace">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label for="" class="form-label">{{ callReplaceWord('社内') }}責任者</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="{{ callReplaceWord('社内') }}責任者" v-model="coreBusinessAdditional.manager">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label for="" class="form-label">記録媒体</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="記録媒体" v-model="coreBusinessAdditional.media">
                                </div>
                            </div>
                            <div class="col-md-1" v-if="index === 0">
                                <label for="" class="form-label">&emsp;</label>
                                <div class="d-flex flex-row">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                                        <template #popper>
                                            <div class="row">
                                                <label>印刷物、パソコンのハードディスク、CD・DVD、外付けハードディスク（SSD）、USB、クラウドなどがあります。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-md-2">
                                <label for="" class="form-label">バックアップの有無</label>
                                <div class="form-group d-flex flex-row">
                                    <select id="バックアップの有無" class="form-control" v-model="coreBusinessAdditional.backupExistence">
                                        <option value="1"></option>
                                        <option value="2">あり</option>
                                        <option value="3">なし</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mt-3 col-md-3">
                                <label for="" class="form-label">バックアップの記録媒体</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="バックアップの記録媒体" v-model="coreBusinessAdditional.backupMedia">
                                </div>
                            </div>
                            <div class="mt-3 col-md-3">
                                <label for="" class="form-label">バックアップの保管場所</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="バックアップの保管場所" v-model="coreBusinessAdditional.backupStoringPlace">
                                </div>
                            </div>
                            <div class="mt-3 col-md-3">
                                <label for="" class="form-label">バックアップの頻度</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="バックアップの頻度" v-model="coreBusinessAdditional.backupFrequency">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-md-8">
                                <label for="" class="form-label">上記の情報が無効になった場合の対応方針（再作成が可能な場合はその方法など）</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="上記の情報が無効になった場合の対応方針（再作成が可能な場合はその方法など）" v-model="coreBusinessAdditional.supportedPolicy">
                                </div>
                            </div>
                            <div class="mt-3 col-md-4">
                                <label for="" class="form-label">備考</label>
                                <div class="d-flex flex-row">
                                    <input type="text" class="form-control" id="備考" v-model="coreBusinessAdditional.note">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1 d-flex align-items-end">
                    <div class="d-flex  flex-column">
                        <div class="ms-3 " v-if="index !== 0">
                            <input type="button" class="btn btn-sm btn-delete" value="削除" @click="deleteCoreBusinessAdditionalSet(index)">
                        </div>
                        <div class="ms-3 mt-3" v-if="index === coreBusinessAdditionalInfo.length - 1">
                            <input type="button" class="btn btn-sm btn-black" value="ワンセット増やす" @click="addCoreBusinessAdditionalSet">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-12 d-flex flex-row align-items-center justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜4.　財務診断と事前対策計画｜復旧費用の算定へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref,reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(async () => {
            // 取得データをセット

            // 復旧場所
            if (props.recovery_locations_info.length > 0) {
                props.recovery_locations_info.forEach(fetchData => {
                    recoveryLocationsInfo.forEach(initData => {
                        if(fetchData.location_type == initData.locationType){
                            // 値をセット
                            recoveryLocationsInfo.splice(
                                parseInt(fetchData.location_type) - 1,
                                1,
                                {
                                    locationType: fetchData.location_type,
                                    isViewLimit : fetchData.is_view_limit == 1 ? true : false,
                                    corpId : fetchData.corp_id,
                                    owner: fetchData.owner,
                                    address: fetchData.address,
                                    firstPhoneNumber: fetchData.first_phone_number,
                                    extraPhoneNumber: fetchData.extra_phone_number,
                                    mobilePhoneNumber: fetchData.mobile_phone_number,
                                    email: fetchData.email,
                                    detail: fetchData.detail,
                                    employeeName: fetchData.employee_name,
                                    note: fetchData.note,
                                    mapId: fetchData.map_id,
                                    fileName: fetchData.name
                                }
                            );
                        }
                    });
                });
            }

            // 要員
            if (props.staffs_info.length > 0) {
                businessRecoveryStaffsInfo.splice(0);
                livelihoodSupportStaffsInfo.splice(0)
                await props.staffs_info.forEach((fetchData, index) => {
                    if (index === 0) {
                        // 要員の閲覧制限
                        staffsViewLimit.value = fetchData.is_view_limit == 1 ? true : false;
                    }
                    if (fetchData.staff_type == businessRecovery) {
                        // 事業復旧のための要員データセット
                        businessRecoveryStaffsInfo.push({
                            staffType: businessRecovery,
                            isViewLimit: fetchData.is_view_limit == 1 ? true : false,
                            corpId: fetchData.corp_id,
                            cooperatingOrderAssumptionPerson: fetchData.cooperating_order_assumption_person,
                            phoneNumber: fetchData.phone_number,
                            orderWorkContent: fetchData.order_work_content,
                            email: fetchData.email,
                        });
                    } else {
                        // 被災生活支援のための要員データセット
                        livelihoodSupportStaffsInfo.push({
                            staffType: livelihoodSupport,
                            isViewLimit: fetchData.is_view_limit == 1 ? true : false,
                            corpId: fetchData.corp_id,
                            cooperatingOrderAssumptionPerson: fetchData.cooperating_order_assumption_person,
                            phoneNumber: fetchData.phone_number,
                            orderWorkContent: fetchData.order_work_content,
                            email: fetchData.email,
                        });
                    }
                });
                if(businessRecoveryStaffsInfo.length === 0){
                    businessRecoveryStaffsInfo.push({
                        staffType: businessRecovery,
                        isViewLimit: 0,
                        corpId: '',
                        cooperatingOrderAssumptionPerson: '',
                        phoneNumber: '',
                        orderWorkContent: '',
                        email: '',
                    });
                }
                if(livelihoodSupportStaffsInfo.length === 0){
                    livelihoodSupportStaffsInfo.push({
                        staffType: livelihoodSupport,
                        isViewLimit: 0,
                        corpId: '',
                        cooperatingOrderAssumptionPerson: '',
                        phoneNumber: '',
                        orderWorkContent: '',
                        email: '',
                    });
                }
            }

            // 中核事業付加情報
            if(props.core_business_additional_info.length > 0){
                coreBusinessAdditionalInfo.splice(0);
                props.core_business_additional_info.forEach(fetchData =>{
                    coreBusinessAdditionalInfo.push({
                        informationName: fetchData.information_name,
                        companyImportantBusiness: fetchData.company_important_business,
                        storingPlace: fetchData.storing_place,
                        manager: fetchData.manager,
                        media: fetchData.media,
                        backupExistence: fetchData.backup_existence,
                        backupMedia: fetchData.backup_media,
                        backupStoringPlace: fetchData.backup_storing_place,
                        backupFrequency: fetchData.backup_frequency,
                        supportedPolicy: fetchData.supported_policy,
                        note: fetchData.note,
                    });
                });
            }

            // バリュー値
            if(props.bcp_values_info.length > 0){
                props.bcp_values_info.forEach(fetchData =>{
                    valueForms.forEach(initData => {
                        if(initData.columnName == fetchData.column_name){
                            initData.value = fetchData.value;
                        }
                    });
                });
            }

            // text値
            if (props.bcp_texts_info.length > 0) {
                props.bcp_texts_info.forEach(fetchData =>{
                    // 閲覧制限
                    if (fetchData.column_name === 'view_limit') {
                        financingViewLimit.value = fetchData.text_value == 1 ? true : false;
                    }
                    // フィールド１
                    textFormsField1.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.value = fetchData.text_value;
                        }
                    });
                    // フィールド２
                    textFormsField2.forEach(initData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.value = fetchData.text_value;
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const contactBase = '1';
        const recoveryImplementation = '2';
        const businessRecovery = '1';
        const livelihoodSupport = '2';
        const implementationFiled = '1';
        const businessRecoveryFiled = '2';
        const livelihoodSupportFiled = '3';
        const pageName = 'F4';
        const setDefaultFieldNumber = 1;
        const setDefaultSeq = 1;
        const staffsViewLimit = ref(0)
        const financingViewLimit = ref(0);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const recoveryLocationsInfo = reactive([
            {
                locationType: contactBase,
                isViewLimit: 0,
                corpId: '',
                owner: '',
                address: '',
                firstPhoneNumber: '',
                extraPhoneNumber: '',
                mobilePhoneNumber: '',
                email: '',
                detail: '',
                employeeName: '',
                note: '',
                mapId: '',
                fileName: '',
            },
            {
                locationType: recoveryImplementation,
                isViewLimit: 0,
                corpId: '',
                owner: '',
                address: '',
                firstPhoneNumber: '',
                extraPhoneNumber: '',
                mobilePhoneNumber: '',
                email: '',
                detail: '',
                employeeName: '',
                note: '',
                mapId: '',
                fileName: '',
            },
        ]);

        const businessRecoveryStaffsInfo = reactive([
            {
                staffType: businessRecovery,
                isViewLimit: 0,
                corpId: '',
                cooperatingOrderAssumptionPerson: '',
                phoneNumber: '',
                orderWorkContent: '',
                email: '',
            }
        ]);

        const livelihoodSupportStaffsInfo = reactive([
            {
                staffType: livelihoodSupport,
                isViewLimit: 0,
                corpId: '',
                cooperatingOrderAssumptionPerson: '',
                phoneNumber: '',
                orderWorkContent: '',
                email: '',
            }
        ]);

        const coreBusinessAdditionalInfo = reactive([
            {
                informationName: '',
                companyImportantBusiness: '',
                storingPlace: '',
                manager: '',
                media: '',
                backupExistence: '',
                backupMedia: '',
                backupStoringPlace: '',
                backupFrequency: '',
                supportedPolicy: '',
                note: '',
            }
        ]);

        const valueForms = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00059',
                seq: setDefaultSeq,
                value: '',
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00061',
                seq: setDefaultSeq,
                value: '',
            },
        ]);

        const textFormsField1 = reactive([]);
        for (let i = 62; i <= 63; i++) {
            textFormsField1.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K' + ('00000' + i).slice(-5),
                value: '',
            });
        }

        const textFormsField2 = reactive([]);
        for (let i = 64; i <= 69; i++) {
            textFormsField2.push({
                fieldNumber: setDefaultFieldNumber + 1,
                columnName: 'K' + ('00000' + i).slice(-5),
                value: '',
            });
        }

        const addStaffSet = (type) => {
            if (type === businessRecovery) {
                businessRecoveryStaffsInfo.push({
                    staffType: businessRecovery,
                    isViewLimit: 0,
                    corpId: '',
                    cooperatingOrderAssumptionPerson: '',
                    phoneNumber: '',
                    orderWorkContent: '',
                    email: '',
                });
            } else {
                livelihoodSupportStaffsInfo.push({
                    staffType: livelihoodSupport,
                    isViewLimit: 0,
                    corpId: '',
                    cooperatingOrderAssumptionPerson: '',
                    phoneNumber: '',
                    orderWorkContent: '',
                    email: '',
                });
            }
        }

        const deleteRecoveryStaffSet = (index) => {
            if(!confirm((index + 1) + "番目（" + businessRecoveryStaffsInfo[index].cooperatingOrderAssumptionPerson + "）を削除します。よろしいですか？")){
                return false;
            }

            businessRecoveryStaffsInfo.splice(index, 1);
        }

        const deleteSupportStaffSet = (index) => {
            if(!confirm((index + 1) + "番目（" + livelihoodSupportStaffsInfo[index].cooperatingOrderAssumptionPerson + "）を削除します。よろしいですか？")){
                return false;
            }

            livelihoodSupportStaffsInfo.splice(index, 1);
        }

        const addCoreBusinessAdditionalSet = () => {
            coreBusinessAdditionalInfo.push({
                informationName: '',
                companyImportantBusiness: '',
                storingPlace: '',
                manager: '',
                media: '',
                backupExistence: '',
                backupMedia: '',
                backupStoringPlace: '',
                backupFrequency: '',
                supportedPolicy: '',
                note: '',
            });
        }

        const deleteCoreBusinessAdditionalSet = (index) => {
            if(!confirm((index + 1) + "番目を削除します。よろしいですか？")){
                return false;
            }

            coreBusinessAdditionalInfo.splice(index, 1);
        }

        const showBack = async () => {
            // 復旧目標を登録
            const result = await registerFirst4();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 復旧目標を登録
            const result = await registerFirst4();

            if (result == '200') {
                const url = '/bcp/bcp_first/5';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 復旧目標を登録
            const result = await registerFirst4();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showAdvanced = async () => {
            // 復旧目標を登録
            const result = await registerFirst4();

            if (result == '200') {
                const url = '/bcp/bcp_multiple/1';
                location.href = url;
            }
        }

        const getMemberInfo = (index, searchId, targetField) => {
            const url="/api/bcp/getMemberInfo";

            // 企業情報取得
            return axios.post(url, {
                corp_id: searchId,
            }).then(res=>{
                if (targetField === implementationFiled) {
                    recoveryLocationsInfo[index].corpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    recoveryLocationsInfo[index].owner = res.data.owner !== undefined ? res.data.owner : null;
                    recoveryLocationsInfo[index].address = res.data.address !== undefined ? res.data.address : null;
                    recoveryLocationsInfo[index].firstPhoneNumber = res.data.phone_number !== undefined ? res.data.phone_number : null;
                } else if (targetField === businessRecoveryFiled) {
                    businessRecoveryStaffsInfo[index].corpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    businessRecoveryStaffsInfo[index].cooperatingOrderAssumptionPerson = res.data.owner !== undefined ? res.data.owner : null;
                    businessRecoveryStaffsInfo[index].phoneNumber = res.data.phone_number !== undefined ? res.data.phone_number : null;
                } else if (targetField === livelihoodSupportFiled) {
                    livelihoodSupportStaffsInfo[index].corpId = res.data.corp_id !== undefined ? res.data.corp_id : null;
                    livelihoodSupportStaffsInfo[index].cooperatingOrderAssumptionPerson = res.data.owner !== undefined ? res.data.owner : null;
                    livelihoodSupportStaffsInfo[index].phoneNumber = res.data.phone_number !== undefined ? res.data.phone_number : null;
                }
            });
        }

        const isEnter = ref(false);
        const dragEnter = () => {
            isEnter.value = true;
        }
        const dragLeave = () => {
            isEnter.value = false;
        }

        // 地図のアップロード
        const files = ref([]);
        const dropFile = (e, index) => {
            isEnter.value = false;
            const fileList = e.target.files ? e.target.files : e.dataTransfer.files;

            if(fileList.length === 0){
                return false;
            }

            let files = [];
            for(let i = 0; i < fileList.length; i++){
                files.push(fileList[i]);
            }

            let file = files.length > 0 ? files[0] : [];
            if(file.type !== 'image/jpeg'){
                return false;
            }
            if(file.size > 10000000){
                return false;
            }

            recoveryLocationsInfo[index].fileName = file.name;
            const mapType = index == 0 ? contactBase : recoveryImplementation;

            let fd = new FormData();
            fd.append('file', file);
            fd.append('bcp_id', props.bcp_basic_frame_info.id);
            fd.append('version', props.bcp_basic_frame_info.version);
            fd.append('map_type', mapType);
            fd.append('map_id', recoveryLocationsInfo[index].mapId ?? '');

            const url = '/api/bcp/postBcpMapImage';
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(url, fd, config).then(res => {
                recoveryLocationsInfo[index].mapId = res.data.image_id
            }).finally(fin => {
                e.target.value = null;
            });
        }

        // 復旧目標登録
        const registerFirst4 = async () => {
            // リクエスト内容の生成
            // 要員
            const staffsInfo = businessRecoveryStaffsInfo.concat(livelihoodSupportStaffsInfo);
            staffsInfo.forEach(data => {
                // 要員の閲覧制限
                data.isViewLimit = staffsViewLimit.value;
            });

            // text値
            const textForms = textFormsField1.concat(textFormsField2);

            // 資金調達の閲覧制限
            textForms.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'view_limit',
                value: financingViewLimit.value,
            });

            // リクエスト送信
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst4';
            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'recovery_locations_info': recoveryLocationsInfo,
                'staffs_info': staffsInfo,
                'core_business_additional_info': coreBusinessAdditionalInfo,
                'value_form_list': valueForms.filter(data => data.value != ''),
                'text_form_list': textForms.filter(data => data.value != ''),
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            businessRecovery,
            livelihoodSupport,
            recoveryLocationsInfo,
            businessRecoveryStaffsInfo,
            livelihoodSupportStaffsInfo,
            implementationFiled,
            businessRecoveryFiled,
            livelihoodSupportFiled,
            coreBusinessAdditionalInfo,
            valueForms,
            textFormsField1,
            textFormsField2,
            staffsViewLimit,
            financingViewLimit,
            addStaffSet,
            deleteRecoveryStaffSet,
            deleteSupportStaffSet,
            addCoreBusinessAdditionalSet,
            deleteCoreBusinessAdditionalSet,
            callReplaceWord,
            showBack,
            showNext,
            showContents,
            showAdvanced,
            getMemberInfo,
            isEnter,
            dragEnter,
            dragLeave,
            files,
            dropFile,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        recovery_locations_info: {
            type: Object,
        },
        core_business_list: {
            type: Object,
        },
        staffs_info: {
            type: Object,
        },
        core_business_additional_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
        bcp_texts_info: {
            type: Object,
        },
        is_completed_middle: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>
.bg-blue {
    background-color: #4472c4;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
}

input[type="file"] {
    display: none;
}
</style>
