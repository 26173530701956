<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:12pt">
            4. 財務診断と事前対策計画｜復旧費用総額の算出・調達
        </div>

        
        <div class="col-md-4 d-flex justify-content-evenly">
            <h4 class="align-self-end"><span class="badge bg-badge-yellow">製造業</span></h4>
            <h5 class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る"></h5>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row">
        <div class="mt-5 col-md-1 ms-auto d-flex flex-row">
            <input type="button" class="btn btn-bcp-restrictions btn-sm" value="閲覧制限｜設定">
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-blue border-green">
                    <div class="card-body">

                        <!--------------------------------------復旧費用総額（C）の算出---------------------------------------------------------------->

                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="p2 d-flex align-items-center justify-content-start ">
                                <label for="" class="form-label">復旧費用総額（C）の算出</label>
                            </div>
                            <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
                                <label for="" class="col-md-11 form-label">&emsp;</label>
                                <label for="" class="form-label">単位：千円</label>
                            </div>
                        </div>

                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                科目
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                資産の復旧費用金額
                            </div>
                            <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                事業中断によるキャッシュフローの悪化額
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
                                復旧費用総額（C）
                            </div>
                            <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>'「資産の復旧費用（A）＋事業中断によるキャッシュフローの悪化額（B）＝復旧費用総額（C）」として、この後財務的な検討を行います。金額が大きいと再建が不能だと感じますが、まずは、金額を把握しBCPの具体化につなげましょう。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                金額
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                            <div class="p2 col-md-4 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 bg-light d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-2 border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                備考
                            </div>
                            <div class="p2 col-md-2 text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                （A）
                            </div>
                            <div class="p2 col-md-4 text-center border border-dark border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                （B）
                            </div>
                            <div class="p2 col-md-2 text-center border border-dark bg-light-gray d-flex align-items-center justify-content-start">
                                （A）＋（B）
                            </div>
                        </div>

                        <!--------------------------------------復旧費用の調達---------------------------------------------------------------->

                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="p2 d-flex align-items-center justify-content-start ">
                                <label for="" class="form-label">復旧費用の調達</label>
                            </div>

                            <!--------------------------------------① 自力で賄えるケース---------------------------------------------------------------->

                            <div class="row ms-1 d-flex flex-row">
                                <div class="p2 d-flex align-items-center justify-content-start ">
                                    <label for="" class="form-label">① 自力で賄えるケース</label>
                                </div>

                                <div class="row d-flex flex-row">
                                    <div class="p2 col-md-3 text-white bg-resilience-orange d-flex align-items-center justify-content-center">
                                        復旧費用総額（C）
                                    </div>
                                    <div class="p2 col-md-1 d-flex align-items-center justify-content-center" style="font-size:16pt">
                                        ＜
                                    </div>
                                    <div class="p2 col-md-3 bg-funds d-flex align-items-center justify-content-center">
                                        手許資金（D1）
                                    </div>
                                    <div class="p2 col-md-1 d-flex align-items-center justify-content-center" style="font-size:16pt">
                                        ＝
                                    </div>
                                    <div class="p2 col-md-3 text-white bg-resilience-gray d-flex align-items-center justify-content-start">
                                        自力で賄える
                                    </div>
                                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                        <VDropdown
                                            theme="hint-theme"
                                        >
                                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                            <template #popper>
                                                <div class="row">
                                                    <label>'復旧費用総額（C）が手許資金（D1）を下回れば、自力で賄えます。<br />あなたの{{ callReplaceWord('組織') }}は災害時にお金のことは心配せず、復旧対策に専念してください。<br />しかし、お金の心配は要らなくとも、平素から防災事前対策を行い、{{ callReplaceWord('従業員') }}の生命の保護とあなたの{{ callReplaceWord('組織') }}の存続の可能性を高めるための努力をしましょう。<br />なお、緊急時に備えて、手元現金・預金は月の{{ callReplaceWord('売上') }}の1ヶ月分くらいは持っているようにしましょう</label>
                                                </div>
                                            </template>
                                        </VDropdown>
                                    </div>
                                </div>

                                <div class="p2 mt-3 d-flex align-items-center justify-content-start ">
                                    <label for="" class="col-md-11 form-label">&emsp;</label>
                                    <label for="" class="form-label">単位：千円</label>
                                </div>

                                <div class="table-responsive-md">
                                    <table class="table table-bordered border-dark">
                                        <tbody>
                                            <tr>
                                                <td class="bg-light-gray text-center" rowspan="" colspan="2">復旧費用総額</td>
                                                <td class="bg-light-gray" colspan="2">調達可能金額</td>
                                                <td class="bg-light-gray" colspan="">過不足金額</td>
                                            </tr>

                                            <tr>
                                                <td class="bg-light" colspan="">（復旧費用）</td>
                                                <td class="bg-light-gray" colspan="">&emsp;</td>
                                                <td class="bg-light" colspan="">現金・預金</td>
                                                <td class="bg-white" colspan="">&emsp;</td>
                                                <td class="bg-light" rowspan="5" colspan="">&emsp;</td>
                                            </tr>

                                            <tr>
                                                <td class="bg-light" rowspan="2" colspan="">資産の復旧費用（A）</td>
                                                <td class="bg-light" rowspan="2" colspan="">&emsp;</td>
                                                <td class="bg-light" colspan="">損害保険金</td>
                                                <td class="bg-white" colspan="">&emsp;</td>
                                            </tr>

                                            <tr>
                                                <td class="bg-light" colspan="">会社資産売却</td>
                                                <td class="bg-white" colspan="">&emsp;</td>
                                            </tr>

                                            <tr>
                                                <td class="bg-light" colspan="">事業中断によるキャッシュフローの悪化額（B）</td>
                                                <td class="bg-light-gray" colspan="">&emsp;</td>
                                                <td class="bg-light" colspan="">会社調達分　計</td>
                                                <td class="bg-light" colspan="">&emsp;</td>
                                            </tr>

                                            <tr>
                                                <td class="bg-light" colspan="">&emsp;</td>
                                                <td class="bg-light-gray" colspan="">&emsp;</td>
                                                <td class="bg-light" colspan="">経営者から支援</td>
                                                <td class="bg-white" colspan="">&emsp;</td>
                                            </tr>

                                            <tr>
                                                <td class="bg-light" rowspan="" colspan="">計（A＋B＝C）</td>
                                                <td class="bg-light" rowspan="" colspan="">&emsp;</td>
                                                <td class="bg-light" colspan="">手元資金　計（D1）</td>
                                                <td class="bg-white" colspan="">&emsp;</td>
                                                <td class="bg-light-gray" rowspan="" colspan="">&emsp;</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <!--------------------------------------② 自力で賄えないケース---------------------------------------------------------------->

                                <div class="row mt-3 d-flex flex-row">
                                    <div class="p2 d-flex align-items-center justify-content-start ">
                                        <label for="" class="form-label">② 自力で賄えないケース</label>
                                    </div>

                                    <div class="row d-flex flex-row">
                                        <div class="p2 col-md-3 text-white bg-resilience-orange d-flex align-items-center justify-content-center">
                                            復旧費用総額（C）
                                        </div>
                                        <div class="p2 col-md-1 d-flex align-items-center justify-content-center" style="font-size:16pt">
                                            ＞
                                        </div>
                                        <div class="p2 col-md-3 bg-funds d-flex align-items-center justify-content-center">
                                            手許資金（D1）
                                        </div>
                                        <div class="p2 col-md-1 d-flex align-items-center justify-content-center" style="font-size:16pt">
                                            ＝
                                        </div>
                                        <div class="p2 col-md-3 text-white bg-resilience-gray d-flex align-items-center justify-content-start">
                                            新規借入必要金額（E）
                                        </div>
                                        <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                            <VDropdown
                                                theme="hint-theme"
                                            >
                                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                <template #popper>
                                                    <div class="row">
                                                        <label>復旧費用総額（C）が手許資金（D1）の金額を上回ると、自力では賄えませんので、新規借入必要金額（E）を計算する必要があります。</label>
                                                    </div>
                                                </template>
                                            </VDropdown>
                                        </div>
                                    </div>
                                    <!--------------------------------------○ 借入金額の算定---------------------------------------------------------------->

                                    <div class="row mt-3 d-flex flex-row">
                                        <div class="p2 d-flex align-items-center justify-content-start ">
                                            <label for="" class="form-label">○ 借入金額の算定</label>
                                        </div>

                                        <div class="p2 d-flex align-items-center justify-content-start ">
                                            <label for="" class="col-md-11 form-label">&emsp;</label>
                                            <label for="" class="form-label">単位：千円</label>
                                        </div>

                                        <div class="table-responsive-md">
                                            <table class="table table-bordered border-dark">
                                                <tbody>
                                                    <tr>
                                                        <td class="bg-light-gray text-center" rowspan="" colspan="2">復旧費用総額</td>
                                                        <td class="bg-light-gray" colspan="2">調達可能金額</td>
                                                        <td class="bg-light-gray" colspan="">過不足金額</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light" colspan="">（復旧費用）</td>
                                                        <td class="bg-light-gray" colspan="">&emsp;</td>
                                                        <td class="bg-light" colspan="">現金・預金</td>
                                                        <td class="bg-white" colspan="">&emsp;</td>
                                                        <td class="bg-light" rowspan="6" colspan="">&emsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light" rowspan="2" colspan="">資産の復旧費用（A）</td>
                                                        <td class="bg-light" rowspan="2" colspan="">&emsp;</td>
                                                        <td class="bg-light" colspan="">損害保険金</td>
                                                        <td class="bg-white" colspan="">&emsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light" colspan="">会社資産売却</td>
                                                        <td class="bg-white" colspan="">&emsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light" colspan="">事業中断によるキャッシュフローの悪化額（B）</td>
                                                        <td class="bg-light-gray" colspan="">&emsp;</td>
                                                        <td class="bg-light" colspan="">会社調達分　計</td>
                                                        <td class="bg-light" colspan="">&emsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light" rowspan="2" colspan="">&emsp;</td>
                                                        <td class="bg-light-gray" rowspan="2" colspan="">&emsp;</td>
                                                        <td class="bg-light" colspan="">経営者から支援</td>
                                                        <td class="bg-white" colspan="">&emsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light-gray" colspan="">新規借入必要金額（C-D1＝E）</td>
                                                        <td class="bg-light-gray" colspan="">&emsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-light" rowspan="" colspan="">計（A＋B＝C）</td>
                                                        <td class="bg-light" rowspan="" colspan="">&emsp;</td>
                                                        <td class="bg-light" colspan="">手元資金　計（D1）</td>
                                                        <td class="bg-white" colspan="">&emsp;</td>
                                                        <td class="bg-light-gray" rowspan="" colspan="">&emsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                        <!--------------------------------------○ 返済原資の計算---------------------------------------------------------------->

                                        <div class="row mt-3 d-flex flex-row">
                                            <div class="p2 d-flex align-items-center justify-content-start ">
                                                <label for="" class="form-label">○ 返済原資の計算</label>
                                            </div>

                                            <div class="p2 d-flex align-items-center justify-content-start ">
                                                <label for="" class="col-md-9 form-label">&emsp;</label>
                                                <label for="" class="form-label">単位：千円</label>
                                            </div>

                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-bottom-0 border-end-0 bg-light-gray d-flex align-items-center justify-content-start">
                                                    科目
                                                </div>
                                                <div class="p2 col-md-4 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
                                                    金額
                                                </div>
                                                <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                                    <VDropdown
                                                        theme="hint-theme"
                                                    >
                                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                        <template #popper>
                                                            <div class="row">
                                                                <label>緊急事態後、2年たったら少なくとも現状には復帰しているものと仮定した計算書です。<br />返済期間が異なる場合は、各々のケースで計算してください。<br />返済原資検討の結果、新規借入が返済の面から全額は無理な場合には、防災事前対策を行って復旧費用を減らすことを考えるべきです。<br />また、{{ callReplaceWord('事業') }}を復旧するにあたり2年後（据置期間終了後には）に従来以上の利益を上げるよう{{ callReplaceWord('事業') }}内容を改善することを考えなければなりません。</label>
                                                            </div>
                                                        </template>
                                                    </VDropdown>
                                                </div>
                                            </div>

                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                                    Ⅰ．税引後当期純利益
                                                </div>
                                                <div class="p2 col-md-4 border border-dark border-bottom-0 bg-light d-flex align-items-center justify-content-start">
                                                    &emsp;
                                                </div>
                                            </div>

                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                                    Ⅱ．年間減価償却金額
                                                </div>
                                                <div class="p2 col-md-4 border border-dark border-bottom-0 bg-light d-flex align-items-center justify-content-start">
                                                    &emsp;
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                                    Ⅲ．返済原資合計（Ⅰ＋Ⅱ）
                                                </div>
                                                <div class="p2 col-md-4 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
                                                    &emsp;
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                                    Ⅳ．既往借入金年間 約定返済額
                                                </div>
                                                <div class="p2 col-md-4 border border-dark border-bottom-0 bg-light d-flex align-items-center justify-content-start">
                                                    &emsp;
                                                </div>
                                                <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                                    <VDropdown
                                                        theme="hint-theme"
                                                    >
                                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                        <template #popper>
                                                            <div class="row">
                                                                <label>すでに借入金がある場合は、金額を記入してください。</label>
                                                            </div>
                                                        </template>
                                                    </VDropdown>
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-bottom-0 border-end-0 bg-light d-flex align-items-center justify-content-start">
                                                    Ⅴ．新規借入金返済原資（年間）（Ⅲ－Ⅳ）
                                                </div>
                                                <div class="p2 col-md-4 border border-dark border-bottom-0 bg-light-gray d-flex align-items-center justify-content-start">
                                                    &emsp;
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-row">
                                                <div class="p2 col-md-6 border border-dark border-end-0 bg-light d-flex align-items-center justify-content-start">
                                                    Ⅵ．新規借入可能金額（e）（Ⅴ×8）
                                                </div>
                                                <div class="p2 col-md-4 border border-dark bg-light d-flex align-items-center justify-content-start">
                                                    &emsp;
                                                </div>
                                                <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                                    <VDropdown
                                                        theme="hint-theme"
                                                    >
                                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                                        <template #popper>
                                                            <div class="row">
                                                                <label>据置期間2年、返済期間8年のケースです。（e）の金額以上借りると、あなたの{{ callReplaceWord('組織') }}は返済ができないということになります。</label>
                                                            </div>
                                                        </template>
                                                    </VDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-12 align-items-center justify-content-start">
            <input type="button" class="ms-4 btn btn-bcp-middle text-white" value="4. 財務診断と事前対策計画｜キャッシュフロー対策の考え方・事前対策 へ" style="font-size:12pt" @click="showNext">
            <input type="button" class="ms-3 btn btn-bcp-basic text-white" value="目次構成 へ戻る" style="font-size:10.5pt">
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');

        const showNext = () => {
            const url = '/bcp/bcp_financial/製6';

            location.href = url;
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        return {
            setLevel,

            showNext,

            callReplaceWord,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-yellow {
    background-color: #ffd966;
    border: 1px solid #404040;
    color: #404040;
}

.bg-funds {
    background-color: #009900;
    color: white;
}
</style>