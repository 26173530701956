<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 資産の損害に対する損害保険の加入状況</label>
                </div>
            </div>
            <div class="width-100">
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-8 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                        <label class="form-label">火災保険（資産の損害対策）</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">普通火災一般物件</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">普通火災工場物件</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">加入の有無</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions104" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions104" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00104'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions104" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions104" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00104'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions105" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions105" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00105'][1]">
                            <label class="form-check-label" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions105" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions105" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00105'][1]">
                            <label class="form-check-label" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">自然災害</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">①落雷</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">②風・雹・雪災</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">③水害</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">④地震</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">人災等</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑤火災</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑥破裂・爆裂</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑦飛来・落下・衝突</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑧水濡れ</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑨破壊</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑩盗難</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑪破損</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">その他1</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">テロ</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">その他2</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">電気的機械的事故</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">災害の際の出費</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">臨時費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">残存物片付費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">失火見舞費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">地震火災費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][4] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">修理付帯費用</label>
                    </div>
                    <div class="p2 col-2  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][4] || '&emsp;' }}</div>
                    </div>
                </div>
            </div>
            <div class="width-100">
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-4 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-8 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                        <label class="form-label">火災保険（資産の損害対策）</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">店舗総合保険</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">オールリスク型</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">加入の有無</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions106" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions106" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00106'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions106" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions106" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00106'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions107" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions107" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00107'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions107" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions107" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00107'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">付保対象</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">保険金額（円）</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">建物</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00108'][1] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">什器備品・機械設備等</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00109'][1] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-4 border border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">商品・製品等</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00110'][1] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">自然災害</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">①落雷</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00111'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">②風・雹・雪災</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00112'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">③水害</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00113'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">④地震</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00114'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">人災等</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑤火災</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00115'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑥破裂・爆裂</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00116'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑦飛来・落下・衝突</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00117'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑧水濡れ</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00118'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑨破壊</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00119'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑩盗難</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00120'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑪破損</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00121'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">その他1</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">テロ</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00122'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">その他2</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">電気的機械的事故</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00123'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">災害の際の出費</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">臨時費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00124'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">残存物片付費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00125'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">失火見舞費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00126'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">地震火災費用</label>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00127'][8] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-2 border border-top-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">修理付帯費用</label>
                    </div>
                    <div class="p2 col-2  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][6] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][7] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00128'][8] || '&emsp;' }}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">・　建設業に対しては、別に建設工事保険があり、天災は特約の対象になっています。</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 事業中断の損害に対する保険の加入状況</label>
                </div>
            </div>
            <div class="width-100 mt-3">
                <div class="row ms-md-1">
                    <div class="p2 col-3 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-9 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-center">
                        <label class="form-label">利益保険（事業中断の損害対策）</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-3 border border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">利益保険</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">営業継続費用保険</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                        <label class="form-label">オールリスク型</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">加入の有無</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions129" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions129" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00129'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions129" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions129" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00129'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions130" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions130" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00130'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions130" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions130" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00130'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions131" id="inlineRadio1" value="あり" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions131" id="inlineRadio1" value="あり" onclick="return false;" v-model="valueForms['K00131'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio1">あり</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-if="!is('administrator') && isViewLimit" class="form-check-input" type="radio" name="inlineRadioOptions131" id="inlineRadio2" value="なし" onclick="return false;">
                            <input v-else class="form-check-input" type="radio" name="inlineRadioOptions131" id="inlineRadio2" value="なし" onclick="return false;" v-model="valueForms['K00131'][1]">
                            <label class="form-check-label text-nowrap" for="inlineRadio2">なし</label>
                        </div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">約定てん補期間方式　保険金額（円）</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00132'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                        <div class="col-form-label border-white bg-white"></div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00132'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">約定付保割合方式　保険金額（円）</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00133'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                        <div class="col-form-label border-white bg-white"></div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-center">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00133'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">営業継続費用条項</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧するまでの予想時間（ヶ月）</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="col-form-label border-white bg-white"></div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00134'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00134'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">保険金額（円）</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="col-form-label border-white bg-white"></div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00135'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00135'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">自然災害</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">①落雷</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00136'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00136'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00136'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00136'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00136'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00136'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">②風・雹・雪災</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00137'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00137'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00137'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00137'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00137'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00137'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">③水害</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00138'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00138'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00138'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00138'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00138'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00138'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">④地震</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00139'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00139'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00139'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00139'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00139'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00139'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">人災等</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑤火災</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00140'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00140'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00140'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00140'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00140'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00140'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑥破裂・爆裂</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00141'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00141'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00141'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00141'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00141'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00141'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑦飛来・落下・衝突</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00142'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00142'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00142'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00142'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00142'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00142'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑧水濡れ</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00143'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00143'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00143'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00143'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00143'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00143'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑨破壊</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00144'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00144'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00144'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00144'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00144'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00144'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑩盗難</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00145'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00145'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00145'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00145'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00145'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00145'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">⑪破損</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00146'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00146'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00146'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00146'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00146'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00146'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">その他1</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">集団感染等による操業停止</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00147'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00147'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00147'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00147'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00147'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00147'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">その他2</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">構外ユーティリティ設備の供給の停止</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00148'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00148'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00148'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00148'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00148'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00148'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">隣接物に生じた①～③、⑤～⑪の事故</label>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00149'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00149'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00149'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00149'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00149'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00149'][6] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-1  border border-top-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">電気的・機械的事故</label>
                    </div>
                    <div class="p2 col-1  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00150'][1] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00150'][2] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00150'][3] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00150'][4] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1  border border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00150'][5] || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border bg-info d-flex align-items-center justify-content-start">
                        <div class="col-form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : valueForms['K00150'][6] || '&emsp;' }}</div>
                    </div>
                </div>
            </div>
            <div class="row pb-5">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label">・　△は、特約を付帯した場合に担保されます。</label>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const isViewLimit = ref(false);

        const valueForms = reactive({
            view_limit: {
                value: '',
            }
        });
        for (let i = 104; i <= 150; i++) {
            valueForms[`K00${i}`] = {};
        }

        const showBack = () => {
            const url = '/browse/K5';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K7';
            location.href = url;
        }

        // 取得データをセット
        if (props.bcp_values_info.length > 0) {
            Object.keys(valueForms).forEach((columnName) => {
                if (columnName === 'view_limit') {
                    valueForms[columnName].value = props.bcp_values_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.value;
                } else {
                    const data = props.bcp_values_info
                        .filter(
                            (fetchData) => fetchData.column_name === columnName
                        )
                        .sort((a, b) => (a.seq < b.seq ? -1 : 1));

                    data.forEach(
                        (d) => (valueForms[columnName][d.seq] = d.value)
                    );
                }
            });
        }

        isViewLimit.value = valueForms['view_limit'].value === '1';

        return {
            setLevel,
            isMargin1,
            isViewLimit,
            valueForms,
            showBack,
            showNext,
        }
    },
    props:{
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-100 {
    width: 100%;
}

@media (max-width: 576px) {
    .width-100 {
        font-size: 0.7em;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
