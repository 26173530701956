<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">支払状況照会</div>
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>

            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row my-3">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="getPaymentStatusList(current_page)">
                        <div class="row">
                            <label class="fw-bold">検索条件</label>
                        </div>
                        <div class="row">
                            <label class="col-form-label col-md-2">会員ID/SBP 顧客ID</label>
                            <div class="col-md-3">
                                <input type="text" class="form-control" v-model="search会員ID" />
                            </div>
                            <label class="col-form-label col-md-1">組織名</label>
                            <div class="col-md-3">
                                <input type="text" class="form-control" v-model="search組織名" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label class="col-form-label col-md-2">利用開始日</label>
                            <div class="col-md-3 d-flex justify-content-start">
                                <input type="date" class="form-control" v-model="search利用開始日From" />
                                <label class="col-form-label">～</label>
                                <input type="date" class="form-control" v-model="search利用開始日To" />
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="withPayed" v-model="withPayed">
                                    <label class="form-check-label" for="withPayed">
                                        支払済みを含む
                                    </label>                                </div>
                                <input type="submit" class="btn btn-sm btn-orange" value="検索" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <table class="table table-bordered table-light table-striped table-hover table-sm">
                <thead>
                    <tr>
                        <th>請求ID</th>
                        <th>会員ID</th>
                        <th>SBP 顧客ID</th>
                        <th>会員名</th>
                        <th>利用開始日</th>
                        <th>請求日</th>
                        <!-- <th>有効期限日</th> -->
                        <th>請求額</th>
                        <th>支払日</th>
                        <th>支払方法</th>
                        <th>未収会員</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bill in paymentStatusList" :key="bill.id">
                        <td>{{ bill.id }}</td>
                        <td>{{ bill.会員ID }}</td>
                        <td>{{ bill.uuid }}</td>
                        <td>{{ bill.組織名 }}</td>
                        <td>{{ this.$root.dayjs(bill.利用開始日).format('YYYY/MM/DD') }}</td>
                        <td>{{ bill.請求日 ? this.$root.dayjs(bill.請求日).format('YYYY/MM/DD') : '' }}</td>
                        <!-- <td>{{ this.$root.dayjs(bill.有効期限日).format('YYYY/MM/DD') }}</td> -->
                        <td class="text-end">{{ formatNumber(bill.請求額) }}</td>
                        <td><input type="date" class="form-control form-control-sm" v-model="bill.支払完了日"><input type="button" class="btn btn-red btn-sm" value="更新" @click="updateBill(bill.id, bill.請求額, bill.支払完了日)"></td>
                        <td>{{ bill.支払方法 }}</td>
                        <!-- <td><input type="button" class="btn btn-black btn-sm" value="支払確認" @click="updateBill(bill.id, bill.請求額)" :disabled="isProcessing || bill.支払完了日" :visible="bill.支払完了日 != undefined"></td> -->
                        <td><Toggle v-model="bill.未収flg" :id="'toggle-' + bill.id" @click="updateBillFlg(bill.id)"></Toggle><label class="ms-2">未収</label></td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="col-sm-6 text-right">全 {{total}} 件中 {{from}} 〜 {{to}} 件表示</div>
                <ul class="pagination pagination-sm">
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link" @click="change(1)" aria-label="Top">&laquo;</a></li>
                    <li :class="[{disabled: current_page <= 1}, 'page-item']"><a href="#" class="page-link" @click="change(current_page - 1)" aria-label="Previous">&lt;</a></li>
                    <li v-for="page in pages" :key="page" :class="[{active: page === current_page}, 'page-item']">
                        <a href="#" class="page-link" @click="change(page)">{{page}}</a>
                    </li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link" @click="change(current_page + 1)" aria-label="Next">&gt;</a></li>
                    <li :class="[{disabled: current_page >= last_page}, 'page-item']"><a href="#" class="page-link" @click="change(last_page)" aria-label="Last">&raquo;</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { CommonNumberFormat } from '../../../function/CommonNumberFormat.js';
import Toggle from '@vueform/toggle';
import axios from 'axios';

export default {
    components: {
        Toggle,
    },
    setup(props) {
        onMounted(() => {
            getPaymentStatusList(1);
        });

        const withPayed = ref(false);

        const paymentStatusList = ref([]);

        const getPaymentStatusList = (page) => {
            const url="/api/s-admin/getPaymentStatus";
            
            axios.post(url, {
                page: page,
                withPayed: withPayed.value,
                会員ID: search会員ID.value,
                組織名: search組織名.value,
                利用開始日From: search利用開始日From.value,
                利用開始日To: search利用開始日To.value,
                請求額From: search請求額From.value,
                請求額To: search請求額To.value,
            }).then(res=>{
                current_page.value = res.data.current_page;
                last_page.value = res.data.last_page;
                total.value = res.data.total;
                from.value = res.data.from;
                to.value = res.data.to;
                paymentStatusList.value.splice(0);
                res.data.data.forEach(item=>{
                    paymentStatusList.value.push({
                        id: item.id,
                        corp_id: item.corp_id,
                        会員ID: item.会員ID,
                        法人番号: item.法人番号,
                        uuid: item.uuid,
                        組織名: item.組織名,
                        支払区分: item.支払区分,
                        支払方法: item.支払方法,
                        利用開始日: item.利用開始日,
                        有償会員切替日: item.有償会員切替日,
                        有効期限日: item.有効期限日,
                        年度: item.年度,
                        請求日: item.請求日,
                        請求額: item.請求額,
                        支払完了日: item.支払完了日,
                        未収flg: item.未収flg == 1 ? true: false,
                    })
                })
                // paymentStatusList.value = [...res.data.data];
            });

        }

        const change = (page) => {
            getPaymentStatusList(page);
        }

        const current_page = ref(1);
        const last_page = ref(1);
        const total = ref(0);
        const from = ref(0);
        const to = ref(0);

        const pages = computed(() => {
            // let start = _.max([current_page.value - 2, 1])
            // let end = _.min([start + 5, last_page.value + 1])
            // start = _.max([end - 5, 1])
            // return _.range(start, end)
            let start = Math.max(current_page.value - 2, 1);
            let end = Math.min(start + 5, last_page.value + 1);
            start = Math.max(end - 5, 1);
    
            // Array.from を使って範囲を生成する方法
            return Array.from({ length: end - start }, (_, index) => start + index);
        })

        const back = () => {
            const url = '/home';

            location.href = url;
        }

        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        const updateBill = (bill_id, 請求額, 支払完了日) => {
            if(支払完了日  == null || 支払完了日 == ""){
                return;
            }
            const url = '/api/s-admin/payed';
            isProcessing.value = true;
            axios.post(url, {
                id: bill_id,
                支払額: 請求額,
                支払完了日: 支払完了日,
            }).then(res => {
                visibleFlashMessage('正常に更新されました', 'success');
                getPaymentStatusList(current_page);
            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        const updateBillFlg = (bill_id) => {
            const url = '/api/s-admin/updateNotPayFlg';
            isProcessing.value = true;
            axios.post(url, {
                id: bill_id,
            }).then(res => {
                // visibleFlashMessage('正常に更新されました', 'success');
                // getPaymentStatusList(current_page);
            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        const search会員ID = ref('');
        const search組織名 = ref('');
        const search利用開始日From = ref('');
        const search利用開始日To = ref('');
        const search請求額From = ref('');
        const search請求額To = ref('');

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const isProcessing = ref(false);

        return {
            withPayed,
            paymentStatusList,
            getPaymentStatusList,
            change,
            current_page,
            last_page,
            total,
            from,
            to,
            pages,

            back,

            formatNumber,
            updateBill,
            updateBillFlg,

            search会員ID,
            search組織名,
            search利用開始日From,
            search利用開始日To,
            search請求額From,
            search請求額To,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            isProcessing,

        }
    },
    props: {
    }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>
