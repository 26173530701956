<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜情報連絡（つづき）
        </div>
        <div class="mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="card-group">
        <div class="card bg-light-blue border-green">
            <div class="card-body" style="padding-right: 2.5rem;">
                <div class="row ms-3">
                    <div class="p2">
                        ◯　主要{{ callReplaceWord('顧客') }}情報
                    </div>
                </div>

                <template v-for="(mainCompany, index) in mainCompanyInfo" :key="index">
                    <div class="row ms-3 mt-3">
                        <div class="col-md-12 border border-dark border-bottom-0 bg-light-gray text-white d-flex align-items-center justify-content-start">
                            <span v-if="index == 0">&emsp;</span>
                            <input type="button" class="btn btn-delete mt-3" value="削除" v-if="index > 0" @click="deleteMainCompany(index)">
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            提供する製品／材料／サービス
                        </div>
                    </div>
                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-10 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.provisionService">
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            平時における製品等の提供手段（輸送手段等）
                        </div>
                    </div>
                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-10 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.method">
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            TOTONO-L 会員ID
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control bg-resilience-light-blue d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.corpId">
                        </div>
                        <button type="button" class="ms-3 col-md-1 btn btn-black my-2" @click="getMemberInfo(index, mainCompany.corpId)">
                            検　索
                        </button>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-4 bg-light-gray text-dark d-flex align-items-center justify-content-start">
                            ※入力すると主要顧客の情報が自動表示されます。
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-3 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            法人名／屋号
                        </div>
                        <div class="ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            口座番号（必要な場合）
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-6 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.corporateName">
                        </div>
                        <div class="ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.bankAccountNumber">
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            都道府県
                        </div>
                        <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            市区町村
                        </div>
                        <div class="ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            町名番地、ビル名等
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.prefecture">
                        </div>
                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.city">
                        </div>
                        <div class="ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.otherAddress">
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-3 col-md-5 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            ホームページアドレス
                        </div>
                        <div class="ms-3 mt-3 col-md-3 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            電話番号（代表）
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-5 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.url">
                        </div>
                        <div class="ms-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.representPhoneNumber">
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-4 mt-5 col-md-1 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                            第<br>
                            1<br>
                            連<br>
                            絡<br>
                            先<br>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="ms-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    部署
                                </div>
                                <div class="ms-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    担当者
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.firstContactDivision">
                                        </div>
                                        <div class="ms-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.firstContactPerson">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    電話番号
                                </div>
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    携帯電話等
                                </div>
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    FAX番号
                                </div>
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    E-mail
                                </div>
                                <div class="ms-2 col-md-1">
                                    <VDropdown
                                        theme="hint-theme"
                                    >
                                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                        <template #popper>
                                            <div class="row">
                                                <label>メールアドレスは、BCP発動時の連絡手段のほか、運用サイクルにおける合同訓練に使用できます。</label>
                                            </div>
                                        </template>
                                    </VDropdown>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.firstContactPhoneNumber">
                                        </div>
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.firstContactMobilePhoneNumber">
                                        </div>
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.firstContactFax">
                                        </div>
                                        <div class="ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.firstContactEmail">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-4 mt-5 col-md-1 border border-dark text-dark text-center bg-light-gray d-flex align-items-center justify-content-center text-bg-dark">
                            第<br>
                            2<br>
                            連<br>
                            絡<br>
                            先<br>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="ms-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    部署
                                </div>
                                <div class="ms-3 mt-5 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    担当者
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.secondContactDivision">
                                        </div>
                                        <div class="ms-3 col-md-3 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.secondContactPerson">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    電話番号
                                </div>
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    携帯電話等
                                </div>
                                <div class="ms-3 mt-3 col-md-2 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    FAX番号
                                </div>
                                <div class="ms-3 mt-3 col-md-4 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                                    E-mail
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.secondContactPhoneNumber">
                                        </div>
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.secondContactMobilePhoneNumber">
                                        </div>
                                        <div class="ms-3 col-md-2 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.secondContactFax">
                                        </div>
                                        <div class="ms-3 col-md-4 d-flex align-items-center justify-content-start py-2">
                                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.secondContactEmail">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 mt-5 col-md-6 bg-light-gray text-dark d-flex align-items-end justify-content-start">
                            備考
                        </div>
                    </div>
                    <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                        <div class="ms-3 col-md-11 d-flex align-items-center justify-content-start py-2">
                            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="mainCompany.note">
                        </div>
                    </div>

                    <div class="row ms-3">
                        <div class="p2 col-md-12 border border-dark border-top-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                            &emsp;
                        </div>
                    </div>
                </template>
                <div class="row mt-3">
                    <div class="p2 ms-3 col-md-10 d-flex align-items-start justify-content-start">
                    </div>
                    <div class="p2 ms-3 col-md-1 d-flex align-items-start justify-content-start">
                        <input type="button" class="btn btn-black" value="ワンセット増やす" @click="addMainCompanyInfoSet">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="card border-0 bg-transparent">
                <div class="card-body">
                    <div class="d-flex flex-row">
                        <VDropdown
                            theme="browsing_restrictions"
                        >
                            <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定" />
                            <template #popper>
                                <div class="d-flex justify-content-start">
                                    <label>閲覧制限</label>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="viewLimit">
                                        <label class="form-check-label" for="checkbox_1">
                                            あり
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="ms-5 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜資源へ進む" style="font-size:10.5pt" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.main_company_info.length > 0) {
                // 閲覧制限
                viewLimit.value = props.main_company_info[0].is_view_limit == 1 ? true : false;

                mainCompanyInfo.splice(0);
                props.main_company_info.forEach(fetchData => {
                    // 主要組織連絡先情報
                    mainCompanyInfo.push({
                        mainCompanyInfoId: fetchData.id,
                        contactType: fetchData.contact_type,
                        isViewLimit: fetchData.is_view_limit,
                        provisionService: fetchData.provision_service,
                        method: fetchData.method,
                        corpId: fetchData.corp_id,
                        corporateName: fetchData.corporate_name,
                        bankAccountNumber: fetchData.bank_account_number,
                        prefecture: fetchData.prefecture,
                        city: fetchData.city,
                        otherAddress: fetchData.other_address,
                        url: fetchData.url,
                        representPhoneNumber: fetchData.represent_phone_number,
                        firstContactDivision: fetchData.first_contact_division,
                        firstContactPerson: fetchData.first_contact_person,
                        firstContactPhoneNumber: fetchData.first_contact_phone_number,
                        firstContactMobilePhoneNumber: fetchData.first_contact_mobile_phone_number,
                        firstContactFax: fetchData.first_contact_fax,
                        firstContactEmail: fetchData.first_contact_email,
                        secondContactDivision: fetchData.second_contact_division,
                        secondContactPerson: fetchData.second_contact_person,
                        secondContactPhoneNumber: fetchData.second_contact_phone_number,
                        secondContactMobilePhoneNumber: fetchData.second_contact_mobile_phone_number,
                        secondContactFax: fetchData.second_contact_fax,
                        secondContactEmail: fetchData.second_contact_email,
                        note: fetchData.note,
                    })
                })
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const viewLimit = ref(0);
        const typeMainCustomer = '2';

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const mainCompanyInfo = reactive([
            {
                mainCompanyInfoId: '',
                contactType: typeMainCustomer,
                isViewLimit: viewLimit.value,
                provisionService: '',
                method: '',
                corpId: '',
                corporateName: '',
                bankAccountNumber: '',
                prefecture: '',
                city: '',
                otherAddress: '',
                url: '',
                representPhoneNumber: '',
                firstContactDivision: '',
                firstContactPerson: '',
                firstContactPhoneNumber: '',
                firstContactMobilePhoneNumber: '',
                firstContactFax: '',
                firstContactEmail: '',
                secondContactDivision: '',
                secondContactPerson: '',
                secondContactPhoneNumber: '',
                secondContactMobilePhoneNumber: '',
                secondContactFax: '',
                secondContactEmail: '',
                note: '',
            }
        ]);

        // 検索ボタン
        const getMemberInfo = (index, searchId) => {
            const url="/api/bcp/getMemberInfo";

            // 企業情報取得
            return axios.post(url, {
                corp_id: searchId,
            }).then(res=>{
                if (res.data.length === 0) {
                    mainCompanyInfo[index].corpId = null;
                    mainCompanyInfo[index].corporateName = null;
                    mainCompanyInfo[index].prefecture = null;
                    mainCompanyInfo[index].city = null;
                    mainCompanyInfo[index].otherAddress = null;
                    mainCompanyInfo[index].url = null;
                    mainCompanyInfo[index].representPhoneNumber = null;
                } else {
                    mainCompanyInfo[index].corpId = res.data.corp_id;
                    mainCompanyInfo[index].corporateName = res.data.corporate_name;
                    mainCompanyInfo[index].prefecture = res.data.prefecture;
                    mainCompanyInfo[index].city = res.data.city;
                    mainCompanyInfo[index].otherAddress = res.data.other_address;
                    mainCompanyInfo[index].url = res.data.url;
                    mainCompanyInfo[index].representPhoneNumber = res.data.phone_number;
                }
            });
        }

        // ワンセット増やすボタン
        const addMainCompanyInfoSet = () => {
            mainCompanyInfo.push({
                mainCompanyInfoId: '',
                contactType: typeMainCustomer,
                isViewLimit: viewLimit.value,
                provisionService: '',
                method: '',
                corpId: '',
                corporateName: '',
                bankAccountNumber: '',
                prefecture: '',
                city: '',
                otherAddress: '',
                url: '',
                representPhoneNumber: '',
                firstContactDivision: '',
                firstContactPerson: '',
                firstContactPhoneNumber: '',
                firstContactMobilePhoneNumber: '',
                firstContactFax: '',
                firstContactEmail: '',
                secondContactDivision: '',
                secondContactPerson: '',
                secondContactPhoneNumber: '',
                secondContactMobilePhoneNumber: '',
                secondContactFax: '',
                secondContactEmail: '',
                note: '',
            });
        }

        const deleteMainCompany = (index) => {
            if(!confirm((index + 1) + "番目（" + mainCompanyInfo[index].corporateName + "）を削除します。よろしいですか？")){
                return false;
            }

            mainCompanyInfo.splice(index, 1);
        }

        const showBack = async () => {
            // 主要顧客連絡先を登録
            const result = await registerSecond7();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 主要顧客連絡先を登録
            const result = await registerSecond7();

            if (result == '200') {
                const url = '/bcp/bcp_second/8';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 主要顧客連絡先を登録
            const result = await registerSecond7();

            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 主要組織連絡先を登録
            const result = await registerSecond7();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 主要顧客連絡先登録
        const registerSecond7 = async () => {
            // リクエスト内容の調整
            mainCompanyInfo.forEach(data => {
                // 閲覧制限
                data.isViewLimit = viewLimit.value;
            })

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond7';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'contact_type': typeMainCustomer,
                'main_company_info': mainCompanyInfo,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            viewLimit,
            callReplaceWord,
            mainCompanyInfo,
            getMemberInfo,
            addMainCompanyInfoSet,
            deleteMainCompany,
            showBack,
            showNext,
            showPrevious,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        main_company_info: {
            type: Object,
        },
    }

}
</script>
