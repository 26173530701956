<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　被害想定と事前対策｜重要{{ callReplaceWord('商品') }}・被害想定
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic" value="基本｜3.中核事業と復旧目標 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>
    <levelIcon
            :set_level="setLevel"
        />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2 font-orange">
            重要{{ callReplaceWord('商品') }}　―――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2">
            災害等の発生時には、限りある人員や資機材の範囲内で、あなたの{{ callReplaceWord('組織') }}の事業を継続させなければなりません。<br>そのため、限りある人員や資機材の中で優先的な{{ callReplaceWord('商品') }}を、あらかじめ取り決めておく必要があります。
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2">
            あなたの{{ callReplaceWord('組織') }}で、最も優先的に{{ callReplaceWord('販売') }}しなければならない重要{{ callReplaceWord('商品') }}を一つ記入してください。
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="row col-md-10">
            <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="valueForms1[0].value">
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>重要{{ callReplaceWord('商品') }}を選ぶ際は、「{{ callReplaceWord('商品') }}の{{ callReplaceWord('提供') }}が停止することで、自社の売上や{{ callReplaceWord('顧客') }}への影響が大きなもの」を記入してください。「○○社向けの△△商品」と、{{ callReplaceWord('顧客') }}名を記載しても構いません。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md  d-flex align-items-center justify-content-start py-2 font-orange">
            被害想定　―――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
    </div>

    <div class="row mx-3 mt-3">
        <span>起こりうる自然災害によって、{{ callReplaceWord('事業') }}活動が停止する場合があります。こうしたリスクを把握するため、想定される被害状況をあらかじめ予測する必要があります。</span>
        <span>まずは、あなたの{{ callReplaceWord('工場') }}等{{ callReplaceWord('組織') }}の位置におけるハザードマップを確認しましょう。</span>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-damage-assumption text-dark btn-lg" value="ハザードマップによる風水害・震度被害の想定" :disabled="isProcessing" @click="showHazardMap">
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 mt-3 col-md d-flex align-items-center justify-content-start">
            ハザードマップを確認した後、自然災害等によりあなたの{{ callReplaceWord('組織') }}の{{ callReplaceWord('工場') }}等が受ける影響をイメージしましょう。<br>
            最初に、大規模地震（震度5以上）で想定される影響を書き出し、次に強毒性の感染症で想定される影響も書き出してみましょう。
        </div>
    </div>

    <div id="page-link-2" class="row mx-3 mt-5">
        <div class="d-xl-flex justify-content-between">
            <input type="button" class="btn btn-black btn-lg" value="大規模地震（震度5弱以上）で想定される影響" :disabled="isProcessing" @click="showOwn">
            <input type="button" class="btn btn-new-window-button btn-lg" value="強毒性の感染症で想定される影響" :disabled="isProcessing" @click="showImpactByCorona">
            <input type="button" class="btn btn-bcp-basic btn-lg" value="基本｜4.　BCP発動フロー｜初動対応 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showActivationFlow">
        </div>
    </div>

    <div v-for="(form, formIndex) in valueForms2" :key="formIndex">
        <div v-if="formIndex >= 0 && formIndex <= 3">
            <div class="row ms-3 mt-5" v-if="formIndex === 0">
                <div class="p2 col-md-5 bg-sub-title text-dark d-flex align-items-center justify-content-start">
                    ◯　インフラへの影響
                </div>
            </div>
            <div class="row mx-3 mt-3" v-if="formIndex === 0">
                <span>大規模地震（震度5弱以上）で想定されるインフラへの影響をイメージし、あなたの{{ callReplaceWord('組織') }}に関わる具体的な影響を記載します。すでに入力されている内容に加筆・修正してください。</span>
                <span>また、その際に各インフラの利用の可否についてもイメージし、利用できない場合は「×」、わからない場合は「△」、利用できる場合は「○」を選択してください。</span>
            </div>
            <div class="row ms-3" :class="{'mt-3':formIndex === 0, 'mt-5':formIndex > 0}">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <span v-if="formIndex === 0">ライフライン</span>
                    <span v-else-if="formIndex === 1">情報通信</span>
                    <span v-else-if="formIndex === 2">道路</span>
                    <span v-else>鉄道</span>
                </div>
            </div>

            <div v-for="(val, valIndex) in form.values" :key="valIndex">
                <div class="row ms-3 mt-3" v-if="valIndex > 1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    </div>
                </div>
                <div class="row ms-3" v-if="valIndex >= 1">
                    <div class="p2 col-md-10 d-flex align-items-center justify-content-start" v-if="valIndex >= 1">
                        <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="val.value">
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === 1">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label v-if="formIndex === 0">災害発生時、ライフラインが停止した場合、業務状況がどうなるかをイメージし、加筆・修正してください。大きな災害が発生した場合、過去の災害では水道・ガスは1か月、電気は1週間程度で復旧しています。停止期間に合わせ、何ができて何ができないのか、また、どうすればよいかを検討することが必要です。</label>
                                    <label v-else-if="formIndex === 1">災害発生時に情報通信に与える影響をイメージし、加筆・修正してください。</label>
                                    <label v-else-if="formIndex === 2">災害発生時の道路状況をイメージし、加筆・修正してください。</label>
                                    <label v-else>災害発生時の鉄道運行状況をイメージし、加筆・修正してください。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === form.values.length - 1">
                        <input type="button" class="btn btn-black" value="行を増やす" @click="addLine(form.columnName)">
                    </div>
                </div>

                <div class="row ms-3 mt-3" v-if="valIndex == form.values.length - 1">
                    <div class="p2 col-md-4 d-flex align-items-center justify-content-start">
                        <span v-if="formIndex === 0">ライフライン利用の可否を選択してください</span>
                        <span v-else-if="formIndex === 1">情報通信利用の可否を選択してください</span>
                        <span v-else-if="formIndex === 2">道路利用の可否を選択してください</span>
                        <span v-else>鉄道利用の可否を選択してください</span>
                    </div>
                    <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                        <input type="radio" :id="'customRadioInline1' + formIndex" :name="'customRadioInline1' + formIndex" class="custom-control-input" value="×" v-model="form.values[0].value">
                        <label class="custom-control-label" :for="'customRadioInline1' + formIndex">&emsp;&emsp;×</label>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                    </div>
                    <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                        <input type="radio" :id="'customRadioInline2' + formIndex" :name="'customRadioInline2' + formIndex" class="custom-control-input" value="△" v-model="form.values[0].value">
                        <label class="custom-control-label" :for="'customRadioInline2' + formIndex">&emsp;&emsp;△</label>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                    </div>
                    <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                        <input type="radio" :id="'customRadioInline3' + formIndex" :name="'customRadioInline3' + formIndex" class="custom-control-input" value="○" v-model="form.values[0].value">
                        <label class="custom-control-label" :for="'customRadioInline3' + formIndex">&emsp;&emsp;○</label>
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label v-if="formIndex === 0">災害発生時、ライフラインが利用不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。</label>
                                    <label v-else-if="formIndex === 1">災害発生時、情報通信が利用不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。</label>
                                    <label v-else-if="formIndex === 2">災害発生時、道路の利用が不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。被害だけでなく、通行規制や渋滞などで通行できない場合もあります。</label>
                                    <label v-else>災害発生時、鉄道の利用が不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。なお、従業員の通勤や業務の鉄道利用とは関係しません。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>

        <hr v-if="formIndex === 4">

        <div v-if="formIndex >= 4">
            <div class="row ms-3 mt-5" v-if="formIndex === 4">
                <div class="p2 col-md-5 bg-sub-title text-dark d-flex align-items-center justify-content-start">
                    ◯　{{ callReplaceWord('組織') }}への影響
                </div>
            </div>

            <div class="row mx-3 mt-3" v-if="formIndex === 4">
                <span>大規模地震（震度5弱以上）で想定される、あなたの{{ callReplaceWord('組織') }}への影響をイメージします。すでに入力されている内容に加筆・修正してください。</span>
            </div>

            <div class="row ms-3" :class="{'mt-3':formIndex === 4, 'mt-5':formIndex > 4}">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <span v-if="formIndex === 4">人（ヒト）</span>
                    <span v-else-if="formIndex === 5">物（モノ）</span>
                    <span v-else-if="formIndex === 6">金（カネ）</span>
                    <span v-else>情報</span>
                </div>
            </div>
            <div v-for="(val, valIndex) in form.values" :key="valIndex">
                <div class="row ms-3 mt-3" v-if="valIndex >= 1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    </div>
                </div>
                <div class="row ms-3">
                    <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                        <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="val.value">
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === 0">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label v-if="formIndex === 4">災害発生時、建物の倒壊や設備の転倒等が想定されますが、人に与える影響（死傷の危険性など）をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                    <label v-else-if="formIndex === 5">災害発生時の会社の「物」に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。地震発生時のオフィス内状況をイメージしするために、南海地震を想定した下記動画を参考にしてください。<a href="https://www.bosai.go.jp/hyogo/research/movie/movie.html" target="_blank">https://www.bosai.go.jp/hyogo/research/movie/movie.html</a>（提供：防災科学技術研究所　Ｅ－ディフェンス）【15】超高層建物のオフィス空間　 南海地震を想定した震動実験　(20080124_t1.wmv)　</label>
                                    <label v-else-if="formIndex === 6">災害発生時に起こり得る、売上や運転資金など金に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                    <label v-else>災害発生時に事業継続に関する重要なデータや情報（バックアップ含む）の破損、喪失、利用不能など会社の情報に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === form.values.length - 1">
                        <input type="button" class="btn btn-black" value="行を増やす" @click="addLine(form.columnName)">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction" value="入門｜被害想定と事前対策（つづき）へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction" value="目次構成 へ戻る" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted( async () => {
            // 取得データをセット
            if(props.bcp_introduction_values_info.length > 0) {
                // 重要商品の取得結果をセット
                const importantItem = props.bcp_introduction_values_info.filter(data => {
                    return data.column_name == 'N00016';
                });
                if (importantItem.length > 0) {
                    valueForms1.splice(0);
                    valueForms1.push({
                        'columnName': importantItem[0].column_name,
                        'value': importantItem[0].value,
                    });
                }

                // 被害想定の取得結果をセット
                const damagePrediction = props.bcp_introduction_values_info.filter(data => {
                    return columnNameRegex.test(data.column_name);
                });
                if (damagePrediction.length > 0) {
                    valueForms2.forEach(initData => {
                        initData.values.splice(0);
                        damagePrediction.forEach(data => {
                            // インフラへの影響の場合
                            if(data.column_name == initData.columnName && infraRegex.test(data.column_name)) {
                                // 取得データにチェック欄が存在するか確認
                                const result = damagePrediction.filter(value => {
                                    return value.column_name == data.column_name && value.seq == 1;
                                });
                                // 存在しない場合で、まだ１行もデータがない場合は空行を追加
                                if (result.length == 0 && initData.values.length == 0) {
                                    initData.values.push({value: ''});
                                }
                                initData.values.push({value: data.value});
                            } else if(data.column_name == initData.columnName) {
                                // 組織への影響の場合
                                initData.values.push({value: data.value});
                            }
                        });
                        // 該当カラムに対して取得データが１件も存在しなかった場合は空行を追加
                        if (initData.values.length == 0) {
                            initData.values.push({value: ''});
                        }
                    });

                    // 取得データがなかった場合の行数調整
                    valueForms2.forEach(initData => {
                        // 最低行数(ヒント、行を増やすボタン表示のために２カラム以上必要)に満たない場合、空行追加
                        if((infraRegex.test(initData.columnName) && initData.values.length == 1 )
                            || (organizationRegex.test(initData.columnName) && initData.values.length == 0)
                        ) {
                            initData.values.push(
                                {value: ''},
                                {value: ''},
                            );
                        } else if(
                            (infraRegex.test(initData.columnName) && initData.values.length == 2 )
                            || (organizationRegex.test(initData.columnName) && initData.values.length == 1)
                        ) {
                            initData.values.push({value: ''});
                        }
                    });
                }
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 1000);

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const columnNameRegex = new RegExp('^N0001[7-9]{1}$|^N0002[0-4]{1}$');
        const infraRegex = new RegExp('^N0001[7-9]{1}$|^N0002[0]{1}$');
        const organizationRegex = new RegExp('^N0002[1-4]{1}$');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms1 = reactive([
            {
                columnName: 'N00016',
                value: '',
            },
        ]);

        const valueForms2 = reactive([
            {
                columnName: 'N00017',
                values:[
                    {value: ''},
                    {value: '停電が発生し、水道とガスが停止する。'},
                    {value: 'その後、電気、水道、ガスの順番で復旧する。'},
                ]
            },
            {
                columnName: 'N00018',
                values:[
                    {value: ''},
                    {value: '電話やインターネット等が発生直後は、つながらなくなる。'},
                    {value: 'その後、ケーブル断線の復旧等により、順次復旧する。'},
                ]
            },
            {
                columnName: 'N00019',
                values:[
                    {value: ''},
                    {value: '一部の道路が通行規制となる。'},
                    {value: 'その他の道路で、渋滞が発生する。'},
                ]
            },
            {
                columnName: 'N00020',
                values:[
                    {value: ''},
                    {value: '発生直後は、鉄道の運行が完全に停止する。'},
                    {value: 'その後、被害の少ない地域から順次再開する。'},
                ]
            },
            {
                columnName: 'N00021',
                values:[
                    {value: '設備・什器類の移動・転倒、耐震性の低い建物の倒壊、津波の発生等により、一部の' + callReplaceWord('従業員') + 'が負傷する。'},
                    {value: callReplaceWord('従業員') + 'やその家族の負傷、交通機関の停止等により、一部の' + callReplaceWord('従業員') + 'が出社できなくなる。'},
                ]
            },
            {
                columnName: 'N00022',
                values:[
                    {value: callReplaceWord('工場') + '等が、大破・倒壊・浸水する。'},
                    {value: '固定していない設備・什器類が移動・転倒する。'},
                    {value: callReplaceWord('商品') + '・備品類が落下・破損する。'},
                    {value: callReplaceWord('仕入') + '先の被災により、部品や' + callReplaceWord('原材料') + '等が調達できずに、' + callReplaceWord('製品') + 'の' + callReplaceWord('生産') + 'ができなくなる。'},
                ]
            },
            {
                columnName: 'N00023',
                values:[
                    {value: callReplaceWord('工場') + 'の' + callReplaceWord('生産') + '停止や' + callReplaceWord('従業員') + 'の出社率の低下により事業が停止してしまい、その間の売上がなくなる。'},
                    {value: callReplaceWord('組織') + 'の運転資金（' + callReplaceWord('従業員') + 'の給与、賃借料等）と建物・設備等の復旧のための資金が必要となる。'},
                ]
            },
            {
                columnName: 'N00024',
                values:[
                    {value: 'パソコン等の機器類が破損する。'},
                    {value: '重要な書類・データ（' + callReplaceWord('顧客') + '管理簿、' + callReplaceWord('仕入先') + '管理簿、' + callReplaceWord('商品') + 'の設計図 等）が復旧できなくなる。'},
                ]
            },
        ]);

        const addLine = (targetColumnName) => {
            valueForms2.forEach(data => {
                if(data.columnName == targetColumnName){
                    data.values.push({
                        value: ''
                    });
                }
            });
        }

        const showBasic = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/3';
                location.href = url;
            }
        }

        const showOwn = async () => {
            // 現在のページを表示
            const url = '#page-link-2';
            location.href = url;
        }

        const showBack = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/5';
                location.href = url;
            }
        }

        const showImpactByCorona = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                // 被害想定のページへ遷移
                const url = '/bcp/bcp_introduction/6';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showHazardMap = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                // ハザードマップページへ遷移
                const url = '/bcp/bcp_introduction/h';
                // location.href = url;
                window.open(url, '_blank');
            }
        }

        const showActivationFlow = async () => {
            // 重要商品と被害想定を登録
            const result = await registerIntroduction4();

            if (result == '200') {
                const url = '/bcp/bcp_introduction/7';
                location.href = url;
            }
        }

        // 重要商品と被害想定の登録
        const registerIntroduction4 = async () => {
            // リクエストの整形
            const reqValueForms = [];
            if (valueForms1[0].value != null && valueForms1[0].value != '') {
                    reqValueForms.push({
                    columnName: valueForms1[0].columnName,
                    seq: 1,
                    value: valueForms1[0].value
                });
            }

            valueForms2.forEach(data => {
                let seq = 1;
                data.values.forEach(val => {
                    if (val.value == '' && seq == 1 ) {
                        // チェックボックス用にseqの値を制御（チェックボックス未入力ならseqだけインクリメント）
                        seq++;
                    } else if(val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: seq++,
                            value: val.value,
                        });
                    }
                });
            });

            const postUrl = '/api/bcp/registerIntroduction4';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            valueForms1,
            valueForms2,
            isProcessing,
            callReplaceWord,
            addLine,
            showBasic,
            showOwn,
            showBack,
            showNext,
            showImpactByCorona,
            showHazardMap,
            showContents,
            showActivationFlow,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

    .btn-damage-assumption {
        border: 1px solid;
        color: white;
        border-color: #f4b084;
        background-color: #f4b084;
    }
    .btn-damage-assumption:hover {
        border: 1px solid;
        color: #f4b084;
        border-color: #f4b084;
        background-color: white;
    }
    .bg-sub-title {
        background-color: #D9D9D9;
    }

    .font-orange {
        color: common_colors.colors(resilience-orange);
    }

</style>
