<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜あり</label>
    </div>

    <template v-if="is('administrator') || !viewLimit">
        <template v-for="val, valIndex in valueForms" :key="valIndex">
            <div class="card" :class="{'mt-3': valIndex >= 1}">
                <div class="card-body">
                    <div class="row mt-3">
                        <div class="p2 col-md">
                            <label class="form-label">2. BCPの運用体制</label>
                            <span class="ms-3 bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式05</span>
                        </div>
                    </div>

                    <div class="row mt-3 ms-md-1">
                        <div class="p2 col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">複数企業連携によるBCPの策定・運用体制</label>
                        </div>
                    </div>

                    <div class="row mt-3 ms-md-1">
                        <div class="p2 col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">・ 複数企業で連携して取り組む際の体制及び連携内容は以下のとおりである。</label>
                        </div>
                    </div>

                    <div class="row mt-3 ms-md-1">
                        <div class="p2 col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">1. 連携企業</label>
                        </div>
                    </div>

                    <div class="ms-md-3 d-none d-md-block d-print-block">
                        <div class="row mt-3">
                            <div class="col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">①種類</label>
                            </div>
                            <div class="col-md-10 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <template v-for="typeContent in typeList" :key="typeContent.typeId">
                                    <div class="ms-3 form-check form-check-inline" :class="{'ms-5': typeContent.typeId >= 2}">
                                        <input type="checkbox" :id="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`" :name="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`"
                                        class="custom-control-input"
                                        :value="typeContent.typeId" v-model="val.values[0].value" onclick="return false;"
                                    >
                                        <label class="form-check-label" :for="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`">&nbsp;{{ typeContent.type }}</label>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">②企業名</label>
                            </div>
                            <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">幹事{{ callReplaceWord('組織') }}名</label>
                            </div>
                            <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-center">
                                <label class="form-label">{{ val.values[1].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">&emsp;</label>
                            </div>
                            <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">連絡先</label>
                            </div>
                            <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-center">
                                <label class="form-label">{{ val.values[2].value }}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="p2 col-md-2 border border-top-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">&emsp;</label>
                            </div>
                            <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">構成企業</label>
                            </div>
                            <div class="p2 col-md-7 border bg-white d-flex align-items-center justify-content-center">
                                <template v-for="text, textIndex in textForms" :key="textIndex">
                                    <template v-if="text.fieldNumber === val.fieldNumber && text.columnName === val.columnName && valIndex === textIndex">
                                        <textarea readonly type="text" rows="5" class="form-control d-flex border-white align-items-center justify-content-center py-2" placeholder="" v-model="text.value"></textarea>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="ms-md-3 d- d-md-none d-print-none">
                        <div class="row d-md-none d-print-none">
                            <div class="col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">①種類</label>
                            </div>
                            <div class="col-9 border border-bottom-0 bg-white">
                                <template v-for="typeContent in typeList" :key="typeContent.typeId">
                                    <div class="row">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" :id="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`" :name="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`"
                                                class="custom-control-input"
                                                :value="typeContent.typeId" v-model="val.values[0].value" onclick="return false;"
                                            >
                                                <label class="form-check-label" :for="`inlineCheckbox1${val.fieldNumber}${valIndex}${typeContent.typeId}`">&nbsp;{{ typeContent.type }}</label>
                                            </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="row">
                            <div class="border border-bottom-0 bg-gray-3">
                                <label class="form-label">②企業名</label>
                            </div>
                            <div class="col-1 border border-top-0 border-end-0 bg-gray-3">

                            </div>
                            <div class="col px-0">
                                <div class="border border-bottom-0 bg-gray-3">
                                    <label class="form-label ms-2">幹事{{ callReplaceWord('組織') }}名</label>
                                </div>
                                <div class="border border-bottom-0 bg-white">
                                    <label class="form-label ms-2">{{ val.values[1].value }}</label>
                                </div>
                                <div class="border border-bottom-0 bg-gray-3">
                                    <label class="form-label ms-2">連絡先</label>
                                </div>
                                <div class="border border-bottom-0 bg-white">
                                    <label class="form-label ms-2">{{ val.values[2].value }}</label>
                                </div>
                                <div class="border border-bottom-0 bg-gray-3">
                                    <label class="form-label ms-2">構成企業</label>
                                </div>
                                <div class="border bg-white">
                                    <template v-for="text, textIndex in textForms" :key="textIndex">
                                        <template v-if="text.fieldNumber === val.fieldNumber && text.columnName === val.columnName && valIndex === textIndex">
                                            <textarea readonly type="text" rows="5" class="form-control d-flex border-white align-items-center justify-content-center py-2" placeholder="" v-model="text.value"></textarea>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-md-3 ms-md-1">
                        <div class="p2 col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">2. 連携内容</label>
                        </div>
                    </div>

                    <div class="ms-3 d-none d-md-block d-print-block">
                        <div class="row mt-3">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">チェック</label>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">種類</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">補足</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox2${valIndex}`" value="true" v-model="val.values[3].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label" :for="`inlineCheckbox2${valIndex}`">目標復旧時間の目処を予め調整の上、{{callReplaceWord('組織')}}間で共通認識を持っておく。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[4].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox3${valIndex}`" value="true" v-model="val.values[5].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label" :for="`inlineCheckbox3${valIndex}`">共同でBCPに関する勉強会を開催したり、訓練を行ったりする。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[6].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox4${valIndex}`" value="true" v-model="val.values[7].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">緊急時対策のための施設や資機材を共同で設置・備蓄する。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[8].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox5${valIndex}`" value="true" v-model="val.values[9].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">緊急時に操業停止した場合、他の{{ callReplaceWord('企業') }}が{{ callReplaceWord('製造') }}や{{ callReplaceWord('納入') }}を代替する。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[10].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox6${valIndex}`" value="true" v-model="val.values[11].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">緊急時に被災{{ callReplaceWord('企業') }}に対して要員応援を行う。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[12].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox7${valIndex}`" value="true" v-model="val.values[13].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">その他</label>
                            </div>
                            <div class="p2 col-md-5 border bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[14].value }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-md-none d-print-none">
                        <div class="row mt-3">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 px-0 fs-half">
                                <label class="form-label">チェック</label>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">種類</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">補足</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox2${valIndex}`" value="true" v-model="val.values[3].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label" :for="`inlineCheckbox2${valIndex}`">目標復旧時間の目処を予め調整の上、{{callReplaceWord('組織')}}間で共通認識を持っておく。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[4].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox3${valIndex}`" value="true" v-model="val.values[5].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label" :for="`inlineCheckbox3${valIndex}`">共同でBCPに関する勉強会を開催したり、訓練を行ったりする。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[6].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox4${valIndex}`" value="true" v-model="val.values[7].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">緊急時対策のための施設や資機材を共同で設置・備蓄する。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[8].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox5${valIndex}`" value="true" v-model="val.values[9].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">緊急時に操業停止した場合、他の{{ callReplaceWord('企業') }}が{{ callReplaceWord('製造') }}や{{ callReplaceWord('納入') }}を代替する。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[10].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox6${valIndex}`" value="true" v-model="val.values[11].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">緊急時に被災{{ callReplaceWord('企業') }}に対して要員応援を行う。</label>
                            </div>
                            <div class="p2 col-md-5 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[12].value }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p2 col-md-1 border border-end-0 bg-white d-flex align-items-center justify-content-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="`inlineCheckbox7${valIndex}`" value="true" v-model="val.values[13].value" onclick="return false;">
                                </div>
                            </div>
                            <div class="p2 col-md-6 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">その他</label>
                            </div>
                            <div class="p2 col-md-5 border bg-white d-flex align-items-center justify-content-start">
                                <label class="form-label">{{ val.values[14].value }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-md-3 ms-md-1">
                        <div class="p2 col-md d-flex align-items-center justify-content-start">
                            <label class="form-label">3.その他</label>
                        </div>
                    </div>

                    <div class="pb-5 d-none d-md-block d-print-block">
                        <div class="row mt-3 ms-1">
                            <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">連携活動によって発生する費用は、</label>
                            </div>
                            <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                                <input readonly type="text" class="form-control" style="" placeholder="" v-model="val.values[15].value">
                            </div>
                            <div class="p2 col-md-4 d-flex align-items-center justify-content-start">
                                <label class="form-label">　とする。</label>
                            </div>
                        </div>
                        <div class="row mt-3 ms-1">
                            <div class="p2 col-md-2 d-flex align-items-center justify-content-start">
                                <label class="form-label">幹事{{ callReplaceWord('組織') }}は、</label>
                            </div>
                            <div class="p2 col-md-3 d-flex align-items-center justify-content-start">
                                <input readonly type="text"  class="form-control" style="" placeholder="" v-model="val.values[16].value">
                            </div>
                            <div class="p2 col-md d-flex align-items-center justify-content-start">
                                <label class="form-label">　頃に全ての構成企業の担当幹部が出席する連絡会を開催し、情報交換を行うとともに、必要に応じて連携内容を見直す。</label>
                            </div>
                        </div>
                    </div>
                    <div class="pb-5 d-md-none d-print-none">
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label">連携活動によって発生する費用は、</label>
                            </div>
                            <div class="col-12">
                                <div class="border p-1">
                                    <span class="form-label">{{ val.values[15].value }}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label">とする。</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label">幹事{{ callReplaceWord('組織') }}は、</label>
                            </div>
                            <div class="col-12">
                                <div class="border p-1">
                                    <span class="form-label">{{ val.values[16].value }}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label">頃に全ての構成企業の担当幹部が出席する連絡会を開催し、情報交換を行うとともに、必要に応じて連携内容を見直す。</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </template>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const typeList =[
            {typeId: 1, type: 'サプライチェーン形成企業'},
            {typeId: 2, type: '同業者の協同組合等'},
            {typeId: 3, type: '地域的な協同組合等'},
        ]

        const valueForms = ref([]);
        const textForms = ref([]);
        const viewLimit = ref(0);

        // バリュー値
        if (props.bcp_values_info.length > 0) {
            let wkFieldNumber = null;
            props.bcp_values_info.forEach(fetchData => {
                if (fetchData.column_name === 'view_limit') {
                    // 閲覧制限
                    viewLimit.value = fetchData.value == 1 ? true : false;
                    return;
                } else if (wkFieldNumber !== fetchData.field_number) {
                    // 初期値作成
                    valueForms.value.push(
                        {
                            fieldNumber: fetchData.field_number,
                            columnName: fetchData.column_name,
                            values: [],
                        }
                    );
                    for (let i = 0; i <= 16; i++) {
                        const setValue = i === 0 ? {value:[]} : {value: ''};
                        valueForms.value[fetchData.field_number -1 ].values.push(
                            setValue
                        );
                    }
                    wkFieldNumber = fetchData.field_number;
                }
                // 取得データをセット
                valueForms.value.forEach(initData => {
                    initData.values.forEach((initValues, index) => {
                        if (initData.fieldNumber === fetchData.field_number && initData.columnName === fetchData.column_name && index + 1 === fetchData.seq) {
                            let setValue = fetchData.value;
                            if (index === 0) {
                                // 最初のチェックボックス部分
                                setValue = fetchData.value.split(",");
                            } else if (index >= 3 && index <= 13 && index/2 != 0 && fetchData.value === '1') {
                                // 「連携内容連携内」のチェックボックス部分
                                setValue = 'true';
                            }
                            initData.values[index].value = setValue;
                        }
                    })
                });
            })
        } else {
            // 初期値作成
            valueForms.value.push(
                {
                    fieldNumber: 1,
                    columnName: 'J00001',
                    values: [],
                }
            );
            for (let i = 0; i <= 16; i++) {
                const setValue = i === 0 ? {value:[]} : {value: ''};
                valueForms.value[0].values.push(
                    setValue
                );
            }
        }

        // テキスト値
        if (props.bcp_texts_info.length > 0) {
            // 初期値作成
            for (let i = 0; i < valueForms.value.length; i++) {
                textForms.value.push({
                    fieldNumber: i + 1,
                    columnName: 'J' + ('00000' + (i + 1)).slice(-5),
                    value: '',
                });
            }
            // 取得データをセット
            props.bcp_texts_info.forEach(fetchData => {
                textForms.value.forEach(initData => {
                    if (fetchData.field_number === initData.fieldNumber && fetchData.column_name === initData.columnName) {
                        initData.value = fetchData.text_value
                    }
                })
            })
        } else {
            // 初期値作成
            textForms.value.push({
                fieldNumber: 1,
                columnName: 'J00001',
                value: '',
            });
        }

        const showBack = () => {
            const url = '/browse/J';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/J2';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            typeList,
            valueForms,
            textForms,
            viewLimit,
            showBack,
            showNext,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_values_info: {
            type: Object,
        },
        bcp_texts_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="checkbox"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }

    .fs-half {
        font-size: 0.5rem;;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
