import * as CryptoJS from 'crypto-js';

export default function(){
    const SBConvertToUTF8 = (arr) => {
        const str = arr.join('');
        return CryptoJS.enc.Utf8.parse(str).toString();
    }

    const SbHash = (utf8str) => {
        return CryptoJS.SHA1(utf8str).toString();
    }

    return {
        SBConvertToUTF8,
        SbHash,
    }
}