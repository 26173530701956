<template>
    <div id="confirm-modal" v-show="modalConfirmVisible">
        <div id="content">
            <div class="btn-close" @click="closeModal"></div>
            <div class="col-md-12 align-items-center justify-content-center text-center">
                <label style="font-size:15pt">あなたの{{ callReplaceWord('組織') }}のBCP策定・運用データがととのいました。</label>
            </div>
            <hr>
            <div class="row mt-3 d-flex flex-row">
                <div class="col-md-12 align-items-center justify-content-center text-center">
                    TOTONO-Lでは、会員からBCP策定データを、防災研究に役立てたいと考えています。<br>
                    そこで、「国立大学法人東海国立大学機構 岐阜大学小山真紀准教授チーム」へデータを提供し、<br>
                    BCP策定機能の充実を図るとともに、官民連携による日本の防災力向上に係る研究開発を進めてまいります。<br>
                    つきましては、それらのデータベースに含まれる個人情報・{{ callReplaceWord('組織') }}情報を第三者へ提供することについて、ご同意くださいますようお願いいたします。<br>
                    <br>
                    ～TOTONO-L事務局～<br>
                </div>
            </div>

            <div class="row mt-3 ms-auto d-flex flex-row py-2">
                <div class="p2 mt-3 col-md d-flex align-items-center justify-content-start custom-control custom-radio custom-control-inline">
                    <input type="checkbox" id="customRadioInline1" name="customRadioInline1" class="custom-control-input"
                        value="1" v-model="isAgree" @change="checkedAgree">
                    <label class="custom-control-label" for="customRadioInline1">&nbsp;同意する</label>
                </div>
                <button class="btn mt-3 col-md-10 btn-kyodo-button" @click="showKyodo">研究テーマ｜BCP策定機能を充実し官民連携により日本の防災をととのエル</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            経営者による自己診断
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-1 mt-3 d-flex flex-row">
        <div class="col-md-2 align-items-center justify-content-center">
            BCP策定・運用状況の診断
        </div>
        <div class="col-md-2 bg-color-middle d-flex align-items-center justify-content-center">
            中級コース
        </div>
        <div class="col-md-2 ms-3 bg-color-advanced d-flex align-items-center justify-content-center">
            上級コース
        </div>
    </div>

    <div class="row ms-1 mt-3 ">
        <div class="col-md-11 d-flex flex-row">
            <div class="p2 col-md border d-flex align-items-center justify-content-center bg-light-gray py-2">
                はいの数
            </div>
            <div class="p2 col-md border d-flex align-items-center justify-content-center py-2" >
                {{ yes_count }}
            </div>
            <div class="p2 col-md ms-5 border d-flex align-items-center justify-content-center bg-light-gray py-2">
                いいえの数
            </div>
            <div class="p2 col-md border d-flex align-items-center justify-content-center py-2" >
                {{ no_count }}
            </div>
            <div class="p2 col-md ms-5 border d-flex align-items-center justify-content-center bg-light-gray py-2">
                不明の数
            </div>
            <div class="p2 col-md border d-flex align-items-center justify-content-center py-2" >
                {{ unknown_count }}
            </div>
        </div>
    </div>

    <div class="row ms-1 mt-5 d-flex flex-row">
        <div class="col-md-11">
            この自己診断は、あなたの会社のBCP運用状況をチェックするためのものですが、「はい」の数の合計により「合格」「不合格」を判定することが目的ではなく、「はい」にチェックが付けられなかった項目を把握して、その部分の対応について、今後もう少し力を入れて取り組む必要があるということを認識することが本来の目的です。ですから、「はい」の数を深く気にすることなく、「いいえ」にチェックが付いた項目を一つずつ減らしていくように努力してください。
        </div>
    </div>

    <div class="row ms-1 mt-5">
        <div class="col-md-11">
            <table class="table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td class="bg-light-gray">いいえ、不明の項目</td>
                    </tr>
                    <tr v-for="item, index in no_unknown_item_list" :key="index">
                        <td>{{ item }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-5">
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" @click="backMenu">
        </div>
    </div>

</template>

<script>
import { ref, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() =>{
            isAgree.value = props.corp_info.岐阜大学への情報提供の同意 === 1 ? true : false ;
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const modalConfirmVisible = ref(true);
        const isAgree = ref(false);

        const closeModal = () => {
            modalConfirmVisible.value = false
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 同意するチェック
        const checkedAgree = () => {
            const url="/api/pdca/putGifuUniInfoProvisionAgreement";

            // 企業情報の岐阜大学への情報提供の同意を更新
            return axios.post(url, {
                corp_id: props.corp_info.id,
                is_agree: isAgree.value,
            }).then(res=>{

            }).finally(fin => {
                // モーダルを閉じる
                modalConfirmVisible.value = false;
            });
        }

        // TODO 遷移先決まり次第
        const showKyodo = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const backMenu = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        return {
            setLevel,
            modalConfirmVisible,
            isAgree,
            closeModal,
            callReplaceWord,
            checkedAgree,
            showKyodo,
            showBack,
            backMenu,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        yes_count: {
            type: Number,
        },
        no_count: {
            type: Number,
        },
        unknown_count: {
            type: Number,
        },
        no_unknown_item_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-color-middle {
    background-color: #2353a4;
    color: white;
}
.bg-color-advanced {
    background-color: #002060;
    color: white;
}

.btn-kyodo-button {
    background-color: #6cddda;
}

.btn-kyodo-button:hover {
        border: 1px solid;
        color: white;
        background-color: #46908e;
    }

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}
#content {
    z-index: 2;
    width: 40%;
    padding: 1em 3em;
    background: #33cccc;
}

 .btn-close {
    position: relative;
    top: 0px;
    left: 680px;
}

.btn-close:hover{
    cursor: pointer;
}
</style>
