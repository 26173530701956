<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式17-1</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">中核事業に必要な供給品目情報</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 中核事業を復旧・継続するために必要な供給品目には以下のものがある。</label>
                </div>
            </div>
            <div class="pb-5 d-none d-md-block d-print-block width-100">
                <div class="row ms-1">
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">供給品目</label>
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">注文番号</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">数量</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">供給事業者</label>
                    </div>
                    <div class="p2 col-md-3 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">関連する{{ callReplaceWord('社内') }}需要業務</label>
                    </div>
                </div>
                <div class="row ms-1" v-for="(item, index) in supplyItemInfo">
                    <div class="p2 col-md-3 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== supplyItemInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.supplyItem || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-2 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== supplyItemInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.orderNumber || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-1 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== supplyItemInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.quantity == null ? '&emsp;' : item.quantity }}</div>
                    </div>
                    <div class="p2 col-md-3 border border-end-0 bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== supplyItemInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.supplyCompany || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-md-3 border bg-info d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== supplyItemInfo.length - 1 }">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : convertImportantBusinessIdToString(item.importantBusinessId) || '&emsp;' }}</div>
                    </div>
                </div>
            </div>
            <div class="pb-5 d-md-none d-print-none width-100">
                <div class="row mt-2" v-for="(item, index) in supplyItemInfo" :key="item.id">
                    <div class="form-label">{{ (index + 1) + "." }}</div>
                    <div class="border border-bottom-0 bg-gray-3">
                        <label class="form-label">供給品目</label>
                    </div>
                    <div class="border border-bottom-0 bg-info">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.supplyItem || '&emsp;' }}</div>
                    </div>
                    <div class="border border-bottom-0 bg-gray-3">
                        <label class="form-label">注文番号</label>
                    </div>
                    <div class="border border-bottom-0 bg-info">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.orderNumber || '&emsp;' }}</div>
                    </div>
                    <div class="border border-bottom-0 bg-gray-3">
                        <label class="form-label">数量</label>
                    </div>
                    <div class="border border-bottom-0 bg-info">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.quantity || '&emsp;' }}</div>
                    </div>
                    <div class="border border-bottom-0 bg-gray-3">
                        <label class="form-label">供給事業者</label>
                    </div>
                    <div class="border border-bottom-0 bg-info">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : item.supplyCompany || '&emsp;' }}</div>
                    </div>
                    <div class="border border-bottom-0 bg-gray-3">
                        <label class="form-label">関連する{{ callReplaceWord('社内') }}需要業務</label>
                    </div>
                    <div class="border bg-info">
                        <div class="form-control bg-info">{{ !is('administrator') && item.isViewLimit ? '&emsp;' : convertImportantBusinessIdToString(item.importantBusinessId) || '&emsp;' }}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNextForAdmin" />
        <input v-else type="button" class="btn btn-resilience-green no-print px-4 py-2" value="［利用者専用］ 次のページへ" @click="showNextForUser" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        let supplyItemInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K15';
            location.href = url;
        }

        const showNextForAdmin = () => {
            const url = '/browse/K17';
            location.href = url;
        }

        const showNextForUser = () => {
            const url = '/browse/K18';
            location.href = url;
        }

        const convertImportantBusinessIdToString = (value) => {
            return props.important_businesses_info.find(
                (data) => data.id === value
            )?.important_business_content || '';
        };

        if (props.supply_item_info.length > 0) {
            supplyItemInfo = props.supply_item_info.map((fetchData) => ({
                id: fetchData.id,
                importantBusinessId: fetchData.important_business_id,
                isViewLimit: fetchData.is_view_limit,
                orderNumber: fetchData.order_number,
                quantity: fetchData.quantity,
                supplyCompany: fetchData.supply_company,
                supplyItem: fetchData.supply_item,
            }));
        }

        return {
            setLevel,
            isMargin1,
            supplyItemInfo,
            callReplaceWord,
            showBack,
            showNextForAdmin,
            showNextForUser,
            convertImportantBusinessIdToString,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        important_businesses_info: {
            type: Object,
        },
        supply_item_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.width-100 {
    width: 100%;
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
