<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                クレジット登録
            </div>
            <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="back">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="row my-3">
                        <span>下記の「クレジット登録」ボタンを押し、遷移先にてクレジットカードの登録を行ってください。</span>
                    </div>
                    <form id="form_credit" :action="densan_url" method="post" @submit.prevent="getOrderID" ref="submitCredit" accept-charset='shift_jis'>
                        <input type="hidden" name="pay_method" :value="sb_pay_method" />
                        <input type="hidden" name="merchant_id" :value="sb_merchant_id" />
                        <input type="hidden" name="service_id" :value="sb_service_id">
                        <input type="hidden" name="cust_code" :value="sb_cust_code">
                        <input type="hidden" name="terminal_type" :value="sb_terminal_type">

                        <input type="hidden" name="success_url" :value="sb_success_url">
                        <input type="hidden" name="cancel_url" :value="sb_cancel_url">
                        <input type="hidden" name="error_url" :value="sb_error_url">
                        <input type="hidden" name="pagecon_url" :value="sb_pagecon_url">

                        <input type="hidden" name="request_date" :value="sb_request_date">
                        <input type="hidden" name="hashkey" :value="sb_hashkey">
                        <input type="hidden" name="sps_hashcode" :value="sb_sps_hashcode">

                        <input type="submit" class="btn btn-black px-5" value="クレジット登録" :disabled="isProcessing" >
                    </form>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { inject, nextTick, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import CommonCrypt from '../../../function/CommonCrypto';
import { useEnv } from '../../../function/useEnv';

const { SbHash } = CommonCrypt();

import axios from 'axios';
export default {
    components: {
        levelIcon
    },
    setup(props) {
        const { VITE_SB_REGISTER_CREDIT_URL, VITE_SB_MERCHANT_ID, VITE_SB_SERVICE_ID, VITE_SB_HASH_KEY, VITE_SB_GET_TOKEN_URL, VITE_SHOW_CONVENIENT_PAY_FLG } = useEnv();

        const dayjs = inject('dayjs');

        const back = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const setLevel = ref('111111');

        const isProcessing = ref(false);

        const submitCredit = ref();

        const densan_url = ref(VITE_SB_REGISTER_CREDIT_URL);

        const sb_pay_method = ref('');
        const sb_merchant_id = ref(VITE_SB_MERCHANT_ID);
        const sb_service_id = ref(VITE_SB_SERVICE_ID);
        const sb_cust_code = ref('');
        const sb_terminal_type = ref('');

        const sb_success_url = ref('');
        const sb_cancel_url = ref('');
        const sb_error_url = ref('');
        const sb_pagecon_url = ref('');

        const sb_request_date = ref('');
        const sb_hashkey = ref(VITE_SB_HASH_KEY);
        const sb_sps_hashcode = ref('');

        const getOrderID = () => {
            const url = '/api/getOrderSequence';

            axios.get(url).then(res => {
                registerCredit(res.data);
            });
        }

        const registerCredit = async() => {
            isProcessing.value = true;

            const time_string = dayjs().format('YYYYMMDDHHmmss')
            const baseUrl = window.location.origin;

            const creditParams = {
                'pay_method': 'credit3d2',
                'merchant_id': sb_merchant_id.value,
                'service_id': sb_service_id.value,
                'cust_code': props.corp_info.uuid,
                'terminal_type': 0,
                'success_url': baseUrl + '/al/administrator/register_credit_success?uuid='+props.uuid+"&corp_id="+props.corp_info.id,
                'cancel_url': baseUrl + '/al/administrator/register_credit_cancel?uuid='+props.uuid+"&corp_id="+props.corp_info.id,
                'error_url': baseUrl + '/al/administrator/register_credit_error?uuid='+props.uuid+"&corp_id="+props.corp_info.id,
                'pagecon_url': 'https://totono-l.org/api/hon_pagecon',

                'request_date': time_string,

            }

            sb_pay_method.value = creditParams.pay_method;
            sb_merchant_id.value = creditParams.merchant_id;
            sb_service_id.value = creditParams.service_id;
            sb_cust_code.value = creditParams.cust_code;
            sb_terminal_type.value = creditParams.terminal_type;

            sb_success_url.value = creditParams.success_url;
            sb_cancel_url.value = creditParams.cancel_url;
            sb_error_url.value = creditParams.error_url;
            sb_pagecon_url.value = creditParams.pagecon_url;
            sb_request_date.value = creditParams.request_date;

            const vals = Object.values(creditParams);

            const str = vals.reduce((temp, item) => {
                return temp + item;
            });

            const hashData = SbHash(str + sb_hashkey.value);

            sb_sps_hashcode.value = hashData;

            await nextTick();
            submitCredit.value.submit();

            isProcessing.value = false;

        }


        return {
            setLevel,

            back,

            isProcessing,

            submitCredit,

            densan_url,

            sb_pay_method,
            sb_merchant_id,
            sb_service_id,
            sb_cust_code,
            sb_terminal_type,

            sb_success_url,
            sb_cancel_url,
            sb_error_url,
            sb_pagecon_url,
            sb_request_date,
            sb_hashkey,
            sb_sps_hashcode,

            getOrderID,
            registerCredit,
        }

    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        uuid: {
            type: String,
        },
    },
}
</script>