<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            {{ callReplaceWord('組織') }}BCP策定
        </div>
        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backMenu">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="text">
        <label class="ms-3 mt-3">当サイトのBCP策定手順は、経済産業省が公表した「中小企業BCP策定運用指針」をもとに開発しました。<br>詳しくは、「チュートリアルを見る（参考にした国の公表資料｜BCP事業継続計画とは）」をクリックしてご確認ください。</label>
    </div>

    <tutorial v-if="dispIntroACount <= 5"></tutorial>
    <bcpStart v-if="dispIntroACount > 5" :is_formulation="isFormulation" :bcp_title_info="bcp_title_info" :is_bcp_edit="is_bcp_edit"></bcpStart>

    <div class="text">
        <label class="ms-3 mt-5">当サイトは、経営者やサブリーダーが投入できる時間と労力に応じて4つのコースを用意しました。<br>まずは、入門コースから策定をスタートし、その後、リスクの想定や事業継続に関するさまざまな戦略の深化度合いに応じて、上位コースに進みます。</label>
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 title-bcp-introduction d-flex align-items-center justify-content-center py-2">
            入門コース
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
            ▶
        </div>
        <div class="p2 col-md-2 title-bcp-basic d-flex align-items-center justify-content-center py-2">
            基本コース
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
            ▶
        </div>
        <div class="p2 col-md-2 title-bcp-middle  d-flex align-items-center justify-content-center py-2">
            中級コース
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
            ▶
        </div>
        <div class="p2 col-md-2 title-bcp-advanced d-flex align-items-center justify-content-center py-2">
            上級コース
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            経営者の頭の中にある考えをBCP様式類に記入していくことで、必要最低限のBCPを策定・運用します
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            経営者の頭の中にある考えをBCPサイクルに沿って、BCP様式類に目標復旧時間や緊急連絡先等の具体的な情報を記入して、BCPを策定・運用します
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            BCPサイクルに関する理論を学びつつ、経営者の頭の中にある考えを BCPサイクルに沿って、BCP様式類に目標復旧時間や緊急連絡先等の具体的な情報を記入して、体系的にBCPを策定・運用します
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            BCPを策定・運用済みの企業が、複数の企業と連携して取り組んだり、より深い分析を行うことで、BCPの策定・運用をステップアップします
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            <div class="p2 col-md bg-course d-flex align-items-top justify-content-center py-2">
                経営者1人で<br>1～2時間程度
            </div>
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            <div class="p2 col-md bg-course d-flex align-items-top justify-content-center py-2">
                経営者1人で<br>1～2時間程度
            </div>
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex text-center align-items-top justify-content-center py-2">
            <div class="p2 col-md bg-course d-flex align-items-top justify-content-center py-2">
                経営者1人で<br>延べ3～5日程度<br><br>経営者とサブリーダー<br>含め数人で<br>2～3日程度
            </div>
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            <div class="p2 col-md bg-course d-flex text-center align-items-top justify-content-center py-2">
                経営者とサブリーダー<br>含め数人で<br>延べ1週間程度
            </div>
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 title-bcp-introduction d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 title-bcp-basic d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 title-bcp-middle d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 title-bcp-advanced d-flex align-items-center justify-content-center py-2">
        </div>
    </div>

    <div class="text mt-5">
        <label>注）BCPの策定に限った日数の目安ですが、会社の規模や事業内容、事前対策の選定内容等によって変動します。また、別途、BCPの運用（教育訓練や計画見直し）に取り組むための時間が必要となります。</label>
    </div>

    <tutorial v-if="dispIntroACount > 5"></tutorial>
    <bcpStart v-if="dispIntroACount <= 5" :is_formulation="isFormulation" :bcp_title_info="bcp_title_info" :is_bcp_edit="is_bcp_edit"></bcpStart>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backMenu">
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import tutorial from "./ShowTutorialComponent.vue";
import bcpStart from "./ShowBCPStartComponent.vue";
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
        tutorial,
        bcpStart,
    },
    setup(props) {
        const setLevel = ref('011100');
        const isFormulation = props.bcp_title_info.id === undefined ? false : true;

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backMenu = () => {
            const url = '/bcp/home';

            location.href = url;
        }

        const showNext = () => {
            const url = '/bcp/bcp_introduction/1';

            location.href = url;
        }

        let dispIntroACount = 0;
        if (window.localStorage) {
            localStorage.setItem('dispIntroACount', Number(localStorage.getItem('dispIntroACount')) + 1);
            dispIntroACount = localStorage.getItem('dispIntroACount');
        }

        return {
            setLevel,
            isFormulation,
            callReplaceWord,
            showNext,
            backMenu,
            dispIntroACount,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_title_info: {
            type: Object,
        },
        is_created_first_version: {
            type: Boolean,
        },
        is_bcp_edit: {
            type: Number,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

.bg-course {
    background-color: #D9D9D9;
}

.title-bcp-introduction {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-introduction);
    background-color: common_colors.colors(bcp-introduction);
}

.title-bcp-basic {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-basic);
    background-color: common_colors.colors(bcp-basic);
}

.title-bcp-middle {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-middle);
    background-color: common_colors.colors(bcp-middle);
}

.title-bcp-advanced {
    border: 1px solid;
    color: white;
    border-color: common_colors.colors(bcp-advanced);
    background-color: common_colors.colors(bcp-advanced);
}

</style>
