<template>
    <div class="row ms-auto mt-3 height-83px" v-for="info in disaster_prevent_information_list" :key="`${info.pref_id}-${info.seq}`">
        <input type="button" class="col-md-6 border d-flex align-items-center justify-content-start py-2" :class="isOdd(info.pref_id) ? 'bg-odd' : 'bg-even'"
            :value="info.pref_name + '｜' + info.name" @click="linkURL(info.url)" style="font-size:12pt" >
        <div class="col-md-6 d-flex justify-content-start">
            <div class="px-1" v-if="info.flood == 1">
                <i id="hazard-image-01" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.sediment == 1">
                <i id="hazard-image-02" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.high_tide == 1">
                <i id="hazard-image-03" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.tsunami == 1">
                <i id="hazard-image-04" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.road == 1">
                <i id="hazard-image-05" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.terrain == 1">
                <i id="hazard-image-06" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.earthquake == 1">
                <i id="hazard-image-07" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.volcano == 1">
                <i id="hazard-image-08" class="top_image"></i>
            </div>
            <div class="px-1" v-if="info.liquefaction == 1">
                <i id="hazard-image-09" class="top_image"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref, watch } from 'vue'

export default {
    setup(props) {

        watch(() => props.region, (newVal, oldVal) => {
            console.log(newVal);

            getDisasterPreventionInfo();
        });

        const regionRanges = {
            '北海道・東北': { from: 91010001, to: 91010007 },
            '関東': { from: 91010008, to: 91010014 },
            '中部': { from: 91010015, to: 91010023 },
            '近畿': { from: 91010024, to: 91010030 },
            '中国・四国': { from: 91010031, to: 91010039 },
            '九州・沖縄': { from: 91010040, to: 91010047 },
        };

        const disaster_prevent_information_list = ref([]);

        const getDisasterPreventionInfo = () => {
            const url = '/api/getDisasterPreventionInfoList';

            axios.post(url, {
                from: regionRanges[props.region].from,
                to: regionRanges[props.region].to,
            }).then(res => {
                disaster_prevent_information_list.value = res.data;
            });
        }

        const linkURL = (url) => {
            let newWindow = window.open(url, '_blank');
        }

        const isOdd = (pref_id) => {
            return pref_id % 2 === 1;
        }

        return {
            getDisasterPreventionInfo,
            regionRanges,
            disaster_prevent_information_list,
            linkURL,
            isOdd,
        }
    },
    props: {
        region: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
    .top_image {
        display: inline-flex;
        width: 83px;
        height: 83px;
        background-size: 83px auto;
        background-repeat: no-repeat;
    }

    #hazard-image-01{
        background-image: url('../../../../svg/07-62.svg');
    }

    #hazard-image-02{
        background-image: url('../../../../svg/07-63.svg');
    }

    #hazard-image-03{
        background-image: url('../../../../svg/07-64.svg');
    }

    #hazard-image-04{
        background-image: url('../../../../svg/07-65.svg');
    }

    #hazard-image-05{
        background-image: url('../../../../svg/07-66.svg');
    }

    #hazard-image-06{
        background-image: url('../../../../svg/07-67.svg');
    }

    #hazard-image-07{
        background-image: url('../../../../svg/07-61.svg');
    }

    #hazard-image-08{
        background-image: url('../../../../svg/07-68.svg');
    }

    #hazard-image-09{
        background-image: url('../../../../svg/07-69.svg');
    }

    .bg-odd {
        background-color: #D9D9D9;
    }

    .bg-even {
        background-color: #f8cbad;
    }

    .height-83px {
        height: 83px;
        min-height: 83px;
    }

</style>
