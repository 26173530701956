<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            内部監査人登録
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome" :disabled="isProcessing">
        </div>
    </div>
    <levelIcon
        :set_level="setLevel"
    />
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-borderless" id="main_table">
                <thead>
                    <tr>
                        <th>部署</th>
                        <th>氏名</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(auditor, index) in auditors" :key="auditor.id">
                        <td><label class="col-form-label">{{ auditor.部署 }}</label></td>
                        <td>
                            <select class="form-select" v-model="auditor.id" @change="changeAuditor(index)" :disabled="isProcessing">
                                <option v-for="user in user_list" :key="user.id" :value="user.id">{{ user.姓 + "　" + user.名 }}</option>
                            </select>
                        </td>
                        <td>
                            <input type="button" class="btn btn-gray btn-sm" value="クリア" @click="removeAuditor(index)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3 pb-5 d-flex flex-row">
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-gray" value="運用サイクルHOME へ戻る" @click="backHome">
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import levelIcon from '../common/UserLevelIconsComponent.vue';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const auditors = reactive([]);

        onMounted(() => {
            // auditors.splice(0);
            if(props.auditor_list.length > 0){
                props.auditor_list.forEach(item => {
                    auditors.push({
                        id: item.user_id,
                        corp_id: item.corp_id,
                        利用者ID: item.利用者ID,
                        部署: item.部署,
                        表示名: item.姓 + '　' + item.名,
                    })
                });
                auditors.push({
                    id: 0,
                    corp_id: props.corp_info.id,
                    利用者ID: 0,
                    部署: "",
                    表示名: "",
                });
            }else{
                auditors.push({
                    id: 0,
                    corp_id: props.corp_info.id,
                    利用者ID: 0,
                    部署: "",
                    表示名: "",
                });
            }



        });

        const backHome = () => {
            const url = '/fixation/home';

            location.href = url;
        }

        const isProcessing = ref(false);

        const changeAuditor = (index) => {
            isProcessing.value = true;

            const url = '/api/editAuditor';

            axios.post(url, {
                corp_id: props.corp_info.id,
                auditors_list: auditors.map(item => { return item.id }), 
            }).then(res => {
                getList();
            }).finally(fin => {
                isProcessing.value = false;
            });

            // //処理が複雑になるため重複チェックは行わない
            // if((index + 1) === auditors.length){
            //     auditors.push({
            //         id: 0,
            //         corp_id: props.corp_info.id,
            //         利用者ID: 0,
            //         部署: "",
            //         表示名: "",
            //     });
            // }

        }

        const getList = () => {
            const url = '/api/getAuditorList?corp_id=' + props.corp_info.id;

            axios.get(url).then(res => {
                auditors.splice(0);

                res.data.forEach(item => {
                    auditors.push({
                        id: item.user_id,
                        corp_id: item.corp_id,
                        利用者ID: item.利用者ID,
                        部署: item.部署,
                        表示名: item.姓 + '　' + item.名,
                    });
                });

                auditors.push({
                    id: 0,
                    corp_id: props.corp_info.id,
                    利用者ID: 0,
                    部署: "",
                    表示名: "",
                });

            })
        }

        const removeAuditor = (index) => {
            if(!confirm(auditors[index].表示名 + "を内部監査人登録から解除します。よろしいですか？")){
                return false;
            }

            isProcessing.value = true;

            const url = '/api/removeAuditor';

            axios.post(url, {
                user_id: auditors[index].id,
            }).then(res => {
                getList();
            }).finally(fin => {
                isProcessing.value = false;
            });


        }

        return {
            setLevel,
            backHome,
            auditors,
            changeAuditor,
            isProcessing,
            getList,
            removeAuditor,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        user_list: {
            type: Array,
        },
        auditor_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
#main_table thead tr th:first-child,
#main_table thead tr th:nth-child(2),
#main_table tbody tr td:first-child,
#main_table tbody tr td:nth-child(2)
{
    border: thin solid #808080;
}
</style>