<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                利用者更新
            </div>
            <div class="col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backList">
            </div>
        </div>

        <form @submit.prevent="post_form">
            <div class="bg-member_card ">
                <div class="row ms-4 mt-5">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        利用者ID
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-2 align-items-center justify-content-center py-2">
                        <select class="form-select" v-model="input利用者ID" required :class="{'is-invalid': 利用者ID_has_error}" :disabled="input利用者ID === '000001'">
                            <option v-for="user_number in user_number_list" :key="user_number" :value="('000000' + user_number).slice(-6)">{{ ('000000' + user_number).slice(-6) }}</option>
                        </select>
                        <div class='invalid-feedback'>{{ 利用者ID_message }}</div>
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1"  />
                            <template #popper>
                                <div class="row">
                                    <label>ID000002以降は、{{ callReplaceWord('従業員') }}ごとに管理者が自由に選択し登録できます。<br />BCPの{{ callReplaceWord('従業員') }}リスト等は、ID番号順に列記されます。<br />また、{{ callReplaceWord('従業員') }}の退職などで欠番が生じた場合は、前任者の情報を初期化し再利用できます。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        氏名
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-1 d-flex align-items-center ">
                        姓
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" v-model="input姓" placeholder="岐阜" required id='family_name' @input="handleFamilyNameInput">
                    </div>
                    <div class="col-md-1 d-flex align-items-center ">
                        名
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" v-model="input名" placeholder="太郎" required id='first_name' @input="handleFirstNameInput">
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-1 d-flex align-items-center ">
                        セイ
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" v-model="input姓_カナ" placeholder="(例)ギフ" required id='family_name_kana'>
                    </div>
                    <div class="col-md-1 d-flex align-items-center ">
                        メイ
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" v-model="input名_カナ" placeholder="(例)タロウ" required id='first_name_kana'>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        拠点・支店（勤務地）
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-6 align-items-center justify-content-center py-2">
                        <select class="form-select" v-model="input拠点" required>
                            <option v-for="branch in branch_list" :key="branch.idx" :value="branch.idx">{{ branch.拠点名 }}</option>
                        </select>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center">
                        部署・役職
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <!-- <span class="badge bg-secondary">必須</span> -->
                    </div>
                    <div class="col-md-1 d-flex align-items-center ">
                        部署
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" :placeholder="checkDepartmentNothing ? '' : '総務部'" required v-model="input部署" :disabled="checkDepartmentNothing">
                    </div>
                    <div class="col-md-1 py-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="checkDepartmentNothing" id="department-nothing" @change="changeDepartmentNothing">
                            <label class="form-check-label" for="department-nothing">
                                なし
                            </label>
                        </div>
                    </div>
                    <div class="col-md-1 d-flex align-items-center ">
                        役職
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" :placeholder="checkClassNothing ? '' : '部長'" required v-model="input役職" :disabled="checkClassNothing">
                    </div>
                    <div class="col-md-1 py-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="checkClassNothing" id="class-nothing" @change="changeClassNothing">
                            <label class="form-check-label" for="class-nothing">
                                なし
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center">
                        携帯電話
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                    </div>
                    <div class="col-md-1 py-2">
                        <input type="text" class="form-control" maxlength="10" v-model="input電話番号1" :class="{'is-invalid': 電話番号_has_error}">
                        <div class='invalid-feedback'>{{ 電話番号_message }}</div>
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        －
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="10" v-model="input電話番号2" :class="{'is-invalid': 電話番号_has_error}">
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        －
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="10" v-model="input電話番号3" :class="{'is-invalid': 電話番号_has_error}">
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        {{ callReplaceWord('組織') }}用e-mail
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                    </div>
                    <div class="col-md-6  py-2">
                        <input type="text" class="form-control" maxlength="255" v-model="inputEmail" :class="{'is-invalid': email_has_error}">
                        <div class='invalid-feedback'>{{ email_message }}</div>
                    </div>
                </div>

                <div class="row ms-4 d-flex align-items-center ">
                    <div class="ms-2 col-md-2">
                        主要な責務
                        <div style="font-size:9pt">
                            ※中級コース以上
                        </div>
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                    </div>
                    <div class="col-md-6 align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" disabled :value="main_responsibilities.join('、')">
                    </div>
                    <div class="col-md-2 d-flex align-items-center py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1"  />
                            <template #popper>
                                <div class="row">
                                    <label>BCP策定に関する責任者や緊急事態発生時の初動対応・復旧対応の各担当責任者が該当します。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-4 d-flex align-items-center ">
                    <div class="ms-2 col-md-2">
                        緊急時の依頼事項
                        <div style="font-size:9pt">
                            ※中級コース以上
                        </div>
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                    </div>
                    <div class="col-md-6 align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" :value="emergency_order_matters.order_matter" disabled>
                    </div>
                    <div class="col-md-2 d-flex align-items-center py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1"  />
                            <template #popper>
                                <div class="row">
                                    <label>{{ callReplaceWord('従業員') }}の役割や資格などに応じて、緊急時に依頼したい事項を記入します。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        責任者候補
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-3">
                        <div class="form-check form-check-inline mt-3">
                            <input type="radio" class="form-check-input" v-model="input責任者候補" value="true" id="責任者_登録する">
                            <label class="form-check-label" for="責任者_登録する">登録する</label>
                        </div>
                        <div class="form-check form-check-inline mt-3">
                            <input type="radio" class="form-check-input ms-3" v-model="input責任者候補" value="false" id="責任者_登録しない">
                                <label class="form-check-label" for="責任者_登録しない">登録しない</label>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-center py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1" />
                            <template #popper>
                                <div class="row">
                                    <label>BCP策定時の「統括責任者」や「代理責任者」の候補者としてリストに表示されます。責任者候補者には、BCP発動権限を付与します。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        管理者
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-3">
                        <div class="form-check form-check-inline mt-3">
                            <input type="radio" class="form-check-input" v-model="input管理者" value="true" id="管理者_登録する" :disabled="input利用者ID === '000001'">
                                <label class="form-check-label" for="管理者_登録する">登録する</label>
                        </div>
                        <div class="form-check form-check-inline mt-3">
                            <input type="radio" class="form-check-input ms-3" v-model="input管理者" value="false" id="管理者_登録しない" :disabled="input利用者ID === '000001'">
                                <label class="form-check-label" for="管理者_登録しない">登録しない</label>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-center py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" tabindex="-1"  />
                            <template #popper>
                                <div class="row">
                                    <label>管理者としてBCPの策定権限やBCP発動権限等を付与します。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center ">
                        登録する家族の人数
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        <span class="badge bg-secondary">必須</span>
                    </div>
                    <div class="col-md-2 py-2">
                        <input type="number" class="form-control" v-model="input家族数" :class="{'is-invalid': 家族人数_has_error}">
                        <div class='invalid-feedback'>{{ 家族人数_message }}</div>
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                        人
                    </div>
                </div>

                <div class="row ms-4">
                    <div class="ms-2 col-md-2 d-flex align-items-center">
                        パスワード
                    </div>
                    <div class="col-md-1 d-flex align-items-center justify-content-center py-2">
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center py-2">
                        <input type="text" class="form-control" maxlength="255" v-model="inputPassword">
                    </div>
                    <div class="col-md-2 d-flex align-items-center py-2">
                        <input type="button" class="btn btn-red btn-sm" value="自動生成" @click="makePassword">
                    </div>
                    <div class="col-md-4 d-flex align-items-center py-2">
                        <label class="col-form-label">※変更する場合のみ入力してください</label>
                    </div>
                </div>
            </div>

            <div class="col-md-2 ms-4 mt-3 d-flex align-items-center py-2">
                <input type="submit" class="btn btn-black" value="上記の内容で利用者を更新する" :disabled='isProcessing'>
            </div>
        </form>

        <div class="ms-4 mt-3 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-gray" value="利用者一覧へ戻る" @click="backList" :disabled='isProcessing'>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { CommonMakePassword } from '../../../function/CommonMakePassword.js';
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import * as AutoKana from 'vanilla-autokana';
import axios from 'axios';

let autokana_family;
let autokana_first;

export default {
    setup(props) {
        onMounted(() => {
            autokana_family = AutoKana.bind('#family_name');
            autokana_first = AutoKana.bind('#first_name');

            input利用者ID.value = props.user_info.利用者ID;
            input姓.value = props.user_info.姓;
            input名.value = props.user_info.名;
            input姓_カナ.value = props.user_info.姓_カナ;
            input名_カナ.value = props.user_info.名_カナ;
            input拠点.value = props.user_info.拠点;
            input部署.value = props.user_info.部署;
            input役職.value = props.user_info.役職;
            if(props.user_info.電話番号){
                const arr電話番号 = props.user_info.電話番号.split('-');
                input電話番号1.value = arr電話番号[0];
                input電話番号2.value = arr電話番号[1];
                input電話番号3.value = arr電話番号[2];
            }
            inputEmail.value = props.user_info.email;
            input責任者候補.value = props.user_info.責任者候補 === 1 ? "true" : "false";
            input管理者.value = props.user_info.管理者 === 1 ? "true" : "false";
            input家族数.value = props.user_info.家族人数;

            if(props.user_info.部署 === 'なし'){
                input部署.value = '';
                checkDepartmentNothing.value = true;
            }

            if(props.user_info.役職 === 'なし'){
                input役職.value = '';
                checkClassNothing.value = true;
            }
        });

        const handleFamilyNameInput = () => {
            input姓_カナ.value = convertKatakana(autokana_family.getFurigana());
        }

        const handleFirstNameInput = () => {
            input名_カナ.value = convertKatakana(autokana_first.getFurigana());
        }

        const convertKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function(match) {
                const chr = match.charCodeAt(0) + 0x60;
                return String.fromCharCode(chr);
            });
        }

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const backList = () => {
            const url ='/al/administrator/show_user_list';

            location.href = url;
        }

        const user_number_list = ref([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list);
        }

        const showPayPerUse = () => {
            const url ="/al/administrator/register_pay_per_use?back=RegisterUser";

            location.href = url;
        }

        const makePassword = () => {
            inputPassword.value = CommonMakePassword(12);
        }

        const input利用者ID = ref('');
        const input姓 = ref('');
        const input名 = ref('');
        const input姓_カナ = ref('');
        const input名_カナ = ref('');
        const input拠点= ref('');
        const input部署 = ref('');
        const input役職 = ref('');
        const input電話番号1 = ref('');
        const input電話番号2 = ref('');
        const input電話番号3 = ref('');
        const inputEmail = ref('');
        const input責任者候補 = ref(false);
        const input管理者 = ref(false);
        const input家族数 = ref(0);
        const inputPassword = ref('');

        const isProcessing = ref(false);

        const 電話番号_message= ref('');
        const 電話番号_has_error = ref(false);
        const email_message= ref('');
        const email_has_error = ref(false);
        const 家族人数_message= ref('');
        const 家族人数_has_error = ref(false);
        const 利用者ID_message= ref('');
        const 利用者ID_has_error = ref(false);

        const post_form = () => {
            const url = '/api/editUser';

            電話番号_message.value = '';
            電話番号_has_error.value = false;
            email_message.value = '';
            email_has_error.value = false;
            家族人数_message.value = '';
            家族人数_has_error.value = false;
            利用者ID_message.value = '';
            利用者ID_has_error.value = false;

            if(inputPassword.value){
                if(confirm("パスワードが変更されました。\n新パスワードをお控えのうえ、更新をしてください") === false){
                    return false;
                }
            }

            isProcessing.value = true;

            axios.post(url, {
                id: props.user_info.id,
                利用者ID: input利用者ID.value,
                姓: input姓.value,
                名: input名.value,
                姓_カナ: input姓_カナ.value,
                名_カナ: input名_カナ.value,
                拠点: input拠点.value,
                部署: input部署.value,
                役職: input役職.value,
                電話番号: input電話番号1.value === '' ? '' : input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                email: inputEmail.value,
                password: inputPassword.value,
                責任者候補: input責任者候補.value,
                管理者: input管理者.value,
                家族人数: input家族数.value,

            }).then(res=>{
                const url = '/al/administrator/show_user_list'

                location.href = url;
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.電話番号){
                        電話番号_message.value = err.response.data.errors.電話番号[0];
                        電話番号_has_error.value = true;
                    }
                    if(err.response.data.errors.email){
                        email_message.value = err.response.data.errors.email[0];
                        email_has_error.value = true;
                    }
                    if(err.response.data.errors.家族人数){
                        家族人数_message.value = err.response.data.errors.家族人数[0];
                        家族人数_has_error.value = true;
                    }
                    if(err.response.data.errors.利用者ID){
                        利用者ID_message.value = err.response.data.errors.利用者ID[0];
                        利用者ID_has_error.value = true;
                    }
                }
            }).finally(fin => {
                isProcessing.value = false;
            })
        }

        const checkDepartmentNothing = ref(false);
        const checkClassNothing = ref(false);
        const tmpDepartment = ref('');
        const tmpPosition = ref('');

        const changeDepartmentNothing = () => {
            if(checkDepartmentNothing.value){
                tmpDepartment.value = input部署.value;
                input部署.value = "";
            }else{
                input部署.value = tmpDepartment.value;
            }
        }

        const changeClassNothing = () => {
            if(checkClassNothing.value){
                tmpPosition.value = input役職.value;
                input役職.value = "";
            }else{
                input役職.value = tmpPosition.value;
            }
        }

        return {
            backHome,
            backList,

            handleFamilyNameInput,
            handleFirstNameInput,
            convertKatakana,

            user_number_list,
            showPayPerUse,

            callReplaceWord,

            makePassword,

            input利用者ID,
            input姓,
            input名,
            input姓_カナ,
            input名_カナ,
            input拠点,
            input部署,
            input役職,
            input電話番号1,
            input電話番号2,
            input電話番号3,
            inputEmail,
            input責任者候補,
            input管理者,
            input家族数,
            inputPassword,

            post_form,

            isProcessing,

            電話番号_message,
            電話番号_has_error,
            email_message,
            email_has_error,
            家族人数_message,
            家族人数_has_error,
            利用者ID_message,
            利用者ID_has_error,

            changeDepartmentNothing,
            changeClassNothing,

            checkDepartmentNothing,
            checkClassNothing,
            tmpDepartment,
            tmpPosition,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        pay_per_use_info: {
            type: Object,
        },
        user_list: {
            type: Array,
        },
        replacement_word_list: {
            type: Array,
        },
        user_number_list: {
            type: Object,
        },
        branch_list: {
            type: Array,
        },
        user_info: {
            type: Object,
        },
        emergency_order_matters: {
            type: Object,
        },
        main_responsibilities: {
            type: Array,
        },
    }
}
</script>
