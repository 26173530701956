<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式12-3</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 情報連絡</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 緊急時においても従業員と連絡をとることができるように、従業員（または事業主）の情報を以下に整理する。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">従業員連絡リスト［基本情報整理用］</label>
                </div>
            </div>
            <template v-for="(userList, groupIndex) in groupUserList" :key="groupIndex">
                <div class="row mt-3 ms-md-1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                        <label class="form-label">部署</label>
                    </div>
                </div>
                <div class="row ms-md-1">
                    <div class="p2 col-md-5 border d-flex align-items-center justify-content-start">
                        <div class="form-control border-white ">{{ groupIndex }}</div>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="d-none d-md-block d-print-block">
                        <table class="table table table-bordered" id="groupTable">
                            <thead>
                                <tr class="bg-gray-3">
                                    <th scope="col">利用者ID</th>
                                    <th scope="col">{{ callReplaceWord('従業員') }}氏名</th>
                                    <th scope="col">主要な責務</th>
                                    <th scope="col">緊急時の依頼事項</th>
                                    <th scope="col" colspan="2">{{ callReplaceWord('事業') }}復旧に有効な資格・技能等</th>
                                    <th scope="col">自宅電話／携帯電話</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="user in userList">
                                    <tr>
                                        <td rowspan="2">
                                            <div class="form-label border-white ">{{ user.利用者ID || '&emsp;' }}</div>
                                        </td>
                                        <td rowspan="2">
                                            <div class="form-label border-white ">{{ user.氏名 || '&emsp;' }}</div>
                                        </td>
                                        <td rowspan="2">
                                            <div class="form-label border-white ">{{ user.主な責務 || '&emsp;' }}</div>
                                        </td>
                                        <td rowspan="2">
                                            <div class="form-label border-white ">{{ user.order_matter || '&emsp;' }}</div>
                                        </td>
                                        <td rowspan="2">
                                            <div class="form-label border-white ">{{ user.資格技能 || '&emsp;' }}</div>
                                        </td>
                                        <td rowspan="2">
                                            <div class="form-label border-white ">{{ user.資格技能詳細 || '&emsp;' }}</div>
                                        </td>
                                        <td class="bg-info">
                                            <div class="form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : user.自宅電話 || '&emsp;' }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg-info">
                                            <div class="form-label bg-info">{{ !is('administrator') && isViewLimit ? '&emsp;' : user.個人携帯電話 || '&emsp;' }}</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-md-none d-print-none">
                        <div class="row mt-2" v-for="user in userList" :key="user.利用者ID">
                            <div class="col-12 bg-gray-3 border">
                                利用者ID
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ user.利用者ID || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                {{ callReplaceWord('従業員') }}氏名
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ user.氏名 || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                主要な責務
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ user.主な責務 || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                緊急時の依頼事項
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ user.order_matter || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                {{ callReplaceWord('事業') }}復旧に有効な資格等
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ user.資格技能 || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                {{ callReplaceWord('事業') }}復旧に有効な技能等
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ user.資格技能詳細 || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                自宅電話
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ !is('administrator') && isViewLimit ? '&emsp;' : user.自宅電話 || '&emsp;' }}
                            </div>
                            <div class="col-12 bg-gray-3 border border-top-0">
                                携帯電話
                            </div>
                            <div class="col-12 border border-top-0 ">
                                {{ !is('administrator') && isViewLimit ? '&emsp;' : user.個人携帯電話 || '&emsp;' }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const isViewLimit = ref(false);

        const groupUserList = ref(0);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K11';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K13';
            location.href = url;
        }

        const groupBy = (xs, key) => {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        props.group_user_list.forEach( user => {
            user.主な責務 = user.主な責務.replace(/(@@顧客@@)/g, callReplaceWord('顧客'))
            user.is_view_limit = user.is_view_limit == 1 ? true : false;
            if (user.資格技能詳細 !== null && user.備考 !== null) {
                user.資格技能詳細 = [user.資格技能詳細, user.備考].join('、')
            } else if (user.資格技能詳細 === null && user.備考 !== null) {
                user.資格技能詳細 = user.備考
            }
        });
        groupUserList.value = groupBy(props.group_user_list, '部署');

        if (props.bcp_values_info.length) {
            isViewLimit.value = props.bcp_values_info.find(
                (fetchData) => fetchData.column_name === 'view_limit'
            )?.value === '1';
        }

        return {
            setLevel,
            isMargin1,
            isViewLimit,
            groupUserList,
            callReplaceWord,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        group_user_list: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

table {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

#groupTable thead tr th:nth-child(1) {
    width: 5%;
}

#groupTable thead tr th:nth-child(n+2):nth-child(-n+4),
#groupTable thead tr th:nth-child(6) {
    width: 15%;
}

#groupTable {
    width: 100%;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    table {
        border-color: black;
        color: black;
    }

    .col-md-5 {
        width: 41.66666667%;
    }
}
</style>
