<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap">様式19</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 被災時において緊急支援が到着するまでに必要となる防災用具の整備状況は以下のとおりである。 </label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">災害対応用具チェックリスト</label>
                </div>
            </div>
            <div class="mt-3">
                <div class="row mx-md-3" v-for="(item, index) in valueFormsField">
                    <div class="p2 col-2 col-md-1 border border-end-0 d-flex align-items-center justify-content-center" :class="{ 'border-bottom-0' : index !== valueFormsField.length - 1}">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onclick="return false;" :checked="Number(valueBySeq(item, 1))">
                        </div>
                    </div>
                    <div class="p2 col col-md-10 border d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== valueFormsField.length - 1}">
                        <label class="form-label">{{ valueBySeq(item, 2) }}</label>
                    </div>
                </div>
            </div>
        </div>

        <br>

    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="BCP閲覧 HOMEへ戻る" @click="showHome" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);

        const valueFormsField = ref(Object.values(props.bcp_values_info));

        const showBack = () => {
            const url = '/browse/K17';
            location.href = url;
        }

        const showHome = () => {
            const url = '/browse/home';
            location.href = url;
        }

        const valueBySeq = (item, seq) => {
            return item.find((d) => d.seq === seq)?.value;
        }

        return {
            setLevel,
            isMargin1,
            valueFormsField,
            showBack,
            showHome,
            valueBySeq,
        }
    },
    props:{
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="checkbox"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }
}
</style>
