<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">賛助会員一覧画面</div>
                <div class="flash col-5 alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                    {{ flashMessage }}
                </div>

            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-3">
            <div id="annual_charge"><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">賛助会員</label></div>
        </div>
        <div class="row mt-3 ms-5 mb-5">
            <table class="table table-bordered bg-light">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>賛助会員コード</th>
                        <th>賛助組織名</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(supporting_member, index) in supporting_member_info" :key="supporting_member.賛助会員コード">
                        <td class="col-form-label">{{ index + 1 }}</td>
                        <td class="col-form-label">{{ supporting_member.賛助会員コード }}</td>
                        <td><input type="text" v-model="supporting_member.賛助組織名" class="form-control"></td>
                        <td>
                            <input type="button" class="btn btn-red px-3 " value="更新" @click="editMember(supporting_member.賛助会員コード, supporting_member.賛助組織名)" />
                            <input type="button" class="btn btn-black px-3 ms-5" value="削除" @click="deleteMember(supporting_member.賛助会員コード, supporting_member.賛助組織名)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios';

export default {
    setup(props) {
        const back = () => {
            const url = '/s-admin/manage_group_supporting';

            location.href = url;
        }

        const editMember = (id, name) => {
            const url = '/api/s-admin/updateSupportingMember';

            axios.post(url, {
                id: id,
                name: name,
            }).then(res=> {
                visibleFlashMessage('正常に更新されました', 'success');

            });
        }

        const deleteMember = (id, name) => {
            if(!confirm(name + 'を削除してよろしいですか')){
                return;
            }

            const url = '/api/s-admin/deleteSupportingMember';

            axios.delete(url, {data: {id: id}})
            .then(res => {
                const url = '/s-admin/showSupportingMemberList';

                location.href = url;
            })
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        
        return {
            back,
            editMember,
            deleteMember,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,

            visibleFlashMessage,
        }
    },
    props: {
        supporting_member_info: {
            type: Array,
        }
    }
}
</script>
