<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">ととのエルとは</div>
        </div>

        <ul class="nav nav-tabs pt-3 font-s-half-up fs-md-sm">
            <li class="nav-item col-6 tab-1">
                <a class="nav-link active tab1_link text-center" href="#tab-content1" data-bs-toggle="tab">ととのエル開発に至った社会の課題</a>
            </li>
            <li class="nav-item col-6 tab-2">
                <a class="nav-link tab2_link text-center" href="#tab-content2" data-bs-toggle="tab">ととのエル開発に向けた想い</a>
            </li>
        </ul>

        <div class="tab-content">
            <div id="tab-content1" class="tab-pane active">
                <div class="row">
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-01" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                BCPを策定したいけれど、<br />できない中小企業が多いこと
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    全国の中小企業のBCP策定率は、依然として低く、「策定に必要なスキル・ノウハウがない」と回答する企業が半数を占めるアンケート結果があります。策定に必要なノウハウは、国の公表資料や専門書等で学習することができますが、策定には経験値も必要であるうえ、同業の策定サンプルもありません。結局的に、専門家に依頼しなければわからないという事案が散見しています。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-02" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                紙媒体のBCPでは<br />運用サイクルの可視化が難しいこと
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    上級レベルのBCPは、50ページにも及ぶ紙媒体で作成します。しかし、BCPの策定以上に重要な運用サイクル（計画、教育・訓練、検証、見直し等）については、紙媒体であっても継続して行っている企業は非常に少ないといえます。また、運用サイクルの履歴を残すことができたとしても、紙媒体ではすべての従業員に周知することに限界が生じてしまいます。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-03" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                代替策を検討するとき、<br />信頼できる同業他社を探すのが難しいこと
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    緊急時の代替戦略の提携先は、同業や関連業種がターゲットとなります。特殊な業務や商品等だった場合は、同一の取引を求めることが困難となります。また、代替提携先の同業者を見つけたとしても、災害が収束した後に顧客が代替提携先への流出してしまうのではないかという不安が生じてしまいます。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-04" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                ハザードマップ利用が無償であっても、<br />BCP策定コンサルティングは有償であること
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    国や都道府県が提供するハザードマップなどの情報は、すべて無償で利用することができます。一方、BCP策定に必要な専門家のコンサルティング料や、安否確認サービス等はいずれも有償で高額な料金設定が多く、中小企業のBCP策定の障害になっている現状があります。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-05" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                BCPを策定するために集めなければならない<br />情報が分散していること
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    BCPは、自社の災害被害を想定する際、参考となる情報を集める必要がありますが、情報の入手先はバラバラです。情報収集に多くの労力を要する現状では、策定を煩雑にするだけでなく、策定したBCPの有意性を保ち続けることが難しくなります。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-06" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                地方自治体と民間企業の<br />官民連携が整っていないこと
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    地方自治体が策定する「国土強靱化地域計画」では、「民」主導の取組みを活性化させる等の環境整備を求められていますが、企業全体のBCP策定率の低さもあって、官民連携は効果的に機能しているとは言えない状況が続いています。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-07" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                                このままでは、我が国は壊滅的な<br />災害に耐えられないこと
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    BCPの実行力を確保できず、代替戦略や官民連携が効果的に機能しないまま、大規模災害が発生した場合、早期復旧や事業継続ができず倒産に追い込まれる企業が増加します。地域経済は衰退し、ひいては国力が弱体化してしまいます。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-5">
                    </div>
                </div>
            </div>
            <div id="tab-content2" class="tab-pane">
                <div class="row">
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-11" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-tab2 text-light">
                                BCP 策定のノウハウは、<br />岐阜大学小山准教授主宰の研究チームとタックを実現
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    防災工学が専門の岐阜大学小山准教授が主宰する研究チームからBCP策定に関する学術的知見を得ています。具体的には、チャットボットQ&Aのシナリオデータ分析や作成監修を行っていただきました。国立研究開発法人防災科学技術研究所部門長の臼田氏には、同研究所とのハザードマップや即時被害推定情報の連携実現に向けてご尽力いただきました。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-12" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-tab2 text-light">
                                あらゆる業種のBCPを効果的に運用するためには、<br />策定手順を標準化
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    開発期間中、数多くの企業が小山准教授のコンサルティングを受け、サンプルBCPを集めながら策定手順を標準化いたしました。AIチャットボットやヒント表示の誘導によって、あらゆる業種の実情に応じ、容易にBCPを策定することができます。また、これまで紙媒体による管理が多かったBCP策定業務をクラウド上で行うことで、データをリアルタイムで可視化できるようになりました。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-13" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-tab2 text-light">
                                安価で効果的なBCP策定環境を提供し、<br />企業経営の強靭化を支援
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    システムの利用料は年会費（2022年度45,000円）に加え、クラウドへの負担を考慮した実費相当の従量課金にとどめ、中小企業が取組みやすい価格帯とします。また、災害協定ビジネスマッチング提携先との契約は、違反企業の公表等を含む当会のモデル協定書の使用を推奨し、より安全な締結を支援してまいります。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-5">
                        <div class="card">
                            <div class="relative d-flex justify-content-center py-3">
                                <i id="about-image-14" class="top_image" />
                            </div>
                            <div class="py-2 mx-3 mx-md-5 mt-3 mb-1 text-center bg-tab2 text-light">
                                防災ビッグデータの活発な利活用による<br />ネットワーク効果を目指す
                            </div>
                            <div class="card-body px-3 px-md-5">
                                <div class="card-text">
                                    「大規模災害発生は異なる地域と同時被災をしない」という一般論を踏まえ、全国の中小企業を対象としたBCP策定や災害協定ビジネスマッチングをサポートするとともに、長期的に防災ビッグデータを蓄積し、さらに精度の高いBCP策定が実現できるシステムを構築してまいります。「強さ」と「しなやかさ」を持った安全な日本経済の構築を実現したいと思います。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tab-1, #tab-content1{
    background-color: #D9D9D9;
}

.tab-2, #tab-content2{
    background-color: #fce4d6;
}

a:link.tab1_link, 
a:visited.tab1_link, 
a:hover.tab1_link, 
a:active.tab1_link{
    color: #b93133;
}

a:link.tab2_link, 
a:visited.tab2_link, 
a:hover.tab2_link, 
a:active.tab2_link{
    color: #ff6600;
}

.nav-tabs .tab-1 .nav-link.active{
    background-color: #D9D9D9;
    border-color: black black #D9D9D9;
}

.nav-tabs .tab-2 .nav-link.active{
    background-color: #fce4d6;
    border-color: black black #fce4d6;
}

.tab-content{
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

#about-image-01{
    background-image: url('../../../svg/02-01.svg');
}

#about-image-02{
    background-image: url('../../../svg/02-02.svg');
}

#about-image-03{
    background-image: url('../../../svg/02-03.svg');
}

#about-image-04{
    background-image: url('../../../svg/02-04.svg');
}

#about-image-05{
    background-image: url('../../../svg/02-05.svg');
}

#about-image-06{
    background-image: url('../../../svg/02-06.svg');
}

#about-image-07{
    background-image: url('../../../svg/02-07.svg');
}

#about-image-11{
    background-image: url('../../../svg/02-11.svg');
}

#about-image-12{
    background-image: url('../../../svg/02-12.svg');
}

#about-image-13{
    background-image: url('../../../svg/02-13.svg');
}

#about-image-14{
    background-image: url('../../../svg/02-14.svg');
}

.bg-tab2{
    background-color: #ff6600;
}

@media (max-width: 767px) {
    .fs-md-sm {
        font-size: 1rem;
    }
}

</style>