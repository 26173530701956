<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式16-2</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">• 資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">中核{{ callReplaceWord('事業') }}に係るボトルネック資源</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">・ 当社の中核事業継続においてボトルネックとなるコンピュータ、周辺機器及びソフトウェアには以下のものがある。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">2&emsp;コンピュータ機器とソフトウェア</label>
                </div>
            </div>
            <div class="ms-md-3" v-for="item in bottleneckResourceInfo">
                <div class="row mt-3">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">当該資源を利用する{{ callReplaceWord('社内') }}重要業務</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.importantBusinessContent || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">社内責任者</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.importantBusinessManager || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">当該資源の現状</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="現在、使用中" value="1" onclick="return false;" :checked="item.currentResource.includes('1')">
                            <label class="form-check-label">現在、使用中</label>
                        </div>
                        <div class="ms-md-5 form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="リース予定／購入予定" value="2" onclick="return false;" :checked="item.currentResource.includes('2')">
                            <label class="form-check-label">リース予定／購入予定</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">資源の品名・バージョン等</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.productName || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">数量(機器)</label>
                    </div>
                    <div class="p2 col-md-1 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-center d-none d-md-block d-print-block">
                        <label class="form-label">{{ item.equipmentQuantity || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-md-6 border border-start-0 border-bottom-0 bg-white d-flex align-items-center justify-content-start d-none d-md-block d-print-block">
                        <label class="form-label">台</label>
                    </div>
                    <div class="border border-bottom-0 d-md-none d-print-none">
                        <label class="form-label">{{ item.equipmentQuantity + " 台" || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">タイプ</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="コンピュータ｜パソコンやサーバなど" value="1" onclick="return false;" :checked="item.types.includes('1')">
                            <label class="form-check-label">コンピュータ｜パソコンやサーバなど</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="各種周辺機器" value="2" onclick="return false;" :checked="item.types.includes('2')">
                            <label class="form-check-label">各種周辺機器</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="ソフトウェア" value="3" onclick="return false;" :checked="item.types.includes('3')">
                            <label class="form-check-label">ソフトウェア</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">供給事業者</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.supplyCompany || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">供給事業者メールアドレス</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.supplyCompanyEmail || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">供給事業者（予備）</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.supplyCompanyExtra || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">供給事業者（予備）メールアドレス</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.supplyCompanyExtraEmail || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-bottom-0 border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('事業') }}継続の際の想定設置場所</label>
                    </div>
                    <div class="p2 col-md-7 border border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.installationLocation || '&emsp;' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="p2 col-md-5 border border-end-md-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考（購買/リースした日付・価格、シリアル番号 等）</label>
                    </div>
                    <div class="p2 col-md-7 border bg-white d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ item.note || '&emsp;' }}</label>
                    </div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        let bottleneckResourceInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/J4';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/J6';
            location.href = url;
        }

        if (props.bottleneck_resources_info.length > 0) {
            bottleneckResourceInfo = props.bottleneck_resources_info.map(
                (fetchData) => ({
                    resourceType: fetchData.resource_type,
                    isViewLimit: fetchData.is_view_limit,
                    productName: fetchData.product_name,
                    equipmentQuantity: fetchData.equipment_quantity,
                    types: fetchData.types?.split(',') || [],
                    supplyCompanyCorpId: fetchData.supply_company_corp_id,
                    supplyCompany: fetchData.supply_company,
                    supplyCompanyEmail: fetchData.supply_company_email,
                    supplyCompanyExtraCorpId: fetchData.supply_company_extra_corp_id,
                    supplyCompanyExtra: fetchData.supply_company_extra,
                    supplyCompanyExtraEmail: fetchData.supply_company_extra_email,
                    importantBusinessId: fetchData.important_business_id,
                    importantBusinessContent: fetchData.important_business_content,
                    importantBusinessManager: fetchData.important_business_manager,
                    currentResource: fetchData.current_resource?.split(',') || [],
                    installationLocation: fetchData.installation_location,
                    installationTime: fetchData.installation_time,
                    note: fetchData.note,
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            bottleneckResourceInfo,
            callReplaceWord,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bottleneck_resources_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="checkbox"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }
}
</style>
