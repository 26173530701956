<template>
   <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　被害想定と事前対策｜事前対策
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic" value="基本｜3.中核事業と復旧目標 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black" value="前ページへ戻る"  :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-7 mt-3">
            <levelIcon
                :set_level="setLevel"
            />
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-5 bg-sub-title-1 text-dark d-flex align-items-center justify-content-start">
            ◯　{{ callReplaceWord('経営') }}資源（人・モノ・金・情報）への事前対策
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
            事前対策の検討ステップを基に、内容を整理しています。<br>各ステップに沿い、あなたの{{ callReplaceWord('組織') }}の事前対策の実施状況等について、記入してください。
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                theme="hint-theme"
            >
                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                <template #popper>
                    <div class="row">
                        <label>地震等によるあなたの{{ callReplaceWord('組織') }}への影響があった場合でも、{{ callReplaceWord('組織') }}は、重要{{ callReplaceWord('商品') }}を提供していかなければなりません。そして、重要{{ callReplaceWord('商品') }}を提供し続けるためには、{{ callReplaceWord('製造') }}に携わる{{ callReplaceWord('従業員') }}や機械設備等、様々な{{ callReplaceWord('経営') }}資源（人、物、金、情報 等）が必要となります。そのため、緊急時においても、あなたの{{ callReplaceWord('組織') }}がこうした必要な経営資源を確保するための対策（事前対策）を平常時から検討・実施しておくことが重要です。</label>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-1 border border-dark d-flex align-items-center justify-content-center py-2">
            ステップ 1
        </div>
        <div class="p2 col-md-7 d-flex flex-column justify-content-start py-2">
            <span>事前対策の実施状況の把握</span>
            <span>あなたの{{ callReplaceWord('組織') }}でどのような事前対策が実施できているのかを確認します。</span>
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-1 border border-dark d-flex align-items-center justify-content-center py-2">
            ステップ 2
        </div>
        <div class="p2 col-md-11 d-flex flex-column justify-content-start py-2">
            <span>事前対策の検討・実施</span>
            <span>あなたの{{ callReplaceWord('組織') }}で実施できている対策、もしくはこれから実施しなければならない事前対策を検討し記入します。</span>
        </div>
    </div>

    <div v-for="(form, formIndex) in valueForms" :key="formIndex">
        <hr v-if="formIndex %2 === 0 && formIndex !== 0">

        <div class="row ms-3 mt-5" v-if="formIndex %2 === 0">
            <div class="p2 col-md-5 bg-sub-title-2 text-dark d-flex align-items-center justify-content-start" >
                <span v-if="formIndex === 0">◯　{{ callReplaceWord('経営') }}資源：人（ヒト）</span>
                <span v-else-if="formIndex === 2">◯　{{ callReplaceWord('経営') }}資源：物（モノ）</span>
                <span v-else-if="formIndex === 4">◯　{{ callReplaceWord('経営') }}資源：金（カネ）</span>
                <span v-else-if="formIndex === 6">◯　{{ callReplaceWord('経営') }}資源：情報</span>
                <span v-else-if="formIndex === 8">◯　その他の事前対策</span>
            </div>
        </div>

        <div v-for="(val, index) in form.values" :key="index">
            <div class="row ms-3 mt-5" v-if="index === 0">
                <div class="p2 col-md-1 border border-dark d-flex align-items-center justify-content-center py-2">
                    ステップ 1
                </div>
                <div class="p2 col-md-7 text-danger d-flex align-items-center justify-content-start py-2">
                    <span v-if="formIndex === 0">1.　{{ callReplaceWord('従業員') }}の安否確認ルールの決定や安否確認手段の確保を行っていますか？</span>
                    <span v-else-if="formIndex === 1">2.　緊急時に必要な{{ callReplaceWord('従業員') }}が{{ callReplaceWord('出社') }}できない場合に、代行できる{{ callReplaceWord('従業員') }}を育成していますか？</span>
                    <span v-else-if="formIndex === 2">1.　什器や棚等、設備を固定していますか？</span>
                    <span v-else-if="formIndex === 3">2.　{{ callReplaceWord('原材料') }}の代替調達や代替{{ callReplaceWord('生産') }}等、業務を代替して行う手段を確保していますか？</span>
                    <span v-else-if="formIndex === 4">1.　操業が停止した場合に、必要な運転資金を把握していますか？</span>
                    <span v-else-if="formIndex === 5">2.　緊急時に運転資金として活用できる現金・預金を準備していますか？</span>
                    <span v-else-if="formIndex === 6">1.　重要なデータを特別に保管（バックアップ、耐火金庫等）していますか？</span>
                    <span v-else-if="formIndex === 7">2.　緊急時に{{ callReplaceWord('取引先') }}等へ情報を発信、{{ callReplaceWord('取引先') }}等の情報を収集する手段を整備していますか？</span>
                    <span v-else-if="formIndex === 8">1.　{{ callReplaceWord('取引先') }}及び同業者等と災害発生時の相互支援について取り決めていますか？</span>
                    <span v-else-if="formIndex === 9">2.　緊急時に自治体・公共団体・地域住民等、多様な連携体制はありますか？</span>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center custom-control custom-radio custom-control-inline">
                    <input type="radio" :id="'customRadioInline1' + formIndex" :name="'customRadioInline1' + formIndex" class="custom-control-input" value="はい" v-model="val.value">
                    <label class="custom-control-label" :for="'customRadioInline1' + formIndex">はい</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center custom-control custom-radio custom-control-inline">
                    <input type="radio" :id="'customRadioInline2' + formIndex" :name="'customRadioInline2' + formIndex" class="custom-control-input" value="いいえ" v-model="val.value">
                    <label class="custom-control-label" :for="'customRadioInline2' + formIndex">いいえ</label>
                </div>
                <div class="p2 ms-2 col-md-1 d-flex align-items-center justify-content-start" v-if="formIndex % 2 === 0 || formIndex === 9">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                                <label v-if="formIndex === 0">緊急時に重要{{ callReplaceWord('商品') }}を提供し続けるために、「人」（{{ callReplaceWord('従業員') }}の安否確認・代替人員等）に関する事前対策が実施できているのかを「はい」「いいえ」で回答します。ステップ1で「はい」と答えた場合は、ステップ２にその対策を記入します。「いいえ」と答えた場合はこれから実施しなければならない事前対策を検討して記入します。いずれも「いつ」「誰が」「何を」するのかを明記してください。</label>
                                <label v-if="formIndex === 2">緊急時に重要{{ callReplaceWord('商品') }}を提供し続けるために、「物」（設備・代替先等）に関する事前対策が実施できているのかを「はい」「いいえ」で回答します。ステップ1で「はい」と答えた場合は、ステップ２にその対策を記入します。「いいえ」と答えた場合はこれから実施しなければならない事前対策を検討して記入します。いずれも「いつ」「誰が」「何を」するのかを明記してください。</label>
                                <label v-if="formIndex === 4">緊急時に重要{{ callReplaceWord('商品') }}を提供し続けるために、「金」（運転資金・現預金等）に関する事前対策が実施できているのかを「はい」「いいえ」で回答します。ステップ1で「はい」と答えた場合は、ステップ２にその対策を記入します。「いいえ」と答えた場合はこれから実施しなければならない事前対策を検討して記入します。いずれも「いつ」「誰が」「何を」するのかを明記してください。</label>
                                <label v-if="formIndex === 6">緊急時に重要{{ callReplaceWord('商品') }}を提供し続けるために、「情報」（データ保管・情報収集等）に関する事前対策が実施できているのかを「はい」「いいえ」で回答します。ステップ1で「はい」と答えた場合は、ステップ２にその対策を記入します。「いいえ」と答えた場合はこれから実施しなければならない事前対策を検討して記入します。いずれも「いつ」「誰が」「何を」するのかを明記してください。</label>
                                <label v-if="formIndex === 8">緊急時に重要{{ callReplaceWord('商品') }}を提供し続けるために、災害発生時の相互支援協定等の取り決めや契約書等があるかどうかを「はい」「いいえ」で回答します。ステップ1で「はい」と答えた場合は、ステップ２にその対策を記入します。「いいえ」と答えた場合はこれから実施しなければならない事前対策を検討して記入します。いずれも「いつ」「誰が」「何を」するのかを明記してください。※基本コース以上の策定では、本サイト内「B&B災害応援協定ビジネスマッチング」機能がご利用いただけます。</label>
                                <label v-if="formIndex === 9">緊急時に重要{{ callReplaceWord('商品') }}を提供し続けるために、市区町村・学校、地域防災団体などとの連携体制があるかどうかを「はい」「いいえ」で回答します。ステップ1で「はい」と答えた場合は、ステップ２にその対策を記入します。「いいえ」と答えた場合はこれから実施しなければならない事前対策を検討して記入します。いずれも「いつ」「誰が」「何を」するのかを明記してください。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>

            <div class="row ms-3 mt-3" v-if="index >= 1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2" :class="{'border border-dark':index === 1}">
                    <span v-if="index === 1">ステップ 2</span>
                </div>
                <div class="p2 col-md-7 d-flex align-items-center justify-content-start py-2">
                    <span v-if="index === 1">何をやりますか？</span>
                    <span v-else-if="index === 2">誰がやりますか？</span>
                    <span v-else>いつやりますか？</span>
                </div>
            </div>

            <div class="row ms-3" v-if="index >= 1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-7 d-flex align-items-center justify-content-start">
                    <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="val.value">
                </div>
                <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="index == 1">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                                <label v-if="formIndex === 0">緊急事態発生時に{{ callReplaceWord('従業員') }}の安否確認を行う手段があり、確認方法のルールを定めている場合、内容をご記入してください。定めていない場合は、例えば、携帯電話を用いた緊急連絡網により安否確認を実施する、LINE Worksを活用した安否確認等、どうやって安否確認を行うのが適しているのかを検討し、ご記入してください。</label>
                                <label v-if="formIndex === 1">必要な{{ callReplaceWord('従業員') }}が{{ callReplaceWord('出社') }}できない場合の対策を検討している場合はご記入してください。検討していない場合は、代替要員を決め、作業手順を教育する等、代行できる{{ callReplaceWord('従業員') }}を育成する方法を検討し、ご記入ください。</label>
                                <label v-if="formIndex === 2">安全性を考える上で什器や棚、設備の固定が必要な場合の対策を行っている場合、内容をご記入ください。行っていない場合は、どの什器に対してどんな対策を行うかなど具体的にご記入ください。</label>
                                <label v-if="formIndex === 3">緊急事態により業務が中断した場合に代替する手段を確保していれば内容をご記入ください。確保していない場合は事前対策（部品の納入について別の{{ callReplaceWord('組織') }}と支援協定を結ぶなど）を検討し、ご記入ください。</label>
                                <label v-if="formIndex === 4">操業が停止した場合や{{ callReplaceWord('取引先') }}の被災状況により{{ callReplaceWord('売上') }}が入金されない場合など、必要な運転資金を把握している場合はその内容についてご記入ください。把握していない場合は把握するために何をやるかを具体的にご記入ください。</label>
                                <label v-if="formIndex === 5">緊急時のための運転資金について、何らかの準備がされていますか。されている場合その内容を具体的に記入してください。されていない場合、運転資金を確保するために行うことを具体的に記入してください。現金・預金の他に保険や特別借入も含みます。</label>
                                <label v-if="formIndex === 6">{{ callReplaceWord('社内') }}外に関わる全ての重要なデータや情報のバックアップをとっており、同一場所保管をしていない等、何らかの対策をとっている場合は内容をご記入ください。対策をとっていない場合はクラウドにバックアップを取る等、対策を検討しご記入ください。</label>
                                <label v-if="formIndex === 7">緊急時に{{ callReplaceWord('取引先') }}等へ連絡できる手段（メールや電話）を確保している場合は内容をご記入ください。なお、災害時は電気通信網の渋滞が発生し電話がつながりにくい状況が続くため、そのような場合の代替手段を用意していれば併せてご記入ください。確保していない場合は、連絡手段を確保するために行うことについて具体的にご記入ください（電話やメールだけでなくSNSや無線を活用するために複数手段の連絡先を交換しておくなど）。</label>
                                <label v-if="formIndex === 8">{{ callReplaceWord('取引先') }}と、相互支援の取り決めがある場合はご記入ください。取り決めがない場合は、相互支援があったほうがよいと思われることについて取り決めに向けた対策を具体的に記入してください。</label>
                                <label v-if="formIndex === 9">緊急時に市区町村・学校、地域防災団体などとの連携体制がある場合はご記入ください。ない場合で、連携体制があったほうが良いと思われる場合、連携体制構築に向けた対策を具体的に記入してください。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction" value="入門｜4.BCP発動フロー へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
             if(props.bcp_introduction_values_info.length > 0){
                props.bcp_introduction_values_info.forEach(data => {
                    valueForms.forEach(initData => {
                        if(data.column_name == initData.columnName){
                            initData.values.splice(data.seq-1, 1, {value: data.value});
                        }
                    });
                });
             }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms = reactive([]);
        for (let i = 33; i <= 42; i++) {
            valueForms.push({
                columnName: 'N000' + i,
                values:[
                    {value: ''},
                    {value: ''},
                    {value: ''},
                    {value: ''},
                ]
            });
        }

        const showBasic = async () => {
            // 被害想定を登録
            const result = await registerIntroduction5();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/3';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 被害想定を登録
            const result = await registerIntroduction5();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 被害想定を登録
            const result = await registerIntroduction5();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/7';
                location.href = url;
            }
        }

        const showContents = async () => {
           // 被害想定を登録
           const result = await registerIntroduction5();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 被害想定の登録
        const registerIntroduction5 = async () => {
            // リクエストの整形
            const reqValueForms = [];
            valueForms.forEach(data => {
                data.values.forEach((val, index) => {
                    if (val.value.length > 0){
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: index + 1,
                            value: val.value,
                        });
                    }
                })
            })

            const postUrl = '/api/bcp/registerIntroduction5';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            valueForms,
            isProcessing,
            callReplaceWord,
            showBasic,
            showBack,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
    .bg-sub-title-1 {
        background-color: #D9D9D9;
    }
    .bg-sub-title-2 {
        background-color: #ddebf7;
    }
</style>
