<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label text-nowrap">様式12-1</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 情報連絡</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">従業員連絡リスト［従業員一覧］</label>
                </div>
            </div>
            <div class="pb-5 d-none d-md-block d-print-block">
                <div class="row ms-3">
                    <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start py-2" style="font-size:12pt">
                        個別リストNo.
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 bg-white d-flex align-items-center justify-content-start py-2">
                        {{ callReplaceWord('従業員') }}氏名
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2">
                        &emsp;
                    </div>
                    <div class="ms-5 p2 col-md-2 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start py-2" style="font-size:12pt">
                        個別リストNo.
                    </div>
                    <div class="p2 col-md-2 border border-bottom-0 bg-white d-flex align-items-center justify-content-start py-2">
                        {{ callReplaceWord('従業員') }}氏名
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2">
                        &emsp;
                    </div>
                </div>
                <div class="row ms-3" v-for="num in totalRows = list_frame_number / 2">
                    <div class="p2 col-md-2 border border-end-0 bg-white d-flex align-items-center justify-content-center py-2" style="font-size:12pt" :class="{ 'border-bottom-0' : num !== totalRows}">
                        {{ String(num).padStart(6, 0) }}
                    </div>
                    <div class="p2 col-md-2 border bg-white d-flex align-items-center justify-content-start py-2" :class="{ 'border-bottom-0' : num !== totalRows}">
                        {{ user_list[num - 1] != null ? user_list[num - 1]['氏名'] : '&emsp;' }}
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                        <input v-if="user_list[num - 1] != null && user_list[num - 1].利用者ID != null && (!is('administrator') && isViewLimit)" type="button" class="btn color-resilience-green border-resilience-green bg-individual-information no-print" value="個別情報" disabled>
                        <input v-else-if="user_list[num - 1] != null && user_list[num - 1].利用者ID && (is('administrator') || !isViewLimit)" type="button" class="btn color-resilience-green border-resilience-green bg-individual-information no-print" value="個別情報" @click="showC4(String(num).padStart(6, 0))">
                    </div>
                    <div class="ms-5 p2 col-md-2 border border-end-0 bg-white d-flex align-items-center justify-content-center py-2" style="font-size:12pt" :class="{ 'border-bottom-0' : num !== totalRows }">
                        {{ String(num + totalRows).padStart(6, 0) }}
                    </div>
                    <div class="p2 col-md-2 border bg-white d-flex align-items-center justify-content-start py-2" :class="{ 'border-bottom-0' : num !== totalRows }">
                        {{ user_list[(num + totalRows) - 1] != null ? user_list[(num + totalRows) - 1]['氏名'] : '&emsp;' }}
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                        <input v-if="user_list[(num + totalRows) - 1] != null && user_list[(num + totalRows) - 1].利用者ID && (!is('administrator') && isViewLimit)" type="button" class="btn color-resilience-green border-resilience-green bg-individual-information no-print" value="個別情報" disabled>
                        <input v-else-if="user_list[(num + totalRows) - 1] != null && user_list[(num + totalRows) - 1].利用者ID && (is('administrator') || !isViewLimit)" type="button" class="btn color-resilience-green border-resilience-green bg-individual-information no-print" value="個別情報" @click="showC4(String(num + totalRows).padStart(6, 0))">
                    </div>
                </div>
            </div>
            <div class="pb-5 d-md-none d-print-none">
                <template v-for="(num) in list_frame_number">
                    <div class="col border bg-light-gray px-2">個別リストNo.</div>
                    <div class="col border bg-white px-2">{{ String(num).padStart(6, 0) }}</div>
                    <div class="col border bg-light-gray px-2">{{ callReplaceWord('従業員') }}氏名</div>
                    <div class="col border bg-white px-2">{{ user_list[num - 1] != null ? user_list[num - 1]['氏名'] : '&emsp;' }}</div>
                    <div class="col border bg-white px-2">
                        <input v-if="user_list[num - 1] != null && user_list[num - 1].利用者ID != null && (!is('administrator') && isViewLimit)" type="button" class="btn color-resilience-green border-resilience-green bg-individual-information no-print" value="個別情報" disabled>
                        <input v-else-if="user_list[num - 1] != null && user_list[num - 1].利用者ID && (is('administrator') || !isViewLimit)" type="button" class="btn color-resilience-green border-resilience-green bg-individual-information no-print" value="個別情報" @click="showC4(String(num).padStart(6, 0))">
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);
        const isViewLimit = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/C2';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C4';
            location.href = url;
        }

        const showC4 = (individualListNo) => {
            const url = `/browse/C4/${individualListNo}`;
            location.href = url;
        }

        if (props.bcp_values_info.length) {
            isViewLimit.value = props.bcp_values_info.find(
                (fetchData) => fetchData.column_name === 'view_limit'
            )?.value === '1';
        }

        return {
            setLevel,
            isMargin1,
            isViewLimit,
            callReplaceWord,
            showBack,
            showNext,
            showC4,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
        user_list: {
            type: Array,
        },
        list_frame_number: {
            type: Number,
        }
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-individual-information {
    background-color: #C9FFF1;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
