<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 災害後のキャッシュフローの変化</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-end">
                    <label class="form-label">単位：千円</label>
                </div>
            </div>


            <div class="row ms-1">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td rowspan="8" class="bg-gray-3 text-center align-middle">事<br>業<br>中<br>断</td>
                                <td colspan="2" class="bg-gray-2 text-center align-middle">科目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">1ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">2ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">3ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">4ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">5ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">6ヶ月目</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">（稼働率）</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">営業収入</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="3" class="bg-gray-3 text-center align-middle">営<br>業<br>支<br>出</td>
                                <td colspan="1" class="bg-gray-3 text-center align-middle">変動費</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="bg-gray-3 text-center align-middle">固定費</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="bg-gray-3 text-center align-middle">小計</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">月次事業資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">累積事業資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="bg-gray-3 text-center align-middle">資産の復旧費用</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="bg-gray-3 text-center align-middle">月次資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="bg-gray-3 text-center align-middle">累計資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td rowspan="8" class="bg-gray-3 text-center align-middle">事<br>業<br>中<br>断</td>
                                <td colspan="2" class="bg-gray-2 text-center align-middle">科目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">7ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">8ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">9ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">10ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">11ヶ月目</td>
                                <td colspan="1" class="bg-gray-2 text-center align-middle">12ヶ月目</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">（稼働率）</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">営業収入</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="3" class="bg-gray-3 text-center align-middle">営<br>業<br>支<br>出</td>
                                <td colspan="1" class="bg-gray-3 text-center align-middle">変動費</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="bg-gray-3 text-center align-middle">固定費</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="bg-gray-3 text-center align-middle">小計</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">月次事業資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-gray-3 text-center align-middle">累積事業資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="bg-gray-3 text-center align-middle">資産の復旧費用</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="bg-gray-3 text-center align-middle">月次資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="bg-gray-3 text-center align-middle">累計資金収支</td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                                <td colspan="1" class="bg-info text-center align-middle">
                                    <label class="form-label">&emsp;</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);

        const showBack = () => {
            const url = '/browse/C1a';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C1c';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            showBack,
            showNext,
        }
    },
    props:{},
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

table {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    table {
        border-color: black;
        color: black;
    }
}
</style>
