<template>
    <div class="container">
        <div class="d-flex justify-content-end mt-2">
            <input type="button" class="btn btn-inquiry-button" value="前のページへ戻る" @click="historyBack" />
        </div>
        <div class="relative d-flex flex-column min-h-screen pt-2 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">個人情報保護方針</div>
        </div>
        <div class="row mx-1 mx-md-5 mt-3">
            <div class="text-break">
                一般社団法人岐阜レジリエンス推進協議会（以下、「当会」といいます。）は、国土強靭化地域計画に基づく事業継続の支援及び人材育成事業、ならびに企業間災害協定締結の推進及び災害時連携計画の作成事業に基づいて運営する「企業防災をTOTONO-Lととのエル～持続型BCP策定システム～」Webサイト（以下「本サイト」といいます。）を通じてご提供あるいは直接ご提供いただいた個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令（以下「適用法令」といいます。）を遵守します。
            </div>
            <div class="color-resilience-red mt-3">1.個人情報の取得などの遵守事項</div>
            <div class="mt-3">
                <div>
                    当会による個人情報の取得、利用、提供については、以下の事項を遵守します。
                </div>
                <div class="mt-3">
                    (1)個人情報の取得
                </div>
                <div class="mt-3">
                    <div class="text-break">
                        当会は、本サイトの管理に必要な範囲で、本サイトの利用者（以下「ユーザー」といいます。）から、ユーザーに係る次の個人情報を適法・適正な手段で取得いたします。
                    </div>
                    <div class="ms-3">
                        <div>
                            ①本サイトの利用等に関する情報
                        </div>
                        <div>
                            ②支払のための情報
                        </div>
                        <div>
                            ③情報通信端末に関する情報およびCookie等を利用して取得する情報（ユーザーの利用履歴等を含む）
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    (2)個人情報の利用目的
                </div>
                <div class="mt-3">
                    <div class="text-break">
                        当会は、当会が上記（1）に従って取得した個人情報について、適用法令で定める場合又は本人の同意を得た場合を除き、以下に定める利用目的の達成に必要な範囲を超えて利用することはありません。
                    </div>
                    <div class="ms-3 mt-3">
                        <div>
                            ①本サイトの運営、維持、管理のため
                        </div>
                        <div>
                            ②本サイトを通じた情報提供及び紹介のため
                        </div>
                        <div>
                            ③本サイトの改善及び研究開発のため
                        </div>
                        <div>
                            ④本サイトの品質向上に資する調査のため
                        </div>
                        <div>
                            ⑤詐欺、サイバー攻撃等の不正行為の防止および対応のため
                        </div>
                        <div class="text-break">
                            ※この不正行為等の防止のために、上記「（1）個人情報の取得」（ユーザー利用履歴等）を分析し、当該分析・推定結果を第三者に提供する場合があります。
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    (3)個人情報の提供
                </div>
                <div class="mt-3">
                    <div class="text-break">
                        当会は、適用法令で定める場合を除き、ユーザーの同意に基づき取得した個人情報を、ユーザーの事前の同意なく第三者に提供することはありません。
                    </div>
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                2.個人情報の利用目的の変更
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、前項で特定した利用目的は、予めユーザーの同意を得た場合を除くほかは、原則として変更しません。但し、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲において、予め変更後の利用目的を公表の上で変更を行う場合はこの限りではありません。
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                3.個人データの第三者提供
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、個人データの取扱いの全部又は一部を第三者に委託する場合、その適格性を十分に審査し、その取扱いを委託された個人データの安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                4.保有個人データの開示、訂正、削除
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、ユーザーの求めによる保有個人データの開示、訂正、追加若しくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                5.個人情報の取扱いの改善・見直し
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、適用法令を遵守し、個人情報保護の取組みに関する点検を実施し、継続的に改善・見直しを行います。
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                6.個人情報の廃棄
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                7.個人情報の安全管理
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、個人情報の保護に関して、組織的、物理的、人的、技術的に適切な対策を実施し、当会の取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                8.セキュリティについて
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、ユーザーの個人情報に対する不正アクセスまたは漏えいを防止し、個人情報の正確性を維持し、および必要な場合は適切な破棄を行うために、適用法令を遵守するとともに、高い情報セキュリティ水準での個人情報の取扱いに努めています。当会は、個人情報の取得時または移転時には、通信を暗号化し、また、保管する個人情報 についてアクセス権の制限を設け、業務の遂行に必要な者のみに取扱わせています。加えて、当会は、上記取り組みが実効的に適切になされているか、および適正な水準にあるかを確認するため、定期的に見直しを行います。私たちが講ずる安全管理の措置については、「クラウドとセキュリティについて」もあわせてご確認ください。
                </div>
                <div class="mt-3">
                    <a href="cloud_security">クラウドとセキュリティについて</a>
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                9. Cookie（クッキー）等の使用について
            </div>
            <div class="mt-3">
                <div class="text-break">
                    Cookieとは、Webページを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、ユーザーのコンピュータにファイルとして保存しておく仕組みです。次回、同じページにアクセスすると、Cookieの情報を使って、ページの運営者はユーザーごとに表示を変えたりすることができます。ユーザーがブラウザの設定でCookieの送受信を許可している場合、Webサイトは、ユーザーのブラウザからCookieを取得できます。なお、ユーザーのブラウザは、プライバシー保護のため、そのWebサイトのサーバーが送受信したCookieのみを送信します。「Cookieとアクセスログについて」もあわせてご確認ください。
                </div>
                <div class="mt-3">
                    <a href="cookie">Cookieとアクセスログについて</a>
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                10. 個人情報の取扱いに関する相談や苦情の連絡先
            </div>
            <div class="mt-3">
                <div class="text-break">
                    当会は、個人情報保護に関する相談及び苦情に対応する窓口を設けて、適切に対応するよう努めます。当会の個人情報の取扱いに関する連絡・相談・苦情等は、下記までお問い合わせください。
                </div>
                <div class="">
                    <div>
                        一般社団法人岐阜レジリエンス推進協議会
                    </div>
                    <div>
                        受付時間9：00～17：00（土日祝除く）
                    </div>
                    <div>
                        Mail.　<a href="mailto:info@gifu-resilience.org">info@gifu-resilience.org</a>
                    </div>
                </div>
            </div>
            <div class="color-resilience-red mt-3">
                11. その他の公表事項
            </div>
            <div class="my-3">
                <div class="">
                    <div>
                        一般社団法人岐阜レジリエンス推進協議会
                    </div>
                    <div>
                        〒500-8113　岐阜県岐阜市金園町１丁目３番地の３
                    </div>
                    <div>
                        代表理事　桐山 詔宇
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    setup() {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const historyBack = () =>{
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        };
        return {
            historyBack,
        }
    },
}
</script>