<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="p2 col-md">
                    <label class="form-label">1. BCPの基本方針</label>
                    <label class="ms-3 bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式1</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">目的</label>
                </div>
                <div class="p2 col-md-12 d-flex align-items-center justify-content-start">
                    <div class="form-control d-flex py-2 objective-content">{{ textForms[0].value }}</div>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                    <label class="form-label">基本方針</label>
                </div>
            </div>

            <template v-for="form in valueForms">
                <div v-if="form.isSelected" class="row mt-3 ms-1">
                    <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                        <div class="form-control">{{ form.value || '&emsp;' }}</div>
                    </div>
                </div>
            </template>
            <div v-if="textForms[1].value" class="row mt-3 ms-1">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <div class="form-control">{{ textForms[1].value || '&emsp;' }}</div>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                textForms.forEach(initData => {
                    props.bcp_introduction_texts_info.forEach(fetchData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.value = fetchData.text_value;
                        }
                    });
                })
            }

            if (props.bcp_introduction_values_info.length > 0) {
                valueForms.forEach(initData => {
                    props.bcp_introduction_values_info.forEach(fetchData => {
                        if (initData.columnName == fetchData.column_name) {
                            initData.isSelected = true;
                        }
                    });
                })
            }
        });

        const setLevel = ref('100000');
        const isMargin1 = ref(true);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive([
            {
                columnName: 'N00004',
                value: '',
            },
            {
                columnName: 'N00010',
                value: '',
            },
        ]);

        const valueForms = reactive([
            {
                columnName: 'N00005',
                value: '人命（' + callReplaceWord('従業員') + '・' + callReplaceWord('顧客') + '）の安全を守る',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00006',
                value: callReplaceWord('当社') + 'の' + callReplaceWord('経営') + 'を維持する',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00007',
                value: '供給責任を果たし、' + callReplaceWord('顧客') + 'からの信用を守る',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00008',
                value: callReplaceWord('従業員') + 'の' + callReplaceWord('雇用') + 'を守る',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00009',
                value: '地域経済の活力を守る',
                seq: 1,
                isSelected: false,
            },
        ]);

        const showBack = () => {
            const url = '/browse/N';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/N2';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            textForms,
            valueForms,
            showBack,
            showNext,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.objective-content {
    min-height: 7.5em;
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        border-color: black;
        color: black;
    }
}
</style>
