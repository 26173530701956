<template>
    <div class="container">
        <div class="row mt-3">
            <div class="heading mt-3">お知らせ・更新履歴</div>
        </div>
        <div class="row mt-3">
            <div class="col-md-2">
                <i class="bi bi-record-circle col-form-label"></i><label class="ms-3 form-label">お知らせ訂正</label>
            </div>
            <div class="col-md-10">
                <input type="button" class="btn btn-red px-3" value="一覧から訂正" @click="showNoticeList" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-2">
                <i class="bi bi-record-circle col-form-label"></i><label class="ms-3 form-label">お知らせ登録</label>
            </div>
        </div>
        <form @submit.prevent="createNotice">
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">日付</label>
                <div class="col-md-2">
                    <input type="date" class="form-control" v-model="お知らせ_開始日" required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">件名</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" v-model="お知らせ_件名" required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">内容</label>
                <div class="col-md-10">
                    <textarea class="form-control" v-model="お知らせ_内容" rows=5 required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <div class="col-md-3" v-for="notice in notice_kind" :key="notice.code" :class="{'is-invalid': has_notice_kind_Error}">
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" v-model="お知らせ_通知範囲" :id="notice.code" :value="notice.code" :class="{'is-invalid': has_notice_kind_Error}" />
                        <label :for="notice.code" class="form-check-label">{{ notice.name }}</label>
                    </div>
                </div>
                <div class="invalid-feedback">{{ notice_kind_エラーメッセージ }}</div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">表示終了日</label>
                <div class="col-md-2">
                    <input type="date" class="form-control" v-model="お知らせ_終了日" :class="{'is-invalid': has_notice_to_Error}" />
                    <div class="invalid-feedback">{{ notice_to_エラーメッセージ }}</div>
                </div>
            </div>
            <div class="row mt-3 pb-5 border-bottom-black">
                <div class="col-md-2 offset-md-2">
                    <input type="submit" class="btn btn-black px-3" value="新規登録" />
                </div>
                <div class="flash col-5 alert" :class="this.flashNoticeStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashNotice">
                    {{ flashNoticeMessage }}
                </div>
            </div>
        </form>

        <div class="row mt-3">
            <div class="col-md-2">
                <i class="bi bi-record-circle col-form-label"></i><label class="ms-3 form-label">更新履歴訂正</label>
            </div>
            <div class="col-md-10">
                <input type="button" class="btn btn-red px-3" value="一覧から訂正" @click="showHistoryList" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-2">
                <i class="bi bi-record-circle col-form-label"></i><label class="ms-3 form-label">更新履歴登録</label>
            </div>
        </div>
        <form @submit.prevent="createHistory">
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">日付</label>
                <div class="col-md-2">
                    <input type="date" class="form-control" v-model="更新履歴_登録日" required />
                </div>
            </div>
            <div class="row mt-3 px-3">
                <label class="col-form-label col-md-2">内容</label>
                <div class="col-md-10">
                    <textarea class="form-control" v-model="更新履歴_内容" rows=5 required />
                </div>
            </div>
            <div class="row mt-3 pb-5">
                <div class="col-md-2 offset-md-2">
                    <input type="submit" class="btn btn-black px-3" value="新規登録" />
                </div>
                <div class="flash col-5 alert" :class="this.flashHistoryStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashHistory">
                    {{ flashHistoryMessage }}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const dayjs = inject('dayjs');

        onMounted(() => {
            お知らせ_開始日.value = dayjs().format('YYYY-MM-DD');
            更新履歴_登録日.value = dayjs().format('YYYY-MM-DD');
        });

        const お知らせ_開始日 = ref('');
        const お知らせ_件名 = ref('');
        const お知らせ_内容 = ref('');
        const お知らせ_通知範囲 = ref('');
        const お知らせ_終了日 = ref('');
        const 更新履歴_登録日 = ref('');
        const 更新履歴_内容 = ref('');

        const has_notice_kind_Error = ref(false);
        const notice_kind_エラーメッセージ = ref('');
        const has_notice_to_Error = ref(false);
        const notice_to_エラーメッセージ = ref('');

        const createNotice = () => {
            has_notice_kind_Error.value = false;
            notice_kind_エラーメッセージ.value = ''
            has_notice_to_Error.value = false;
            notice_to_エラーメッセージ.value = ''

            const url = '/api/s-admin/createNotice';

            axios.post(url, {
                notice_kind: お知らせ_通知範囲.value,
                notice_from: お知らせ_開始日.value,
                notice_to: お知らせ_終了日.value,
                title: お知らせ_件名.value,
                detail: お知らせ_内容.value
            }).then(res => {
                showFlashNoticeMethod('正常に登録されました', 'success');

                お知らせ_件名.value = '';
                お知らせ_内容.value = '';
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.notice_kind){
                        has_notice_kind_Error.value = true;
                        notice_kind_エラーメッセージ.value = 'お知らせの範囲を選択してください。';
                    }
                    if(err.response.data.errors.notice_to){
                        has_notice_to_Error.value = true;
                        notice_to_エラーメッセージ.value = err.response.data.errors.notice_to[0];
                    }
                }else{
                    console.log(err.response.data);
                }
            });
        }

        const createHistory = () => {
            const url = '/api/s-admin/createHistory';

            axios.post(url, {
                notice_date: 更新履歴_登録日.value,
                detail: 更新履歴_内容.value
            }).then(res => {
                showFlashHistoryMethod('正常に登録されました', 'success');

                更新履歴_内容.value = '';
            }).catch(err => {
                console.log(err.response.data);
            });
        }

        const flashNoticeMessage = ref('');
        const showFlashNotice = ref(false);
        const flashNoticeStyle = ref('');

        const showFlashNoticeMethod = (msg, kind) => {
            showFlashNotice.value = true;
            flashNoticeMessage.value = msg;
            flashNoticeStyle.value = kind;
            setTimeout(() => {
                showFlashNotice.value = false
            }, 3000)
        }

        const flashHistoryMessage = ref('');
        const showFlashHistory = ref(false);
        const flashHistoryStyle = ref('');

        const showFlashHistoryMethod = (msg, kind) => {
            showFlashHistory.value = true;
            flashHistoryMessage.value = msg;
            flashHistoryStyle.value = kind;
            setTimeout(() => {
                showFlashHistory.value = false
            }, 3000)
        }

        const showNoticeList = () => {
            const url = '/s-admin/showNoticeList';

            location.href = url;
        }

        const showHistoryList = () => {
            const url = '/s-admin/showHistoryList';

            location.href = url;
        }

        return {
            お知らせ_開始日,
            お知らせ_件名,
            お知らせ_内容,
            お知らせ_通知範囲,
            お知らせ_終了日,
            更新履歴_登録日,
            更新履歴_内容,

            has_notice_kind_Error,
            notice_kind_エラーメッセージ,
            has_notice_to_Error,
            notice_to_エラーメッセージ,

            createNotice,
            createHistory,

            flashNoticeMessage,
            showFlashNotice,
            flashNoticeStyle,

            showFlashNoticeMethod,

            flashHistoryMessage,
            showFlashHistory,
            flashHistoryStyle,

            showFlashHistoryMethod,
            
            showNoticeList,
            showHistoryList,
        }
    },
    props: {
        notice_kind: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-middle{
        vertical-align: middle;
    }

    .t-center {
        text-align: center;
    }


</style>