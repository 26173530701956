<template>
    <div class="container">
        <div class="d-flex justify-content-end mt-2">
            <input type="button" class="btn btn-inquiry-button" value="前のページへ戻る" @click="historyBack" />
        </div>
        <div class="relative d-flex flex-column min-h-screen pt-2 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">Cookieとアクセスログについて</div>
        </div>
        <div class="row mx-1 mx-md-5 mt-3">
            <div>
                <div class="fw-bold">cookieについて</div>
                <div class="text-break">当サイトは、お客さまにより適したサービスを提供するため、「Cookie」（クッキー）を利用しています。「Cookie」とは、お客さまが当サイトをご覧になったという情報を、そのお客さまのコンピューター（またはスマートフォンやタブレットなどのインターネット接続可能な機器）内に記憶させる機能のことです。「Cookie」には個人が特定できる情報は残されません。「Cookie」を利用することによりWebサイトへの訪問回数や訪問したページなどの情報を取得することができます。なお、当会は、お客さまへのサービス提供と利用状況分析に限定して「Cookie」を使用するものとし、それ以外の目的で利用することはございません。</div>
            </div>
            <div class="my-3">
                <div class="fw-bold">アクセスログについて</div>
                <div class="text-break">当サイトは、アクセスされたお客さまのコンピュータの情報をアクセスログとして記録しています。アクセスログには、お客さまがアクセスの際に利用されているドメイン名やIPアドレス、使用しているOSおよびブラウザの種類、アクセス日時等が含まれますが、個人を特定できる情報を含むものではありません。これらの情報は統計処理を施して、当サイトの管理や利用状況に関する分析のために利用いたします。なお、お客さまなどの情報と合わせて識別可能な個人情報となった段階では、当会の個人情報保護方針に基づいて厳正に管理いたします。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const historyBack = () =>{
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        };
        return {
            historyBack,
        }
    },
}
</script>