<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            BCP更新記録の閲覧
        </div>
    </div>
    <levelIcon
        :set_level="setLevel"
    />
    <div class="table-responsive-md d-flex align-items-top justify-content-start">
        <table class="ms-3 mt-3 table table-bordered border-black">
            <thead>
                <tr>
                    <th>BCPを見直す基準（入門コース策定／自動表示）</th>
                </tr>
            </thead>
            <tbody v-for="(textForm, index) in textForms" :key="index">
                <tr>
                    <td>
                        <div class="objective-content">{{ textForm.value }}&emsp;</div></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive-md d-flex align-items-top justify-content-start">
        <table class="ms-3 mt-3 table-bordered border-black">
            <thead>
                <tr>
                    <th><div class="mx-2 py-2">BCPの更新時期</div></th>
                    <th><div class="mx-2 py-2">更新回数（年）</div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class=" mx-2 d-flex align-items-top justify-content-between py-2">
                            <div>毎年</div>
                            <div>{{ valueForms.K00010.value }}</div>
                            <div>月</div>
                        </div>
                    </td>
                    <td>
                        <div class=" mx-2 d-flex align-items-top justify-content-around py-2">
                            <div>{{ valueForms.K00011.value }}</div>
                            <div>回</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive-md d-flex align-items-top justify-content-start">
        <table class="ms-3 mt-5 table table-bordered border-black">
            <thead>
                <tr>
                    <th colspan="1" scope="col"><div class="">BCPの更新日</div></th>
                    <th colspan="2" scope="col"><div class="">更新内容</div></th>
                </tr>
            </thead>
            <tbody v-for="(version, versionIndex) in versionList" :key="versionIndex">
                <template v-for="(val, index) in versionList[versionIndex]" :key="index">
                    <tr>
                        <td v-if="index === 0" :rowspan="version.length">
                            <div class="d-flex align-items-top">
                                <div>{{ val.作成日 }}</div>
                            </div>
                        </td>
                        <td class="col-md-1">
                            <div class="d-flex align-items-top justify-content-center">
                                <div> {{ versionIndex == 1 ? "作成" : updateType.find((type) => type.id === val.update_type)?.name }} </div>
                            </div>
                        </td>
                        <td class="col-md-10">
                            <div class="d-flex align-items-top">
                                <div>{{ val.update_details }}</div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <div class="mt-5 ms-3">
        <input type="button" class="btn btn-new-window-button btn-sm" value="診断改善HOMEへ戻る" @click="showHome" />
    </div>
    <div class="mt-3 ms-3">
        ※ 入門コースの書き換えは行われませんのでご注意ください。
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (props.bcp_introduction_texts_info.length > 0) {
                Object.keys(textForms).forEach(columnName => {
                    textForms[columnName].value = props.bcp_introduction_texts_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.text_value;
                });
            }

            if (props.bcp_values_info.length > 0) {
                Object.keys(valueForms).forEach(columnName => {
                    valueForms[columnName].value = props.bcp_values_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.value;
                });
            }

            if (props.bcp_update_records.length > 0) {
                // 更新日ごとにグルーピングする
                const groupByUpdate = function(xs, key) {
                    return xs.reduce(function(rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                versionList.value = groupByUpdate(props.bcp_update_records, 'version');
            }
        });

        const setLevel = ref('111100');
        const updateType = [
            {
                id:"1",
                name: "追加"
            },
            {
                id:"2",
                name: "削除"
            },
            {
                id:"3",
                name: "修正"
            },
        ];

        const textForms = reactive({
            N00061: {
                value: '',
            },
            N00062: {
                value: '',
            },
            N00063: {
                value: '',
            },
        });

        const valueForms = reactive({
            K00010: {
                value: '',
            },
            K00011: {
                value: '',
            },
        });

        const versionList = ref([]);

        const showHome = () => {
            const url = '/pdca/home';
            location.href = url;
        }

        return {
            setLevel,
            textForms,
            valueForms,
            versionList,
            updateType,
            showHome,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_update_records: {
            type: Array,
        },
        bcp_introduction_texts_info: {
            type: Array,
        },
        bcp_values_info: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.no-display {
    display: none;
}

.border-black {
    border-color: #808080;
}

table thead tr th {
    background-color: #d9d9d9;
}

table tbody tr td {
    background-color: #f2f2f2;
}

.objective-content {
    min-height: 3.5em;
}
</style>
