<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜発動フローに沿った実施項目
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-gray border-black">
                    <div class="card-body">
                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12 bg-secondary">
                                <label for="" class="form-label d-flex align-items-center text-white">初動対応</label>
                            </div>
                        </div>

<!--------------------------------------◯　二次災害の防止措置---------------------------------------------------------------->

                        <div id="first10-1" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　二次災害の防止措置</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">事業所からの退避</td>
                                        <td class="bg-white" colspan="">事業所に留まっていると危険な場合、お客さまや{{ callReplaceWord('従業員') }}を事業所の外の安全な場所に退避させる。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜避難計画シート へ" @click="showEvacuationPlan">
                                                <levelIcon
                                                    :set_level="setLevel_1_1"
                                                />
                                            </div>
                                            <div class="mt-3 ms-5 d-flex bg-danger text-white align-items-center justify-content-center flex-row">
                                                災害ごとに策定します
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">退避が必要な状況としては、津波の来襲、洪水、土砂災害、火災、有毒ガスの漏洩など。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">応急手当や初期消火</td>
                                        <td class="bg-white" colspan="">負傷者の救出や応急手当を行う。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜災害対応用具チェックリスト へ" @click="showItemCheckList">
                                                <levelIcon
                                                    :set_level="setLevel_1_2"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">火災が発生した場合は初期消火を行う。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="3" colspan="">警察・消防への通報</td>
                                        <td class="bg-white" colspan="">事件性がある場合は110番通報する。</td>
                                        <td class="bg-white" rowspan="3" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜主要組織の連絡先 へ" @click="showContactAddress">
                                                <levelIcon
                                                    :set_level="setLevel_1_3"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">火災発生時や救急車出動要請の場合は119番通報する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">その他、法律や協定で決められた機関がある場合は、そこに通報する。</td>
                                    </tr>


                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">重要書類の保護</td>
                                        <td class="bg-white" colspan="">重要書類が損傷するおそれのある場合、事業所内の安全な場所に移動するか、事業所外へ持ち出す。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-document" value="資料｜重要書類等" @click="showXxxx">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">重要書類が損傷した場合、予め別の場所に保管していた書類のコピーで然るべき処置を行う。</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
<!--------------------------------------◯　@@従業員@@の参集---------------------------------------------------------------->
                        <div id="first10-2" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　{{ callReplaceWord('従業員') }}の参集</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">{{ callReplaceWord('経営者') }}の対応</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('組織') }}外にいる場合、直ちに{{ callReplaceWord('出社') }}する。</td>
                                        <td class="bg-white" rowspan="4" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-gray" value="安否確認｜新規登録 へ" @click="showEducationPlanList">
                                                <levelIcon
                                                    :set_level="setLevel_2"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('出社') }}までの間、電話等で{{ callReplaceWord('従業員') }}に指示を出す。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">{{ callReplaceWord('従業員') }}の参集</td>
                                        <td class="bg-white" colspan="">就業時間外に緊急事態が発生した場合、{{ callReplaceWord('従業員') }}を招集する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">地震や風水害では{{ callReplaceWord('従業員') }}が自主的に参集する基準を事前に設けておく。</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
<!--------------------------------------◯　安否・被災状況の把握---------------------------------------------------------------->
                        <div id="first10-3" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　安否・被災状況の把握</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="3" colspan="">{{ callReplaceWord('顧客') }}、{{ callReplaceWord('従業員') }}とその家族の安否</td>
                                        <td class="bg-white" colspan="">来所中の{{ callReplaceWord('顧客') }}に負傷がないか確認する。</td>
                                        <td class="bg-white" rowspan="3" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜従業員連絡先リスト［基本情報整理用］ へ" @click="showBasicEmployeeList">
                                                <levelIcon
                                                    :set_level="setLevel_3_1_1"
                                                />
                                                <input type="button" class="btn btn-sm btn-bcp-middle" v-if="is_display_middle" value="中級｜従業員連絡先リスト［従業員一覧］ へ" @click="showMiddleEmployeeList">
                                                <levelIcon
                                                    :set_level="setLevel_3_1_2"
                                                    v-if="is_display_middle"
                                                />
                                                <input type="button" class="mt-3 btn btn-sm btn-gray" value="安否確認｜新規登録 へ" @click="showEducationPlanList">
                                                <levelIcon
                                                    :set_level="setLevel_3_1_3"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('従業員') }}とその家族に負傷がないか、住家の損傷がないかを確認する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('従業員') }}と電話がつながらない場合、近所の{{ callReplaceWord('従業員') }}等に様子を見に行かせる。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="4" colspan="">建屋、{{ callReplaceWord('生産') }}機械、通信機器</td>
                                        <td class="bg-white" colspan="">事業所内への立入りが危険でなくなってから実施。</td>
                                        <td class="bg-white" rowspan="4" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" :value="'基本｜中核' + callReplaceWord('事業') + 'に係るボトルネック資源 へ'" @click="showBasicMaterial">
                                                <levelIcon
                                                    :set_level="setLevel_3_2_1"
                                                />
                                            </div>
                                            <div class="mt-3 d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" :value="'上級｜中核' + callReplaceWord('事業') + 'に係るボトルネック資源 へ'" @click="showAdvancedMaterial">
                                                <levelIcon
                                                    :set_level="setLevel_3_3_1"
                                                    v-if="is_display_advanced"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">建屋の損傷状況を調べる。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('生産') }}機械の損傷状況を調べる。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">一般電話、携帯電話、FAX、インターネット等の通信機器が使えるかどうかを調べる。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">情報システム</td>
                                        <td class="bg-white" colspan="">事業所内への立入りが危険でなくなってから実施。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" :value="'上級｜中核' + callReplaceWord('事業') + 'に係るボトルネック資源 へ'" @click="showAdvancedComputer">
                                                <levelIcon
                                                    :set_level="setLevel_3_3_1"
                                                    v-if="is_display_advanced"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">パソコン、ソフトウェアが使えるかどうかを調べる。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">地域住民や近隣事業所</td>
                                        <td class="bg-white" colspan="">延焼火災や有毒ガス漏洩等、直ちに避難が必要な状況でないかどうかを調べる。</td>
                                        <td class="bg-white" rowspan="6" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜主要組織の連絡先 へ" @click="showContactAddress">
                                                <levelIcon
                                                    :set_level="setLevel_3_4_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">初期消火や下敷き者の救出等、地域貢献活動が必要な状況かどうかを把握する。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="4" colspan="">自然災害、交通やライフライン</td>
                                        <td class="bg-white" colspan="">大雨の場合、河川増水の状況や土砂災害の兆候に注意する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">交通機関の混乱状況を調べる。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">電気、ガス、上下水道の停止状況を調べる。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">それらの情報源としては、ラジオ、インターネット、テレビ、電話問合せなどがある。</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
<!--------------------------------------@@事業@@継続のための緊急対策---------------------------------------------------------------->
                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="col-md-12 bg-secondary">
                                <label for="" class="form-label d-flex align-items-center text-white">{{ callReplaceWord('事業') }}継続のための緊急対策</label>
                            </div>
                        </div>

<!--------------------------------------◯　@@顧客@@・@@協力会社@@への連絡---------------------------------------------------------------->

                        <div id="first10-4" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}への連絡</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">連絡手段の確保</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}との連絡手段を確保する。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" value="上級｜情報通信手段の情報 へ" @click="showCommunicationMeans">
                                                <levelIcon
                                                    :set_level="setLevel_4_1_1"
                                                    v-if="is_display_advanced"
                                                />
                                            </div>
                                            <div class="mt-3 d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜電話／FAX番号シート（自社用） へ" @click="showTel">
                                                <levelIcon
                                                    :set_level="setLevel_4_1_2"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">電話、メールのほか、{{ callReplaceWord('従業員') }}による自転車往来を含めて、あらゆる手段を検討する。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">{{ callReplaceWord('顧客') }}への被災状況報告</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('顧客') }}に対して、事業所の被災状況、今後の{{ callReplaceWord('納入') }}等の目処、確実な連絡手段、次回の連絡時期を報告する。</td>
                                        <td class="bg-white" rowspan="1" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜主要顧客情報 へ" @click="showMainCustomer">
                                                <levelIcon
                                                    :set_level="setLevel_4_2_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">{{ callReplaceWord('協力会社') }}の被災状況把握</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('協力会社') }}に対して、事業所の被災状況、今後の{{ callReplaceWord('納入') }}の目処、確実な連絡手段、次回の連絡時期について報告を求める。</td>
                                        <td class="bg-white" rowspan="1" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜主要供給者・業者情報［供給品目別］ へ" @click="showSupplies3">
                                                <levelIcon
                                                    :set_level="setLevel_4_3_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
<!--------------------------------------◯　中核{{ callReplaceWord('事業') }}の継続方針立案・体制確立---------------------------------------------------------------->

                        <div id="first10-5" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　中核{{ callReplaceWord('事業') }}の継続方針立案・体制確立</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">中核{{ callReplaceWord('事業') }}のダメージ判断</td>
                                        <td class="bg-white" colspan="">中核{{ callReplaceWord('事業') }}について、ボトルネックとなる事業資源の被災状況等から、中核{{ callReplaceWord('事業') }}が被ったダメージの大きさを把握する。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" :value="'上級｜中核' + callReplaceWord('事業') + '影響度評価フォーム へ'" @click="showEvaluationForm">
                                                <levelIcon
                                                    :set_level="setLevel_5_1_1"
                                                    v-if="is_display_advanced"
                                                />
                                            </div>
                                            <div class="mt-3 ms-5 d-flex bg-danger text-white align-items-center justify-content-center flex-row" v-if="is_display_advanced">
                                                災害ごとに策定します
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">ボトルネックとなる{{ callReplaceWord('事業') }}資源としては、{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}、{{ callReplaceWord('従業員') }}、建屋や{{ callReplaceWord('生産') }}機械、情報システム、ライフライン、交通機関などがあげられる。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="3" colspan="">目標復旧時間設定</td>
                                        <td class="bg-white" colspan="">予め検討していた「目標復旧時間の目処」を元に、現在の被災状況、今後の事態進展の予測を考慮して設定する。</td>
                                        <td class="bg-white" rowspan="4" colspan="">
                                            &emsp;
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('顧客') }}の納得が得られるか、復旧後に{{ callReplaceWord('経営') }}が成り立つか、現実的かどうかを総合的に考えて設定する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">下記の「財務の予測診断」の結果も考慮する。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">応急・復旧対策方針の決定</td>
                                        <td class="bg-white" colspan="">
                                            事業資源の損害が大きい場合、次のどの方針で目標復旧時間内に中核{{ callReplaceWord('事業') }}の復旧を目指すかを決定します（組み合わせもある）。<br>
                                            ①現在の事業所を復旧させて操業<br>
                                            ②代替場所に{{ callReplaceWord('生産') }}機械等を移動して操業<br>
                                            ③他の{{ callReplaceWord('組織') }}等に{{ callReplaceWord('生産') }}を一時移管する
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">財務の予測診断</td>
                                        <td class="bg-white" colspan="">財務診断モデルを用いて、復旧費用、今後のキャッシュフロー、不足資金を予測する。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜財務診断と事前対策計画 へ" @click="showFund">
                                                <levelIcon
                                                    :set_level="setLevel_5_2_1"
                                                />
                                                <input type="button" class="btn btn-sm btn-bcp-middle" v-if="is_display_middle" value="中級｜" @click="showFinance">
                                                <levelIcon
                                                    :set_level="setLevel_5_2_2"
                                                    v-if="is_display_middle"
                                                />
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" value="上級｜" @click="showFinance">
                                                <levelIcon
                                                    :set_level="setLevel_5_2_3"
                                                    v-if="is_display_advanced"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">予測結果は融資申請の際にも役立てる。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">実施体制の確立</td>
                                        <td class="bg-white" colspan="">指揮命令系統と役割分担を{{ callReplaceWord('従業員') }}に明示する。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜BCPの策定・運用体制 へ" @click="showOperationStructureOfBasic">
                                                <levelIcon
                                                    :set_level="setLevel_5_3_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">必要なら、会社OB、協同組合、{{ callReplaceWord('取引先') }}等から要員応援を仰ぐ（あるいは応援を出す）。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">拠点場所の確保</td>
                                        <td class="bg-white" colspan="">事業所が損傷した場合、{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}と連絡が取れ、{{ callReplaceWord('従業員') }}を指揮できる拠点場所を確保する（自宅やプレハブ、自動車でも良い）。</td>
                                        <td class="bg-white" rowspan="1" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" :value="'基本｜' + callReplaceWord('事業') + '継続に係る各種資源の代替の情報 へ'" @click="showSubstituteInformation">
                                                <levelIcon
                                                    :set_level="setLevel_5_4_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

<!--------------------------------------@@事業@@継続のための応急・復旧対策---------------------------------------------------------------->
                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="col-md-12 bg-secondary">
                                <label for="" class="form-label d-flex align-items-center text-white">{{ callReplaceWord('事業') }}継続のための応急・復旧対策</label>
                            </div>
                        </div>

<!--------------------------------------◯　@@顧客@@・@@協力会社@@向け対策---------------------------------------------------------------->

                        <div id="first10-6" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}向け対策</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="4" colspan="">取引調整（他の{{ callReplaceWord('組織') }}等への一時移管を含む）</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('顧客') }}に対して今後の{{ callReplaceWord('納入') }}等の計画を説明し了解を得る。</td>
                                        <td class="bg-white" rowspan="4" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜主要顧客情報 へ" @click="showMainCustomer">
                                                <levelIcon
                                                    :set_level="setLevel_6_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">必要に応じて、他の{{ callReplaceWord('組織') }}での一時的な代替{{ callReplaceWord('生産') }}等を調整する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('協力会社') }}に対して今後の{{ callReplaceWord('納入') }}等の計画の説明を求め、必要に応じて、他の{{ callReplaceWord('組織') }}での一時的な代替生産等を調整する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('顧客') }}や{{ callReplaceWord('協力会社') }}との取引ルールとして、他の{{ callReplaceWord('組織') }}での代替{{ callReplaceWord('生産') }}は一時的なものであり、復旧後は{{ callReplaceWord('発注') }}を戻すことを原則とする。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">{{ callReplaceWord('取引') }}復元</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('自社') }}の{{ callReplaceWord('事業') }}資源が復旧した時点で、代替{{ callReplaceWord('生産') }}を引き上げ、{{ callReplaceWord('顧客') }}に被災前の{{ callReplaceWord('取引') }}に復元してもらう（上記の調整結果どおり）。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜主要供給者・業者情報［供給品目別］ へ" @click="showSupplies3">
                                                <levelIcon
                                                    :set_level="setLevel_6_2"
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('協力会社') }}の{{ callReplaceWord('事業') }}資源が復旧した時点で、代替{{ callReplaceWord('生産') }}を引き上げ、被災前の{{ callReplaceWord('取引') }}に復元する（上記の調整結果どおり）。</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
<!--------------------------------------◯　@@従業員@@・@@事業@@資源対策---------------------------------------------------------------->

                        <div id="first10-7" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　{{ callReplaceWord('従業員') }}・{{ callReplaceWord('事業') }}資源対策</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="4" colspan="">{{ callReplaceWord('従業員') }}との情報共有と生活支援</td>
                                        <td class="bg-white" colspan="">全{{ callReplaceWord('従業員') }}に対して{{ callReplaceWord('事業') }}継続方針を説明し、適宜、その進捗状況を示す。</td>
                                        <td class="bg-white" rowspan="4" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" value="基本｜災害対応用具チェックリスト へ" @click="showItemCheckList">
                                                <levelIcon
                                                    :set_level="setLevel_7_1"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('従業員') }}の食事や日用品等を確保する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('従業員') }}の本人や家族が死傷した場合、できる限りの配慮を行う。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">住家が被災した{{ callReplaceWord('従業員') }}に対して、可能であれば仮住居を提供する。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">建屋の修理・一時移転</td>
                                        <td class="bg-white" colspan="">建屋が損傷した場合、その修理を建設会社等に要請する（目標復旧時間に間に合うスケジュールで）。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" :value="'基本｜' + callReplaceWord('事業') + '継続に係る各種資源の代替の情報 へ'" @click="showSubstituteInformation">
                                                <levelIcon
                                                    :set_level="setLevel_7_2"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">建屋の早期復旧が困難な場合は、他の場所に移転する。</td>
                                    </tr>


                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">{{ callReplaceWord('生産') }}機械の修理・調達</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('生産') }}機械の修理・調達を専門メーカー等に要請する。</td>
                                        <td class="bg-white" rowspan="1" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" :value="'基本｜中核' + callReplaceWord('事業') + 'に係るボトルネック資源 へ'" @click="showBasicMaterial">
                                                <levelIcon
                                                    :set_level="setLevel_7_3"
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">情報システムの回復</td>
                                        <td class="bg-white" colspan="">パソコン等ハードウェアの修理・調達を専門メーカー等に要請する。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" :value="'上級｜中核' + callReplaceWord('事業') + 'に係るボトルネック資源 へ'" @click="showAdvancedComputer">
                                                <levelIcon
                                                    :set_level="setLevel_7_4"
                                                    v-if="is_display_advanced"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">ソフトウェアの破損は、予めバックアップしていたデータを用いて回復させる。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">供給品の調達</td>
                                        <td class="bg-white" colspan="">通常のルートからの調達が困難な場合、予め定めた代替ルート（業者や搬送方法）により調達する。</td>
                                        <td class="bg-white" rowspan="1" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-basic" :value="'基本｜中核' + callReplaceWord('事業') + 'に必要な供給品目情報 へ'" @click="showSupplies">
                                                <levelIcon
                                                    :set_level="setLevel_7_5"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

<!--------------------------------------◯　財務対策---------------------------------------------------------------->
                        <div id="first10-8" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　財務対策</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="3" colspan="">運転資金の確保</td>
                                        <td class="bg-white" colspan="">緊急時発生後1ヶ月間、当面必要な運転資金を確保する。</td>
                                        <td class="bg-white" rowspan="3" colspan="">
                                            &emsp;
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">銀行預金（積立金）を引き出す。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">必要に応じて、地方自治体等の制度による緊急貸付を受ける。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">決済不渡り対策</td>
                                        <td class="bg-white" colspan="">発行済みの手形が不渡りにならないよう、取引銀行等と調整する。</td>
                                        <td class="bg-white" rowspan="1" colspan="">
                                            &emsp;
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="2" colspan="">仕入支払い・給与支払い</td>
                                        <td class="bg-white" colspan="">できる限り、{{ callReplaceWord('協力会社') }}や{{ callReplaceWord('納入') }}業者等に対して過日分の支払いを行う。</td>
                                        <td class="bg-white" rowspan="2" colspan="">
                                            <div class="d-flex flex-column">
                                                &emsp;
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">できる限り、{{ callReplaceWord('従業員') }}に対して給料を支払う。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="4" colspan="">復旧資金の確保</td>
                                        <td class="bg-white" colspan="">財務診断結果から、建物や生産機械の修理費用等、復旧に必要な費用を見積もる。</td>
                                        <td class="bg-white" rowspan="4" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-middle" v-if="is_display_middle" value="中級｜保険情報リスト へ" @click="showInsuranceList">
                                                <levelIcon
                                                    :set_level="setLevel_8"
                                                    v-if="is_display_middle"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">損害保険や共済の支払いを受ける。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">証券等の資産を売却する。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">必要に応じて、政府系金融機関等から災害復旧貸付を受ける。</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
<!--------------------------------------◯　地域貢献活動---------------------------------------------------------------->

                        <div id="first10-9" class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-12">
                                <label for="" class="form-label d-flex align-items-center">◯　地域貢献活動</label>
                            </div>
                        </div>

                        <div class="table-responsive-md">
                            <table class="ms-1 mt-3 table table-bordered border-dark">
                                <tbody>
                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">被災者の救出・応急救護・初期消火</td>
                                        <td class="bg-white" colspan="">{{ callReplaceWord('組織') }}の近所で被災者や火災が発生した場合に協力する。</td>
                                        <td class="bg-white" rowspan="5" colspan="">
                                            <div class="d-flex flex-column">
                                                <input type="button" class="btn btn-sm btn-bcp-advanced" v-if="is_display_advanced" value="上級｜地域貢献活動 へ" @click="showCommunityContribution">
                                                <levelIcon
                                                    :set_level="setLevel_9"
                                                    v-if="is_display_advanced"/>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="1" colspan="">{{ callReplaceWord('商品') }}等の提供</td>
                                        <td class="bg-white" colspan="">食料品や日用品の小売業の場合、在庫商品を避難所に無償提供する案もある。</td>
                                    </tr>

                                    <tr>
                                        <td class="bg-white" rowspan="3" colspan="">ボランティア活動</td>
                                        <td class="bg-white" colspan="">損傷した住家の後片付け、救援物資の仕分け等のボランティア活動がある。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">{{ callReplaceWord('従業員') }}の自主的なボランティア参加を支援する（ボランティア保険の負担等を含め）。</td>
                                    </tr>
                                    <tr>
                                    <td class="bg-white" colspan="">必要に応じて、{{ callReplaceWord('従業員') }}に業務としてボランティア活動に参加させることも検討する。</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-12 align-items-center justify-content-start">
            <input type="button" class="ms-3 btn btn-bcp-blue" value="目次構成 へ戻る" style="font-size:10.5pt" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            // window.location.reload();
        }

        // ターゲット指定の制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 500);

        const setLevel = ref('011100');

        const setLevel_1_1 = ref('011100');
        const setLevel_1_2 = ref('011100');
        const setLevel_1_3 = ref('011100');

        const setLevel_2 = ref('111111');

        const setLevel_3_1_1 = ref('001100');
        const setLevel_3_1_2 = ref('001100');
        const setLevel_3_1_3 = ref('111111');
        const setLevel_3_2_1 = ref('011100');
        const setLevel_3_3_1 = ref('000100');
        const setLevel_3_4_1 = ref('011100');

        const setLevel_4_1_1 = ref('000100');
        const setLevel_4_1_2 = ref('011100');
        const setLevel_4_2_1 = ref('011100');
        const setLevel_4_3_1 = ref('011100');

        const setLevel_5_1_1 = ref('000100');
        const setLevel_5_2_1 = ref('010000');
        const setLevel_5_2_2 = ref('001000');
        const setLevel_5_2_3 = ref('000100');
        const setLevel_5_3_1 = ref('011100');
        const setLevel_5_4_1 = ref('011100');

        const setLevel_6_1 = ref('011100');
        const setLevel_6_2 = ref('011100');

        const setLevel_7_1 = ref('011100');
        const setLevel_7_2 = ref('011100');
        const setLevel_7_3 = ref('011100');
        const setLevel_7_4 = ref('000100');
        const setLevel_7_5 = ref('011100');

        const setLevel_8 = ref('001100');

        const setLevel_9 = ref('000100');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showEvacuationPlan = () => {
            const url = '/bcp/bcp_second/1';
            location.href = url;
        }

        const showContactAddress = () => {
            const url = '/bcp/bcp_second/2';
            location.href = url;
        }

        const showBasicEmployeeList = () => {
            const url = '/bcp/bcp_second/3';
            location.href = url;
        }

        const showMiddleEmployeeList = () => {
            const url = '/bcp/bcp_second/4';
            location.href = url;
        }

        const showCommunicationMeans = () => {
            const url = '/bcp/bcp_second/5';
            location.href = url;
        }

        const showTel = () => {
            const url = '/bcp/bcp_second/6';
            location.href = url;
        }

        const showMainCustomer = () => {
            const url = '/bcp/bcp_second/7';
            location.href = url;
        }

        const showBasicMaterial = () => {
            const url = '/bcp/bcp_second/8';
            location.href = url;
        }

        const showAdvancedMaterial = () => {
            const url = '/bcp/bcp_second/10';
            location.href = url;
        }

        const showAdvancedComputer = () => {
            const url = '/bcp/bcp_second/9';
            location.href = url;
        }

        const showSupplies = () => {
            const url = '/bcp/bcp_second/11';
            location.href = url;
        }

        const showSupplies3 = () => {
            const url = '/bcp/bcp_second/11#detail';
            location.href = url;
        }

        const showItemCheckList = () => {
            const url = '/bcp/bcp_second/12';
            location.href = url;
        }

        const showCommunityContribution = () => {
            const url = '/bcp/bcp_second/13';
            location.href = url;
        }

        const showEvaluationForm = () => {
            const url = '/bcp/bcp_first/form';
            location.href = url;
        }

        const showOperationStructureOfBasic = () => {
            const url = '/bcp/bcp_first/2';
            location.href = url;
        }

        const showSubstituteInformation = () => {
            const url = '/bcp/bcp_first/4';
            location.href = url;
        }

        const showFund = () => {
            const url = '/bcp/bcp_first/6';
            location.href = url;
        }

        // TODO: 変更となる可能性あり
        const showFinance = () => {
            const url = '/bcp/bcp_financial/home';

            location.href = url;
        }

        // TODO: 変更となる可能性あり
        const showInsuranceList = () => {
            const url = '/bcp/bcp_financial/18';
            location.href = url;
        }

        // TODO: 確定次第追加
        const showXxxx = () => {
            const url = '';
            location.href = url;
        }

        const showEducationPlanList = () => {
            const url = '/fixation/k2';
            location.href = url;
        }

        const showBack = async () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showContents = async () => {
            // 目次ページへ遷移
            const url = '/bcp/bcp_introduction/目';
            location.href = url;
        }

        return {
            setLevel,
            setLevel_1_1,
            setLevel_1_2,
            setLevel_1_3,
            setLevel_2,
            setLevel_3_1_1,
            setLevel_3_1_2,
            setLevel_3_1_3,
            setLevel_3_2_1,
            setLevel_3_3_1,
            setLevel_3_4_1,
            setLevel_4_1_1,
            setLevel_4_1_2,
            setLevel_4_2_1,
            setLevel_4_3_1,
            setLevel_5_1_1,
            setLevel_5_2_1,
            setLevel_5_2_2,
            setLevel_5_2_3,
            setLevel_5_3_1,
            setLevel_5_4_1,
            setLevel_6_1,
            setLevel_6_2,
            setLevel_7_1,
            setLevel_7_2,
            setLevel_7_3,
            setLevel_7_4,
            setLevel_7_5,
            setLevel_8,
            setLevel_9,

            callReplaceWord,

            showEvacuationPlan,
            showContactAddress,
            showBasicEmployeeList,
            showMiddleEmployeeList,
            showCommunicationMeans,
            showTel,
            showMainCustomer,
            showBasicMaterial,
            showAdvancedMaterial,
            showAdvancedComputer,
            showSupplies,
            showSupplies3,
            showItemCheckList,
            showCommunityContribution,
            showEvaluationForm,
            showOperationStructureOfBasic,
            showSubstituteInformation,
            showFund,
            showInsuranceList,
            showFinance,
            showEducationPlanList,
            showContents,
            showBack,
            showXxxx,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_display_middle: {
            type: Boolean,
        },
        is_display_advanced: {
            type: Boolean,
        },
    }

}
</script>

<style lang="scss" scoped>

.btn-text-align {
    text-align: left;
}
.btn-gray {
    border: 1px solid;
    color: white;
    border-color: #808080;
    background-color: #808080;
}

.btn-gray:hover {
    border: 1px solid;
    color: #808080;
    border-color: #808080;
    background-color: white;
}

.btn-document {
    border: 1px solid;
    color: black;
    border-color: #33cccc;
    background-color: #33cccc;
}

.btn-document:hover {
    border: 1px solid;
    color: black;
    border-color: #33cccc;
    background-color: white;
}


.bg-blue {
    background-color: #4472c4;
}

.bg-disaster {
    background-color: #c00000;
    color: white;
}

.bg-gray {
    background-color: #a6a6a6;
}

.border-orange{
    border-style: solid;
    border-width: 1px;
    border-color: #FF6600;
}

.bg-header {
    background-color: #F2F2F2;

}

table tr td:nth-child(1){
    width: 20%;
}

table tr td:nth-child(2){
    width: 45%;
}

table tr td:nth-child(3){
    width: 35%;

}

</style>
