<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            入門コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="returnHome" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="is_margin_1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row title headline">
                <label class="form-label ps-md-3 col-3">目次構成</label>
            </div>
            <div class="row mt-3">
                <div class="ps-md-3 col-12 col-md-5">
                    <div class="row">
                        <div class="col-8">
                            <label class="form-label headline">表紙</label>
                        </div>
                        <div class="col-4"></div>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <input type="button" class="btn btn-next-page btn-sm px-4 no-print" value="ページへ移動" @click="showH" />
                </div>
            </div>
            <div class="row mt-5 mt-md-3 headline">
                <label class="form-label ps-3 col-3">目次構成</label>
            </div>
            <div class="row mt-3 mt-md-3">
                <div class="ps-md-3 col-12 col-md-5">
                    <div class="row">
                        <div class="col-7 d-md-flex flex-column">
                            <label class="form-label headline">1.基本方針</label>
                            <label class="form-label ms-3">目的、基本方針</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式1</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <input type="button" class="btn btn-next-page btn-sm px-4 no-print" value="ページへ移動" @click="showN1" />
                </div>
            </div>
            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-5">
                    <div class="row">
                        <div class="col-7 d-md-flex flex-column">
                            <label class="form-label headline">2.BCPの運用体制</label>
                            <label class="form-label ms-3">緊急時の人員体制</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式4</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <input type="button" class="btn btn-next-page btn-sm px-4 no-print" value="ページへ移動" @click="showN2" />
                </div>
            </div>
            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-5">
                    <div class="row">
                        <div class="col-7 d-md-flex flex-column">
                            <label class="form-label headline">3.被害想定と事前対策</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式3</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column">
                            <label class="form-label ms-3">重要{{  callReplaceWord('商品') }}</label>
                            <label class="form-label ms-3">ハザードマップによる風水害・震度被害の想定</label>
                            <label class="form-label ms-3">被害想定</label>
                            <label class="form-label ms-4 mt-3">・ 大規模地震（震度5弱以上）で想定される影響</label>
                            <label class="form-label ms-5">インフラ・{{  callReplaceWord('組織') }}への影響</label>
                            <label class="form-label ms-5">{{  callReplaceWord('経営') }}資源（人・モノ・金・情報）への事前対策</label>
                            <label class="form-label ms-4 mt-3">・ 新型コロナウイルス感染症で想定される影響</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <input type="button" class="btn btn-next-page btn-sm px-4 no-print" value="ページへ移動" @click="showN3" />
                </div>
            </div>
            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-5">
                    <div class="row">
                        <div class="col-7 d-md-flex flex-column">
                            <label class="form-label headline">4.BCP発動フロー</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式4</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column">
                            <label class="form-label ms-3">初動対応</label>
                            <label class="form-label ms-3">復旧に向けた対応</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <input type="button" class="btn btn-next-page btn-sm px-4 no-print" value="ページへ移動" @click="showN4" />
                </div>
            </div>
            <div class="row mt-5 mt-md-3">
                <div class="ps-md-3 col-12 col-md-5">
                    <div class="row">
                        <div class="col-7 d-md-flex flex-column">
                            <label class="form-label headline">5.BCPの運用</label>
                        </div>
                        <div class="col-4">
                            <label class="bg-bcp-introduction text-light px-4 py-1 col-form-label text-nowrap">様式5</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-column">
                            <label class="form-label ms-3">BCPの定着</label>
                            <label class="form-label ms-3">BCPの見直し</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <input type="button" class="btn btn-next-page btn-sm px-4 no-print" value="ページへ移動" @click="showN5" />
                </div>
            </div>
        </div> 
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showN1" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('100000');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showNext = () => {
            const url = "/browse/N";

            location.href = url;
        }

        const is_margin_1 = ref(true);

        const returnHome = () => {
            const url = "/browse/home";

            location.href = url;
        }

        const showN1 = () => {
            const url = "/browse/N1";

            location.href = url;
        }

        const showN2 = () => {
            const url = "/browse/N2";

            location.href = url;
        }

        const showN3 = () => {
            const url = "/browse/N3";

            location.href = url;
        }

        const showN4 = () => {
            const url = "/browse/N4";

            location.href = url;
        }

        const showN5 = () => {
            const url = "/browse/N5";

            location.href = url;
        }

        const showH = () => {
            const url = "/browse/H";

            location.href = url;
        }

        return {
            setLevel,
            callReplaceWord,
            showNext,
            is_margin_1,

            returnHome,
            showN1,
            showN2,
            showN3,
            showN4,
            showN5,
            showH,

        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        }
    }
}

</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.title {
    background-color: #f2f2f2;
}

.btn-next-page {
    border: 1px solid;
    color: white;
    border-color: #00c0bc;
    background-color: #00c0bc;
}

.btn-next-page:hover {
    border: 1px solid;
    color: #00c0bc;
    border-color: #00c0bc;
    background-color: white;
}

@media print {
.card {
    border:1px solid black;
}

}
</style>