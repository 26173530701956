<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                退会予告手続
            </div>
            <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome"  :disabled="isProcessing">
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="text">
            <label class="ms-4 mt-5">お手続きの前に以下の注意点をご確認いただき、お進みください。</label><br>
            <label class="ms-5 mt-3">〇&emsp;退会予告手続後、実際にTOTONO-Lサイトが利用できなくなる日は、毎年9月末日（TOTONO-L年度末）となります。</label><br>
            <label class="ms-5 mt-3">〇&emsp;退会予告手続が年度の途中であっても、TOTONO-Lが受領した年会費、従量課金は一切返戻いたしません。</label><br>
            <label class="ms-5 mt-3">〇&emsp;毎年9月末日の深夜0時に、本サービスの利用は終了し、会員データは自動的に消去されます。</label><br>
            <label class="ms-5 mt-3">〇&emsp;万一、TOTONO-Lを再度利用されたい場合は、最初から入力していただく必要があります。会員データを復活することができません。</label><br>
        </div>

        <div v-if="会員資格更新種別 === 10060001 || 会員資格更新種別 === 10060002">
            <div class="p2 col-md-2 ms-4 mt-5 d-flex align-items-center py-2">
                <input type="button" class="btn btn-black btn-sm" value="脱退予告手続を申し込みます" @click="withdrawal"  :disabled="isProcessing">
            </div>

        </div>
        <div v-else>
            <div class="text">
                <label class="ms-4 mt-5">毎年9月末日（TOTONO-L年度末）までは、脱退予告手続を取り消すことができます。</label><br>
            </div>

            <div class="p2 col-md-2 ms-4 mt-3 d-flex align-items-center py-2">
                <input type="button" class="btn btn-orange btn-sm" value="脱退予告手続を取消します" @click="cancelWithdrawal" :disabled="isProcessing">
            </div>
        </div>

        <div class="row mt-3">
            <div class="p2 border border-dark d-flex border-top-0 border-start-0 border-end-0 py-2">
            </div>
        </div>

        <div class="p2 col-md-2 ms-4 mt-5 d-flex align-items-center py-2">
            <input type="button" class="btn btn-gray btn-sm" value="管理者ページへ戻る" @click="backHome"  :disabled="isProcessing">
        </div>
    </div>
</template>

<script>
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import { computed, inject, onMounted, reactive, ref } from 'vue';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        const 会員資格更新種別 = ref(0);

        const isProcessing = ref(false);

        onMounted(() => {
            会員資格更新種別.value = props.corp_info.会員資格更新種別;
        });

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const withdrawal = () => {
            const url = '/api/withdrawal';

            isProcessing.value = true;
            axios.get(url).then(res => {
                会員資格更新種別.value = 10060003;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const cancelWithdrawal = () => {
            const url = '/api/cancel_withdrawal';

            isProcessing.value = true;
            axios.get(url).then(res => {
                会員資格更新種別.value = 10060001;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            backHome,

            会員資格更新種別,
            isProcessing,

            withdrawal,
            cancelWithdrawal,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
    .color-black {
        color: #404040;
    }
</style>