import {reactive} from 'vue'

export default function(){
    const isZenKatakana = (str) => {
        str = (str==null)?"":str;
        if(str.match(/^[ァ-ヶー　]+$/)){    //"ー"の後ろの文字は全角スペースです。
            return true;
        }else{
            return false;
        }
    }

    const isTelNumber = (tel) => {
        tel = (tel == null) ? '' : tel;

        if(tel.match(/^0[-0-9]{11,12}$/)){
            return true;
        } else {
            return false;
        }
    }

    const isZipNumber = (zip) => {
        zip = (zip == null) ? '' : zip;

        if(zip.match(/^[0-9]{7}$/)){
            return true;
        } else {
            return false;
        }
    }

    return {
        isZenKatakana,
        isTelNumber,
        isZipNumber,
    }
}