<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            上級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label bg-resilience-green text-light no-print px-2">閲覧制限｜なし</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">5. 緊急時におけるBCP発動</label>
                    <span class="ms-3 bg-bcp-advanced text-light px-4 py-1 col-form-label text-nowrap">様式20</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 地域貢献</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">・ 地域住民の安全・安心に貢献するため、当社が行う活動には以下のものがある。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">1&emsp;日常的な活動</label>
                </div>
            </div>
            <div class="ms-md-3">
                <div class="row mt-3">
                    <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label d-none d-md-block d-print-block">チェック</label>
                    </div>
                    <div class="p2 col-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">種類</label>
                    </div>
                    <div class="p2 col-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">補足</label>
                    </div>
                </div>
                <div class="row" v-for="(item, index) in valueFormsField1">
                    <div class="p2 col-1 border border-end-0 bg-white d-flex align-items-center justify-content-center" :class="{ 'border-bottom-0' : index !== valueFormsField1.length - 1}">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" onclick="return false;" :checked="Number(valueBySeq(item, 1))">
                        </div>
                    </div>
                    <div class="px-0 px-md-2 col-6 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== valueFormsField1.length - 1}">
                        <label class="form-label">{{ valueBySeq(item, 2) || '&emsp;' }}</label>
                    </div>
                    <div class="px-0 px-md-2 col-5 border bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== valueFormsField1.length - 1}">
                        <label class="form-label">{{ valueBySeq(item, 3) || '&emsp;' }}</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">2&emsp;緊急時における活動</label>
                </div>
            </div>
            <div class="ms-md-3 pb-5">
                <div class="row mt-3">
                    <div class="p2 col-1 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label d-none d-md-block d-print-block">チェック</label>
                    </div>
                    <div class="p2 col-6 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">種類</label>
                    </div>
                    <div class="p2 col-5 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">補足</label>
                    </div>
                </div>
                <div class="row" v-for="(item, index) in valueFormsField2">
                    <div class="px-0 px-md-2 col-1 border border-end-0 bg-white d-flex align-items-center justify-content-center" :class="{ 'border-bottom-0' : index !== valueFormsField2.length - 1}">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" onclick="return false;" :checked="Number(valueBySeq(item, 1))">
                        </div>
                    </div>
                    <div class="px-0 px-md-2 col-6 border border-end-0 bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== valueFormsField2.length - 1}">
                        <label class="form-label">{{ valueBySeq(item, 2) || '&emsp;' }}</label>
                    </div>
                    <div class="px-0 px-md-2 col-5 border bg-white d-flex align-items-center justify-content-start" :class="{ 'border-bottom-0' : index !== valueFormsField2.length - 1}">
                        <label class="form-label">{{ valueBySeq(item, 3) || '&emsp;' }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="BCP閲覧 HOMEへ戻る" @click="showHome" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('000100');
        const isMargin1 = ref(true);

        let valueFormsField1 = reactive([]);
        let valueFormsField2 = reactive([]);

        const showBack = () => {
            const url = '/browse/J6';
            location.href = url;
        }

        const showHome = async () => {
            const url = '/browse/home';
            location.href = url;
        }

        const valueBySeq = (item, seq) => {
            return item.find((d) => d.seq === seq)?.value;
        }

        if (Object.keys(props.bcp_values_info).length) {
            valueFormsField1 = Object.values(props.bcp_values_info).filter(
                (fetchData) => fetchData[0].field_number === 1
            );
            valueFormsField2 = Object.values(props.bcp_values_info).filter(
                (fetchData) => fetchData[0].field_number === 2
            );
        }

        return {
            setLevel,
            isMargin1,
            valueFormsField1,
            valueFormsField2,
            showBack,
            showHome,
            valueBySeq,
        }
    },
    props:{
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="checkbox"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-6 {
        width: 50%;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
