<template>
    <div class="row">
        <div class="col-md-8">
            <div class="row mt-3 pb-5">
                <i id="teicyaku-logo"></i>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-4 px-5">
                <div class="row">
                    <label class="user-menu h4 px-3 py-2">□ 利用者メニュー</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="showTrainingPlan">訓練計画の閲覧</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="showTrainingRecord">訓練実施記録の閲覧</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="showEducationRecord">教育実施記録の閲覧</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="showTrainingBoard">訓練&教育掲示板</label>
                </div>
            </div>
            <div class="col-md-4 px-5 mt-3 mt-md-0" v-if="is('administrator')">
                <div class="row">
                    <label class="administrator_menu h4 px-3 py-2">□ 管理者メニュー</label>
                </div>
                <div class="row mt-3 d-flex flex-row">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="registerTrainingPlan">訓練計画登録</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="implementTrainingPlan">訓練計画一覧から実施</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button px-3 py-2 cursor-pointer" @click="recordTraining">訓練実施記録</label>
                </div>
            </div>
            <div class="col-md-4 px-5" v-if="is('administrator')">
                <div class="row d-none d-md-block d-print-block" id="row_margin">
                    <!-- <label class="administrator_menu h4 px-3 py-2">□ 管理者メニュー</label> -->
                </div>
                <div class="row mt-3 d-flex flex-row">
                    <label class="menu-button ml-5 px-3 py-2 cursor-pointer" @click="registerEducationPlan">教育計画登録</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button ml-5 px-3 py-2 cursor-pointer" @click="implementEducationPlan">教育計画一覧から実施</label>
                </div>
                <div class="row mt-3">
                    <label class="menu-button ml-5 px-3 py-2 cursor-pointer" @click="recordEducation">教育実施記録</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showTrainingPlan = () => {
            const url = '/fixation/b4';

            location.href = url;
        }

        const showEducationRecord = () => {
            const url = '/fixation/k4';

            location.href = url;
        }

        const showTrainingBoard = () => {
            const url = '/fixation/kei';

            location.href = url;
        }

        const registerTrainingPlan = () => {
            const url = '/fixation/b1';

            location.href = url;
        }

        const implementTrainingPlan = () => {
            const url = '/fixation/b2';

            location.href = url;
        }

        const recordTraining = () => {
            const url = '/fixation/b3';

            location.href = url;
        }

        const registerEducationPlan = () => {
            const url = '/fixation/k1';

            location.href = url;
        }

        const implementEducationPlan = () => {
            const url = '/fixation/k2';

            location.href = url;
        }

        const recordEducation = () => {
            const url = '/fixation/k3';

            location.href = url;
        }

        const showTrainingRecord = () => {
            const url = '/fixation/showTrainingRecord';

            location.href = url;
        }

        return {
            showTrainingPlan,
            showEducationRecord,
            showTrainingBoard,
            registerTrainingPlan,
            implementTrainingPlan,
            recordTraining,
            registerEducationPlan,
            implementEducationPlan,
            recordEducation,
            showTrainingRecord,
        }
    },
}
</script>

<style lang="scss" scoped>
#teicyaku-logo {
    display: inline-block;
    width: 590px;
    height: 180px;
    background-image: url('../../../svg/13-03_teicyaku.svg');
    background-size: 590px auto;
    background-repeat: no-repeat;

}

.user-menu {
    background-color: #33cccc;
    border: 1px solid white;
    color: white;
}

.auditor-menu {
    background-color: #00cc99;
    border: 1px solid white;
    color: white;
}

.administrator_menu {
    background-color: #ff6600;
    border: 1px solid white;
    color: white;
}

.menu-button {
    background-color: #808080;
    border: 1px solid white;
    color: white;
}

#row_margin {
    height: 56px;
}

@media screen and (max-width: 700px){
    #teicyaku-logo {
        display: inline-block;
        width: 304px;
        height: 92px;
        background-image: url('../../../svg/13-03_teicyaku.svg');
        background-size: 304px auto;
        background-repeat: no-repeat;
    }
}
</style>