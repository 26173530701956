<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            介護BCP策定
        </div>
        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backMenu">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="text">
        <label class="ms-3 mt-3">　2021年4月に施行された「令和3年度介護報酬改定における改定事項について（以下「介護報酬改定」）」では、感染症や災害への対応力強化として、介護事業者は2024年3月末までにBCP（事業継続計画）を策定し、研修や訓練を実施することが義務付けられました。<br>　本サイトは、厚生労働省が公表しているガイドラインをもとに開発しました。詳しくは、「チュートリアルを見る（参考にした国の公表資料｜BCP事業継続計画とは）」をクリックしてご確認ください。</label>
    </div>

    <tutorial v-if="dispIntroBCount <= 5"></tutorial>
    <careBcpStart v-if="dispIntroBCount > 5"></careBcpStart>

    <div class="text">
        <label class="ms-3 mt-5">当サイトは、経営者やサブリーダーが投入できる時間と労力に応じて4つのコースを用意しました。<br>まずは、入門コースから策定をスタートし、その後、リスクの想定や事業継続に関するさまざまな戦略の深化度合いに応じて、上位コースに進みます。</label>
    </div>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 btn-bcp-care-natural-disasters d-flex align-items-center justify-content-center py-2">
            介護・自然災害
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
            ▶
        </div>
        <div class="p2 col-md-2 btn-bcp-care-infection d-flex align-items-center justify-content-center py-2">
            介護・感染症
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            自然災害が発生すると、インフラ停止などによる通常業務の休止や、避難誘導・安否確認などによる災害時業務の発生のため、通常の業務量が急減します。できる限り事業の継続・早期復旧を図ります。
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
            新型コロナウイルス感染症が国内で拡大し始めると、自身が感染したり、濃厚接触者になる等により出勤できなくなる職員が出ます。感染リスク、社会的責任、経営面を勘案し、事業継続のレベルを決定します。
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 d-flex align-items-top justify-content-center py-2">
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 d-flex align-items-top justify-content-center py-2">
            <div class="p2 col-md d-flex align-items-top justify-content-center py-2">
            </div>
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex text-center align-items-top justify-content-center py-2">
            <div class="p2 col-md bg-course d-flex align-items-top justify-content-center py-2">
                経営者1人で<br>延べ3～5日程度<br><br>経営者とサブリーダー<br>含め数人で<br>2～3日程度
            </div>
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex text-center align-items-top justify-content-center py-2">
            <div class="p2 col-md bg-course d-flex align-items-top justify-content-center py-2">
                経営者1人で<br>延べ3～5日程度<br><br>経営者とサブリーダー<br>含め数人で<br>2～3日程度
            </div>
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 d-flex align-items-top justify-content-center py-2">
            <div class="p2 col-md d-flex text-center align-items-top justify-content-center py-2">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 bg-white d-flex align-items-top justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 d-flex align-items-top justify-content-center py-2">
        </div>
    </div>

    <div class="row">
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 btn-bcp-care-natural-disasters d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 btn-bcp-care-infection d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
        </div>
        <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
        </div>
    </div>

    <div class="text mt-5">
        <label>注）BCPの策定に限った日数の目安ですが、会社の規模や事業内容、事前対策の選定内容等によって変動します。また、別途、BCPの運用（教育訓練や計画見直し）に取り組むための時間が必要となります。</label>
    </div>

    <tutorial v-if="dispIntroBCount > 5"></tutorial>
    <careBcpStart v-if="dispIntroBCount <= 5"></careBcpStart>

    <div class="row mt-5">
        <div class="p2 col-md-2 d-flex align-items-center">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backMenu">
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import tutorial from "./ShowTutorialComponent.vue";
import careBcpStart from "./ShowCareBCPStartComponent.vue";

export default {
    components: {
        levelIcon,
        tutorial,
        careBcpStart,
    },
    setup(props) {
        const setLevel = ref('000011');

        const backMenu = () => {
            const url = '/bcp/home';
            location.href = url;
        }

        const showNext = () => {
            const url = '/bcp/bcp_introduction/1';
            location.href = url;
        }

        let dispIntroBCount = 0;
        if (window.localStorage) {
            localStorage.setItem('dispIntroBCount', Number(localStorage.getItem('dispIntroBCount')) + 1);
            dispIntroBCount = localStorage.getItem('dispIntroBCount');
        }

        return {
            setLevel,
            showNext,
            backMenu,
            dispIntroBCount,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
    .bg-course {
        background-color: #D9D9D9;
    }
</style>
