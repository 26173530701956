<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            3.　被害想定と事前対策｜被害想定
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic" value="基本｜3.中核事業と復旧目標 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
            :set_level="setLevel"
        />

    <div id="page-link-1" class="row ms-3 mt-5">
        <div class="p2 col-md-4 d-flex align-items-center justify-content-center" >
            <input type="button" class="btn btn-new-window-button btn-lg" style="font-size:12pt"
                value="大規模地震（震度5弱以上）で想定される影響" :disabled="isProcessing" @click="showAssumptionAndCounterPlan">
        </div>
        <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2" style="font-size:12pt">
        </div>
        <div class="p2 col-md-5 d-flex align-items-center justify-content-start py-2" >
            <input type="button" class="btn btn-black" style="font-size:12pt"
                value="強毒性の感染症で想定される影響" :disabled="isProcessing" @click="showOwn">
        </div>
    </div>

    <div v-for="(form, formIndex) in valueForms" :key="formIndex">
        <div v-if="formIndex >= 0 && formIndex <= 3">
            <div class="row ms-3 mt-5" v-if="formIndex === 0">
                <div class="p2 col-md-5 bg-sub-title text-dark d-flex align-items-center justify-content-start">
                    ◯　インフラへの影響
                </div>
            </div>

            <div class="row mx-3 mt-3" v-if="formIndex === 0">
                <span>強毒性の感染症で想定されるインフラへの影響をイメージし、あなたの{{ callReplaceWord('組織') }}に関わる具体的な影響を記載します。すでに入力されている内容に加筆・修正してください。</span>
                <span>また、その際に各インフラの利用の可否についてもイメージし、利用できない場合は「×」、わからない場合は「△」、利用できる場合は「○」を選択してください。</span>
            </div>

            <div class="row ms-3 mt-3" :class="{'mt-3':formIndex === 0, 'mt-5':formIndex > 0}">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <span v-if="formIndex === 0">ライフライン</span>
                    <span v-else-if="formIndex === 1">情報通信</span>
                    <span v-else-if="formIndex === 2">道路</span>
                    <span v-else>鉄道</span>
                </div>
            </div>

            <div v-for="(val, valIndex) in form.values" :key="valIndex">
                <div class="row ms-3 mt-3" v-if="valIndex > 1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    </div>
                </div>
                <div class="row ms-3" v-if="valIndex >= 1">
                    <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                        <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="val.value">
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === 1">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label v-if="formIndex === 0">感染症発生時、ライフラインが停止した場合、業務状況がどうなるかをイメージし、加筆・修正してください。</label>
                                    <label v-else-if="formIndex === 1">感染症発生時に情報通信に与える影響をイメージし、加筆・修正してください。</label>
                                    <label v-else-if="formIndex === 2">感染症発生時に道路状況をイメージし、加筆・修正してください。</label>
                                    <label v-else>感染症発生時の鉄道運行状況をイメージし、加筆・修正してください。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>

                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === form.values.length - 1">
                        <input type="button" class="btn btn-black" value="行を増やす" @click="addLine(form.columnName)">
                    </div>
                </div>

                <div class="row ms-3 mt-3" v-if="valIndex == form.values.length - 1">
                    <div class="p2 col-md-4 d-flex align-items-center justify-content-start">
                        <span v-if="formIndex === 0">ライフライン利用の可否を選択してください</span>
                        <label v-else-if="formIndex === 1">情報通信利用の可否を選択してください</label>
                        <label v-else-if="formIndex === 2">道路利用の可否を選択してください</label>
                        <label v-else>鉄道利用の可否を選択してください</label>
                    </div>
                    <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                        <input type="radio" :id="'customRadioInline1' + formIndex" :name="'customRadioInline1' + formIndex" class="custom-control-input" value="×" v-model="form.values[0].value">
                        <label class="custom-control-label" :for="'customRadioInline1' + formIndex">&emsp;&emsp;×</label>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                    </div>
                    <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                        <input type="radio" :id="'customRadioInline2' + formIndex" :name="'customRadioInline2' + formIndex" class="custom-control-input" value="△" v-model="form.values[0].value">
                        <label class="custom-control-label" :for="'customRadioInline2' + formIndex">&emsp;&emsp;△</label>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                    </div>
                    <div class="p2 col-md-1 custom-control custom-radio custom-control-inline">
                        <input type="radio" :id="'customRadioInline3' + formIndex" :name="'customRadioInline3' + formIndex" class="custom-control-input" value="○" v-model="form.values[0].value">
                        <label class="custom-control-label" :for="'customRadioInline3' + formIndex">&emsp;&emsp;○</label>
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label v-if="formIndex === 0">感染症発生時、ライフラインが利用不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。</label>
                                    <label v-else-if="formIndex === 1">感染症発生時、情報通信が利用不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。</label>
                                    <label v-else-if="formIndex === 2">感染症発生時、道路の利用が不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。</label>
                                    <label v-else>感染症発生時、鉄道の利用が不可の場合×、一部利用可能の場合△、問題なく利用可能な場合○を選択ください。なお、{{ callReplaceWord('従業員') }}の通勤や業務の鉄道利用とは関係しません。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>

        <hr v-if="formIndex === 4">

        <div v-if="formIndex >= 4">
            <div class="row ms-3 mt-5" v-if="formIndex === 4">
                <div class="p2 col-md-5 bg-sub-title text-dark d-flex align-items-center justify-content-start">
                    ◯　{{ callReplaceWord('組織') }}への影響
                </div>
            </div>

            <div class="row mx-3 mt-3" v-if="formIndex===4">
                <span>強毒性の感染症で想定される、あなたの{{ callReplaceWord('組織') }}への影響をイメージします。すでに入力されている内容に加筆・修正してください。</span>
            </div>

            <div class="row ms-3" :class="{'mt-3':formIndex === 4, 'mt-5':formIndex > 4}">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <span v-if="formIndex === 4">人（ヒト）</span>
                    <span v-else-if="formIndex === 5">物（モノ）</span>
                    <span v-else-if="formIndex === 6">金（カネ）</span>
                    <span v-else>情報</span>
                </div>
            </div>

            <div v-for="(val, valIndex) in form.values" :key="valIndex">
                <div class="row ms-3 mt-3" v-if="valIndex >= 1">
                    <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    </div>
                </div>
                <div class="row ms-3">
                    <div class="p2 col-md-10 d-flex align-items-center justify-content-start">
                        <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder="" v-model="val.value">
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === 0">
                        <VDropdown
                                theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                            <template #popper>
                                <div class="row">
                                    <label v-if="formIndex === 4">感染症発生時、人に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                    <label v-else-if="formIndex === 5">感染症発生時の会社の「物」に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                    <label v-else-if="formIndex === 6">感染症発生時に起こりえる、{{ callReplaceWord('売上') }}や運転資金など金に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                    <label v-else>感染症発生時に事業継続に関する重要なデータのや情報（バックアップ含む）の破損、喪失など{{ callReplaceWord('組織') }}の情報に与える影響をイメージしてください。内容に変更があれば加筆・修正してください。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="valIndex === form.values.length - 1">
                        <input type="button" class="btn btn-black" value="行を増やす" @click="addLine(form.columnName)">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 mt-5 col-md-6 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" :value="'入門｜3.　被害想定と事前対策｜重要' + callReplaceWord('商品') + '・被害想定 へ戻る'" :disabled="isProcessing" @click="showAssumptionAndCounterPlan">
        </div>
        <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜4.　BCP発動フロー｜初動対応 へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-5 col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(props.bcp_introduction_values_info.length > 0) {
                // 該当カラムに絞り込み
                const introductionValues = props.bcp_introduction_values_info.filter(fetchData => {
                    return columnNameRegex.test(fetchData.column_name);
                })
                if (introductionValues.length > 0) {
                    valueForms.forEach(initData => {
                        initData.values.splice(0);
                        introductionValues.forEach(data => {
                            // インフラへの影響の場合
                            if(data.column_name == initData.columnName && infraRegex.test(data.column_name)) {
                                // 取得データにチェック欄が存在するか確認
                                const result = introductionValues.filter(value => {
                                    return value.column_name == data.column_name && value.seq == 1;
                                });
                                // 存在しない場合で、まだ１行もデータがない場合は空行を追加
                                if (result.length == 0 && initData.values.length == 0) {
                                    initData.values.push({value: ''});
                                }
                                initData.values.push({value: data.value});
                            } else if(data.column_name == initData.columnName) {
                                // 組織への影響の場合
                                initData.values.push({value: data.value});
                            }
                        });
                        // 該当カラムに対して取得データが１件も存在しなかった場合は空行を追加
                        if (initData.values.length == 0) {
                            initData.values.push({value: ''});
                        }
                    });

                    // 取得データがなかった場合の行数調整
                    valueForms.forEach(initData => {
                        // 余分な空が存在する場合は表示させない
                        initData.values.forEach((data, index) => {
                            if (index != 0 && data.value == '') {
                                initData.values.splice(index, 1);
                            }
                        })
                        // 最低行数(ヒント、行を増やすボタン表示のために２カラム以上必要)に満たない場合、空行追加
                        if((infraRegex.test(initData.columnName) && initData.values.length == 1 )
                            || (organizationRegex.test(initData.columnName) && initData.values.length == 0)
                        ) {
                            initData.values.push(
                                {value: ''},
                                {value: ''},
                            );
                        } else if(
                            (infraRegex.test(initData.columnName) && initData.values.length == 2 )
                            || (organizationRegex.test(initData.columnName) && initData.values.length == 1)
                        ) {
                            initData.values.push({value: ''});
                        }
                    });
                }
             }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const columnNameRegex = new RegExp('^N0002[5-9]{1}$|^N0003[0-2]{1}$');
        const infraRegex = new RegExp('^N0002[5-8]{1}$');
        const organizationRegex = new RegExp('^N0002[9]{1}$|^N0003[0-2]{1}$');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const valueForms = reactive([
            {
                columnName: 'N00025',
                values:[
                    {value: ''},
                    {value: '社会昨日の維持に関わるライフライン（電気、ガス、水道）は、基本的に通常どおり使用できる。'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00026',
                values:[
                    {value: ''},
                    {value: '電話、インターネット等の情報通信手段は、基本的に通常どおり使用できる。'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00027',
                values:[
                    {value: ''},
                    {value: '道路に大きな影響はなく、基本的に通常どおりに利用できる。'},
                    {value: ''},
                ]
            },
            {
                columnName: 'N00028',
                values:[
                    {value: ''},
                    {value: '運航本数が減少する。'},
                    {value: '乗客数が制限される。'},
                ]
            },
            {
                columnName: 'N00029',
                values:[
                    {value: '一部の' + callReplaceWord('従業員') + 'やその家族が新型インフルエンザに感染する。'},
                    {value: '約4割の' + callReplaceWord('従業員') + 'が' + callReplaceWord('出社') + 'できなくなる。'},
                ]
            },
            {
                columnName: 'N00030',
                values:[
                    {value: '物流網の混乱や' + callReplaceWord('取引先') + 'の事業停止により、' + callReplaceWord('原材料') + '・部品・' + callReplaceWord('商品') + '等の供給が停止する。'},
                    {value: '在庫品が不足する。'},
                ]
            },
            {
                columnName: 'N00031',
                values:[
                    {value: '事業が停止してしまい、その間の売上がなくなる。'},
                    {value: callReplaceWord('組織') + 'の運転資金が必要となる。'},
                ]
            },
            {
                columnName: 'N00032',
                values:[
                    {value: '一部機能の低下の可能性はあるが、基本的には通常どおり利用できる。'},
                    {value: ''},
                ]
            },
        ]);

        const addLine = (targetColumnName) => {
            valueForms.forEach(data => {
                if(data.columnName == targetColumnName){
                    data.values.push({
                        value: ''
                    });
                }
            });
        }

        const showBasic = async () => {
            // 被害想定を登録
            const result = await registerIntroduction6();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/3';
                location.href = url;
            }
        }

        const showOwn = async () => {
            // 現在のページを表示
            const url = '#page-link-1';
            location.href = url;
        }

        const showBack = async () => {
            // 被害想定を登録
            const result = await registerIntroduction6();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showAssumptionAndCounterPlan = async () => {
            // 被害想定を登録
            const result = await registerIntroduction6();

            if (result == '200') {
                // 被害想定と事前対策のページへ遷移
                const url = '/bcp/bcp_introduction/4#page-link-2';
                location.href = url;
            }
        }

        const showNext = async () => {
            // 被害想定を登録
            const result = await registerIntroduction6();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/7';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 被害想定を登録
            const result = await registerIntroduction6();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 被害想定の登録
        const registerIntroduction6 = async () => {
            // リクエストの整形
            const reqValueForms = [];
            valueForms.forEach(data => {
                let seq = 1;
                data.values.forEach(val => {
                    if (val.value == '' && seq == 1 ) {
                        // チェックボックス用にseqの値を制御（チェックボックス未入力ならseqだけインクリメント）
                        seq++;
                    } else if (val.value != '') {
                        // 入力済みの場合
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: seq++,
                            value: val.value,
                        });
                    }
                });
            });

            const postUrl = '/api/bcp/registerIntroduction6';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            valueForms,
            isProcessing,
            callReplaceWord,
            addLine,
            showBasic,
            showOwn,
            showBack,
            showAssumptionAndCounterPlan,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
    .bg-sub-title {
        background-color: #ddebf7;
    }
</style>
