<template>
    <div>
        <div class="heading-orange mt-3">
        会員情報の閲覧
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="text">
            <label class="ms-3 mt-3">会員情報（１）B&B災害応援協定検索結果で表示される情報</label><br>
        </div>

        <corpInfoForBnB
            :組織名="corp_info.組織名"
            :郵便番号="corp_info.郵便番号"
            :住所="corp_info.都道府県 + corp_info.市区町村 + corp_info.番地"
            :業種="corp_info.業種"
            :業種名="corp_info.業種名"
            :label従業員="callReplaceWord('従業員')"
            :url="corp_info.url"
            :img_src="corp_image"
            :matching_list = matching_list
            :協定実績 = count_matching
            :BCP策定レベル = "bcp_status"
            :BCP発動回数 = count_bcp_activate
            :協定書保管数 = count_agreement
            :TOTONOL歴 = yearPassed
            :従業員数 = pay_per_use_info.利用者数
        />

        <div class="text">
            <label class="ms-3 mt-5">会員情報（２）{{ callReplaceWord('従業員') }}のみ閲覧できる情報</label><br>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row mx-5 mt-5 border-bottom-black">
                    <div class="ps-5 py-3 member-info-2-title">
                        あなたの{{ callReplaceWord('組織') }}のTOTONO-L登録情報
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">業種（日本標準産業分類）</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.業種 }}</label>
                        <label class="col-form-label ms-5">{{ corp_info.業種名 }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-1">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">サイトの利用開始希望日<br>（年会費発生日）</label>
                    </div>
                    <div class="col-md-8 d-flex align-items-center">
                        <label class="col-form-label">{{ this.$root.dayjs(corp_info.利用開始日).format('YYYY年MM月DD日') }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">ユーザー種別</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.会員種別名 }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">従量課金情報</label>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-1">
                                <label class="col-form-label">1</label>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">実際に登録している拠点・支店の数</label>
                            </div>
                            <div class="col-md-2">
                                <label class="col-form-label">{{ count_branches }}／{{ pay_per_use_info.拠点数 }}</label>
                            </div>
                            <div class="col-md-1">
                                <label class="col-form-label">ヶ所</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <label class="col-form-label">2</label>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">実際に使用している人数</label>
                            </div>
                            <div class="col-md-2">
                                <label class="col-form-label">{{ count_users }}／{{ pay_per_use_info.利用者数 }}</label>
                            </div>
                            <div class="col-md-1">
                                <label class="col-form-label">人</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <label class="col-form-label">3</label>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">実際に登録している利用者家族の人数</label>
                            </div>
                            <div class="col-md-2">
                                <label class="col-form-label">{{ count_family }}／{{ pay_per_use_info.利用家族数 }}</label>
                            </div>
                            <div class="col-md-1">
                                <label class="col-form-label">人</label>
                            </div>
                        </div>
                        <div class="row" v-if="showLINE === 'true'">
                            <div class="col-md-1">
                                <label class="col-form-label">4</label>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">LINE連携オプション</label>
                            </div>
                            <div class="col-md-3">
                                <label class="col-form-label">{{ corp_info.LINE連携 == 1 ? "あり" : "なし" }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-5 mt-5 border-bottom-black">
                    <div class="ps-5 py-3 member-info-2-title">
                        あなたの{{ callReplaceWord('組織') }}の管理者（利用者ID：000001）
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">氏名</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.管理者名_姓}}　{{ corp_info.管理者名_名 }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">フリガナ</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.管理者名_カナ_姓}}　{{ corp_info.管理者名_カナ_名 }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">部署・役職</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.管理者_所属部署}}・{{ corp_info.管理者_役職 }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">電話番号</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.管理者_電話番号 }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">メールアドレス</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.管理者_メールアドレス }}</label>
                    </div>
                </div>
                <div class="row mx-5 border-bottom-black py-2">
                    <div class="col-md-4 ps-5">
                        <label class="col-form-label">メルマガ登録</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col-form-label">{{ corp_info.メルマガ登録 ? '登録する' : '登録しない' }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row ms-4 mt-5">
            <div class="row p2 col-md-2 d-flex align-items-center py-2">
                <input type="button" class="btn btn-green btn-sm" value="マイページへ戻る" @click="backMyPage">
            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import corpInfoForBnB from '../../common/CorpInfoForBnBComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import CommonDate from '../../../function/CommonDate';
const { calculateYearsPassed } = CommonDate();
import { useEnv } from '../../../function/useEnv';

export default {
    components: {
        levelIcon,
        corpInfoForBnB,
    },
    setup(props) {
        const dayjs = inject('dayjs');

        const { VITE_ENABLE_LINE } = useEnv();
        const showLINE = ref(VITE_ENABLE_LINE);

        const yearPassed = ref(0);
        
        onMounted(() => {
            yearPassed.value = calculateYearsPassed(dayjs(props.corp_info.利用開始日).format('YYYY-MM-DD'));
        });

        const setLevel = ref('111111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backMyPage = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        // const matching_list = reactive([
        //     {
        //         'thing_title': 'ヒト',
        //         'thing_value': '応援要員',
        //         'matching_title': '協力を依頼したい',
        //         'matching_value1': '7日間応援可',
        //     },
        //     {
        //         'thing_title': 'モノ',
        //         'thing_value': '資機材・車両等',
        //         'matching_title': '協力を依頼したい',
        //         'matching_value1': '固定側サポートユニット（丸形）',
        //     },
        //     {
        //         'thing_title': 'コト',
        //         'thing_value': '代替拠点',
        //         'matching_title': '協力を依頼したい',
        //         'matching_value1': '岐阜市・関市周辺の拠点を探しています',
        //     },
        //     {
        //         'thing_title': 'コト',
        //         'thing_value': '代替拠点',
        //         'matching_title': '要請に応えたい',
        //         'matching_value1': '鉄筋コンクリート造の倉庫あり',
        //     },
        //     {
        //         'thing_title': 'コト',
        //         'thing_value': '代替業務〔生産〕',
        //         'matching_title': '協力を依頼したい',
        //         'matching_value1': '集荷拠点や倉庫機能を希望',
        //     },
        //     {
        //         'thing_title': 'コト',
        //         'thing_value': '代替業務〔生産〕',
        //         'matching_title': '要請に応えたい',
        //         'matching_value1': 'マザック VARIAXIS J600 2016年',
        //     },
        // ]);

        // const matching_list = reactive([
        // ]);

        return {
            setLevel,
            yearPassed,

            callReplaceWord,

            backMyPage,

            // matching_list,

            showLINE,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        pay_per_use_info: {
            type: Object,
        },
        count_branches: {
            type: Number,
        },
        count_users: {
            type: Number,
        },
        count_family: {
            type: Number,
        },
        count_bcp_activate: {
            type: Number,
        },
        count_agreement: {
            type: Number,
        },
        count_matching: {
            type: Number,
        },
        corp_image: {
            type: String,
        },
        bcp_status: {
            type: String,
        },
        replacement_word_list: {
            type: Array,
        },
        matching_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
    .enter {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

    .font-blue{
        color: #2f75b5;
    }

    .img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .member-info-2-title {
        background: #ddebf7;
    }

</style>