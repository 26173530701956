<template>
    
    <div class="row">
        <div class="h5 col-md-8 heading-blue mt-3" style="font-size:14pt">
            モデル協定書ダウンロード
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-dark btn-sm" value="前ページへ戻る" @click="historyBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />


    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md d-flex align-items-center justify-content-start">
            <label for="" class="form-label">あなたの{{ callReplaceWord('組織') }}および交渉先の実情に合わせ、適宜修正してご使用ください。</label>
        </div>
    </div>
    <div class="row ms-3 ">
        <div class="p2 col-md d-flex align-items-center justify-content-start">
            <label for="" class="form-label">なお、利用規約に記載された内容については、災害応援協定書に記載がない場合でも適用されますのでご注意ください。（協定違反による通報事案の公表など）</label>
        </div>
    </div>

    <div class="row mt-5 ms-3 ">
        <div class="p2 col-md-8 d-flex align-items-center justify-content-start">
            <button type="button" class="btn btn-download py-3 px-5" id="災害応援協定書削除" @click="downloadWord">
                <div style="font-size:24pt">
                    <label for="" class="form-label cursor-pointer">
                        モデル災害応援協定書｜Word<br>
                        をダウンロードする<br>                  
                    </label>
                </div>
                <div style="font-size:14pt">
                    <label for="" class="form-label cursor-pointer">
                        神谷慎一弁護士監修
                    </label>
                </div>
            </button>
        </div>
    </div>

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default{
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
        })

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011111');

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const downloadWord = () => {
            const url_print = '/api/downloadWord';
            axios.post(url_print, {
                'kbn': 5,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '【モデル】B＆B災害応援協定.docx';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            });
        }

        const historyBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        return {
            setLevel,
            callReplaceWord,
            downloadWord,
            historyBack,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-download {
    border: 1px solid;
    color: white;
    border-color: #a5a5a5;
    background-color: #a5a5a5;
}

.btn-download:hover {
    border: 1px solid;
    color: #a5a5a5;
    background-color: white;
}

</style>