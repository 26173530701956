import {ref} from 'vue'

export default function() {
    const getReplaceWord = (word, replacement_word_list) => {
        const replaced_word = replacement_word_list.filter((val) => val.読替キー === word)[0];

        if(!replaced_word){
            return word;
        }
        
        if(replaced_word.読替その他){
            return replaced_word.読替その他;
        }else{
            return replaced_word.読替名称;
        }
    }

    return {
        getReplaceWord,
    }
}