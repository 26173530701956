<template>
    <div id="confirm-internal-modal" v-show="modalConfirmInternalVisible" @click.self="closeConfirm">
        <div id="content">
            <div class="btn-close" @click="closeConfirm"></div>
            <div>
                <span class="font-red">{{ modal_title }}</span>
            </div>
            <div>
                <span>の災害が発生したため、<span class="color-resilience-orange">登録利用者全員</span>にBCPを発動しますか？</span>
            </div>
            <div class="mt-3">
                <div class="d-flex align-items-end">
                    <button class="btn btn-lg btn-red" @click="makeBCPMailForInternal" :disabled="isProcessing">BCPを発動する</button>
                    <div class="ms-3"></div>
                    <button class="btn btn-new-window-button" @click="closeConfirm" :disabled="isProcessing">キャンセル</button>
                </div>
            </div>
        </div>
    </div>

    <div id="confirm-external-modal" v-show="modalConfirmExternalVisible" @click.self="closeConfirm">
        <div id="content">
            <div class="btn-close" @click="closeConfirm"></div>
            <div>
                <span class="font-red">{{ modal_title }}</span>
            </div>
            <div>
                <span>の災害が発生したため、<span class="color-resilience-orange">{{ callReplaceWord('取引先') }}・協定先等</span>にBCPを発動しますか？</span>
            </div>
            <div class="mt-3">
                <div class="d-flex align-items-end">
                    <button class="btn btn-lg btn-red" @click="makeBCPMailForExternal" :disabled="isProcessing">BCPを発動する</button>
                    <div class="ms-3"></div>
                    <button class="btn btn-new-window-button" @click="closeConfirm" :disabled="isProcessing">キャンセル</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                BCP発動（Emergency Call／エマージェンシーコール）
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end me-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>


    <div class="row mt-5">
        <span class="font-color-danger">▷　BCP発動（BCP発動訓練を含む）</span>
    </div>
    <div class="row mt-2 mx-3">
        <span class="">□ 各責任者が「災害想定」を選択し発動してください。気象庁連携による自動配信はありません。（安否確認のみ自動配信）</span>
    </div>

    <div class="row mt-5 mx-3">
        <span>災害想定</span>
    </div>
    <div class="mb-3 mx-md-3" v-for="(item, index) in disaster_assumptions_info" :key="item.id">
        <div class="row">
            <div class="col-md-6">
                <div class="d-flex">
                    <div class="py-4 px-3 flex-fill bg-red text-light font-s-half-up">{{ item.disaster_assumption_content }}</div>
                </div>
            </div>
            <div class="col-md-5 d-flex" v-if="is_administrator">
                <button class="border-red btn text-center flex-fill bg-orange rounded-0 font-red" :disabled="isProcessing" @click="showConfirmInternal(item.id, item.disaster_assumption_content)"><span class="font-s-half-up">BCPを発動する</span><br /><span class="notice">登録利用者全員に通知</span></button>
                <button class="border-red btn text-center flex-fill bg-yellow rounded-0 font-red" :disabled="isProcessing" @click="showConfirmExternal(item.id, item.disaster_assumption_content)"><span class="font-s-half-up">BCPを発動する</span><br /><span class="notice">{{ callReplaceWord('取引先') }}・協定先等に通知</span></button>
            </div>
            <div class="col-md-1" v-if="index === 0">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>訓練を行う場合は、「BCP発動ボタン」の前に押してください。<br />なお、訓練による場合は、［BCP運用サイクル▶訓練［計画＆実施］▶訓練計画一覧から実施］により、訓練参加者に配信してから「BCP発動」を行ってください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

    </div>

    <div class="row mt-3 mx-md-3">
        <span>被害想定にない場合記入</span>
    </div>
    <div class="row mt-1 mx-md-3">
        <div class="col-md-6">
            <div class="d-flex">
                <input type="text" class="py-4 px-3 flex-fill bg-red text-light font-s-half-up" placeholder="例：震度7の地震" v-model="freeInputDisaster">
            </div>
        </div>
        <div class="col-md-5 d-flex" v-if="is_administrator">
            <button class="border-red btn text-center flex-fill bg-orange rounded-0 font-red" :disabled="isProcessing || freeInputDisaster.length === 0" @click="showConfirmInternal(0, freeInputDisaster)"><span class="font-s-half-up">BCPを発動する</span><br /><span class="notice">登録利用者全員に通知</span></button>
            <button class="border-red btn text-center flex-fill bg-yellow rounded-0 font-red" :disabled="isProcessing || freeInputDisaster.length === 0" @click="showConfirmExternal(0, freeInputDisaster)"><span class="font-s-half-up">BCPを発動する</span><br /><span class="notice">{{ callReplaceWord('取引先') }}・協定先等に通知</span></button>
        </div>
    </div>
    <div class="row mt-5">
        <span class="font-color-danger">▷　{{ callReplaceWord('従業員') }}連絡先リスト</span>
    </div>
    <div class="mx-2 mx-md-4">
        □ 緊急事態発生時、各責任者が{{ callReplaceWord('従業員') }}と連絡を取ってください。安否確認自動配信があれば、同時に確認してください。
    </div>
    <div class="mt-4">
        <div class="mx-4" v-if="is('administrator')">
            <button class="btn btn-gray px-5 py-3" @click="showEmployeeInfo" :disabled="isProcessing">{{ callReplaceWord('従業員') }}基本情報を開く</button>
        </div>
        <div class="mx-4" v-else>
            <button class="btn btn-gray px-5 py-3" @click="showWarning(2)" :disabled="isProcessing">{{ callReplaceWord('従業員') }}基本情報を開く</button>
        </div>
    </div>
    <div class="mt-3">
        <div class="row">
            <div class="col-10 col-md-4">
                <div class="d-flex flex-column">
                    <span class="font-color-danger">▷　活動チェックと実施内容メモ書き（訓練を含む）</span>
                    <span>□ すべての利用者・{{ callReplaceWord('従業員') }}がアクセスできます。</span>
                </div>
            </div>
            <div class="col-md-2">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label>訓練を行う場合は、「BCP発動ボタン」の前に押してください。<br />なお、訓練による場合は、［BCP運用サイクル▶訓練［計画＆実施］▶訓練計画一覧から実施］により、<br />訓練参加者に配信してから「BCP発動」を行ってください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
            <div class="col-md-6">
                <button class="btn btn-activate-red py-3 px-5" :disabled="isProcessing" @click="showMemoList"><span class="font-s-half-up">メモ一覧を確認する</span></button>
            </div>
        </div>
    </div>
    <div class="mt-5 mx-1 mx-md-4">
        <div class="mt-3">
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-md-3 py-3">◯　二次災害の防止措置</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">事業所からの退避</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">応急手当や初期消火</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">警察・消防への通報</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">重要書類の保護</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="8" v-model="textarea_二次災害の防止措置" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300001)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300001">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　{{ callReplaceWord('従業員') }}の参集</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('経営者') }}の対応</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('従業員') }}の参集</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="3" v-model="textarea_従業員の参集" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300002)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300002">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　安否・被災状況の把握</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('顧客') }}、{{ callReplaceWord('従業員') }}とその家族の安否</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">建屋、{{ callReplaceWord('生産') }}機械、通信機器</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">情報システム</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">地域住民や近隣事業所</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">自然災害、交通やライフライン</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="10" v-model="textarea_安否_被災状況の把握" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300003)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300003">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}への連絡</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">連絡手段の確保</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('顧客') }}への被災状況報告</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('協力会社') }}の被災状況把握</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="6" v-model="textarea_顧客_協力会社への連絡" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300004)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300004">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　中核{{ callReplaceWord('事業') }}の継続方針立案・体制確立</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">中核{{ callReplaceWord('事業') }}のダメージ判断</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">目標復旧時間設定</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">応急・復旧対策方針の決定</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">財務の予測診断</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">実施体制の確立</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">拠点場所の確保</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="12" v-model="textarea_中核事業の継続方針立案_体制確立" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300005)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300005">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　{{ callReplaceWord('顧客') }}・{{ callReplaceWord('協力会社') }}向け対策</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">取引調整（他の{{ callReplaceWord('組織') }}等への一時移管を含む）</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">取引復元</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="3" v-model="textarea_顧客_協力会社向け対策" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300006)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300006">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　{{ callReplaceWord('従業員') }}・{{ callReplaceWord('事業') }}資源対策</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('従業員') }}との情報共有と生活支援</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">建屋の修理・一時移転</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">{{ callReplaceWord('生産') }}機械の修理・調達</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">情報システムの回復</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">供給品の調達</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="10" v-model="textarea_従業員_事業資源対策" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300007)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300007">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　財務対策</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">運転資金の確保</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">決済不渡り対策</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">仕入支払い・給与支払い</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">復旧資金の確保</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="8" v-model="textarea_財務対策" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300008)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300008">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 header">
                    <div class="ps-3 py-3">◯　地域貢献活動</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 px-0 d-flex flex-column">
                    <div class="item">
                        <div class="ps-4 py-3">被災者の救出・応急救護・初期消火</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">商品等の提供</div>
                    </div>
                    <div class="item">
                        <div class="ps-4 py-3">ボランティア活動</div>
                    </div>
                </div>
                <div class="col-md-8 item">
                    <div class="row">
                        <div class="col-md-10">
                            <textarea class="form-control mt-2" rows="6" v-model="textarea_地域貢献活動" placeholder="（緊急時にメモ書き）"></textarea>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-activate-red py-2 px-3 mt-2" @click="registerMemo(30300009)" :disabled="isProcessing || (bcp_title_info == null || bcp_title_info.中級確定日 == null)">登録する</button>
                            <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage_30300009">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-5">
            <div class="d-md-flex flex-row">
                <input type="button" class="btn btn-orange px-4 py-2" value="TOPページ へ" @click="goTop" />
                <input type="button" class="btn btn-bcp-blue mt-3 mt-md-0 ms-md-5 p-2" value="基本｜緊急時におけるBCP発動 へ" v-if="is('administrator')" @click="goBCP" />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111100');
        const isProcessing = ref(false);

        const modalConfirmInternalVisible = ref(false);
        const modalConfirmExternalVisible = ref(false);

        // 確認モーダルの表示
        const viewConfirmInternal = () => {
            modalConfirmInternalVisible.value = true;
        }
        const viewConfirmExternal = () => {
            modalConfirmExternalVisible.value = true;
        }

        // 確認モーダルを閉じる
        const closeConfirm = () => {
            modalConfirmInternalVisible.value = false;
            modalConfirmExternalVisible.value = false;
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showEmployeeInfo = () => {
            const url = '/browse/K12';

            location.href = url;
        }

        const showWarning = (mode) => {
            switch(mode){
                case 1:
                    break;
                case 2:
                    alert(callReplaceWord('従業員') + '基本情報を閲覧できるのは、管理者のみです。');
                    break;
            }
            return false;
        }

        const textarea_二次災害の防止措置 = ref('');
        const textarea_従業員の参集 = ref('');
        const textarea_安否_被災状況の把握 = ref('');
        const textarea_顧客_協力会社への連絡 = ref('');
        const textarea_中核事業の継続方針立案_体制確立 = ref('');
        const textarea_顧客_協力会社向け対策 = ref('');
        const textarea_従業員_事業資源対策 = ref('');
        const textarea_財務対策 = ref('');
        const textarea_地域貢献活動 = ref('');

        const registerMemo = (code) => {
            const url = '/api/bcp/registerMemo';

            let memo_val = '';

            switch(code){
                case 30300001:
                    memo_val = textarea_二次災害の防止措置.value;
                    break;
                case 30300002:
                    memo_val = textarea_従業員の参集.value;
                    break;
                case 30300003:
                    memo_val = textarea_安否_被災状況の把握.value;
                    break;
                case 30300004:
                    memo_val = textarea_顧客_協力会社への連絡.value;
                    break;
                case 30300005:
                    memo_val = textarea_中核事業の継続方針立案_体制確立.value;
                    break;
                case 30300006:
                    memo_val = textarea_顧客_協力会社向け対策.value;
                    break;
                case 30300007:
                    memo_val = textarea_従業員_事業資源対策.value;
                    break;
                case 30300008:
                    memo_val = textarea_財務対策.value;
                    break;
                case 30300009:
                    memo_val = textarea_地域貢献活動.value;
                    break;
                default:
            }

            if(memo_val === ''){
                return false;
            }

            isProcessing.value = true;

            axios.post(url, {
                code: code,
                memo: memo_val,
            }).then(res => {
                switch(code){
                    case 30300001:
                        visibleFlashMessage_30300001('正常に更新されました', 'success');
                        textarea_二次災害の防止措置.value = '';
                        break;
                    case 30300002:
                        visibleFlashMessage_30300002('正常に更新されました', 'success');
                        textarea_従業員の参集.value = '';
                        break;
                    case 30300003:
                        visibleFlashMessage_30300003('正常に更新されました', 'success');
                        textarea_安否_被災状況の把握.value = '';
                        break;
                    case 30300004:
                        visibleFlashMessage_30300004('正常に更新されました', 'success');
                        textarea_顧客_協力会社への連絡.value = '';
                        break;
                    case 30300005:
                        visibleFlashMessage_30300005('正常に更新されました', 'success');
                        textarea_中核事業の継続方針立案_体制確立.value = '';
                        break;
                    case 30300006:
                        visibleFlashMessage_30300006('正常に更新されました', 'success');
                        textarea_顧客_協力会社向け対策.value = '';
                        break;
                    case 30300007:
                        visibleFlashMessage_30300007('正常に更新されました', 'success');
                        textarea_従業員_事業資源対策.value = '';
                        break;
                    case 30300008:
                        visibleFlashMessage_30300008('正常に更新されました', 'success');
                        textarea_財務対策.value = '';
                        break;
                    case 30300009:
                        visibleFlashMessage_30300009('正常に更新されました', 'success');
                        textarea_地域貢献活動.value = '';
                        break;
                    default:
                }

            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const goTop = () => {
            const url = '/home';

            location.href = url;
        }

        const goBCP = () => {
            const url = '/bcp/bcp_first/10';

            location.href = url;
        }

        const flashMessage = ref('');
        const flashMessageStyle = ref('');

        const showFlashMessage_30300001 = ref(false);
        const showFlashMessage_30300002 = ref(false);
        const showFlashMessage_30300003 = ref(false);
        const showFlashMessage_30300004 = ref(false);
        const showFlashMessage_30300005 = ref(false);
        const showFlashMessage_30300006 = ref(false);
        const showFlashMessage_30300007 = ref(false);
        const showFlashMessage_30300008 = ref(false);
        const showFlashMessage_30300009 = ref(false);

        const visibleFlashMessage_30300001 = (msg, kind) => {
            showFlashMessage_30300001.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300001.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300002 = (msg, kind) => {
            showFlashMessage_30300002.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300002.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300003 = (msg, kind) => {
            showFlashMessage_30300003.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300003.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300004 = (msg, kind) => {
            showFlashMessage_30300004.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300004.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300005 = (msg, kind) => {
            showFlashMessage_30300005.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300005.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300006 = (msg, kind) => {
            showFlashMessage_30300006.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300006.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300007 = (msg, kind) => {
            showFlashMessage_30300007.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300007.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300008 = (msg, kind) => {
            showFlashMessage_30300008.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300008.value = false
            }, 3000)
        }
        const visibleFlashMessage_30300009 = (msg, kind) => {
            showFlashMessage_30300009.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage_30300009.value = false
            }, 3000)
        }

        const showMemoList = () => {
            const url = '/bcp/activate/memo_list';

            location.href = url;
        }

        const modal_id = ref(0);
        const modal_title = ref('');

        const showConfirmInternal = (id, title) => {
            modal_id.value = id;
            modal_title.value = title;
            modalConfirmInternalVisible.value = true;
        }

        const showConfirmExternal = (id, title) => {
            modal_id.value = id;
            modal_title.value = title;
            modalConfirmExternalVisible.value = true;
        }

        const makeBCPMailForInternal = () => {
            const url = '/api/bcp/makeBCPMailForInternal';

            isProcessing.value = true;
            axios.post(url, {
                disaster_id: modal_id.value,
                title: modal_title.value,
            }).then(res => {

            }).finally(fin => {
                isProcessing.value = false;
                closeConfirm();
            })
        }

        const makeBCPMailForExternal = () => {
            const url = '/api/bcp/makeBCPMailForExternal';

            isProcessing.value = true;
            axios.post(url, {
                disaster_id: modal_id.value,
                title: modal_title.value,
            }).then(res => {

            }).finally(fin => {
                isProcessing.value = false;
                closeConfirm();
            })
        }

        const freeInputDisaster = ref("");

        return {
            setLevel,
            isProcessing,
            callReplaceWord,
            showBack,

            modalConfirmInternalVisible,
            modalConfirmExternalVisible,

            viewConfirmInternal,
            viewConfirmExternal,
            closeConfirm,

            showEmployeeInfo,
            showWarning,

            textarea_二次災害の防止措置,
            textarea_従業員の参集,
            textarea_安否_被災状況の把握,
            textarea_顧客_協力会社への連絡,
            textarea_中核事業の継続方針立案_体制確立,
            textarea_顧客_協力会社向け対策,
            textarea_従業員_事業資源対策,
            textarea_財務対策,
            textarea_地域貢献活動,

            registerMemo,

            goTop,
            goBCP,

            flashMessage,
            flashMessageStyle,
            showFlashMessage_30300001,
            showFlashMessage_30300002,
            showFlashMessage_30300003,
            showFlashMessage_30300004,
            showFlashMessage_30300005,
            showFlashMessage_30300006,
            showFlashMessage_30300007,
            showFlashMessage_30300008,
            showFlashMessage_30300009,
            visibleFlashMessage_30300001,
            visibleFlashMessage_30300002,
            visibleFlashMessage_30300003,
            visibleFlashMessage_30300004,
            visibleFlashMessage_30300005,
            visibleFlashMessage_30300006,
            visibleFlashMessage_30300007,
            visibleFlashMessage_30300008,
            visibleFlashMessage_30300009,

            showMemoList,

            modal_id,
            modal_title,
            showConfirmInternal,
            showConfirmExternal,

            makeBCPMailForInternal,
            makeBCPMailForExternal,

            freeInputDisaster,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        disaster_assumptions_info: {
            type: Array,
            default: () => [],
        },
        bcp_title_info: {
            type: Object,
        },
        is_administrator: {
            type: Boolean,
            default: false,
        },
    }

}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

#confirm-internal-modal, #confirm-external-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 30%;
    padding: 1em 3em;
    background: #fff;
}

.no-display {
    display: none;
}

.font-color-danger{
    color: #C00000;
}

.bg-red {
    background-color: #a50021;
}

.bg-orange {
    background-color: #ffc197;
}

.bg-yellow {
    background-color: #ffd966;
}

.border-red {
    border: thin solid #c00000;
}

.font-red {
    color: #c00000;
}

.border-button-red{
    border: thin solid #a50021;
}

.btn-activate-red {
    border: thin solid #a50021;
    color: #a50021;
    background-color: white;
}

.btn-activate-red:hover {
    color: white;
    background-color: #a50021;
}

.header {
    border: 1px solid common_colors.colors(resilience-gray);
    background-color: common_colors.colors(resilience-light-gray);
    color: common_colors.colors(resilience-black);
}

.item {
    border: 1px solid common_colors.colors(resilience-gray);
}

.btn-close {
    position: relative;
    top: 0px;
    left: 490px;
}


@media (max-width: 767px) {
    .font-s-half-up {
        font-size: 1.25rem;;
    }

    .notice {
        font-size: 0.75rem;
    }

    #content {
        z-index: 2;
        width: 80%;
        padding: 1em 3em;
        background: #fff;
    }

}

</style>
