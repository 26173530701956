<template>
    <div id="confirm-modal" v-show="availableStatusModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeAvailableStatusModal"></div>
            <div class="row mt-3 ms-auto">
                <table class="table table-bordered border-dark">
                    <tbody>
                        <tr>
                            <td class="bg-light-gray" colspan="2">電気</td>
                            <td class="bg-white" style="width: 120px;">
                                {{ availableStatusInfo.electricity }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">通信（固定電話、携帯電話、インターネット、社内イントラネット等）</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.communication }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">ガス</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.gas }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">上下水道</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.waterworks }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">交通機関（高速道路、国道、鉄道等）</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.trafficMethod }}
                             </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">小売業（金融機関、スーパー等）</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.retailBusiness }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray" colspan="2">地域住民等</td>
                            <td class="bg-white">
                                {{ availableStatusInfo.regionalResident }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray">その他</td>
                            <td class="bg-white" style="width: 400px;">
                                {{ availableStatusInfo.otherInputValue }}
                            </td>
                            <td class="bg-white">
                                {{ availableStatusInfo.other }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-3 ms-auto align-items-center justify-content-center py-2">
                <button class="col-md-5 btn btn-gray " @click="closeAvailableStatusModal">閉じる</button>
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="participantModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeParticipantModal"></div>
            <div class="row mt-3 ms-auto">
                <template v-if="participantInfo.length > 0">
                    <div class="col-md align-items-center justify-content-start">
                        <template v-for="participant, index in participantInfo" :key="index">
                            <div class="align-items-center justify-content-start d-flex">
                                <div class="col-md border border-dark bg-white text-dark d-flex align-items-center justify-content-center py-2"
                                    :class="{'border-bottom-0': index !== participantInfo.length - 1}"
                                >
                                    {{ participant.participant }}
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md ms-auto d-flex align-items-center justify-content-center py-2">
                        参加者がいません
                    </div>
                </template>
            </div>
            <div class="row mt-3 ms-auto align-items-center justify-content-center py-2">
                <button class="col-md-5 btn btn-gray " @click="closeParticipantModal">閉じる</button>
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="deleteModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeDeleteModal"></div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-10 align-items-start">
                    登録一覧から、<br>
                 <div class="color-resilience-light-blue">{{ selectedTrainingInfo.trainingName }}</div>
                    を削除します。よろしいですか？（復元不可）
                </div>
            </div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-3 d-flex align-items-start">
                    <button class="btn btn-bcp-blue " :disabled="isProcessing" @click="deleteTrainingPlan">はい</button>
                </div>
                <div class="col-md-3 d-flex align-items-start">
                    <button class="btn btn-gray " :disabled="isProcessing" @click="closeDeleteModal">キャンセル</button>
                </div>
            </div>
            <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
        </div>
    </div>

    <div id="confirm-modal" v-show="setMailModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeSetMailModal"></div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-10 align-items-start">
                    登録一覧から、
                    <div class="color-resilience-light-blue">
                        {{ selectedTrainingInfo.trainingName }}
                    </div>
                    を実施します。実施日時をご指定ください。
                </div>
            </div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-4 py-2">
                    <input type="date" class="form-control" v-model="implementationDate" @change="getWeekDay">
                </div>
                <div class="col-md-2 py-2">
                    {{ dispWeekDay }}
                </div>
                <div class="col-md-3 py-2">
                    <input type="time" min="00:00:00" class="form-control" v-model="implementationTime" @change="inputDateTime">
                </div>
                <div class="col-md-1 py-2">
                    ～
                </div>
            </div>
            <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-md-5 d-flex align-items-start">
                    <button class="btn btn-green" :disabled="isProcessing || isNotInput" @click="deliveryMail">参加者へ今すぐ配信する</button>
                </div>
                <div class="col-md-3 d-flex align-items-start">
                    <button class="btn btn-gray " :disabled="isProcessing" @click="closeSetMailModal">キャンセル</button>
                </div>
            </div>
            <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                {{ flashMessage }}
            </div>
        </div>
    </div>

    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            訓練計画一覧から実施
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row">
        <div class="row ms-3 ">
            <template v-for="planInfo, index in trainingPlanInfo" :key="index">
                <div class="d-flex col-md-10">
                    <table class="table table-bordered border-dark col-md-11">
                        <thead v-if="index === 0">
                            <tr>
                                <td class="bg-light-gray text-dark" style="width: 3%;">No</td>
                                <td class="bg-light-gray text-dark" colspan="3" style="width: 45%;">訓練シナリオの名称・テーマ｜訓練の目的</td>
                                <td class="bg-light-gray text-dark" colspan="5">災害想定｜影響を受ける中核事業・重要業務</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-items-center" rowspan="4" style="width: 3%;">
                                    {{ index + 1 }}
                                </td>
                                <td class="" colspan="3"  style="width: 45%;">
                                    {{ planInfo.trainingName }}
                                </td>
                                <td class="bg-disaster text-white" colspan="5">
                                    {{ planInfo.disasterAssumption }}
                                </td>
                            </tr>
                            <tr>
                                <td class="" colspan="3">
                                    {{ planInfo.trainingTarget }}
                                </td>
                                <td class="bg-blue text-white" colspan="2">
                                    {{ planInfo.coreBusiness }}
                                </td>
                                <td class="bg-orange text-white" colspan="3">
                                    {{ planInfo.importantBusiness }}
                                </td>
                            </tr>
                            <tr>
                                <td class="bg-light-gray">訓練の方法</td>
                                <td class="bg-light-gray" style="width: 8%;">発生曜日</td>
                                <td class="bg-light-gray" style="width: 8%;">発生時間</td>
                                <td class="bg-light-gray" style="width: 11%;">経過想定日数</td>
                                <td class="bg-light-gray">被害場所</td>
                                <td class="bg-light-gray" style="width: 10%;">災害イメージ</td>
                                <td class="bg-light-gray" style="width: 10%;">参加者｜{{ callReplaceWord('自社') }}</td>
                                <td class="bg-light-gray" style="width: 10%;">参加者｜外部</td>
                            </tr>
                            <tr>
                                <td class="">
                                    <template v-if="trainingMethodList.find(data => data.id == planInfo.trainingMethod)">
                                        <template v-if="trainingMethodList.find(data => data.id == planInfo.trainingMethod).id == trainingMethodList[7].id">
                                            {{ trainingMethodList.find(data => data.id == planInfo.trainingMethod).methodName }} ：
                                            {{ planInfo.trainingMethodOther }}
                                        </template>
                                        <template v-else>
                                            {{ trainingMethodList.find(data => data.id == planInfo.trainingMethod).methodName }}
                                        </template>
                                    </template>
                                </td>
                                <td class="">
                                    <template v-if="weekdayList.find(data => data.id == planInfo.occurrenceWeekday)">
                                        {{ weekdayList.find(data => data.id == planInfo.occurrenceWeekday).weekday }}
                                    </template>
                                </td>
                                <td class="">
                                    {{ planInfo.occurrenceTime }}
                                </td>
                                <td class="">
                                    <template v-if="assumptionDaysList.find(data => data.id == planInfo.progressAssumptionDays)">
                                        {{ assumptionDaysList.find(data => data.id == planInfo.progressAssumptionDays).assumptionDays }}
                                    </template>
                                </td>
                                <td class="">
                                    {{ planInfo.damage }}
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-green" value="利用可能状況" style="font-size:10.5pt" @click="showAvailableStatusModal(planInfo)">
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-green" value="参加者一覧" style="font-size:10.5pt" @click="showParticipantModal(planInfo, ownCompanyDepartmentType)">
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-green" value="参加者一覧" style="font-size:10.5pt" @click="showParticipantModal(planInfo, externalOrganizationType)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-md-1 ms-5">
                        <div class="row">
                            <button class="col d-flex btn-gray btn-sm border border-white justify-content-center align-items-center py-2"
                                style="font-size:10.5pt" @click="showTargetTrainingPlan(planInfo.trainingPlanId)"
                            >
                                編集
                            </button>
                        </div>
                        <div class="row">
                            <button class="col d-flex btn-gray btn-sm border border-white justify-content-center align-items-center py-2"
                                style="font-size:10.5pt" @click="showDeleteModal(planInfo)"
                            >
                                削除
                            </button>
                        </div>
                        <div class="row">
                            <button class="col d-flex btn-green btn-sm border border-white justify-content-center align-items-center py-2"
                                style="font-size:10.5pt" @click="showSetMailModal(planInfo)"
                            >
                                日時を決めて<br>今すぐ配信
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-2 d-flex align-items-start">
            <input type="button" class="btn btn-bcp-blue" value="新規作成" style="font-size:10.5pt" :disabled="isProcessing" @click="showRegister">
        </div>
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.training_plan_list).length > 0) {
                Object.values(props.training_plan_list).forEach((fetchData, index) => {
                    // 訓練計画
                    trainingPlanInfo.value.push({
                        trainingPlanId: fetchData.training_plan_id,
                        trainingName: fetchData.training_name,
                        trainingTarget: fetchData.training_target,
                        trainingMethod: fetchData.training_method,
                        trainingMethodOther: fetchData.training_method_other,
                        disasterAssumption: fetchData.disaster_assumption,
                        coreBusiness: fetchData.core_business,
                        importantBusiness: fetchData.important_business,
                        occurrenceWeekday: fetchData.occurrence_weekday,
                        occurrenceTime: fetchData.occurrence_time ? fetchData.occurrence_time.substring(0, 5) : fetchData.occurrence_time,
                        progressAssumptionDays: fetchData.progress_assumption_days,
                        damage: fetchData.damage,
                        electricity: fetchData.electricity,
                        communication: fetchData.communication,
                        gas: fetchData.gas,
                        waterworks: fetchData.waterworks,
                        trafficMethod: fetchData.traffic_method,
                        retailBusiness: fetchData.retail_business,
                        regionalResident: fetchData.regional_resident,
                        other: fetchData.other,
                        otherInputValue: fetchData.other_input_value,
                        ownCompanyDepartment: [],
                        externalOrganization: [],

                    });

                    // 自社部署
                    if (typeof fetchData.own_company_department !== 'undefined') {
                        trainingPlanInfo.value[index].ownCompanyDepartment = Object.values(fetchData.own_company_department).map(
                            fetchDepartment => ({
                                participantType: fetchDepartment.own_company_department,
                                participant: fetchDepartment.participant,
                            })
                        );
                    }
                    // 外部組織
                    if (typeof fetchData.external_organization !== 'undefined') {
                        trainingPlanInfo.value[index].externalOrganization = Object.values(fetchData.external_organization).map(
                            fetchExternal => ({
                                participantType: fetchData.external_organization,
                                participant: fetchExternal.participant,
                            })
                        );
                    }
                })
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001111');
        const isProcessing = ref(false);
        const isNotInput = ref(true);
        const ownCompanyDepartmentType = '1';
        const externalOrganizationType = '2';
        const selectedTrainingInfo = ref({});
        const dispWeekDay = ref('');
        const implementationDate = ref('');
        const implementationTime = ref('');

        const availableStatusModalVisible = ref(false);
        const availableStatusInfo = ref({});
        const participantModalVisible = ref(false);
        const deleteModalVisible = ref(false);
        const setMailModalVisible = ref(false);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');
        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false;
                window.location.reload();
                isProcessing.value = false;
            }, 3000)
        }

        const trainingPlanInfo = ref([]);
        const participantInfo = ref([]);
        for (let i = 0; i < 6; i++) {
            participantInfo.value.push({
                participant: '',
            });
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 訓練の方法
        const trainingMethodList = [
            {id: 1, methodName: '机上訓練（模擬災害対策訓練）'},
            {id: 2, methodName: 'バックアップデータを取り出す訓練'},
            {id: 3, methodName: '机上訓練（ワークショップ訓練）'},
            {id: 4, methodName: 'BCP手順確認訓練'},
            {id: 5, methodName: '机上訓練（ロールプレイング訓練）'},
            {id: 6, methodName: 'BCP全体を通して行う総合訓練'},
            {id: 7, methodName: '安否確認システム操作訓練・緊急時通報診断'},
            {id: 8, methodName: 'その他'},
            {id: 9, methodName: '代替施設への移動訓練'},
        ]

        // 曜日
        const weekdayList = [
            {id: 0, weekday: '日曜日'},
            {id: 1, weekday: '月曜日'},
            {id: 2, weekday: '火曜日'},
            {id: 3, weekday: '水曜日'},
            {id: 4, weekday: '木曜日'},
            {id: 5, weekday: '金曜日'},
            {id: 6, weekday: '土曜日'},
        ]

        // 経過想定日数
        const assumptionDaysList = [
            {id: 1, assumptionDays: '発災初日'},
            {id: 2, assumptionDays: '発災後4～5日目'},
        ]

        // 利用可能状況
        const availableStatusList = [
            {id: 1, availableStatus: '使える＝◯'},
            {id: 0, availableStatus: '使えない＝✕'},
        ]

        // 利用可能状況モーダル
        const showAvailableStatusModal = planInfo => {
            availableStatusInfo.value = {
                electricity: availableStatusList.find(data => data.id == planInfo.electricity)
                    ? availableStatusList.find(data => data.id == planInfo.electricity).availableStatus
                    : '',
                communication: availableStatusList.find(data => data.id == planInfo.communication)
                    ? availableStatusList.find(data => data.id == planInfo.communication).availableStatus
                    : '',
                gas: availableStatusList.find(data => data.id == planInfo.gas)
                    ? availableStatusList.find(data => data.id == planInfo.gas).availableStatus
                    : '',
                waterworks: availableStatusList.find(data => data.id == planInfo.waterworks)
                    ? availableStatusList.find(data => data.id == planInfo.waterworks).availableStatus
                    : '',
                trafficMethod: availableStatusList.find(data => data.id == planInfo.trafficMethod)
                    ? availableStatusList.find(data => data.id == planInfo.trafficMethod).availableStatus
                    : '',
                retailBusiness: availableStatusList.find(data => data.id == planInfo.retailBusiness)
                    ? availableStatusList.find(data => data.id == planInfo.retailBusiness).availableStatus
                    : '',
                regionalResident: availableStatusList.find(data => data.id == planInfo.regionalResident)
                    ? availableStatusList.find(data => data.id == planInfo.regionalResident).availableStatus
                    : '',
                other: availableStatusList.find(data => data.id == planInfo.other)
                    ?  availableStatusList.find(data => data.id == planInfo.other).availableStatus
                    : '',
                otherInputValue: planInfo.otherInputValue,
            }
            availableStatusModalVisible.value = true;
        }

        // 参加者モーダル
        const showParticipantModal = (planInfo, participantType) => {
            participantInfo.value.splice(0);
            if (participantType === ownCompanyDepartmentType) {
                // 自社部署
                participantInfo.value = planInfo.ownCompanyDepartment.map(
                    fetchData => ({
                        participant: fetchData.participant
                    })
                );
            } else {
                // 外部組織
                participantInfo.value = planInfo.externalOrganization.map(
                    fetchData => ({
                        participant: fetchData.participant
                    })
                );
            }
            participantModalVisible.value = true;
        }

        // 削除モーダル
        const showDeleteModal = planInfo => {
            selectedTrainingInfo.value = {
                trainingPlanId: planInfo.trainingPlanId,
                trainingName: planInfo.trainingName,
            }
            deleteModalVisible.value = true;
        }

        // メール送信モーダル
        const showSetMailModal = planInfo => {
            // 表示初期化
            implementationDate.value = '';
            implementationTime.value = '';
            dispWeekDay.value = '';

            selectedTrainingInfo.value = {
                trainingPlanId: planInfo.trainingPlanId,
                trainingName: planInfo.trainingName,
            }
            setMailModalVisible.value = true;
        }

        const closeAvailableStatusModal = () => {
            availableStatusModalVisible.value = false;
        }

        const closeParticipantModal = () => {
            participantModalVisible.value = false;
        }

        const closeDeleteModal = () => {
            deleteModalVisible.value = false;
        }

        const closeSetMailModal = () => {
            setMailModalVisible.value = false;
            isNotInput.value = true;
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showRegister = () => {
            const url = '/fixation/b1';
            location.href = url;
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        const showTargetTrainingPlan = (targetTag) => {
            const url = `/fixation/b1#list-${targetTag}`;
            // 選択された訓練計画にスクロール
            location.href = url;
        }

        const getWeekDay = e => {
            const dObj = new Date(e.target.value);
            const wDay = dObj.getDay();
            dispWeekDay.value = weekdayList[wDay].weekday
            inputDateTime();
        }

        // 実施日時入力判定
        const inputDateTime = () => {
            if (implementationDate.value && implementationTime.value) {
                isNotInput.value = false;
            } else {
                isNotInput.value = true;
            }
        }

        // 訓練計画削除
        const deleteTrainingPlan = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/deleteTrainingPlan';

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'selected_training_plan_id': selectedTrainingInfo.value.trainingPlanId,
            }).then(res => {
                visibleFlashMessage('正常に削除されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                // isProcessing.value = false;
            });
        }

        // メール配信
        const deliveryMail = async() => {
            isProcessing.value = true;
            const url = '/api/fixation/sendTrainingMail';

            return await axios.post(url, {
                'corp_id': props.corp_info.id,
                'training_plan_id': selectedTrainingInfo.value.trainingPlanId,
                'implementation_date': implementationDate.value,
                'implementation_time': implementationTime.value,
            }).then(res => {
                visibleFlashMessage('送信しました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('送信に失敗しました', 'danger');
                return err.response?.status;
            })
            .finally(fin => {
                // isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            isNotInput,
            ownCompanyDepartmentType,
            externalOrganizationType,
            selectedTrainingInfo,
            dispWeekDay,
            implementationDate,
            implementationTime,
            trainingPlanInfo,
            participantInfo,
            availableStatusModalVisible,
            availableStatusInfo,
            participantModalVisible,
            deleteModalVisible,
            setMailModalVisible,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            callReplaceWord,
            trainingMethodList,
            weekdayList,
            assumptionDaysList,
            availableStatusList,
            showAvailableStatusModal,
            showParticipantModal,
            showDeleteModal,
            showSetMailModal,
            closeAvailableStatusModal,
            closeParticipantModal,
            closeDeleteModal,
            closeSetMailModal,
            showBack,
            showRegister,
            showTop,
            showTargetTrainingPlan,
            getWeekDay,
            inputDateTime,
            deleteTrainingPlan,
            deliveryMail,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        training_plan_list: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-disaster {
    background-color: #c00000;
    color: white;
    height: 39.5px;
}
.bg-blue {
    background-color: #4472c4;
    color: white;
    height: 39.5px;
}

.bg-orange {
  background-color: #FF6600;
  height: 39.5px;
}

.bg-light-gray {
    height: 39.5px;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 40%;
    padding: 1em 3em;
    background: #F2F2F2;
}

.btn-close {
    position: relative;
    top: 0px;
    left: 680px;
}

.btn-close:hover{
    cursor: pointer;
}

</style>
