<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>会社名</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="corp in corps" :key="corp.key">
                                    <td><div class="btn-group"><button type="button" class="btn btn-info" @click="openCorpInfo(corp.id)">{{ corp.id }}</button><div v-if="corp.deleted_at !== null"><span class="badge bg-dark">削除</span></div></div></td>
                                    <td>{{ corp.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <input type="button" @click="openCreateCorp()" value="新規作成" class="btn btn-primary" >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    setup() {
        
    },
    props: [
        "corps",
    ],
    methods: {
        openCorpInfo(corp_id){
            const url = '/corps/show/' + corp_id;
            let newWindow = window.open(url, '_blank');
            // let self = this;

            // let loop = setInterval(function() {
            //     if(newWindow.closed){
            //         clearInterval(loop);
            //         self.searchCustomerList(self.current_page);
            //     }
            // });
        },
        openCreateCorp() {
            const url = '/corps/create';
            let newWindow = window.open(url, '_blank');
            let self = this;

            let loop = setInterval(function() {
                if(newWindow.closed){
                    clearInterval(loop);
                    // self.searchCustomerList(self.current_page);
                }
            });
        },
        searchCorpList() {
            const url = '/api/getCorpLists';
            axios.get(url).then(function(response){
                if(response.status === 200) {
                    this.corps = response.data.corps;
                }
            });
        }
    },
}
</script>