<template>
    <div id="confirm-modal" v-show="participantModalVisible">
        <div id="content">
            <div class="btn-close" @click="closeParticipantModal"></div>
            <div class="row mt-3 ms-auto">
                <template v-if="participantInfo.length > 0">
                    <div class="col-md align-items-center justify-content-start">
                        <template v-for="participant, index in participantInfo" :key="index">
                            <div class="align-items-center justify-content-start d-flex">
                                <div class="col-md border border-dark bg-white text-dark d-flex align-items-center justify-content-center py-2"
                                    :class="{'border-bottom-0': index !== participantInfo.length - 1}"
                                >
                                    {{ participant.participant }}
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md ms-auto d-flex align-items-center justify-content-center py-2">
                        参加者がいません
                    </div>
                </template>
            </div>
            <div class="row mt-3 ms-auto align-items-center justify-content-center py-2">
                <button class="col-md-5 btn btn-gray btn-sm" @click="closeParticipantModal">閉じる</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                教育実施記録の閲覧
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end me-md-5 ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <div class="mt-3">
        <div class="row ms-md-3 ">
            <template v-for="implementation, index in implementationRecordInfo" :key="index">
                <div class="d-flex col-md-11">
                    <table class="table table-bordered border-dark col-md-11 fs-half">
                        <thead>
                            <tr>
                                <td class="bg-light-gray text-dark">No</td>
                                <td class="bg-light-gray text-dark">実施日</td>
                                <td class="bg-light-gray text-dark">教育計画のテーマ・目的</td>
                                <td class="bg-light-gray text-dark">教育の方法</td>
                                <td class="bg-light-gray text-dark">記録内容詳細</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-items-center">
                                    {{ index + 1 }}
                                </td>
                                <td>{{ this.$root.dayjs(implementation.implementationDate).format('YYYY/MM/DD') }}</td>
                                <td class="">
                                    {{ implementation.theme }}
                                </td>
                                <td class="">
                                    <template v-if="educationMethodList.find(data => data.id == implementation.educationMethod)">
                                        <template v-if="educationMethodList.find(data => data.id == implementation.educationMethod).id == educationMethodList[4].id">
                                            {{ educationMethodList.find(data => data.id == implementation.educationMethod).methodName }} ：
                                            {{ implementation.educationMethodOther }}
                                        </template>
                                        <template v-else>
                                            {{ educationMethodList.find(data => data.id == implementation.educationMethod).methodName }}
                                        </template>
                                    </template>
                                </td>
                                <td class="">
                                    <input type="button" class="btn btn-sm btn-resilience-light-blue btn-responsive" value="教育実施記録" @click="showDetail(implementation.id)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" @click="showTop">
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.implementation_record_list).length > 0) {

                // 登録降順に並び替え
                const sortedKeys = Object.values(props.implementation_record_list).sort((a, b) => {
                    return a.implementation_record_id > b.implementation_record_id ? -1 : 1;
                });

                // 訓練計画実施情報
                sortedKeys.forEach((fetchData, index) => {
                    implementationRecordInfo.value.push({
                        id: fetchData.implementation_record_id,
                        theme: fetchData.theme,
                        educationMethod: fetchData.education_method,
                        educationMethodOther: fetchData.education_method_other,
                        implementationDate: fetchData.implementation_date,
                        participants: [],
                    });
                    // 参加者
                    if (typeof fetchData.participants !== 'undefined') {
                        implementationRecordInfo.value[index].participants = Object.values(fetchData.participants).map(
                            fetchParticipant => ({
                                participant: fetchParticipant.participant,
                            })
                        );
                    }
                })
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011111');
        const participantModalVisible = ref(false);

        // 教育計画実施情報
        const implementationRecordInfo = ref([]);

        // 参加者情報
        const participantInfo = ref([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        // 教育の方法
        const educationMethodList = [
            {id: 1, methodName: '社内ディスカッション（BCPの周知・防災）'},
            {id: 2, methodName: '防災に関する勉強会への参加'},
            {id: 3, methodName: '応急救護の受講（心肺蘇生法等）'},
            {id: 4, methodName: 'BCPや防災対策関連のセミナーへの参加'},
            {id: 5, methodName: 'その他'},
        ]

        // 参加者モーダル
        const showParticipantModal = implementation => {
            participantInfo.value.splice(0);
            participantInfo.value = implementation.participants.map(
                fetchData => ({
                    participant: fetchData.participant
                })
            );
            participantModalVisible.value = true;
        }

        const closeParticipantModal = () => {
            participantModalVisible.value = false;
        }

        const showBack = () => {
            // 前のページへ遷移
            history.back();
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        const showDetail = (id) => {
            const url = '/fixation/showParticipantDetail/' + id;

            location.href = url;
        }

        return {
            setLevel,
            participantModalVisible,
            participantInfo,
            implementationRecordInfo,
            callReplaceWord,
            educationMethodList,
            showParticipantModal,
            closeParticipantModal,
            showBack,
            showTop,
            showDetail,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        implementation_record_list: {
            type: Object,
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-light-gray {
    height: 39.5px;
}

#confirm-modal{
    /*　要素を重ねた時の順番　*/
    z-index: 1;

    /*　画面全体を覆う設定　*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 2;
    width: 40%;
    padding: 1em 3em;
    background: #F2F2F2;
}

.btn-close {
    position: relative;
    top: 0px;
    left: 680px;
}

.btn-close:hover{
    cursor: pointer;
}

.btn-resilience-light-blue {
    background-color: common_colors.colors(resilience-light-blue);
    color: white;
    border: 1px solid common_colors.colors(resilience-light-blue);
}

.btn-resilience-light-blue:hover {
    background-color: white;
    color: common_colors.colors(resilience-light-blue);
    border: 1px solid common_colors.colors(resilience-light-blue);
}

table thead tr td:nth-child(1) {
    width: 5%;
}

table thead tr td:nth-child(2),
table thead tr td:nth-child(5) {
    width: 10%;
}

table thead tr td:nth-child(3) {
    width: 40%;
}

@media screen and (max-width: 756px){
    .fs-half {
        font-size: 0.5em;
    }

    .btn-responsive {
        font-size: 0.5em;
    }

    table thead tr td:nth-child(1) {
        width: 5%;
    }

    table thead tr td:nth-child(2),
    table thead tr td:nth-child(5) {
        width: 10%;
    }

    table thead tr td:nth-child(3) {
        width: 30%;
    }

}

</style>
