<template>
    <div>
        <div class="row mt-3">
            <div class="heading mt-3">管理者専用ページ</div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="内部情報管理" @click="showInternalInfo" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="企業ID・パスワード生成" @click="showRegisterCorp" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="団体会員・賛助会員の管理" @click="showGroupAndSupportingManage" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="お知らせ・更新履歴" @click="showNoticeHistory" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="「BCP用語」表示編集" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="「ヒント」表示編集" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="ドロップダウンリスト編集" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="メール自動送信案内文編集" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="チャットボットシナリオ" />
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="有人チャット予約管理" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="クーポンコード発行" @click="issueCoupon" />
            </div>
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="会員企業CSVダウンロード" @click="corpInfo" />
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-4">
                <input type="button" class="btn btn-black w-100 px-5" value="支払状況確認" @click="showPaymentStatus" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    setup() {
        const showInternalInfo = () => {
            const url = '/s-admin/internal_info';

            location.href = url;
        }

        const showRegisterCorp = () => {
            const url = '/s-admin/registerCorp';

            location.href = url;
        }
    
        const showGroupAndSupportingManage = () => {
            const url = '/s-admin/manage_group_supporting';

            location.href = url;
        }

        const showNoticeHistory = () => {
            const url = '/s-admin/noticeHistoryLists';

            location.href = url;
        }

        const showPaymentStatus = () => {
            const url = '/s-admin/paymentStatus';

            location.href = url;
        }

        const issueCoupon = () => {
            const url = '/s-admin/issueCoupon';

            location.href = url;
        }

        const corpInfo = () => {
            const url = '/s-admin/corpInfo';

            location.href = url;
        }

        return {
            showInternalInfo,
            showRegisterCorp,
            showGroupAndSupportingManage,
            showNoticeHistory,
            showPaymentStatus,
            issueCoupon,
            corpInfo,
        }
    },
}
</script>
