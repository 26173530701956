<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　BCPの運用
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black" value="前ページへ戻る" @click="showBack" :disabled="isProcessing">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2 font-orange">
        BCPの定着　―――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
            BCPは、策定して終わりではありません。緊急事態になった時に{{callReplaceWord('従業員')}}がBCPを有効に活用し、適切な対応ができるよう準備しておくことではじめて意味を成します。<br>
            そのため、BCPを策定した後は、{{callReplaceWord('従業員')}}にBCPの内容やその重要性を理解してもらうために、{{callReplaceWord('社内')}}における教育活動を実施することが重要となります。<br>
            教育計画では、誰が、いつ、何を行うのかを入力してください。
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>
                                BCPを考えることは、「災害時の事業継続」だけの話ではなく、日常の業務を見直すことそのものです。<br>
                                BCPを通じて事業を見直すことで、事業のボトルネックや弱点、長所などが見える化され、自然災害以外の危機（{{callReplaceWord('取引先')}}の倒産など）でも無理なく対応できるようになります。
                                BCPの定着とは、BCPを通じて、日常の業務の見直し、特性の見える化と改善を行うことを意味しています。
                                BCP策定後、{{callReplaceWord('従業員')}}にBCPの内容や重要性を理解してもらうために、{{callReplaceWord('社内')}}教育活動を実施することが重要となります。<br>
                                少なくとも年1回以上は{{callReplaceWord('経営者')}}が{{callReplaceWord('従業員')}}に対してBCPの進捗状況や問題点を説明するとともに、各自の取組状況、役割分担を定期的に確認することをお勧めします。</label>
                        </div>
                    </template>
            </VDropdown>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-5 border bg-sub-title d-flex align-items-center justify-content-start py-2">
            ◯　教育計画
        </div>
    </div>
    <div v-for="(form, formIndex) in valueForms" :key="formIndex">

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-10 border bg-one-set border-dark border-bottom-0 d-flex align-items-center justify-content-center py-2">
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-4 border bg-one-set border-dark border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start py-2">
                誰がやりますか？
            </div>
            <div class="p2 col-md-1 bg-one-set d-flex align-items-center justify-content-center py-2" >
            </div>
            <div class="p2 col-md-4 bg-one-set d-flex align-items-center justify-content-start py-2">
                いつやりますか？（どのくらいの頻度で？）
            </div>
            <div class="p2 col-md-1 border bg-one-set border-dark border-start-0 border-top-0 border-bottom-0 d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex === 0">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>
                                【教育活動の例】<br>
                                ■業務の内容について、定期的に状況把握を行う（クリティカルパスになっている事象は前回と同様か、など）<br>
                                ■毎年1回以上、{{callReplaceWord('経営者')}}が{{callReplaceWord('従業員')}}に対してBCPの進捗状況や問題点を説明する<br>
                                ■{{callReplaceWord('従業員')}}各自がBCPの取組状況、役割分担の定期的な確認を行う<br>
                                ■策定したBCPのポイントに関する{{callReplaceWord('社内')}}研修会を開催する<br>
                                ■BCPの内容等に関する{{callReplaceWord('社内')}}掲示を実施する
                            </label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-4 border bg-one-set border-dark border-top-0 border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                <select name="selectAdminCandidate" class="form-select" v-model="form.values[0].value">
                    <option type="text" v-for="adminCandidate in admin_candidate_list" :value="adminCandidate.氏名" >{{ adminCandidate.氏名 }}</option>
                </select>
            </div>

            <div class="p2 col-md-1 bg-one-set d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 col-md-4 bg-one-set d-flex align-items-center justify-content-start py-2">
                <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder=""
                v-model="form.values[1].value">
            </div>
            <div class="p2 col-md-1 border bg-one-set border-dark border-start-0 border-top-0 border-bottom-0 d-flex align-items-center justify-content-center py-2">
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex !== 0">
                <input type="button" class="btn btn-delete" value="削除" @click="deleteLine(formIndex)">
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-10 border bg-one-set border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start py-2">
                何をやりますか？
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-10 border bg-one-set border-dark border-top-0 border-bottom-0 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control d-flex align-items-center justify-content-start py-2" placeholder=""
                v-model="form.values[2].value">
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex === valueForms.length - 1">
                <input type="button" class="btn btn-black" value="ワンセット増やす" @click="addLine">
            </div>
        </div>

        <div class="row ms-3">
            <div class="p2 col-md-10 border bg-one-set border-dark border-top-0 d-flex align-items-center justify-content-center py-2">
                &emsp;
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div id="a" class="p2 col-md d-flex align-items-center justify-content-start py-2 font-orange">
        BCPの見直し　――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――――
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 col-md d-flex align-items-center justify-content-start py-2">
            常に BCPの内容をあなたの{{callReplaceWord('組織')}}の現状に見合ったものとしておくために、必要に応じBCPの見直しを行うことが重要となります。BCPの見直しは、日頃からあなたの{{callReplaceWord('組織')}}が実施している{{callReplaceWord('経営')}}管理の延長にあるものとして、随時確認するようにしましょう。
        </div>
        <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start">
            <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>
                                BCPの内容は現状に見合ったものとしておくために、必要に応じ 見直しを行うことが重要です。<br>
                                経営管理の延長にあるものと考え、見直す基準を検討してください。例えば、災害シナリオ（ある想定災害）について、どのような状況が起きるかのイメージを{{callReplaceWord('従業員')}}全員で共有し、BCPでそれに対してどこまで対応できるか、どこが対応できないかを洗い出すなど、BCPの実効性について検証、見直しましょう。これは、訓練（図上を含む）を通じて検証すると効果的です。
                            </label>
                        </div>
                    </template>
                </VDropdown>
        </div>
    </div>

    <div id="a" class="row ms-3 mt-5">
        <div class="p2 col-md-5 bg-sub-title d-flex align-items-center justify-content-start py-2">
            ◯　BCPを見直す基準
        </div>
    </div>

    <div v-for="(form, formIndex) in textForms" :key="formIndex">
        <div class="row ms-3 mt-3">
            <div class="row col-md-10">
                <textarea type="text" rows="3" class="form-control d-flex align-items-center justify-content-center py-2"
                    placeholder="" v-model="form.value"></textarea>
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex == 0">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>
                                {{callReplaceWord('顧客')}}状況や在庫状況等に大幅な変更があった場合、{{callReplaceWord('商品')}}・サービスの変更・追加、生産ラインの組み替え、人事異動等があった場合は、BCPの見直しを行う必要があるか検討し、その必要があればBCPに反映します。
                                その他、以下のような状況もBCPの見直しを行うか検討し、必要に応じてBCPに反映します。<br>
                                ■定期的に実施する事業内容の確認の中で、クリティカルパス（代替できない{{callReplaceWord('取引先')}}など）の状況に変化があったり、その対応方策の選択肢が変化したとき<br>
                                ■被災シナリオに基づくBCPの実効性確認（訓練など）の結果、対応できない事象が明らかになったとき<br>
                                また、事前対策の進捗状況や問題点をチェックし、必要に応じてBCPを見直すこともお勧めします。
                            </label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-introduction" :value="confirm_button_label" @click="showIntroductionComplete" :disabled="isProcessing">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-introduction" value="目次構成 へ戻る" @click="showContents" :disabled="isProcessing">
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(async() => {
            // 取得データをセット
            let isExistValueForm = false;
            if(props.bcp_introduction_values_info.length > 0) {
                await props.bcp_introduction_values_info.forEach(data => {
                    if(
                        columnNameRegex.test(data.column_name)
                        && parseInt(data.column_name.substring(1)) >= 56
                        && !valueForms.value.some(val => val.columnName == data.column_name)
                    ) {
                        // 同一カラムのvalue値を取得
                        const findDataSeq1 = props.bcp_introduction_values_info.find(fetchData => {
                            return fetchData.column_name === data.column_name && fetchData.seq === 1
                        });
                        // 同一カラムのvalue値を取得
                        const findDataSeq2 = props.bcp_introduction_values_info.find(fetchData => {
                            return fetchData.column_name === data.column_name && fetchData.seq === 2
                        });
                        const findDataSeq3 = props.bcp_introduction_values_info.find(fetchData => {
                            return fetchData.column_name === data.column_name && fetchData.seq === 3
                        });
                        valueForms.value.push({
                            columnName: data.column_name,
                            values:[
                                {value: typeof findDataSeq1 !== 'undefined' ? findDataSeq1.value : ''},
                                {value: typeof findDataSeq2 !== 'undefined' ? findDataSeq2.value : '' },
                                {value: typeof findDataSeq3 !== 'undefined' ? findDataSeq3.value : '' },
                            ]
                        });
                        valueToColumnName = data.column_name;
                        isExistValueForm = true;
                    }
                });

                if(props.bcp_basic_frame_info.入門確定日){
                    confirm_button_label.value = "次版作成年月日へ";
                }
            }
            if (!isExistValueForm) {
                // 既存のデータが存在しない場合は初期データを投入する
                valueForms.value = [...valueFormsInitialData];
            }

            if(props.bcp_introduction_texts_info.length > 0) {
                props.bcp_introduction_texts_info.forEach(data => {
                    textForms.forEach((initData, index) => {
                        if(data.column_name === initData.columnName) {
                            textForms.splice(index, 1, {
                                columnName: data.column_name,
                                value: data.text_value
                            });
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 1000);

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const selectAdminCandidate = ref('');
        const columnNameRegex = new RegExp(`^N[0-9]{5}$`);
        let valueToColumnName = 'N00059';

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive([
            {
                columnName: 'N00061',
                value: '日頃の' + callReplaceWord('顧客') + '管理、在庫管理、' + callReplaceWord('仕入先') + '管理の結果に大幅な変更があった場合、'+ callReplaceWord('商品') + 'の変更・追加、' + callReplaceWord('生産') + '工程の組み替え、人事異動等があった場合は、見直しを行う必要があるか検討し、その必要があれば、即座にその変更をBCPに反映する',
            },
            {
                columnName: 'N00062',
                value: '毎年1回以上、事前対策の進捗状況や問題点をチェックし、必要に応じてBCPを見直す',
            },
            {
                columnName: 'N00063',
                value: '',
            },
        ]);

        const valueForms = ref([]);
        const valueFormsInitialData = reactive([
            {
                columnName: 'N00056',
                values:[
                    {value: ''},
                    {value: '毎年1回'},
                    {value: callReplaceWord('従業員') + 'に対して、BCPの進捗状況や問題点を説明する'},
                ]
            },
            {
                columnName: 'N00057',
                values:[
                    {value: ''},
                    {value: '毎年1回'},
                    {value: callReplaceWord('製造') + '工程の変更に伴うBCPへの影響について、' +callReplaceWord('工場') + '内の' + callReplaceWord('従業員') + 'と共有化する'},
                ]
            },
            {
                columnName: 'N00058',
                values:[
                    {value: ''},
                    {value: '毎年1回'},
                    {value: 'BCPのポイントに関する' + callReplaceWord('社内') + '工研修会を開催する'},
                ]
            },
            {
                columnName: 'N00059',
                values:[
                    {value: ''},
                    {value: '毎年1回'},
                    {value: callReplaceWord('従業員') + '各自のBCPの役割分担（' +callReplaceWord('社外') + 'との連絡担当 等）の定期的な確認を行う'},
                ]
            },
         ]);

        const addLine = () => {
            const lastColumnName = valueForms.value.slice(-1)[0].columnName;
            const columnNameNumber = parseInt(lastColumnName.substring(1));
            valueForms.value.push({
                columnName: 'N' + ('00000' + (columnNameNumber + 1)).slice(-5),
                values:[
                    {value: ''},
                    {value: ''},
                    {value: ''},
                ]
            });
        }

        const showBack = async () => {
            // BCPの運用を登録
            const result = await registerIntroduction8();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showIntroductionComplete = async () => {
            // BCPの運用を登録
            const result = await registerIntroduction8();

            if (result == '200') {
                // 終了ページへ遷移
                const nextPageUrl = '/bcp/bcp_introduction/introduction_complete';
                location.href = nextPageUrl;
            }
        }

        const showContents = async () => {
            // BCPの運用を登録
            const result = await registerIntroduction8();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // BCPの運用の登録
        const registerIntroduction8 = async() => {
            // リクエストの整形
            const reqValueForms = [];
            valueForms.value.forEach(data => {
                data.values.forEach((val, index) => {
                    if (val.value != '') {
                        reqValueForms.push({
                            columnName: data.columnName,
                            seq: index + 1,
                            value: val.value,
                        });
                    }
                });
            });

            const reqTextForms = [];
            textForms.forEach(data => {
                reqTextForms.push({
                    columnName: data.columnName,
                    value: data.value,
                });
            });

            const postUrl = '/api/bcp/registerIntroduction8';
            isProcessing.value = true;

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'text_form_list': reqTextForms,
                'value_form_list': reqValueForms,
                'value_to_column_name': valueToColumnName,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const confirm_button_label = ref("初版の作成年月日へ");

        const deleteLine = (index) => {
            if(!confirm((index + 1) + "番目の教育計画を削除します。よろしいですか？")){
                return false;
            }

            valueForms.value.splice(index, 1);

        }

        return {
            setLevel,
            textForms,
            valueForms,
            valueFormsInitialData,
            isProcessing,
            selectAdminCandidate,
            callReplaceWord,
            addLine,
            showBack,
            showIntroductionComplete,
            showContents,
            confirm_button_label,
            deleteLine
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
        admin_candidate_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';

    .font-orange {
        color: common_colors.colors(resilience-orange);
    }

    .bg-sub-title {
        background-color: #f4b084;
    }
    .bg-one-set {
        background-color: #D9D9D9;
    }

</style>
