<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="row">
                <div class="h4 d-flex justify-content-center color-resilience-red">主な機能（TOTONO-Lが解決してくれること）</div>
            </div>
            <div class="row d-flex justify-content-center mb-2">
                <div class="h4 col-1 bg-color-white border-resilience-red d-flex justify-content-center color-resilience-red fw-bold">
                    5
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center my-3">
            <div class="h1 d-flex justify-content-center color-resilience-red fw-bold">
                有事のスピード感をＵＰする産官学ネットワーク
            </div>
        </div>
        <div class="row">
            <label class="color-resilience-red">最新の防災技術と産業界・官公庁のBCPがDXを通じて連携し、「有事のスピード感」を実現する ----- それがTOTONO-Lの目指す未来です。</label>
        </div>
        <div class="relative d-flex justify-content-center py-3">
            <i id="explanation_image_1" class="explanation_image" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .explanation_image {
        display: inline-flex;
        width: 1100.341px;
        height: 583.67px;
        background-size: 1100.341px auto;
        background-repeat: no-repeat;

    }

    #explanation_image_1 {
        background-image: url('../../../svg/01-L5.svg');
    }

    @media (max-width: 1199px) {
        .explanation_image {
            display: inline-flex;
            width: 900px;
            height: 447.273px;
            background-size: 900px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 991px) {
        .explanation_image {
            display: inline-flex;
            width: 700px;
            height: 447.273px;
            background-size: 700px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 767px) {
        .explanation_image {
            display: inline-flex;
            width: 500px;
            height: 447.273px;
            background-size: 500px auto;
            background-repeat: no-repeat;
        }
    }

    @media (max-width: 575px) {
        .explanation_image {
            display: inline-flex;
            width: 400px;
            height: 447.273px;
            background-size: 400px auto;
            background-repeat: no-repeat;
        }
    }

</style>