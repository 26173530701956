<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            教育実施記録
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row">
        <div class="row ms-3">
            <div class="col-md-2 border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                実施日
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                教育計画のテーマ・目的
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-light-gray text-dark d-flex align-items-center py-2">
                教育の方法
            </div>
            <div class="col-md-2 border border-dark border-bottom-0 bg-light-gray text-dark d-flex align-items-center py-2">
                責任者
            </div>
        </div>
        <div class="row ms-3">
            <div class="col-md-2 border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center py-2">
                <input type="date" class="form-control d-flex align-items-center justify-content-center py-2" placeholder=""
                    v-model="educationImplementationInfo.implementationDate"
                    @change="selectedImplementationDate"
                >
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center py-2">
                <select name="selectEducation" class="form-select form-control"
                    v-model="selectedEducationPlan"
                    @change="selectEducation"
                >
                    <option type="text"></option>
                    <option type="text" v-for="item in educationNameList" :key="item.id" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="col-md border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center py-2">
                <template v-if="educationMethodList.find(data => data.id == educationImplementationInfo.educationMethod)">
                    <template v-if="educationMethodList.find(data => data.id == educationImplementationInfo.educationMethod).id == educationMethodList[4].id">
                        {{ educationMethodList.find(data => data.id == educationImplementationInfo.educationMethod).methodName }} ：
                        {{ educationImplementationInfo.educationMethodOther }}
                    </template>
                    <template v-else>
                        {{ educationMethodList.find(data => data.id == educationImplementationInfo.educationMethod).methodName }}
                    </template>
                </template>
            </div>
            <div class="col-md-2 border border-dark border-bottom-0 bg-white text-dark d-flex align-items-center py-2">
                {{ educationImplementationInfo.manager }}
            </div>
        </div>
        <div class="row ms-3">
            <div class="col-md border border-dark bg-light-gray text-dark d-flex align-items-center py-2">
                訓練参加者
            </div>
        </div>
        <div class="row ms-3">
            <template v-for="participant, index in educationParticipants" :key="index">
                <div class="col-md-2 border border-dark border-top-0 bg-white text-dark d-flex align-items-center py-2" style="height: 63px;"
                    :class="{'border-end-0 ': (index + 1) % 6 && index + 1  !== educationParticipants.length,
                    'border-bottom-0': index >= (educationParticipants.length - ((educationParticipants.length) % 6)) && index < educationParticipants.length }"
                >
                    {{ participant.participant }}
                </div>
            </template>
        </div>

        <div class="row ms-3">
            <div class="col-md border border-dark bg-light-gray text-dark d-flex align-items-center py-2" :class="{'border-top-0': (educationParticipants.length) % 6 === 0}">
                教育終了後に気づいた課題・BCP見直しのポイントなど（自由記述）
            </div>
        </div>
        <div class="row ms-3">
            <div class="col-md border border-dark border-top-0 py-2">
                <textarea type="text" class="form-control"  style="height: 200px;" placeholder="" v-model="educationImplementationInfo.freeDescription"></textarea>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-5 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="教育実施記録の閲覧 へ登録する" style="font-size:10.5pt" :disabled="isProcessing || isUnselectedPlan" @click="register">
        </div>
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>
    <div class="row ms-5 mt-3 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
        {{ flashMessage }}
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if (Object.values(props.education_plan_info).length > 0) {
                Object.values(props.education_plan_info).map(fetchData => {
                    educationNameList.value.push({
                        id: fetchData[0].education_plan_id,
                        name: fetchData[0].theme,
                    });
                })
            }
        });

        const setLevel = ref('011111');
        const isProcessing = ref(false);
        const isUnselectedPlan = ref(true);

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');
        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const educationNameList = ref([]);
        const selectedEducationPlan = ref('');

        // 教育の方法
        const educationMethodList = [
            {id: 1, methodName: '社内ディスカッション（BCPの周知・防災）'},
            {id: 2, methodName: '防災に関する勉強会への参加'},
            {id: 3, methodName: '応急救護の受講（心肺蘇生法等）'},
            {id: 4, methodName: 'BCPや防災対策関連のセミナーへの参加'},
            {id: 5, methodName: 'その他'},
        ]

        // 教育計画実施情報
        const educationImplementationInfo = ref({});
        // 参加者情報
        const educationParticipants = ref([]);
        for (let i = 0; i < 12; i++) {
            educationParticipants.value.push({
                participant: '',
            });
        }

        // 実施日選択
        const selectedImplementationDate = () => {
            if (educationImplementationInfo.value.implementationDate !== '' && typeof educationPlan !== 'undefined') {
                isUnselectedPlan.value = false;
            } else {
                isUnselectedPlan.value = true;
            }
        }

        // 教育計画選択プルダウン
        const selectEducation = () => {
            const educationPlan = props.education_plan_info[selectedEducationPlan.value];
            if (typeof educationPlan !== 'undefined') {
                // 教育計画実施情報
                educationImplementationInfo.value = {
                    id: '',
                    educationPlanId: educationPlan[0].education_plan_id,
                    implementationDate: educationImplementationInfo.value.implementationDate,
                    manager: props.manager_name,
                    theme: educationPlan[0].theme,
                    educationMethod: educationPlan[0].education_method,
                    educationMethodOther: educationPlan[0].education_method_other,
                    implementationCycle: educationPlan[0].implementation_cycle,
                    freeDescription: '',
                }
                // 参加者
                educationParticipants.value.splice(0);
                educationPlan.forEach(data => {
                    if (data.participant) {
                        educationParticipants.value.push({
                            participant: data.participant,
                        });
                    }
                });
            } else {
                // データがない場合は表示内容を初期化
                educationImplementationInfo.value = {};
                educationParticipants.value.splice(0);
                for (let i = 0; i < 12; i++) {
                    educationParticipants.value.push({
                        participant: '',
                    });
                }
            }

            if (educationImplementationInfo.value.implementationDate !== '') {
                isUnselectedPlan.value = false;
            } else {
                isUnselectedPlan.value = true;
            }
        }

        const showBack = async () => {
            let result = null;
            if (!isUnselectedPlan.value) {
                // 教育実施記録を登録
                result = await registerK3();
            }
            if (result == '200' || isUnselectedPlan.value) {
                // 前のページへ遷移
                history.back();
            }
        }

        const showTop = async () => {
            // 教育実施記録を登録
            const result = await registerK3();

            if (result == '200') {
                const url = '/fixation/fixation';
                location.href = url;
            }
        }

        const register = () => {
            // 教育実施記録を登録
            registerK3();
        }

        // 教育実施記録登録
        const registerK3 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/registerK3';

            // 参加者の情報を付与
            educationImplementationInfo.value.participants = educationParticipants.value;

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'education_implementation_info': educationImplementationInfo.value,
            }).then(res => {
                if(typeof res.data.entry_record_id !== 'undefined'){
                    // 新規登録の場合は、登録した訓練実施記録idをセット
                    educationImplementationInfo.value.id = res.data.entry_record_id;
                }
                visibleFlashMessage('正常に登録されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            isUnselectedPlan,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
            educationMethodList,
            educationNameList,
            educationImplementationInfo,
            educationParticipants,
            selectedImplementationDate,
            selectedEducationPlan,
            selectEducation,
            showBack,
            showTop,
            register,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        education_plan_info: {
            type: Object,
        },
        manager_name: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
