<template>
    <div>
        <div id="alert-modal" v-show="modalAlertVisible" @click.self="closeModal">
            <div id="content">
                <div class="d-flex justify-content-end">
                    <a href="#" @click.prevent.stop="closeModal"><i class="bi bi-x"></i></a>
                </div>
                <div class="row">
                    <label>{{ alertMessage_1 }}</label>
                    <a href="#" @click.prevent.stop="movePage" class="color-resilience-orange mt-3"><i class="bi bi-arrow-right-circle-fill"></i>{{ alertMessage_2 }}</a>
                </div>
            </div> 
        </div>

        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                拠点・支店登録
            </div>
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <div class="alert alert-danger" v-show="hasError" role="alert">
            {{ errorMessage }}
        </div>

        <div class="card bg-member_card my-2" v-for="(branch, index) in branches" :key="branch.idx">
            <div class="row mx-4 mt-3">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                    所在地
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                    <span class="badge badge-gray ">必須</span>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                    郵便番号
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
                    <input type="text" class="form-control" v-model="branch.郵便番号"  required maxlength="7" @blur="onZipChange($event, branch.郵便番号, index)" :class="{'is-invalid': branch.has郵便番号Error}" />
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                    <input type="button" class="btn btn-black btn-sm" value="住所検索">
                </div>
            </div>
            
            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                    <input type="button" class="btn btn-black btn-sm" value="本社所在地と同じ" @click="copyMainOfficeAddress(index)">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                </div>
                <div class="p2 col-md-2 d-flex align-items-start justify-content-center ">
                    半角数字、ハイフン省略
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start py-2">
                    都道府県
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
                    <input type="text" class="form-control" v-model="branch.都道府県" maxlength="10">
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                    市区町村
                </div>
                <div class="p2 col-md-4 d-flex align-items-center justify-content-center py-2">
                    <input type="text" class="form-control" v-model="branch.市区町村" maxlength="50">
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                    <span class="badge badge-gray">必須</span>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                    番地
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-center py-2">
                    <input type="text" class="form-control" v-model="branch.番地" maxlength="255" :class="{'is-invalid': branch.has番地Error}">
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                </div>
                <div class="p2 col-md-2 d-flex align-items-start justify-content-start ">
                    半角数字
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                    建物・号
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-center py-2">
                    <input type="text" class="form-control">
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-start ">
                </div>
                <div class="p2 col-md-2 d-flex align-items-start justify-content-start ">
                    半角数字
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                    拠点・支店の名称
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                    <span class="badge badge-gray">必須</span>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-center py-2">
                    <input type="text" class="form-control" v-model="branch.拠点名" maxlength="100" :class="{'is-invalid': branch.has拠点名Error}">
                </div>
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                        <template #popper>
                            <div class="row">
                                <label>勤務先の名称に使用します。「本店」や「○○支店」のようにごご入力ください。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center ">
                    電話番号
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2">
                    <span class="badge badge-gray">必須</span>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-center">
                    <input type="text" class="form-control" v-model="branch.電話番号" maxlength="30" :class="{'is-invalid': branch.has電話番号Error}">
                </div>
            </div>

            <div class="row mx-4">
                <div class="p2 col-md-2 d-flex align-items-center justify-content-center py-2">
                    <input type="button" class="btn btn-black btn-sm" value="本社所在地と同じ" @click="copyMainOfficeTel(index)">
                </div>
            </div>
        </div>

        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
                <input type="button" class="btn btn-black btn-sm" value="ワンセット増やす" @click="addRow" :disabled="isProcessing">
        </div>

        <div class="row">
            <div class="p2 border border-warning d-flex border-top-0 border-start-0 border-end-0 py-2">
            </div>
        </div>

        <div class="p2 ms-4 mt-3 col-md-4 d-flex">
            <input type="button" class="btn btn-black btn-sm" value="登録・更新" :disabled="isProcessing" @click="saveBranches" v-show="!nextMode" />
            <input type="button" class="btn btn-black btn-sm ms-3" value="次へ進む" @click="moveNext" :disabled="isProcessing" v-show="nextMode" />
        </div>
        <div class="flash alert color-resilience-orange" v-if="showFlashMessage">
            {{ flashMessage }}
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import CommonCheck from '../../../function/CommonCheck';
const { isTelNumber } = CommonCheck();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            if(props.branch_list.length > 0){
                branches.splice(0);
                for(let i = 0; i < props.branch_list.length; i++){
                    branches.push(props.branch_list[i]);
                }
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('111111');

        const isProcessing = ref(false);

        const hasError = ref(false);
        const errorMessage = ref('');

        const branches = reactive([
            {
                corp_id: props.corp_info.id,
                idx: 0,
                郵便番号: '',
                都道府県: '',
                市区町村: '',
                番地: '',
                拠点名: '',
                電話番号: '',
            }
        ]);

        const onZipChange = (e, zip_code, index) => {
            if(zip_code.length !== 3 && zip_code.length !== 7){
                return false;
            }

            const url = "/api/getAddressSeparate?zip=" + e.target.value;
            axios.get(url).then(response => {
                if(response.status === 200) {
                    branches[index].都道府県 = response.data.prefecture;
                    branches[index].市区町村 = response.data.municipalitie;
                    branches[index].番地 = response.data.town;
                }
            });
            branches[index].郵便番号 = e.target.value;
        }

        const addRow = () => {
            if(branches[branches.length - 1].has郵便番号Error){
                branches[branches.length - 1].has郵便番号Error = false;
                hasError.value = false;
                errorMessage.value = '';
            }

            if(branches[branches.length - 1].has番地Error){
                branches[branches.length - 1].has番地Error = false;
                hasError.value = false;
                errorMessage.value = '';
            }

            if(branches[branches.length - 1].has拠点名Error){
                branches[branches.length - 1].has拠点名Error = false;
                hasError.value = false;
                errorMessage.value = '';
            }

            if(branches[branches.length - 1].has電話番号Error){
                branches[branches.length - 1].has電話番号Error = false;
                hasError.value = false;
                errorMessage.value = '';
            }

            if(branches[branches.length - 1].郵便番号 === ''){
                branches[branches.length - 1].has郵便番号Error = true;
                hasError.value = true;
                errorMessage.value = branches.length + '番目の郵便番号は必須です';
                return false;
            }
            if(branches[branches.length - 1].郵便番号.length !== 3 && branches[branches.length - 1].郵便番号.length !== 7){
                branches[branches.length - 1].has郵便番号Error = true;
                hasError.value = true;
                errorMessage.value = branches.length + '番目の郵便番号を確認してください';
                return false;
            }
            if(branches[branches.length - 1].番地 === ''){
                branches[branches.length - 1].has番地Error = true;
                hasError.value = true;
                errorMessage.value = branches.length + '番目の番地は必須です';
                return false;
            }
            if(branches[branches.length - 1].拠点名 === ''){
                branches[branches.length - 1].has拠点名Error = true;
                hasError.value = true;
                errorMessage.value = branches.length + '番目の拠点・支店の名称は必須です';
                return false;
            }
            if(branches[branches.length - 1].電話番号 === ''){
                branches[branches.length - 1].has電話番号Error = true;
                hasError.value = true;
                errorMessage.value = branches.length + '番目の電話番号は必須です';
                return false;
            }
            if(!isTelNumber(branches[branches.length - 1].電話番号)){
                branches[branches.length - 1].has電話番号Error = true;
                hasError.value = true;
                errorMessage.value = branches.length + '番目の電話番号を確認してください';
                return false;
            }

            saveBranch(branches.length - 1);

            if(branches.length === props.pay_per_use_info.拠点数){
                if(props.corp_info.会員種別 === 10000001 || props.corp_info.会員種別 === 10000002){
                    alertMessage_1.value = 'これ以上の拠点を登録したい場合は、有償会員に切り替える必要がございます。';
                    alertMessage_2.value = '有償会員切替ページへ';
                }else{
                    alertMessage_1.value = '登録されている拠点数の数を超えています';
                    alertMessage_2.value = '従量課金の登録・変更ページへ';
                }
                modalAlertVisible.value = true;
                
            }else{
                branches.push({
                    corp_id: props.corp_info.id,
                    idx: 0,
                    郵便番号: '',
                    都道府県: '',
                    市区町村: '',
                    番地: '',
                    拠点名: '',
                    電話番号: '',
                });
            }
        }

        const saveBranch = (index) => {
            const url = '/api/registerBranch';
            
            axios.post(url, {
                corp_id: branches[index].corp_id,
                idx: index + 1,
                郵便番号: branches[index].郵便番号,
                都道府県: branches[index].都道府県,
                市区町村: branches[index].市区町村,
                番地: branches[index].番地,
                拠点名: branches[index].拠点名,
                電話番号: branches[index].電話番号,
            }).then(res=>{
            });
        }

        const saveBranches = () => {
            let i = 0;
            branches.forEach(ele => {
                if(ele.郵便番号 || ele.番地 || ele.拠点名 || ele.電話番号){
                    if(ele.has郵便番号Error){
                        ele.has郵便番号Error = false;
                        hasError.value = false;
                        errorMessage.value = '';
                    }

                    if(ele.has番地Error){
                        elehas番地Error = false;
                        hasError.value = false;
                        errorMessage.value = '';
                    }

                    if(ele.has拠点名Error){
                        ele.has拠点名Error = false;
                        hasError.value = false;
                        errorMessage.value = '';
                    }

                    if(ele.has電話番号Error){
                        ele.has電話番号Error = false;
                        hasError.value = false;
                        errorMessage.value = '';
                    }

                    if(ele.郵便番号 === ''){
                        ele.has郵便番号Error = true;
                        hasError.value = true;
                        errorMessage.value = (i + 1) + '番目の郵便番号は必須です';
                        return false;
                    }
                    if(ele.郵便番号.length !== 3 && ele.郵便番号.length !== 7){
                        ele.has郵便番号Error = true;
                        hasError.value = true;
                        errorMessage.value = (i + 1) + '番目の郵便番号を確認してください';
                        return false;
                    }
                    if(ele.番地 === ''){
                        ele.has番地Error = true;
                        hasError.value = true;
                        errorMessage.value = (i + 1) + '番目の番地は必須です';
                        return false;
                    }
                    if(ele.拠点名 === ''){
                        ele.has拠点名Error = true;
                        hasError.value = true;
                        errorMessage.value = (i + 1) + '番目の拠点・支店の名称は必須です';
                        return false;
                    }
                    if(ele.電話番号 === ''){
                        ele.has電話番号Error = true;
                        hasError.value = true;
                        errorMessage.value = (i + 1) + '番目の電話番号は必須です';
                        return false;
                    }
                    if(!isTelNumber(ele.電話番号)){
                        ele.has電話番号Error = true;
                        hasError.value = true;
                        errorMessage.value = (i + 1) + '番目の電話番号を確認してください';
                        return false;
                    }

                    i++;
                }
            });

            saveBranchAll();

        }

        const saveBranchAll = () => {
            const url = '/api/registerBranchAll';
            
            axios.post(url, {
                corp_id: props.corp_info.id,
                branch_list: branches,
            }).then(res=>{
                visibleFlashMessage('正常に登録されました', 'success');
                nextMode.value = true;
            });

        }

        const modalAlertVisible = ref(false);
        const closeModal = () => {
            modalAlertVisible.value = false;
        }
        const alertMessage_1 = ref('');
        const alertMessage_2 = ref('');

        const movePage = () => {
            let url = '';
            if(props.corp_info.会員種別 === 10000001 || props.corp_info.会員種別 === 10000002){

            }else{
                url = '/al/administrator/register_pay_per_use?back=RegisterBranch';
            }

            location.href = url;
        }

        const copyMainOfficeAddress = (index) => {
            branches[index].郵便番号 = props.corp_info.郵便番号;
            branches[index].都道府県 = props.corp_info.都道府県;
            branches[index].市区町村 = props.corp_info.市区町村;
            branches[index].番地 = props.corp_info.番地;
        }

        const copyMainOfficeTel = (index) => {
            branches[index].電話番号 = props.corp_info.電話番号;
        }

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const back = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const nextMode = ref(false);

        const moveNext = () => {
            const url = '/home';

            location.href = url;
        }

        return {
            setLevel,
            isProcessing,
            hasError,
            errorMessage,
            branches,
            onZipChange,
            addRow,
            saveBranch,
            saveBranches,
            saveBranchAll,
            modalAlertVisible,
            closeModal,
            alertMessage_1,
            alertMessage_2,
            movePage,
            copyMainOfficeAddress,
            copyMainOfficeTel,
            backHome,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            back,

            nextMode,

            moveNext,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        pay_per_use_info: {
            type: Object,
        },
        branch_list: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
    .badge-gray {
        background-color: #a6a6a6;
    }

    #alert-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #content {
        z-index: 2;
        width: 30%;
        padding: 1em 3em;
        background: #fff;
    }

</style>