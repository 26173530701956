<template>
    <div class="row">
        <div class="col-md-6">
            <div class="h5 heading-orange mt-3">
                訓練＆教育掲示板
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-start justify-content-end me-md-5 ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <div class="row ms-md-3 mt-3">
        <div class="p-2 col-md-6 border bg-secondary text-white text-center d-flex align-items-center justify-content-start py-2">
            ◯　掲示板への投稿
        </div>
    </div>
    <div class="row ms-md-3">
        <div class="mt-3 col-md-2 text-dark align-items-center justify-content-start">
            タイトル
        </div>
    </div>
    <div class="row ms-md-3">
        <div class="p-2 col-md-10 text-dark d-flex align-items-center justify-content-start">
            <input type="text" class="form-control py-2" v-model="entryBbsInfo.title" @change="inputBbs">
        </div>
    </div>
    <div class="row ms-md-3">
        <div class="mt-3 col-md-2 text-dark align-items-center justify-content-start">
            内容
        </div>
    </div>
    <div class="row ms-md-3">
        <div class="p-2 col-md-10 text-dark d-flex align-items-center justify-content-start">
            <textarea type="text" class="form-control" style="height: 160px;" v-model="entryBbsInfo.content" @change="inputBbs"></textarea>
        </div>
        <div class="p-2 col-md-1 d-flex align-items-end">
            <input type="button" class="btn btn-black btn-sm" value="登録" style="font-size:10.5pt" :disabled="isProcessing || isNotInputBbs" @click="registerBbs">
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="p-2 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
            {{ flashMessage }}
        </div>
    </div>

    <div class="row ms-md-3 mt-5">
        <div class="p-2 col-md-6 border bg-secondary text-white text-center d-flex align-items-center justify-content-start py-2">
            ◯　掲示板
        </div>
    </div>
    <div class="row ms-md-3 mt-3" v-if="bbsInfo.length > 0">
        <template v-for="bbs, index in bbsInfo" :key="index">
            <div class="d-flex col-md-10">
                <table class="table table-bordered border-dark">
                    <thead>
                        <tr>
                            <td class="bg-light-gray text-dark" style="width: 13%;">記入日時</td>
                            <td class="bg-light-gray text-dark" style="width: 25%;">記入者</td>
                            <td class="bg-light-gray text-dark">タイトル</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="">
                                {{ bbs.entryTime }}
                            </td>
                            <td class="">
                                {{ bbs.entryPerson }}
                            </td>
                            <td class="">
                                {{ bbs.title }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray text-dark" colspan="3">
                                内容
                            </td>
                        </tr>
                        <tr>
                            <td class="" colspan="3" style="white-space: pre-wrap;">
                                {{ bbs.content }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="py-3 col-md-1 d-flex align-items-end">
                <input type="button" class="btn btn-black btn-sm" value="削除" style="font-size:10.5pt" :disabled="isProcessing" @click="deleteBbs(bbs.bbsId, bbs.title)">
            </div>
            <div class="row justify-content-center">
                <div class="p-2 flash col-5 alert d-flex align-items-start justify-content-around" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showDeleteFlashMessage && bbs.bbsId == selectBbsId">
                    {{ flashMessage }}
                </div>
            </div>
        </template>
    </div>

    <div class="row ms-md-3 mt-5">
        <div class="p2 col-md d-flex align-items-start">
            <input type="button" class="btn btn-gray" value="文化定着［訓練＆教育］TOP へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showTop">
        </div>
    </div>

</template>

<script>
import { onMounted,  ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(props.bbs_list.length > 0){
                bbsInfo.value =  props.bbs_list.map(
                    fetchData => ({
                        bbsId: fetchData.id,
                        entryTime: fetchData.entry_time,
                        entryPerson: fetchData.entry_person,
                        title: fetchData.title,
                        content: fetchData.content,
                    })
                );
            }
        });
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('001111');
        const isProcessing = ref(false);
        const isNotInputBbs = ref(true);
        const selectBbsId = ref(0);

        const flashMessage = ref('');
        const flashMessageStyle = ref('');
        const showFlashMessage = ref(false);
        const showDeleteFlashMessage = ref(false);

        const visibleFlashMessage = async (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false;
                window.location.reload();
            }, 3000)
        }

        const visibleDeleteFlashMessage = async (msg, kind) => {
            showDeleteFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showDeleteFlashMessage.value = false;
                window.location.reload();
            }, 3000);
        }

        // 掲示板登録情報
        const entryBbsInfo = ref({
            entryTime: '',
            entryPerson: props.entry_person,
            title: '',
            content: '',
        });

        // 掲示板情報
        const bbsInfo = ref([]);

        // 掲示板入力判定
        const inputBbs = () => {
            if (entryBbsInfo.value.title && entryBbsInfo.value.content) {
                isNotInputBbs.value = false;
            } else {
                isNotInputBbs.value = true;
            }
        }

        const registerBbs = () => {
            registerKei();
        }

        const showBack = () => {
            // 前のページへ遷移
            history.back();
        }

        const showTop = () => {
            const url = '/fixation/fixation';
            location.href = url;
        }

        // 掲示板登録
        const registerKei = async () => {
            isProcessing.value = true;
            const postUrl = '/api/fixation/registerKei';

            const nowDate = new Date();
            entryBbsInfo.value.entryTime = nowDate.toLocaleString();

            return await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
                'entry_bbs_info': entryBbsInfo.value,
            }).then(res => {
                visibleFlashMessage('正常に登録されました', 'success');
                return res.status;
            }).catch(err => {
                visibleFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                // isProcessing.value = false;
            });
        }

        // 掲示板削除
        const deleteBbs = async (bbsId, title) => {

            if(!confirm(title + "を削除します。よろしいですか？")){
                return false;
            }

            selectBbsId.value = bbsId;
            isProcessing.value = true;
            const postUrl = '/api/fixation/deleteKei';

            return await axios.post(postUrl, {
                'bbs_id': bbsId,
            }).then(res => {
                visibleDeleteFlashMessage('正常に削除されました', 'success');
                return res.status;
            }).catch(err => {
                visibleDeleteFlashMessage('処理に失敗しました', 'error');
                return err.response?.status;
            }).finally(fin => {
                // isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            isNotInputBbs,
            selectBbsId,
            flashMessage,
            flashMessageStyle,
            showFlashMessage,
            showDeleteFlashMessage,
            visibleFlashMessage,
            visibleDeleteFlashMessage,
            entryBbsInfo,
            bbsInfo,
            inputBbs,
            registerBbs,
            showBack,
            showTop,
            registerKei,
            deleteBbs,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        bbs_list: {
            type: Array,
        },
        entry_person: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-light-gray {
    height: 39.5px;
}

</style>
