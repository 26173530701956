<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜資源（つづき）
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-end align-self-end">
            <input type="button" class="btn btn-new-window-button btn-sm" value="防災備蓄品リスト へ" :disabled="isProcessing" @click="showStockpileList">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-6 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　災害対応用具チェックリスト
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="flex-row">
            <div class="list">
                <div class="row" v-for="(form, index) in valueFormsField" :key="index">
                    <div class="check-item p2 col-md-1 border border-dark border-bottom-0 border-end-0 text-dark bg-white d-flex align-items-center justify-content-center py-2">
                        <input type="checkbox" :id="'customRadioInline1' + index" :name="'customRadioInline1' + index" class="custom-control-input" v-model="form.values[0].value">
                    </div>
                    <div class="check-item p2 col-md-10 border border-dark border-bottom-0 text-dark d-flex align-items-center justify-content-start py-2">
                        <input type="text" class="form-control" v-model="form.values[1].value">
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2" v-if="index===0">
                        <VDropdown theme="hint-theme">
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ hint_word_1 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                    <div class="p2 col-md-1 d-flex align-items-center justify-content-center py-2" v-if="index + 1 === valueFormsField.length">
                        <input type="button" class="btn btn btn-black btn-sm" value="1行増やす" @click="addRow">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5. 緊急時におけるBCP発動 終了ページへ" style="font-size:10.5pt" :disabled="isProcessing" @click="showBasicComplete">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-center justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // // 取得データをセット
            if (typeof props.bcp_values_info !== undefined && props.bcp_values_info.length !== 0) {
                valueFormsField.splice(0);
                Object.values(props.bcp_values_info).forEach(fetchData => {
                    valueFormsField.push({
                        fieldNumber: fetchData[0].field_number,
                        columnName: fetchData[0].column_name,
                        values: [
                            { value: fetchData[0].value == 1 ? true : false } ,
                            { value: fetchData[1].value },
                        ],
                    });
                });
                // 空行を追加
                valueFormsField.push({
                    fieldNumber: setDefaultFieldNumber,
                    columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                    values: [
                        {value: false},
                        {value: ''},
                    ],
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const pageName = 'S12';
        const columnPrefix = 'J'
        const setDefaultFieldNumber = 1;
        let setColumnNumber = 0;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }
        const hint_word_1 = ref(`不足している用具については是非とも購入しておくことが望ましいでしょう。また、${callReplaceWord('組織')}が供給すべき防災用具について${callReplaceWord('従業員')}と検討し、以下に挙げた用具以外にも必要と 思われる防災用具がある場合は、併せて準備を検討してください。`);

        // フィールド値
        const valueFormsField = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: true},
                    {value: `${callReplaceWord('従業員')}分の水（飲料用と生活用のためには、一人当たり一日3リットルが目安）`},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: `${callReplaceWord('従業員')}分の食物（非腐敗性食品を少なくとも3日間分）`},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '缶切及び紙製（またはプラスチック製）食器'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: 'カセットコンロ及びガスボンベ'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: 'ラジオ（乾電池型、手巻充電型）と予備乾電池'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '懐中電燈と予備乾電池'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '救急箱'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '笛（救助を求めるためのもの）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '作業用防具類（ヘルメット、防塵マスク、アイガード、作業用手袋など）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '衛生用具類（ウェットティッシュ、トイレットペーパーなど）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '工具類（ペンチ、ハンマー、遮断レンチ、シャベル、てこ用棒など）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '文具類（鉛筆、マジックペン（数色）、ノートなど）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '蓋付きポリバケツ、ゴミ袋、ほうき'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: 'ビニールシート及びテープ（部屋を閉じるため）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: 'ブルーシート'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '簡易トイレ製品（または、トイレ用ビニール袋及びビニールテープ）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: 'カメラ、フィルム、予備乾電池（損害を記録するため。使い捨てカメラでもOK）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '毛布（可能ならば、簡易ベッドやマットなどもあるとよい）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '現金（電話用の小銭も含む）、キャッシュカード、クレジットカード（停電により、ATMが利用不可な状況などに備えるため）'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: `連絡先リスト（${callReplaceWord('従業員')}、警察、消防等の公益事業会社などの緊急サービスなど）`},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: `${callReplaceWord('事業')}継続のための活動項目リスト`},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '地図、ビル内フロアマップ'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: '拡声器'},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: ''},
                ],
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: ''},
                ],
            },
        ]);

        // 1行増やすボタン
        const addRow = () => {
            valueFormsField.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: `${columnPrefix}${String(setColumnNumber += 1).padStart(5, '0')}`,
                values: [
                    {value: false},
                    {value: ''},
                ],
            });
        }

        // 災害対応用具チェックリスト登録
        const registerSecond12 = async () => {
            // リクエスト内容の生成
            const reqValueForms = [];
            valueFormsField.forEach(data => {
                if (data.values[1].value.length !== 0) {
                    // チェック内容に入力がある場合に登録値として設定
                    data.values.forEach((val, index) => {
                        if(val.value.length !== 0){
                            reqValueForms.push({
                                fieldNumber: data.fieldNumber,
                                columnName: data.columnName,
                                seq: index + 1,
                                value: val.value,
                            });
                        }
                    })
                }
            });

            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond12';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const showBasicComplete = async () => {
            // 災害対応用具チェックリスト登録
            const result = await registerSecond12();
            if (result == '200') {
                const url = '/bcp/bcp_second/basic_complete';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 災害対応用具チェックリスト登録
            const result = await registerSecond12();
            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 災害対応用具チェックリスト登録
            const result = await registerSecond12();
            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showStockpileList = async () => {
            // 災害対応用具チェックリスト登録
            const result = await registerSecond12();
            if (result == '200') {
                const url = '/fixation/Bi?back=BCP12';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 災害対応用具チェックリスト登録
            const result = await registerSecond12();
            if (result == '200') {
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        return {
            setLevel,
            hint_word_1,
            isProcessing,
            valueFormsField,
            callReplaceWord,
            showBasicComplete,
            showPrevious,
            showBack,
            showContents,
            showStockpileList,
            addRow,
            registerSecond12,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>
    .list div:last-of-type .check-item {
        border-bottom: 1px solid #000 !important;
    }
</style>
