<template>
    <div>
        <div class="heading-orange mt-3">
            わたし登録
        </div>

        <levelIcon
            :set_level="setLevel"
        />

        <form @submit.prevent="post_form">
            <div class="mx-2 ms-md-5 mt-5">
                <div class="row mt-3 border-bottom-black pb-3">
                    <label class="col-md-1 col-form-label">
                        氏名
                    </label>
                    <div class="row col-md-4">
                        <input type="text" class="form-control" :value="ユーザー名" disabled />
                    </div>
                    <label class="col-md-1 col-form-label offset-md-1">
                        LINE連携
                    </label>
                    <div class="row col-md-4">
                        <div class="col-4">
                            <input type="text" class="form-control" :value="user_send_line == 1 ? '連携中' : '未連携'" disabled />
                        </div>
                        <div class="col-4">
                            <input type="button" class="btn btn-delete" value="解除" :disabled="user_send_line != 1" @click="clearSendLINE">
                        </div>
                    </div>
                </div>
                <div class="row mt-3">     
                    <label> 以下の情報は、ご自身が携帯する「携帯カード」へ転記されます。なお、「基本以上」の項目は、BCPの{{ callReplaceWord('従業員') }}リストに掲載されます。</label>
                </div>
                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        血液型<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="row col-md-2">
                        <input type="text" class="form-control" :disabled="血液型不明" v-model="血液型" :class="{'is-invalid': 血液型_has_error}" maxlength="10" />
                        <div class='invalid-feedback'>{{ 血液型_message }}</div>
                    </div>
                    <label class="col-md-1 col-form-label">
                        型
                    </label>
                    <label class="col-md-1 mt-2">
                        <input type="checkbox" id="blood-type" name="blood-type" value="不明" class="form-check-input" v-model="血液型不明"><label for="blood-type" class="form-check-label ms-2" :class="{'is-invalid': 血液型_has_error}">不明</label>
                    </label>
                </div>

                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        自宅電話<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-3 col-md-2">
                                <input type="text" class="form-control" maxlength="10" v-model="自宅電話1" required :class="{'is-invalid': 自宅電話_has_error}" />
                                <div class='invalid-feedback'>{{ 自宅電話_message }}</div>
                            </div>
                            <label class="col-1 d-flex align-items-center justify-content-center  col-form-label">
                                －
                            </label>
                            <div class="col-3 col-md-2">
                                <input type="text" class="form-control" maxlength="10" v-model="自宅電話2" required :class="{'is-invalid': 自宅電話_has_error}" />
                            </div>
                            <label class="col-1 d-flex align-items-center justify-content-center col-form-label">
                                －
                            </label>
                            <div class="col-3 col-md-2">
                                <input type="text" class="form-control" maxlength="10" v-model="自宅電話3" required :class="{'is-invalid': 自宅電話_has_error}" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        携帯電話<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-3 col-md-2">
                                <input type="text" class="form-control" maxlength="10" v-model="携帯電話1" :class="{'is-invalid': 携帯電話_has_error}" required />
                                <div class='invalid-feedback'>{{ 携帯電話_message }}</div>
                            </div>
                            <label class="col-1 d-flex align-items-center justify-content-center col-form-label">
                                －
                            </label>
                            <div class="col-3 col-md-2">
                                <input type="text" class="form-control" maxlength="10" v-model="携帯電話2" :class="{'is-invalid': 携帯電話_has_error}" required />
                            </div>
                            <label class="col-1 d-flex align-items-center justify-content-center col-form-label">
                                －
                            </label>
                            <div class="col-3 col-md-2">
                                <input type="text" class="form-control" maxlength="10" v-model="携帯電話3" :class="{'is-invalid': 携帯電話_has_error}" required />
                            </div>
                            <div class="col-2 py-2">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>部下等の「携帯カード」に掲載されます。所属長等部下をお持ちの{{callReplaceWord('従業員')}}は、必ずご記入ください。<br />なお、部下等が所属長にあなたの氏名を選択した際、あなた宛に許可を得る旨のポップアップが表示されます。<br />許可後、あなたの「自宅電話番号」「携帯電話」「携帯メール」が部下の携帯カードに表示されますので、ご承知おきください。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        携帯mail
                    </label>
                    <div class="row col-md-7">
                        <input type="text" class="form-control" maxlength="255" v-model="携帯メールアドレス" :class="{'is-invalid': 携帯メールアドレス_has_error}" @blur="copyMailAddress" />
                        <div class='invalid-feedback'>{{ 携帯メールアドレス_message }}</div>
                    </div>
                    <div class="col-md-2  py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>部下等を持つ{{ callReplaceWord('従業員') }}は必ずご入力ください。また、安否確認の受信メールとして使用します。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        {{ callReplaceWord('組織') }}用e-mail
                    </label>
                    <div class="row col-md-7">
                        <input type="text" class="form-control" maxlength="255" v-model="組織用メールアドレス" :class="{'is-invalid': 組織用メールアドレス_has_error}" />
                        <div class='invalid-feedback'>{{ 組織用メールアドレス_message }}</div>
                    </div>
                    <div class="col-md-2 py-2">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ callReplaceWord('組織') }}e-mailがない場合は、個人用e-mailをご記入ください。BCP発動（訓練を含む）時の受信メールとして使用します。</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>

                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        自宅の住所<span class="badge bg-secondary ms-5">必須</span>
                    </label>
                    <label class="col-md-1 form-label">
                        〒
                    </label>
                    <div class="row col-md-2">
                        <input type="text" class="form-control" maxlength="10" v-model="自宅郵便番号" @change="getAddress" required />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-3 offset-md-3">
                        <label class="form-label" for="input都道府県">
                            都道府県
                        </label>
                        <input type="text" class="form-control" maxlength="10" v-model="自宅都道府県" id="input都道府県" required />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-3 offset-md-3">
                        <label class="form-label" for="input市区町村">
                            市区町村
                        </label>
                        <input type="text" class="form-control" maxlength="50" v-model="自宅市区町村" id="input市区町村" required />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-3 offset-md-3">
                        <label class="form-label" for="input番地">
                            町名番地、ビル名等
                        </label>
                        <input type="text" class="form-control" maxlength="50" v-model="自宅番地" id="input番地" required />
                    </div>
                </div>
                    
                <div class="card my-5 basic-area border-black">
                    <div class="card-body">
                        <div class="row mt-1">
                            <div class="p2 ms-md-2 col-md-1 d-flex blue-back text-white align-items-center justify-content-center py-2">
                                基本以上
                            </div>
                            <div class="p2 col-md-4 d-flex align-items-center justify-content-center py-2">
                                ※{{ callReplaceWord('従業員') }}連絡先リストに掲載されます。
                            </div>
                        </div>
                        <div class="row mt-5">
                            <label class="col-md-3 col-form-label">
                                〇　緊急連絡先
                            </label>
                        </div>

                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label ms-md-5">
                                個人用携帯電話
                            </label>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="自宅連絡先_電話番号1" :class="{'is-invalid': 自宅連絡先_電話番号_has_error}" />
                                        <div class='invalid-feedback'>{{ 自宅連絡先_電話番号_message }}</div>
                                    </div>
                                    <label class="col-1 d-flex align-items-center justify-content-center py-2 col-form-label">
                                        －
                                    </label>
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="自宅連絡先_電話番号2" :class="{'is-invalid': 自宅連絡先_電話番号_has_error}" />
                                    </div>
                                    <label class="col-1 d-flex align-items-center justify-content-center py-2 col-form-label">
                                        －
                                    </label>
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="自宅連絡先_電話番号3" :class="{'is-invalid': 自宅連絡先_電話番号_has_error}" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label ms-md-5">
                                自宅用e-mail<span class="badge bg-secondary ms-5" v-show="clearBCPBasic">必須</span>
                            </label>
                            <div class="col-md-7">
                                <input type="text" class="form-control" maxlength="255" v-model="自宅連絡先_メールアドレス" :required="clearBCPBasic" :class="{'is-invalid': 自宅連絡先_メールアドレス_has_error}" />
                                <div class='invalid-feedback'>{{ 自宅連絡先_メールアドレス_message }}</div>
                            </div>
                            <div class="col-md-1">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>{{ callReplaceWord('組織') }}用e-mailとともに、BCP発動（訓練を含む）時および安否確認の受信メールとして使用します。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <label class="col-md-3 col-form-label">
                                〇　自宅以外の緊急連絡先
                            </label>
                        </div>

                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label ms-md-5">
                                氏名<span class="badge bg-secondary ms-5" v-show="clearBCPBasic">必須</span>
                            </label>
                            <div class="col-md-5">
                                <input type="text" class="form-control" maxlength="50" v-model="緊急連絡先_氏名" :required="clearBCPBasic" />
                            </div>
                        </div>        

                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label ms-md-5">
                                本人との続柄<span class="badge bg-secondary ms-5" v-show="clearBCPBasic">必須</span>
                            </label>
                            <div class="col-md-5">
                                <input type="text" class="form-control" maxlength="50" v-model="緊急連絡先_続柄" :required="clearBCPBasic" />
                            </div>
                        </div>        

                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label ms-md-5">
                                緊急連絡先<span class="badge bg-secondary ms-5" v-show="clearBCPBasic">必須</span>
                            </label>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="緊急連絡先_電話番号1" :required="clearBCPBasic" :class="{'is-invalid': 緊急連絡先_電話番号_has_error}" />
                                        <div class='invalid-feedback'>{{ 緊急連絡先_電話番号_message }}</div>
                                    </div>
                                    <label class="col-1 d-flex align-items-center justify-content-center py-2 col-form-label">
                                        －
                                    </label>
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="緊急連絡先_電話番号2" :required="clearBCPBasic" :class="{'is-invalid': 緊急連絡先_電話番号_has_error}" />
                                    </div>
                                    <label class="col-1 d-flex align-items-center justify-content-center py-2 col-form-label">
                                        －
                                    </label>
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="緊急連絡先_電話番号3" :required="clearBCPBasic" :class="{'is-invalid': 緊急連絡先_電話番号_has_error}" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label ms-md-5">
                                予備電話番号
                            </label>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="緊急連絡先_予備電話番号1" :class="{'is-invalid': 緊急連絡先_予備電話番号_has_error}" />
                                        <div class='invalid-feedback'>{{ 緊急連絡先_予備電話番号_message }}</div>
                                    </div>
                                    <label class="col-1 d-flex align-items-center justify-content-center py-2 col-form-label">
                                        －
                                    </label>
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="緊急連絡先_予備電話番号2" :class="{'is-invalid': 緊急連絡先_予備電話番号_has_error}" />
                                    </div>
                                    <label class="col-1 d-flex align-items-center justify-content-center py-2 col-form-label">
                                        －
                                    </label>
                                    <div class="col-3">
                                        <input type="text" class="form-control" maxlength="10" v-model="緊急連絡先_予備電話番号3" :class="{'is-invalid': 緊急連絡先_予備電話番号_has_error}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <label class="col-md-3 col-form-label">
                        持病・アレルギー
                    </label>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <div class="row mt-2" v-for="condition in conditions" :key="condition.seq">
                            <input type="text" class="form-control" maxlength="50" v-model="condition.持病" />
                        </div>
                    </div>
                    <div class="p2 ms-4 mt-3 col-md-2 d-flex align-items-end">
                        <input type="button" class="btn btn-black btn-sm" value="行を増やす" @click="addCondition">
                    </div>
                </div>

                <div class="row mt-3">
                    <label class="col-md-3 col-form-label">
                        かかりつけ医師
                    </label>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <div class="row mt-2" v-for="care in cares" :key="care.seq">
                            <input type="text" class="form-control" maxlength="100" v-model="care.かかりつけ医" />
                        </div>
                    </div>
                    <div class="p2 ms-4 mt-3 col-md-2 d-flex align-items-end">
                        <input type="button" class="btn btn-black btn-sm" value="行を増やす" @click="addCare">
                    </div>
                </div>

                <!-- <div class="row mt-5">
                    <label class=" col-form-label">わたしに何かあった場合の{{ callReplaceWord('組織') }}への連絡先{{ callReplaceWord('従業員') }}リストに掲載されます。</label>
                </div>
                <div class="row mt-3">
                    <label class="col-form-label">所属長の氏名</label>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <input type="text" class="form-control" maxlength="50" v-model="所属長名" />
                    </div>
                    <div class="p2 ms-4 mt-3 col-md-2 d-flex align-items-center justify-content-center">
                        <input type="button" class="btn btn-warning btn-sm" value="&emsp;所属長の許可を得る&emsp;">
                    </div>
                </div>
                    
                <div class="row mt-3">
                    <label class=" col-form-label">所属長代理者の氏名</label>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <input type="text" class="form-control" maxlength="50" v-model="所属長代理者名" />
                    </div>
                    <div class="p2 ms-4 mt-3 col-md-2 d-flex align-items-center justify-content-center">
                        <input type="button" class="btn btn-warning btn-sm" value="所属長代理の許可を得る">
                    </div>
                </div> -->

                <div class="card my-5 basic-area border-black">
                    <div class="card-body">
                        <div class="row mt-1">
                            <div class="p2 ms-md-2 col-md-1 d-flex blue-back text-white align-items-center justify-content-center py-2">
                                基本以上
                            </div>
                            <div class="p2 col-md-4 d-flex align-items-center justify-content-center py-2">
                                ※{{ callReplaceWord('従業員') }}連絡先リストに掲載されます。
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label class="col-md-3 col-form-label">
                                〇　災害時による{{ callReplaceWord('出社') }}可能性
                            </label>
                        </div>

                        <div class="row mt-3">
                            <label class="ms-md-2 col-md-8 col-form-label">
                                自宅から{{ callReplaceWord('組織') }}までの距離（徒歩による{{ callReplaceWord('出社') }}等の可能性判断）
                            </label>
                        </div>
                            
                        <div class="row">
                            <label class="ms-3 col-2 col-md-1 col-form-label">
                                約
                            </label>
                            <div class="col-5 col-md-2">
                                <input type="number" class="form-control" step="0.01" v-model="自宅までの距離" />
                            </div>
                            <label class="ms-3 col-2 col-md-1 col-form-label">
                                Km
                            </label>
                        </div>

                        <div class="row mt-3">
                            <label class="ms-md-2 col-md-3 col-form-label">
                                平時の通勤手段
                            </label>
                        </div>

                        <div class="row">
                            <div class="ms-md-4 col-md-8">
                                <input type="text" class="form-control" maxlength="255" v-model="交通手段" />
                            </div>
                        </div>

                        <div class="row mt-3">
                            <label class="col-md-8 col-form-label">
                                〇　資格・技能等（事業復旧時に有効と考えられるもの）
                            </label>
                        </div>
                        <div class="mt-3 custom-control custom-checkbox">
                            <div class="row ms-md-4">
                                <div class="col-md-4 ps-md-0">
                                    <input type="checkbox" class="custom-control-input" id="応急処置" v-model="資格技能_応急処置">
                                    <label class="ms-3 custom-control-label" for="応急処置"> 応急処置</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="checkbox" class="custom-control-input" id="CPR（心肺蘇生法）" v-model="資格技能_心肺蘇生法">
                                    <label class="ms-3 custom-control-label" for="CPR（心肺蘇生法）">CPR（心肺蘇生法）</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="checkbox" class="custom-control-input" id="アマチュア無線" v-model="資格技能_アマチュア無線">
                                    <label class="ms-3 custom-control-label" for="アマチュア無線">アマチュア無線</label>
                                </div>
                            </div>


                        </div>

                        <div class="mt-3 custom-control custom-checkbox">
                            <input type="checkbox" class="ms-md-4 custom-control-input" id="建設・輸送機械操作免許" v-model="資格技能_建設輸送機械操作免許">
                            <label class="ms-3 custom-control-label" for="建設・輸送機械操作免許"> 建設・輸送機械操作免許</label>
                        </div>

                        <div class="row">
                            <label class="ms-md-5 col-md-2 col-form-label">
                                操作対象：
                            </label>
                            <div class="col-md-4">
                                <input type="text" class="form-control" maxlength="255" :disabled="資格技能_建設輸送機械操作免許 === false" v-model="資格技能_建設輸送機械操作免許_操作対象" />
                            </div>
                        </div>

                        <div class="mt-3 custom-control custom-checkbox">
                            <input type="checkbox" class="ms-md-4 custom-control-input" id="自動二輪、大型特殊車両等の運転免許" v-model="資格技能_自動二輪大型特殊車両等の運転免許">
                            <label class="ms-3 custom-control-label" for="自動二輪、大型特殊車両等の運転免許">
                                自動二輪、大型特殊車両等の運転免許
                            </label>
                        </div>
                        <div class="row">
                            <label class="ms-md-5 col-md-2 col-form-label">
                                対象：
                            </label>
                            <div class="col-md-4">
                                <input type="text" class="form-control" maxlength="255" v-model="資格技能_自動二輪大型特殊車両等の運転免許_対象" :disabled='資格技能_自動二輪大型特殊車両等の運転免許 === false' />
                            </div>
                        </div>

                        <div class="mt-3 custom-control custom-checkbox">
                            <input type="checkbox" class="ms-md-4 custom-control-input" id="緊急時に有効なその他の資格" v-model="資格技能_緊急時に有効なその他の資格">
                            <label class="ms-3 custom-control-label" for="緊急時に有効なその他の資格">
                                緊急時に有効なその他の資格
                            </label>
                            <div class="ms-md-5 col-md-8">
                                <input type="text" class="form-control" maxlength="255" v-model="資格技能_緊急時に有効なその他の資格_内容" :disabled="資格技能_緊急時に有効なその他の資格 === false" />
                            </div>
                        </div>

                        <div class="row mt-3 pb-3">
                            <label class="col-md-8 col-form-label">
                                〇　備考
                            </label>
                            <div class="ms-md-5 col-md-8">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="備考"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ms-5 mt-5">
                <div class="row">
                    <div class="col-md-4 m-3">
                        <input type="submit" class="btn btn-black px-4 py-2" value="上記内容でわたしを登録する" :disabled="isProcessing">
                        <div class="flash alert" :class="this.flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                            {{ flashMessage }}
                        </div>
                    </div>
                    <div class="col-md-4 m-3">
                        <input type="button" class="ms-5 btn btn-green px-4 py-2" value="マイページへ戻る" @click="backMyPage">
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            //血液型
            if(props.user_detail){
                if(props.user_detail.血液型 === '不明'){
                    血液型不明.value = true;
                }else{
                    血液型不明.value = false;
                    血液型.value = props.user_detail.血液型;
                }
            }
            //自宅電話（ユーザー詳細）
            if(props.user_detail && props.user_detail.自宅電話){
                const arr自宅電話 = props.user_detail.自宅電話.split('-');
                自宅電話1.value = arr自宅電話[0];
                自宅電話2.value = arr自宅電話[1];
                自宅電話3.value = arr自宅電話[2];
            }
            //携帯電話（ユーザーテーブル）
            if(props.user_info.電話番号){
                const arr電話番号 = props.user_info.電話番号.split('-');
                携帯電話1.value = arr電話番号[0];
                携帯電話2.value = arr電話番号[1];
                携帯電話3.value = arr電話番号[2];
            }
            //携帯メールアドレス（ユーザー詳細）
            if(props.user_detail && props.user_detail.携帯メールアドレス){
                携帯メールアドレス.value = props.user_detail.携帯メールアドレス;
            }
            //組織用メールアドレス（ユーザーテーブル）
            組織用メールアドレス.value = props.user_info.email;
            //自宅の住所
            if(props.user_detail){
                自宅郵便番号.value = props.user_detail.自宅郵便番号;
                自宅都道府県.value = props.user_detail.自宅都道府県;
                自宅市区町村.value = props.user_detail.自宅市区町村;
                自宅番地.value = props.user_detail.自宅番地;
            }
            //自宅連絡先_電話番号
            if(props.user_detail && props.user_detail.自宅連絡先_電話番号){
                const arr自宅連絡先_電話番号 = props.user_detail.自宅連絡先_電話番号.split('-');
                自宅連絡先_電話番号1.value = arr自宅連絡先_電話番号[0];
                自宅連絡先_電話番号2.value = arr自宅連絡先_電話番号[1];
                自宅連絡先_電話番号3.value = arr自宅連絡先_電話番号[2];
            }
            if(props.user_detail){
                //自宅連絡先_メールアドレス
                自宅連絡先_メールアドレス.value = props.user_detail.自宅連絡先_メールアドレス;
                //緊急連絡先_氏名
                緊急連絡先_氏名.value = props.user_detail.緊急連絡先_氏名;
                //緊急連絡先_続柄
                緊急連絡先_続柄.value = props.user_detail.緊急連絡先_続柄;
            }
            //緊急連絡先_電話番号
            if(props.user_detail && props.user_detail.緊急連絡先_電話番号){
                const arr緊急連絡先_電話番号 = props.user_detail.緊急連絡先_電話番号.split('-');
                緊急連絡先_電話番号1.value = arr緊急連絡先_電話番号[0];
                緊急連絡先_電話番号2.value = arr緊急連絡先_電話番号[1];
                緊急連絡先_電話番号3.value = arr緊急連絡先_電話番号[2];
            }
            //緊急連絡先_予備電話番号
            if(props.user_detail && props.user_detail.緊急連絡先_予備電話番号){
                const arr緊急連絡先_予備電話番号 = props.user_detail.緊急連絡先_予備電話番号.split('-');
                緊急連絡先_予備電話番号1.value = arr緊急連絡先_予備電話番号[0];
                緊急連絡先_予備電話番号2.value = arr緊急連絡先_予備電話番号[1];
                緊急連絡先_予備電話番号3.value = arr緊急連絡先_予備電話番号[2];
            }

            //持病
            if(props.user_conditions.length > 0){
                conditions.splice(0);
                for(let i = 0; i < props.user_conditions.length; i++){
                    conditions.push({
                        'seq': props.user_conditions[i].seq,
                        '持病': props.user_conditions[i].持病,
                    });
                }
            }

            //かかりつけ医
            if(props.user_primary_cares.length > 0){
                cares.splice(0);
                for(let i = 0; i < props.user_primary_cares.length; i++){
                    cares.push({
                        'seq': props.user_primary_cares[i].seq,
                        'かかりつけ医': props.user_primary_cares[i].かかりつけ医,
                    });
                }
            }

            if(props.user_detail){
                //所属長名
                所属長名.value = props.user_detail.所属長名;
                //所属長代理者名
                所属長代理者名.value = props.user_detail.所属長代理者名;
                //自宅までの距離
                自宅までの距離.value = props.user_detail.自宅までの距離;
                //交通手段
                交通手段.value = props.user_detail.交通手段;
                //資格技能_応急処置
                資格技能_応急処置.value = props.user_detail.資格技能_応急処置 === 1 ? true : false;
                //資格技能_心肺蘇生法
                資格技能_心肺蘇生法.value = props.user_detail.資格技能_心肺蘇生法 === 1 ? true : false;
                //資格技能_アマチュア無線
                資格技能_アマチュア無線.value = props.user_detail.資格技能_アマチュア無線 === 1 ? true : false;
                //資格技能_建設輸送機械操作免許
                資格技能_建設輸送機械操作免許.value = props.user_detail.資格技能_建設輸送機械操作免許 === 1 ? true : false;
                //資格技能_建設輸送機械操作免許_操作対象
                資格技能_建設輸送機械操作免許_操作対象.value = props.user_detail.資格技能_建設輸送機械操作免許_操作対象;
                //資格技能_自動二輪大型特殊車両等の運転免許
                資格技能_自動二輪大型特殊車両等の運転免許.value = props.user_detail.資格技能_自動二輪大型特殊車両等の運転免許 === 1 ? true : false;
                //資格技能_自動二輪大型特殊車両等の運転免許_対象
                資格技能_自動二輪大型特殊車両等の運転免許_対象.value = props.user_detail.資格技能_自動二輪大型特殊車両等の運転免許_対象;
                //資格技能_緊急時に有効なその他の資格
                資格技能_緊急時に有効なその他の資格.value = props.user_detail.資格技能_緊急時に有効なその他の資格 === 1 ? true : false;
                //資格技能_緊急時に有効なその他の資格_内容
                資格技能_緊急時に有効なその他の資格_内容.value = props.user_detail.資格技能_緊急時に有効なその他の資格_内容;
                //備考
                備考.value = props.user_detail.備考;
            }

            //BCP
            if(props.bcp_info && Object.keys(props.bcp_info).length !== 0 && props.bcp_info.入門確定日 !== null){
                clearBCPBasic.value = true;
            }

            user_send_line.value = props.user_info.isSendLINE;
        });

        const setLevel = ref('111111');

        const isProcessing = ref(false);

        const ユーザー名 = computed(() => {
            return props.user_info.姓 + ' ' + props.user_info.名;
        })

        const 血液型不明 = ref(true);
        const 血液型 = ref('');
        const 自宅電話1 = ref('');
        const 自宅電話2 = ref('');
        const 自宅電話3 = ref('');
        const 携帯電話1 = ref('');
        const 携帯電話2 = ref('');
        const 携帯電話3 = ref('');
        const 携帯メールアドレス = ref('');
        const 組織用メールアドレス = ref('');
        const 自宅郵便番号 = ref('');
        const 自宅都道府県 = ref('');
        const 自宅市区町村 = ref('');
        const 自宅番地 = ref('');
        const 自宅連絡先_電話番号1 = ref('');
        const 自宅連絡先_電話番号2 = ref('');
        const 自宅連絡先_電話番号3 = ref('');
        const 自宅連絡先_メールアドレス = ref('');
        const 緊急連絡先_氏名 = ref('');
        const 緊急連絡先_続柄 = ref('');
        const 緊急連絡先_電話番号1 = ref('');
        const 緊急連絡先_電話番号2 = ref('');
        const 緊急連絡先_電話番号3 = ref('');
        const 緊急連絡先_予備電話番号1 = ref('');
        const 緊急連絡先_予備電話番号2 = ref('');
        const 緊急連絡先_予備電話番号3 = ref('');
        const conditions = reactive([
            {
                seq: 1,
                持病: '',
            }
        ]);

        const cares = reactive([
            {
                seq: 1,
                かかりつけ医: '',
            }
        ])

        const addCondition = () => {
            conditions.push({
                seq: Math.max(...conditions.map((value) => value.seq)) + 1,
                持病: '',
            });
        }

        const addCare = () => {
            cares.push({
                seq: Math.max(...cares.map((value) => value.seq)) + 1,
                かかりつけ医: '',
            });          
        }

        const 所属長名 = ref('');
        const 所属長代理者名 = ref('');
        const 自宅までの距離 = ref(0);
        const 交通手段 = ref('');
        const 資格技能_応急処置 = ref(false);
        const 資格技能_心肺蘇生法 = ref(false);
        const 資格技能_アマチュア無線 = ref(false);
        const 資格技能_建設輸送機械操作免許 = ref(false);
        const 資格技能_建設輸送機械操作免許_操作対象 = ref('');
        const 資格技能_自動二輪大型特殊車両等の運転免許 = ref(false);
        const 資格技能_自動二輪大型特殊車両等の運転免許_対象 = ref('');
        const 資格技能_緊急時に有効なその他の資格 = ref(false);
        const 資格技能_緊急時に有効なその他の資格_内容 = ref('');
        const 備考 = ref('');

        const getAddress = () => {
            const zip_code = 自宅郵便番号.value.replace('-', '');

            const url = '/api/getAddressSeparate?zip=' + zip_code;

            axios.get(url).then(res =>{
                自宅都道府県.value = res.data.prefecture;
                自宅市区町村.value = res.data.municipalitie;
                自宅番地.value = res.data.town;
            });
        }

        const 血液型_has_error = ref(false);
        const 血液型_message = ref('');
        const 自宅電話_has_error = ref(false);
        const 自宅電話_message = ref('');
        const 携帯電話_has_error = ref(false);
        const 携帯電話_message = ref('');
        const 携帯メールアドレス_has_error = ref(false);
        const 携帯メールアドレス_message = ref('');
        const 組織用メールアドレス_has_error = ref(false);
        const 組織用メールアドレス_message = ref('');
        const 自宅連絡先_電話番号_has_error = ref(false);
        const 自宅連絡先_電話番号_message = ref('');
        const 自宅連絡先_メールアドレス_has_error = ref(false);
        const 自宅連絡先_メールアドレス_message = ref('');
        const 緊急連絡先_電話番号_has_error = ref(false);
        const 緊急連絡先_電話番号_message = ref('');
        const 緊急連絡先_予備電話番号_has_error = ref(false);
        const 緊急連絡先_予備電話番号_message = ref('');

        const clearBCPBasic = ref(false);

        const post_form = () => {
            血液型_has_error.value =  false;
            自宅電話_has_error.value = false;
            携帯電話_has_error.value = false;
            携帯メールアドレス_has_error.value = false;
            組織用メールアドレス_has_error.value = false;
            自宅連絡先_電話番号_has_error.value = false;
            自宅連絡先_メールアドレス_has_error.value = false;
            緊急連絡先_電話番号_has_error.value = false;
            緊急連絡先_予備電話番号_has_error.value = false;

            血液型_message.value = '';
            自宅電話_message.value = '';
            携帯電話_message.value = '';
            携帯メールアドレス_message.value = '';
            組織用メールアドレス_message.value = '';
            自宅連絡先_電話番号_message.value = '';
            自宅連絡先_メールアドレス_message.value = '';
            緊急連絡先_電話番号_message.value = '';
            緊急連絡先_予備電話番号_message.value = '';
            
            const url = '/api/myPage/register_myself';

            isProcessing.value = true;

            axios.post(url, {
                血液型: 血液型不明.value === true ? '不明' : 血液型.value,
                自宅電話: 自宅電話1.value + '-' + 自宅電話2.value + '-' + 自宅電話3.value,
                携帯電話: 携帯電話1.value + '-' + 携帯電話2.value + '-' + 携帯電話3.value,
                携帯メールアドレス: 携帯メールアドレス.value,
                組織用メールアドレス: 組織用メールアドレス.value,
                自宅郵便番号: 自宅郵便番号.value,
                自宅都道府県: 自宅都道府県.value,
                自宅市区町村: 自宅市区町村.value,
                自宅番地: 自宅番地.value,
                自宅連絡先_電話番号: 自宅連絡先_電話番号1.value === '' ? '' : 自宅連絡先_電話番号1.value + '-' + 自宅連絡先_電話番号2.value + '-' + 自宅連絡先_電話番号3.value,
                自宅連絡先_メールアドレス: 自宅連絡先_メールアドレス.value,
                緊急連絡先_氏名: 緊急連絡先_氏名.value,
                緊急連絡先_続柄: 緊急連絡先_続柄.value,
                緊急連絡先_電話番号: 緊急連絡先_電話番号1.value === '' ? '' : 緊急連絡先_電話番号1.value + '-' + 緊急連絡先_電話番号2.value + '-' + 緊急連絡先_電話番号3.value,
                緊急連絡先_予備電話番号: 緊急連絡先_予備電話番号1.value === '' ? '' : 緊急連絡先_予備電話番号1.value + '-' + 緊急連絡先_予備電話番号2.value + '-' + 緊急連絡先_予備電話番号3.value,
                所属長名: 所属長名.value,
                所属長代理者名: 所属長代理者名.value,
                自宅までの距離: 自宅までの距離.value,
                交通手段: 交通手段.value,
                資格技能_応急処置: 資格技能_応急処置.value,
                資格技能_心肺蘇生法: 資格技能_心肺蘇生法.value,
                資格技能_アマチュア無線: 資格技能_アマチュア無線.value,
                資格技能_建設輸送機械操作免許: 資格技能_建設輸送機械操作免許.value,
                資格技能_建設輸送機械操作免許_操作対象: 資格技能_建設輸送機械操作免許_操作対象.value,
                資格技能_自動二輪大型特殊車両等の運転免許: 資格技能_自動二輪大型特殊車両等の運転免許.value,
                資格技能_自動二輪大型特殊車両等の運転免許_対象: 資格技能_自動二輪大型特殊車両等の運転免許_対象.value,
                資格技能_緊急時に有効なその他の資格: 資格技能_緊急時に有効なその他の資格.value,
                資格技能_緊急時に有効なその他の資格_内容: 資格技能_緊急時に有効なその他の資格_内容.value,
                備考: 備考.value,
                user_conditions: conditions.filter((value) => { return value.持病 !== '' }),
                user_primary_cares: cares.filter((value) => { return value.かかりつけ医 !== '' }),
            }).then(res => {
                visibleFlashMessage('正常に登録されました', 'success');

            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.血液型){
                        血液型_has_error.value = true;
                        血液型_message.value = err.response.data.errors.血液型[0];
                    }
                    if(err.response.data.errors.自宅電話){
                        自宅電話_has_error.value = true;
                        自宅電話_message.value = err.response.data.errors.自宅電話[0];
                    }
                    if(err.response.data.errors.携帯電話){
                        携帯電話_has_error.value = true;
                        携帯電話_message.value = err.response.data.errors.携帯電話[0];
                    }
                    if(err.response.data.errors.携帯メールアドレス){
                        携帯メールアドレス_has_error.value = true;
                        携帯メールアドレス_message.value = err.response.data.errors.携帯メールアドレス[0];
                    }
                    if(err.response.data.errors.組織用メールアドレス){
                        組織用メールアドレス_has_error.value = true;
                        組織用メールアドレス_message.value = err.response.data.errors.組織用メールアドレス[0];
                    }
                    if(err.response.data.errors.自宅連絡先_電話番号){
                        自宅連絡先_電話番号_has_error.value = true;
                        自宅連絡先_電話番号_message.value = err.response.data.errors.自宅連絡先_電話番号[0];
                    }
                    if(err.response.data.errors.自宅連絡先_メールアドレス){
                        自宅連絡先_メールアドレス_has_error.value = true;
                        自宅連絡先_メールアドレス_message.value = err.response.data.errors.自宅連絡先_メールアドレス[0];
                    }
                    if(err.response.data.errors.緊急連絡先_電話番号){
                        緊急連絡先_電話番号_has_error.value = true;
                        緊急連絡先_電話番号_message.value = err.response.data.errors.緊急連絡先_電話番号[0];
                    }
                    if(err.response.data.errors.緊急連絡先_予備電話番号){
                        緊急連絡先_予備電話番号_has_error.value = true;
                        緊急連絡先_予備電話番号_message.value = err.response.data.errors.緊急連絡先_予備電話番号[0];
                    }
                }

            }).finally(fin => {
                isProcessing.value = false;
            });

        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backMyPage = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        const copyMailAddress = () => {
            if(!自宅連絡先_メールアドレス.value){
                自宅連絡先_メールアドレス.value = 携帯メールアドレス.value;
            }
        }

        const user_send_line = ref("");

        const clearSendLINE = () => {
            const url = "/api/clearSendLINE";

            axios.post(url, {
                user_id: props.user_info.id,
            }).then(res=>{
                user_send_line.value = 0;
            })
        }

        return {
            setLevel,
            isProcessing,
            ユーザー名,

            血液型不明,
            血液型,
            自宅電話1,
            自宅電話2,
            自宅電話3,
            携帯電話1,
            携帯電話2,
            携帯電話3,
            携帯メールアドレス,
            組織用メールアドレス,
            自宅郵便番号,
            自宅都道府県,
            自宅市区町村,
            自宅番地,
            自宅連絡先_電話番号1,
            自宅連絡先_電話番号2,
            自宅連絡先_電話番号3,
            自宅連絡先_メールアドレス,
            緊急連絡先_氏名,
            緊急連絡先_続柄,
            緊急連絡先_電話番号1,
            緊急連絡先_電話番号2,
            緊急連絡先_電話番号3,
            緊急連絡先_予備電話番号1,
            緊急連絡先_予備電話番号2,
            緊急連絡先_予備電話番号3,
            conditions,
            addCondition,
            cares,
            addCare,
            所属長名,
            所属長代理者名,
            自宅までの距離,
            交通手段,
            資格技能_応急処置,
            資格技能_心肺蘇生法,
            資格技能_アマチュア無線,
            資格技能_建設輸送機械操作免許,
            資格技能_建設輸送機械操作免許_操作対象,
            資格技能_自動二輪大型特殊車両等の運転免許,
            資格技能_自動二輪大型特殊車両等の運転免許_対象,
            資格技能_緊急時に有効なその他の資格,
            資格技能_緊急時に有効なその他の資格_内容,
            備考,

            血液型_has_error,
            血液型_message,
            自宅電話_has_error,
            自宅電話_message,
            携帯電話_has_error,
            携帯電話_message,
            携帯メールアドレス_has_error,
            携帯メールアドレス_message,
            組織用メールアドレス_has_error,
            組織用メールアドレス_message,
            自宅連絡先_電話番号_has_error,
            自宅連絡先_電話番号_message,
            自宅連絡先_メールアドレス_has_error,
            自宅連絡先_メールアドレス_message,
            緊急連絡先_電話番号_has_error,
            緊急連絡先_電話番号_message,
            緊急連絡先_予備電話番号_has_error,
            緊急連絡先_予備電話番号_message,

            clearBCPBasic,

            post_form,
            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,

            getAddress,

            callReplaceWord,

            backMyPage,

            copyMailAddress,

            user_send_line,
            clearSendLINE,
        }
    },
    props: {
        corp_info: {
            type: Object,
        },
        user_info: {
            type: Object,
        },
        user_detail: {
            type: Object,
        },
        user_conditions: {
            type: Array,
        },
        user_primary_cares: {
            type: Array,
        },
        bcp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
    .enter {
        border: 1px solid;
        color: #2f75b5;
        border-color: #2f75b5;
        background-color: white;
    }

    .font-blue{
        color: #2f75b5;
    }

    .img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .basic-area {
        background: #ddebf7;
    }

    .blue-back {
        background-color: #2353a4;
    }

</style>