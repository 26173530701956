<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 復旧費用の算定</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">［建物全壊時］</label>
                </div>
            </div>
            <div class="hikaku">
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧時間（日）</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧費用（円）</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">建物</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00081'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00081'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00081'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">機械</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00082'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00082'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00082'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('棚卸資産') || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00083'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00083'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00083'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">器具・工具等</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00084'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00084'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00084'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">資産関係 計</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00085'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00085'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">（A）</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('事業') }}中断損失</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info"></div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00086'].value) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">（B）</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧期間・費用推定値</label>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00087'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00087'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border d-flex align-items-center justify-content-start">
                        <label class="form-label">（A）＋（B）＝（C）</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">［建物半壊時］</label>
                </div>
            </div>
            <div class="hikaku">
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">&emsp;</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧時間（日）</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧費用（円）</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">建物</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00088'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00088'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00088'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">機械</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00089'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00089'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00089'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('棚卸資産') || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00090'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00090'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00090'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">器具・工具等</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00091'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00091'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : valueForms['K00091'][3] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">資産関係 計</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00092'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00092'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">（A）</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('事業') }}中断損失</label>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info"></div>
                    </div>
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00093'].value) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">（B）</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧期間・費用推定値</label>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00094'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-2 border border-end-0 bg-info text-end">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF5 ? '&emsp;' : formatNumber(valueForms['K00094'][2]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-4 border d-flex align-items-center justify-content-start">
                        <label class="form-label">（A）＋（B）＝（C）</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 手元資金の状況</label>
                </div>
            </div>
            <div class="hikaku">
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">種類</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">金額（円）</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">投入時期</label>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">備考</label>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">現金・預金</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00095'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="form-label border-white bg-white">即時可能</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00095'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">損害保険金</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00096'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="form-label border-white bg-white">支払までに時間がかかる</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00096'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('組織') }}資産売却</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00097'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="form-label border-white bg-white">換金までに時間がかかる</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00097'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-bottom-0 border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ callReplaceWord('経営者') }}から支援</label>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00098'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="form-label border-white bg-white">{{ callReplaceWord('経営者') }}の意向次第</div>
                    </div>
                    <div class="p2 col-4 border border-bottom-0 bg-info d-flex align-items-center justify-content-start">
                        <div class="form-label bg-info">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00098'][2] || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="p2 col-2 border border-end-0 d-flex align-items-center justify-content-start">
                        <label class="form-label">計</label>
                    </div>
                    <div class="p2 col-3 border border-end-0 bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00099'].value) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-3 border border-end-0 bg-white d-flex align-items-center justify-content-start">
                        <div class="form-label border-white bg-white"></div>
                    </div>
                    <div class="p2 col-4 border d-flex align-items-center justify-content-start">
                        <label class="form-label">（D1）</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 財務診断</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">手元資金（C）と復旧費用（D1）を比較します。</label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">［建物全壊時］</label>
                </div>
            </div>
            <div class="hikaku">
                <div class="row ms-1">
                    <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">手元資金</label>
                    </div>
                    <div class="p2 col-2 border bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00100'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1 bg-white d-flex align-items-center justify-content-center">
                        <label class="form-label text-center" style="font-size:16pt">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00100'][2] || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧費用</label>
                    </div>
                    <div class="p2 col-2 border bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00100'][3]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1 bg-white d-flex align-items-center justify-content-center">
                        <label class="form-label text-center" style="font-size:16pt">➡</label>
                    </div>
                    <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">診断結果</label>
                    </div>
                    <div class="p2 col-3 border bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00101'].value || '&emsp;' }}</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">［建物半壊時］</label>
                </div>
            </div>
            <div class="hikaku pb-5">
                <div class="row ms-1">
                    <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">手元資金</label>
                    </div>
                    <div class="p2 col-2 border bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00102'][1]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1 bg-white d-flex align-items-center justify-content-center">
                        <label class="form-label text-center" style="font-size:16pt">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00102'][2] || '&emsp;' }}</label>
                    </div>
                    <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">復旧費用</label>
                    </div>
                    <div class="p2 col-2 border bg-info text-end">
                        <div class="form-label bg-info text-end">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : formatNumber(valueForms['K00102'][3]) || '&emsp;' }}</div>
                    </div>
                    <div class="p2 col-1 bg-white d-flex align-items-center justify-content-center">
                        <label class="form-label text-center" style="font-size:16pt">➡</label>
                    </div>
                    <div class="p2 col-1 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">診断結果</label>
                    </div>
                    <div class="p2 col-3 border bg-info d-flex align-items-center justify-content-start">
                        <label class="form-label">{{ !is('administrator') && isViewLimitF6 ? '&emsp;' : valueForms['K00103'].value || '&emsp;' }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import { CommonNumberFormat } from '../../function/CommonNumberFormat.js';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const isViewLimitF5 = ref(false);
        const isViewLimitF6 = ref(false);
        const pageNameF5 = 'F5';
        const pageNameF6 = 'F6';

        const valueForms = reactive({
            K00081: {},
            K00082: {},
            K00083: {},
            K00084: {},
            K00085: {},
            K00086: {
                value: '',
            },
            K00087: {},
            K00088: {},
            K00089: {},
            K00090: {},
            K00091: {},
            K00092: {},
            K00093: {
                value: '',
            },
            K00094: {},
            K00095: {},
            K00096: {},
            K00097: {},
            K00098: {},
            K00099: {
                value: '',
            },
            K00100: {},
            K00101: {
                value: '',
            },
            K00102: {},
            K00103: {
                value: '',
            },
            view_limit: {},
        });

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K4f';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K6';
            location.href = url;
        }

        // 取得データをセット
        if (props.bcp_values_info.length > 0) {
            Object.keys(valueForms).forEach(columnName => {
                if (columnName === 'view_limit') {
                    const data = props.bcp_values_info.filter((fetchData) => fetchData.column_name === columnName);
                    data.forEach(
                        (d) => (valueForms[columnName][d.page_name] = d.value)
                    );
                } else if (Object.keys(valueForms[columnName]).includes('value')) {
                    valueForms[columnName].value = props.bcp_values_info.find(
                        (fetchData) => fetchData.column_name === columnName
                    )?.value;
                } else {
                    const data = props.bcp_values_info
                        .filter(
                            (fetchData) => fetchData.column_name === columnName
                        )
                        .sort((a, b) => (a.seq < b.seq ? -1 : 1));

                    data.forEach(
                        (d) => (valueForms[columnName][d.seq] = d.value)
                    );
                }
            });

            valueForms['K00085'][1] = [
                valueForms['K00081'][1],
                valueForms['K00082'][1],
                valueForms['K00083'][1],
                valueForms['K00084'][1],
            ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();
            valueForms['K00085'][2] = [
                valueForms['K00081'][2],
                valueForms['K00082'][2],
                valueForms['K00083'][2],
                valueForms['K00084'][2],
            ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();

            valueForms['K00087'][1] = valueForms['K00085'][1];
            valueForms['K00087'][2] = [
                valueForms['K00085'][2],
                valueForms['K00086'].value
            ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();

            valueForms['K00092'][1] = [
                    valueForms['K00088'][1],
                    valueForms['K00089'][1],
                    valueForms['K00090'][1],
                    valueForms['K00091'][1],
            ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();
            valueForms['K00092'][2] = [
                    valueForms['K00088'][2],
                    valueForms['K00089'][2],
                    valueForms['K00090'][2],
                    valueForms['K00091'][2],
                ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();

            valueForms['K00094'][1] = valueForms['K00092'][1];
            valueForms['K00094'][2] = [
                valueForms['K00092'][2],
                valueForms['K00093'].value
            ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();

            valueForms['K00099'].value = [
                valueForms['K00095'][1],
                valueForms['K00096'][1],
                valueForms['K00097'][1],
                valueForms['K00098'][1],
            ]
                .reduce((sum, curr) => sum + Number(curr || 0), 0)
                .toString();

            const cashOnHand = Number(valueForms['K00099'].value);
            const restorationCostOfTotalDestruction = Number(valueForms['K00087'][2]);
            const restorationCostOfPartialDestruction = Number(valueForms['K00094'][2]);

            valueForms['K00100'][1] = valueForms['K00099'].value;
            if (cashOnHand > restorationCostOfTotalDestruction) {
                valueForms['K00100'][2] = '＞';
                valueForms['K00101'].value = '緊急時はお金の心配なしに復旧対策に集中しましょう。';
            } else if (cashOnHand < restorationCostOfTotalDestruction) {
                valueForms['K00100'][2] = '＜';
                valueForms['K00101'].value = '災害貸付制度などを利用して資金を借りる必要があります。';
            } else {
                valueForms['K00100'][2] = '＝';
                valueForms['K00101'].value = '資金調達がいつでもできるよう事前に準備をしておきましょう。';
            }
            valueForms['K00100'][3] = valueForms['K00087'][2];

            valueForms['K00102'][1] = valueForms['K00099'].value;
            if (cashOnHand > restorationCostOfPartialDestruction) {
                valueForms['K00102'][2] = '＞';
                valueForms['K00103'].value = '緊急時はお金の心配なしに復旧対策に集中しましょう。';
            } else if (cashOnHand < restorationCostOfPartialDestruction) {
                valueForms['K00102'][2] = '＜';
                valueForms['K00103'].value = '災害貸付制度などを利用して資金を借りる必要があります。';
            } else {
                valueForms['K00102'][2] = '＝';
                valueForms['K00103'].value = '資金調達がいつでもできるよう事前に準備をしておきましょう。';
            }
            valueForms['K00102'][3] = valueForms['K00094'][2];
        }

        isViewLimitF5.value = valueForms['view_limit'][pageNameF5] === '1';
        isViewLimitF6.value = valueForms['view_limit'][pageNameF6] === '1';

        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        return {
            setLevel,
            isMargin1,
            isViewLimitF5,
            isViewLimitF6,
            valueForms,
            callReplaceWord,
            showBack,
            showNext,
            formatNumber,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        bcp_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.form-control {
    border: none;
    color: common_colors.colors(resilience-black);
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

.min-width-800 {
    min-width: 800px;
}

.hikaku {
    width: 100%;
}

@media (max-width: 576px) {
    .hikaku {
        font-size: 0.5em;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border {
        border-color: black !important;
    }

    .hikaku {
        width: 100%;
    }
}
</style>
