<template>
    <div>
        <div class="row mt-3 pb-1 border-bottom-red">
            <div class="h4 mt-5 color-resilience-red">
                クラファン会員規約
            </div>
        </div>

        <div class="row">
            <div class="p2 ms-4 mt-3 d-flex align-items-center justify-content-start py-2">
                本規程は、TOTONO-Lサイトの利用規約を補完するクラファン会員専用の規程です。クラファン会員は、利用規約と共に本規約を十分に理解しこれを承諾したうえで、本サービスを利用するものとします。
            </div>
        </div>
            
        <div class="row ms-4 mt-3">
            <ol class="color-resilience-red">
                <li>
                    会員種別
                    <ul class="list-second py-3">
                        <li>
                            クラファン会員は、次の中からいずれか1つの会員種別を選択し、当会定款第５条の会員として登録するものとします。
                            <ul class="list-third py-3">
                                <li class="pb-3">
                                    企業会員
                                </li>
                                <li class="pb-3">
                                    団体会員
                                </li>
                                <li>
                                    公共会員
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    クラファン会員の地位
                    <ul class="list-second py-3">
                        <li>
                            クラファン会員は、「一般社団法人及び一般財団法人に関する法律」による社員の地位に該当しない会員とします。
                        </li>
                    </ul>
                </li>
                <li>
                    入会申込
                    <ul class="list-second py-3">
                        <li>
                            本サービスの利用を目的に、TOTONO-Lサイト上でクラファン会員の登録手続を行ったユーザーは、当会定款第6条の入会申し込みを行ったものとみなします。
                        </li>
                    </ul>
                </li>
                <li>
                    クーポンコードの利用
                    <ul class="list-second py-3">
                        <li class="pb-3">
                            クーポンコードは、当会が発行するクラファン会員専用の確認コードです。クラウドファンディングへの支援者に対しメールで付与するものです。
                        </li>
                        <li class="pb-3">
                            クーポンコードの利用は1回のみ使用ができ、使用後はその権利が消失します。また、すでに手続きが終了しているクーポンコードはご利用いただけません。
                        </li>
                        <li>
                            クーポンコードを紛失された場合は、再発行を行なうことはできません。
                        </li>
                    </ul>
                </li>
                <li>
                    クーポンコードの利用できる期間
                    <ul class="list-second py-3">
                        <li>
                            クラファン会員は、当会が定める年会費をクラウドファンディングのリターンに代えて、本サービスを2024年9月末日まで利用できるものとします。
                        </li>
                    </ul>
                </li>
                <li>
                    クーポンコードの譲渡・転売の禁止
                    <ul class="list-second py-3">
                        <li>
                            クーポンコードを他人に譲渡することや転売することはできません。万一このような行為が発覚した場合には当会は譲渡や転売を行なった本人およびそのクーポンコードを使用された第三者の双方に損害賠償を行なえるものとします。
                        </li>
                    </ul>
                </li>
                <li>
                    有償会員への切り替え
                    <ul class="list-second py-3">
                        <li>
                            クラファン会員は、上記期間終了時に有償会員と同様に更新手続を行うことができます。
                        </li>
                    </ul>
                </li>
                <li>
                    付則
                    <ul class="list-second-none py-3">
                        <li class="pb-3">
                            本規約の改廃は、当会の理事会において行なう。
                        </li>
                        <li>
                            制定：2022年12月8日
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
        
        <div class="mt-5">
            <input type="button" class="btn btn-black px-3" value="確認したので、このページを綴じる" @click="closeTab" />
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const closeTab = () => {
            window.close();
        }

        return {
            closeTab,
        }
        
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.list-second, .list-second-none {
    color: initial;
}

.list-second > li {
    list-style-type: disc;
}

.list-second-none > li {
    list-style-type: none;
}

.list-third > li {
    position: relative;
    padding: 0px 0px 0px 2em;
    list-style: none;
}
.list-third > li::before {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%) rotate(45deg);
    width: 0.3em;
    height: 0.3em;
    background-color: common_colors.colors('resilience-black');
    content: "";
}
</style>