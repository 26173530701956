<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            基本コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">3. 中核{{ callReplaceWord('事業') }}と復旧目標</label>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap text-nowrap">様式06-1</span>
                    <span class="ms-3 bg-bcp-basic text-light px-4 py-1 col-form-label text-nowrap text-nowrap">様式06-2</span>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">• 当社における中核事業及びそれに係る情報は以下のとおりである。</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">中核事業に係る情報</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 border border-gray  bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">中核{{ callReplaceWord('事業') }}</label>
                </div>
                <div class="p2 col-md-7 border border-gray d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ coreBusinessContent || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">中核{{ callReplaceWord('事業') }}の社内責任者 氏名</label>
                </div>
                <div class="p2 col-md-3 border border-gray d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ inCompanyManager || '&emsp;' }}</div>
                </div>
                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ jobTitle || '&emsp;' }}</div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-5 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">中核{{ callReplaceWord('事業') }}中断の場合の損失額（含む違約金等）</label>
                </div>
                <div class="p2 col-10 col-md-3 border border-gray d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ loss == null ? '&emsp;' : loss }}</div>
                </div>
                <div class="p2 col-2 col-md-4 d-flex align-items-end justify-content-start">
                    <label class="form-label">円</label>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-3">
        <div class="card-body">
            <template v-for="(item, index) in deliveriesInfo">
                <div class="row mx-md-1">
                    <div class="col-md-5 border border-gray bg-gray-3 d-flex align-items-center py-2">
                        <span class="form-label">中核{{ callReplaceWord('事業') }}による{{ callReplaceWord('商品') }}の{{ callReplaceWord('納入') }}先</span>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-5 border border-gray py-2">
                                <span class="form-label">{{ callReplaceWord('納入') }}先名</span>
                            </div>
                            <div class="col border border-gray bg-info py-2">
                                <span class="form-label">{{ !is('administrator') && deliveriesInfo[0].isViewLimit ? '&emsp;' : item.corporateName || '&emsp;' }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 border border-gray py-2">
                                <span class="form-label">主要連絡先（電話番号等）</span>
                            </div>
                            <div class="col border border-gray bg-info py-2">
                                <span class="form-label">{{ !is('administrator') && deliveriesInfo[0].isViewLimit ? '&emsp;' : item.phoneNumber || '&emsp;' }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 border border-gray py-2">
                                <span class="form-label">{{ callReplaceWord('納入') }}先担当者</span>
                            </div>
                            <div class="col border border-gray bg-info py-2">
                                <span class="form-label">{{ !is('administrator') && deliveriesInfo[0].isViewLimit ? '&emsp;' : item.person || '&emsp;' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-for="(item, index) in vendorsInfo">
                <div class="row mx-md-1" :class="{ 'mt-3': index === 0 }">
                    <div class="col-md-5 border border-gray bg-gray-3 d-flex align-items-center py-2">
                        <span class="form-label">中核{{ callReplaceWord('事業') }}に必要な{{ callReplaceWord('原材料') }}の{{ callReplaceWord('仕入') }}先</span>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-5 border border-gray py-2">
                                <span class="form-label">{{ callReplaceWord('仕入') }}先名</span>
                            </div>
                            <div class="col border border-gray bg-info py-2">
                                <span class="form-label">{{ !is('administrator') && vendorsInfo[0].isViewLimit ? '&emsp;' : item.corporateName || '&emsp;' }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 border border-gray py-2">
                                <span class="form-label">主要連絡先（電話番号等）</span>
                            </div>
                            <div class="col border border-gray bg-info py-2">
                                <span class="form-label">{{ !is('administrator') && vendorsInfo[0].isViewLimit ? '&emsp;' : item.phoneNumber || '&emsp;' }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 border border-gray py-2">
                                <span class="form-label">{{ callReplaceWord('仕入') }}先担当者</span>
                            </div>
                            <div class="col border border-gray bg-info py-2">
                                <span class="form-label">{{ !is('administrator') && vendorsInfo[0].isViewLimit ? '&emsp;' : item.person || '&emsp;' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div class="card mt-3">
        <div class="card-body">
            <template v-for="(item, index) in disasterAssumptionsInfo">
                <div class="row mx-md-1" :class="{ 'mt-3': index !== 0 }">
                    <div class="p2 col-md-5 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">中核{{ callReplaceWord('事業') }}中断の可能性がある災害等</label>
                    </div>
                    <div class="p2 col-md-7 border border-gray d-flex align-items-center justify-content-start">
                        <div class="form-control border-white">{{ item.disasterAssumptionContent || '&emsp;' }}</div>
                    </div>
                </div>
                <div class="row mx-md-1">
                    <div class="p2 col-md-5 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                        <label class="form-label">目標復旧時間</label>
                    </div>
                    <div class="p2 col-4 col-md-3 border border-gray d-flex align-items-center justify-content-start">
                        <div class="form-control border-white">{{ item.targetRecoveryTime == null ? '&emsp;' : item.targetRecoveryTime }}</div>
                    </div>
                    <div class="p2 col-8 col-md-4 d-flex align-items-center justify-content-center">
                        <div class="ms-auto form-check form-check-inline">
                            <input class="form-check-input" type="radio" :name="'inlineRadioOptions' + index" id="inlineRadio1" value="時間" onclick="return false;" v-model="item.timeDivision">
                            <label class="form-check-label" for="inlineRadio1">時間</label>
                        </div>
                        <div class="ms-auto form-check form-check-inline">
                            <input class="form-check-input" type="radio" :name="'inlineRadioOptions' + index" id="inlineRadio2" value="週間" onclick="return false;" v-model="item.timeDivision">
                            <label class="form-check-label" for="inlineRadio2">週間</label>
                        </div>
                        <div class="ms-auto form-check form-check-inline">
                            <input class="form-check-input" type="radio" :name="'inlineRadioOptions' + index" id="inlineRadio3" value="日" onclick="return false;" v-model="item.timeDivision">
                            <label class="form-check-label" for="inlineRadio3">日</label>
                        </div>
                    </div>
                </div>
            </template>

            <div class="row mt-3 mx-md-1">
                <div class="p2 col-md-5 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-7 border border-gray d-flex align-items-center justify-content-start">
                    <div class="form-control border-white">{{ note || '&emsp;' }}</div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">中核{{ callReplaceWord('事業') }}の継続に必要な重要業務 </label>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <template v-for="(item, index) in importantBusinessesInfo">
                        <div class="row mx-md-1" :class="{ 'mt-3': index !== 0 }">
                            <div class="p2 col-md-6 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="col-form-label">重要業務</label>
                            </div>
                            <div class="p2 col-md-6 border border-gray d-flex align-items-center justify-content-start">
                                <div class="form-control border-white">{{ item.importantBusinessContent || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row mx-md-1">
                            <div class="p2 col-md-3 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                                <span class="col-form-label">重要業務に必要な資源</span>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                        <div class="col-form-label">人手による一部代替が不可能な資源</div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.impossibleRequiredResources[0]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.impossibleRequiredResources[1]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.impossibleRequiredResources[2]?.resource || '&emsp;' }}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.impossibleRequiredResources[3]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.impossibleRequiredResources[4]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.impossibleRequiredResources[5]?.resource || '&emsp;' }}</div>
                                            </div>
                                        </div>
                                        <template v-if="item.impossibleRequiredResources.length >= 7" v-for="(_, i) in createArray(Math.ceil(item.impossibleRequiredResources.length / 3))">
                                            <div class="row" v-if="i >= 2">
                                                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                    <div class="form-control border-white">{{ item.impossibleRequiredResources[i * 3]?.resource || '&emsp;' }}</div>
                                                </div>
                                                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                    <div class="form-control border-white">{{ item.impossibleRequiredResources[i * 3 + 1]?.resource || '&emsp;' }}</div>
                                                </div>
                                                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                    <div class="form-control border-white">{{ item.impossibleRequiredResources[i * 3 + 2]?.resource || '&emsp;' }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                        <div class="col-form-label">人手による一部代替が可能な資源</div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[0]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[1]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[2]?.resource || '&emsp;' }}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[3]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[4]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[5]?.resource || '&emsp;' }}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[6]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[7]?.resource || '&emsp;' }}</div>
                                            </div>
                                            <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                <div class="form-control border-white">{{ item.possibleRequiredResources[8]?.resource || '&emsp;' }}</div>
                                            </div>
                                        </div>
                                        <template v-if="item.possibleRequiredResources.length >= 10" v-for="(_, i) in createArray(Math.ceil(item.possibleRequiredResources.length / 3))">
                                            <div class="row" v-if="i >= 3">
                                                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                    <div class="form-control border-white">{{ item.possibleRequiredResources[i * 3]?.resource || '&emsp;' }}</div>
                                                </div>
                                                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                    <div class="form-control border-white">{{ item.possibleRequiredResources[i * 3 + 1]?.resource || '&emsp;' }}</div>
                                                </div>
                                                <div class="p2 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                                    <div class="form-control border-white">{{ item.possibleRequiredResources[i * 3 + 2]?.resource || '&emsp;' }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-md-1">
                            <div class="p2 col-6 col-md-3 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">重要業務の責任者</label>
                            </div>
                            <div class="p2 col-6 col-md-3 border border-gray d-flex align-items-center justify-content-start">
                                <div class="form-control border-white">{{ item.importantBusinessManager || '&emsp;' }}</div>
                            </div>
                            <div class="p2 col-6 col-md-2 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">責任者の連絡先</label>
                            </div>
                            <div class="p2 col-6 col-md-4 border border-gray d-flex align-items-center justify-content-start">
                                <div class="form-control border-white text-nowrap">{{ item.responsibleContactPhoneNumber || '&emsp;' }}</div>
                            </div>
                        </div>
                        <div class="row mx-md-1">
                            <div class="p2 col-md-3 border border-gray bg-gray-3 d-flex align-items-center justify-content-start">
                                <label class="form-label">備考</label>
                            </div>
                            <div class="p2 col-md-9 border border-gray d-flex align-items-center justify-content-start">
                                <div class="form-control border-white">{{ item.note || '&emsp;' }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('010000');
        const isMargin1 = ref(true);
        const coreBusinessContent = ref('');
        const inCompanyManager = ref('');
        const jobTitle = ref('');
        const loss = ref(0);
        const note = ref('');
        const typeDelivery = '1';
        const typeVendor = '2';

        const deliveriesInfo = reactive([]);
        const vendorsInfo = reactive([]);
        let disasterAssumptionsInfo = reactive([]);
        let importantBusinessesInfo = reactive([]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/browse/K2';
            location.href = url;
        }

        const showNext = async () => {
            const url = '/browse/K4a';
            location.href = url;
        }

        const createArray = (number) => {
            return [...Array(number)];
        }

        // 取得データをセット
        // 中核事業
        if (props.core_businesses_info.length > 0) {
            const coreBusinessesInfo = props.core_businesses_info[0];
            coreBusinessContent.value = coreBusinessesInfo.core_business_content;
            inCompanyManager.value = coreBusinessesInfo.in_company_manager;
            jobTitle.value = coreBusinessesInfo.job_title;
            loss.value = coreBusinessesInfo.loss;
            note.value = coreBusinessesInfo.note;
        } else {
            // 重要商品情報
            coreBusinessContent.value = props.introduction_important_product_info[0]?.value || '';
            // 責任者情報
            inCompanyManager.value = props.introduction_manager_info[0]?.value || '';
            jobTitle.value = props.introduction_manager_info[1]?.value || '';
        }

        // 納入仕入先
        if (props.delivery_vendors_info.length > 0) {
            props.delivery_vendors_info.forEach((fetchData) => {
                if (fetchData.delivery_vendor_type === typeDelivery) {
                    // 納入先データセット
                    deliveriesInfo.push({
                        deliveryVendorType: typeDelivery,
                        isViewLimit: fetchData.is_view_limit === 1,
                        corpId: fetchData.corp_id,
                        corporateName: fetchData.corporate_name,
                        phoneNumber: fetchData.phone_number,
                        person: fetchData.person,
                        email: fetchData.email,
                    });
                } else {
                    // 仕入先データセット
                    vendorsInfo.push({
                        deliveryVendorType: typeVendor,
                        isViewLimit: fetchData.is_view_limit === 1,
                        corpId: fetchData.corp_id,
                        corporateName: fetchData.corporate_name,
                        phoneNumber: fetchData.phone_number,
                        person: fetchData.person,
                        email: fetchData.email,
                    });
                }
            });
        }

        // 災害想定
        if (props.disaster_assumptions_info.length > 0) {
            disasterAssumptionsInfo = props.disaster_assumptions_info.map(
                (fetchData) => ({
                    disasterAssumptionContent: fetchData.disaster_assumption_content,
                    targetRecoveryTime: fetchData.target_recovery_time,
                    timeDivision: fetchData.time_division,
                })
            );
        }

        // 重要業務
        if (props.important_businesses_info.length > 0) {
            importantBusinessesInfo = props.important_businesses_info.map(
                (fetchData) => ({
                    importantBusinessContent: fetchData.important_business_content,
                    importantBusinessManager: fetchData.important_business_manager,
                    responsibleContactPhoneNumber: fetchData.responsible_contact_phone_number,
                    note: fetchData.note,
                    impossibleRequiredResources:
                        fetchData.impossible_required_resources.map((data) => ({
                            resourceType: data.resource_type,
                            resource: data.resource,
                        })),
                    possibleRequiredResources:
                        fetchData.possible_required_resources.map((data) => ({
                            resourceType: data.resource_type,
                            resource: data.resource,
                        })),
                })
            );
        }

        return {
            setLevel,
            isMargin1,
            callReplaceWord,
            showBack,
            showNext,
            createArray,
            coreBusinessContent,
            inCompanyManager,
            jobTitle,
            loss,
            note,
            deliveriesInfo,
            vendorsInfo,
            disasterAssumptionsInfo,
            importantBusinessesInfo,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        introduction_important_product_info: {
            type: Object,
        },
        introduction_manager_info: {
            type: Object,
        },
        core_businesses_info: {
            type: Object,
        },
        delivery_vendors_info: {
            type: Object,
        },
        disaster_assumptions_info: {
            type: Object,
        },
        important_businesses_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

input[type="radio"] {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-control {
    border: none;
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.form-control {
    color: common_colors.colors(resilience-black);
}

.border-gray {
    border-color: common_colors.colors(resilience-gray) !important;
}


@media print {
    .card {
        border-color: black;
        color: black;
    }

    .form-control {
        color: black;
    }

    .border-gray {
        border-color: black !important;
    }
}
</style>
