<template>
    <div v-show="mode==='回答'">
        <div name="設問1" v-show="safety_confirmation.設問1_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">設問1</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-center">{{ safety_confirmation.設問1_タイトル }}</label>
                </div>
            </div>
            <div class="row mb-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5">
                    <div class="form-check mt-3" v-for="問1 in 問1_arr" :key="問1.key">
                        <input class="form-check-input" :type="safety_confirmation.設問1_選択肢タイプ == 30080001 ? 'radio' : 'checkbox'" v-model="q1" :id="問1.key" :value="問1.name">
                        <label class="form-check-label" :for="問1.key"> {{ 問1.name }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div name="設問2" v-show="safety_confirmation.設問2_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">設問2</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-center">{{ safety_confirmation.設問2_タイトル }}</label>
                </div>
            </div>
            <div class="row mb-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5">
                    <div class="form-check mt-3" v-for="問2 in 問2_arr" :key="問2.key">
                        <input class="form-check-input" :type="safety_confirmation.設問2_選択肢タイプ == 30080001 ? 'radio' : 'checkbox'" v-model="q2" :id="問2.key" :value="問2.name">
                        <label class="form-check-label" :for="問2.key"> {{ 問2.name }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div name="設問3" v-show="safety_confirmation.設問3_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">設問3</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-center">{{ safety_confirmation.設問3_タイトル }}</label>
                </div>
            </div>
            <div class="row mb-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5">
                    <div class="form-check mt-3" v-for="問3 in 問3_arr" :key="問3.key">
                        <input class="form-check-input" :type="safety_confirmation.設問3_選択肢タイプ == 30080001 ? 'radio' : 'checkbox'" v-model="q3" :id="問3.key" :value="問3.name">
                        <label class="form-check-label" :for="問3.key"> {{ 問3.name }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div name="設問4" v-show="safety_confirmation.設問4_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">設問4</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-center">{{ safety_confirmation.設問4_タイトル }}</label>
                </div>
            </div>
            <div class="row mb-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5">
                    <div class="form-check mt-3" v-for="問4 in 問4_arr" :key="問4.key">
                        <input class="form-check-input" :type="safety_confirmation.設問4_選択肢タイプ == 30080001 ? 'radio' : 'checkbox'" v-model="q4" :id="問4.key" :value="問4.name">
                        <label class="form-check-label" :for="問4.key"> {{ 問4.name }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div name="出社可能時間" v-show="safety_confirmation.出社可能時間確認要否 == true && safety_confirmation_detail.family_id == ''">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">□</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-center">{{ callReplaceWord('出社') }}可能時間</label>
                </div>
            </div>
            <div class="row mb-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5">
                    <div class="form-check mt-3" v-for="workable_item in workable_list" :key="workable_item.code">
                        <input class="form-check-input" type="radio" v-model="select出社可能時間" :id="workable_item.code" :value="workable_item.code">
                        <label class="form-check-label" :for="workable_item.code"> {{ workable_item.name }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3 ms-1">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                <label class="form-label text-center">コメント</label>
            </div>
        </div>
        <div class="row mt-3 ms-1">
            <div class="p2 col-md-6 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control" placeholder="30文字まで" maxlength="30" v-model="comment">
            </div>
        </div>

        <div name="位置情報" v-show="safety_confirmation.位置情報確認要否 == true">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">□</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <label class="form-label text-center">位置情報</label>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="select位置情報" value="通知する" id="位置情報を通知する" @change="change位置情報通知有無">
                        <label class="form-check-label" for="位置情報を通知する">
                            位置情報を通知する
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mt-3 ms-1">
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label text-center">&emsp;</label>
                </div>
                <div class="p2 col-md-5 d-flex align-items-center justify-content-start">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="select位置情報" value="通知しない" id="位置情報を通知しない" @change="change位置情報通知有無">
                        <label class="form-check-label" for="位置情報を通知しない">
                            位置情報を通知しない
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5 pb-5 d-flex flex-row">
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                <label class="form-label text-center">&emsp;</label>
            </div>
            <div class="col-md-3">
                <div class="d-flex align-items-center justify-content-start flex-row">
                    <input type="button" class="text-center btn btn-black" value="確 認" @click="confirm">
                </div>
            </div>
        </div>
    </div>
    <div v-show="mode==='確認'">
        <div name="確認1" v-show="safety_confirmation.設問1_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">設問1</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ safety_confirmation.設問1_タイトル }}</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label text-answer">{{ answer1 }}</label>
            </div>
        </div>
        </div>
        <div name="確認2" v-show="safety_confirmation.設問2_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">設問2</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ safety_confirmation.設問2_タイトル }}</label>
                </div>
            </div>
        </div>
        <div class="row ms-1">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label text-answer">{{ answer2 }}</label>
            </div>
        </div>
        <div name="確認3" v-show="safety_confirmation.設問3_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">設問3</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ safety_confirmation.設問3_タイトル }}</label>
                </div>
            </div>
        </div>
        <div class="row ms-1">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label text-answer">{{ answer3 }}</label>
            </div>
        </div>
        <div name="確認4" v-show="safety_confirmation.設問4_タイトル">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">設問4</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">{{ safety_confirmation.設問4_タイトル }}</label>
                </div>
            </div>
        </div>
        <div class="row ms-1">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label text-answer">{{ answer4 }}</label>
            </div>
        </div>

        <div name="出社可能時間" v-show="safety_confirmation.出社可能時間確認要否 == true && safety_confirmation_detail.family_id == ''">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">□　{{ callReplaceWord('出社') }}可能時間</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label text-answer">{{ label出社可能時間 }}</label>
                </div>
            </div>
        </div>

        <div class="row mt-3 ms-1">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label">コメント</label>
            </div>
        </div>
        <div class="row ms-1">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label text-answer">{{ comment }}</label>
            </div>
        </div>

        <div name="位置情報" v-show="safety_confirmation.位置情報確認要否 == true">
            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">□　位置情報</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md">
                    <label class="form-label text-answer">位置情報を{{ select位置情報 }}</label>
                    <label class="form-label text-answer" v-show="select位置情報 === '通知する'">（{{ latitude }}, {{ longitude }}）</label>
                </div>
            </div>
        </div>

        <div class="row mt-5 pb-5 d-flex flex-row">
            <div class="col-2">
                <div class="d-flex align-items-center justify-content-start flex-row">
                    <input type="button" class="text-center btn btn-black" value="修 正" @click="fix">
                </div>
            </div>
            <div class="col-2 ms-5 ms-md-0">
                <div class="d-flex align-items-center justify-content-start flex-row">
                    <input type="button" class="text-center btn btn-black" value="登 録" @click="register">
                </div>
            </div>
        </div>
    </div>
    <div v-show="mode==='登録'">
        <div class="row py-5 ms-1">
            <div class="p2 col-md d-flex align-items-center justify-content-start">
                <label class="form-label">登録が完了しました</label>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue'
import ReplaceWord from '../../function/CommonReplaceWord'
import { computed } from '@vue/reactivity';
import axios from 'axios';

const { getReplaceWord } = ReplaceWord();
export default {
    components: {
    },
    setup(props) {
        const dayjs = inject('dayjs');
        onMounted(() => {
            mode.value = '回答';

            if(props.safety_confirmation.設問1_問1) {問1_arr.value.push({'key': '1_1', 'name':props.safety_confirmation.設問1_問1})}
            if(props.safety_confirmation.設問1_問2) {問1_arr.value.push({'key': '1_2', 'name':props.safety_confirmation.設問1_問2})}
            if(props.safety_confirmation.設問1_問3) {問1_arr.value.push({'key': '1_3', 'name':props.safety_confirmation.設問1_問3})}
            if(props.safety_confirmation.設問1_問4) {問1_arr.value.push({'key': '1_4', 'name':props.safety_confirmation.設問1_問4})}
            if(props.safety_confirmation.設問1_問5) {問1_arr.value.push({'key': '1_5', 'name':props.safety_confirmation.設問1_問5})}
            if(props.safety_confirmation.設問2_問1) {問2_arr.value.push({'key': '2_1', 'name':props.safety_confirmation.設問2_問1})}
            if(props.safety_confirmation.設問2_問2) {問2_arr.value.push({'key': '2_2', 'name':props.safety_confirmation.設問2_問2})}
            if(props.safety_confirmation.設問2_問3) {問2_arr.value.push({'key': '2_3', 'name':props.safety_confirmation.設問2_問3})}
            if(props.safety_confirmation.設問2_問4) {問2_arr.value.push({'key': '2_4', 'name':props.safety_confirmation.設問2_問4})}
            if(props.safety_confirmation.設問2_問5) {問2_arr.value.push({'key': '2_5', 'name':props.safety_confirmation.設問2_問5})}
            if(props.safety_confirmation.設問3_問1) {問3_arr.value.push({'key': '3_1', 'name':props.safety_confirmation.設問3_問1})}
            if(props.safety_confirmation.設問3_問2) {問3_arr.value.push({'key': '3_2', 'name':props.safety_confirmation.設問3_問2})}
            if(props.safety_confirmation.設問3_問3) {問3_arr.value.push({'key': '3_3', 'name':props.safety_confirmation.設問3_問3})}
            if(props.safety_confirmation.設問3_問4) {問3_arr.value.push({'key': '3_4', 'name':props.safety_confirmation.設問3_問4})}
            if(props.safety_confirmation.設問3_問5) {問3_arr.value.push({'key': '3_5', 'name':props.safety_confirmation.設問3_問5})}
            if(props.safety_confirmation.設問4_問1) {問4_arr.value.push({'key': '4_1', 'name':props.safety_confirmation.設問4_問1})}
            if(props.safety_confirmation.設問4_問2) {問4_arr.value.push({'key': '4_2', 'name':props.safety_confirmation.設問4_問2})}
            if(props.safety_confirmation.設問4_問3) {問4_arr.value.push({'key': '4_3', 'name':props.safety_confirmation.設問4_問3})}
            if(props.safety_confirmation.設問4_問4) {問4_arr.value.push({'key': '4_4', 'name':props.safety_confirmation.設問4_問4})}
            if(props.safety_confirmation.設問4_問5) {問4_arr.value.push({'key': '4_5', 'name':props.safety_confirmation.設問4_問5})}

            let wkArray;
            if(props.safety_confirmation_detail.設問1_回答) {
                if(props.safety_confirmation.設問1_選択肢タイプ == 30080002){
                    //複数
                    wkArray = props.safety_confirmation_detail.設問1_回答.split(',');
                    q1.value = wkArray;
                }else{
                    q1.value = props.safety_confirmation_detail.設問1_回答;
                }
            }
            if(props.safety_confirmation_detail.設問2_回答) {
                if(props.safety_confirmation.設問2_選択肢タイプ == 30080002){
                    //複数
                    wkArray = props.safety_confirmation_detail.設問2_回答.split(',');
                    q2.value = wkArray;
                }else{
                    q2.value = props.safety_confirmation_detail.設問2_回答;
                }
            }
            if(props.safety_confirmation_detail.設問3_回答) {
                if(props.safety_confirmation.設問3_選択肢タイプ == 30080002){
                    //複数
                    wkArray = props.safety_confirmation_detail.設問3_回答.split(',');
                    q3.value = wkArray;
                }else{
                    q3.value = props.safety_confirmation_detail.設問3_回答;
                }
            }
            if(props.safety_confirmation_detail.設問4_回答) {
                if(props.safety_confirmation.設問4_選択肢タイプ == 30080002){
                    //複数
                    wkArray = props.safety_confirmation_detail.設問4_回答.split(',');
                    q4.value = wkArray;
                }else{
                    q4.value = props.safety_confirmation_detail.設問4_回答;
                }
            }
            if(props.safety_confirmation_detail.コメント) {
                comment.value = props.safety_confirmation_detail.コメント;
            }
            if(props.safety_confirmation_detail.出社可能時間) {
                select出社可能時間.value = props.safety_confirmation_detail.出社可能時間;
            }

            if(!props.safety_confirmation.位置情報確認要否){
                select位置情報.value = '通知しない';
            }
        })

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const mode = ref('');

        const confirm = () => {
            if(props.safety_confirmation.設問1_タイトル && (props.safety_confirmation.設問1_未回答可 == false && answer1.value === '')){
                alert('設問1を回答してください');
                return false;
            }

            if(props.safety_confirmation.設問2_タイトル && (props.safety_confirmation.設問2_未回答可 == false && answer2.value === '')){
                alert('設問2を回答してください');
                return false;
            }

            if(props.safety_confirmation.設問3_タイトル && (props.safety_confirmation.設問3_未回答可 == false && answer3.value === '')){
                alert('設問3を回答してください');
                return false;
            }

            if(props.safety_confirmation.設問4_タイトル && (props.safety_confirmation.設問4_未回答可 == false && answer4.value === '')){
                alert('設問4を回答してください');
                return false;
            }

            if(props.safety_confirmation.出社可能時間確認要否 == true && label出社可能時間.value === '' && props.safety_confirmation_detail.family_id == ''){
                alert('出社可能時間を回答してください');
                return false;
            }

            if(select位置情報.value === '通知する'){
                getLocation();
            }

            window.scroll(0,0);
            mode.value = '確認';
        }

        const fix = () => {
            window.scroll(0,0);
            mode.value = '回答';
        }

        const register = () => {
            const url = '/api/safety/answer';

            axios.post(url, {
                id: props.safety_confirmation_detail.id,
                回答ステータス: 30100003,
                回答日時: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                設問1_回答: answer1.value,
                設問2_回答: answer2.value,
                設問3_回答: answer3.value,
                設問4_回答: answer4.value,
                コメント: comment.value,
                出社可能時間: select出社可能時間.value,
                位置情報有無: select位置情報.value === '通知する' ? 1 : 0,
                latitude: latitude.value,
                longitude: longitude.value,
            }).then(res => {
                mode.value = '登録';
            }).finally(fin => {

            });
            

        }

        const 問1_arr = ref([]);
        const q1 = ref([]);
        const 問2_arr = ref([]);
        const q2 = ref([]);
        const 問3_arr = ref([]);
        const q3 = ref([]);
        const 問4_arr = ref([]);
        const q4 = ref([]);

        const answer1 = computed(() => {
            return q1.value.toString();
        })

        const answer2 = computed(() => {
            return q2.value.toString();
        })

        const answer3 = computed(() => {
            return q3.value.toString();
        })

        const answer4 = computed(() => {
            return q4.value.toString();
        })

        const select出社可能時間 = ref('');

        const label出社可能時間 = computed(() => {
            if(select出社可能時間.value){
                const wkArray = props.workable_list.filter(ele => ele.code === select出社可能時間.value);
                return wkArray[0].name;
            }
            return "";
        });

        const comment = ref('');

        const select位置情報 = ref('通知する');

        const getLocation = () => {
                if (!navigator.geolocation) {
                    alert('現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。');
                    return
                }

                const options = {
                    enableHighAccuracy: false,
                    timeout: 5000,
                    maximumAge: 0
                }

                navigator.geolocation.getCurrentPosition(success, error, options);
        }

        const latitude = ref('');
        const longitude = ref('');

        const success = (position) => {
            latitude.value = position.coords.latitude
            longitude.value = position.coords.longitude
        }

        const error = (error) => {
            switch (error.code) {
                case 1: //PERMISSION_DENIED
                    alert('位置情報の利用が許可されていません');
                    break;
                case 2: //POSITION_UNAVAILABLE
                    alert('現在位置が取得できませんでした');
                    break;
                case 3: //TIMEOUT
                    alert('タイムアウトになりました');
                    break;
                default:
                    alert('現在位置が取得できませんでした');
                    break;
            }
        }

        const change位置情報通知有無 = () => {
            if(select位置情報.value === '通知しない'){
                latitude.value = '';
                longitude.value = '';
            }
        }

        return {
            callReplaceWord,
            mode,
            confirm,
            fix,
            register,
            問1_arr,
            q1,
            answer1,
            問2_arr,
            q2,
            answer2,
            問3_arr,
            q3,
            answer3,
            問4_arr,
            q4,
            answer4,
            select出社可能時間,
            label出社可能時間,
            comment,
            select位置情報,
            getLocation,
            latitude,
            longitude,
            success,
            error,
            change位置情報通知有無,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        },
        safety_confirmation: {
            type: Object,
        },
        safety_confirmation_detail: {
            type: Object,
        },
        workable_list: {
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
.text-answer {
    color: #0070c0,
}
</style>