<template>
    <div class="w-75">
        <div class="row ms-3 mt-3" v-bind:id="'list-'+ number">
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                個別リストNo.
            </div>
            <div class="p2 col-md-2 border border-secondary border-end-0 text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ number }}
            </div>
            <div class="p2 col-md-1 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                氏名
            </div>
            <div class="p2 col-md-3 border border-secondary border-end-0 text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ user['氏名'] }}
            </div>
            <div class="p2 col-md-1 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                役職
            </div>
            <div class="p2 col-md-3 border border-secondary text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ user['役職'] }}
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                主な責務
            </div>
            <div class="p2 col-md-10 border border-secondary text-dark d-flex align-items-center justify-content-start py-2">
                {{ user['主な責務'] }}
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ callReplaceWord('組織') }}用e-mail
            </div>
            <div class="p2 col-md-4 border border-secondary border-end-0 text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ user['組織用e-mail'] }}
            </div>
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                自宅郵便番号
            </div>
            <div class="p2 col-md-2 border border-secondary text-dark text-center d-flex align-items-center justify-content-start py-2">
                〒{{ user['自宅郵便番号'] }}
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-4 text-dark text-center d-flex align-items-center justify-content-start py-2">
                ◯　BCP発動
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                緊急時の依頼事項
            </div>
            <div class="p2 col-md-10 border border-secondary text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ user['order_matter'] }} &emsp;
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-3 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                緊急時における{{ callReplaceWord('出社') }}可能性
            </div>
            <div class="p2 col-md-3 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                自宅から{{ callReplaceWord('組織') }}までの距離
            </div>
            <div class="p2 col-md-2 border border-secondary border-end-0 text-dark text-center d-flex align-items-center justify-content-start py-2">
                約{{ user['自宅までの距離'] }}Km
            </div>
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                平時の出勤手段
            </div>
            <div class="p2 col-md-2 border border-secondary text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ user['交通手段'] }}
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark d-flex align-items-center justify-content-start py-2">
                {{ callReplaceWord('事業') }}復旧に有効な資格・技能等
            </div>
            <div class="col">
                <div class="row">
                    <div class="p2 col border border-secondary text-dark text-left d-flex align-items-center justify-content-start py-2">
                        {{ user['資格技能'] }}&emsp;
                    </div>
                </div>
                <div class="row ">
                    <div class="p2 col border border-secondary border-top-0 text-dark text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['資格技能詳細'] }}&emsp;
                    </div>
                </div>
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md-1 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                備考
            </div>
            <div class="p2 col-md-11 border border-secondary text-dark text-center d-flex align-items-center justify-content-start py-2">
                {{ user['備考'] }}
            </div>
        </div>
    </div>
    <div class="ms-3 mt-3 d-flex">
        <div class="card mt-3 bg-light-blue border-green w-100">
            <div class="card-body">
                <div class="row ms-3">
                    <div class="p2 col-md-4 text-dark text-center d-flex align-items-center justify-content-start py-2">
                        ◯　緊急連絡先
                    </div>
                </div>
                <div class="row ms-3 mt-3">
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        自宅の住所
                    </div>
                    <div class="p2 col-md-8 border border-secondary bg-light text-dark text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['自宅都道府県'] }}{{ user['自宅市区町村'] }}{{ user['自宅番地'] }}
                    </div>
                </div>

                <div class="row ms-3 mt-3">
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        自宅電話番号
                    </div>
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light text-dark text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['自宅連絡先_電話番号'] }}
                    </div>
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        個人携帯電話
                    </div>
                    <div class="p2 col-md-2 border border-secondary bg-light text-dark text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['個人携帯電話'] }}
                    </div>
                </div>

                <div class="row ms-3 mt-3">
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        自宅用e-mail
                    </div>
                    <div class="p2 col-md-4 border border-secondary bg-light text-dark text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['自宅連絡先_メールアドレス'] }}
                    </div>
                </div>

                <div class="row ms-3 mt-3">
                    <div class="p2 col-md-4 text-dark text-center d-flex align-items-center justify-content-start py-2">
                        ◯　自宅以外の緊急連絡先
                    </div>
                </div>

                <div class="row ms-3 mt-3">
                    <div class="p2 col-md-1 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        氏名
                    </div>
                    <div class="p2 col-md-4 border border-end-0 border-secondary bg-light text-dark text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['緊急連絡先_氏名'] }}
                    </div>
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        本人との続柄
                    </div>
                    <div class="p2 col-md-2 border border-secondary text-dark bg-light text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['緊急連絡先_続柄'] }}
                    </div>
                </div>

                <div class="row ms-3 mt-3">
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        緊急連絡先
                    </div>
                    <div class="p2 col-md-2 border border-secondary border-end-0 text-dark bg-light text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['緊急連絡先_電話番号'] }}
                    </div>
                    <div class="p2 col-md-2 border border-secondary border-end-0 bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
                        予備電話番号
                    </div>
                    <div class="p2 col-md-2 border border-secondary text-dark bg-light text-center d-flex align-items-center justify-content-start py-2">
                        {{ user['緊急連絡先_予備電話番号'] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="ms-3 mt-3">
            <VDropdown
                theme="browsing_restrictions"
            >
                <input type="button" class="btn btn-sm btn-browsing-restrictions rounded-0 text-nowrap" value="閲覧制限｜設定"/>
                <template #popper>
                    <div class="d-flex justify-content-start">
                        <label>閲覧制限</label>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="checkbox" id="checkbox_1" v-model="emergencyContactViewLimit">
                            <label class="form-check-label" for="checkbox_1">
                                あり
                            </label>
                        </div>
                    </div>
                </template>
            </VDropdown>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    emits: ['changeViewLimit'],
    setup(props) {
        const emergencyContactViewLimit = ref(0);
        emergencyContactViewLimit.value = props.value;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        return {
            emergencyContactViewLimit,
            callReplaceWord,
        }
    },
    computed: {
        emergencyContactViewLimit: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('viewLimitChange', newValue);
            }
        }
    },
    props:{
        user: {
            type: Object,
        },
        number: {
            type: Number,
        },
        replacement_word_list: {
            type: Object
        },
        value: {
            type: Boolean
        }
    },
}
</script>

<style lang="scss" scoped>
    .width {
        width: 85%
    }
</style>
