<template>
    <div id="change-paid-modal" v-show="modalVisible" @click.self="closeModal">
        <div id="content">
            <div class="row">
                <label class="col-form-label">これ以降のページを利用される場合は、<br />有償会員に切り替える必要がございます。</label>
            </div>
            <div class="row mt-3">
                <a href="#" @click.prevent.stop="changePaid" class="link-orange">➡ 有償会員切替ページへ</a>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:14pt">
            目次構成
        </div>
        <div class="p2 col-md-2 mt-3 ms-auto d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="p2 col-md-4 d-flex align-items-start justify-content-start" v-if="is_fixed_latest_version">
            <input type="button" class="btn btn-al-inquiry-button btn-lg" style="font-size:12pt" value="最新版のコピーをもとに、次版の策定に取り掛かる" @click="nextVersionStart" :disabled="isProcessing">
        </div>
        <div class="p2 col-md-4 d-flex align-items-center justify-content-center" v-else-if="is_fixed_first_version">
            <input type="button" class="btn btn-new-window-button btn-lg" style="font-size:12pt" value="策定中のBCPを次版として確定する" @click="showVersionFix">
        </div>
    </div>

    <div class="ms-5 text">
        <label class="mt-5">下位コースの入力が完了していない場合、上位コースのボタンはクリックできませんのでご注意ください。</label>
    </div>


    <div class="container-fluid">
        <div class="row px-5">
            <table class="table table-borderless table-responsive" id="contents_table">
                <thead>
                    <tr>
                        <th class="bg-color-intro text-light text-center">入門コース</th>
                        <th></th>
                        <th class="bg-color-basic text-light text-center">基本コース</th>
                        <th></th>
                        <th class="bg-color-middle text-light text-center">中級コース</th>
                        <th></th>
                        <th class="bg-color-advanced text-light text-center">上級コース</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="py-3">
                        <td class="bg-light">表紙</td>
                        <td></td>
                        <td class="bg-light">表紙</td>
                        <td></td>
                        <td class="color-contents-gray bg-light">表紙</td>
                        <td></td>
                        <td class="color-contents-gray bg-light">表紙</td>
                    </tr>
                    <tr>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    1.表紙
                                </div>
                                <div class="col-4">
                                    <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showTitle">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">目次構成</td>
                        <td></td>
                        <td class="color-contents-gray bg-light">目次構成</td>
                        <td></td>
                        <td class="color-contents-gray bg-light">目次構成</td>
                    </tr>
                    <tr>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    1.基本方針
                                </div>
                                <div class="col-4">
                                    <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showBasicPolicyOfIntro">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        目的、基本方針
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    1.基本方針
                                </div>
                                <div class="col-4">
                                    <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showBasicPolicyOfBasic">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの基本方針
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="color-contents-gray bg-light">
                            <div class="row">
                                <div class="col-8">
                                    1.基本方針
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの基本方針
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="color-contents-gray bg-light">
                            <div class="row">
                                <div class="col-8">
                                    1.基本方針
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの基本方針
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    2.BCPの運用体制
                                </div>
                                <div class="col-4">
                                    <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showOperationStructureOfIntro">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        緊急時の人員体制
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    2.BCPの運用体制
                                </div>
                                <div class="col-4">
                                    <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showOperationStructureOfBasic">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの策定・運用体制
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        {{ callReplaceWord('従業員') }}携帯カード
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="color-contents-gray bg-light">
                            <div class="row">
                                <div class="col-8">
                                    2.BCPの運用体制
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの策定・運用体制
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        {{ callReplaceWord('従業員') }}携帯カード
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    2.BCPの運用体制
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの策定・運用体制
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        {{ callReplaceWord('従業員') }}携帯カード
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        複数企業連携によるBCPの策定・運用体制
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="offset-8 col-4">
                                    <span class="badge btn-high cursor-pointer px-2" v-if="is_display_advanced && !is_fixed_latest_version" @click="showMultipleCompanyLink">ページ移動</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    3.被害想定と事前対策
                                </div>
                                <div class="col-4">
                                    <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showAssumptionAndCounterplan">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        重要{{ callReplaceWord('商品') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        被害想定
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        （ハザードマップによる風水害・震度被害の想定）
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                    ・ 大規模地震（震度5弱以上）で想定される影響
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-4">
                                    インフラ、{{ callReplaceWord('組織') }}への影響
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-4">
                                    {{ callReplaceWord('経営') }}資源（人・モノ・金・情報）への事前対策
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="offset-8 col-4">
                                    <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showImpactByBigEarthquake">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                    ・ 新型コロナウイルス感染症で想定される影響
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="offset-8 col-4">
                                   <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showImpactByCorona">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    3. 中核{{ callReplaceWord('事業' )}}と復旧目標
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showCoreBusinesses">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        中核{{ callReplaceWord('事業' )}}に係る情報
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        中核{{ callReplaceWord('事業' )}}の継続に必要な重要業務
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="ps-2">
                                        {{ callReplaceWord('事業' )}}継続に係る各種資源の代替の情報
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        ・　情報連絡の拠点となる場所
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showSubstituteInformation">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　{{ callReplaceWord('事業' )}}復旧を実施するための場所
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　応援要員
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資金調達
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　通信手段・各種インフラ
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　中核事業に必要となる情報に関する情報
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    3. 中核{{ callReplaceWord('事業' )}}と復旧目標
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        中核{{ callReplaceWord('事業' )}}に係る情報
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        中核{{ callReplaceWord('事業' )}}の継続に必要な重要業務
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        {{ callReplaceWord('事業' )}}継続に係る各種資源の代替の情報
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-2">
                                        ・　情報連絡の拠点となる場所
                                    </div>
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　{{ callReplaceWord('事業' )}}復旧を実施するための場所
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　応援要員
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資金調達
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　通信手段・各種インフラ
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　中核事業に必要となる情報に関する情報
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    3. 中核{{ callReplaceWord('事業' )}}と復旧目標
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        中核{{ callReplaceWord('事業' )}}に係る情報
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        中核{{ callReplaceWord('事業' )}}の継続に必要な重要業務
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        中核事業影響度評価フォーム
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-high cursor-pointer px-2" v-if="is_display_advanced && !is_fixed_latest_version" @click="showCoreBusinessImpactEvaluationForm">ページ移動</span>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        {{ callReplaceWord('事業' )}}継続に係る各種資源の代替の情報
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　{{ callReplaceWord('事業' )}}復旧を実施するための場所
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　応援要員
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資金調達
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　通信手段・各種インフラ
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　中核事業に必要となる情報に関する情報
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col">
                                    4. 財務診断と事前対策計画
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-8">
                                    <div class="ps-2">
                                        ・　復旧費用の算定（入門）（財務診断シート）
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showRecoveryCostCalculation">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        ・　緊急時に使える資金
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showFund">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　財務診断とキャッシュフロー対策
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col">
                                    4. 財務診断と事前対策計画
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-8">
                                    <div class="ps-2">
                                        製造業・小売業・建設業
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-middle cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="showFinanceHome">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　損益計算書（製造原価報告書・完成工事原価計算書）
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資産復旧費用の算定
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　事業中断によるキャッシュフローの悪化額
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　緊急事態発生後のキャッシュフローの算定
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　復旧費用総額の算定・調達
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　キャッシュフロー対策の考え方・事前対策
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col">
                                    4. 財務診断と事前対策計画
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        損害保険の整理
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showGeneralInsurance">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        事前対策のための投資計画
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showInvestmentPlan">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        保険情報リスト
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-middle cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="showInsuranceInfo">ページ移動</span>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-2">
                                        事前対策のための投資計画
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light color-contents-gray">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        保険情報リスト
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        事前対策のための投資計画
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    4.BCP発動フロー
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showActivationFlow">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        初動対応
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        復旧に向けた対応
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    5. 緊急時におけるBCP発動
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showBcpInvocation">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　発動フロー
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        発動フローに沿った実施項目
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showImplementationItems">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　避難
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        避難計画シート｜屋外避難用
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showRefugePlan">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　情報連絡
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要組織の連絡先
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showMainOrganizationContact">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-4">
                                        従業員連絡リスト
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　基本情報整理用
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showBasicInfoOrganizing">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    5. 緊急時におけるBCP発動
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　発動フロー
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        災害ごとの発動フロー
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　避難
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        避難計画シート｜屋外避難用
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　情報連絡
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要組織の連絡先
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-4">
                                        従業員連絡リスト
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　基本情報整理用
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　従業員一覧
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-middle cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="showStaffList">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　従業員個別用
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-middle cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="showStaffIndividual">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    5. 緊急時におけるBCP発動
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　発動フロー
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        災害ごとの発動フロー
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　避難
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        避難計画シート｜屋外避難用
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　情報連絡
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要組織の連絡先
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-4">
                                        従業員連絡リスト
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　基本情報整理用
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　従業員一覧
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        ・　従業員個別用
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        情報通信手段
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-high cursor-pointer px-2" v-if="is_display_advanced && !is_fixed_latest_version" @click="showInformationCommunicatingMethod">ページ移動</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        電話／FAX番号シート【{{callReplaceWord('自社')}}用】
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showPhoneFax">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要{{callReplaceWord('顧客')}}情報
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showMainCustomerContact">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資源
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-4">
                                        中核事業に係るボトルネック資源
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        1 設備・機械・車両など
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showBottleneckResource">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light color-contents-gray">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        電話／FAX番号シート【{{callReplaceWord('自社')}}用】
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要{{callReplaceWord('顧客')}}情報
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資源
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-4">
                                        中核事業に係るボトルネック資源
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        1 設備・機械・車両など
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        電話／FAX番号シート【{{callReplaceWord('自社')}}用】
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要{{callReplaceWord('顧客')}}情報
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　資源
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col">
                                    <div class="ps-4">
                                        中核事業に係るボトルネック資源
                                    </div>
                                </div>
                            </div>
                            <div class="row color-contents-gray">
                                <div class="col-8">
                                    <div class="ps-4">
                                        1 設備・機械・車両など
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        2 コンピュータ機器とソフトウェア
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-high cursor-pointer px-2" v-if="is_display_advanced && !is_fixed_latest_version" @click="showComputerSoftware">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        3 その他の器具類
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-high cursor-pointer px-2" v-if="is_display_advanced && !is_fixed_latest_version" @click="showOtherEquipment">ページ移動</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        中核事業に必要な供給品目情報
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showNecessarySupplyItemInfo">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要供給者・業者情報【供給品目別】
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showMainSupplierVendorInfo">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        災害対応用具チェックリスト
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showToolCheckList">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light color-contents-gray">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        中核事業に必要な供給品目情報
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要供給者・業者情報【供給品目別】
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        災害対応用具チェックリスト
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light color-contents-gray">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        中核事業に必要な供給品目情報
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        主要供給者・業者情報【供給品目別】
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        災害対応用具チェックリスト
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        ・　地域貢献
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-4">
                                        地域貢献活動
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-high cursor-pointer px-2" v-if="is_display_advanced && !is_fixed_latest_version" @click="showRegionalContributionWork">ページ移動</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light"></td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        ※ 訓練計画登録
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-al-inquiry-button cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="showTrainingPlanRegistration">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="ps-2">
                                        ※ 教育計画登録
                                    </div>
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-al-inquiry-button cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="EducationPlanRegistration">ページ移動</span>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light"></td>
                    </tr>
                    <tr>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    5.BCPの運用
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-intro cursor-pointer px-2" v-if="is_display_intro && !is_fixed_latest_version" @click="showBcpOperation">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの定着
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        BCPの見直し
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    6. 自己診断結果
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-basic cursor-pointer px-2" v-if="is_display_basic && !is_fixed_latest_version" @click="showSelfDiagnosisBasic">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        自己診断チェックリスト（基本）
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light">
                            <div class="row">
                                <div class="col-8">
                                    6. 自己診断結果
                                </div>
                                <div class="col-4">
                                   <span class="badge btn-middle cursor-pointer px-2" v-if="is_display_middle && !is_fixed_latest_version" @click="showSelfDiagnosisMiddleAdvance">ページ移動</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        自己診断チェックリスト（中級・上級）
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td class="bg-light color-contents-gray">
                            <div class="row">
                                <div class="col-8">
                                    6. 自己診断結果
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="ps-2">
                                        自己診断チェックリスト（中級・上級）
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            // window.location.reload();
        }

        const setLevel = ref('111100');
        const isProcessing = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const nextVersionStart = async () => {
            const postUrl = '/api/bcp/registerNextVersion';
            isProcessing.value = true;

            const result = await axios.post(postUrl, {
                'corp_id': props.corp_info.id,
            }).then(res => {
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });

            if (result == '200') {
                // 自ページ再描画
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        const showVersionFix = async () => {
            // 確定ページへ遷移
            const nextPageUrl = '/bcp/bcp_introduction/version_fix';
            location.href = nextPageUrl;
        }

        const showBack = () => {
            // 前のページへ遷移
            sessionStorage.setItem('refresh', 'true');
            history.back();
        }

        const showTitle = () => {
            const url = '/bcp/bcp_introduction/1';
            location.href = url;
        }

        const showBasicPolicyOfIntro = () => {
            const url = '/bcp/bcp_introduction/2';
            location.href = url;
        }

        const showOperationStructureOfIntro = () => {
            const url = '/bcp/bcp_introduction/3';
            location.href = url;
        }

        const showAssumptionAndCounterplan = () => {
            const url = '/bcp/bcp_introduction/4';
            location.href = url;
        }

        const showImpactByBigEarthquake = () => {
            const url = '/bcp/bcp_introduction/5';
            location.href = url;
        }

        const showImpactByCorona = () => {
            const url = '/bcp/bcp_introduction/6';
            location.href = url;
        }

        const showActivationFlow = () => {
            const url = '/bcp/bcp_introduction/7';
            location.href = url;
        }

        const showBcpOperation = () => {
            const url = '/bcp/bcp_introduction/8';
            location.href = url;
        }

        const showBasicPolicyOfBasic = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/1';
            location.href = url;
        }

        const showOperationStructureOfBasic = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/2';
            location.href = url;
        }

        const showCoreBusinesses = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/3';
            location.href = url;
        }

        const showSubstituteInformation = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/4';
            location.href = url;
        }

        const showRecoveryCostCalculation = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/5';
            location.href = url;
        }

        const showFund = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/6';
            location.href = url;
        }

        const showGeneralInsurance = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/7';
            location.href = url;
        }

        const showInvestmentPlan = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/8';
            location.href = url;
        }

        const showBcpInvocation = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/9';
            location.href = url;
        }

        const showImplementationItems = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/10';
            location.href = url;
        }

        const showCoreBusinessImpactEvaluationForm = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_first/form';
            location.href = url;
        }

        const showRefugePlan = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/1';
            location.href = url;
        }

        const showMainOrganizationContact = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/2';
            location.href = url;
        }

        const showBasicInfoOrganizing = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/3';
            location.href = url;
        }

        const showStaffList = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/4';
            location.href = url;
        }

        const showStaffIndividual = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/4#list-000001';
            location.href = url;
        }

        const showInformationCommunicatingMethod = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/5';
            location.href = url;
        }

        const showPhoneFax = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/6';
            location.href = url;
        }

        const showMainCustomerContact = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/7';
            location.href = url;
        }

        const showBottleneckResource = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/8';
            location.href = url;
        }

        const showComputerSoftware = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/9';
            location.href = url;
        }

        const showOtherEquipment = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/10';
            location.href = url;
        }

        const showNecessarySupplyItemInfo = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/11';
            location.href = url;
        }

        const showMainSupplierVendorInfo = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/11#detail';
            location.href = url;
        }

        const showToolCheckList = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/12';
            location.href = url;
        }

        const showRegionalContributionWork = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_second/13';
            location.href = url;
        }

        const showSelfDiagnosisBasic = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/pdca/j1a';
            location.href = url;
        }

        const showSelfDiagnosisMiddleAdvance = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/pdca/j2a';
            location.href = url;
        }

        const showMultipleCompanyLink = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_multiple/1';
            location.href = url;
        }

        const showTrainingPlanRegistration = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/fixation/b1';
            location.href = url;
        }

        const EducationPlanRegistration = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/fixation/k1';
            location.href = url;
        }

        const showInsuranceInfo = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_financial/18';
            location.href = url;
        }

        const showFinanceHome = () => {
            if(judgeFreeMember()){
                modalVisible.value = true;
                return false;
            }
            const url = '/bcp/bcp_financial/home';
            location.href = url;
        }

        const modalVisible = ref(false);

        const closeModal = () => {
            modalVisible.value = false;
        }

        const changePaid = () => {
            const url = '/al/administrator/switchPaidMember';

            location.href = url;
        }

        const judgeFreeMember = () => {
            if(props.corp_info.会員種別.indexOf('無償') != -1){
                return true;
            }
            return false;
        }

        return {
            setLevel,
            callReplaceWord,
            nextVersionStart,
            showVersionFix,
            showBack,
            showTitle,
            showBasicPolicyOfIntro,
            showOperationStructureOfIntro,
            showAssumptionAndCounterplan,
            showImpactByBigEarthquake,
            showImpactByCorona,
            showActivationFlow,
            showBcpOperation,
            showBasicPolicyOfBasic,
            showOperationStructureOfBasic,
            showCoreBusinesses,
            showSubstituteInformation,
            showRecoveryCostCalculation,
            showFund,
            showGeneralInsurance,
            showInvestmentPlan,
            showBcpInvocation,
            showImplementationItems,
            showCoreBusinessImpactEvaluationForm,
            showRefugePlan,
            showMainOrganizationContact,
            showBasicInfoOrganizing,
            showStaffList,
            showStaffIndividual,
            showInformationCommunicatingMethod,
            showPhoneFax,
            showMainCustomerContact,
            showBottleneckResource,
            showComputerSoftware,
            showOtherEquipment,
            showNecessarySupplyItemInfo,
            showMainSupplierVendorInfo,
            showToolCheckList,
            showRegionalContributionWork,
            showSelfDiagnosisBasic,
            showSelfDiagnosisMiddleAdvance,
            showMultipleCompanyLink,
            showTrainingPlanRegistration,
            EducationPlanRegistration,
            showInsuranceInfo,
            showFinanceHome,

            judgeFreeMember,
            modalVisible,
            closeModal,
            changePaid,

            isProcessing,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_fixed_first_version: {
            type: Boolean,
        },
        is_fixed_latest_version: {
            type: Boolean,
        },
        is_display_intro: {
            type: Boolean,
        },
        is_display_basic: {
            type: Boolean,
        },
        is_display_middle: {
            type: Boolean,
        },
        is_display_advanced: {
            type: Boolean,
        },
    },
}
</script>

<style lang="scss" scoped>
    .bg-color-intro {
        background-color: #8ea9db;
    }

    .btn-intro {
        border: 1px solid;
        color: white;
        border-color: #8ea9db;
        background-color: #8ea9db;
    }

    .btn-intro:hover {
        border: 1px solid;
        color: #8ea9db;
        border-color: #8ea9db;
        background-color: white;
    }

    .bg-color-basic {
        background-color: #4472c4;
    }

    .btn-basic {
        border: 1px solid;
        color: white;
        border-color: #4472c4;
        background-color: #4472c4;
    }

    .btn-basic:hover {
        border: 1px solid;
        color: #4472c4;
        border-color: #4472c4;
        background-color: white;
    }

    .bg-color-middle {
        background-color: #2353a4;
    }

    .btn-middle {
        border: 1px solid;
        color: white;
        border-color: #2353a4;
        background-color: #2353a4;
    }

    .btn-middle:hover {
        border: 1px solid;
        color: #2353a4;
        border-color: #2353a4;
        background-color: white;
    }

    .bg-color-advanced {
        background-color: #002060;
    }

    .btn-high {
        border: 1px solid;
        color: white;
        border-color: #002060;
        background-color: #002060;
    }

    .btn-high:hover {
        border: 1px solid;
        color: #002060;
        border-color: #002060;
        background-color: white;
    }

    .color-contents-gray {
        color: #a6a6a6
    }

    #contents_table thead tr th:nth-child(1),
    #contents_table thead tr th:nth-child(3),
    #contents_table thead tr th:nth-child(5),
    #contents_table thead tr th:nth-child(7) {
        width: 24%;
    }

    #change-paid-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #content {
        z-index: 2;
        width: 30%;
        padding: 5em;
        background: #fff;
    }

    .link-orange,
    .link-orange:link,
    .link-orange:visited,
    .link-orange:hover,
    .link-orange:active{
    color: #Ff6600;
    }

</style>
