<template>
    <div class="row">
        <div class="col-md-8">
            <div class="h5 heading-orange mt-3" style="font-size:14pt">
                安否メッセージ
            </div>
            <levelIcon
                :set_level="setLevel"
            />
        </div>
        <div class="p2 mt-3 ms-1 col-md d-flex align-items-start justify-content-end me-md-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="backHome" :disabled="isProcessing">
        </div>
    </div>

    <div class="pb-5 border-bottom" v-for="(safety_confirm, index) in safety_list" :key="safety_confirm.id">
        <div class="row mt-5 mx-1">
            <div class="p2 col-md-1 border border-end-0 bg-header d-flex align-items-center justify-content-center">
                <label class="col-form-label text-center">配信日時</label>
            </div>
            <div class="p2 col-md-8 border d-flex align-items-center justify-content-start">
                <label class="col-form-label">{{ this.$root.dayjs(safety_confirm.配信日時).format('YYYY/MM/DD HH:mm') }}</label>
            </div>
            <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                <label class="col-form-label">&emsp;</label>
            </div>
            <div class="p2 col-md-1 label-answered d-flex align-items-center justify-content-center" v-if="safety_confirm.回答ステータス === 30100003">
                <label class="col-form-label text-white">回答ずみ</label>
            </div>
            <div class="p2 col-md-1 label-not-answered d-flex align-items-center justify-content-center" v-else>
                <label class="col-form-label text-white">未回答</label>
            </div>
            <div class="col-md-1 d-flex align-items-center justify-content-center d-none d-md-block d-print-block">
                <input type="button" class="btn btn-red" value="削除" @click="deleteConfirm(index)" :disabled="isProcessing">
            </div>
        </div>
        <div class="row mt-3 mx-1">
            <div class="p2 col-md-1 border border-end-0 bg-header d-flex align-items-center justify-content-center">
                <label class="col-form-label text-center">件名</label>
            </div>
            <div class="p2 col-md-11 border d-flex align-items-center justify-content-start">
                <label class="col-form-label">{{ safety_confirm.メール件名 }}</label>
            </div>
        </div>
        <div class="row mt-3 mx-1">
            <div class="p2 col-md-1 border border-end-0 bg-header d-flex align-items-center justify-content-center pb-md-5">
                <label class="col-form-label text-center">本文</label>
            </div>
            <div class="p2 col-md-11 border d-flex flex-column">
                <textarea class="form-control pb-5" disabled readonly>{{ safety_confirm.本文 }} </textarea>
                <button class="btn btn-primary mt-5 p-2" @click="showAnswer(safety_confirm.corp_id, safety_confirm.branch_id, safety_confirm.token, safety_confirm.id, safety_confirm.user_id)" :disabled="isProcessing">回答する</button>
                <input type="button" class="btn btn-red d-md-none d-print-none mt-3" value="削除" @click="deleteConfirm(index)" :disabled="isProcessing">
            </div>
        </div>
    </div>

    <div class="row mt-3 pb-5 d-flex flex-row">
        <div class="col-md-3">
            <div class="d-flex align-items-center justify-content-start flex-row">
                <input type="button" class="text-center btn btn-gray" value="安否確認HOMEへ戻る" @click="backHome" :disabled="isProcessing">
            </div>
        </div>
    </div>

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('111111');

        onMounted(() => {
        })

        const showAnswer = (corp_id, branch_id, token, safety_confirm_id, user_id ) => {
            const location = '/replySafetyConfirmation?corp_id=' + corp_id 
                    + '&branch_id=' + branch_id + '&token=' + token + '&safety_confirm_id=' + safety_confirm_id + '&user_id=' + user_id;

            window.open(location, '_blank');
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const backHome = () => {
            const url = '/safety/home';

            location.href = url;
        }

        const isProcessing = ref(false);

        const deleteConfirm = (index) => {
            if(props.safety_list[index].回答ステータス !== 30100003){
                alert("安否メッセージが未回答です。");

                return false;
            }

            isProcessing.value = true;

            const url = "/api/safety/updateShowStatus";
            axios.post(url, {
                id: props.safety_list[index].detail_id,
            }).then(res=>{
                location.reload();
            }).finally(fin=>{
                isProcessing.value = false;
            })
        }

        return {
            showAnswer,
            setLevel,
            callReplaceWord,
            backHome,
            isProcessing,
            deleteConfirm,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        safety_list: {
            type: Array,
        }
    }

}
</script>

<style lang="scss" scoped>
    .label-not-answered {
        background-color: #2f75b5;
    }

    .label-answered {
        background-color: #9bc2e6;
    }

    .bg-header {
        background-color: #ddebf7;
    }

    #training-modal{
        /*　要素を重ねた時の順番　*/
        z-index: 1;

        /*　画面全体を覆う設定　*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        /*　画面の中央に要素を表示させる設定　*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #training-content {
        z-index: 2;
        width: 80%;
        padding: 5em;
        background: #f2f2f2;
    }

</style>