<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3" style="font-size:14pt">
            都道府県ハザードマップ
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-introduction px-3" value="被害想定へ戻る" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-auto mt-5">
        <input type="button" class="p2 col-md-2 border py-2 text-white text-center"
            value="北海道・東北地方&#13;&#10;7道県" @click="showHCommon('北海道・東北')" :class="{'bg-resilience-black': selectRegion == '北海道・東北', 'bg-non-select': selectRegion != '北海道・東北'}" style="font-size:12pt" >

        <input type="button" class="ms-3 p2 col-md border py-2 text-white text-center"
            value="関東地方&#13;&#10;7都県" @click="showHCommon('関東')" :class="{'bg-resilience-black': selectRegion == '関東', 'bg-non-select': selectRegion != '関東'}" style="font-size:12pt" >

        <input type="button" class="ms-3 p2 col-md border py-2 text-white text-center"
            value="中部地方&#13;&#10;9県" @click="showHCommon('中部')" :class="{'bg-resilience-black': selectRegion == '中部', 'bg-non-select': selectRegion != '中部'}" style="font-size:12pt" >

        <input type="button" class="ms-3 p2 col-md border py-2 text-white text-center"
            value="近畿地方&#13;&#10;7府県" @click="showHCommon('近畿')" :class="{'bg-resilience-black': selectRegion == '近畿', 'bg-non-select': selectRegion != '近畿'}" style="font-size:12pt" >

        <input type="button" class="ms-3 p2 col-md border py-2 text-white text-center"
            value="中国・四国地方&#13;&#10;9県" @click="showHCommon('中国・四国')" :class="{'bg-resilience-black': selectRegion == '中国・四国', 'bg-non-select': selectRegion != '中国・四国'}" style="font-size:12pt" >

        <input type="button" class="ms-3 p2 col-md border py-2 text-white text-center"
            value="九州・沖縄地方&#13;&#10;8県" @click="showHCommon('九州・沖縄')" :class="{'bg-resilience-black': selectRegion == '九州・沖縄', 'bg-non-select': selectRegion != '九州・沖縄'}" style="font-size:12pt" >
    </div>

    <hr>
    <HCommonComponent
        :region = selectRegion
    />

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import HCommonComponent from './hCommonComponent.vue';
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon,
        HCommonComponent,
    },
    setup(props) {
        const setLevel = ref('011100');

        const selectRegion = ref("");

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = () => {
            const url = '/bcp/bcp_introduction/h';
            location.href = url;
        }

        const showHCommon = (region) => {
            selectRegion.value = region;
        }

        return {
            setLevel,
            callReplaceWord,
            showBack,

            selectRegion,
            showHCommon,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-non-select {
    background-color: #a6a6a6;
}
</style>
