<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3">
            1.　基本方針｜BCPの基本方針
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div v-for="(form, formIndex) in textForms" :key="formIndex">
        <div class="row ms-3 mt-5" v-if="formIndex === 0">
            <div class="p2 col-md-6 border bg-secondary text-white text-center d-flex align-items-center justify-content-start py-2">
                ◯　BCP策定･運用の目的
            </div>
        </div>
        <div class="row ms-3 mt-5" v-if="formIndex === 4">
            <div class="p2 col-md-6 border bg-secondary text-white text-center d-flex align-items-center justify-content-start py-2">
                ◯　緊急時に事業継続を図る上での要点
            </div>
        </div>

        <div class="row ms-3 mt-3" >
            <div class="p2 mt-3 col-md-6 text-dark d-flex align-items-end justify-content-start">
                <span v-if="formIndex === 0">{{ callReplaceWord('顧客') }}にとって</span>
                <span v-else-if="formIndex === 1">{{ callReplaceWord('従業員') }}にとって</span>
                <span v-else-if="formIndex === 2">地域にとって</span>
                <span v-else-if="formIndex === 3">その他</span>
                <span v-else-if="formIndex === 4">{{ callReplaceWord('組織') }}同士の助け合い</span>
                <span v-else-if="formIndex === 5">商取引上のモラル</span>
                <span v-else-if="formIndex === 6">地域への貢献</span>
                <span v-else-if="formIndex === 7">公的支援制度の活用</span>
                <span v-else>その他</span>
            </div>
        </div>
        <div class="row ms-3">
            <div class="row col-md-10">
                <textarea type="text" rows="3" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="form.value"></textarea>
            </div>
            <div class="p2 col-md-1 d-flex align-items-start justify-content-start" v-if="formIndex !== 1 && formIndex !== 2 && formIndex !== 3 && formIndex !== 8">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                    <template #popper>
                        <div class="row">
                            <label v-if="formIndex === 0">BCPを策定し運用する目的は、緊急時においても事業を継続できるように準備しておくことで、<br />顧客からの信用、従業員の雇用、地域経済の活力の3つを守ろうとするものです。</label>
                            <label v-if="formIndex === 4">中小企業では、日常的に業務を分担したり、情報交換したりと助け合いの中で事業を行っています。<br />緊急時において同業者組合や取引企業同士、被害の少ない企業が困っている企業を助ける、そのことが結局は自社の事業継続にもつながります。</label>
                            <label v-if="formIndex === 5">協力会社への発注を維持する、取引業者へきちんと支払いをする、便乗値上げはしない、こうしたモラルが守れないと、企業の信用が失墜し、工場や店舗が直っても事業の復旧は望めません。</label>
                            <label v-if="formIndex === 6">中小企業では、顧客が地域住民であったり、経営者や従業員も地域住民の一人であったりします。<br />企業の事業継続とともに、企業の能力を活かして、被災者の救出や商品の提供等の地域貢献活動が望まれます。</label>
                            <label v-if="formIndex === 7">わが国では中小企業向けに、公的金融機関による緊急時融資制度や特別相談窓口の開設などの各種支援制度が充実しています。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div id="a" class="p2 col-md-6 border bg-secondary text-white text-center d-flex align-items-center justify-content-start py-2">
            ◯　BCPの更新時期
        </div>
    </div>

    <div class="row ms-3 mt-3">
        <div class="p2 mt-3 col-md-2 text-dark d-flex align-items-center justify-content-start">
            毎年
        </div>
        <div class="p2 mt-3 col-md-1 text-dark d-flex align-items-center justify-content-start">
        </div>
        <div class="p2 mt-3 col-md-2 text-dark d-flex align-items-center justify-content-start">
            更新回数（年）
        </div>
    </div>
    <div class="row ms-3">
        <div class="p2 col-md-2 text-dark d-flex align-items-center justify-content-start">
            <input type="number" class="form-control d-flex align-items-center justify-content-center py-2" min="1" max="12" v-model="valueForms[0].value">
        </div>
        <div class="p2 col-md-1 text-dark d-flex align-items-end justify-content-start">
            月
        </div>
        <div class="p2 col-md-2 text-dark d-flex align-items-center justify-content-start">
            <input type="number" class="form-control d-flex align-items-center justify-content-center py-2" min="1" v-model="valueForms[1].value">
        </div>
        <div class="p2 col-md-1 text-dark d-flex align-items-end justify-content-start">
            回
        </div>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 mx-auto col-md-4 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="基本｜2.　BCPの運用体制｜BCPの策定・運用体制 へ進む" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mx-auto col-md-2 d-flex align-items-start justify-content-start">
            <input type="button" class="btn btn-bcp-blue" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
        </div>
    </div>

</template>

<script>
import { ref, reactive, onBeforeMount, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(props.bcp_texts_info.length > 0){
                props.bcp_texts_info.forEach(fetchData =>{
                    textForms.forEach(initData => {
                        if(initData.columnName == fetchData.column_name){
                            initData.value = fetchData.text_value;
                        }
                    });
                });
            }

            if(props.bcp_values_info.length > 0){
                props.bcp_values_info.forEach(fetchData =>{
                    valueForms.forEach(initData => {
                        if(initData.columnName == fetchData.column_name){
                            initData.value = fetchData.value;
                        }
                    });
                });
            }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 1000);

        const setLevel = ref('011100');
        const isProcessing = ref(false);
        const pageName = 'F1';
        const setDefaultFieldNumber = 1;
        const setDefaultSeq = 1;

        const textForms = reactive([]);
        for (let i = 1; i <= 9; i++) {
            textForms.push({
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K' + ('00000' + i).slice(-5),
                value: '',
            });
        }

        const valueForms = reactive([
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00010',
                seq: setDefaultSeq,
                value: '',
            },
            {
                fieldNumber: setDefaultFieldNumber,
                columnName: 'K00011',
                seq: setDefaultSeq,
                value: '',
            },
        ]);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const showBack = async () => {
            // 基本方針を登録
            const result = await registerFirst1();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 基本方針を登録
            const result = await registerFirst1();

            if (result == '200') {
                const url = '/bcp/bcp_first/2';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 基本方針を登録
            const result = await registerFirst1();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 基本方針登録
        const registerFirst1 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerFirst1';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'text_form_list': textForms.filter(data => data.value != ''),
                'value_form_list': valueForms.filter(data => data.value != ''),
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            isProcessing,
            textForms,
            valueForms,
            callReplaceWord,
            showBack,
            showNext,
            showContents,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_texts_info: {
            type: Object,
        },
        bcp_values_info: {
            type: Object,
        },
    }

}
</script>

<style lang="scss" scoped>

</style>
