<template>
    <div class="row" >
        <div class="h5 col-md-6 heading-orange mt-3">
            5.　緊急時におけるBCP発動｜情報連絡（つづき）
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-bcp-advanced btn-sm" value="上級｜5.　緊急時におけるBCP発動｜情報連絡（つづき）へ進む" v-show="is_completed_middle" :disabled="isProcessing" @click="showNext">
        </div>
        <div class="p2 mt-3 ms-auto col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />
    <div class="row ms-3 mt-5">
        <div class="p2 col-md-4 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　従業員連絡リスト［従業員一覧］
        </div>
    </div>

    <div class="width">
        <div class="row ms-3 mt-3">
            <div class="p2 col-md-12 border border-dark border-bottom-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
        </div>
        <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
            <div class="p2 ms-4 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                個別リストNo.
            </div>
            <div class="p2 col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                {{ callReplaceWord('従業員') }}氏名
            </div>
            <div class="p2 ms-2 col-md-1 d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
            <div class="p2 ms-4 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                個別リストNo.
            </div>
            <div class="p2 col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                {{ callReplaceWord('従業員') }}氏名
            </div>
        </div>
        <div class="list">
        <div v-for="num in list_frame_number/2" :key="num">
            <div class="row ms-3 border border-dark border-top-0 border-bottom-0 bg-light-gray">
                <div class="user p2 ms-4 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                    {{ String(num).padStart(6, 0) }}
                </div>
                <div class="user p2 col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                    {{ userList[num - 1] != null ? userList[num - 1]['氏名'] : '' }}
                </div>
                <div class="p2 ms-2 col-md-1 d-flex align-items-center justify-content-center">
                    <input type="button" class="btn btn-al-inquiry-button btn-sm" value="個別情報"
                        v-if="userList[num - 1] != null && userList[num - 1].利用者ID != null"
                        @click="showDetail(`${String(num).padStart(6, 0)}`, num)">
                </div>
                <div class="user p2 ms-4 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white text-dark d-flex align-items-center justify-content-center py-2" style="font-size:12pt">
                    {{ String(num + (list_frame_number/2)).padStart(6, 0) }}
                </div>
                <div class="user p2 col-md-2 border border-dark border-bottom-0 text-dark bg-white d-flex align-items-center justify-content-start py-2">
                    {{ userList[num+(list_frame_number/2)-1] != null ? userList[num+(list_frame_number/2)-1]['氏名'] : '' }}
                </div>
                <div class="p2 ms-2 col-md-1 d-flex align-items-center justify-content-center">
                    <input type="button" class="btn btn-al-inquiry-button btn-sm" value="個別情報"
                        v-if="userList[num+(list_frame_number/2)-1] != null && userList[num+(list_frame_number/2)-1].利用者ID != null"
                        @click="showDetail(`${String(num+(list_frame_number/2)).padStart(6, 0)}`, num+(list_frame_number/2))">
                </div>
            </div>
        </div>
        </div>
        <div class="row ms-3">
            <div class="p2 col-md-12 border border-dark border-top-0 bg-light-gray text-white d-flex align-items-center justify-content-start py-2">
                &emsp;
            </div>
        </div>
    </div>

    <div class="row ms-3 mt-5">
        <div class="p2 col-md-4 border bg-light-gray text-dark text-center d-flex align-items-center justify-content-start py-2">
            ◯　従業員連絡リスト［従業員個別］
        </div>
    </div>
    <div v-for="(user, index) in userList" :key="index" v-show="isVisible[index]">
        <detail
            :user="user"
            :number="String(index + 1).padStart(6, 0)"
            :replacement_word_list="replacement_word_list"
            :value="emergencyContactViewLimit"
            @viewLimitChange="viewLimitChange"/>
    </div>

    <div class="row mt-5">
        <div class="p2 ms-3 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-middle" value="中級｜5. 緊急時におけるBCP発動 終了ページへ" style="font-size:10.5pt" :disabled="isProcessing" @click="showMiddleComplete">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="基本｜5.　緊急時におけるBCP発動｜災害ごとの発動フロー へ" style="font-size:10.5pt" :disabled="isProcessing" @click="showPrevious">
        </div>
        <div class="p2 col-md d-flex align-items-start justify-content-around">
            <input type="button" class="btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt" :disabled="isProcessing" @click="showContents">
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import detail from "./4DetailComponent.vue";
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
        detail
    },
    setup(props) {
        onMounted(() => {
            userList.value = props.user_list;
            userList.value.forEach(user => {
                user.主な責務 = user.主な責務.replace(/(@@顧客@@)/g, callReplaceWord('顧客'));
            });
        });
        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        // ターゲット指定制御
        setTimeout(() => {
            const urlHash = location.hash;
            if (urlHash) {
                const url = urlHash.split('#');
                location.href = '#' + url[1];
            }
        }, 500);

        const setLevel = ref('001100');
        const isProcessing = ref(false);
        const userList = ref([]);
        let emergencyContactViewLimit = ref(0);
        emergencyContactViewLimit.value = props.is_view_limit;
        const isVisible = ref(Array(props.user_list.length).fill(false));
        isVisible.value[0] = true;
        let currentVisibleIndex = 0;

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const viewLimitChange = async (param) => {
            // 閲覧制限設定値を書き換え
            emergencyContactViewLimit.value = param;
        };

        // 従業員連絡リスト［従業員個別］を表示
        const showDetail = async (targetTag, index) => {
            // 現在表示しているカードを非表示に変更
            isVisible.value[currentVisibleIndex] = false;

            // 選択されたカードを表示
            isVisible.value[index - 1] = true;
            currentVisibleIndex = index - 1;

            // 選択されたカードにスクロール
            location.href = `#list-${targetTag}`;
        }

        const showMiddleComplete = async () => {
            // 閲覧制限設定を登録
            const result = await registerSecond4();
            if (result == '200') {
                const url = '/bcp/bcp_second/middle_complete';
                location.href = url;
            }
        }

        const showPrevious = async () => {
            // 閲覧制限設定を登録
            const result = await registerSecond4();
            if (result == '200') {
                const url = '/bcp/bcp_first/10';
                location.href = url;
            }
        }

        const showNext = async () => {
            // 閲覧制限設定を登録
            const result = await registerSecond4();
            if (result == '200') {
                const url = '/bcp/bcp_second/5';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 閲覧制限設定を登録
            const result = await registerSecond4();
            if (result == '200') {
                // 前ページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showContents = async () => {
            // 閲覧制限設定を登録
            const result = await registerSecond4();
            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 閲覧制限の登録
        const registerSecond4 = async () => {
            const setDefaultSeq = 1;
            const setDefaultFieldNumber = 1;
            const pageName = 'S4';
            const reqValueForms = [
                {
                    fieldNumber: setDefaultFieldNumber,
                    columnName: 'view_limit',
                    seq: setDefaultSeq,
                    value: emergencyContactViewLimit.value,
                }
            ];

            // リクエスト送信
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerSecond4';
            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'page_name': pageName,
                'value_form_list': reqValueForms,
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(() => {
                isProcessing.value = false;
            });
        }

        return {
            setLevel,
            userList,
            emergencyContactViewLimit,
            isVisible,
            isProcessing,
            callReplaceWord,
            viewLimitChange,
            showDetail,
            showMiddleComplete,
            showPrevious,
            showContents,
            showBack,
            showNext,
            registerSecond4,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        is_view_limit: {
            type: Boolean,
        },
        user_list: {
            type: Array,
        },
        list_frame_number: {
            type: Number,
        },
        is_completed_middle: {
            type: Boolean,
        },
    }
}
</script>

<style lang="scss" scoped>
    .width {
        width: 80%
    }
    .list div:last-of-type div .user {
        border-bottom: 1px solid #000 !important;
    }
</style>
