<template>
    <div>
        <div class="row">
            <div class="h5 col-md-8 heading-orange mt-3">
                請求書・領収書
            </div>
            <div class="col-md-4 mt-3 ">
                <button class="btn btn-bcp-restrictions py-2 me-3" @click="backMenu"><i class="bi bi-gear"></i><span class="ms-3">マイページ</span></button>
                <input type="button" class="btn btn-black" value="前ページへ戻る" @click="backHome">
            </div>
        </div>
        <levelIcon
            :set_level="setLevel"
        />
        <div class="row mt-3">
            <div class="col-md-10">
                <table class="table table-bordered table-light table-striped table-hover table-sm" id="bill_table">
                    <thead>
                        <tr>
                            <th class="text-center">No.</th>
                            <th class="text-center">請求日</th>
                            <th class="text-center">支払方法</th>
                            <th class="text-center">請求額</th>
                            <th class="text-center">請求書・領収書PDFをダウンロード</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="(bill, index) in bill_info" :key="bill.id">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ this.$root.dayjs(bill.請求日).format('YYYY-MM-DD') }}</td>
                            <td class="text-center">{{ bill.支払区分名 }}</td>
                            <td class="text-center">{{ formatNumber(bill.請求額) }}</td>
                            <td><input type="button" class="btn btn-orange-80 btn-sm ms-2 me-5" value="請求書発行" @click="makeInvoice(bill.id)" :disabled="isProcessing"><input type="button" class="btn btn-orange-80 btn-sm mx-2" value="領収書発行" @click="makeReceipt(bill.id)" v-show="bill.未収flg == 0" :disabled="isProcessing"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <span>※支払方法が口座振替の場合、年会費振替後に領収書をダウンロードすることができます。初回は口座振替手続きにお時間を要しますので、ご了承ください。</span>
        </div>

    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import { CommonNumberFormat } from '../../../function/CommonNumberFormat.js';
import axios from 'axios';

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const isProcessing = ref(false);
        const setLevel = ref('111111');

        const backHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }

        const formatNumber = (num) => {
            return CommonNumberFormat(num);
        }

        const makeInvoice = (id) => {
            const url="/api/makeReports"

            isProcessing.value = true;

            axios.post(url, {
                    'ReportName': 'invoice_from_payment_status',
                    'bill_id': id,
                    'corp_id': props.corp_info.id,
                },{
                    responseType: 'arraybuffer',
                }).then(res => {
                    const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                    let link = document.createElement('a');
                    link.href = data;
                    link.download = '請求書.pdf';
                    link.click();

                    setTimeout(function(){
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }).finally(fin => {
                    isProcessing.value = false;

                });
        }

        const makeReceipt = (id) => {
            const url = '/api/makeReports';

            isProcessing.value = true;

            axios.post(url, {
                'ReportName': 'receipt',
                'bill_id': id,
                'corp_id': props.corp_info.id,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '領収書.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const backMenu = () => {
            const url = '/al/myPage/home';

            location.href = url;
        }

        return {
            isProcessing,
            setLevel,

            backHome,
            formatNumber,
            makeInvoice,
            makeReceipt,
            backMenu,
        }
        
    },
    props: {
        corp_info: {
            type: Object,
        },
        bill_info: {
            type: Array,
        }

    }
}
</script>

<style lang="scss" scoped>
#bill_table thead tr th:nth-child(1),
#bill_table thead tr th:nth-child(2),
#bill_table thead tr th:nth-child(3),
#bill_table thead tr th:nth-child(4)
{
    width: 17%;
}
</style>