<template>
    <div class="ms-lg-5 d-flex justify-content-between">
        <div class="h5 heading-green mt-lg-3 print-font-sm">
            中級コース
        </div>
        <div class="no-print mt-1 me-5">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" @click="showBack" />
        </div>
    </div>
    <div class="ms-md-5">
        <levelIcon class="print-font-sm"
            :set_level="setLevel"
            :margin_1="isMargin1"
        />
    </div>
    <div class="ms-md-5 no-print">
        <label class="ms-4 form-label color-resilience-green border-resilience-green bg-resilience-light-green no-print px-2">閲覧制限｜あり</label>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="row mt-3">
                <div class="p2 col-md">
                    <label class="form-label" style="font-size:12pt">4. 財務診断と事前対策計画</label>
                    <span class="ms-3 bg-bcp-middle text-light px-4 py-1 col-form-label">財務診断</span>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 復旧費用総額（Ｃ）</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">これまでの結果から復旧費用総額は次の通りとなります。</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-end">
                    <label class="form-label">単位：千円</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">項目</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">資産の復旧費用金額</label>
                </div>
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">事業中断によるキャッシュフローの悪化額</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">復旧費用総額（C）</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">金額</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-4 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-2 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">備考</label>
                </div>
                <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">（A）</label>
                </div>
                <div class="p2 col-md-4 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">（B）</label>
                </div>
                <div class="p2 col-md-3 border bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">（A）＋（B）</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 復旧費用の調達</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">① 自力で賄えるケース</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border bg-danger d-flex align-items-center justify-content-center">
                    <label class="form-label text-white">復旧費用総額（C）</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label" style="font-size:16pt">＜</label>
                </div>
                <div class="p2 col-md-3 border bg-success d-flex align-items-center justify-content-center">
                    <label class="form-label text-white">手許資金（D1）</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label" style="font-size:16pt">＝</label>
                </div>
                <div class="p2 col-md-3 border bg-secondary d-flex align-items-center justify-content-start">
                    <label class="form-label text-white">自力で賄える</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <button type="button" class="btn btn-info btn-sm text-white" id="ﾋﾝﾄ">ﾋﾝﾄ</button>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-end">
                    <label class="form-label">単位：千円</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">復旧費用総額</label>
                </div>
                <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">調達可能金額</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">過不足金額</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">（復旧費用）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">現金・預金</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">資産の復旧費用（A）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">損害保険金</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">会社資産売却</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">事業中断によるキャッシュフローの悪化額（B）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">会社調達分　計</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">経営者から支援</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">計（A＋B＝C）</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">手元資金　計（D1）</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label">② 自力で賄えないケース</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border bg-danger d-flex align-items-center justify-content-center">
                    <label class="form-label text-white">復旧費用総額（C）</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label" style="font-size:16pt">＞</label>
                </div>
                <div class="p2 col-md-3 border bg-success d-flex align-items-center justify-content-center">
                    <label class="form-label text-white">手許資金（D1）</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-center justify-content-center">
                    <label class="form-label" style="font-size:16pt">＝</label>
                </div>
                <div class="p2 col-md-3 border bg-secondary d-flex align-items-center justify-content-start">
                    <label class="form-label text-white">新規借入必要金額（E）</label>
                </div>
                <div class="p2 col-md-1 d-flex align-items-start justify-content-start">
                    <button type="button" class="btn btn-info btn-sm text-white" id="ﾋﾝﾄ">ﾋﾝﾄ</button>
                </div>
            </div>

            <div class="row mt-3 ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-start">
                    <label class="form-label" style="font-size:12pt">◯ 借入金額の算定</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md d-flex align-items-center justify-content-end">
                    <label class="form-label">単位：千円</label>
                </div>
            </div>

            <div class="row ms-1">
                <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">復旧費用総額</label>
                </div>
                <div class="p2 col-md-5 border border-bottom-0 border-end-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">調達可能金額</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 bg-gray-2 d-flex align-items-center justify-content-start">
                    <label class="form-label">過不足金額</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">（復旧費用）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">現金・預金</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">資産の復旧費用（A）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">損害保険金</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">会社資産売却</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">事業中断によるキャッシュフローの悪化額（B）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">会社調達分　計</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">経営者から支援</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-bottom-0 border-end-0 bg-gray-1 d-flex align-items-center justify-content-start">
                    <label class="form-label">新規借入必要金額（C-D1＝E）</label>
                </div>
                <div class="p2 col-md-2 border border-bottom-0 border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border border-top-0 border-bottom-0 bg-gray-3 d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>
            <div class="row ms-1">
                <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">計（A＋B＝C）</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-info d-flex align-items-center justify-content-start">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-3 border border-end-0 bg-gray-3 d-flex align-items-center justify-content-start">
                    <label class="form-label">手元資金　計（D1）</label>
                </div>
                <div class="p2 col-md-2 border border-end-0 bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
                <div class="p2 col-md-2 border bg-info d-flex align-items-center justify-content-center">
                    <label class="form-label">&emsp;</label>
                </div>
            </div>

            <br>

        </div>
    </div>
    <div class="mt-5 ms-md-5 no-print">
        <input v-if="is('administrator')" type="button" class="btn btn-resilience-green no-print px-4 py-2" value="次のページへ" @click="showNext" />
    </div>
</template>

<script>
import { ref } from 'vue'
import levelIcon from '../common/UserLevelIconsComponent.vue'

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        const setLevel = ref('001000');
        const isMargin1 = ref(true);

        const showBack = () => {
            const url = '/browse/C1b';
            location.href = url;
        }

        const showNext = () => {
            const url = '/browse/C1d';
            location.href = url;
        }

        return {
            setLevel,
            isMargin1,
            showBack,
            showNext,
        }
    },
    props:{},
}
</script>

<style lang="scss" scoped>
@use '../../../sass/common_colors.scss';

.bg-info {
    --bs-bg-opacity: 1;
    background-color: #C9FFF1 !important;
}

.bg-resilience-light-green {
    background-color: #E5FFFF;
}

.bg-gray-1 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #BFBFBF;
}

.bg-gray-2 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #D9D9D9;
}

.bg-gray-3 {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #F2F2F3;
}

.card {
    border-color: common_colors.colors(resilience-gray);
    color: common_colors.colors(resilience-black);
}

.border {
    border-color: common_colors.colors(resilience-gray) !important;
}

@media (max-width: 767px) {
    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .btn-info {
        border: none;
    }
}

@media print {
    .card {
        border-color: black;
        color: black;
    }

    .border {
        border-color: black !important;
    }
}
</style>
