<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-11">
                <div class="heading mt-3">会員企業CSVダウンロード</div>
            </div>
            <div class="col-md-1">
                <input type="button" class="btn btn-black" value="戻る" @click="back">
            </div>
        </div>
        <div class="row mt-5">
            <div><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">会員種別</label></div>
        </div>
        <div class="row mt-2 pb-3 border-separate">
            <div class="mx-3">
                <div class="form-check form-check-inline ms-2" v-for="kind in kind_list" :key="kind.key">
                    <input class="form-check-input" type="checkbox" v-model="selectKindList" :value="kind.val" :id="kind.val">
                    <label class="form-check-label" :for="kind.val">{{ kind.val }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">取得期間</label></div>
        </div>
        <div class="row mt-2 pb-3 border-separate">
            <div class="mx-3 row">
                <div class="col-auto">
                    <input type="date" class="form-control" v-model="date_from">
                </div>
                <label class="col-form-label mx-3 col-auto">～</label>
                <div class="col-auto">
                    <input type="date" class="form-control" v-model="date_to">
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">対象地域</label></div>
        </div>
        <div class="row mt-2 pb-3 border-separate">
            <div class="mx-3 row">
                <div class="col-auto">
                    <input class="form-check-input" type="radio" name="areaAll" id="areaAll" v-model="selectArea" value="all">
                    <label class="form-check-label ms-3" for="areaAll">
                        全国
                    </label>
                </div>
                <div class="col-auto">
                    <input class="form-check-input" type="radio" name="areaIndividual" id="areaIndividual" v-model="selectArea" value="individual">
                    <label class="form-check-label ms-3" for="areaIndividual">
                        都道府県
                    </label>
                </div>
                <div class="col-auto">
                    <select class="form-select"  v-model='selectPref' :disabled="selectArea==='all'">
                        <option v-for="pref in pref_list" :key="pref.code" :value="pref.name">{{ pref.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">業種別</label></div>
        </div>
        <div class="row mt-2 pb-3 border-separate">
            <div class="mx-3 row">
                <div class="col-auto">
                    <input class="form-check-input" type="radio" name="industriesAll" id="industriesAll" v-model="selectIndustry" value="all">
                    <label class="form-check-label ms-3" for="industriesAll">
                        全業種
                    </label>
                </div>
                <div class="col-auto">
                    <input class="form-check-input" type="radio" name="industriesIndividual" id="industriesIndividual" v-model="selectIndustry" value="individual">
                    <label class="form-check-label ms-3" for="industriesIndividual">
                        個別指定
                    </label>
                </div>
                <div class="col-md-6 ms-5">
                    <div class="row">
                        <label class="col-md-3 col-form-label">大分類</label>
                        <div class="col-md-9">
                            <select name="業種_大分類" class="form-select" v-model="業種_大分類" @change="changeLargeClass" :disabled="selectIndustry==='all'">
                                <option type="text" v-for="large in large_class_list" :key="large.id" :value="large.大分類">{{ large.大分類 + ':' + large.項目名 }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-md-3 col-form-label">中分類</label>
                        <div class="col-md-9">
                            <select name="業種_中分類" class="form-select" v-model="業種_中分類" @change="changeMiddleClass" :disabled="selectIndustry==='all'">
                                <option type="text" v-for="middle in middle_class_list" :key="middle.id" :value="middle.中分類">{{ middle.中分類 + ':' + middle.項目名 }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-md-3 col-form-label">小分類</label>
                        <div class="col-md-9">
                            <select name="業種_小分類" class="form-select" v-model="業種_小分類" @change="changeMinorClass" :disabled="selectIndustry==='all'">
                                <option type="text" v-for="minor in minor_class_list" :key="minor.id" :value="minor.小分類">{{ minor.小分類 + ':' + minor.項目名 }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-md-3 col-form-label">細分類</label>
                        <div class="col-md-9">
                            <select name="業種_細分類" class="form-select" v-model="業種_細分類" @change="changeSubClass" :disabled="selectIndustry==='all'">
                                <option type="text" v-for="sub in sub_class_list" :key="sub.id" :value="sub.細分類">{{ sub.細分類 + ':' + sub.項目名 }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div><i class="bi bi-record-circle form-label"></i><label class="ms-3 form-label">取得する項目</label></div>
        </div>
        <div class="row mt-2 pb-3">
            <div class="mx-3">
                <div class="form-check mt-2" v-for="column in select_column" :key="column.code">
                    <input class="form-check-input" type="checkbox" v-model="selectColumn" :value="column.name" :id="column.code">
                    <label class="form-check-label" :for="column.code">{{ column.name }}</label>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="">
                <input type="button" class="btn btn-orange-80 px-4" value="ダウンロードを開始" @click="downloadCorpInfoCSV" />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import axios from 'axios';

export default{
    setup() {
        onMounted(() => {
            getLargeClassList();
        });

        const back = () => {
            const url = '/home';

            location.href = url;
        }

        const selectKindList = ref([]);

        const date_from = ref("");
        const date_to = ref("");

        const selectArea = ref("all");
        const selectPref = ref("");

        const selectIndustry = ref("all");

        const kind_list = reactive([
            {
                key: 1,
                val: '企業会員',
            },
            {
                key: 2,
                val: '団体会員',
            },
            {
                key: 3,
                val: '団体所属会員',
            },
            {
                key: 4,
                val: '公共会員',
            },
            {
                key: 5,
                val: '賛助会員',
            },
            {
                key: 6,
                val: '無償会員',
            },
        ]);

        const 業種_大分類 = ref('');
        const 業種_中分類 = ref('');
        const 業種_小分類 = ref('');
        const 業種_細分類 = ref('');

        const large_class_list = ref([]);
        const middle_class_list = ref([]);
        const minor_class_list = ref([]);
        const sub_class_list = ref([]);

        const getLargeClassList = () => {
            large_class_list.value = [];
            業種_大分類.value = '0';
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getLargeClassList';
            axios.get(url).then(res => {
                large_class_list.value = res.data;
            });
        }

        const changeLargeClass = () => {
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMiddleClassList?largeClass=' + 業種_大分類.value;

            axios.get(url).then(res => {
                middle_class_list.value = res.data;
            });
        }

        const changeMiddleClass = () => {
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMinorClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value;

            axios.get(url).then(res => {
                minor_class_list.value = res.data;
            });
        }

        const changeMinorClass = () => {
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getSubClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value + '&minorClass=' + 業種_小分類.value;

            axios.get(url).then(res => {
                sub_class_list.value = res.data;
            });
        }

        const selectColumn = ref([]);

        const downloadCorpInfoCSV = () => {
            const industrialClass = calcIndustrialClass();

            const url = "/api/s-admin/downloadCorpInfoCSV";

            axios.post(url, {
                会員種別: selectKindList.value,
                取得期間_from: date_from.value,
                取得期間_to: date_to.value,
                対象地域: selectArea.value === 'all' ? selectArea.value : selectPref.value,
                業種別: industrialClass,
                取得する項目: selectColumn.value,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = '会員企業一覧.csv';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            });
        }

        const calcIndustrialClass = () => {
            if(selectIndustry.value === 'all'){
                return 'all';
            }else{
                const rtnVal = returnIndustrialClass();

                if(rtnVal == "" || rtnVal == "0"){
                    return 'all';
                }

                return rtnVal;
            }
        }

        const returnIndustrialClass = () => {
            if(業種_細分類.value){
                return 業種_細分類.value;
            }
            if(業種_小分類.value){
                return 業種_小分類.value;
            }
            if(業種_中分類.value){
                return 業種_中分類.value;
            }
            return 業種_大分類.value;
        }

        return {
            back,

            selectKindList,
            date_from,
            date_to,
            kind_list,
            selectArea,
            selectPref,
            selectIndustry,

            業種_大分類,
            業種_中分類,
            業種_小分類,
            業種_細分類,

            large_class_list,
            middle_class_list,
            minor_class_list,
            sub_class_list,

            getLargeClassList,
            changeLargeClass,
            changeMiddleClass,
            changeMinorClass,

            selectColumn,

            downloadCorpInfoCSV,

            calcIndustrialClass,
            returnIndustrialClass,
        }
    },
    props: {
        pref_list: {
            type: Array,
        },
        select_column: {
            type: Array,
        },
    }
}
</script>

<style lang="scss" scoped>
.border-separate {
    border-bottom: solid thin #404040;
}
</style>