<template>
    <div>
        <div class="row">
            <div class="col-md-10">
                <div class="row">
                    <span class="col-md-2 font-orange">{{ corp_info.会員種別 }}</span>
                    <span class="col-md-6 text-light">{{ corp_info.トライアル期限 }}</span>
                </div>
                <div class="row mt-1">
                    <span class="col-md-2 text-light">{{ corp_info.企業ID }}</span>
                    <span class="col-md-9 text-light">{{ corp_info.組織名 }}</span>
                </div>
            </div>
            <div class="col-md-2">
                <div class=" d-flex justify-content-end" v-if="is('administrator')">
                    <input type="button" class="btn btn-only-administrator btn-sm px-3" value="管理者専用TOP" @click="showAdministratorHome" /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const showAdministratorHome = () => {
            const url = '/al/administrator/home';

            location.href = url;
        }
        return {
            showAdministratorHome,
        }
    },
    props: {
        corp_info: {
            type: Object,
        }
    }
}
</script>

<style lang="scss" scoped>
    .font-orange{
        color: #ffaa71;
    }

    .btn-only-administrator {
        background-color: #7f7f7f;
    }

    .btn-only-administrator:hover,
    .btn-only-administrator:active {
        background-color: #FEFEFE;
    }
</style>