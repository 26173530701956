<template>
    <div class="row">
        <div class="h5 col-md-8 heading-orange mt-3" style="font-size:12pt">
            4. 財務診断と事前対策計画｜資産復旧費用の算定
        </div>

        
        <div class="col-md-4 d-flex justify-content-evenly">
            <h4 class="align-self-end"><span class="badge bg-badge-yellow">製造業</span></h4>
            <h5 class="align-self-end"><input type="button" class="btn btn-black btn-sm align-self-end" value="前ページへ戻る"></h5>
        </div>
    </div>

    <levelIcon
        :set_level="setLevel"
    />

    <div class="row ms-3 mt-3">
        <div class="col-md-12">
            <div class="card-group">
                <div class="card bg-light-gray">
                    <div class="card-body">
<!--------------------------------------対象とする被害の選択---------------------------------------------------------------->
                        <div class="row ms-1 mt-3 d-flex flex-row">
                            <div class="col-md-6">
                                <label for="" class="form-label d-flex align-items-center">対象とする被害の選択</label>
                            </div>
                        </div>

                        <div class="row ms-1 d-flex flex-row">
                            <div class="col-md-10">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                                    <label class="form-check-label" for="inlineRadio1">地震による全壊</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                    <label class="form-check-label" for="inlineRadio2">地震による半壊</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                                    <label class="form-check-label" for="inlineRadio3">床上浸水</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4">
                                    <label class="form-check-label" for="inlineRadio4">火災による全焼</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="option5">
                                    <label class="form-check-label" for="inlineRadio5">水害による床上浸水</label>
                                </div>
                            </div>
                        </div>

                        <div class="row ms-1 mt-5 d-flex flex-row">
                            <div class="col-md-11">
                                <label for="" class="form-label d-flex align-items-center">復旧費用の算定</label>
                            </div>
                            <div class="p2 d-flex align-items-center justify-content-start ">
                                <label for="" class="col-md-9 form-label">&emsp;</label>
                                <label for="" class="form-label">単位：千円</label>
                            </div>
                        </div>

                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                復旧期間（日）
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                復旧費用
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                事業再開に不可欠なもの
                            </div>
                            <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                                <VDropdown
                                    theme="hint-theme"
                                >
                                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                    <template #popper>
                                        <div class="row">
                                            <label>電気・ガス・水道の復旧日数は、自治体によっては予想が示されていますから参考にしてください。<br />ただ、災害の種類や強さが違えば使えない場合がありますので注意が必要です。<br />あなたの{{ callReplaceWord('組織') }}の設備と、電気・ガス・水道の両方が復旧していなければ稼動出来ません。<br />両方を比べて、あなたの{{ callReplaceWord('組織') }}の{{ callReplaceWord('事業') }}がストップする期間を考えてください。</label>
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>

                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                建物・構造物
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="建物・構造物" placeholder="20">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="建物・構造物" placeholder="3,000,000">
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                機械・装置等
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="機械・装置等" placeholder="80">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="機械・装置等" placeholder="1,000,000">
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                棚卸資産
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="棚卸資産" placeholder="25">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="棚卸資産" placeholder="1,000,000">
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                器具・工具等
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="器具・工具等" placeholder="15">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="器具・工具等" placeholder="1,000,000">
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                電気
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="電気" placeholder="5">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                都市ガス
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="都市ガス" placeholder="15">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                水道
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="水道" placeholder="10">
                            </div>
                            <div class="p2 col-md-2 border border-dark border-bottom-0 border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                            <div class="p2 col-md-3 border border-dark border-bottom-0 bg-white d-flex align-items-center justify-content-start">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">&emsp;</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ms-3 d-flex flex-row">
                            <div class="p2 col-md-3 border border-dark border-end-0 bg-resilience-gray d-flex align-items-center justify-content-start">
                                計
                            </div>
                            <div class="p2 col-md-2 border border-dark border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="計" placeholder="170" disabled>
                            </div>
                            <div class="p2 col-md-2 border border-dark border-end-0 bg-white d-flex align-items-center justify-content-start">
                                <input type="text" class="form-control border-white text-end" id="計" placeholder="6,000,000" disabled>
                            </div>
                            <div class="p2 col-md-3 border border-dark bg-white d-flex align-items-center justify-content-start">
                                &emsp;
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-1">
                        <div class="p2 col-md-1 d-flex align-items-center justify-content-start">
                            <VDropdown
                                theme="hint-theme"
                            >
                                <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                                <template #popper>
                                    <div class="row">
                                        <label>あなたの{{ callReplaceWord('組織') }}が自然災害によってどうなるか、できる限り、経営者のあなた自身で大まかに見積もってください。<br />そして納得のいく復旧費用の金額を算定してください。</label>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="d-flex flex-row">
                            <input type="button" class="btn btn-sm btn-dark" value="ワンセット増やす">
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="p2 d-flex flex-row align-items-center justify-content-start">
                <input type="button" class="btn btn-bcp-middle" value="4. 財務診断と事前対策計画｜事業中断によるキャッシュフローの悪化額Bの算定 へ" style="font-size:10.5pt" @click="showNext">
                <input type="button" class="ms-3 btn btn-bcp-basic" value="目次構成 へ戻る" style="font-size:10.5pt">
            </div>
        </div>
    </div>

</template>

<script>
import { ref } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();

export default {
    components: {
        levelIcon
    },
    setup(props) {
        const setLevel = ref('001000');

        const showNext = () => {
            const url = '/bcp/bcp_financial/製3';

            location.href = url;
        }

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        return {
            setLevel,

            showNext,

            callReplaceWord,
        }
    },
    props:{
        replacement_word_list: {
            type: Array,
        }
    }

}
</script>

<style lang="scss" scoped>
.btn-manufacture {
    border: 1px solid;
    color: #404040;
    border-color: #ffd966;
    background-color: #ffd966;
}

.btn-manufacture:hover {
    border: 1px solid;
    color: #ffd966;
    border-color: #ffd966;
    background-color: #404040;
}

.btn-construction {
    border: 1px solid;
    color: #404040;
    border-color: #9bc2e6;
    background-color: #9bc2e6;
}

.btn-construction:hover {
    border: 1px solid;
    color: #9bc2e6;
    border-color: #9bc2e6;
    background-color: #404040;
}

.btn-retail {
    border: 1px solid;
    color: #404040;
    border-color: #a9d08e;
    background-color: #a9d08e;
}

.btn-retail:hover {
    border: 1px solid;
    color: #a9d08e;
    border-color: #a9d08e;
    background-color: #404040;
}

.bg-badge-yellow {
    background-color: #ffd966;
    border: 1px solid #404040;
    color: #404040;
}

.bg-resilience-gray {
    background-color: #bfbfbf;
}


</style>