<template>
    <div id="top">
    </div>
    <div class="container" v-if="showMode === 3">
        <div class="heading-red mt-3">無償会員登録</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label fw-bold">3.無償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・無償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">6.本登録完了</div>
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">組織・企業の情報</div>
        </div>
        <div class="row mt-1">
            <div class="offset-md-1 col-md-8">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td colspan="2">事業形態</td>
                            <td>{{ temp_corp_info.事業形態名 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">法人名／屋号</td>
                            <td>{{ temp_corp_info.組織名 }}</td>
                        </tr>
                        <tr>
                            <td rowspan="4" class="align-middle">所在地</td>
                            <td>郵便番号</td>
                            <td>{{ label郵便番号 }}</td>
                        </tr>
                        <tr>
                            <td>都道府県</td>
                            <td>{{ temp_corp_info.都道府県 }}</td>
                        </tr>
                        <tr>
                            <td>市区町村</td>
                            <td>{{ temp_corp_info.市区町村 }}</td>
                        </tr>
                        <tr>
                            <td>町名番地、ビル名等</td>
                            <td>{{ temp_corp_info.町名番地 }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-2 col-form-label">代表電話番号<span class="badge bg-resilience-red ms-3">必須</span></label>
                    
                    <div class="col-md-6 me-5">
                        <div class="row">
                            <div class="col-2">
                                <input type="text" class="form-control" v-model="input電話番号1" required maxlength="10" :class="{'is-invalid': has電話番号_Error}" />
                                <div class="invalid-feedback">{{ 電話番号_エラーメッセージ }}</div>
                                <div class="form-text">半角数字</div>
                            </div>
                            <label class="col-1 col-form-label">-</label>
                            <div class="col-2">
                                <input type="text" class="form-control" v-model="input電話番号2" required maxlength="10" :class="{'is-invalid': has電話番号_Error}" />
                            </div>
                            <label class="col-1 col-form-label">-</label>
                            <div class="col-2">
                                <input type="text" class="form-control" v-model="input電話番号3" required maxlength="10" :class="{'is-invalid': has電話番号_Error}" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-1 col-form-label">代表者</label>
                    <label class="col-md-2 col-form-label">氏名<span class="badge bg-resilience-red ms-3">必須</span></label>
                    <div class="col-md-8 me-5">
                        <div class="row">
                            <label class="col-form-label col-1">（姓）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_family_name' v-model="input代表者名_姓" required @input="handleRepresentativeFamilyNameInput" maxlength="255" :class="{'is-invalid': has代表者名_姓_Error}">
                                <div class="invalid-feedback">{{ 代表者名_姓_エラーメッセージ }}</div>
                            </div>
                            <label class="col-form-label col-1">（名）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_first_name' v-model="input代表者名_名" required @input="handleRepresentativeFirstNameInput" maxlength="255" :class="{'is-invalid': has代表者名_名_Error}">
                                <div class="invalid-feedback">{{ 代表者名_名_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-1 col-form-label"></label>
                    <label class="col-md-2 col-form-label">フリガナ<span class="badge bg-resilience-red ms-3">必須</span></label>
                    <div class="col-md-8 me-5">
                        <div class="row">
                            <label class="col-form-label col-1">（姓）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_family_name_kana' v-model="input代表者名_姓_カナ" required maxlength="255" :class="{'is-invalid': has代表者名_姓_カナ_Error}" />
                                <div class="invalid-feedback">{{ 代表者名_姓_カナ_エラーメッセージ }}</div>
                            </div>
                            <label class="col-form-label col-1">（名）</label>
                            <div class="col-4">
                                <input type="text" class="form-control" id='representative_first_name_kana' v-model="input代表者名_名_カナ" required maxlength="255" :class="{'is-invalid': has代表者名_名_カナ_Error}" />
                                <div class="invalid-feedback">{{ 代表者名_名_カナ_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-1 col-form-label"></label>
                    <label class="col-md-2 col-form-label">役職<span class="badge bg-resilience-red ms-3">必須</span></label>
                    <div class="col-md-8 me-5">
                        <div class="row">
                            <div class="col-4 offset-1">
                                <input type="text" class="form-control" id='representative_post' v-model="input代表者_役職" required maxlength="255" :class="{'is-invalid': has代表者_役職_Error}" />
                                <div class="invalid-feedback">{{ 代表者_役職_エラーメッセージ }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1 col-md-8">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td rowspan="5" class="align-middle text-nowrap">管理者</td>
                            <td >氏名</td>
                            <td>{{ temp_corp_info.管理者名_姓 }}</td>
                            <td>{{ temp_corp_info.管理者名_名 }}</td>
                        </tr>
                        <tr>
                            <td class="col-form-label text-nowrap">フリガナ</td>
                            <td colspan="2">
                                <div class="row">
                                    <label class="col-1 col-form-label">姓</label>
                                    <div class="col-5">
                                        <input type="text" class="form-control" v-model="input管理者名_姓_カナ" required maxlength="255" :class="{'is-invalid': has管理者名_姓_カナ_Error}" />
                                        <div class="invalid-feedback">{{ 管理者名_姓_カナ_エラーメッセージ }}</div>
                                    </div>
                                    <label class="col-1 col-form-label">名</label>
                                    <div class="col-5">
                                        <input type="text" class="form-control" v-model="input管理者名_名_カナ" required maxlength="255" :class="{'is-invalid': has管理者名_名_カナ_Error}" />
                                        <div class="invalid-feedback">{{ 管理者名_名_カナ_エラーメッセージ }}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">部署・役職</td>
                            <td>{{ temp_corp_info.管理者_所属部署 }}</td>
                            <td>{{ temp_corp_info.管理者_役職 }}</td>
                        </tr>
                        <tr>
                            <td class="col-form-label text-nowrap">電話番号</td>
                            <td colspan="2">
                                <div class="row">
                                    <div class="col-2">
                                        <input type="text" class="form-control" v-model="input管理者_電話番号1" required maxlength="10" :class="{'is-invalid': has管理者電話番号_Error}" />
                                        <div class="invalid-feedback">{{ 管理者電話番号_エラーメッセージ }}</div>
                                    </div>
                                    <label class="col-1 col-form-label">-</label>
                                    <div class="col-2">
                                        <input type="text" class="form-control" v-model="input管理者_電話番号2" required maxlength="10" :class="{'is-invalid': has管理者電話番号_Error}" />
                                    </div>
                                    <label class="col-1 col-form-label">-</label>
                                    <div class="col-2">
                                        <input type="text" class="form-control" v-model="input管理者_電話番号3" required maxlength="10" :class="{'is-invalid': has管理者電話番号_Error}" />
                                    </div>
                                    <div class="col-2">
                                        <input type="button" value="代表電話番号と同じ" class="btn btn-black" @click="sameTel">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">メールアドレス</td>
                            <td colspan="2">{{ temp_corp_info.管理者_メールアドレス }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-2 col-md-8">
                <div class="row">
                    <label class="col-md-2">メルマガ登録</label>
                    <label class="col-md-6">無償会員は自動的にメルマガが配信されます</label>
                    <div class="col-md-1">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>{{ msg_1 }}</label>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <label class="col-md-2 col-form-label">業種<br />（日本標準産業分類）</label>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">大分類（A～T）</label>
                    <div class="col-md-3">
                        <select name="業種_大分類" class="form-select" v-model="業種_大分類" required @change="changeLargeClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="large in japan_standard_industrial_large_list" :key="large.id" :value="large.大分類">{{ large.大分類 + ':' + large.項目名 }}</option>
                        </select>
                    </div>
                    <div class="col-md-1">
                        <VDropdown
                            theme="hint-theme"
                        >
                            <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント" />
                            <template #popper>
                                <div class="row">
                                    <label>総務省日本標準産業分類（平成26年4月1日施行）</label>
                                </div>
                                <div class="row">
                                    <a href="https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000023.html" target="_blank">https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000023.html</a>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">中分類（2桁）</label>
                    <div class="col-md-3">
                        <select name="業種_中分類" class="form-select" v-model="業種_中分類" required @change="changeMiddleClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="middle in middle_class_list" :key="middle.id" :value="middle.中分類">{{ middle.中分類 + ':' + middle.項目名 }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">小分類（3桁）</label>
                    <div class="col-md-3">
                        <select name="業種_中分類" class="form-select" v-model="業種_小分類" required @change="changeMinorClass" :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="minor in minor_class_list" :key="minor.id" :value="minor.小分類">{{ minor.小分類 + ':' + minor.項目名 }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 pb-5 border-bottom-red">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-1 col-form-label"><span class="badge bg-resilience-red ms-3">必須</span></div>
                    <label class="col-md-2 col-form-label">細分類（4桁）</label>
                    <div class="col-md-3">
                        <select name="業種_中分類" class="form-select" v-model="業種_細分類" required @change="changeSubClass"  :class="{'is-invalid': has業種_Error}">
                            <option type="text" v-for="sub in sub_class_list" :key="sub.id" :value="sub.細分類">{{ sub.細分類 + ':' + sub.項目名 }}</option>
                        </select>
                        <div class="invalid-feedback">{{ 業種_エラーメッセージ }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-3">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mx-5 px-5">
                                    <input type="button" class="btn btn-red" value="利用規約、無償規約を確認する" @click="showTermsForFree" />
                                </div>
                                <div class="row mx-5 px-5 mt-3 justify-content-md-center">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" v-model="isAgree" id="agree" :disabled="!isShowTerms" />
                                        <label class="form-check-label" for="agree">
                                            利用規約、無償会員規約に同意します。
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-5">
            <div class="offset-md-1">
                <div class="row">
                    <div class="col-md-3">
                        <input type="button" class="btn btn-inquiry-button" value="入力内容を確認する" v-scroll-to="'#top'" @click="postForm" :disabled="isShowTerms === false || isAgree === false"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-else-if="showMode === 4">
        <div class="heading-red mt-3">無償会員登録</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.無償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・無償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label fw-bold">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">6.本登録完了</div>
        </div>
        <div class="row my-5">
            <div class="col-md-1 text-end">◉</div>
            <div class="col-md-11">組織・企業の情報</div>
        </div>
        <div class="row pb-5 border-bottom-red">
            <div class="col-md-8 offset-md-1">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td colspan="2">法人名／屋号</td>
                            <td>{{ temp_corp_info.組織名 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" rowspan="2" class="align-middle">所在地</td>
                            <td>{{ label郵便番号 }}</td>
                        </tr>
                        <tr>
                            <td>{{ temp_corp_info.都道府県 + temp_corp_info.市区町村 + temp_corp_info.町名番地 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">代表電話番号</td>
                            <td>{{ input電話番号1 + '-' + input電話番号2 + '-' + input電話番号3 }}</td>
                        </tr>
                        <tr>
                            <td rowspan="3" class="align-middle">代表者</td>
                            <td>氏名</td>
                            <td>{{ input代表者名_姓 + '　' + input代表者名_名 }}</td>
                        </tr>
                        <tr>
                            <td>フリガナ</td>
                            <td>{{ input代表者名_姓_カナ + '　' + input代表者名_名_カナ }}</td>
                        </tr>
                        <tr>
                            <td>役職</td>
                            <td colspan="4">{{ input代表者_役職 }}</td>
                        </tr>
                        <tr>
                            <td rowspan="7" class="align-middle">管理者</td>
                            <td>利用者ID</td>
                            <td>{{ 利用者ID }}</td>
                        </tr>
                        <tr>
                            <td>氏名</td>
                            <td>{{ temp_corp_info.管理者名_姓 + '　' + temp_corp_info.管理者名_名 }}</td>
                        </tr>
                        <tr>
                            <td>フリガナ</td>
                            <td>{{ input管理者名_姓_カナ + '　' + input管理者名_名_カナ }}</td>
                        </tr>
                        <tr>
                            <td>部署・役職</td>
                            <td>{{ temp_corp_info.管理者_所属部署 + '　' + temp_corp_info.管理者_役職 }}</td>
                        </tr>
                        <tr>
                            <td>電話番号</td>
                            <td>{{ input管理者_電話番号1 + '-' + input管理者_電話番号2 + '-' + input管理者_電話番号3 }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">メールアドレス</td>
                            <td>{{ temp_corp_info.管理者_メールアドレス }}</td>
                        </tr>
                        <tr>
                            <td>メルマガ登録</td>
                            <td><div class="row"><div class="col-3">登録する</div><div class="ms-2 col-8 color-resilience-red">※無償会員は自動的に配信されます。</div></div></td>
                        </tr>
                        <tr>
                            <td colspan="2">業種（日本標準産業分類）</td>
                            <td>{{ 業種_細分類 + '　' + label細分類名 }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">利用規約、無償会員規約</td>
                            <td>{{ isAgree ? '同意する' : '同意しない' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-8 offset-md-1">
                <label>上記の内容でよろしければ、次の手続へお進みください。</label>
            </div>
        </div>
        <div class="row mb-5 mt-3">
            <div class="col-md-10 offset-md-1">
                <input type="button" class="btn btn-inquiry-button" value="上記内容で登録し、専用ページURLをメールで受信する" @click="confirm" :disabled="isProcessing">
                <input type="button" class="btn btn-inquiry-button ms-3" value="前ページに戻って訂正する" @click="backInput" :disabled="isProcessing">
            </div>
        </div>
    </div>
    <div class="container" v-else-if="showMode === 5">
        <div class="heading-red mt-3">無償会員（本登録完了）</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.無償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・無償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label fw-bold">6.本登録完了</div>
        </div>
        <div class="row mt-3">
            <label>登録が完了いたしました。ログインに必要な専用URL、ID・パスワードは、登録メールアドレスに送信しました。</label>
        </div>
        <div class="row mt-3">
            <div class="col-md-8">
                <table class="table table-bordered bg-light" id="result_table">
                    <tbody>
                        <tr>
                            <td>企業ID（会員番号）</td>
                            <td>{{ 企業ID }}</td>
                        </tr>
                        <tr>
                            <td>開始日（開始月）</td>
                            <td>
                                <div class="row">
                                    <div class="col-3">
                                        {{ this.$root.dayjs(corp_info_create).format('YYYY年M月D日') }}
                                    </div>
                                    <div class="col-9">
                                        （{{ this.$root.dayjs(corp_info_create).format('YYYY年M月') }}）
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>終了日</td>
                            <td>{{ this.$root.dayjs(service_end_day).format('YYYY年M月D日') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col-md-8 color-resilience-red">
                <label>※終了時の予告はいたしませんので、継続利用されたい場合は、必ず終了前にマイページ・管理者画面において「有償会員切替」を行ってください。なお、終了後に行う場合は、「有償会員」に直接ご登録ください。</label>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, inject, onBeforeMount, onMounted, ref } from 'vue';
import CommonCheck from '../../function/CommonCheck';
import * as AutoKana from 'vanilla-autokana';
import axios from 'axios';

const { isZenKatakana, isTelNumber } = CommonCheck();

let autokana_representative_family;
let autokana_representative_first;

export default {
    setup(props) {
        const dayjs = inject('dayjs');

        onBeforeMount(() => {
            showMode.value = props.mode;
        });

        onMounted(() => {
            autokana_representative_family = AutoKana.bind('#representative_family_name');
            autokana_representative_first = AutoKana.bind('#representative_first_name');

            msg_1.value = '企業防災やBCP策定に役立つ情報を、お知らせいたします。';
        });

        const handleRepresentativeFamilyNameInput = () => {
            input代表者名_姓_カナ.value = convertKatakana(autokana_representative_family.getFurigana());
        }

        const handleRepresentativeFirstNameInput = () => {
            input代表者名_名_カナ.value = convertKatakana(autokana_representative_first.getFurigana());
        }

        const convertKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function(match) {
                const chr = match.charCodeAt(0) + 0x60;
                return String.fromCharCode(chr);
            });
        }

        const showMode = ref(0);
        const isProcessing = ref(false);

        const label郵便番号 = computed(() => {
            const 郵便番号 = props.temp_corp_info.郵便番号.toString();

            return 郵便番号.substring(0, 3) + '-' + 郵便番号.substring(3);
        });

        const input電話番号1 = ref('');
        const input電話番号2 = ref('');
        const input電話番号3 = ref('');
        const has電話番号_Error = ref(false);
        const 電話番号_エラーメッセージ = ref('');

        const input管理者_電話番号1 = ref('');
        const input管理者_電話番号2 = ref('');
        const input管理者_電話番号3 = ref('');
        const has管理者電話番号_Error = ref(false);
        const 管理者電話番号_エラーメッセージ = ref('');

        const input代表者名_姓 = ref('');
        const has代表者名_姓_Error = ref(false);
        const 代表者名_姓_エラーメッセージ = ref('');
        const input代表者名_名 = ref('');
        const has代表者名_名_Error = ref(false);
        const 代表者名_名_エラーメッセージ = ref('');
        const input代表者名_姓_カナ = ref('');
        const has代表者名_姓_カナ_Error = ref(false);
        const 代表者名_姓_カナ_エラーメッセージ = ref('');
        const input代表者名_名_カナ = ref('');
        const has代表者名_名_カナ_Error = ref(false);
        const 代表者名_名_カナ_エラーメッセージ = ref('');
        const input代表者_役職 = ref('');
        const has代表者_役職_Error = ref(false);
        const 代表者_役職_エラーメッセージ = ref('');
        const has業種_Error = ref(false);
        const 業種_エラーメッセージ = ref('');

        const 利用者ID = ref('000001');

        const input管理者名_姓_カナ = ref('');
        const has管理者名_姓_カナ_Error = ref(false);
        const 管理者名_姓_カナ_エラーメッセージ = ref('');
        const input管理者名_名_カナ = ref('');
        const has管理者名_名_カナ_Error = ref(false);
        const 管理者名_名_カナ_エラーメッセージ = ref('');

        const sameTel = () => {
            input管理者_電話番号1.value = input電話番号1.value;
            input管理者_電話番号2.value = input電話番号2.value;
            input管理者_電話番号3.value = input電話番号3.value;
        }

        const 業種_大分類 = ref('');
        const 業種_中分類 = ref('');
        const 業種_小分類 = ref('');
        const 業種_細分類 = ref('');

        const middle_class_list = ref([]);
        const minor_class_list = ref([]);
        const sub_class_list = ref([]);

        const changeLargeClass = () => {
            middle_class_list.value = [];
            業種_中分類.value = '';
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMiddleClassList?largeClass=' + 業種_大分類.value;

            axios.get(url).then(res => {
                middle_class_list.value = res.data;
            });
        }

        const changeMiddleClass = () => {
            minor_class_list.value = [];
            業種_小分類.value = '';
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getMinorClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value;

            axios.get(url).then(res => {
                minor_class_list.value = res.data;
            });
        }

        const changeMinorClass = () => {
            sub_class_list.value = [];
            業種_細分類.value = '';

            const url = '/api/getSubClassList?largeClass=' + 業種_大分類.value + '&middleClass=' + 業種_中分類.value + '&minorClass=' + 業種_小分類.value;

            axios.get(url).then(res => {
                sub_class_list.value = res.data;
            });
        }

        const postForm = () => {
            has代表者名_姓_Error.value = false;
            代表者名_姓_エラーメッセージ.value = ''
            has代表者名_名_Error.value = false;
            代表者名_名_エラーメッセージ.value = ''
            has代表者名_姓_カナ_Error.value = false;
            代表者名_姓_カナ_エラーメッセージ.value = ''
            has代表者名_名_カナ_Error.value = false;
            代表者名_名_カナ_エラーメッセージ.value = ''
            has代表者_役職_Error.value = false;
            代表者_役職_エラーメッセージ.value = '';
            has管理者名_姓_カナ_Error.value = false;
            管理者名_姓_カナ_エラーメッセージ.value = ''
            has管理者名_名_カナ_Error.value = false;
            管理者名_名_カナ_エラーメッセージ.value = ''
            has電話番号_Error.value = false;
            電話番号_エラーメッセージ.value = ''
            has管理者電話番号_Error.value = false;
            管理者電話番号_エラーメッセージ.value = ''
            has業種_Error.value = false;
            業種_エラーメッセージ.value = ''

            const url = '/api/register/checkFreeMember';

            axios.post(url, {
                代表者名_姓: input代表者名_姓.value,
                代表者名_名: input代表者名_名.value,
                代表者名_姓_カナ: input代表者名_姓_カナ.value,
                代表者名_名_カナ: input代表者名_名_カナ.value,
                代表者_役職: input代表者_役職.value,
                管理者名_姓_カナ: input管理者名_姓_カナ.value,
                管理者名_名_カナ: input管理者名_名_カナ.value,
                電話番号: input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                管理者電話番号: input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                業種: 業種_細分類.value,
            }).then(res => {
                showMode.value = 4;
                location.hash = "#top_4";
            })
            .catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.代表者名_姓){
                        has代表者名_姓_Error.value = true;
                        代表者名_姓_エラーメッセージ.value = err.response.data.errors.代表者名_姓[0];
                    }
                    if(err.response.data.errors.代表者名_名){
                        has代表者名_名_Error.value = true;
                        代表者名_名_エラーメッセージ.value = err.response.data.errors.代表者名_名[0];
                    }
                    if(err.response.data.errors.代表者名_姓_カナ){
                        has代表者名_姓_カナ_Error.value = true;
                        代表者名_姓_カナ_エラーメッセージ.value = '代表者名（姓）は全角カタカナで入力してください。'
                    }
                    if(err.response.data.errors.代表者名_名_カナ){
                        has代表者名_名_カナ_Error.value = true;
                        代表者名_名_カナ_エラーメッセージ.value = '代表者名（名）は全角カタカナで入力してください。'
                    }
                    if(err.response.data.errors.代表者_役職){
                        has代表者_役職_Error.value = true;
                        代表者_役職_エラーメッセージ.value = err.response.data.errors.代表者_役職[0];
                    }
                    if(err.response.data.errors.管理者名_姓_カナ){
                        has管理者名_姓_カナ_Error.value = true;
                        管理者名_姓_カナ_エラーメッセージ.value = '管理者名（姓）は全角カタカナで入力してください。'
                    }
                    if(err.response.data.errors.管理者名_名_カナ){
                        has管理者名_名_カナ_Error.value = true;
                        管理者名_名_カナ_エラーメッセージ.value = '管理者名（名）は全角カタカナで入力してください。'
                    }
                    if(err.response.data.errors.電話番号){
                        has電話番号_Error.value = true;
                        電話番号_エラーメッセージ.value = '電話番号を確認してください。'
                    }
                    if(err.response.data.errors.管理者電話番号){
                        has管理者電話番号_Error.value = true;
                        管理者電話番号_エラーメッセージ.value = '管理者電話番号を確認してください。'
                    }
                    if(err.response.data.errors.業種){
                        has業種_Error.value = true;
                        業種_エラーメッセージ.value = '業種を選択してください。'
                    }
                }else{
                    console.log(err.response.data);
                }
            })
        }

        const backInput = () => {
            window.scroll(0,0);
            showMode.value = 3;
        }

        const label細分類名 = computed(() => {
            if(sub_class_list.value.length > 0){
                const wkArray = sub_class_list.value.filter(ele => ele.細分類 === 業種_細分類.value);
                return wkArray[0].項目名;
            }
            return '';
        });

        const 企業ID = ref('');
        const corp_info_create = ref('');
        const local_code = ref('');

        const confirm = () => {
            const url = '/api/register/corpFree';

            isProcessing.value = true;

            axios.post(url, {
                法人番号: props.temp_corp_info.法人番号,
                事業形態: props.temp_corp_info.事業形態,
                組織名: props.temp_corp_info.組織名,
                組織名_フリガナ: props.temp_corp_info.組織名_フリガナ,
                郵便番号: props.temp_corp_info.郵便番号,
                都道府県: props.temp_corp_info.都道府県,
                市区町村: props.temp_corp_info.市区町村,
                町名番地: props.temp_corp_info.町名番地,
                電話番号: input電話番号1.value + '-' + input電話番号2.value + '-' + input電話番号3.value,
                代表者名_姓: input代表者名_姓.value,
                代表者名_名: input代表者名_名.value,
                代表者名_カナ_姓: input代表者名_姓_カナ.value,
                代表者名_カナ_名: input代表者名_名_カナ.value,
                代表者_役職: input代表者_役職.value,
                管理者名_姓: props.temp_corp_info.管理者名_姓,
                管理者名_名: props.temp_corp_info.管理者名_名,
                管理者名_カナ_姓: input管理者名_姓_カナ.value,
                管理者名_カナ_名: input管理者名_名_カナ.value,
                管理者_所属部署: props.temp_corp_info.管理者_所属部署,
                管理者_役職: props.temp_corp_info.管理者_役職,
                管理者_電話番号: input管理者_電話番号1.value  + '-' + input管理者_電話番号2.value + '-' + input管理者_電話番号3.value,
                email: props.temp_corp_info.管理者_メールアドレス,
                メルマガ登録: true,
                会員種別: props.temp_corp_info.会員種別,
                業種: 業種_細分類.value,
                会員資格更新区分: 10060002,

                利用者ID: 利用者ID.value,

                仮登録ID: props.temp_corp_info.id,

                contractAgree: isAgree.value,

                uuid: props.temp_corp_info.uuid,
                アンケート: props.temp_corp_info.アンケート,
                アンケート_その他: props.temp_corp_info.アンケート_その他,

                LINE連携: 0,
            }).then(res => {
                企業ID.value = res.data.corp.企業ID;
                corp_info_create.value = res.data.corp.created_at;
                local_code.value = res.data.local_code;

                showMode.value = 5;
                location.hash = "#top_5";
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const service_end_day = computed(() => {
            return dayjs(corp_info_create.value).add(local_code.value == '21' ? 720 : 72, 'h').format('YYYY-MM-DD');
        });

        const showTerms = () => {
            const url = '/terms'

            let newWindow = window.open(url, '_blank');
        }

        const showFreeMembershipTerms = () => {
            const url = '/free_membership_terms'

            let newWindow = window.open(url, '_blank');
        }

        const msg_1 = ref('');

        const isShowTerms = ref(false);
        const isAgree = ref(false);

        const showTermsForFree = () => {
            const url = '/termsForFree';
            let newWindow = window.open(url, '_blank');
            // let self = this;

            // let loop = setInterval(function() {
            //     if(newWindow.closed){
            //         clearInterval(loop);
            //         self.searchCustomerList(self.current_page);
            //     }
            // });
            isShowTerms.value = true;
        }

        return {
            handleRepresentativeFamilyNameInput,
            handleRepresentativeFirstNameInput,

            convertKatakana,

            showMode,
            isProcessing,

            label郵便番号,

            input代表者名_姓,
            has代表者名_姓_Error,
            代表者名_姓_エラーメッセージ,
            input代表者名_名,
            has代表者名_名_Error,
            代表者名_名_エラーメッセージ,
            input代表者名_姓_カナ,
            has代表者名_姓_カナ_Error,
            代表者名_姓_カナ_エラーメッセージ,
            input代表者名_名_カナ,
            has代表者名_名_カナ_Error,
            代表者名_名_カナ_エラーメッセージ,
            has業種_Error,
            業種_エラーメッセージ,

            input代表者_役職,
            has代表者_役職_Error,
            代表者_役職_エラーメッセージ,

            利用者ID,

            input管理者名_姓_カナ,
            has管理者名_姓_カナ_Error,
            管理者名_姓_カナ_エラーメッセージ,
            input管理者名_名_カナ,
            has管理者名_名_カナ_Error,
            管理者名_名_カナ_エラーメッセージ,

            input電話番号1,
            input電話番号2,
            input電話番号3,
            has電話番号_Error,
            電話番号_エラーメッセージ,

            input管理者_電話番号1,
            input管理者_電話番号2,
            input管理者_電話番号3,
            has管理者電話番号_Error,
            管理者電話番号_エラーメッセージ,

            sameTel,

            業種_大分類,
            業種_中分類,
            業種_小分類,
            業種_細分類,

            middle_class_list,
            minor_class_list,
            sub_class_list,
            changeLargeClass,
            changeMiddleClass,
            changeMinorClass,

            label細分類名,

            postForm,

            backInput,

            企業ID,
            corp_info_create,
            local_code,
            confirm,
            service_end_day,

            showTerms,
            showFreeMembershipTerms,

            msg_1,

            isShowTerms,
            isAgree,

            showTermsForFree,
        }
    },
    props: {
        temp_corp_info: {
            type: Object,
        },
        mode: {
            type: Number,
        },
        japan_standard_industrial_large_list: {
            type: Array,
        }
    },
}
</script>

<style lang="scss" scoped>
    #result_table tr td:nth-child(1){
        width: 25%;
    }

</style>